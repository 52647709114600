import { useState, useEffect } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";

const Item = (props) => {
    const {
        item,
        setItem,
        remove
    } = props;

    /**
     * @NOTE @HACK
     * Wierd ui update problem when the state is to deep
     * So we keep a local state val and serialNumbers for displaying porposes
     * 
     * @NOTE
     * This hack let us make the ui more efficent because we can validate for local changes only
     */
    const [val, setVal] = useState(0);
    const [serialNumbers, setSerialNumbers] = useState([]);

    const onValueChange = (v) => {
        if (!item.hasSerialNumber) return;

        const serialNumbersLength = item.serialNumbers ? item.serialNumbers.length() : 0;

        if (serialNumbersLength === 0) {
            setItem({ ...item, serialNumbers: new Array(v).fill("") });
        }

        const diff = v - length;

        if (diff > 0) {
            let n = new Array(diff).fill("");
            setItem.Item({ ...item, serialNumbers: [...item.serialNumbers, ...n] });
            setSerialNumbers([...item.serialNumbers, ...n]);
        } else if (diff < 0) {
            const sorted = item.serialNumbers.sort();
            sorted.splice(0, diff);
            setItem({ ...item, serialNumbers: [...sorted] });
            setSerialNumbers([...sorted]);
        }
    }

    const setValue = (q) => {
        const v = Number(q);
        if (isNaN(val)) {
            return;
        }

        let tmp = { ...item };
        const value = Math.max(Math.min(v, tmp.quantity), 0);
        tmp.value = value;
        setVal(value);
        setItem(tmp);
    }

    const updateSerial = (v, index) => {
        let tmp = { ...item };
        tmp.serialNumbers[index] = v;
        setSerialNumbers(tmp.serialNumbers);
        setItem(tmp);
    }

    useEffect(() => {
        if (item && item.value && item.hasSerialNumber) {
            onValueChange(item.value);
        }
    }, [item]);

    return (
        <Paper style={{  padding: "10px", boxShadow: "0px 0px 0px 1px #ffffff40" }} elevation={2}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography varinat="h6">
                        {item.productName}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography varinat="h6">
                        Cantitate existenta: {`${item.quantity} (${item.um})`}
                    </Typography>
                </Grid>
                <Grid item md={2}>

                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        required
                        type="number"
                        fullWidth
                        variant="outlined"
                        placeholder="Cantitate descarcata"
                        value={val}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button
                        fullWidth
                        onClick={() => remove()}
                        variant="contained"
                        color="secondary"
                    >
                        Stergere
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                {
                    item.hasSerialNumber &&
                    serialNumbers.map((x, index) => <Grid item xs={12}>
                        <TextField
                            placeholder={`Seria #${index + 1}`}
                            value={x}
                            onChange={(e) => updateSerial(e.target.value, index)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>)
                }
            </Grid>
        </Paper>
    )
}

export default Item;
