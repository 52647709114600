import React from "react";
import {
	CssBaseline,
	makeStyles,
	Checkbox,
	Grid,
	FormControlLabel,
	TextField,
	Typography,
	Divider,
	MenuItem,
	IconButton,
	Paper,
} from "@material-ui/core";
import CustomModal from "../../../../Components/Modals/CustomModal";
import * as lang from "../../../../Constants/strings";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import axios from "axios";
import { checkSpecialChar } from "../../../../Functions/stringManipulation";
import { Add, Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {},
	textField: {
		// margin: theme.spacing(2, 2, 0, 0),
		marginTop: theme.spacing(2),
	},
	divider: {
		margin: theme.spacing(2),
	},
	listItem: {
		margin: theme.spacing(1),
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	emailList: {
		maxHeight: "100px",
		overflowY: "auto",
	},
	clear: {
		position: "absolute",
		right: 0,
		margin: theme.spacing(2),
		marginTop: theme.spacing(4),
	},
}));

export default function AreaEditModal(props) {
	const classes = useStyles();
	const { open, close, execute, language, addData, updateField, error, updateError } = props;
	const [selectedUser, setselectedUser] = React.useState(null);
	const [userList, setUserList] = React.useState([]);

	const [errorText, setError] = React.useState();

	function checkNameAvailability(name) {
		if (name)
			axios
				.get(`/api/check_area?name=${name}&id=${addData.AREA_CODE}`)
				.then((res) => {
					if (parseInt(res.data, 10) > 0 && parseInt(res.data, 10) !== 0) {
						setError("VALUE_EXISTS");
						updateError(parseInt(res.data, 10) > 0);
					}
					if (parseInt(res.data, 10) === 0) {
						setError(null);
						updateError(parseInt(res.data, 10) > 0);
					}
					if (checkSpecialChar(name.trim(), "*|,\":<>[]{}`';()@&$#%")) {
						setError("SPECIAL_CHAR");
						updateError(true);
					}
				})
				.catch((err) => {
					console.log(err.message);
				});
		else {
			setError("EMPTY_FIELD");
			updateError(true);
		}
	}

	function getItems() {
		fetch("/api/users", {
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.token,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				// let users = response.data.userList
				// 	.filter((user) => user.LOCKED !== 1)
				// 	.map((user, index) => ({ NR: index, ...user }));
				// console.log(users);
				setUserList(
					response.data.userList
						.filter((user) => user.LOCKED !== 1)
						.map((user, index) => ({ USER_ID: user.USER_ID, USERNAME: user.USERNAME, EMAIL: user.EMAIL }))
				);
			})
			.catch((err) => console.log(err));
	}

	function focus() {
		updateError(null);
		setError(null);
	}

	React.useEffect(() => {
		setError(null);
		updateError(null);
		if (userList && userList.length === 0) getItems();
	}, [addData.AREA]);

	const updateAreaStatus = (e) => {
		e.persist();
		if (Number(e.target.value) === 1) {
			e.target.value = 0;
		} else {
			e.target.value = 1;
		}
		props.updateField(e, 1);
	};

	const changeValue = (e) => {
		e.persist();
		checkNameAvailability(e.target.value);
		props.updateField(e, 50);
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<CustomModal
				fullWidth
				open={open}
				title={lang.EDIT_AREA[language]}
				content={
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div style={{ position: "relative" }}>
								<Typography className={classes.textField} variant="h5">
									{lang.AREA_NAME[language]}
								</Typography>
								<TextField
									autoFocus={false}
									required
									fullWidth
									variant="outlined"
									className={classes.textField}
									name="AREA"
									value={addData.AREA}
									onChange={(e) => changeValue(e)}
									onFocus={() => focus()}
									error={error}
									helperText={errorText ? lang[errorText][language] : null}
									onBlur={() => checkNameAvailability(addData.AREA)}
								/>
								{error === true ? (
									<ErrorRoundedIcon className={classes.clear} color="secondary" />
								) : error === false ? (
									<CheckCircleRoundedIcon className={classes.clear} />
								) : null}
								<Divider className={classes.divider} />
								<Typography className={classes.textField} variant="h5">
									{lang.STATUS[language]}
								</Typography>
								<FormControlLabel
									className={classes.textField}
									control={
										<Checkbox
											checked={Number(addData.ACTIVE) === 1}
											name="ACTIVE"
											value={addData.ACTIVE}
											onChange={(e) => updateAreaStatus(e)}
										/>
									}
									label="Activ"
								/>
								<Typography className={classes.textField} variant="h5">
									{lang.MAILING_LISTS[language]}
								</Typography>
								<div
									className={classes.textField}
									style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
								>
									<TextField
										select
										fullWidth
										style={{ width: "90%" }}
										variant="outlined"
										label={lang.USERS[language]}
										value={selectedUser}
										onChange={(e) => setselectedUser(e.target.value)}
									>
										<MenuItem value={null}>{lang.NONE[language]}</MenuItem>
										{userList &&
											userList.map((user) => {
												return (
													<MenuItem key={user.USER_ID + "_Menu_Item"} value={user}>
														{user.USERNAME}
													</MenuItem>
												);
											})}
									</TextField>
									<IconButton
										color="primary"
										disabled={selectedUser === null}
										onClick={() => {
											props.addUser(selectedUser);
											setselectedUser(null);
										}}
									>
										<Add />
									</IconButton>
								</div>
								<Paper elevation={0}>
									<div className={classes.emailList}>
										<ul style={{ listStyleType: "none", padding: "0px" }}>
											{addData.USERS && addData.USERS.length ? (
												addData.USERS.map((item, index) => (
													<li className={classes.listItem}>
														<IconButton color="secondary" size="small" onClick={() => props.removeUser(index)}>
															<Delete fontSize="small" />
														</IconButton>
														<a>{item.USERNAME}</a>
													</li>
												))
											) : (
												<Typography>Nu exista adrese de email...</Typography>
											)}
										</ul>
									</div>
								</Paper>
							</div>
						</Grid>
					</Grid>
				}
				close={close}
				execute={() => execute()}
				validate={!error}
				language={language}
			/>
		</React.Fragment>
	);
}
