import React from "react";
import {
  LocalShippingRounded,
  DescriptionRounded,
  AssignmentLateRounded,
  PermPhoneMsg,
  Apartment,
  AccountTree,
  Description
} from "@material-ui/icons";
import {
  Tabs,
  AppBar,
  Tab,
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import * as lang from "../../../Constants/strings";
import { checkPermissions } from "../../../Functions/permissionHide";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function SiteTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { tiny, language, tabs, isAuthenticated, userPermissions } = props;
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          paddingRight: "0px",
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <div className={classes.root}>
          <AppBar
            position="static"
            color="default"
          >
            <Tabs
              value={tabs.tabIndex}
              onChange={tabs.changeTabIndex}
              variant="scrollable"
              indicatorColor="secondary"
            >
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "CONTRACTS",
                      name: "VIEW_CONTRACT",
                    }) === true
                    ? false
                    : true
                }
                icon={<DescriptionRounded />}
                label={lang.CONTRACTS[language]}
              />
              <Tab
                disabled={isAuthenticated &&
                  checkPermissions(userPermissions, {
                    parent: "MAINTENANCE",
                    name: "VIEW_MAINTENANCE",
                  }) === true
                  ? false
                  : true}
                icon={<AssignmentLateRounded />}
                label={lang.TASK_MAINTENANCES[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "TASKS",
                      name: "VIEW_TASK",
                    }) === true
                    ? false
                    : true
                }
                icon={<AssignmentLateRounded />}
                label={lang.TASKS_REPAIR[language]}
              />
              <Tab
                // disabled
                icon={<LocalShippingRounded />}
                label={lang.INTERVENTIONS[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "INTERVENTIONS",
                      name: "VIEW_PHONE_WARNING",
                    }) === true
                    ? false
                    : true
                }
                icon={<PermPhoneMsg />}
                label={lang.PHONE_WARNING[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "SITE",
                      name: "VIEW_SITE_AREA",
                    }) === true
                    ? false
                    : true
                }
                icon={<Apartment />}
                label={lang.SITE_ALARM_AREA[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "TASKS",
                      name: "VIEW_TASK_BILL_HISTORY",
                    }) === true
                    ? false
                    : true
                }
                icon={<Apartment />}
                label={lang.TASK_BILL_HISTORY[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "PROJECTS",
                      name: "VIEW_PROJECTS",
                    }) === true
                    ? false
                    : true
                }
                icon={<AccountTree />}
                label={lang.PROJECTS[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "RISC_EVALUATIONS",
                      name: "VIEW_RISC_EVALUATIONS",
                    }) === true
                    ? false
                    : true
                }
                icon={<Description />}
                label={lang.RISC_EVALUATION[language]}
              />
              <Tab
                disabled={
                  isAuthenticated &&
                    checkPermissions(userPermissions, {
                      parent: "INSTALLATION",
                      name: "VIEW_INSTALLATION",
                    }) === true
                    ? false
                    : true
                }
                icon={<Description />}
                label={lang.TASKS_INSTALLATION[language]}
              />
            </Tabs>
          </AppBar>
        </div>
      </Grid>
    </React.Fragment>
  );
}

