import React from 'react';
import { Button, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import * as lang from '../../../Constants/strings';
import { tasks_cashing_validation } from '../../../Components/Misc/Tables/TableHeaders';
import CashingValidationModal from '../CashingValidationModal';
import AreaSelectModal from "../../Tasks/Modals/AreaSelect";
import SelectDateModal from "../../Tasks/Modals/SelectIntervalModal";
import axios from 'axios';
import * as requestHeaders from '../../../Constants/requestHeaders';
import { getBillPayStatus } from "../../../Functions/getIcon";
import moment from "moment";
import {useBillingStyles} from "../../../Constants/styles/billValidation";

export default function ProjectBillCashingValidation(props) {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const classes = useBillingStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const [data, setData] = React.useState([]);
    const [selectedId, setSelectedId] = React.useState(null);
    const [modal, setModal] = React.useState(false);

    const [selectAreaModal, setSelectedAreaModal] = React.useState(false);
    const [selectDateIntervalModal, setSelectDateIntervalModal] = React.useState(false);
    const [changeFilter, setChangeFilter] = React.useState(false);

    const [selectedArea, setSelectedArea] = React.useState(null);
    const [interval, setInterval] = React.useState(undefined);

    React.useEffect(() => {
        if (changeFilter) {
            getUnvalidatedBillsByType();
        } else {
            getUnvaliadtedBillCashing();
        }
    }, [selectedArea, interval]);

    const resetFilters = () => {
        setInterval(undefined);
        setSelectedArea(null);
        setChangeFilter(false);
    };

    const getUnvalidatedBillsByType = async () => {
        try {
            const response = await axios.get('/api/get_unvalidated_project_bill_by_type', {
                ...requestHeaders.getGetHeader(),
                params: {
                    type: "chasing",
                    areaCode: selectedArea !== null ? selectedArea.AREA_CODE : undefined,
                    interval: interval ? interval : undefined,
                }
            });
            let formatedBills = [];

            if (response.status === 200) {
                formatedBills = response.data.data.map(bill => {
                    bill.BILLING_TYPE = bill.BILLING_TYPE !== null ? lang[bill.BILLING_TYPE][language] : bill.BILLING_TYPE;
                    bill.PAYMENT_TYPE = bill.PAYMENT_TYPE !== null ? lang[bill.PAYMENT_TYPE][language] : bill.PAYMENT_TYPE;
                    bill.CREATE_IN = bill.CREATE_IN !== null ? moment(bill.CREATE_IN).format("DD-MM-YYYY HH:mm") : bill.CREATE_IN;
                    bill.PAY = bill.FK2BILL_TYPE !== 1 ? (bill.IS_PARTIAL_PAYED === 1 ? "Tranșe multiple" : "O singură tranșă") : "";
                    bill.COMPLETED_PAYED = getBillPayStatus(bill);
                    return bill;
                });
                setData(formatedBills ? formatedBills : []);
            } else {
                console.error("Error: ", response.data.message);
                setData(formatedBills);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    React.useEffect(() => {
        getUnvaliadtedBillCashing();
    }, []);

    const getUnvaliadtedBillCashing = async () => {
        const response = await axios.get('/api/get_unvalidated_project_cashing', requestHeaders.getGetHeader());
        let formatedBills;

        if (response.data.data && response.data.data.length > 0) {
            formatedBills = response.data.data.map(bill => {
                bill.BILLING_TYPE = bill.BILLING_TYPE !== null ? lang[bill.BILLING_TYPE][language] : bill.BILLING_TYPE;
                bill.PAYMENT_TYPE = bill.PAYMENT_TYPE !== null ? lang[bill.PAYMENT_TYPE][language] : bill.PAYMENT_TYPE;
                bill.CREATE_IN = bill.CREATE_IN !== null ? moment(bill.CREATE_IN).format("DD-MM-YYYY HH:mm") : bill.CREATE_IN;
                bill.PAY = bill.FK2BILL_TYPE !== 1 ? (bill.IS_PARTIAL_PAYED === 1 ? "Tranșe multiple" : "O singură tranșă") : "";
                bill.COMPLETED_PAYED = getBillPayStatus(bill);
                return bill;
            });
        };

        setData(formatedBills ? formatedBills : []);
    };

    return (
        <React.Fragment>
            <CashingValidationModal
                language={language}
                open={modal}
                reopen={() => setModal(true)}
                close={() => setModal(false)}
                billId={selectedId}
                lockFields={true}
                refeatchTaskData={() => getUnvaliadtedBillCashing()}
            />
            <AreaSelectModal
                open={selectAreaModal}
                onClose={() => setSelectedAreaModal(false)}
                language={language}
                selectedArea={selectedArea}
                setSelectedArea={(e) => {
                    setChangeFilter(true);
                    setSelectedArea(e.target.value);
                }}
                callback={() => {
                    setSelectedAreaModal(false)
                }}
            />
            <SelectDateModal
                open={selectDateIntervalModal}
                onClose={() => setSelectDateIntervalModal(false)}
                language={language}
                callback={(startDate, endDate) => {
                    setInterval({ startDate, endDate })
                    setSelectDateIntervalModal(false);
                    setChangeFilter(true);
                }}
            />

            <Grid container component="main" className={classes.margin}>
                <Grid item xs={12}>
                    <div className={classes.filterButtonsContainer}>
                        <Button
                            className={classes.filterButtons}
                            variant="contained"
                            color="primary"
                            onClick={() => setSelectDateIntervalModal(true)}
                        >
                            {lang.SELECT_INTERVAL_DATE[language]}
                        </Button>
                        <Button
                            className={classes.filterButtons}
                            variant="contained"
                            color="secondary"
                            onClick={() => setSelectedAreaModal(true)}
                        >
                            {lang.AREA_SELECT[language]}
                        </Button>
                        <Button
                            className={classes.filterButtons}
                            variant="contained"
                            color="primary"
                            onClick={() => resetFilters()}
                        >
                            {lang.RESET[language]}
                        </Button>
                    </div>
                </Grid>


                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.RECEIPT_VALIDATION[language]}
                        language={language}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={tasks_cashing_validation(language)}
                        dense={false}
                        // openAddModal={props.openAddModal}
                        openRowModal={(id) => {
                            setSelectedId(id);
                            setModal(true);
                        }}
                        clickLabel="OBJID"
                        hideAddBtn={true}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
