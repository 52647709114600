import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    makeStyles,
    Grid,
    CircularProgress,
    Button
} from "@material-ui/core";
import axios from "axios";
import moment from 'moment';

import * as actionCreators from "../../actions/actions";
import * as lang from "../../Constants/strings";
import * as requestHeaders from "../../Constants/requestHeaders";

import FilterHeader from "./FilterHeader";
import CollapsibleTable from "../../Components/Misc/Tables/CollapsibleTable";

import {
    area_management,
    intervention_history_data_management
} from "../../Components/Misc/Tables/TableHeaders";
import {handleAsyncCall} from "../../Functions/async_utils";
import getGoogleMapLink from '../../Functions/getGoogleMapLink';
import {openPageNewTab} from '../../Functions/linkFunctions';
import {GpsFixedRounded} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        overflow: "hidden"
    },
    paper: {
        width: 'auto',
    }
}));

const InterventionManagement = (props) => {
        const {
            language,
            actions
        } = props;

        const classes = useStyles();

        const [loading, setLoading] = useState(false);
        const [rowsPerPage, onRowsPerPageChange] = useState(10);
        const [page, setPage] = useState(0);

        const [data, setData] = useState([]);
        const [dataLength, setDataLength] = useState(0);

        const [cachedFilters, setCachedFilter] = useState({});

        const fetchData = async (filters) => {

            const newFilters = JSON.stringify(cachedFilters) !== JSON.stringify(filters);

            setLoading(true);

            setCachedFilter(filters);

            try {
                const result = await axios.get('/api/get_area_intervention', {
                    ...requestHeaders.getGetHeader(),
                    params: {
                        ...filters,
                        page: newFilters ? 0 : page,
                        perPage: rowsPerPage
                    }
                });

                if (newFilters) setPage(0);

                const formattedData = result.data.data.map(e => {
                    return {
                        ...e,
                        BILL_CYCLE_DESC: e.BILL_CYCLE_DESC ? lang[e.BILL_CYCLE_DESC][language] : '',
                        GPS_POSITION: e.GPS_POSITION && e.GPS_POSITION !== "-" ? <Button
                            size="small"
                            variant="outlined"
                            className={classes.commentButton}
                            onClick={() => openPageNewTab(getGoogleMapLink(e.GPS_POSITION.split(" / ")[0], e.GPS_POSITION.split(" / ")[1]))}
                        >
                            <GpsFixedRounded fontSize="small" className={classes.leftIcon}/>
                        </Button> : "-"
                    }
                });
                if (newFilters) {
                    setData([...formattedData]);
                } else {
                    setData([...data, ...formattedData]);
                }
                setDataLength(result.data.count);
            } catch (e) {
                setData([]);
                setDataLength(0);
            }


            setLoading(false);
        };


        const download = async (data) => {
            const response = await axios.get("/api/excel_area_intervention", {
                ...requestHeaders.getGetHeader(),
                params: {
                    ...data
                },
                responseType: 'blob',
            });

            if (response.status === 200) {
                const blob = new Blob([response.data])
                const href = window.URL.createObjectURL(blob)
                let link = document.createElement("a");
                link.download = `gestiune_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`;
                link.href = href;
                link.click();
            }
        };

        useEffect(() => {
            setData([]);
            actions.checkSession();
        }, []);

        useEffect(() => {
            if (cachedFilters && page > 0) fetchData(cachedFilters);
        }, [page])

        const getCollapsingTableName = (row) => `${lang.SITE[language]} - ${row.SITE_NAME} - ${lang.INTERVENTION_HISTORY[language]}`

        return (
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <FilterHeader
                        language={language}
                        getData={fetchData}
                        generateExcel={download}
                    />
                </Grid>
                <Grid item xs={12} style={{position: "relative"}}>
                    <CollapsibleTable
                        language={language}
                        navigation={
                            {
                                rowsPerPage,
                                page: page,
                                setRowPerPage: onRowsPerPageChange,
                                setPage: (e, newPage) => setPage(newPage),
                                itemsCount: dataLength
                            }
                        }
                        tableTitle={lang.AREA_MANAGEMENT[language]}
                        collapsingDataKey="history"
                        tableHeader={area_management(language)}
                        data={data}
                        collapsingHeader={intervention_history_data_management(language)}
                        getCollapsingTableName={getCollapsingTableName}
                    />
                    {
                        loading && <div style={{
                            "top": "0",
                            "z-index": "10",
                            "width": "100%",
                            "height": "100%",
                            "position": "absolute",
                            "background-color": "#ffffff40",
                            "display": "flex",
                            "align-items": "center",
                            "justify-content": "center",
                        }}><CircularProgress disableShrink/></div>
                    }
                </Grid>
            </Grid>
        )
    }
;

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterventionManagement);
