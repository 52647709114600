import React, { useState, useEffect } from 'react';
import axios from "axios";

import GeneralModal from "../../../../Components/Modals/CustomModal";
import CloseFields from "./CloseFields";
import LoadPvImage from "./LoadPvModal";
import ObservationField from "./ObservationField";

import * as lang from "../../../../Constants/strings";
import * as requestHeaders from "../../../../Constants/requestHeaders";

const ActionModal = (props) => {
	const {
		open,
		close,
		execute,
		maintenance,
		loadPvImage = false,
		language,
		refetchTaskData,
		userId,
		onNotificationOpen,
		gps
	} = props;

	useEffect(() => {
		if (props.maintenance)
			setNextStatusId(getNextStatusId(props.maintenance.STATUS_ID));
	}, [props.maintenance]);

	const [nextStatusId, setNextStatusId] = useState(2);
	const [observation, setObservation] = useState("");

	const getNextStatusId = (status) => {
		switch (status) {
			case 1:
			case 4:
				return 2;
			case 2:
			case 3:
				return 5;
			case 5:
				return 6;
			case 6:
				return 8;
			default:
				return null;
		}
	};

	const getNextStatus = (status) => {
		switch (status) {
			case 1:
			case 4:
				return "OPEN";
			case 2:
			case 3:
				return "ON_DRIVE";
			case 5:
				return "STARTED";
			case 6:
				return "CLOSED";
			default:
				return "CLOSED";
		}
	};

	const nextStep = async () => {
		let data = {
			maintenanceId: maintenance.OBJID,
			note: observation,
			userId: userId,
			GPS: gps
		}
		try {
			await axios.post("/api/maintenance_next_step", data, requestHeaders.getPostHeader());
			refetchTaskData();
			setObservation("");
			onNotificationOpen("success", "DATA_UPDATE_SUCCESS")
		} catch (error) {
			console.error(error);
			onNotificationOpen("error", "ERROR_OCCURED")
		}
	}

	const doAction = () => {
		switch (nextStatusId) {
			case 8:
				close();
				break;
			default: {
				nextStep();
				close();
			}
		}
	}

	return (
		<GeneralModal
			open={open}
			close={close}
			fullWidth
			title={
				props.maintenance && props.maintenance.STATUS_ID ?
					lang[getNextStatus(props.maintenance.STATUS_ID)][language] :
					""
			}
			language={language}
			content={
				<>
					{
						nextStatusId !== 8 &&
						<ObservationField
							language={language}
							note={observation}
							updateNote={setObservation}
						/>
					}
					{
						nextStatusId === 8 && !loadPvImage &&
						<CloseFields
							maintenanceId={props.maintenance ? props.maintenance.OBJID : ""}
							language={language}
							userId={userId}
							refetchTaskData={() => {
								refetchTaskData();
								close();
							}}
						/>
					}
					{
						nextStatusId === 8 && loadPvImage &&
						<LoadPvImage />
					}
				</>
			}
			execute={doAction}
			formValidate={nextStatusId !== 8}
		/>
	)
};

export default ActionModal;