import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from '../../actions/actions';

import {
    Grid,
    TextField,
    MenuItem,
    Fab,
    Divider,
    Typography
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import TaskCommentHistory from "../../Components/Modals/TaskCommentHistory";
import CustomModal from '../../Components/Modals/CustomModal';
import Section from '../../Components/Section';
import { formatDateFromDb } from '../../Functions/dateFunctions';
import * as requestHeaders from '../../Constants/requestHeaders';
import * as lang from '../../Constants/strings';

const CashingValidationModal = (props) => {
    const {
        open,
        language,
        close,
        billId,
        lockAllFields,
        lockFields,
        reopen,
        userAuth
    } = props;

    const [firstLoad, setFirstLoad] = React.useState(true);
    // The user list
    const [userList, setUserList] = React.useState([]);
    // Store the bill type from db
    const [billTypes, setBillTypes] = React.useState([]);

    //  1 FREE   -- for the free option we need to get a user
    //  2 BILL   -- bill number
    //  3 TO_BILL  -- set the user that will bill the task

    // Form data
    const [selectedBillType, setSelectedBillType] = React.useState(1);
    const [selectedUser, setSelectedUser] = React.useState(1);

    // Bill Info
    const [billNo, setBillNo] = React.useState('');
    const [billDate, setBillDate] = React.useState(new Date());
    const [billSum, setBillSum] = React.useState('');

    // Is Payed
    const [isPayed, setIsPayed] = React.useState(false);
    const [isPartial, setIsPartial] = React.useState(false);

    // Pay method
    const [payMethod, setPayMethod] = React.useState("BANK");

    // PF RECIPE
    const [recipePf, setRecipePf] = React.useState('');
    const [recipePfDate, setRecipePfDate] = React.useState(new Date());

    // PJ RECIPE
    const [recipePj, setRecipePj] = React.useState('');
    const [recipePjDate, setRecipePjDate] = React.useState(new Date());

    // Bank Transfer
    const [bankDate, setBankDate] = React.useState(new Date());

    // Contractor List
    const [contractorList, setContractorList] = React.useState([]);
    const [selectedContractor, setSelectedContractor] = React.useState(32);

    // Partial payed sum
    const [partialSum, setPartialSum] = React.useState('');

    // Task Data
    const [taskReason, setTaskReason] = React.useState('');

    const [taskBillObj, setTaskBillObj] = React.useState(null);

    const [followRecipe, setFollowRecipe] = React.useState([]);

    const [isCompletedPay, setIsCompletedPay] = React.useState(false);

    const [lastNote, setLastNote] = React.useState("");
    const [commentsHistoryModal, setCommnetsHistoryModal] = React.useState(false);
    const [history, setHistory] = React.useState([]);
    // Effects

    // Initial Effect for fetching data
    React.useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            getUserList();
            getBillTypes();
            getContractor();
        }
    }, [props.open]);

    React.useEffect(() => {
        if (props.billId && props.open)
            getBillData(props.billId)
    }, [props.billId, props.open])

    // Functions
    const getBillTypes = () => {
        fetch('/api/get_task_billing_types', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.length > 0) {
                    setBillTypes(response.data);
                } else {
                    setBillTypes([]);
                }
            })
    };

    const getUserList = () => {
        fetch('/api/users', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.userList.length > 0) {
                    setUserList(response.data.userList);
                } else {
                    setUserList([]);
                }
            })
    };

    const editRecipeFollowUp = (name, index, value) => {
        let placeholder = [...followRecipe];
        placeholder[index][name] = value;
        setFollowRecipe(placeholder);
    }
    const newRecipeFollowUp = () => {
        let newRecipe = {
            RECEIPT_NO: "",
            RECEIPT_DATE: new Date(),
            RECEIP_NO_PF: "",
            RECEIP_DATE_PF: new Date(),
            BANK_TRANSFER_DATE: new Date(),
            PARTIAL_PAYED_SUM: "",
            payMethod: "PJ_CASH"
        };

        setFollowRecipe(followRecipe => [...followRecipe, newRecipe]);
    }

    const getBillData = async (id) => {
        const response = await axios.get(`/api/get_evaluation_bill_data`, {
            ...requestHeaders.getGetHeader(),
            params: {
                id: id
            }
        });

        if (response.status === 200) {
            if (response.data) {
                let billData = response.data.billData;
                let taskHistory = response.data.taskHistory;
                let followUpRecipe = response.data.followUpRecipe;

                if (followUpRecipe) {
                    setFollowRecipe(followUpRecipe);
                } else {
                    setFollowRecipe([]);
                }

                setTaskReason('Proiectare');
                setHistory(taskHistory);

                if (billData.USER_THAT_BILLS && billData.USER_THAT_BILLS !== null)
                    setSelectedUser(billData.USER_THAT_BILLS);
                if (billData.USER_APROVE_FREE && billData.USER_APROVE_FREE !== null)
                    setSelectedUser(billData.USER_APROVE_FREE);

                setTaskBillObj(billData.OBJID);
                setSelectedContractor(billData.FK2CONTRACTOR);
                setSelectedBillType(billData.FK2BILL_TYPE);
                if (billData.BILL_NO !== null) {
                    setBillNo(billData.BILL_NO);
                    setBillDate(billData.BILL_DATE);
                    setBillSum(billData.BILL_VALUE);
                } else {
                    setBillNo(null);
                    setBillDate(null);
                    setBillSum(null);
                }
                setIsPayed(billData.IS_PAYED === 1);
                setIsPartial(billData.IS_PARTIAL_PAYED === 1);

                if (billData.RECEIP_NO_PF !== null) {
                    setRecipePf(billData.RECEIP_NO_PF);
                    setRecipePfDate(new Date(billData.RECEIP_DATE_PF));
                    setPayMethod("BF");
                } else {
                    setRecipePf(null);
                    setRecipePfDate(null);
                }

                if (billData.RECEIPT_NO !== null) {
                    setRecipePj(billData.RECEIPT_NO);
                    setRecipePjDate(billData.BILL_DATE);
                    setPayMethod("RECIPE");
                } else {
                    setRecipePj(null);
                    setRecipePjDate(null);
                }
                if (billData.BANK_TRANSFER_DATE !== null) {
                    setPayMethod("BANK");
                    setBankDate(billData.BANK_TRANSFER_DATE);
                } else {
                    setBankDate(null);
                }

                if (billData.PARTIAL_PAYED_SUM !== null) {
                    setPartialSum(billData.PARTIAL_PAYED_SUM);
                }
                setIsCompletedPay(billData.COMPLETED_PAYED === 1 ? true : false);
            }
        }
    };

    const getContractor = async () => {
        const result = await axios.get('/api/get_executors', {
            ...requestHeaders.getGetHeader(),
            params: {
                active: 1,
                type: 3,
                subType: 4
            }
        });
        if (result.data.length > 0) {
            setContractorList(result.data)
        } else {
            setContractorList([]);
        }
    };

    const validateTaskBillCashing = () => {
        fetch('/api/validate_evaluation_bill_cashing', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify(
                {
                    FK2CONTRACTOR: selectedContractor,
                    FK2BILL_TYPE: selectedBillType,
                    BILL_NO: billNo,
                    BILL_DATE: billDate !== null ? formatDateFromDb(billDate, 15) : null,
                    BILL_VALUE: billSum,
                    RECEIPT_NO: recipePj,
                    RECEIPT_DATE: recipePjDate !== null ? formatDateFromDb(recipePjDate, 15) : null,
                    IS_PAYED: isPayed,
                    IS_PARTIAL_PAYED: isPartial,
                    USER_THAT_BILLS: selectedUser,
                    USER_APROVE_FREE: selectedUser,
                    RECEIP_NO_PF: recipePf,
                    RECEIP_DATE_PF: recipePfDate !== null ? formatDateFromDb(recipePfDate, 15) : null,
                    BANK_TRANSFER_DATE: bankDate !== null ? formatDateFromDb(bankDate, 15) : null,
                    payMethod: payMethod,
                    BILL_VALIDATION: true,
                    OBJID: taskBillObj,
                    PARTIAL_PAYED_SUM: partialSum,
                    followRecipe: followRecipe,
                    isCompletedPay: isCompletedPay,
                    userId: userAuth.id
                }
            )
        })
            .then(response => {
                if (response.status >= 400) {
                    console.error(response)
                }
                props.refeatchTaskData();
                return response.json();
            })
            .then(response => {
                console.log(response)
            })
    }

    return (
        <React.Fragment>
            <TaskCommentHistory
                open={commentsHistoryModal}
                tiny={true}
                classes={{}}
                data={history}
                language={language}
                close={() => {
                    setCommnetsHistoryModal(false);
                    reopen();
                }}
            />
            <CustomModal
                fullWidth
                open={open}
                title={lang.CASHING_VALIDATION[language]}
                content={
                    <React.Fragment>
                        <Grid container alignItems='center' alignContent="center" justify="center" spacing={2}>
                            <Typography variant="h4">{lang.TASK_DETAILS[language]}</Typography>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    variant='outlined'
                                    multiline
                                    name='reason'
                                    label={lang.TASK_REASON[language]}
                                    onChange={() => { }}
                                    value={taskReason}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockFields}
                                    required
                                    select
                                    variant='outlined'
                                    name='contractor'
                                    label={lang.CONTRACTOR[language]}
                                    onChange={(e) => setSelectedContractor(e.target.value)}
                                    value={selectedContractor}
                                    fullWidth
                                >
                                    {
                                        contractorList.map(contractor => {
                                            return <MenuItem key={contractor.OBJID} value={contractor.OBJID}>{contractor.NAME}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockFields}
                                    required
                                    select
                                    variant='outlined'
                                    name='BillType'
                                    label={lang.TYPE[language]}
                                    onChange={(e) => setSelectedBillType(e.target.value)}
                                    value={selectedBillType}
                                    fullWidth
                                >
                                    {
                                        billTypes.map(type => {
                                            return <MenuItem key={type.OBJID} value={type.OBJID}>{lang[type.BILLING_TYPE][language].toUpperCase()}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Section render={selectedBillType === 1 || selectedBillType === 3}>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={lockFields}
                                        required
                                        select
                                        variant='outlined'
                                        name='user'
                                        label={lang.USER[language]}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        value={selectedUser}
                                        fullWidth
                                    >
                                        {
                                            userList.map(user => {
                                                return <MenuItem key={user.USER_ID} value={user.USER_ID}>{user.USERNAME}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            </Section>
                            {/* BILL SECTION */}
                            <Section render={selectedBillType === 2}>
                                <Grid item xs={6}>
                                    <TextField
                                        disabled={lockFields}
                                        required
                                        variant='outlined'
                                        name='bill_no'
                                        type='number'
                                        label={lang.BILL_NO[language]}
                                        onChange={(e) => setBillNo(e.target.value)}
                                        value={billNo}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disabled={lockFields}
                                            disableToolbar
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            required
                                            id="date-picker-inline-1"
                                            label="Dată factură"
                                            value={billDate}
                                            inputValue={billDate ? moment(billDate).format("DD/MM/YYYY") : null}
                                            onChange={date => setBillDate(date, 'bill')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={lockFields}
                                        required
                                        variant='outlined'
                                        name='bill_sum'
                                        type='number'
                                        label={`${lang.BILL_SUM[language]} RON`}
                                        onChange={(e) => setBillSum(e.target.value)}
                                        value={billSum}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        required
                                        disabled={lockAllFields}
                                        variant='outlined'
                                        name='is_payed'
                                        label={lang.IS_PAYED[language]}
                                        onChange={(e) => setIsPayed(e.target.value)}
                                        value={isPayed}
                                        fullWidth
                                    >
                                        <MenuItem value={true}>{lang.YES[language].toUpperCase()}</MenuItem>
                                        <MenuItem value={false}>{lang.NO[language].toUpperCase()}</MenuItem>
                                    </TextField>
                                </Grid>
                                {/* The sub section for paied tasks */}
                                <Section render={isPayed}>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            required
                                            disabled={lockAllFields}
                                            variant='outlined'
                                            name='method_pay'
                                            label={lang.PARTIAL_PAYED[language]}
                                            onChange={(e) => setIsPartial(e.target.value)}
                                            value={isPartial}
                                            fullWidth
                                        >
                                            <MenuItem value={true}>{lang.YES[language].toUpperCase()}</MenuItem>
                                            <MenuItem value={false}>{lang.NO[language].toUpperCase()}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Section render={isPartial}>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Typography variant="h4">{lang.RECIPE_NR[language]} {1}</Typography>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={lockAllFields}
                                                required
                                                variant='outlined'
                                                name='partial_payed_sum'
                                                label={lang.PARTIAL_PAYED_SUM[language]}
                                                onChange={(e) => setPartialSum(e.target.value)}
                                                value={partialSum}
                                                fullWidth
                                                type="number"
                                            />
                                        </Grid>
                                    </Section>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            required
                                            disabled={lockAllFields}
                                            variant='outlined'
                                            name='method_pay'
                                            label={lang.PAY_METHOD[language]}
                                            onChange={(e) => setPayMethod(e.target.value)}
                                            value={payMethod}
                                            fullWidth
                                        >
                                            <MenuItem value={"RECIPE"}>{lang.CASH_PJ[language].toUpperCase()}</MenuItem>
                                            <MenuItem value={"BF"}>{lang.CASH_PF[language].toUpperCase()}</MenuItem>
                                            <MenuItem value={"BANK"}>{lang.BANK[language].toUpperCase()}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {/* Base on the pay method we have the next subsection */}
                                    <Section render={payMethod === 'RECIPE'}>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                disabled={lockAllFields}
                                                variant='outlined'
                                                name='bill_no'
                                                type='number'
                                                label={lang.RECIPE_NO[language]}
                                                onChange={(e) => setRecipePj(e.target.value)}
                                                inputValue={recipePj}
                                                value={recipePj}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    disabled={lockAllFields}
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    fullWidth
                                                    required
                                                    id="date-picker-inline-y"
                                                    label={lang.RECIPE_DATE[language]}
                                                    value={recipePjDate}
                                                    inputValue={recipePjDate ? moment(recipePjDate).format("DD/MM/YYYY") : null}
                                                    onChange={date => setRecipePjDate(date, 'bill')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Section>
                                    <Section render={payMethod === 'BANK'}>
                                        <Grid item xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    disabled={lockAllFields}
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    fullWidth
                                                    required
                                                    id="date-picker-inline-x"
                                                    label={lang.BANK_TRANSFER_DATE[language]}
                                                    value={bankDate}
                                                    inputValue={bankDate ? moment(bankDate).format("DD/MM/YYYY") : null}
                                                    onChange={date => setBankDate(date, 'bill')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Section>
                                    <Section render={payMethod === 'BF'}>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant='outlined'
                                                required
                                                disabled={lockAllFields}
                                                name='bill_no'
                                                type='number'
                                                label={lang.RECIPE_NO_PF[language]}
                                                onChange={(e) => setRecipePf(e.target.value)}
                                                value={recipePf}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    disabled={lockAllFields}
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    fullWidth
                                                    required
                                                    id="date-picker-inline-3"
                                                    label={lang.RECIPE_DATE_PF[language]}
                                                    value={recipePfDate}
                                                    inputValue={recipePfDate ? moment(recipePfDate).format("DD/MM/YYYY") : null}
                                                    onChange={date => setRecipePfDate(date, 'bill')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Section>
                                </Section>
                                <Section render={isPartial}>
                                    {
                                        followRecipe.map(recipe => {
                                            return <React.Fragment key={`fragment_${followRecipe.indexOf(recipe)}`}>
                                                <Grid item xs={12} key={`grid_1_${followRecipe.indexOf(recipe)}`}>
                                                    <Divider key={`name ${followRecipe.indexOf(recipe)}`} />
                                                </Grid>
                                                <Typography
                                                    variant="h4"
                                                    key={`ty_1_${followRecipe.indexOf(recipe)}`}
                                                >
                                                    {lang.RECIPE_NR[language]} {followRecipe.indexOf(recipe) + 2}
                                                </Typography>
                                                <Grid item xs={12} key={`grid_2_${followRecipe.indexOf(recipe)}`}>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={12} key={`grid_3_${followRecipe.indexOf(recipe)}`}>
                                                    <TextField
                                                        select
                                                        required
                                                        disabled={lockAllFields}
                                                        variant='outlined'
                                                        label={lang.PAY_METHOD[language]}
                                                        onChange={(e) => editRecipeFollowUp("payMethod", followRecipe.indexOf(recipe), e.target.value)}
                                                        value={recipe.payMethod}
                                                        fullWidth
                                                        key={`t_1_${followRecipe.indexOf(recipe)}`}
                                                    >
                                                        <MenuItem value={"PJ_CASH"}>{lang.CASH_PJ[language].toUpperCase()}</MenuItem>
                                                        <MenuItem value={"PF_CASH"}>{lang.CASH_PF[language].toUpperCase()}</MenuItem>
                                                        <MenuItem value={"BANK_TRANSFER"}>{lang.BANK[language].toUpperCase()}</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} key={`grid_4_${followRecipe.indexOf(recipe)}`}>
                                                    <TextField
                                                        disabled={!isPartial}
                                                        required
                                                        disabled={lockAllFields}
                                                        variant='outlined'
                                                        label={lang.PARTIAL_PAYED_SUM[language]}
                                                        onChange={(e) => editRecipeFollowUp("PARTIAL_PAYED_SUM", followRecipe.indexOf(recipe), e.target.value)}
                                                        value={recipe.PARTIAL_PAYED_SUM}
                                                        fullWidth
                                                        type="number"
                                                        key={`t_2_${followRecipe.indexOf(recipe)}`}
                                                    />
                                                </Grid>
                                                <Section render={recipe.payMethod === 'PJ_CASH'} key={`s_1_${followRecipe.indexOf(recipe)}`} >
                                                    <Grid item xs={6} key={`grid_5_${followRecipe.indexOf(recipe)}`}>
                                                        <TextField
                                                            required
                                                            disabled={lockAllFields}
                                                            variant='outlined'
                                                            type='number'
                                                            label={lang.RECIPE_NO[language]}
                                                            onChange={(e) => editRecipeFollowUp("RECEIPT_NO", followRecipe.indexOf(recipe), e.target.value)}
                                                            value={recipe.RECEIPT_NO}
                                                            fullWidth
                                                            key={`t_3_${followRecipe.indexOf(recipe)}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} key={`grid_6_${followRecipe.indexOf(recipe)}`}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} key={`qq_${followRecipe.indexOf(recipe)}`}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                disabled={lockAllFields}
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy"
                                                                fullWidth
                                                                required
                                                                id={`date-picker-inline-y ${followRecipe.indexOf(recipe)}`}
                                                                label={lang.RECIPE_DATE[language]}
                                                                value={recipe.RECEIPT_DATE}
                                                                inputValue={recipe.RECEIPT_DATE ? moment(recipe.RECEIPT_DATE).format("DD/MM/YYYY") : null}
                                                                onChange={date => editRecipeFollowUp("RECEIPT_DATE", followRecipe.indexOf(recipe), date)}
                                                                key={`t_4_${followRecipe.indexOf(recipe)}`}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                </Section>
                                                <Section render={recipe.payMethod === 'BANK_TRANSFER'} key={`grid_7_${followRecipe.indexOf(recipe)}`}>
                                                    <Grid item xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} key={`name ${followRecipe.indexOf(recipe)}`}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                disabled={lockAllFields}
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy"
                                                                fullWidth
                                                                required
                                                                id={`date-picker-inline-x-${followRecipe.indexOf(recipe)}`}
                                                                label={lang.BANK_TRANSFER_DATE[language]}
                                                                value={recipe.BANK_TRANSFER_DATE}
                                                                inputValue={recipe.BANK_TRANSFER_DATE ? moment(recipe.BANK_TRANSFER_DATE).format("DD/MM/YYYY") : null}
                                                                onChange={date => editRecipeFollowUp("BANK_TRANSFER_DATE", followRecipe.indexOf(recipe), date)}
                                                                key={`t_6_${followRecipe.indexOf(recipe)}`}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                </Section>
                                                <Section render={recipe.payMethod === 'PF_CASH'} key={`s_3_${followRecipe.indexOf(recipe)}`}>
                                                    <Grid item xs={6} key={`grid_8_${followRecipe.indexOf(recipe)}`}>
                                                        <TextField
                                                            variant='outlined'
                                                            required
                                                            disabled={lockAllFields}
                                                            type='number'
                                                            label={lang.RECIPE_NO_PF[language]}
                                                            onChange={(e) => editRecipeFollowUp("RECIPE_NO_PF", followRecipe.indexOf(recipe), e.target.value)}
                                                            value={recipe.RECEIP_NO_PF}
                                                            fullWidth
                                                            key={`t_7_${followRecipe.indexOf(recipe)}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} key={`grid_9_${followRecipe.indexOf(recipe)}`}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} key={`mui_${followRecipe.indexOf(recipe)}`}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                disabled={lockAllFields}
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy"
                                                                fullWidth
                                                                required
                                                                id="date-picker-inline-3"
                                                                label={lang.RECIPE_DATE_PF[language]}
                                                                value={recipe.RECEIP_DATE_PF}
                                                                inputValue={recipePfDate ? moment(recipePfDate).format("DD/MM/YYYY") : null}
                                                                onChange={date => editRecipeFollowUp("RECEIP_DATE_PF", followRecipe.indexOf(recipe), date)}
                                                                key={`t_8_${followRecipe.indexOf(recipe)}`}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                </Section>
                                            </React.Fragment>
                                        })
                                    }
                                    <Fab
                                        style={{ margin: '10px' }}
                                        color="primary"
                                        disabled={lockAllFields}
                                        aria-label="add"
                                        variant="extended"
                                        onClick={() => newRecipeFollowUp()}
                                    >
                                        {lang.ADD_RECIPE[language]}
                                    </Fab>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <TextField
                                        select
                                        variant='outlined'
                                        required
                                        disabled={lockAllFields}
                                        type='number'
                                        label={lang.COMPLETED_BILL_PAY[language]}
                                        onChange={(e) => setIsCompletedPay(e.target.value)}
                                        value={isCompletedPay}
                                        fullWidth
                                    >
                                        <MenuItem value={false}>NU</MenuItem>
                                        <MenuItem value={true}>DA</MenuItem>
                                    </TextField>
                                </Section>
                            </Section>
                        </Grid>
                    </React.Fragment>
                }
                close={close}
                execute={() => {
                    if (!lockAllFields) {
                        validateTaskBillCashing();
                    }
                    close();
                }}
                language={language}
                formValidate={!lockAllFields && isPayed}
                validate={!lockAllFields}
            />
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashingValidationModal);