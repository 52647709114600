import React from "react";
import {
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  Divider,
} from "@material-ui/core";
import * as lang from "../../../Constants/strings";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // marginTop: theme.spacing(2),
  },
}));

export default function MaintenaceNewForm(props) {
  const classes = useStyles();
  const { addData, language, updateField, updateFieldRaw, baseData } = props;

  const inputLabelType = React.useRef(null);
  const [labelWidthType, setLabelWidthType] = React.useState(35);
  const inputLabelContract = React.useRef(null);
  const [labelWidthContract, setLabelWidthContract] = React.useState(35);

  const [contractList, setContractList] = React.useState([]);

  function fetchFunction(url, setResult) {
    fetch(`/api/${url}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setResult(response);
      })
      .catch((err) => console.log(err));
  }

  React.useEffect(() => {
    if (contractList.length === 0)
      fetchFunction(
        `get_contracts_list_summary?id=${baseData.siteId}`,
        setContractList
      );
  }, [baseData.siteId]);

  return (
    <>
      <Grid item xs={12}>
        <FormControl
          // required
          fullWidth
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel ref={inputLabelContract}>
            {lang.CONTRACT[language]}
          </InputLabel>
          <Select
            name="CONTRACT_OBJID"
            labelWidth={labelWidthContract}
            value={addData.CONTRACT_OBJID ? addData.CONTRACT_OBJID : ""}
            onChange={(e) => updateFieldRaw("CONTRACT_OBJID", e.target.value)}
          >
            {contractList &&
              contractList.length > 0 &&
              contractList.map((item) => {
                return (
                  <MenuItem key={item.OBJID} value={item.OBJID}>
                    {`#${item.CONTRACT_NO} - ${lang[item.SUB_TYPE][language]} / ${moment(item.START_DATE).format("DD-MM-YYYY")} (${item.CONTRACTOR_NAME})`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          variant="outlined"
          name="REASON"
          multiline
          rowsMax="4"
          label={lang.REASON[language]}
          value={addData.REASON ? addData.REASON : ""}
          onChange={(e) => updateField(e, 2000)}
          className={classes.formControl}
        />
      </Grid>
    </>
  );
}

