import React from 'react';
import ContractNewForm from '../ContractNewForm';
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { Grid, useMediaQuery, useTheme, FormControl } from '@material-ui/core';

export default function SingleContractEdit(props) {
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const { siteId, contractID } = props;
    const [contractList, setContractList] = React.useState([]);

    React.useEffect(() => {
        if (siteId !== undefined)
            fetch(`/api/get_site_contracts?id=${siteId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    let contract = response.map(res => {
                        if (res.END_DATE === null)
                            res.INDETERMINATE = true
                        return res
                    })
                    setContractList(contract);
                })
                .catch(err => console.log(err))
    }, [siteId]);

    React.useEffect(() => {
        contractList.map(cont => {
            if (cont.OBJID === contractID) {
                props.setAddData({ ...cont })
            }
        })
    }, [contractID]);

    return (
        <React.Fragment>
            <CustomModal
                open={props.open !== false}
                title={lang.ADJUST_CONTRACT[props.language]}
                content={
                    <Grid container spacing={2}>
                        {props.open
                            ?
                            <ContractNewForm
                                {...props}
                                addData={props.addData}
                                tiny={tiny}
                                resetCloseComment={true}
                            />
                            : null
                        }
                    </Grid>
                }
                close={() => props.close()}
                execute={async () => {
                    await props.updateContract(contractID);
                    await props.close(false);
                    await props.reload();
                }}
                language={props.language}
                formValidate
                validate={true}
            />
        </React.Fragment>
    )
}
