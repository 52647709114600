import React from 'react';
import * as lang from '../../../../Constants/strings';
import { Grid, TextField } from '@material-ui/core';
import { PeopleAlt } from '@material-ui/icons';
import CustomModal from '../../../../Components/Modals/CustomModal';

// This modal is use to reset the oner of the task to the original one
export default function TaskResetModal(props) {
    const { open, note, updatenote, language, classes, resetTask, close } = props;
    return (
        <React.Fragment>
            <CustomModal
                fullWidth
                open={open}
                title={lang.CANCEL_ALOCATION[language]}
                content={
                    <Grid container spacing={2}>
                        <TextField
                            fullWidth
                            value={note}
                            onChange={updatenote}
                            label={lang.COMMENT[language]}
                            className={classes.textField}
                            variant='outlined'
                        />
                    </Grid>
                }
                validate={note !== ''}
                execute={() => resetTask()}
                close={close}
                language={language}
            />
        </React.Fragment>
    )
}
