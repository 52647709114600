import React, {useState} from "react";
import {CLOSE_MAINTENANCE_WITH_IMAGE} from "../../../Constants/strings";
import axios from "axios";
import {getPostHeader} from "../../../Constants/requestHeaders";
import CloseTaskWithImage from "../../../Components/Modals/CloseTaskWithImage";

/**
 * @param open boolean
 * @param close: function that will close the modal
 * @param maintenanceId: the id of the maintenance
 * @param refetch: function that reloads the maintenance data; use after the execute function
 * @param userId: the id of the current user
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CloseWithImage = (props) => {
    const [loading, setLoading] = useState(false);

    const closeMaintenance = async ({image, observation}) => {
        try {
            setLoading(true);
            await axios.post('/api/close_maintenance_with_image', {
                image,
                maintenanceId: props.maintenanceId,
                observation,
                userId: props.userId,
            }, getPostHeader());
            await props.refetch();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            props.close();
        }
    }

    return (
        <CloseTaskWithImage
            open={props.open}
            close={() => props.close()}
            title={CLOSE_MAINTENANCE_WITH_IMAGE[props.language] + " #" + props.maintenanceId}
            language={props.language}
            loading={loading}
            execute={closeMaintenance}
        />
    )
}

export default CloseWithImage;