import React, { useState } from "react";
import * as lang from "../../../../Constants/strings";

import {
    Grid,
    TextField
} from "@material-ui/core";

const ObservationField = (props) => {
    const {
        language,
        note,
        updateNote
    } = props;
    return (
        <>
            <Grid item xs={12}>
                <TextField
                    multiline
                    fullWidth
                    // required
                    variant="outlined"
                    name="OBSERVATION"
                    label={lang.OBSERVATION[language]}
                    value={note ? note : ""}
                    onChange={(e) => updateNote(e.target.value)}
                />
            </Grid>
        </>
    )
};

export default ObservationField;