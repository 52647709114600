import React from 'react';
import { ButtonGroup, Button, Grow } from '@material-ui/core';
import { MoreHorizRounded, StarRounded, ContactSupportOutlined } from '@material-ui/icons';
import CustomDrawer from '../Modals/CustomDrawer';

export default function GroupButtonSet(props) {
    const { style, list, tiny } = props;
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const drawerList = [];
    list.filter(e => (e.render === true) && (!e.primary)).forEach(e => {
        drawerList.push({
            primary: e.text,
            secondary: "",
            icon: e.icon ? e.icon : <StarRounded />,
            action: e.action,
            render: e.render,
            disabled: e.style && e.style.disabled ? e.style.disabled : false,
        })
    });

    return (
        <React.Fragment>
            <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                <ButtonGroup
                    style={{}}
                    fullWidth={style && style.fullWidth !== undefined ? style.fullWidth : false}
                    size={style && style.size ? style.size : "medium"}
                    color={style && style.color ? style.color : "default"}
                    variant={style && style.variant ? style.variant : "outlined"}
                    disabled={style && style.disabled !== undefined ? style.disabled : false}
                >
                    {list.filter(e => (e.render === true) && (e.primary || !tiny)).map((e, index) =>
                        <Button
                            key={index}
                            style={{ width: 'auto' }}
                            color={e.primary && tiny ? "primary" : e.style && e.style.color ? e.style.color : (style && style.color ? style.color : "default")}
                            variant={e.primary && tiny ? "contained" : e.style && e.style.variant ? e.style.variant : (style && style.variant ? style.variant : "outlined")}
                            onClick={e.action}
                            disabled={e.style && e.style.disabled ? e.style.disabled : false}
                        >
                            {index === 0 && e.leftIcon}
                            {e.text}
                            {index === list.filter(e => (e.render === true) && (e.primary || !tiny)).length - 1 && e.rightIcon}
                        </Button>
                    )}
                    {drawerList.length > 0 && tiny &&
                        // <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                        <Button
                            color="default"
                            variant="contained"
                            style={{ width: 'auto' }}
                            onClick={() => setDrawerOpen(true)}
                        >
                            <MoreHorizRounded />
                        </Button>
                    }
                </ButtonGroup>
            </Grow>

            {
                true &&
                <CustomDrawer
                    open={drawerOpen && tiny}
                    close={() => setDrawerOpen(false)}
                    list={drawerList}
                />
            }
        </React.Fragment>
    );
}