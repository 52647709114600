export * from "./strings/clientsStrings";
export * from "./strings/addressString";
export * from "./strings/siteString";
export * from "./strings/taskString";
export * from "./strings/reportingString";
export * from "./strings/formStrings";
export * from "./strings/interventionString";
export * from "./strings/emailString";
export * from "./strings/updatedStringsAlarm";
export * from "./strings/billingString";
export * from "./strings/templateFilesString";
export * from "./strings/maintenanceStrings";
export * from "./strings/accounting";
export * from "./strings/projects";
export * from "./strings/riscEvaluation";
export * from "./strings/installation";
export * from "./strings/technicalTeams";

export const DUPLICATE_ALARM = {
  en: "There is a duplicate!",
  ro: "Exista o alarma deschis pe acest obiectiv!"
}
export const TEXT = {
  en: "Text",
  ro: "Text"
};
export const NEW = {
  en: "New",
  ro: "Nou",
};
export const QUICK_SETTINGS = {
  en: "Quick settings",
  ro: "Setări rapide",
};
export const SIGN_IN = {
  en: "Sign In",
  ro: "Autentificare",
};
export const EMAIL = {
  en: "Email",
  ro: "Email",
};
export const PASSWORD = {
  en: "Password",
  ro: "Parolă",
};
export const USERNAME = {
  en: "Username",
  ro: "Utilizator",
};
export const REMEMBER_ME = {
  en: "Remember me",
  ro: "Rămâi autentificat",
};
export const ENGLISH_LANGUAGE = {
  en: "english",
  ro: "engleză",
};
export const ROMANIAN_LANGUAGE = {
  en: "romanian",
  ro: "română",
};
export const HOME = {
  en: "Home",
  ro: "Acasă",
};
export const REPORTS = {
  en: "Reports",
  ro: "Rapoarte",
};
export const PROFILE = {
  en: "Profile",
  ro: "Profil",
};
export const LOGOUT = {
  en: "Logout",
  ro: "Deconectare",
};
export const MY_PROFILE = {
  en: "My Profile",
  ro: "Profilul meu",
};
export const DASHBOARD = {
  en: "Dashboard",
  ro: "Administrare",
};
export const EDIT = {
  en: "Edit",
  ro: "Editează",
};
export const EDIT_PROFILE = {
  en: "EDIT PROFILE",
  ro: "EDITEAZĂ PROFILUL",
};
export const SAVE = {
  en: "Save",
  ro: "Salvează",
};
export const ACOUNT_DETAILS = {
  en: "ACOUNT DETAILS",
  ro: "DETALII CONT",
};
export const PERSONAL_DETAILS = {
  en: "PERSONAL DETAILS",
  ro: "DETALII PERSONALE",
};
export const ASSIGNED_WORK = {
  en: "ASSIGNED WORK",
  ro: "IN LUCRU",
};
export const FIRST_NAME = {
  en: "First name",
  ro: "Prenume",
};
export const LAST_NAME = {
  en: "Last name",
  ro: "Nume",
};
export const FULL_NAME = {
  en: "Full name",
  ro: "Nume complet",
};
export const ROLE = {
  en: "Role",
  ro: "Rol",
};
export const DETAILS = {
  en: "Details",
  ro: "Detalii",
};
export const RECENT_ACTIVITY = {
  en: "RECENT ACTIVITY",
  ro: "ACTIVITATE RECENTĂ",
};
export const NO_RESULT = {
  en: "No results...",
  ro: "Nici un rezultat...",
};
export const BILLED = {
  en: "Billed",
  ro: "Facturat",
};
export const PHONE = {
  en: "Phone",
  ro: "Telefon",
};
export const PHONE_PERSONAL = {
  en: "Personal phone",
  ro: "Telefon personal",
};
export const PHONE_WORK = {
  en: "Work phone",
  ro: "Telefon serviciu",
};
export const BIRTHDAY = {
  en: "Birthday",
  ro: "Zi de naștere",
};
export const GENDER = {
  en: "Gender",
  ro: "Gen",
};
export const MALE = {
  en: "Male",
  ro: "Masculin",
};
export const FEMALE = {
  en: "Female",
  ro: "Feminin",
};
export const NEW_AVATAR = {
  en: "Change avatar",
  ro: "Alege un avatar",
};
export const CHANGE_PASSWORD = {
  en: "Change password",
  ro: "Schimbă parola",
};
export const RESET_PASSWORD = {
  en: "Reset password",
  ro: "Resetează parola",
};
export const UPDATE_PASSWORD_PRIMARY = {
  en: "Update your password",
  ro: "Actualizează parola",
};
export const UPDATE_PASSWORD_SECONDARY = {
  en: "Please update your password.",
  ro: "Te rugăm să îți actualizezi parola.",
};
export const UPDATE = {
  en: "Update",
  ro: "Actualizează",
};
export const FORCE_LOGOUT = {
  en: "Log out",
  ro: "Delogare",
};
export const BACK = {
  en: "Back",
  ro: "Înapoi",
};
export const FINISH = {
  en: "Finish",
  ro: "Finalizează",
};
export const OLD_PASSWORD = {
  en: "Old password",
  ro: "Parola curentă",
};
export const NEW_PASSWORD = {
  en: "New password",
  ro: "Parola nouă",
};
export const NEW_PASSWORD2 = {
  en: "Confirm password",
  ro: "Confirmă parola",
};
export const EMPTY_FIELD = {
  en: "This field can't be empty",
  ro: "Câmpul nu poate fi gol",
};
export const SHORT_FIELD = {
  en: "Use at least 8 characters",
  ro: "Minim 8 caractere",
};
export const SPECIAL_CHAR = {
  en: "Illegal characters detected!",
  ro: "Câmpul conține caractere nepermise!",
};
export const USER_DUPLICATE = {
  en: "User already exists",
  ro: "User deja existent",
};
export const SAME_FIELD = {
  en: "Chose a different password",
  ro: "Alegeți o parolă diferită",
};
export const CONFIRM_FIELD = {
  en: "Can't confirm password",
  ro: "Confirmați parola",
};
export const PASSWORD_UPDATE_SUCCESS = {
  en: "Password updated successfully!",
  ro: "Parolă actualizată cu success!",
};
export const EXIPIRED_SESSION = {
  en: "Session expired!",
  ro: "Sesiunea a expirat!",
};
export const CONFIRM_PASSWORD = {
  en: "Please confirm your password to resume your session.",
  ro: "Te rugăm să confirmi parola pentru a continua sesiunea.",
};
export const ADD_ROLE = {
  en: "New role",
  ro: "Rol nou",
};
export const EDIT_ROLE = {
  en: "Edit role",
  ro: "Editare rol",
};
export const MIN_THREE_CHAR = {
  en: "Use at least 3 characters",
  ro: "Cel puțin 3 caractere",
};
export const UPDATE_ROLE = {
  en: "Updating role",
  ro: "Actualizare rol",
};
export const CONFIRM_RESET_PASSWORD = {
  en: "Are you sure you want to reset this user's password?",
  ro: "Ești sigur că vrei să resetezi parola acestui utilizator?",
};
export const CONFIRM_UPDATE_ROLE = {
  en: "Are you sure you want to update the role of this user?",
  ro: "Ești sigur că vrei să actualizezi rolul acestui utilizator?",
};
export const CONFIRM_UPDATE_DEPARTMENT = {
  en: "Are you sure you want to update the department of this user?",
  ro: "Ești sigur că vrei să actualizezi departamentul acestui utilizator?",
};

export const ROWS_PER_PAGE = {
  en: "Rows per page:",
  ro: "Rânduri:",
};
export const USER_TABLE = {
  en: "Users",
  ro: "Utilizatori",
};
export const TYPE = {
  en: "Type",
  ro: "Tip",
};
export const ADDRESS_TABLE = {
  en: "Addresses",
  ro: "Adrese",
};
export const USER = {
  en: "USER",
  ro: "UTILIZATOR",
};
export const SEARCH = {
  en: "Search",
  ro: "Căutare",
};
export const ADD_USER = {
  en: "Add user",
  ro: "Adaugă utilizator",
};
export const UNLOCK_USER = {
  en: "Unlock account",
  ro: "Deblochează contul",
};
export const LOCK_USER = {
  en: "Lock account",
  ro: "Blochează contul",
};
export const CONFIRM_UNLOCK_USER = {
  en: "Are you sure you want to unblock this account?",
  ro: "Ești sigur că vrei să deblochezi acest cont?",
};
export const CONFIRM_LOCK_USER = {
  en: "Are you sure you want to block this account?",
  ro: "Ești sigur că vrei să blochezi acest cont?",
};
export const EXEC_NAME = {
  en: "Business Name",
  ro: "Nume Firmă",
};
export const USERS = {
  en: "Users",
  ro: "Utilizatori",
};
export const EDIT_EXEC = {
  en: "Edit executor",
  ro: "Editare firmă",
};
export const ADD_EXEC = {
  en: "Add executor",
  ro: "Adăugare firmă",
};
export const EXEC_TABLE = {
  en: "Executors",
  ro: "Firme contractoare",
};
export const EXEC_NAME2 = {
  en: "Executor",
  ro: "Firmă contractoare",
};
export const ROLE_TABLE = {
  en: "Roles",
  ro: "Roluri",
};
export const OPTIONS = {
  en: "Options",
  ro: "Opțiuni",
};
export const IS_DEFAULT = {
  en: "Default",
  ro: "Implicit",
};
export const AREA_TABLE = {
  en: "Areas",
  ro: "Zone",
};
export const ADD_AREA = {
  en: "Add area",
  ro: "Adăugare zonă",
};
export const EDIT_AREA = {
  en: "Edit area",
  ro: "Editare zonă",
};
export const AREA_NAME = {
  en: "Area name",
  ro: "Denumire zonă",
};
export const DEPARTMENT_NAME = {
  en: "Department name",
  ro: "Denumire departament",
};
export const DEPARTMENT = {
  en: "Department",
  ro: "Departament",
};
export const ADD_DEPARTMENT = {
  en: "Add department",
  ro: "Adăugare departament",
};
export const EDIT_DEPARTMENT = {
  en: "Edit department",
  ro: "Editare departament",
};
export const UPDATE_DEPARTMENT = {
  en: "Updating department",
  ro: "Actualizare departament",
};
export const DEPARTMENT_TABLE = {
  en: "Departments",
  ro: "Departamente",
};
export const SITES = {
  en: "Sites",
  ro: "Obiective",
};
export const SITE_CONTACT_NAME = {
  en: "Contact",
  ro: "Contact",
};
export const TOGGLE_EXECUTOR = (active) => {
  return {
    en: active ? "Lock executor" : "Unlock executor",
    ro: active ? "Dezactivează firma" : "Activează firma",
  };
};
export const TOGGLE_ACTION = (active) => {
  return {
    en: active ? "Lock" : "Unlock",
    ro: active ? "Dezactivează" : "Activează",
  };
};
export const STATUS = {
  en: "Status",
  ro: "Status",
};
export const ROWS_FUNC = {
  en: ({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count}`,
  ro: ({ from, to, count }) => `${from}-${to === -1 ? count : to} din ${count}`,
};
export const GO_TO = (param) => {
  return {
    en: `Go to ${param}`,
    ro: `Vezi ${param}`,
  };
};

export const FILTERS = {
  en: "Filters",
  ro: "Filtrare",
};
export const ADD_FILTER = {
  en: "Add filter",
  ro: "Adaugă filtru",
};

// SERVER MESSAGES

export const INTERNAL_SERVER_ERROR = (param) => {
  return {
    en: `${param} Internal Server Error`,
    ro: `${param} Internal Server Error`,
  };
};
export const ERROR_OCCURED = {
  en: "Error Occured!",
  ro: "A apărut o eroare!",
};
export const LOG_OUT = {
  en: "You have been logged out!",
  ro: "Ai fost deconectat!",
};
export const WRONG_PASSWORD = {
  en: "User and password do not match!",
  ro: "Ai greșit parola!",
};
export const WRONG_USER = {
  en: "User does not exists!",
  ro: "Utilizatorul nu există!",
};
export const LOG_FAIL = {
  en: "Failed to log activity!",
  ro: "Activitatea nu a putut fi înregistrată!",
};
export const LOCKED = {
  en: "The account is deactivated!",
  ro: "Contul a fost dezactivat!",
};
export const SIGN_IN_SUCCESS = {
  en: "You have been successfully logged in!",
  ro: "Ai fost autentificat cu succes!",
};
export const DATA_RECIEVE_SUCCESS = {
  en: "Data fetched successfully!",
  ro: "Informații extrase cu succes!",
};
export const DATA_UPDATE_SUCCESS = {
  en: "Data updated successfully!",
  ro: "Actualizarea a reușit!",
};
export const VALUE_EXISTS = {
  en: "This value exists already!",
  ro: "Acest articol există deja!",
};
export const CUSTOMER_EXISTS = {
  en: "This customer exists already!",
  ro: "Acest client există deja!",
};
export const OBJECTIVES = {
  en: "Objectives",
  ro: "Obiective",
};
export const SEARCH_RESULT = {
  en: "Search result",
  ro: "Rezultate căutare",
};
export const VERIFIED = {
  en: "Verified",
  ro: "Validat",
};
export const SITE_SEARCH = {
  en: "Site search",
  ro: "Căutare obiectiv",
};
export const CUSTOMER_SEARCH = {
  en: "Customer search",
  ro: "Căutare client",
};
export const QUICK_CUSTOMER_ADD = {
  en: "Quick customer add",
  ro: "Adăugare rapidă client și obiectiv",
};
export const ACTIVE = {
  en: "Active",
  ro: "Activ",
};
export const ACTIVE_TASK_EXIST_ON_SITE = {
  en: "Active task currently open for this site!",
  ro: "Există un ticket activ pentru acest obiectiv!",
};
export const USER_WAS_BLOCK = {
  en: "The user was block!",
  ro: "Utilizatorul a fost blocat!",
};
export const USER_WAS_UNBLOCK = {
  en: "The user was unblock!",
  ro: "Utilizatorul a fost deblocat!",
};
export const EDIT_CONTRACTS = {
  en: "Edit contract",
  ro: "Editare contract",
};
export const VIEW_CONTRACT = {
  en: "View contract",
  ro: "Vizualizare contract",
};
export const RESET = {
  en: "Reset",
  ro: "Resetează",
};
export const END_DATE = {
  en: "End date",
  ro: "Dată finalizare",
};

export const ISNTALED_EQUIPMENT = {
  en: "Instaled equipment",
  ro: "Echipamente instalate",
};

export const BILLING_ASSIGNEE = {
  en: "Billing assignee",
  ro: "Modalitate transmitere factură",
};

export const DISSOLUTION_DATE = {
  en: "Dissolution date",
  ro: "Dată reziliere",
};

export const CLOSE_COMMENT = {
  en: "Close comment",
  ro: "Motiv reziliere",
};

export const SIGNATURE_DATE = {
  en: "Signature date",
  ro: "Data întocmirii contractului",
};

export const BILLING = {
  en: "Billing",
  ro: "Facturare",
};

export const BILL = {
  en: "Bill",
  ro: "Factură",
};

export const FREE = {
  en: "Free",
  ro: "Aprobat gratuit",
};

export const TO_BILL = {
  en: "To bill",
  ro: "De facturat"
};

export const BILL_VALIDATION = {
  en: "Bill validation",
  ro: "Verificare factură",
};

export const RECEIPT_VALIDATION = {
  en: "Receipt validation",
  ro: "Verificare încasare",
};

export const SYSTEM_LOGING_INFO = {
  en: "System loging information",
  ro: "Informații logare sistem",
};

export const PHONE_WARNING = {
  en: "Phone warning",
  ro: "Înștiințări telefonice",
};

export const ADD_PHONE_WARNING = {
  en: "Add phone warning",
  ro: "Adăugare înștiințare telefonică",
};

export const SEND = {
  en: "Send",
  ro: "Trimis",
};

export const NOT_SEND = {
  en: "Not send",
  ro: "Nu a fost trimis",
};

export const SITE_TYPE_TABLE = {
  en: "Site types",
  ro: "Tipuri obiective",
};
export const SITE_TYPE = {
  en: "Site type",
  ro: "Tip obiectiv",
};
export const SITE_AREA_TABLE = {
  en: "Site areas",
  ro: "Zone obiectiv",
};
export const SITE_AREA_NAME = {
  en: "Area name",
  ro: "Nume zonă",
};
export const SITE_AREA_CODE = {
  en: "Area code",
  ro: "Cod zonă",
};
export const SELECT_USER_INTERVENTION_ASSIGN_AREA = {
  en: "Select user assign area",
  ro: "Alege zonele atribuite pentru intervenții",
};
export const YEAR = {
  en: "Year",
  ro: "An",
};
export const MONTH = {
  en: "Month",
  ro: "Lună",
};
export const ACTION_INVOICE = {
  en: "Generate invoice appendix",
  ro: "Generează anexă factură",
};
export const ACTION_INVOICE_ADJUST = {
  en: "Adjust invoice appendix",
  ro: "Ajustează anexă factură",
};
export const DISCOUNT = {
  en: "Discount",
  ro: "Reducere",
};
export const INVOICE_TOTAL = {
  en: "Invoice total",
  ro: "Total factură",
};
export const DOWNLOAD_FILE = {
  en: "Download file",
  ro: "Descarcă fișier",
};
export const DOWNLOAD_INVOICE_APPENDIX = {
  en: "Download invoice appendix",
  ro: "Descarcă anexă factură",
};
export const NONE = {
  en: "None",
  ro: "Niciuna",
};
export const CONTRACT_DETAILS = {
  en: "Contract details",
  ro: "Detali contract",
};
export const UPLOADED_FILE = {
  en: "Uploaded file",
  ro: "Fișier încărcat",
};
export const YES = {
  en: "Yes",
  ro: "Da",
};
export const NO = {
  en: "No",
  ro: "Nu",
};
export const FILE_TYPE = {
  en: "FILE TYPE",
  ro: "Tip de fișier",
};
export const FILE_UPLOAD = {
  en: "File upload",
  ro: "Încărcare fișier",
};
export const FILTER = {
  en: "Filter",
  ro: "Filtrează",
};
export const RESET_CLOSE_COMMENT = {
  en: "Clear close comment",
  ro: "Șterge motiv reziliere",
};
export const EMPLOYEE_NAME = {
  en: "Employee name",
  ro: "Nume angajat",
};

export const RESET_ALOCATION = {
  en: "Reset allocation",
  ro: "Resetare alocare"
};

export const SERVICES = {
  en: "Services",
  ro: "Servicii"
};

export const PERMIT = {
  en: "Permit",
  ro: "Aviz politie"
};

export const PERMIT_NR = {
  en: "Permit number",
  ro: "Nr. Aviz politie"
};

export const PERMIT_DATE = {
  en: "Permit date",
  ro: "Data aviz"
};

export const PROJECT = {
  en: "Project",
  ro: "Proiect"
};
export const PROJECTS = {
  en: "Projects",
  ro: "Proiecte"
};

export const PROJECT_DATE = {
  en: "Project date",
  ro: "Data proiectului",
};

export const PROJECT_NUMBER = {
  en: "Project number",
  ro: "Nr. proiect"
};

export const PROJECT_CONTRACTOR = {
  en: "Project contractor",
  ro: "Firma proiectanta"
};

export const EVALUATION = {
  en: "Evaluation",
  ro: "Evaluare de risc"
};

export const EVALUATION_NUMBER = {
  en: "Evaluation number",
  ro: "Nr. evaluare de risc"
};

export const EVALUATION_CREATION_DATE = {
  en: "Evaluation creation date",
  ro: "Data evaluării"
};

export const EVALUATION_EXPIRATION_DATE = {
  en: "Evaluation expiration date",
  ro: "Data expirării"
};

export const EVALUATION_CONTRACTOR = {
  en: "Evaluation contractor",
  ro: "Firma evaluatoare"
};

export const CONTRACT_TYPE_SERVICES = {
  en: "Services",
  ro: "Servicii"
};

export const FILTER_BY = {
  en: "Filter by : ",
  ro: "Filtrează : "
};

export const AREA_MANAGEMENT = {
  en: "Area Management",
  ro: "Gestiune zone"
};

export const VALUE = {
  en: "Value",
  ro: "Valoare"
};

export const WAS_BILL = {
  en: "Was bill",
  ro: "A fost facturat!"
};

export const COMMENT_PROJECT_HISTORY = {
  en: "Comment project history",
  ro: "Vei comentariile proiectului"
};

export const SIGN_PV = {
  en: "Sign pv",
  ro: "Semnare proces verbal predare"
};

export const SIGN_PV_t = {
  en: "Sign pv",
  ro: "Semnare proces verbal predare"
};

export const ADD_DATA_t = {
  en: "Add data",
  ro: "Adăugare de date"
}

export const NEW_INSTALLATION = {
  en: "New installation",
  ro: "Instalare nouă"
}

export const NEW_PROJECT = {
  en: "New project",
  ro: "Proiectare nouă"
}

export const NEW_MAINTENANCE = {
  en: "New maintenance",
  ro: "Mentenanță nouă"
}

export const NEW_PHONE_NOTIFICATION = {
  en: "New phone notification",
  ro: "Înștiințare telefonică nouă"
}

export const NEW_RISK_ASSESSMENT = {
  en: "New risk assessment",
  ro: "Analiză de risc nouă"
}

export const PROJECT_HISTORY = {
  en: "Project history",
  ro: "Istoric proiecte"
}

export const RISC_EVALUATIONS_HISTORY = {
  en: "Risc evaluation history",
  ro: "Istoric evaluari de risc"
};

export const INTERVENTION_CYCLE = {
  ro: "Ciclu de intervenție",
  en: "Intervation cycle"
}

export const ADD_SITE_IMAGE_MODAL_TITLE = {
  ro: "Adăugare imagine obiectiv",
  en: "Add site image"
}

export const SITE_HAS_NO_IMAGE_NOTIFICATION = {
  ro: "Obiectivul nu are imagine",
  en: "The site has no image"
}

export const UPLOAD_SITE_IMAGE_CALL_TO_ACTION = {
  ro: "Încarcă imagine",
  en: "Upload Image"
}

export const GO_TO_THE_SITE_IMAGE_ACTION = {
  ro: "Vezi poza obiectivului",
  en: "Seed the site photo"
}

export const GO_TO_THE_SITE_IMAGE = {
  ro: "Poză obiectiv",
  en: "Site photo"
}

export const SELECT_CONTRACTOR = {
  ro: "Selecteză compania",
  en: "Select the company"
}

export const RESET_FILTER = {
  ro: "Resetează filtre",
  en: "Reset the filters"
}

export const SELECT_EXTERNAL_ACCOUNT = {
  ro: "Selecteaza cont",
  en: "Select account"
}

export const HAS_ACCOUNT_ID = {
  ro: "Cont Inventar",
  en: "Account ID"
}
