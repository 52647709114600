import React from 'react';
import { Grid, TextField, ButtonGroup, Button, Grow, Typography } from '@material-ui/core';
import Comment from './Comment';
import * as lang from '../../../Constants/strings';
import { ReplyRounded, AddRounded, AttachFile } from '@material-ui/icons';

export default function CommentList(props) {
    const { language, tiny, data, classes, task, isIntervention } = props;
    const [note, setNote] = React.useState("");
    const [show, setShow] = React.useState(false);

    function handleChange(e) {
        const { value } = e.target;
        setNote(value);
    }

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.divider}>
                <Typography variant={tiny ? "h6" : "h5"}>{lang.HISTORY[language]}</Typography>
            </Grid>
            {data !== undefined && data.length >= 0 ?
                data.map((item, index) =>
                    <Comment isIntervention={isIntervention} classes={classes} key={index} data={item} language={language}
                        downloadFile={props.downloadFile} tiny={tiny} />
                )
                :
                <p>Nici un comentariu</p>
            }
            {show &&
                <Grid item xs={12}>
                    <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                        <TextField
                            fullWidth
                            multiline
                            className={classes.textField}
                            variant="outlined"
                            label={lang.COMMENT[language]}
                            value={note}
                            onChange={handleChange}
                        />
                    </Grow>
                </Grid>
            }
            <Grid item xs={12}>
                <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                    {show
                        ?
                        <ButtonGroup
                            className={classes.buttonGroup}
                            color="primary"
                        // variant="contained"
                        >
                            <Button
                                // style={{ width: 'auto' }}
                                color="secondary"
                                variant="contained"
                                onClick={() => { setShow(false); setNote(""); }}
                            >
                                {lang.CANCEL_REPLY[language]}
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                // style={{ marginLeft: 10, marginRight: 10 }}
                                onClick={() => props.openFileUploadModal()}
                            >
                                {lang.FILE_UPLOAD[props.language]}
                                <AttachFile className={classes.leftIcon} />
                            </Button>
                            <Button
                                // style={{ width: 'auto' }}
                                color="primary"
                                variant="contained"
                                onClick={() => { task.newTaskComment(1, task.data.task.STATUS_ID, task.data.task.STATUS_ID, note, props.uploadedFile); setShow(false); setNote(""); }}
                            >
                                {lang.REPLY[language]}
                                <ReplyRounded className={classes.iconRight} />
                            </Button>
                        </ButtonGroup>
                        :
                        <Button
                            className={classes.buttonGroup}
                            color="primary"
                            variant="contained"
                            onClick={() => setShow(true)}
                        >
                            <AddRounded className={classes.leftIcon} />
                            {lang.REPLY[language]}
                        </Button>
                    }
                </Grow>
            </Grid>
        </React.Fragment>
    );
}