import React, { Component } from 'react';
import { Grid, CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTableDemo from '../../../Components/Misc/Tables/MaterialTableSimple';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { siteTypeNom } from '../../../Components/Misc/Tables/TableHeaders'
import * as lang from '../../../Constants/strings';
import CustomModal from '../../../Components/Modals/CustomModal';
import TableSkeleton from '../Skeleton';
// import AreaNewForm from './AreasNomComponents/AreasNewForm';
// import AreaEditModal from './AreasNomComponents/AreaEditModal';
import axios from 'axios';
import SiteTypeNewForm from './SiteTypeNomComponents/SiteTypeNewForm';
import EditModal from './SiteTypeNomComponents/SiteTypeEditModal';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}));

function SiteTypeNomSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.SITE_TYPE_TABLE[props.language]}
                    language={props.language}
                    data={props.data}
                    rowsPerPage={props.rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={siteTypeNom(props.language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="ID"
                />
            </Grid>
        </Grid>
    );
}

class SiteTypeNom extends Component {
    state = {
        addModal: false,
        editModal: false,
        selectedIndex: null,
        error: null,
        addData: {
            SITE_TYPE: "",
        }
    }
    componentDidMount() {
        this.props.actions.getSiteTypeList(0);
    };

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    fullWidth
                    open={this.state.addModal}
                    title={lang.ADD_AREA[this.props.language]}
                    content={
                        <SiteTypeNewForm
                            updateField={(e, length) => this.setState({ addData: { ...this.state.addData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                            addData={this.state.addData}
                            language={this.props.language}
                            error={this.state.error}
                            updateError={(value) => this.setState({ error: value })}
                            open={this.state.addModal}
                        />
                    }
                    close={() => {
                        this.setState({
                            addModal: false,
                        })
                    }}
                    execute={() => {
                        this.props.actions.addNewSiteType(this.props.userAuth.id, this.state.addData);
                        console.log(this.state.addData);
                        this.setState({ addModal: false })
                    }}
                    validate={this.state.error === false}
                    language={this.props.language}
                />

                <EditModal
                    authID={this.props.userAuth.id}
                    language={this.props.language}
                    selectedIndex={this.state.selectedIndex}
                    updateExecutor={(data) => this.props.actions.updateSiteType(this.props.userAuth.id, { ...data, selectedIndex: this.state.selectedIndex })}
                    toggleExecutor={(data) => this.props.actions.toggleSiteType(this.props.userAuth.id, data)}
                    close={() => this.setState({ selectedIndex: null })}
                />

                {this.props.isFetchingSiteTypeList === false
                    ?
                    <SiteTypeNomSetup
                        language={this.props.language}
                        data={this.props.siteTypeList}
                        rowsPerPage={this.props.rowsPerPage}
                        onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
                        openAddModal={() => {
                            this.setState(
                                {
                                    addModal: true,
                                    addData: {
                                        SITE_TYPE: ""
                                    },
                                    error: null,
                                })
                        }
                        }
                        openRowModal={(value) => this.setState({ selectedIndex: value })}
                    />
                    :
                    <TableSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,

        siteTypeList: state.nom.siteTypeList,
        isFetchingSiteTypeList: state.nom.isFetchingSiteTypeList,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteTypeNom);