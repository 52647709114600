import React, { Component } from 'react';
import { CssBaseline, Grid, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as lang from '../../Constants/strings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';
import ProfileWork, { SkeletonProfileWork } from './ProfileComponents/ProfileWork';
import ProfileDetails, { SkeletonProfileDetails } from './ProfileComponents/ProfileDetails';
import ProfileMain, { SkeletonProfileMain } from './ProfileComponents/ProfileMain';
import ProfileEdit from './ProfileComponents/ProfileEdit';
import ProfileEditSecond from './ProfileComponents/ProfileEditSecond';
import ProfileRecent, { SkeletonProfileRecent } from './ProfileComponents/ProfileRecent';
import { extractQueryFrontend } from '../../Functions/stringManipulation';
import CustomModal from '../../Components/Modals/CustomModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RoleNewForm from '../../Components/Misc/Forms/RoleNewForm';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    bigAvatar: {
        margin: theme.spacing(-1.3),
        width: 150,
        height: 150,
    },
    paper: {
        padding: theme.spacing(2),
    },
    paperBgLight: {
        height: '120px',
        padding: theme.spacing(2),
        backgroundImage: `url(/api/image?image=landing-page-light.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: '100%',
    },
    paperBgDark: {
        height: '120px',
        padding: theme.spacing(2),
        backgroundImage: `url(/api/image?image=landing-page-dark.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: '100%',
    },
    button: {
        margin: theme.spacing(-1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}));
function ProfileSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const [passwordModal, setPassModal] = React.useState(false);
    const [password, setPassword] = React.useState({
        OLD_PASSWORD: {
            value: "",
            helperText: "",
            error: false,
        },
        NEW_PASSWORD: {
            value: "",
            helperText: "",
            error: false,
        },
        NEW_PASSWORD2: {
            value: "",
            helperText: "",
            error: false,
        },
    })

    const updatePasswordField = e => {
        const { name, value } = e.target;
        setPassword({
            ...password,
            [name]: {
                ...password[name],
                value: value,
            }
        })
    }

    const onFocus = e => {
        const { name } = e.target;
        setPassword({
            ...password,
            [name]: {
                ...password[name],
                error: false,
                helperText: "",
            }
        })
    }

    const onBlur = e => {
        const { name } = e.target;
        setPassword({
            ...password,
            [name]: {
                ...password[name],
                helperText: validateField(e),
                error: validateField(e) !== "",
            }
        })
    }
    const validateField = e => {
        const { name, value } = e.target;
        switch (name) {
            case "OLD_PASSWORD": {
                if (value.length < 1)
                    return lang.EMPTY_FIELD[props.language];
                return "";
            }
            case "NEW_PASSWORD": {
                if (value.length < 1)
                    return lang.EMPTY_FIELD[props.language];
                if (value.length < 8)
                    return lang.SHORT_FIELD[props.language];
                if (value === password.OLD_PASSWORD.value)
                    return lang.SAME_FIELD[props.language];
                return "";
            }
            case "NEW_PASSWORD2": {
                if (value.length < 1)
                    return lang.EMPTY_FIELD[props.language];
                if (value.length < 8)
                    return lang.SHORT_FIELD[props.language];
                if (value !== password.NEW_PASSWORD.value)
                    return lang.CONFIRM_FIELD[props.language];
                return "";
            }
            default: return "";
        }
    }

    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <ProfileRecent edit={props.edit} language={props.language} />
            {props.edit === false
                ?
                <React.Fragment>
                    <ProfileMain tiny permissions={props.permissions} userID={props.userAuth.id} edit={props.edit} language={props.language} userData={props.userData} theme={props.theme} toggleEdit={props.toggleEdit} />
                    <ProfileDetails
                        closeEdit={props.closeEdit}
                        openRoleUpdateModal={props.openRoleUpdateModal}
                        openDepartmentUpdateModal={props.openDepartmentUpdateModal}
                        isSubmittingRole={props.isSubmittingRole}
                        openRoleModal={props.openRoleModal}
                        userID={props.userAuth.id}
                        permissions={props.permissions}
                        edit={props.edit}
                        userData={props.userData}
                        language={props.language}
                        departmentList={props.departmentList}
                        resetUserPassword={props.resetUserPassword}
                    />
                    <Hidden smDown>
                        <Grid item xs={false} md={3} />
                    </Hidden>
                    {/* <ProfileWork edit={props.edit} language={props.language} theme={props.theme} /> */}
                </React.Fragment>
                :
                <React.Fragment>
                    <ProfileEdit
                        authID={props.userAuth.id}
                        edit={props.edit}
                        isUpdatingUserData={props.isUpdatingUserData}
                        updateUserData={props.updateUserData}
                        language={props.language}
                        userData={props.userData}
                        toggleEdit={props.toggleEdit}
                        avatarModal={props.avatarModal}
                        toggleAvatarModal={props.toggleAvatarModal}
                        changePassword={{
                            password: password,
                            passwordModal: passwordModal,
                            togglePassModal: () => setPassModal(!passwordModal),
                            onBlur: onBlur,
                            onFocus: onFocus,
                            updatePasswordField: updatePasswordField,
                            execute: () => {
                                if (password.OLD_PASSWORD.value.length > 0 && password.NEW_PASSWORD.value.length > 8 && password.NEW_PASSWORD2.value.length > 8 && password.NEW_PASSWORD.value === password.NEW_PASSWORD2.value && password.NEW_PASSWORD.value !== password.OLD_PASSWORD.value) {
                                    props.updateUserPassword(props.userAuth.id, password);
                                    setPassModal(false);
                                }
                            }
                        }}
                    />
                    <ProfileEditSecond
                        tiny
                        edit={props.edit}
                        changePassword={{
                            togglePassModal: () => setPassModal(!passwordModal),
                        }}
                        toggleAvatarModal={props.toggleAvatarModal}
                        userData={props.userData}
                        language={props.language}
                    />
                </React.Fragment>
            }
        </Grid>
    );
}
function PlaceholderSkeleton() {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <CssBaseline />
            <SkeletonProfileRecent classes={classes} />
            <SkeletonProfileMain classes={classes} />
            <SkeletonProfileDetails classes={classes} />
            <Hidden xsDown>
                <Grid item xs={false} md={3} />
            </Hidden>
            <SkeletonProfileWork classes={classes} />
        </Grid>
    );
}


class ProfileView extends Component {
    state = {
        edit: false,
        avatarModal: false,
        roleModal: false,
        roleData: {},

        roleModalUpdate: false,
        roleSelected: "",

        departmentModalUpdate: false,
        departmentSelected: "",

        resetPasswordModal: false,
    }
    fetchPermissionList = () => {
        axios.get('/api/permission_list')
            .then(res => {
                // console.log(res.data)
                this.setState({ roleData: { ...this.state.roleData, PERMISSIONS: res.data } });
            })
            .catch(err => {
                console.log(err.message);
            });
    }
    componentDidUpdate = (nextProps) => {
        if (this.props.location.search !== nextProps.location.search) {
            const query = extractQueryFrontend(this.props.location.search);
            let title = query.id === undefined || parseInt(query.id, 10) === this.props.userAuth.id ? "MY_PROFILE" : "PROFILE";
            let titleAux = query.id === undefined || parseInt(query.id, 10) === this.props.userAuth.id ? "" : ` #${query.id}`;
            document.title = "RPG - " + lang[title][this.props.language] + titleAux;

            this.props.actions.getUserData(
                query.id ? query.id : this.props.userAuth.id,
                query.id === undefined || parseInt(query.id, 10) === this.props.userAuth.id ? 1 : 0
            );
            this.setState({ edit: false, roleModal: false, roleSelected: "", roleModalUpdate: false, departmentSelected: "", departmentModalUpdate: false })
        }
        return (this.props.location.search !== nextProps.location.search)
    }
    componentDidMount = () => {
        this.props.actions.checkSession(false);
        const query = extractQueryFrontend(this.props.location.search);
        let title = query.id === undefined || parseInt(query.id, 10) === this.props.userAuth.id ? "MY_PROFILE" : "PROFILE";
        let titleAux = query.id === undefined || parseInt(query.id, 10) === this.props.userAuth.id ? "" : ` #${query.id}`;
        document.title = "RPG - " + lang[title][this.props.language] + titleAux;

        this.props.actions.getUserData(
            query.id ? query.id : this.props.userAuth.id,
            query.id === undefined || parseInt(query.id, 10) === this.props.userAuth.id ? 1 : 0
        );
        this.props.actions.getDepartmentList(1);
    }
    getIndeterminate = (title) => {
        let total = this.state.roleData.PERMISSIONS.filter(permission => permission.PARENT_PERMISSION === title).length;
        let checked = this.state.roleData.PERMISSIONS.filter(permission => permission.PARENT_PERMISSION === title && permission.VALUE === 1).length;

        if (total === checked)
            return 1;
        else
            if (checked === 0)
                return 0;
            else
                return -1;
    }
    updateCheckBox = (e, type) => {
        let placeholder = [];
        if (type === 1) {
            let parentName = this.state.roleData.PERMISSIONS.filter(permission => permission.PERMISSION === e.target.value)[0].PARENT_PERMISSION;
            this.state.roleData.PERMISSIONS.forEach(permission => {
                if (permission.PERMISSION === e.target.value)
                    placeholder.push({ ...permission, VALUE: permission.VALUE === 1 ? 0 : 1 })
                else
                    placeholder.push(permission);
            });
            let total = placeholder.filter(permission => permission.PARENT_PERMISSION === parentName).length;
            let checked = placeholder.filter(permission => permission.PARENT_PERMISSION === parentName && permission.VALUE === 1).length;
            placeholder.forEach((permission, index) => {
                if (permission.PERMISSION === parentName)
                    placeholder[index].VALUE = total === checked ? 1 : 0;
            })
        }
        else {
            let parent = this.state.roleData.PERMISSIONS.filter(permission => permission.PERMISSION === e.target.value)[0];
            this.state.roleData.PERMISSIONS.forEach(permission => {
                if (permission.PARENT_PERMISSION === e.target.value)
                    placeholder.push({ ...permission, VALUE: parent.VALUE === 0 ? 1 : 0 })
                else
                    if (permission.PERMISSION === e.target.value) {
                        placeholder.push({ ...permission, VALUE: parent.VALUE === 0 ? 1 : 0 })
                    }
                    else
                        placeholder.push(permission);

            })
        }

        this.setState({
            roleData: {
                ...this.state.roleData,
                PERMISSIONS: placeholder
            }
        })
    }
    updateField = (e) => {
        this.setState({ roleData: { ...this.state.roleData, NAME: e.target.value } })
    }
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    fullScreen
                    open={this.state.roleModal}
                    title={lang.ADD_ROLE[this.props.language]}
                    // content={lang.UPDATE_PASSWORD_SECONDARY[this.props.language]}
                    content={
                        <RoleNewForm
                            updateField={this.updateField}
                            getIndeterminate={this.getIndeterminate}
                            roleData={this.state.roleData}
                            updateCheckBox={this.updateCheckBox}
                            language={this.props.language}
                        />
                    }
                    close={() => {
                        this.setState({
                            roleModal: false,
                            roleData: {},
                        })
                    }}
                    execute={() => {
                        // console.log(this.state.roleData.PERMISSIONS);
                        this.props.actions.addNewRole(this.props.userAuth.id, this.state.roleData);
                        this.setState({ roleModal: false })
                    }}
                    validate={this.state.roleData.NAME && this.state.roleData.NAME.length > 2}
                    language={this.props.language}
                />
                <CustomModal
                    open={this.state.roleModalUpdate}
                    title={lang.UPDATE_ROLE[this.props.language]}
                    content={lang.CONFIRM_UPDATE_ROLE[this.props.language]}
                    close={() => this.setState({ roleModalUpdate: false })}
                    execute={() => {
                        this.props.actions.updateUserRole(this.props.userAuth.id, this.props.userData.basic.ROLE_ID, this.props.userData.basic.USER_ID, { roleSelected: this.state.roleSelected });
                        this.setState({ roleModalUpdate: false })
                    }}
                    validate={true}
                    language={this.props.language}
                />
                <CustomModal
                    open={this.state.resetPasswordModal}
                    title={lang.RESET_PASSWORD[this.props.language]}
                    content={lang.CONFIRM_RESET_PASSWORD[this.props.language]}
                    close={() => this.setState({ resetPasswordModal: false })}
                    execute={() => {
                        this.props.actions.resetUserPassword(this.props.userAuth.id, this.props.userData.basic.USER_ID);
                        this.setState({ resetPasswordModal: false })
                    }}
                    validate={true}
                    language={this.props.language}
                />
                <CustomModal
                    open={this.state.departmentModalUpdate}
                    title={lang.UPDATE_DEPARTMENT[this.props.language]}
                    content={lang.CONFIRM_UPDATE_DEPARTMENT[this.props.language]}
                    close={() => this.setState({ departmentModalUpdate: false })}
                    execute={() => {
                        this.props.actions.updateUserDepartment(this.props.userAuth.id, this.props.userData.basic.DEPARTMENT_ID, this.props.userData.basic.USER_ID, { departmentSelected: this.state.departmentSelected });
                        this.setState({ departmentModalUpdate: false })
                    }}
                    validate={true}
                    language={this.props.language}
                />
                {this.props.isFetchingUserData === false
                    ?
                    <ProfileSetup
                        openRoleModal={() => {
                            this.fetchPermissionList();
                            this.setState({
                                roleModal: true,
                                roleData: {
                                    ...this.state.roleData,
                                    NAME: ""
                                }
                            })
                        }}
                        openRoleUpdateModal={(e) => this.setState({ roleModalUpdate: true, roleSelected: e.target.value })}
                        openDepartmentUpdateModal={(e) => this.setState({ departmentModalUpdate: true, departmentSelected: e.target.value })}
                        departmentList={this.props.departmentList}
                        isSubmittingRole={this.props.isSubmittingRole}
                        userData={this.props.userData}
                        userAuth={this.props.userAuth}
                        permissions={this.props.permissions}
                        theme={this.props.theme}
                        language={this.props.language}
                        edit={this.state.edit}
                        toggleEdit={() => this.setState({ edit: !this.state.edit })}
                        closeEdit={() => this.setState({ edit: false })}
                        updateUserData={this.props.actions.updateUserData}
                        updateUserPassword={this.props.actions.updateUserPassword}
                        resetUserPassword={() => this.setState({ resetPasswordModal: true })}
                        isUpdatingUserData={this.props.isUpdatingUserData}
                        avatarModal={this.state.avatarModal}
                        toggleAvatarModal={() => this.setState({ avatarModal: !this.state.avatarModal })}
                    />
                    :
                    <PlaceholderSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isFetchingUserData: state.user.isFetchingUserData,
        isUpdatingUserData: state.user.isUpdatingUserData,
        userData: state.user.userData,
        isSubmittingRole: state.user.isSubmittingRole,
        userAuth: state.auth.userData,
        permissions: state.auth.permissions,
        departmentList: state.nom.departmentList,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);