import React, { useState, useEffect, useCallback } from 'react';

import { Grid, TextField, MenuItem } from '@material-ui/core';
import axios from "axios";
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as requestHeaders from "../../../../Constants/requestHeaders";

const AddProjectsTaskModal = (props) => {
    const {
        language,
        open,
        close,
        siteId,
        userId,
        sendNotification
    } = props;

    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);

    /**
     * For the contracts with the subtype of projects
     * @type = 2
     * @subType = 3
     */
    const getProjectContracts = useCallback(async () => {
        try {
            const response = await axios.get('/api/contracts_by_type', {
                ...requestHeaders.getGetHeader(),
                params: {
                    type: 2,
                    subType: 3,
                    siteId
                }
            });
            setContracts(response.data.contracts);
        } catch (error) {
            console.log(error);
        }
    },
        [siteId]
    );

    const createProject = useCallback(async () => {
        try {
            const response = await axios.post('/api/projects', {
                contractId: selectedContract.OBJID,
                ownerId: userId
            }, requestHeaders.getPostHeader()
            );
            sendNotification(response.status)
        } catch (error) {
            sendNotification(400)
            console.log(error);
        }
    },
        [selectedContract, userId]
    );

    useEffect(() => {
        getProjectContracts();
    }, [siteId, open]);

    return (
        <CustomModal
            open={open}
            title={lang.NEW_PROJECT[language]}
            content={
                <Grid container spacing={2}>
                    <TextField
                        select
                        label="Contracte"
                        value={selectedContract}
                        onChange={(e) => setSelectedContract(e.target.value)}
                        variant="outlined"
                        fullWidth
                    >
                        {
                            contracts.map((c, index) => <MenuItem key={`contract_menu_item_${index}`} value={c}>{`${lang[c.SUB_TYPE][language]} Nr. ${c.CONTRACT_NO}`}</MenuItem>)
                        }
                        {
                            contracts.length === 0 && <div style={{ padding: "10px" }}>Nu exista contracte de instalare</div>
                        }
                    </TextField>
                </Grid>
            }
            close={close}
            execute={() => {
                createProject();
                close();
            }}
            fullWidth
            language={language}
            validate={selectedContract !== null}
        />
    )
};

export default AddProjectsTaskModal;
