import { onNotificationOpen, setNoOfItems, setLoadedPages } from './configActions';
import { SignInUser, OpenConfirmWindow, LogoutAndRedirect } from './authActions';
import * as lang from '../Constants/strings';


export const SITE_LIST_REQUEST = 'SITE_LIST_REQUEST';
export const SITE_LISTE_RECIVE = 'SITE_LISTE_RECIVE';
export const NEW_FETCH_SITES = 'NEW_FETCH_SITES';
export const UPDATE_LAST_ID = 'UPDATE_LAST_ID';

export function updateLastID(data) {
    return {
        type: UPDATE_LAST_ID,
        payload: {
            data: data
        }
    }
}

export function onSiteListRequest(id) {
    return {
        type: SITE_LIST_REQUEST,
        payload: {
            data: id
        }
    }
}

export function onSiteListRecive(data) {
    return {
        type: SITE_LISTE_RECIVE,
        payload: {
            data: data
        }
    }
}

export function addNewSitesToList(data) {
    return {
        type: NEW_FETCH_SITES,
        payload: {
            data: data
        }
    }
}

export function getSiteList(rowsPerPage, currentPage, orderBy, order, area, isActive = false, isValidate = false) {
    return (dispatch) => {
        dispatch(onSiteListRequest());
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_sites?rows=${rowsPerPage}&currentPage=${currentPage}&orderBy=${orderBy}&order=${order}&area=${area}&isActive=${isActive}&isValidate=${isValidate}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteListRecive([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onSiteListRecive(response.data));
                    dispatch(setLoadedPages(response.loadedPages));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onSiteListRecive([]));
                console.log('caught it!', err);
            })
    }
}

export function loadNewSites(rowsPerPage, currentPage, orderBy, order, area, isActive = false, isValidate = false) {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        dispatch(onSiteListRequest());
        return fetch(`/api/get_sites?rows=${rowsPerPage}&currentPage=${currentPage}&orderBy=${orderBy}&order=${order}&area=${area}&isActive=${isActive}&isValidate=${isValidate}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(addNewSitesToList([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(addNewSitesToList(response.data));
                    dispatch(setLoadedPages(response.loadedPages));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(addNewSitesToList([]));
                console.log('caught it!', err);
            })
    }
}

export function getSiteCount(area, isActive = false, isValidate = false) {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_sites_count?area=${area}&isActive=${isActive}&isValidate=${isValidate}`, options)
            .then(response => { return response.json() })
            .then(response => {
                if (response.error === 1) {
                    dispatch(setNoOfItems(0));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(setNoOfItems(response.data));
                }
            })
            .catch(err => {
                dispatch(setNoOfItems(0));
                console.log('caught it!', err);
            })
    }
}

export function onSearchSite(searchTerm, orderBy, order, area, isActive = false, isValidate = false) {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/search_site?searchTerm=${searchTerm}&orderBy=${orderBy}&order=${order}&area=${area}&isActive=${isActive}&isValidate=${isValidate}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default:
                        return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteListRecive([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onSiteListRecive(response));
                    dispatch(setNoOfItems(response.length));
                    dispatch(setLoadedPages(30));
                }
            })
            .catch(err => {
                dispatch(onSiteListRecive([]));
                console.log('caught it!', err);
            })
    }
}

export function onSiteValidation(id) {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/site_validation?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default:
                        return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .catch(err => {
                dispatch(onSiteListRecive([]));
                console.log('caught it!', err);
            })
    }
}