import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { Divider, Grid, Typography } from "@material-ui/core";
import * as lang from "../../../../Constants/strings";

export default function NoGpsModal(props) {
    const { language, open, close } = props;

    return (
        <React.Fragment>
            <CustomModal
                open={open}
                title={lang.NO_GPS_POSITION_WARNING[language]}
                content={
                    <Grid container spacing={2} alignItems='center' justify='center' alignContent='center'>
                        <Typography variant="h4" color="secondary" >{lang.SITE_WITH_NO_GPS_POSITION[language]}</Typography>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Typography variant="body1">{lang.NO_GPS_POSITION_WARNING_CONTENT[language]}</Typography>
                    </Grid>
                }
                close={close}
                execute={() => { }}
                validate={false}
                language={language}
            />
        </React.Fragment>
    )
}