import React from 'react';
import * as lang from '../../../Constants/strings';
import { Grid, InputAdornment, TextField, IconButton, OutlinedInput, InputLabel, FormControl, Divider, Fab, makeStyles, useTheme, MenuItem, Menu, Typography } from '@material-ui/core';
import { Visibility, ClearRounded, AddRounded } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ro, enUS } from 'date-fns/esm/locale';

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));
export default function AccountingCustomersFilters(props) {
    const classes = useStyles();
    const { language, filters, updateFilter, removeFilter } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const [filterList, setFilterList] = React.useState([
        {
            field: "CUSTOMER_CODE",
            visible: filters.CUSTOMER_CODE !== undefined,
        },
        {
            field: "NAME",
            visible: filters.NAME !== undefined,
        },
        {
            field: "PHONE",
            visible: filters.PHONE !== undefined,
        },
        {
            field: "EMAIL",
            visible: filters.EMAIL !== undefined,
        }
    ]);
    function toggleFilter(field) {
        let updatedFilters = [];
        filterList.forEach(item => {
            if (item.field === field)
                updatedFilters.push({ ...item, visible: !item.visible })
            else
                updatedFilters.push(item)
        })
        setFilterList(updatedFilters);
        setAnchorEl(null);
        if (filters[field])
            removeFilter(field);
    }

    return (
        <React.Fragment>
            <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "en" ? enUS : ro} >
                    <DatePicker
                        fullWidth
                        autoOk
                        disableFuture
                        inputVariant="outlined"
                        format="yyyy MMMM"
                        views={["year", "month"]}
                        label={lang.MONTH[language]}
                        value={filters.YEAR && filters.MONTH ? new Date(Date.parse(`${filters.YEAR}-${filters.MONTH}-01`)) : new Date()}
                        onChange={(value) => {
                            updateFilter('YEAR', value.getFullYear())
                            updateFilter('MONTH', value.getMonth() + 1)
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>
            {filterList.filter(item => item.visible === true).map((item, index) =>
                <Grid item xs={12} md={6} key={index}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={lang[item.field][props.language]}
                        onChange={(e) => updateFilter(item.field, e.target.value)}
                        value={filters[item.field] ? filters[item.field] : ""}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => toggleFilter(item.field)}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <ClearRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                {filterList.filter(item => item.visible === true).length > 0
                    ?
                    <Fab
                        onClick={handleMenu}
                        color="secondary"
                    >
                        <React.Fragment>
                            <AddRounded />
                        </React.Fragment>
                    </Fab>
                    :
                    <Fab
                        variant="extended"
                        color="secondary"
                        onClick={handleMenu}
                    >
                        <React.Fragment>
                            <AddRounded
                                className={classes.leftIcon}
                            />
                            {lang.ADD_FILTER[language]}
                        </React.Fragment>
                    </Fab>
                }
            </Grid>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {filterList.map((item, index) =>
                    <MenuItem key={index} disabled={item.visible} onClick={() => toggleFilter(item.field)}>
                        <Typography>
                            {lang[item.field][language]}
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
}
