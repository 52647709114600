import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";
import {
    Grid,
    makeStyles,
    TextField,
    MenuItem,
    Divider,
    Typography,
    Button
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import Section from '../../../../Components/Section';
import { formatDateFromDb } from '../../../../Functions/dateFunctions';
import * as lang from '../../../../Constants/strings';
import * as requestHeaders from '../../../../Constants/requestHeaders';

const useStyle = makeStyles(theme => ({
    title: {
        margin: theme.spacing(2)
    }
}));

export default function BillFields(props) {
    const {
        language,
        taskId,
        contractor,
        refetchTask
    } = props;
    const classes = useStyle();
    // Use to make the initial REST call and ensure that will be done only for th initial load of the componet
    const [firstLoad, setFirstLoad] = React.useState(true);
    // The user list
    const [userList, setUserList] = React.useState([]);
    // Store the bill type from db
    const [billTypes, setBillTypes] = React.useState([]);

    //  1 FREE   -- for the free option we need to get a user
    //  2 BILL   -- bill number
    //  3 TO_BILL  -- set the user that will bill the task

    // Form data
    const [selectedBillType, setSelectedBillType] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState(null);

    // Bill Info
    const [billNo, setBillNo] = React.useState('');
    const [billDate, setBillDate] = React.useState(new Date());
    const [billSum, setBillSum] = React.useState('');

    // Is Payed
    const [isPayed, setIsPayed] = React.useState(false);
    const [isPartial, setIsPartial] = React.useState(false);

    // Pay method
    const [payMethod, setPayMethod] = React.useState();

    // PF RECIPE
    const [recipePf, setRecipePf] = React.useState('');
    const [recipePfDate, setRecipePfDate] = React.useState(new Date());

    // PJ RECIPE
    const [recipePj, setRecipePj] = React.useState('');
    const [recipePjDate, setRecipePjDate] = React.useState(new Date());

    // Partial payed sum
    const [partialSum, setPartialSum] = React.useState('');

    // Bank Transfer
    const [bankDate, setBankDate] = React.useState(new Date());

    // Contractor List
    const [contractorList, setContractorList] = React.useState([]);
    const [selectedContractor, setSelectedContractor] = React.useState(null);

    // 
    const [wasBill, setWasBill] = React.useState()

    // Initial Effect for fetching data
    React.useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            getUserList();
            getBillTypes();
            getContractor();
        }
        setWasBill();
        checkForExistingBill(taskId);
    }, []);

    React.useEffect(() => {
        checkForExistingBill(taskId);
        setSelectedContractor(contractor ? contractor : null);
    }, [taskId]);

    const getBillTypes = async () => {
        const result = await axios.get('/api/get_task_billing_types', requestHeaders.getGetHeader());
        if (result.data.data && result.data.data.length > 0) {
            await setBillTypes(result.data.data);
        } else {
            await setBillTypes([]);
        }
    };

    const getUserList = async () => {
        const result = await axios.get('/api/users', requestHeaders.getGetHeader());
        if (result.data.data && result.data.data.userList.length > 0) {
            setUserList(result.data.data.userList);
        } else {
            setUserList([]);
        }
    };

    const getContractor = async () => {
        const result = await axios.get('/api/get_executors', {
            ...requestHeaders.getGetHeader(),
            params: {
                active: true,
                type: 3,
                subType: 4
            }
        });
        if (result.data.length > 0) {
            setContractorList(result.data)
        } else {
            setContractorList([]);
        }
    };

    const checkForExistingBill = async (id) => {
        try {
            const response = await axios.get("/api/get_evaluation_bill_data", {
                params: {
                    id: id
                },
                ...requestHeaders.getGetHeader()
            });

            if (response.data) {
                setWasBill(true);
            } else {
                setWasBill();
            }

        }
        catch (error) {
            setWasBill()
        }
    };

    const billFieldValidation = () => {
        switch (isPayed) {
            case true:
                switch (payMethod) {
                    case "RECIPE":
                        return billNo !== '' && billSum !== '' && billDate !== '' && recipePj !== '' && recipePjDate !== '';
                    case "BF":
                        return billNo !== '' && billSum !== '' && billDate !== '';
                    case "BANK":
                        return billNo !== '' && billSum !== '' && billDate !== '' && bankDate !== '';
                    default: return false;
                };
            case false:
                return billNo !== '' && billSum !== '' && billDate !== '';
        }
    };

    const getValidation = () => {
        if (selectedBillType === undefined || selectedContractor === undefined) {
            return true;
        } else {
            switch (selectedBillType) {
                case 1:
                    return selectedUser === null;
                case 3:
                    return selectedUser === null;
                case 2:
                    return !billFieldValidation();
                default: return true
            }
        }
    };

    const createTaskBill = async () => {
        setWasBill(true)
        const response = await axios.post('/api/evaluation_task_bill', // was add_task_bill
            {
                FK2CONTRACTOR: props.contractor ? props.contractor : selectedContractor,
                FK2BILL_TYPE: selectedBillType,
                FK2USER: props.userId,
                FK2_EVALUATIONS: props.taskId,
                BILL_NO: billNo,
                BILL_DATE: formatDateFromDb(billDate, 15),
                BILL_VALUE: billSum,
                RECEIPT_NO: recipePj,
                RECEIPT_DATE: formatDateFromDb(recipePjDate, 15),
                IS_PAYED: isPayed,
                IS_PARTIAL_PAYED: isPartial,
                USER_THAT_BILLS: selectedUser,
                USER_APROVE_FREE: selectedUser,
                RECEIP_NO_PF: recipePf,
                RECEIP_DATE_PF: formatDateFromDb(recipePfDate, 15),
                BANK_TRANSFER_DATE: formatDateFromDb(bankDate, 15),
                payMethod: payMethod,
                PARTIAL_PAYED_SUM: partialSum
            },
            requestHeaders.getPostHeader()
        );
        if (response.status === 201) {
            await refetchTask()
            setWasBill(true);
        } else {
            setWasBill(false);
        }
    }

    return (
        <>
            <Grid xs={12}>
                <Divider />
            </Grid>
            <Grid container justify="center">
                <Typography className={classes.title} variant='h5' color="secondary">{wasBill ? lang.WAS_BILL[language] : lang.BILL[language]}</Typography>
            </Grid>
            {!wasBill && <>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {
                    !props.contractor &&
                    <Grid item xs={12}>
                        <TextField
                            required
                            select
                            variant='outlined'
                            name='contractor'
                            label={lang.CONTRACTOR[language]}
                            onChange={(e) => setSelectedContractor(e.target.value)}
                            value={selectedContractor}
                            disabled={wasBill}
                            fullWidth
                        >
                            {
                                contractorList.map(contractor => {
                                    return <MenuItem key={contractor.OBJID} value={contractor.OBJID}>{contractor.NAME}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        required={wasBill === undefined ? true : false}
                        select
                        variant='outlined'
                        name='BillType'
                        label={lang.TYPE[language]}
                        onChange={(e) => setSelectedBillType(e.target.value)}
                        value={selectedBillType}
                        disabled={wasBill}
                        fullWidth
                    >
                        {
                            billTypes.map(type => {
                                return <MenuItem key={type.OBJID} value={type.OBJID}>{lang[type.BILLING_TYPE][language].toUpperCase()}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
                {/* FREE and TO_BILL both need just a responsable user so will be treated in the same section */}
                <Section render={selectedBillType === 1 || selectedBillType === 3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            select
                            variant='outlined'
                            name='user'
                            label={lang.USER[language]}
                            onChange={(e) => setSelectedUser(e.target.value)}
                            value={selectedUser}
                            disabled={wasBill}
                            fullWidth
                        >
                            {
                                userList.map(user => {
                                    return <MenuItem key={user.USER_ID} value={user.USER_ID}>{user.USERNAME}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                </Section>
                {/* BILL SECTION */}
                <Section render={selectedBillType === 2}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            variant='outlined'
                            name='bill_no'
                            type='number'
                            label={lang.BILL_NO[language]}
                            onChange={(e) => setBillNo(e.target.value)}
                            value={billNo}
                            disabled={wasBill}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                fullWidth
                                disabled={wasBill}
                                id="date-picker-inline-1"
                                label="Dată factură"
                                value={billDate}
                                onChange={date => setBillDate(date, 'bill')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            name='bill_sum'
                            type='number'
                            label={`${lang.BILL_SUM[language]} RON`}
                            onChange={(e) => setBillSum(e.target.value)}
                            value={billSum}
                            disabled={wasBill}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            required
                            variant='outlined'
                            name='is_payed'
                            label={lang.IS_PAYED[language]}
                            onChange={(e) => setIsPayed(e.target.value)}
                            value={isPayed}
                            disabled={wasBill}
                            fullWidth
                        >
                            <MenuItem value={true}>{lang.YES[language].toUpperCase()}</MenuItem>
                            <MenuItem value={false}>{lang.NO[language].toUpperCase()}</MenuItem>
                        </TextField>
                    </Grid>
                    {/* The sub section for paied tasks */}
                    <Section render={isPayed}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                required
                                variant='outlined'
                                name='method_pay'
                                label={lang.PARTIAL_PAYED[language]}
                                onChange={(e) => setIsPartial(e.target.value)}
                                value={isPartial}
                                disabled={wasBill}
                                fullWidth
                            >
                                <MenuItem value={true}>{lang.YES[language].toUpperCase()}</MenuItem>
                                <MenuItem value={false}>{lang.NO[language].toUpperCase()}</MenuItem>
                            </TextField>
                        </Grid>
                        <Section render={isPartial}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={wasBill}
                                    required
                                    variant='outlined'
                                    name='partial_payed_sum'
                                    label={lang.PARTIAL_PAYED_SUM[language]}
                                    onChange={(e) => setPartialSum(e.target.value)}
                                    value={partialSum}
                                    fullWidth
                                    type="number"
                                />
                            </Grid>
                        </Section>
                        <Grid item xs={12}>
                            <TextField
                                select
                                required
                                variant='outlined'
                                name='method_pay'
                                label={lang.PAY_METHOD[language]}
                                onChange={(e) => setPayMethod(e.target.value)}
                                value={payMethod}
                                disabled={wasBill}
                                fullWidth
                            >
                                <MenuItem value={"RECIPE"}>{lang.CASH_PJ[language].toUpperCase()}</MenuItem>
                                <MenuItem value={"BF"}>{lang.CASH_PF[language].toUpperCase()}</MenuItem>
                                <MenuItem value={"BANK"}>{lang.BANK[language].toUpperCase()}</MenuItem>
                            </TextField>
                        </Grid>
                        {/* Base on the pay method we have the next subsection */}
                        <Section render={payMethod === 'RECIPE'}>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    variant='outlined'
                                    name='bill_no'
                                    type='number'
                                    label={lang.RECIPE_NO[language]}
                                    onChange={(e) => setRecipePj(e.target.value)}
                                    disabled={wasBill}
                                    value={recipePj}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        // margin="normal"
                                        id="date-picker-inline-1"
                                        label={lang.RECIPE_DATE[language]}
                                        value={recipePjDate}
                                        disabled={wasBill}
                                        onChange={date => setRecipePjDate(date, 'bill')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Section>
                        <Section render={payMethod === 'BANK'}>
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        disabled={wasBill}
                                        id="date-picker-inline-2"
                                        label={lang.BANK_TRANSFER_DATE[language]}
                                        value={bankDate}
                                        onChange={date => setBankDate(date, 'bill')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Section>
                        <Section render={payMethod === 'BF'}>
                            <Grid item xs={6}>
                                <TextField
                                    variant='outlined'
                                    name='bill_no'
                                    type='number'
                                    label={lang.RECIPE_NO_PF[language]}
                                    onChange={(e) => setRecipePf(e.target.value)}
                                    value={recipePf}
                                    disabled={wasBill}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        disabled={wasBill}
                                        id="date-picker-inline-3"
                                        label={lang.RECIPE_DATE_PF[language]}
                                        value={recipePfDate}
                                        onChange={date => setRecipePfDate(date, 'bill')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Section>
                    </Section>
                </Section>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={wasBill === undefined ? getValidation() : wasBill}
                        onClick={() => {
                            createTaskBill();
                        }}
                    >
                        {lang.BILL[language]}
                    </Button>
                </Grid>
                <input
                    onChange={() => 1 + 0}
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, width: 0 }}
                    value={wasBill}
                    required={wasBill === undefined ? true : false}
                />
            </>
            }
        </>
    )
};