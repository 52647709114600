import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { Grid, TextField } from '@material-ui/core';
import * as lang from '../../../../Constants/strings';

export default function SystemInfoModal(props) {
    const { siteId, open, language } = props;
    const [siteInfo, setSiteInfo] = React.useState({});

    React.useEffect(() => {
        fetch(`/api/get_system_info?id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    setSiteInfo({})
                } else {
                    setSiteInfo({ ...response.data })
                }
            })
    }, [siteId]);

    function updateField(name, value, length) {
        console.log(name, value, length);
        let data = { ...siteInfo };
        data[name] = value.toString().slice(0, length);
        setSiteInfo({ ...data });
    };

    function updateSystemInfo() {
        console.log(siteInfo)
        fetch(`/api/update_system_info`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({
                ...siteInfo,
                'SITE_ID': siteId
            })
        })
            .then(response => console.log(response))

    };

    return (
        <React.Fragment>
            <CustomModal
                fullWidth
                open={open}
                title={lang.SYSTEM_LOGING_INFO[props.language]}
                content={
                    <Grid container alignItems='center' justify='center' spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                value={siteInfo.SYSTEM_USERNAME ? siteInfo.SYSTEM_USERNAME : ''}
                                onChange={(e) => updateField('SYSTEM_USERNAME', e.target.value, 50)}
                                variant='outlined'
                                label={lang.USERNAME[language]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                value={siteInfo.SYSTEM_PASSWORD ? siteInfo.SYSTEM_PASSWORD : ''}
                                onChange={(e) => updateField('SYSTEM_PASSWORD', e.target.value, 50)}
                                variant='outlined'
                                label={lang.PASSWORD[language]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                value={siteInfo.SYSTEM_IP ? siteInfo.SYSTEM_IP : ''}
                                onChange={(e) => updateField('SYSTEM_IP', e.target.value, 15)}
                                variant='outlined'
                                label="IP"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                style={{ margin: 5 }}
                                value={siteInfo.SYSTEM_PORT ? siteInfo.SYSTEM_PORT : ''}
                                onChange={(e) => updateField('SYSTEM_PORT', e.target.value, 15)}
                                variant='outlined'
                                label="Port"
                            />
                        </Grid>
                    </Grid>
                }
                close={() => props.close()}
                execute={() => {
                    updateSystemInfo();
                    props.close();
                }}
                validate
                language={language}
            />
        </React.Fragment>
    )
}
