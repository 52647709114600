import React, { useEffect, useState } from 'react';
import axios from "axios";
import * as requestHeaders from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import * as lang from "../../../Constants/strings";
import { pv_register } from "../../../Components/Misc/Tables/TableHeaders";
import SortBar from "./SortBar";

const PvReports = (props) => {
  const { language } = props;
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [data, setData] = useState([]);

  const filter = async ({
    startDate,
    endDate,
    selectedContractor,
    contract_subType,
    contract_code
  }) => {
    try {
      const response = await axios.get(`/api/report_pv?startDate=${startDate}&endDate=${endDate}&selectedContractor=${selectedContractor.join(',')}&contract_subType=${contract_subType.join(',')}&contract_code=${contract_code.join((','))}`, {
        ...requestHeaders.getGetHeader(),
        // params: {
        //   startDate,
        //   endDate,
        //   selectedContractor,
        //   contract_subType,
        //   contract_code
        // }
      });

      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const download = ({
    startDate,
    endDate,
    selectedContractor,
    contract_subType,
    contract_code
  }) => {
    fetch(`/api/report_pv_excel?startDate=${startDate}&endDate=${endDate}&selectedContractor=${selectedContractor.join(',')}&contract_subType=${contract_subType.join(',')}&contract_code=${contract_code.join((','))}`,
      {
        ...requestHeaders.getGetHeader(),
        responseType: 'arraybuffer',
      }
    )
      .then(response => response.blob())
      .then(response => {
        const file = new Blob(
          [response],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `Registru_procese_verbale_predare_primire_${new Date().getDate()}.xlsx`;
        link.click();
      })
      .catch(err => console.log(err))
  }
  return (
    <>
      <SortBar
        language={language}
        filter={(obj) => filter(obj)}
        onDownload={(obj) => download(obj)}
      />
      <MaterialTable
        tiny={true}
        title="Registru procese verbale predare primire a sistemlor executate si procese verbale de instruire a utilizatorilor"
        language={language}
        data={data}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(value) => setRowsPerPage(value)}
        header={pv_register(language)}
        dense={false}
        openAddModal={() => { }}
        openRowModal={(id) => console.log(id)}
        clickLabel="OBJID"
        hideAddBtn={true}
      />

    </>
  )
};

export default PvReports;
