import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";
import axios from "axios";
import * as lang from "../../../Constants/strings";
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import { intervention_history_data } from '../../../Components/Misc/Tables/TableHeaders';
import { formatDateFromDb } from '../../../Functions/dateFunctions';
import * as requestHeaders from "../../../Constants/requestHeaders";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
        width: 'auto'
    },
}));


export default function InterventionHistoryTable(props) {
    const theme = useTheme();
    const classes = useStyles();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const { language, selectedArea } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [intervention, setIntervention] = React.useState([]);
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(new Date());

    // get the table header
    const getHeader = useCallback(() => {
        let header = intervention_history_data(props.language);
        // if the user has the permission insert in the header the column for intervention status
        if (props.hasPermissionToViewStatus) {
            header.splice(6, 0, {
                label: lang.BILLING[props.language],
                id: 'IS_FREE_TRANS',
                numeric: false,
                disablePadding: false,
            });
        };
        return header;
    }, [props.hasPermissionToViewStatus, props.language]);


    const fetchHistory = async () => {
        try {
            const response = await axios.get(`/api/get_close_inter_list`, {
                ...requestHeaders.getGetHeader(),
                params: {
                    startDate: formatDateFromDb(startDate, 15),
                    endDate: formatDateFromDb(endDate, 15),
                    area_code: props.selectedArea
                }
            });

            const formateData = response.data.data.map(d => {
                return {
                    ...d,
                    CREATE_DATE: moment(d.CREATE_DATE).format("DD-MM-YYYY HH:mm"),
                    END_DATE: moment(d.END_DATE).format("DD-MM-YYYY  HH:mm"),
                    TYPE: lang[d.TYPE][language],
                    IS_FREE_TRANS: d.IS_FREE === 1 ? 'Gratuit' : lang.NON_FREE[props.language]
                }
            });

            setIntervention(formateData);
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        fetchHistory();
    }, [selectedArea, startDate, endDate]);

    return (
        <React.Fragment>
            <Grid container component="main" className={classes.margin} spacing={tiny ? 1 : 4}>
                <Grid item xs={6}>
                    <Paper>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required={true}
                                inputVariant="outlined"
                                fullWidth
                                name="START_DATE"
                                label={lang.START_INTERVAL_DATE[props.language]}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={startDate}
                                onChange={(value) => setStartDate(value)}
                            />
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required={true}
                                inputVariant="outlined"
                                fullWidth
                                name="END_DATE"
                                label={lang.LAST_INTERVAL_DATE[props.language]}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={endDate}
                                onChange={(value) => setEndDate(value)}
                            />
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.INTERVENTION_HISTORY[props.language]}
                        language={props.language}
                        data={intervention}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={setRowsPerPage}
                        header={getHeader()}
                        hideAddBtn
                        openRowModal={props.openRowModal}
                        clickLabel="INTERVENTION_ID"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
