import React from 'react';
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { useMediaQuery, useTheme, Grid } from '@material-ui/core';
import InterventionNewForm from '../InterventionNewForm';


export default function AddInterventionModal(props) {
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const { language, open, close, validate, addData, executeFunction, baseData } = props;

    return (
        <CustomModal
            fullWidth
            open={open}
            title={lang.NEW_ALARM[language]}
            content={
                <Grid container spacing={2}>
                    {open ?
                        <InterventionNewForm
                            {...props}
                            addData={addData}
                            baseData={baseData}
                            tiny={tiny}
                        />
                        : null
                    }
                </Grid>
            }
            close={close}
            execute={() => {
                executeFunction();
                close();
            }}
            language={language}
            validate={validate}
        />
    )
}