import * as actionTypes from '../actions/authActions';
import { UPDATE_USER_PASSWORD_SUCCESS } from '../actions/userActions';
import jwtDecode from 'jwt-decode';

const initialState = {
    token: null,
    username: null,
    userData: null,
    isChangingPassword: false,
    isAuthenticated: false,
    isAuthenticating: false,
    confirmLogout: false,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SIGN_IN_BEGIN:
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: true,
            }
        case UPDATE_USER_PASSWORD_SUCCESS:
        case actionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
                token: action.payload.token,
                username: jwtDecode(action.payload.token).username,
                userData: jwtDecode(action.payload.token),
                permissions: action.payload.permissions,
                confirmLogout: false,
            }
        case actionTypes.SIGN_IN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                token: null,
            }
        case actionTypes.LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                token: null,
                confirmLogout: false,
            }
        }
        case actionTypes.OPEN_CONFIRM_WINDOW: {
            let confirmBool = true;
            if (window.location.pathname === "/sign_in")
                confirmBool = false;
            return {
                ...state,
                confirmLogout: confirmBool,
            }
        }
        default:
            return state;
    }
}
