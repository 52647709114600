import React, {useEffect, useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from '../../../actions/actions';
import {
    Grid,
    TextField,
    MenuItem,
    Fab,
    Divider,
    Typography, Button
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import CustomModal from '../../../Components/Modals/CustomModal';
import Section from '../../../Components/Section';
import * as lang from '../../../Constants/strings';
import * as requestHeaders from "../../../Constants/requestHeaders";
import { formatDateFromDb } from '../.../../../../Functions/dateFunctions';
import TaskCommentHistory from "../../../Components/Modals/TaskCommentHistory";
import {getGetHeader} from "../../../Constants/requestHeaders";
import {TASK_SCORING_COMPLETE} from "../../../Constants/strings";
import ScoringModal from "../../../Components/TechnicalUser/ScoringModal";

function BillingModal(props) {
    const { open, language, close, billId, lockAllFields, reopen, userAuth } = props;
    // Use to make the initial REST call and ensure that will be done only for th initial load of the componet
    const [firstLoad, setFirstLoad] = React.useState(true);
    // The user list
    const [userList, setUserList] = React.useState([]);
    // Store the bill type from db
    const [billTypes, setBillTypes] = React.useState([]);

    //  1 FREE   -- for the free option we need to get a user
    //  2 BILL   -- bill number
    //  3 TO_BILL  -- set the user that will bill the task

    // Form data
    const [selectedBillType, setSelectedBillType] = React.useState(1);
    const [selectedUser, setSelectedUser] = React.useState(1);

    // Bill Info
    const [billNo, setBillNo] = React.useState('');
    const [billDate, setBillDate] = React.useState(new Date());
    const [billSum, setBillSum] = React.useState('');

    // Is Payed
    const [isPayed, setIsPayed] = React.useState(false);
    const [isPartial, setIsPartial] = React.useState(false);

    // Pay method
    const [payMethod, setPayMethod] = React.useState("BANK");

    // PF RECIPE
    const [recipePf, setRecipePf] = React.useState('');
    const [recipePfDate, setRecipePfDate] = React.useState(new Date());

    // PJ RECIPE
    const [recipePj, setRecipePj] = React.useState('');
    const [recipePjDate, setRecipePjDate] = React.useState(new Date());

    // Bank Transfer
    const [bankDate, setBankDate] = React.useState(new Date());

    // Contractor List
    const [contractorList, setContractorList] = React.useState([]);
    const [selectedContractor, setSelectedContractor] = React.useState(32);

    // Partial payed sum
    const [partialSum, setPartialSum] = React.useState('');

    // Task Data
    const [taskReason, setTaskReason] = React.useState('');

    const [taskBillObj, setTaskBillObj] = React.useState(null);

    const [lastNote, setLastNote] = React.useState("");
    const [commentsHistoryModal, setCommnetsHistoryModal] = React.useState(false);
    const [history, setHistory] = React.useState([]);

    // Technical Team scoring logic
    const [scoreModal, setScoreModal] = useState(false);
    const [taskScoringComplete, setTaskScoringComplete] = useState(false);
    const [taskId, setTaskId] = useState(null);

    const getTaskScoringStatus = async () => {
        try {
            const res = await axios.get('/api/technical/task/status/' + taskId, getGetHeader());
            setTaskScoringComplete(res.data.status === "COMPLETE");
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(taskId) getTaskScoringStatus();
    }, [taskId]);

    // Effects

    // Initial Effect for fetching data
    React.useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            getUserList();
            getBillTypes();
            getContractor();
        }
    }, [props.open]);

    React.useEffect(() => {
        if (props.billId)
            getBillData(props.billId)
    }, [props.billId])

    // Functions
    const getBillTypes = () => {
        fetch('/api/get_task_billing_types', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.length > 0) {
                    setBillTypes(response.data);
                } else {
                    setBillTypes([]);
                }
            })
    };

    const getUserList = async () => {
        try {
            const result = await axios.get('/api/get_user_base_on_roles',
              {
                  ...requestHeaders.getGetHeader(),
                  params: {
                      roles: [1, 45, 48, 50, 53]
                  }
              }
            );
            setUserList(result.data);
        } catch (e) {
            setUserList([]);
        }
    };

    const getCloseComment = (taskHistory) => {
        let note = "";
        taskHistory.forEach(event => {
            if (event.CHG_TO === 8) {
                note = event.NOTE;
            }
        })

        return note;
    };

    const getBillData = async (id) => {
        const response = await axios.get(`/api/get_task_bill_data`, {
            ...requestHeaders.getGetHeader(),
            params: {
                id: id
            }
        });

        if (response.status === 200) {
            if (response.data) {
                let billData = response.data.billData;
                let taskHistory = response.data.taskHistory;
                let taskData = response.data.taskData;

                setTaskReason(taskData.REASON ? taskData.REASON : '');
                setLastNote(getCloseComment(taskHistory));
                setHistory(taskHistory);
                setTaskId(billData.FK2TASK);

                if (billData.USER_THAT_BILLS && billData.USER_THAT_BILLS !== null)
                    setSelectedUser(billData.USER_THAT_BILLS);
                if (billData.USER_APROVE_FREE && billData.USER_APROVE_FREE !== null)
                    setSelectedUser(billData.USER_APROVE_FREE);

                setTaskBillObj(billData.OBJID);
                setSelectedContractor(billData.FK2CONTRACTOR);
                setSelectedBillType(billData.FK2BILL_TYPE);
                if (billData.BILL_NO !== null) {
                    setBillNo(billData.BILL_NO);
                    setBillDate(billData.BILL_DATE);
                    setBillSum(billData.BILL_VALUE);
                } else {
                    setBillNo(null);
                    setBillDate(null);
                    setBillSum(null);
                }
                setIsPayed(billData.IS_PAYED === 1);
                setIsPartial(billData.IS_PARTIAL_PAYED === 1);

                if (billData.RECEIP_NO_PF !== null) {
                    setRecipePf(billData.RECEIP_NO_PF);
                    setRecipePfDate(new Date(billData.RECEIP_DATE_PF));
                    setPayMethod("BF");
                } else {
                    setRecipePf(null);
                    setRecipePfDate(null);
                }

                if (billData.RECEIPT_NO !== null) {
                    setRecipePj(billData.RECEIPT_NO);
                    setRecipePjDate(billData.BILL_DATE);
                    setPayMethod("RECIPE");
                } else {
                    setRecipePj(null);
                    setRecipePjDate(null);
                }
                if (billData.BANK_TRANSFER_DATE !== null) {
                    setPayMethod("BANK");
                    setBankDate(billData.BANK_TRANSFER_DATE);
                } else {
                    setBankDate(null);
                }

                if (billData.BANK_TRANSFER_DATE === null && billData.RECEIP_NO_PF === null && billData.RECEIPT_NO === null) setPayMethod(null)

                if (billData.PARTIAL_PAYED_SUM !== null) {
                    setPartialSum(billData.PARTIAL_PAYED_SUM)
                }

            }
        }
    };

    const getContractor = async () => {
        const result = await axios.get('/api/get_executors', {
            ...requestHeaders.getGetHeader(),
            params: {
                active: true,
                type: 2
            }
        });
        if (result.data.length > 0) {
            setContractorList(result.data)
        } else {
            setContractorList([]);
        }
    };

    const billFieldValidation = () => {
        switch (isPayed) {
            case true:
                switch (payMethod) {
                    case "RECIPE":
                        return billNo !== '' && billSum !== '' && billDate !== '' && recipePj !== '' && recipePjDate !== '';
                    case "BF":
                        return billNo !== '' && billSum !== '' && billDate !== '' && recipePf !== '' && recipePfDate !== '';
                    case "BANK":
                        return billNo !== '' && billSum !== '' && billDate !== '' && bankDate !== '';
                    default: return false;
                };
            case false:
                return billNo !== '' && billSum !== '' && billDate !== '';
        }
    };

    const validateTaskBill = () => {
        fetch('/api/validate_task_bill', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify(
                {
                    FK2CONTRACTOR: selectedContractor,
                    FK2BILL_TYPE: selectedBillType,
                    BILL_NO: billNo,
                    BILL_DATE: billDate !== null ? formatDateFromDb(billDate, 15) : null,
                    BILL_VALUE: billSum,
                    RECEIPT_NO: recipePj,
                    RECEIPT_DATE: recipePjDate !== null ? formatDateFromDb(recipePjDate, 15) : null,
                    IS_PAYED: isPayed,
                    IS_PARTIAL_PAYED: isPartial,
                    USER_THAT_BILLS: selectedUser,
                    USER_APROVE_FREE: selectedUser,
                    RECEIP_NO_PF: recipePf,
                    RECEIP_DATE_PF: recipePfDate !== null ? formatDateFromDb(recipePfDate, 15) : null,
                    BANK_TRANSFER_DATE: bankDate !== null ? formatDateFromDb(bankDate, 15) : null,
                    payMethod: payMethod,
                    RECEIPT_VALIDATION: true,
                    OBJID: taskBillObj,
                    PARTIAL_PAYED_SUM: partialSum,
                    userId: userAuth.id
                }
            )
        })
            .then(response => {
                if (response.status >= 400) {
                    console.log(response)
                }
                return response.json();
            })
            .then(response => {
                console.log(response)
            })
    }

    return (
        <React.Fragment>
            <TaskCommentHistory
                open={commentsHistoryModal}
                tiny={true}
                classes={{}}
                data={history}
                language={language}
                close={() => {
                    setCommnetsHistoryModal(false);
                    reopen();
                }}
            />
            <CustomModal
                fullWidth
                open={open}
                title={lang.BILLING[language]}
                content={
                    <React.Fragment>
                        <Grid container alignItems='center' alignContent="center" justify="center" spacing={2}>
                            <Typography variant="h4">{lang.TASK_DETAILS[language]}</Typography>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    variant='outlined'
                                    multiline
                                    name='reason'
                                    label={lang.TASK_REASON[language]}
                                    onChange={() => { }}
                                    value={taskReason}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    variant='outlined'
                                    multiline
                                    name='reason'
                                    label={lang.TASK_CLOSE_COMMENT[language]}
                                    onChange={() => { }}
                                    value={lastNote}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockAllFields}
                                    required
                                    select
                                    variant='outlined'
                                    name='contractor'
                                    label={lang.CONTRACTOR[language]}
                                    onChange={(e) => setSelectedContractor(e.target.value)}
                                    value={selectedContractor}
                                    fullWidth
                                >
                                    {
                                        contractorList.map(contractor => {
                                            return <MenuItem key={contractor.OBJID} value={contractor.OBJID}>{contractor.NAME}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockAllFields}
                                    required
                                    select
                                    variant='outlined'
                                    name='BillType'
                                    label={lang.TYPE[language]}
                                    onChange={(e) => setSelectedBillType(e.target.value)}
                                    value={selectedBillType}
                                    fullWidth
                                >
                                    {
                                        billTypes.map(type => {
                                            return <MenuItem key={type.OBJID} value={type.OBJID}>{lang[type.BILLING_TYPE][language].toUpperCase()}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            {/* FREE and TO_BILL both need just a responsable user so will be treated in the same section */}
                            <Section render={selectedBillType === 1 || selectedBillType === 3}>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={lockAllFields}
                                        required
                                        select
                                        variant='outlined'
                                        name='user'
                                        label={lang.USER[language]}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        value={selectedUser}
                                        fullWidth
                                    >
                                        {
                                            userList.map(user => {
                                                return <MenuItem key={user.USER_ID} value={user.USER_ID}>{user.USERNAME}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            </Section>
                            {/* BILL SECTION */}
                            <Section render={selectedBillType === 2}>
                                <Grid item xs={6}>
                                    <TextField
                                        disabled={lockAllFields}
                                        required
                                        variant='outlined'
                                        name='bill_no'
                                        type='number'
                                        label={lang.BILL_NO[language]}
                                        onChange={(e) => setBillNo(e.target.value)}
                                        value={billNo}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disabled={lockAllFields}
                                            disableToolbar
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            required
                                            id="date-picker-inline-1"
                                            label="Dată factură"
                                            value={billDate}
                                            inputValue={billDate ? moment(billDate).format('DD/MM/YYYY') : null}
                                            onChange={date => setBillDate(date, 'bill')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={lockAllFields}
                                        required
                                        variant='outlined'
                                        name='bill_sum'
                                        type='number'
                                        label={`${lang.BILL_SUM[language]} RON`}
                                        onChange={(e) => setBillSum(e.target.value)}
                                        value={billSum}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={true}
                                        select
                                        required
                                        variant='outlined'
                                        name='is_payed'
                                        label={lang.IS_PAYED[language]}
                                        onChange={(e) => setIsPayed(e.target.value)}
                                        value={isPayed}
                                        fullWidth
                                    >
                                        <MenuItem value={true}>{lang.YES[language].toUpperCase()}</MenuItem>
                                        <MenuItem value={false}>{lang.NO[language].toUpperCase()}</MenuItem>
                                    </TextField>
                                </Grid>
                                {/* The sub section for paied tasks */}
                                <Section render={isPayed}>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={true}
                                            select
                                            required
                                            variant='outlined'
                                            name='method_pay'
                                            label={lang.PARTIAL_PAYED[language]}
                                            onChange={(e) => setIsPartial(e.target.value)}
                                            value={isPartial}
                                            fullWidth
                                        >
                                            <MenuItem value={true}>{lang.YES[language].toUpperCase()}</MenuItem>
                                            <MenuItem value={false}>{lang.NO[language].toUpperCase()}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Section render={isPartial}>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={true}
                                                required
                                                variant='outlined'
                                                name='partial_payed_sum'
                                                label={lang.PARTIAL_PAYED_SUM[language]}
                                                onChange={(e) => setPartialSum(e.target.value)}
                                                value={partialSum}
                                                fullWidth
                                                type="number"
                                            />
                                        </Grid>
                                    </Section>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={true}
                                            select
                                            required
                                            variant='outlined'
                                            name='method_pay'
                                            label={lang.PAY_METHOD[language]}
                                            onChange={(e) => setPayMethod(e.target.value)}
                                            value={payMethod}
                                            fullWidth
                                        >
                                            <MenuItem value={"RECIPE"}>{lang.CASH_PJ[language].toUpperCase()}</MenuItem>
                                            <MenuItem value={"BF"}>{lang.CASH_PF[language].toUpperCase()}</MenuItem>
                                            <MenuItem value={"BANK"}>{lang.BANK[language].toUpperCase()}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {/* Base on the pay method we have the next subsection */}
                                    <Section render={payMethod === 'RECIPE'}>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled={true}
                                                required
                                                variant='outlined'
                                                name='bill_no'
                                                type='number'
                                                label={lang.RECIPE_NO[language]}
                                                onChange={(e) => setRecipePj(e.target.value)}
                                                value={recipePj}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disabled={true}
                                                    disableToolbar
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    fullWidth
                                                    id="date-picker-inline-y"
                                                    label={lang.RECIPE_DATE[language]}
                                                    value={recipePjDate}
                                                    onChange={date => setRecipePjDate(date, 'bill')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Section>
                                    <Section render={payMethod === 'BANK'}>
                                        <Grid item xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disabled={true}
                                                    disableToolbar
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    fullWidth
                                                    id="date-picker-inline-x"
                                                    label={lang.BANK_TRANSFER_DATE[language]}
                                                    value={bankDate}
                                                    onChange={date => setBankDate(date, 'bill')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Section>
                                    <Section render={payMethod === 'BF'}>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled={true}
                                                variant='outlined'
                                                required
                                                name='bill_no'
                                                type='number'
                                                label={lang.RECIPE_NO_PF[language]}
                                                onChange={(e) => setRecipePf(e.target.value)}
                                                value={recipePf}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disabled={true}
                                                    disableToolbar
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    fullWidth
                                                    id="date-picker-inline-3"
                                                    label={lang.RECIPE_DATE_PF[language]}
                                                    value={recipePfDate}
                                                    onChange={date => setRecipePfDate(date, 'bill')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Section>
                                </Section>
                            </Section>
                            <Fab
                                style={{ margin: '10px' }}
                                color="primary"
                                aria-label="add"
                                variant="extended"
                                onClick={() => {
                                    setCommnetsHistoryModal(true)
                                    close();
                                }}
                            >
                                {lang.COMMENT_TASK_HISTORY[language]}
                            </Fab>
                            <Button
                              color={taskScoringComplete ? "primary" : "secondary"}
                              variant={"contained"}
                              fullWidth
                              onClick={() => setScoreModal(true)}
                              style={{ marginTop: "10px" }}
                            >
                                {
                                    taskScoringComplete ?
                                      lang.TASK_SCORING_COMPLETE[language] :
                                      lang.TASK_SCORING_INCOMPLETE[language]
                                }
                            </Button>
                            {
                                taskId && scoreModal &&
                                  <ScoringModal
                                    language={language}
                                    taskId={taskId}
                                    open={scoreModal}
                                    close={() => {
                                        if(taskId) {
                                            getTaskScoringStatus();
                                        }
                                        setScoreModal(false);
                                    }}
                                    value={+billSum}
                                  />
                            }
                        </Grid>
                    </React.Fragment>
                }
                close={close}
                execute={() => {
                    validateTaskBill();
                    props.refeatchTaskData();
                    close();
                }}
                language={language}
                formValidate={(selectedBillType !== 3) && taskScoringComplete}
                validate={
                    taskScoringComplete && (!lockAllFields && selectedBillType !== null && selectedBillType !== 3 && (selectedBillType === 2 ? billFieldValidation() : selectedUser !== null))
                }
            />
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingModal);