export const REPORT_ACTIVITY = {
    "en": "Activity report #1",
    "ro": "Raport activitate #1",
}
export const REPORT_ACTIVITY_2 = {
    "en": "Activity report #2",
    "ro": "Raport activitate #2",
}
export const REPORT_DATE_RANGE = {
    "en": "Select an interval for the report",
    "ro": "Selectați un interval pentru raportare",
}
export const REPORT_START_DATE = {
    "en": "Start date",
    "ro": "Dată început",
}
export const REPORT_END_DATE = {
    "en": "End date",
    "ro": "Dată sfârșit",
}
export const WRONG_PV_REPORT = {
    en: "Wrong PV report",
    ro: "Raport PV eronate"
}

export const WRONG_PV_NO = {
    en: "No of wrong PV",
    ro: "Număr PV greșite"
}