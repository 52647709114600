import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button, CircularProgress
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";

import * as lang from "../../Constants/strings";

import * as requestHeaders from "../../Constants/requestHeaders";
import { handleAsyncCall } from "../../Functions/async_utils";
import VirtualizeAutocomplete from "../../Components/Misc/VirtualizatAutocomplete";

const FilterHeader = (props) => {
  const {
    language,
    getData,
    generateExcel
  } = props;

  const [contractTypes, setContractTypes] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [executorList, setExecutorList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [billingAssignee, setBillingAssignee] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [mainContractType, setMainContractType] = useState('');

  const [customerTypes, setCustomerTypes] = useState([]);
  const [subContractTypes, setSubContractTypes] = useState([]);
  const [selectedExecutors, setSelectedExecutors] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedBillingCycles, setSelectedBillingCycles] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [isDownloading, setIsDownloading] = useState(false);

  const getContractType = async () => {
    let [data, error] = await handleAsyncCall(
      () => axios.get('/api/contract_types', requestHeaders.getGetHeader())
    );
    if (data) setContractTypes(data.data)
  };

  const getCustomer = async (name, type, limit) => {
    let [data, error] = await handleAsyncCall(
      () => axios.get('/api/search_customer_name', {
        ...requestHeaders.getGetHeader(),
        params: {
          name,
          limit,
          type: type !== '' ? type : undefined
        }
      })
    );

    if (data) {
      setCustomerList(data.data);
    };

    if (error) {
      setCustomerList([]);
    };

  };

  const getExecutors = async () => {
    let [response, error] = await handleAsyncCall(
      () => axios.get('/api/executors_active', requestHeaders.getGetHeader())
    );

    if (response) setExecutorList(response.data);
  };

  const getAreas = async () => {
    let [response, error] = await handleAsyncCall(
      () => axios.get('/api/areas_active', requestHeaders.getGetHeader())
    );

    if (response) setAreaList(response.data);
  };

  const getAssignees = async () => {
    let [response, error] = await handleAsyncCall(
      () => axios.get('/api/contract_assignee_list', requestHeaders.getGetHeader())
    )
    if (response) setBillingAssignee(response.data)
  };


  useEffect(() => {
    getContractType();
    getCustomer();
    getExecutors();
    getAreas();
    getAssignees();
  }, []);

  useEffect(() => {
    getData({
      billingCycles: selectedBillingCycles,
      assigneeObjIds: selectedAssignees.map(e => e.OBJID),
      assigneeTeams: selectedAreas.map(e => e.AREA_CODE),
      customerTypes: customerTypes,
      contractorNames: selectedExecutors.map(e => e.NAME),
      saleContactObjIds: selectedAssignees.map(e => e.OBJID),
      typeId: mainContractType.ID,
      subTypeId: subContractTypes.map(e => e.CODE),
      startDate: startDate,
      endDate: endDate,
      customerName: selectedCustomer ? selectedCustomer.ID : undefined
    })
  }, [
    selectedBillingCycles, 
    selectedAssignees,
    selectedAreas,
    customerTypes,
    selectedExecutors,
    selectedAssignees,
    contractTypes,
    subContractTypes,
    startDate,
    endDate,
    selectedCustomer,
    mainContractType
  ]);

  const download = useCallback(() => {
    (async () => {
      setIsDownloading(true);
      await generateExcel({
        billingCycles: selectedBillingCycles,
        assigneeObjIds: selectedAssignees.map(e => e.OBJID),
        assigneeTeams: selectedAreas.map(e => e.AREA_CODE),
        customerTypes: customerTypes,
        contractorNames: selectedExecutors.map(e => e.NAME),
        saleContactObjIds: selectedAssignees.map(e => e.OBJID),
        typeId: mainContractType.ID,
        subTypeId: subContractTypes.map(e => e.CODE),
        startDate: startDate,
        endDate: endDate,
        customerName: selectedCustomer ? selectedCustomer.ID : undefined
      });
      setIsDownloading(false);
    })();

  }, [selectedBillingCycles, selectedAssignees, selectedAreas, customerTypes, selectedExecutors, selectedAssignees, contractTypes, subContractTypes, startDate, endDate, selectedCustomer]);

  return (
    <Paper style={{ width: "100%" }}>
      <Grid container spacing={2} style={{ padding: "5px" }}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={lang.CONTRACT_TYPE[language]}
            value={mainContractType}
            onChange={(e) => {
              setMainContractType(e.target.value);
              setSubContractTypes([]);
            }}
          >
            {
              contractTypes.map((type, index) => {
                if (!type.CODE) {
                  return <MenuItem key={`type_${index}`} value={type}>{lang[type.DESCRIPTION_TEXT][language]}</MenuItem>
                }
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={lang.SUBTYPE[language]}
            SelectProps={{
              multiple: true,
              value: subContractTypes,
              onChange: (e) => setSubContractTypes(e.target.value)
            }}
          >
            {
              contractTypes.map((type, index) => {
                if (mainContractType ? type.CODE && type.SUB_TYPE === mainContractType.SUB_TYPE : type.CODE) {
                  return <MenuItem
                    key={`sub_type_${index}`}
                    value={type}
                  >
                    {lang[type.DESCRIPTION_TEXT][language]}
                  </MenuItem>
                }
              })
            }
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={lang.CONTRACTOR[language]}
            SelectProps={{
              multiple: true,
              value: selectedExecutors,
              onChange: (e) => setSelectedExecutors(e.target.value)
            }}
          >
            {
              executorList.map((executor, index) => <MenuItem key={`exec_${index}`} value={executor}>{executor.NAME}</MenuItem>)
            }
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={lang.ASSIGNED_TEAM[language]}
            SelectProps={{
              multiple: true,
              value: selectedAreas,
              onChange: (e) => setSelectedAreas(e.target.value)
            }}
          >
            {
              areaList.map((area, index) => <MenuItem key={`area_${index}`} value={area}>{area.AREA}</MenuItem>)
            }
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={lang.BILLING_ASSIGNEE[language]}
            SelectProps={{
              multiple: true,
              value: selectedAssignees,
              onChange: (e) => setSelectedAssignees(e.target.value)
            }}
          >
            {
              billingAssignee.map((e, index) => <MenuItem key={`assi_${index}`} value={e}>{e.USERNAME}</MenuItem>)
            }
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={lang.BILL_CYCLE[language]}
            SelectProps={{
              multiple: true,
              value: selectedBillingCycles,
              onChange: (e) => setSelectedBillingCycles(e.target.value)
            }}
          >
            <MenuItem value={1}>{lang.BILL_CURRENT_MONTH[language]}</MenuItem>
            <MenuItem value={-1}>{lang.BILL_LAST_MONTH[language]}</MenuItem>
            <MenuItem value={3}>{lang.BILL_QUARTER[language]}</MenuItem>
            <MenuItem value={2}>{lang.BILL_SEMESTER[language]}</MenuItem>
            <MenuItem value={4}>{lang.BILL_YEARLY[language]}</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <VirtualizeAutocomplete
            fullWidth={true}
            label={lang.CUSTOMER[language]}
            onInputChange={(e) => getCustomer(e.target.value, customerTypes)}
            options={customerList}
            renderElementLabel="NAME"
            onSelect={(e, newVal) => {
              console.log(e, newVal)
              setSelectedCustomer(newVal)
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              required
              inputVariant="outlined"
              fullWidth
              name="start-date"
              label={lang.START_INTERVAL_DATE[language]}
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": `change start-date`,
              }}
              value={startDate}
              onChange={(value) => setStartDate(value)}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              required
              inputVariant="outlined"
              fullWidth
              name="end-date"
              label={lang.END_DATE[language]}
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": `change end date`,
              }}
              value={endDate}
              onChange={(value) => setEndDate(value)}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={2}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label={`${lang.CUST_TYPE[language]} ${lang.CUSTOMER[language]}`}
            SelectProps={{
              multiple: true,
              value: customerTypes,
              onChange: (e) => {
                setSelectedCustomer('');
                getCustomer(selectedCustomer, e.target.value)
                setCustomerTypes(e.target.value)
              }
            }}
          >
            <MenuItem value="PJ">Persoană juridică</MenuItem>
            <MenuItem value="PF">Persoană fizică</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6} style={{position: "relative"}}>
          <Button fullWidth variant="contained" color="primary" onClick={download}>
            {lang.DOWNLOAD_FILE[language]}
          </Button>
          {
              isDownloading && <div style={{
                "top": "0",
                "z-index": "10",
                "width": "100%",
                "height": "100%",
                "position": "absolute",
                "background-color": "#ffffff40",
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
              }}><CircularProgress disableShrink/></div>
          }
        </Grid>

      </Grid>
    </Paper>
  )
};

FilterHeader.propTypes = {
  language: PropTypes.oneOf(['ro', 'en']),
  getData: PropTypes.func,
  generateExcel: PropTypes.func
};

export default FilterHeader;
