import React from 'react';
import { Box, Grid, Avatar, Typography, makeStyles, Button } from '@material-ui/core';
import getAvatarUri from '../../../Functions/getDataUriAvatar';
import LockIcon from '@material-ui/icons/LockRounded';
import UnlockIcon from '@material-ui/icons/LockOpenRounded';
import VisibleIcon from '@material-ui/icons/VisibilityRounded';
import { PersonPinCircle } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import CustomModal from '../../../Components/Modals/CustomModal';

const useStyles = makeStyles(theme => ({
    bigAvatar: {
        margin: theme.spacing(-1.3),
        width: 150,
        height: 150,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
}));

export function UserSummary(props) {
    const classes = useStyles();
    const [userData, setUserData] = React.useState(null);
    const [confirmLockModal, setConfirmLock] = React.useState(false);
    const getUserData = (id) => {
        fetch(`/api/user_data?id=${id}&type=${0}`, {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setUserData(response.data.basic)
            })
            .catch(err => console.log(err))
    }
    React.useEffect(() => {
        getUserData(props.id);
    }, [props.id])
    return (
        <React.Fragment>
            <CustomModal
                open={confirmLockModal}
                title={userData && userData.LOCKED === 1 ? lang.UNLOCK_USER[props.language] : lang.LOCK_USER[props.language]}
                content={userData && userData.LOCKED === 1 ? lang.CONFIRM_UNLOCK_USER[props.language] : lang.CONFIRM_LOCK_USER[props.language]}
                close={() => setConfirmLock(false)}
                execute={() => {
                    let newLock = userData.LOCKED ? 0 : 1;
                    setUserData({
                        ...userData,
                        basic: {
                            ...userData.basic,
                            LOCKED: newLock
                        }
                    })
                    props.blockFunction(newLock);
                    setConfirmLock(false);
                    props.close();
                }}
                validate={true}
                language={props.language}
            />
            <Grid container justify="center" alignItems="center" direction="column">
                <Box border={5} borderRadius={100} color="white" >
                    <Avatar alt={userData ? userData.AVATAR_ID + "" : ""} src={getAvatarUri(userData ? userData.AVATAR_ID : 1)} className={classes.bigAvatar} />
                </Box>
                {userData && <Typography variant="h6">{userData.FIRST_NAME}{" "}{userData.LAST_NAME}</Typography>}
                {userData && <Typography variant="body2">{userData.USERNAME}</Typography>}
                <div className={classes.buttons}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={props.goToFunction}
                    >
                        <VisibleIcon className={classes.leftIcon} />
                        {lang.GO_TO(lang.USER[props.language])[props.language]}
                    </Button>
                    {/* <CanVisible
                        visible={props.userData && props.userID === props.userData.USER_ID}
                    > */}
                    {
                        userData && userData.LOCKED !== 1 &&
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={() => {
                                props.openInterventionArea();
                                props.close();
                            }}
                        >
                            <React.Fragment>
                                <PersonPinCircle className={classes.leftIcon} />
                                {lang.SELECT_USER_INTERVENTION_ASSIGN_AREA[props.language]}
                            </React.Fragment>
                        </Button>
                    }
                    {userData &&
                        <Button
                            variant="outlined"
                            color={userData.LOCKED ? "default" : "secondary"}
                            className={classes.button}
                            onClick={() => setConfirmLock(true)}
                        >
                            {userData.LOCKED === 1
                                ?
                                <React.Fragment>
                                    <UnlockIcon className={classes.leftIcon} />
                                    {lang.UNLOCK_USER[props.language]}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <LockIcon className={classes.leftIcon} />
                                    {lang.LOCK_USER[props.language]}
                                </React.Fragment>
                            }
                        </Button>
                    }
                    {
                        userData &&
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={() => props.externalUserModal()}
                        >
                            <React.Fragment>
                                <PersonPinCircle className={classes.leftIcon} />
                                Selecteaza utilizator extern (inventar)
                            </React.Fragment>
                        </Button>
                    }
                    {/* </CanVisible> */}
                </div>
            </Grid>
        </React.Fragment>
    );
}
