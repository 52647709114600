import * as actionTypes from '../actions/userActions';

const initialState = {
    isFetchingUserData: false,
    isUpdatingUserData: false,
    isSubmittingRole: false,
    isUpdatingRole: false,
    userData: null,
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.USER_DATA_REQUEST: {
            return {
                ...state,
                isFetchingUserData: true,
                userData: null,
            }
        }
        case actionTypes.USER_DATA_RECIEVE: {
            return {
                ...state,
                isFetchingUserData: false,
                userData: action.payload.data,
            }
        }
        case actionTypes.UPDATE_USER_DATA_BEGIN: {
            return {
                ...state,
                isUpdatingUserData: true,
            }
        }
        case actionTypes.UPDATE_USER_DATA_SUCCESS: {
            return {
                ...state,
                isUpdatingUserData: false,
                userData: action.payload.data,
            }
        }
        case actionTypes.ADD_ROLE_BEGIN: {
            return {
                ...state,
                isSubmittingRole: true,
            }
        }
        case actionTypes.ADD_ROLE_FAILED:
        case actionTypes.ADD_ROLE_SUCCESS: {
            return {
                ...state,
                isSubmittingRole: false,
            }
        }
        case actionTypes.UPDATE_USER_DEPARTMENT_BEGIN:
        case actionTypes.UPDATE_USER_ROLE_BEGIN: {
            return {
                ...state,
                isUpdatingUserData: true,
            }
        }
        case actionTypes.UPDATE_USER_ROLE_SUCCESS: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    basic: {
                        ...state.userData.basic,
                        ROLE: action.payload.roleName,
                        ROLE_ID: action.payload.roleID
                    }
                },
                isUpdatingUserData: false,
            }
        }
        case actionTypes.UPDATE_USER_DEPARTMENT_SUCCESS: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    basic: {
                        ...state.userData.basic,
                        DEPARTMENT_NAME: action.payload.name,
                        DEPARTMENT_ID: action.payload.id
                    }
                },
                isUpdatingUserData: false,
            }
        }
        case actionTypes.UPDATE_USER_DEPARTMENT_FAILED:
        case actionTypes.UPDATE_USER_ROLE_FAILED: {
            return {
                ...state,
                isUpdatingUserData: false,
            }
        }
        case actionTypes.UPDATE_USER_LOCKED: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    basic: {
                        ...state.userData.basic,
                        LOCKED: action.payload.data
                    }
                },
            }
        }
        case actionTypes.UPDATE_ROLE_BEGIN: {
            return {
                ...state,
                isUpdatingRole: true,
            }
        }
        case actionTypes.UPDATE_ROLE_FAILED:
        case actionTypes.UPDATE_ROLE_SUCCESS: {
            return {
                ...state,
                isUpdatingRole: false,
            }
        }
        default:
            return state;
    }
}