import {
    SignInUser,
    // SignInFailure,
    OpenConfirmWindow,
    LogoutAndRedirect
} from './authActions';
import { onNotificationOpen } from './configActions';
import * as lang from '../Constants/strings';

export const EXECUTOR_LIST_REQUEST = "EXECUTOR_LIST_REQUEST";
export const EXECUTOR_LIST_RECIEVE = "EXECUTOR_LIST_RECIEVE";


export function onExecutorListRequest() {
    return {
        type: EXECUTOR_LIST_REQUEST
    }
};

export function onExecutorListRecieve(data) {
    return {
        type: EXECUTOR_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getExecutorList() {
    return (dispatch) => {
        dispatch(onExecutorListRequest());
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/executors`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                dispatch(onExecutorListRecieve(response.data));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}

export const ADD_EXECUTOR_BEGIN = 'ADD_EXECUTOR_BEGIN';
export const ADD_EXECUTOR_SUCCESS = 'ADD_EXECUTOR_SUCCESS';
export const ADD_EXECUTOR_FAILED = 'ADD_EXECUTOR_FAILED';

export function onAddExecutorBegin() {
    return {
        type: ADD_EXECUTOR_BEGIN
    }
};
export function onAddExecutorSuccess(data) {
    return {
        type: ADD_EXECUTOR_SUCCESS,
        payload: {
            data: data
        }
    }
};
export function onAddExecutorFailed() {
    return {
        type: ADD_EXECUTOR_FAILED
    }
};

export function addNewExecutor(id, data) {
    return (dispatch) => {
        dispatch(onAddExecutorBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_executor`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onAddExecutorFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddExecutorSuccess({ ...data, OBJID: response.data, ACTIVE: 1, STATUS: "active" }));
                    dispatch(getExecutorList());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddExecutorFailed());
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_EXECUTOR_BEGIN = 'UPDATE_EXECUTOR_BEGIN';
export const UPDATE_EXECUTOR_SUCCESS = 'UPDATE_EXECUTOR_SUCCESS';
export const UPDATE_EXECUTOR_FAILED = 'UPDATE_EXECUTOR_FAILED';

export function onUpdateExecutorBegin() {
    return {
        type: UPDATE_EXECUTOR_BEGIN
    }
};
export function onUpdateExecutorSuccess() {
    return {
        type: UPDATE_EXECUTOR_SUCCESS,
    }
};
export function onUpdateExecutorFailed() {
    return {
        type: UPDATE_EXECUTOR_FAILED
    }
};


export function updateExecutor(id, data) {
    return (dispatch) => {
        dispatch(onUpdateExecutorBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/update_executor`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onUpdateExecutorFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateExecutorSuccess());
                    dispatch(getExecutorList());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateExecutorFailed());
                console.log('caught it!', err);
            })
    }
}
export function toggleExecutor(id, data) {
    return (dispatch) => {
        dispatch(onUpdateExecutorBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/toggle_executor?id=${id}&ACTIVE=${data.ACTIVE}&OBJID=${data.OBJID}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onUpdateExecutorFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateExecutorSuccess());
                    dispatch(getExecutorList());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateExecutorFailed());
                console.log('caught it!', err);
            })
    }
}

export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST";
export const ROLE_LIST_RECIEVE = "ROLE_LIST_RECIEVE";


export function onRoleListRequest() {
    return {
        type: ROLE_LIST_REQUEST
    }
};

export function onRoleListRecieve(data) {
    return {
        type: ROLE_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getRoleList() {
    return (dispatch) => {
        dispatch(onRoleListRequest());
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/roles`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                dispatch(onRoleListRecieve(response.data));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}

export const AREA_LIST_REQUEST = "AREA_LIST_REQUEST";
export const AREA_LIST_RECIEVE = "AREA_LIST_RECIEVE";


export function onAreaListRequest() {
    return {
        type: AREA_LIST_REQUEST
    }
};

export function onAreaListRecieve(data) {
    return {
        type: AREA_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getAreaList() {
    return (dispatch) => {
        dispatch(onAreaListRequest());
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/areas`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                dispatch(onAreaListRecieve(response.data));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}
export const ADD_AREA_BEGIN = 'ADD_AREA_BEGIN';
export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';
export const ADD_AREA_FAILED = 'ADD_AREA_FAILED';

export function onAddAreaBegin() {
    return {
        type: ADD_AREA_BEGIN
    }
};
export function onAddAreaSuccess(data) {
    return {
        type: ADD_AREA_SUCCESS,
        payload: {
            data: data
        }
    }
};
export function onAddAreaFailed() {
    return {
        type: ADD_AREA_FAILED
    }
};

export function addNewArea(id, data) {
    return (dispatch) => {
        dispatch(onAddAreaBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_area`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onAddAreaFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddAreaSuccess({ AREA_CODE: response.data, AREA: data.NAME, STATUS: 'active', COUNT: 0 }));
                    dispatch(getAreaList());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddAreaFailed());
                console.log('caught it!', err);
            })
    }
}

export const DEPARTMENT_LIST_REQUEST = "DEPARTMENT_LIST_REQUEST";
export const DEPARTMENT_LIST_RECIEVE = "DEPARTMENT_LIST_RECIEVE";


export function onDepartmentListRequest() {
    return {
        type: DEPARTMENT_LIST_REQUEST
    }
};

export function onDepartmentListRecieve(data) {
    return {
        type: DEPARTMENT_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getDepartmentList(type) {
    let url = type === 0 ? `/api/departments` : `/api/departments_active`;
    return (dispatch) => {
        dispatch(onDepartmentListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(url, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onDepartmentListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onDepartmentListRecieve(response.data));
                    // dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onDepartmentListRecieve([]));
                console.log('caught it!', err);
            })
    }
}

export const ADD_DEPARTMENT_BEGIN = 'ADD_DEPARTMENT_BEGIN';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAILED = 'ADD_DEPARTMENT_FAILED';

export function onAddDepartmentBegin() {
    return {
        type: ADD_DEPARTMENT_BEGIN
    }
};
export function onAddDepartmentSuccess(data) {
    return {
        type: ADD_DEPARTMENT_SUCCESS,
        payload: {
            data: data
        }
    }
};
export function onAddDepartmentFailed() {
    return {
        type: ADD_DEPARTMENT_FAILED
    }
};

export function addNewDepartment(id, data) {
    return (dispatch) => {
        dispatch(onAddDepartmentBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_department?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response);
                if (response.error === 1) {
                    dispatch(onAddDepartmentFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddDepartmentSuccess());
                    dispatch(getDepartmentList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddDepartmentFailed());
                console.log('caught it!', err);
            })
    }
}

export const SITE_TYPE_LIST_REQUEST = "SITE_TYPE_LIST_REQUEST";
export const SITE_TYPE_LIST_RECIEVE = "SITE_TYPE_LIST_RECIEVE";

export function onSiteTypeListRequest() {
    return {
        type: SITE_TYPE_LIST_REQUEST
    }
};

export function onSiteTypeListRecieve(data) {
    return {
        type: SITE_TYPE_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
}


export function getSiteTypeList(type) {
    return (dispatch) => {
        dispatch(onSiteTypeListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/site_types?type=${type}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteTypeListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onSiteTypeListRecieve(response.data));
                    // dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onSiteTypeListRecieve([]));
                console.log('caught it!', err);
            })
    }
}

export const ADD_SITE_TYPE_BEGIN = 'ADD_SITE_TYPE_BEGIN';
export const ADD_SITE_TYPE_SUCCESS = 'ADD_SITE_TYPE_SUCCESS';
export const ADD_SITE_TYPE_FAILED = 'ADD_SITE_TYPE_FAILED';

export function onAddSiteTypeBegin() {
    return {
        type: ADD_SITE_TYPE_BEGIN
    }
};
export function onAddSiteTypeSuccess(data) {
    return {
        type: ADD_SITE_TYPE_SUCCESS,
        payload: {
            data: data
        }
    }
};
export function onAddSiteTypeFailed() {
    return {
        type: ADD_SITE_TYPE_FAILED
    }
};

export function addNewSiteType(id, data) {
    return (dispatch) => {
        dispatch(onAddSiteTypeBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_site_type?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response);
                if (response.error === 1) {
                    dispatch(onAddSiteTypeFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddSiteTypeSuccess());
                    dispatch(getSiteTypeList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddSiteTypeFailed());
                console.log('caught it!', err);
            })
    }
}
export const UPDATE_SITE_TYPE_BEGIN = 'UPDATE_SITE_TYPE_BEGIN';
export const UPDATE_SITE_TYPE_SUCCESS = 'UPDATE_SITE_TYPE_SUCCESS';
export const UPDATE_SITE_TYPE_FAILED = 'UPDATE_SITE_TYPE_FAILED';

export function onUpdateSiteTypeBegin() {
    return {
        type: UPDATE_SITE_TYPE_BEGIN
    }
};
export function onUpdateSiteTypeSuccess() {
    return {
        type: UPDATE_SITE_TYPE_SUCCESS,
    }
};
export function onUpdateSiteTypeFailed() {
    return {
        type: UPDATE_SITE_TYPE_FAILED
    }
};


export function updateSiteType(id, data) {
    return (dispatch) => {
        dispatch(onUpdateSiteTypeBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/update_site_type`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onUpdateSiteTypeFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateSiteTypeSuccess());
                    dispatch(getSiteTypeList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateSiteTypeFailed());
                console.log('caught it!', err);
            })
    }
}
export function toggleSiteType(id, data) {
    return (dispatch) => {
        dispatch(onUpdateSiteTypeBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/toggle_site_type?id=${id}&ACTIVE=${data.ACTIVE}&OBJID=${data.OBJID}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onUpdateSiteTypeFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateSiteTypeSuccess());
                    dispatch(getSiteTypeList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateSiteTypeFailed());
                console.log('caught it!', err);
            })
    }
}

// SITE AREAS

export const SITE_AREA_LIST_REQUEST = "SITE_AREA_LIST_REQUEST";
export const SITE_AREA_LIST_RECIEVE = "SITE_AREA_LIST_RECIEVE";

export function onSiteAreaListRequest() {
    return {
        type: SITE_AREA_LIST_REQUEST
    }
};

export function onSiteAreaListRecieve(data) {
    return {
        type: SITE_AREA_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
}


export function getSiteAreaList(type) {
    return (dispatch) => {
        dispatch(onSiteAreaListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/site_areas?type=${type}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteAreaListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onSiteAreaListRecieve(response.data));
                    // dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onSiteAreaListRecieve([]));
                console.log('caught it!', err);
            })
    }
}

export const ADD_SITE_AREA_BEGIN = 'ADD_SITE_AREA_BEGIN';
export const ADD_SITE_AREA_SUCCESS = 'ADD_SITE_AREA_SUCCESS';
export const ADD_SITE_AREA_FAILED = 'ADD_SITE_AREA_FAILED';

export function onAddSiteAreaBegin() {
    return {
        type: ADD_SITE_AREA_BEGIN
    }
};
export function onAddSiteAreaSuccess(data) {
    return {
        type: ADD_SITE_AREA_SUCCESS,
        payload: {
            data: data
        }
    }
};
export function onAddSiteAreaFailed() {
    return {
        type: ADD_SITE_AREA_FAILED
    }
};

export function addNewSiteArea(id, data) {
    return (dispatch) => {
        dispatch(onAddSiteAreaBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_site_area?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response);
                if (response.error === 1) {
                    dispatch(onAddSiteAreaFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddSiteAreaSuccess());
                    dispatch(getSiteAreaList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddSiteAreaFailed());
                console.log('caught it!', err);
            })
    }
}
export const UPDATE_SITE_AREA_BEGIN = 'UPDATE_SITE_AREA_BEGIN';
export const UPDATE_SITE_AREA_SUCCESS = 'UPDATE_SITE_AREA_SUCCESS';
export const UPDATE_SITE_AREA_FAILED = 'UPDATE_SITE_AREA_FAILED';

export function onUpdateSiteAreaBegin() {
    return {
        type: UPDATE_SITE_AREA_BEGIN
    }
};
export function onUpdateSiteAreaSuccess() {
    return {
        type: UPDATE_SITE_AREA_SUCCESS,
    }
};
export function onUpdateSiteAreaFailed() {
    return {
        type: UPDATE_SITE_AREA_FAILED
    }
};


export function updateSiteArea(id, data) {
    return (dispatch) => {
        dispatch(onUpdateSiteAreaBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/update_site_area`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onUpdateSiteAreaFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateSiteAreaSuccess());
                    dispatch(getSiteAreaList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateSiteAreaFailed());
                console.log('caught it!', err);
            })
    }
}
export function toggleSiteArea(id, data) {
    return (dispatch) => {
        dispatch(onUpdateSiteAreaBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/toggle_site_area?id=${id}&ACTIVE=${data.ACTIVE}&OBJID=${data.OBJID}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onUpdateSiteAreaFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateSiteAreaSuccess());
                    dispatch(getSiteAreaList(0));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateSiteAreaFailed());
                console.log('caught it!', err);
            })
    }
}