import * as actionTypes from '../actions/custActions';
import { getVerifyIcon } from '../Functions/getIcon';

const initialState = {
    isSubmitingNewCustomer: false,

    isUpdatingCustomerData: false,

    isFetchingCustomerList: false,
    customerList: [],

    isFetchingCustomerData: false,
    customerData: {},

    isFetchingSiteData: false,
    siteData: {},

    isUpdatingSiteData: false,

    newCustomerID: {},
    isSubmitingNewContract: false,

    contractList: [],
    isFetchingContractList: false,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADD_CUSTOMER_BEGIN:
            return {
                ...state,
                isSubmitingNewCustomer: true,
            }
        case actionTypes.ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                newCustomerID: action.payload.data.response.CONTACT_OBJID
            }
        case actionTypes.ADD_CUSTOMER_FAILED:
            return {
                ...state,
                isSubmitingNewCustomer: false,
            }
        case actionTypes.CUSTOMER_LIST_REQUEST:
            return {
                ...state,
                isFetchingCustomerList: true,
            }
        case actionTypes.CUSTOMER_LIST_RECIEVE:
            return {
                ...state,
                isFetchingCustomerList: false,
                customerList: getVerifyIcon(action.payload.data),
            }
        case actionTypes.CUSTOMER_DATA_REQUEST:
            return {
                ...state,
                isFetchingCustomerData: true,
            }
        case actionTypes.CUSTOMER_DATA_RECIEVE:
            let cusData = action.payload.data;
            if (cusData.site) {
                let customerSite = getVerifyIcon(cusData.site);
                cusData.site = customerSite
            }
            return {
                ...state,
                isFetchingCustomerData: false,
                customerData: cusData,
            }
        case actionTypes.CUSTOMER_DATA_UPDATE_BEGIN:
            return {
                ...state,
                isUpdatingCustomerData: true,
            }
        case actionTypes.CUSTOMER_DATA_UPDATE_SUCCESS:
        case actionTypes.CUSTOMER_DATA_UPDATE_FAILED:
            return {
                ...state,
                isUpdatingCustomerData: false,
            }
        case actionTypes.SITE_DATA_REQUEST:
            return {
                ...state,
                isFetchingSiteData: true,
            }
        case actionTypes.SITE_DATA_RECIEVE:
            return {
                ...state,
                isFetchingSiteData: false,
                siteData: action.payload.data,
            }
        case actionTypes.CONTRACT_LIST_REQUEST:
            return {
                ...state,
                isFetchingContractList: true,
            }
        case actionTypes.CONTRACT_LIST_RECIEVE:
            return {
                ...state,
                isFetchingContractList: false,
                contractList: action.payload.data,
            }
        case actionTypes.SITE_DATA_UPDATE_BEGIN:
            return {
                ...state,
                isUpdatingSiteData: true,
            }
        case actionTypes.SITE_DATA_UPDATE_SUCCESS:
        case actionTypes.SITE_DATA_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdatingSiteData: false,
            }
        case actionTypes.NEW_FETCH_CUSTOMER:
            let oldList = state.customerList;
            return {
                ...state,
                isFetchingCustomerList: false,
                customerList: oldList.concat(getVerifyIcon(action.payload.data))
            }
        case actionTypes.CONTRACT_ADD_BEGIN:
            return {
                ...state,
                isSubmitingNewContract: true,
            }
        case actionTypes.CONTRACT_ADD_SUCCESS:
        case actionTypes.CONTRACT_ADD_FAILED:
            return {
                ...state,
                isSubmitingNewContract: false,
            }
        default:
            return state;
    }
}