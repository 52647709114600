import React from 'react';
import { Grid, Paper, Typography, Divider, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import * as lang from '../../../Constants/strings';


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    paperBgLight: {
        height: '120px',
        padding: theme.spacing(2),
        backgroundImage: `url(/api/image?image=landing-page-light.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: '100%',
    },
    paperBgDark: {
        height: '120px',
        padding: theme.spacing(2),
        backgroundImage: `url(/api/image?image=landing-page-dark.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: '100%',
    },
    divier: {
        marginTop: theme.spacing(2),
    },
}));
export default function ProfileWork(props) {
    const classes = useStyles();
    return (
        <Grid item xs={12} md={6} container direction="column">
        <Grow in={props.edit === false} style={{ transitionDelay: props.edit === false ? '100ms' : '0ms' }}>
                <Paper className={classes.paper}>
                    <Typography variant="body1" align="center">{lang.ASSIGNED_WORK[props.language]}</Typography>
                    <Divider className={classes.divier} />
                    <br />
                    <Typography variant="caption" align="center">{lang.NO_RESULT[props.language]}</Typography>
                    {/* <Paper elevation={0} className={props.theme === 'dark' ? classes.paperBgDark : classes.paperBgLight} /> */}
                </Paper>
            </Grow>
        </Grid>
    );
}
export function SkeletonProfileWork(props) {
    return (
        <Grid item xs={12} md={6} container direction="column">
            <Skeleton component={Paper} className={props.classes.paper} height="30vh">
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="30%" />
                    <br />
                    <Skeleton width="70%" />
                    <Skeleton width="70%" />
                    <Skeleton width="70%" />
                </Grid>
            </Skeleton>
        </Grid>
    );
}