import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import * as lang from '../../../Constants/strings';
import { Grid, CssBaseline, Typography, makeStyles, Paper } from '@material-ui/core';
import SideTabPanel from '../../../Components/Misc/SideTabPanel';
import { Mail } from '@material-ui/icons';
import MailingListEditor from './MailingComponents/MailingListEditor';

const useStyles = makeStyles(theme => ({
    mailIcon: {
        fontSize: 70
    },
    paper: {
        marginTop: theme.spacing(10),
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        height: '100%',
    },
    title: {
        margin: theme.spacing(3),
    },
}));

// This will create a template for the drawer Labels
function DrawerLabel(props) {
    const { index, selectedIndex } = props;
    const classes = useStyles();
    return (
        <Grid container direction='row' alignItems='center' justify='center' alignContent='center'>
            <Grid item xs={6}>
                <Mail className={classes.mailIcon} color={index === selectedIndex ? "secondary" : "disabled"} />
            </Grid>
            <Grid item xs={6}>
                <Typography variant='subtitle2' >{props.title}</Typography>
            </Grid>
        </Grid>
    )
};

function MailingListHeader(props) {
    const { title } = props;
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid container justify="center" alignItems="center" direction="row">
                <Typography className={classes.title} variant="h4">{title}</Typography>
            </Grid>
        </Paper>
    )
}

class MailingNom extends Component {
    state = {
        selectedTab: 0,
        userList: [],
        mailingLists: []
    };

    getItems() {
        fetch('/api/users', {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                let i = 0;
                let userList = [];
                response.data.userList.map(user => {
                    i++;
                    user["NR"] = i;
                    if (user.LOCKED !== 1)
                        userList.push(user)
                })
                this.setState({ userList: userList })
            })
            .catch(err => console.log(err))
    }

    // Get the mailing lists from the backend
    // This will be render in the left side of the drawer as labels
    getMailingList() {
        fetch('/api/get_mailing_lists', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                this.setState({ mailingLists: response.data });
            })
    };

    componentDidMount() {
        this.getItems();
        this.getMailingList();
    };


    // Return a list of objects
    // Use by SideTabPanel in the tabs prop
    // This list will be render as labels on the left side of the component

    getTabsLabels() {
        let list = [];
        this.state.mailingLists.map(mailList => {
            list.push({
                name: <DrawerLabel title={mailList.MAILING_LIST_NAME} index={this.state.mailingLists.indexOf(mailList)} selectedIndex={this.state.selectedTab} />,
                index: this.state.mailingLists.indexOf(mailList)
            })
        })
        return list;
    };

    // Return the list of componet that is render by the SideTabPanel for a gived selected tab
    getTabComponets() {
        let componentList = [];
        this.state.mailingLists.map(mailList => {
            componentList.push({
                index: this.state.mailingLists.indexOf(mailList),
                component: <MailingListEditor refetchMailList={() => this.getMailingList()} mailListData={mailList} mailListId={mailList.OBJID} userList={this.state.userList} language={this.props.language} />
            })
        })
        return componentList;
    };

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <MailingListHeader title={lang.MAILING_LISTS[this.props.language]} />
                <SideTabPanel
                    selectedTab={this.state.selectedTab}
                    onChangeTab={(index) => this.setState({ selectedTab: index })}
                    tabs={this.getTabsLabels()}
                    tabComponets={this.getTabComponets()}
                />
            </React.Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
    }
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MailingNom);