import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    makeStyles,
    CssBaseline,
    Grid,
    Paper,
    AppBar,
    Tabs,
    Tab
} from '@material-ui/core';
import axios from "axios";

import * as lang from '../../Constants/strings';
import * as actionCreators from '../../actions/actions';
import * as requestHeaders from "../../Constants/requestHeaders";
import FileTable from "./Componets/FileTable";
import FileModal from "./Componets/FileModal";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    button: {
        margin: theme.spacing(2)
    }
}));

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};


const TemplateFileSetup = (props) => {
    const classes = useStyles();
    const { language, userPermission } = props;
    const [files, setFiles] = React.useState([]);
    const [selectedFile, setSelectedFile] = React.useState("");
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [category, setCategoryFile] = React.useState('commercial');
    const [fileModalStatus, setFileModalStatus] = React.useState(false);
    const button = React.createRef();

    React.useEffect(() => {
        getFileNames(category);
    }, [category]);

    const uploadFile = async (file, type) => {
        let form = new FormData;
        form.append("file", file, file.name);

        let response = await axios.post("/api/upload_template_files", form, {
            ...requestHeaders.getPostHeader(),
            params: {
                type: type
            }
        });

        if (response.status === 200) {
            getFileNames(category);
        } else {
            console.error("Message : ", response.status, response.data.message)
        }
    };

    const getFileNames = async (type) => {
        const response = await axios.get("/api/get_files_name", {
            ...requestHeaders.getGetHeader(),
            params: {
                type: type
            }
        });

        if (response.status === 200 || response.status === 304) {
            let files = [];
            response.data.files.forEach(fileName => {
                files.push({ FILE_NAME: fileName });
            });
            setFiles(files);
        } else {
            setFiles([]);
        }
    };

    const setCategory = (event, index) => {
        setSelectedTab(index);
        if (index === 0) {
            setCategoryFile('commercial');
        } else if (index === 1) {
            setCategoryFile('tehnic');
        }
    };

    const downloadFile = async (filename, type) => {
        const response = await axios.get("/api/download_template_file", {
            ...requestHeaders.getGetHeader(),
            params: {
                filename: filename,
                type: type
            },
            responseType: 'blob',
        });

        if (response.status === 200) {
            const blob = new Blob([response.data])
            const href = window.URL.createObjectURL(blob)
            let link = document.createElement("a");
            link.download = filename;
            link.href = href;
            link.click();
        }
    };

    const deleteFile = async (fileName, type) => {
        const response = await axios.delete("/api/delete_template_file", {
            ...requestHeaders.getDeleteHeader(),
            params: {
                fileName: fileName,
                type: type
            }
        });

        if (response.status === 200) {
            getFileNames(category);
        } else {
            console.error(response.data.error);
        }
    };

    const handelFileSelect = (fileName) => {
        setSelectedFile(fileName);
        setFileModalStatus(true);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <FileModal
                open={fileModalStatus}
                fileName={selectedFile}
                language={language}
                downloadFile={() => {
                    downloadFile(selectedFile, category);
                    setSelectedFile('');
                    setFileModalStatus(false);
                }}
                deleteFile={() => {
                    deleteFile(selectedFile, category);
                    setSelectedFile('');
                    setFileModalStatus(false);
                }}
                close={() => {
                    setFileModalStatus(false);
                    setSelectedFile('')
                }}
            />
            <input
                style={{ display: "none" }}
                type="file"
                onChange={(e) => uploadFile(e.target.files[0], category)}
                ref={button}
            />
            <Paper className={classes.root}>
                <Grid container spacing={2}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={selectedTab}
                            onChange={setCategory}
                            aria-label="Tab"
                            centered
                        >
                            <Tab
                                label={lang.COMERCIAL_TEMPLATE_FILES[props.language]}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={lang.TEHNIC_TEMPLATE_FILES[props.language]}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                    <Grid item xs={12}>
                        <FileTable
                            data={files}
                            language={language}
                            handelFileSelect={(fileName => handelFileSelect(fileName))}
                            addFile={() => button.current.click()}
                            userPermission={userPermission}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

class TemplateFilesView extends Component {

    componentDidMount() {
        document.title = lang.TEMPLATE_FILES[this.props.language];
        this.props.actions.SignInUser(false, false, false, true);
        this.props.actions.checkSession(false);
    };

    render() {
        return (
            <React.Fragment>
                <TemplateFileSetup
                    language={this.props.language}
                    notification={this.props.actions.onNotificationModal}
                    userPermission={this.props.permissions}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        userAuth: state.auth.userData,
        permissions: state.auth.permissions,
    }
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateFilesView);
 