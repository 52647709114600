import React from 'react';
import { Grid, Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import * as lang from '../../../Constants/strings';
import { tasks_bill_validation } from '../../../Components/Misc/Tables/TableHeaders';
import BillValidationModal from '../Modals/BillValidationModal';
import AreaSelectModal from '../Modals/AreaSelect';
import SelectDateModal from '../Modals/SelectIntervalModal';

import * as requestHeaders from "../../../Constants/requestHeaders";
import moment from "moment";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(2, 2, 2, 2),
        width: 'auto'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fabGrid: {
        margin: theme.spacing(-5, 0, 0, 0),
        position: 'absolute',
    },
    fab: {
        marginRight: theme.spacing(4),
    },
    filterButtonsContainer: {
        marginTop: "-10px",
        display: "flex",
        justifyContent: "start"
    },
    filterButtons: {
        marginRight: "5px",
    }
}));

export default function TaskBillValidation(props) {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const [data, setData] = React.useState([]);
    const [modal, setModal] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);

    const [selectAreaModal, setSelectedAreaModal] = React.useState(false);
    const [selectDateIntervalModal, setSelectDateIntervalModal] = React.useState(false);
    const [changeFilter, setChangeFilter] = React.useState(false);

    const [selectedArea, setSelectedArea] = React.useState(null);
    const [interval, setInterval] = React.useState(undefined);

    React.useEffect(() => {
        getUnvalidatedBillsByType();
    }, []);

    React.useEffect(() => {
        getUnvalidatedBillsByType();
    }, [selectedArea, interval]);

    const resetFilters = () => {
        setInterval(undefined);
        setSelectedArea(null);
        setChangeFilter(false);
    };

    const getUnvalidatedBillsByType = async () => {
        try {
            const response = await axios.get('/api/installation_bill_by_type', {
                ...requestHeaders.getGetHeader(),
                params: {
                    type: "recipe",
                    areaCode: selectedArea !== null ? selectedArea.AREA_CODE : undefined,
                    interval: interval ? interval : undefined,
                }
            });

            let formatedBills = [];

            if (response.status === 200) {
                formatedBills = response.data.data.map(bill => {
                    bill.BILLING_TYPE = bill.BILLING_TYPE !== null ?
                        lang[bill.BILLING_TYPE][language] :
                        bill.BILLING_TYPE;
                    bill.PAYMENT_TYPE = bill.PAYMENT_TYPE !== null ?
                        lang[bill.PAYMENT_TYPE][language] :
                        bill.PAYMENT_TYPE;
                    bill.CREATE_IN = bill.CREATE_IN !== null ?
                        moment(bill.CREATE_IN).format("DD-MM-YYYY HH:mm") :
                        bill.CREATE_IN;
                    return bill;
                });
                setData(formatedBills ? formatedBills : []);
            } else {
                console.error("Error: ", response.data.message);
                setData(formatedBills);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    return (
        <React.Fragment>
            <BillValidationModal
                language={language}
                open={modal}
                close={() => setModal(false)}
                reopen={() => setModal(true)}
                billId={selectedId}
                lockAllFields={false}
                refeatchTaskData={() => getUnvalidatedBillsByType()}
            />
            <AreaSelectModal
                open={selectAreaModal}
                onClose={() => setSelectedAreaModal(false)}
                language={language}
                selectedArea={selectedArea}
                setSelectedArea={(e) => {
                    setChangeFilter(true);
                    setSelectedArea(e.target.value);
                }}
                callback={() => {
                    setSelectedAreaModal(false)
                }}
            />
            <SelectDateModal
                open={selectDateIntervalModal}
                onClose={() => setSelectDateIntervalModal(false)}
                language={language}
                callback={(startDate, endDate) => {
                    setInterval({ startDate, endDate })
                    setSelectDateIntervalModal(false);
                    setChangeFilter(true);
                }}
            />
            <Grid container component="main" className={classes.margin}>
                <Grid item xs={12}>
                    <div className={classes.filterButtonsContainer}>
                        <Button
                            className={classes.filterButtons}
                            color="primary"
                            onClick={() => setSelectDateIntervalModal(true)}
                        >
                            {lang.SELECT_INTERVAL_DATE[language]}
                        </Button>
                        <Button
                            className={classes.filterButtons}
                            color="primary"
                            onClick={() => setSelectedAreaModal(true)}
                        >
                            {lang.AREA_SELECT[language]}
                        </Button>
                        <Button
                            className={classes.filterButtons}
                            color="secondary"
                            onClick={() => resetFilters()}
                        >
                            {lang.RESET[language]}
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.BILL_VALIDATION[language]}
                        language={language}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={tasks_bill_validation(language)}
                        dense={false}
                        openRowModal={(id) => {
                            setSelectedId(id);
                            setModal(true);
                        }}
                        clickLabel="OBJID"
                        hideAddBtn={true}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
