import React, { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactAudioPlayer from 'react-audio-player';
import {
    Grid,
    Paper,
    TextField,
    FormControlLabel,
    Button,
    makeStyles,
    Typography,
    Divider,
    MenuItem
} from '@material-ui/core';
import { Email, RemoveCircle } from '@material-ui/icons';

import * as lang from '../../../Constants/strings';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import { site_warning_phone } from '../../../Components/Misc/Tables/TableHeaders';
import CustomModal from '../../../Components/Modals/CustomModal';
import { formatDateFromDb } from '../../../Functions/dateFunctions';

const useStyles = makeStyles(theme => ({
    uploadAudioBtn: {
        margin: theme.spacing(1),
    },
    playerStyle: {
        margin: theme.spacing(1)
    },
    textStyle: {
        margin: theme.spacing(1)
    }
}));

export default function SitePhoneWarning(props) {
    const classes = useStyles();
    const { siteId, language, tiny } = props;
    const [warningList, setWarningList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [addModal, setAddModal] = useState(false);
    const [comment, setComment] = useState('');
    const [wasMailed, setWasMailed] = useState(false);

    // Use for the phone Warning data fetching and modal
    const [phoneWarningModalStatus, setPhoneWarningModalStatus] = useState(false);
    const [phoneWarningId, setPhoneWarningId] = useState(null);

    // This will hold the phone warning data
    const [phoneWarningInfo, setPhoneWarningInfo] = useState(null);
    const [event, setEvent] = useState('NO_ALARM');
    const [answerd, setAnswerd] = useState(true);
    const [audioFileInfo, setAudioFileInfo] = useState(null);
    const [audioFileUrl, setAudioFileUrl] = useState(null);

    //  This will hold the base64 encode file
    const [audioFileBase64, setAudioFileBase64] = useState(null);
    // Will be use in ReactAudioPlayer and is a local URL for the uploaded file
    const [rawAudioFile, setRawAudioFile] = useState(null);
    // Is use to display the name of the uploaded file
    const [fileName, setFileName] = useState('');
    // Will containe the mime type of the file as define in IETF's RFC 6838
    const [fileType, setFileType] = useState('');

    // The ref for the input element
    const audioFileRef = React.createRef();

    useEffect(() => {
        fetch(`/api/get_site_warning?id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                response.data.map(d => {
                    d.CREATE_TIME = formatDateFromDb(d.CREATE_TIME, 12);
                    if (d.WAS_MAILED === 1) {
                        d.WAS_MAILED = <FormControlLabel control={<Email style={{ marginRight: 5 }} color='primary' />} label={lang.SEND[language]} />
                    } else {
                        d.WAS_MAILED = <FormControlLabel control={<RemoveCircle style={{ marginRight: 5 }} color='secondary' />} label={lang.NOT_SEND[language]} />
                    }

                })
                setWarningList(response.data);
                setIsLoad(true);
            })
    }, [siteId]);

    useEffect(() => {
        if (phoneWarningId !== null) {
            fetch(`/api/get_phone_warning?id=${phoneWarningId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                },
            })
                .then(response => response.json())
                .then(response => {
                    console.log(response);
                    setPhoneWarningInfo(response.data.phoneWarningInfo);
                    setAudioFileInfo(response.data.audioFileInfo);
                    if (response.data.fileBase64 !== null) {
                        getDataURL(response.data.fileBase64, response.data.audioFileInfo.FILE_TYPE, setAudioFileUrl);
                    } else {
                        setAudioFileUrl(null);
                    }
                })
        }
    }, [phoneWarningId]);

    function refetch() {
        setIsLoad(false);
        fetch(`/api/get_site_warning?id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                response.data.map(d => {
                    d.CREATE_TIME = formatDateFromDb(d.CREATE_TIME, 12);
                    if (d.WAS_MAILED === 1) {
                        d.WAS_MAILED = <FormControlLabel control={<Email style={{ marginRight: 5 }} color='primary' />} label={lang.SEND[language]} />
                    } else {
                        d.WAS_MAILED = <FormControlLabel control={<RemoveCircle style={{ marginRight: 5 }} color='secondary' />} label={lang.NOT_SEND[language]} />
                    }

                })
                setWarningList(response.data);
                setIsLoad(true);
            })
    }

    function addWarning() {
        fetch(`/api/add_site_phone_warning`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify(
                {
                    FK2SITE_OBJID: siteId,
                    CONTENT: comment,
                    WAS_MAILED: wasMailed,
                    FILE: audioFileBase64,
                    FILE_TYPE: fileType,
                    EVENT: event,
                    ANSWERD: answerd,
                    SITE_ID: siteId,
                    USER_ID: props.userId
                }
            )
        })
            .then(response => response.json())
            .then(response => {
                if (response.status > 400) {
                    props.onNotificationOpen('warning', response.message)
                } else {
                    props.onNotificationOpen('success', response.message);
                    setComment('');
                    setWasMailed(false);
                    refetch();
                }
            })

    }

    // The method takes a binary string and convert it to base64 encoded ASCII string
    // Using the WindowOrWorkerGlobalScope.btoa() method, see the MDN web docs
    function getData(audioFile, callback) {
        let reader = new FileReader();
        reader.onload = (event) => {
            let data = event.target.result.split(',');
            console.log(data[0]);
            let decodedImageData = btoa(data[1]);                    // the actual conversion of data from binary to base64 format
            callback(decodedImageData);
        };
        reader.readAsDataURL(audioFile);
    }

    // This method will decode the ASCII string to binary and retunr it as a atribut on the callback function
    // Using the WindowOrWorkerGlobalScope.atob() method, see the MDN web docs
    function getDataURL(b64Data, type, callback) {
        const byteCharacters = atob(b64Data); // the actuale converting from base 64 to binary
        let dataUrl = `data:${type};base64,` + byteCharacters; // add the missing bits
        callback(dataUrl); // call the callback function with the dataUrl as atribute
    };

    return (
        <React.Fragment>
            <CustomModal
                open={phoneWarningModalStatus}
                fullWidth
                title={lang.PHONE_WARNING[language]}
                content={
                    <Grid container spacing={2} justify='space-between' alignItems='center' alignContent='center'>
                        <Grid item xs={3}>
                            <Typography variant='h6'>{`${lang.COMMENT[language]}: `}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant='subtitle1'>{phoneWarningInfo !== null ? phoneWarningInfo.CONTENT : 'null'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1'>{`${lang.EVENT[language]}: ${phoneWarningInfo !== null ? lang[phoneWarningInfo.EVENT][language] : ''}`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1'>{`${lang.WAS_ANSWERD[language]}: ${phoneWarningInfo !== null ? phoneWarningInfo.ANSWERD === 1 ? lang.YES[language] : lang.NO[language] : ''}`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1'>{`${lang.CREATE_TIME[language]}: ${phoneWarningInfo !== null ? formatDateFromDb(phoneWarningInfo.CREATE_TIME, 8) : 'null'}`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1'>{`${lang.WAS_MAILED[language]}: ${phoneWarningInfo !== null ? phoneWarningInfo.WAS_MAILED ? lang.SENT[language] : lang.NOT_SEND[language] : 'null'}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>{
                            audioFileUrl !== null &&
                            <React.Fragment>
                                <Typography className={classes.textStyle} variant='h6'>{`${lang.UPLOADED_FILE[language]}: ${audioFileInfo !== null ? audioFileInfo.FILE_NAME : 'null'}`}</Typography>
                                <ReactAudioPlayer
                                    src={audioFileUrl}
                                    controls
                                    className={classes.playerStyle}
                                />
                            </React.Fragment>
                        }
                    </Grid>
                }
                language={language}
                close={() => setPhoneWarningModalStatus(false)}
                noButtons
            />
            <CustomModal
                open={addModal}
                fullWidth
                title={lang.NEW_PHONE_NOTIFICATION[language]}
                content={
                    <Grid container spacing={2} justify='center' alignItems='center' alignContent='center'>
                        <TextField className={classes.textStyle} fullWidth variant='outlined' multiline value={comment} onChange={(e) => setComment(e.target.value)} label={lang.COMMENT[language]} />
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                variant='outlined'
                                label={lang.EVENT[language]}
                                value={event}
                                onChange={e => setEvent(e.target.value)}
                            >
                                <MenuItem value='NO_ALARM'>{lang.NO_ALARM[language]}</MenuItem>
                                <MenuItem value='NO_DISARMAMENT'>{lang.NO_DISARMAMENT[language]}</MenuItem>
                                <MenuItem value='EFRACTON_SIGNAL'>{lang.EFRACTON_SIGNAL[language]}</MenuItem>
                                <MenuItem value='NO_ARMAMENT'>{lang.NO_ARMAMENT[language]}</MenuItem>
                                <MenuItem value='INCIDENT'>{lang.INCIDENT[language]}</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                variant='outlined'
                                label={lang.WAS_ANSWERD[language]}
                                value={answerd}
                                onChange={e => setAnswerd(e.target.value)}
                            >
                                <MenuItem value={true}>{lang.YES[language]}</MenuItem>
                                <MenuItem value={false}>{lang.NO[language]}</MenuItem>
                            </TextField>
                        </Grid>
                        {answerd &&
                            <Grid container justify='center' alignContent='center' alignItems='center'>
                                <Grid item xs={12}>
                                    <Button className={classes.uploadAudioBtn} color='primary' variant='contained' fullWidth onClick={() => audioFileRef.current.click()} >{lang.UPLOAD_AUDIO_FILE[props.language]}</Button>
                                </Grid>
                            </Grid>
                        }
                        {
                            audioFileBase64 !== null &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Typography className={classes.textStyle} variant='h6'>{`${lang.UPLOADED_FILE[props.language]}: ${fileName}`}</Typography>
                                <ReactAudioPlayer
                                    src={rawAudioFile}
                                    controls
                                    className={classes.playerStyle}
                                />

                            </React.Fragment>
                        }
                        {/* 
                            This input is hiden and can be access only by using the audioFileRef
                            Is use for handeling the file loading
                            The input will move the encoded file to state only if the main type is 'audio' 
                        */}
                        <input
                            type="file"
                            accept="audio/*"
                            style={{ display: "none" }}
                            ref={audioFileRef}
                            onChange={(e) => {
                                e.persist();
                                // Get the first type for the file
                                let mainType = e.target.files[0].type.split('/');
                                // Check is the file is a audio file
                                if (mainType[0] === 'audio') {
                                    // Get the base64 encoded file
                                    getData(e.target.files[0], setAudioFileBase64);
                                    // Get the name for displaying 
                                    setFileName(e.target.files[0].name);
                                    // Get the mime type of the file
                                    setFileType(e.target.files[0].type);
                                    // Create a local URL for the loaded file
                                    setRawAudioFile(URL.createObjectURL(e.target.files[0]));
                                }
                            }}
                            tabIndex={-1}
                        />
                    </Grid>
                }
                close={() => setAddModal(false)}
                execute={() => {
                    addWarning();
                    setAddModal(false);
                }}
                language={language}
                validate={comment.length > 0}
            />
            {isLoad ?
                <Grid item xs={12} md={12} container style={{ paddingRight: '0px' }} direction="column">
                    <MaterialTable
                        tiny={tiny}
                        title={lang.PHONE_WARNING[language]}
                        language={language}
                        data={warningList && warningList.length > 0 ? [...warningList] : []}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={site_warning_phone(language)}
                        dense={false}
                        openAddModal={() => setAddModal(true)}
                        openRowModal={(id) => {
                            setPhoneWarningId(id);
                            setPhoneWarningModalStatus(true);
                        }}
                        clickLabel="OBJID"
                    />
                </Grid> :
                < SkeletonTaskTable />
            }
        </React.Fragment>
    )
};

export function SkeletonTaskTable(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={12} container direction="column">
            <Skeleton component={Paper} height="45vh">
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                </Grid>
            </Skeleton>
        </Grid>
    );
}
