import { checkPermissions } from './permissionHide';

// Use to implement the Separated contract visualization by TYPE
// This function will get the user permission and the fetch contract list
export function contractSorting(permission, contractList) {
    let result = [];

    // Check the permission that the users have
    const technicPermission = checkPermissions(permission, { parent: 'SITE', name: 'SITE_VIEW_CONTRACT_TEHNIC' });
    const watchPermission = checkPermissions(permission, { parent: 'SITE', name: 'SITE_VIEW_CONTRACT_WATCH' });

    // Map the given contract list checking for the contract type and if the users have the permission to see it
    // If it has the permission, then add the contract object to the result list
    contractList.map(contract => {
        if (contract.TYPE === 'CONTRACT_TYPE_TECHNIC' && technicPermission) {
            result.push(contract)
        }
        if (contract.TYPE === 'CONTRACT_TYPE_WATCH' && watchPermission) {
            result.push(contract)
        }
        if (contract.TYPE === 'CONTRACT_TYPE_SERVICES') {
            result.push(contract)
        }
    });

    // Return the resulted list
    return result;
}