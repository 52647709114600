import React from "react";
import {
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  Divider,
} from "@material-ui/core";
import * as lang from "../../../Constants/strings";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // marginTop: theme.spacing(2),
  },
}));

export default function TaskNewForm(props) {
  const classes = useStyles();
  const { addData, language, updateField, updateFieldRaw, baseData } = props;

  const inputLabelType = React.useRef(null);
  const [labelWidthType, setLabelWidthType] = React.useState(35);
  const inputLabelContract = React.useRef(null);
  const [labelWidthContract, setLabelWidthContract] = React.useState(35);

  const [typeList, setTypeList] = React.useState([]);
  const [contractList, setContractList] = React.useState([]);

  function fetchFunction(url, setResult) {
    fetch(`/api/${url}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setResult(response);
      })
      .catch((err) => console.log(err));
  }

  React.useEffect(() => {
    setLabelWidthType(inputLabelType.current.offsetWidth);
    setLabelWidthContract(inputLabelContract.current.offsetWidth);
    if (typeList.length === 0) fetchFunction("task_typelist", setTypeList);
  }, []);

  React.useEffect(() => {
    if (contractList.length === 0)
      fetchFunction(
        `get_contracts_list_summary?id=${baseData.siteId}&type=2&subtype=1,5`,
        setContractList
      );
  }, [baseData.siteId]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormControl
          required
          fullWidth
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel ref={inputLabelType}>{lang.TYPE[language]}</InputLabel>
          <Select
            name="TYPE"
            labelWidth={labelWidthType}
            value={addData.TYPE ? addData.TYPE : ""}
            onChange={(e) => updateFieldRaw("TYPE", e.target.value)}
          >
            {typeList &&
              typeList.length > 0 &&
              typeList.map((type) => {
                return (
                  <MenuItem key={type.OBJID} value={type.OBJID}>
                    {lang[type.TYPE][language]}
                  </MenuItem>
                );
              })}
          </Select>
          <input
            tabIndex={-1}
            readOnly
            autoComplete="off"
            style={{ opacity: 0, height: 0 }}
            value={addData.TYPE ? addData.TYPE : ""}
            required
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          required
          fullWidth
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel ref={inputLabelContract}>
            {lang.CONTRACT[language]}
          </InputLabel>
          <Select
            name="CONTRACT_OBJID"
            labelWidth={labelWidthContract}
            value={addData.CONTRACT_OBJID ? addData.CONTRACT_OBJID : ""}
            onChange={(e) => updateFieldRaw("CONTRACT_OBJID", e.target.value)}
          >
            {contractList &&
              contractList.length > 0 &&
              contractList.map((item) => {
                return (
                  <MenuItem key={item.OBJID} value={item.OBJID}>
                    {`#${item.CONTRACT_NO} - ${item.SUB_TYPE ? lang[item.SUB_TYPE][language] : "fara subtip"} / ${moment(item.START_DATE).format("DD-MM-YYYY")} (${item.CONTRACTOR_NAME})`}
                  </MenuItem>
                );
              })}
            {
              contractList?.length === 0 && <div style={{ padding: "10px" }}>Nu exista contracte de instalare</div>
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          variant="outlined"
          name="REASON"
          multiline
          rowsMax="4"
          label={lang.REASON[language]}
          value={addData.REASON ? addData.REASON : ""}
          onChange={(e) => updateField(e, 2000)}
          className={classes.formControl}
        />
      </Grid>
    </React.Fragment>
  );
}

