import React from "react";
import {
	makeStyles,
	Grid,
	Paper,
	Typography,
	Grow,
	Chip,
	Link,
	useTheme,
	Divider,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
	PhoneRounded,
	EmailRounded,
	FaceRounded,
	BusinessRounded,
	BusinessCenterRounded,
	AccountCircleRounded,
	FormatListNumberedRounded,
	ArrowForwardRounded
} from "@material-ui/icons";
import * as lang from "../../../Constants/strings";
import { redirectTo } from "../../../Functions/linkFunctions";
import { checkPermissions } from "../../../Functions/permissionHide";

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "100%",
		backgroundPosition: "center top",
		padding: theme.spacing(2),
		height: "100%",
	},
	paperClear: {
		padding: theme.spacing(2),
		height: "100%",
	},
	title: {
		margin: theme.spacing(3),
	},
	subtitle: {
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	link: {
		textDecoration: "none",
		color: "inherit",
		cursor: "pointer",
	},
}));
export default function SiteCust(props) {
	const classes = useStyles();
	const theme = useTheme();
	const {
		customer,
		language,
        tiny,
        data,
		cust,
	} = props;

	React.useEffect(() => {
		console.log(props.customer);
	}, [props.customer]);

	return (
		<React.Fragment>
			<Grid
				item
				xs={12}
				style={{
					paddingRight: "0px",
					transition: theme.transitions.create("all", {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
				}}
			>
				<Grow
					in={true}
					style={{ transitionDelay: true ? "100ms" : "0ms" }}
				>
					<Grid
						container
						justify="center"
						alignItems="center"
						direction="column"
					>
						<Grid
							container
							// justify="flex-start"
							alignItems="stretch"
							direction="row"
						>	
							<Typography variant="h5">
								{	checkPermissions(props.userPermissions , { parent: "CUSTOMERS", name: "VIEW_CUSTOMER_DETAIL" } ) ?
									<Link color="textPrimary" onClick={()=>redirectTo(`/customer?id=${customer.ID}`)}>
										{`${lang.CUSTOMER_DETAILS[language]}: ${customer && customer.NAME}`}
									</Link>
									:
									`${lang.CUSTOMER_DETAILS[language]}: ${customer && customer.NAME}`
								}
							</Typography>

						</Grid>
						<Grid
							container
							justify="flex-start"
							alignItems="stretch"
							direction="column"
						>
							<Divider />
						</Grid>
						<Grid
							container
							justify="flex-start"
							alignItems="stretch"
							direction="row"
						>
							{customer && customer.CUSTOMER_CODE && customer.TYPE && (
								<Chip
									className={classes.subtitle}
									icon={
										customer.TYPE === "PF" ? (
											<FaceRounded fontSize="small" />
										) : (
											<BusinessRounded fontSize="small" />
										)
									}
									color="secondary"
									label={`${
										lang["CLIENT_TYPE_" + customer.TYPE][
											language
										]
									} #${customer.CUSTOMER_CODE}`}
								/>
							)}
							{customer && customer.NAME && (
								<Chip
									className={classes.subtitle}
									icon={<FaceRounded fontSize="small" />}
									color="primary"
									label={`${lang.CUSTOMER[language]}: ${customer.NAME}`}
								/>
							)}
							{customer && customer.PHONE && (
								<Chip
									className={classes.subtitle}
									icon={<PhoneRounded fontSize="small" />}
									color="primary"
									label={customer.PHONE}
								/>
							)}
							{customer && customer.EMAIL && (
								<Chip
									className={classes.subtitle}
									icon={<EmailRounded fontSize="small" />}
									color="primary"
									label={customer.EMAIL}
								/>
							)}
							{customer && customer.TYPE === "PJ" && customer.CNP_CUI && (
								<Chip
									className={classes.subtitle}
									icon={
										<BusinessCenterRounded fontSize="small" />
									}
									color="primary"
									label={
										lang.CUI[language] + ": " + customer.CNP_CUI
									}
								/>
							)}
							{customer && customer.TYPE === "PJ" && customer.NR_REG && (
								<Chip
									className={classes.subtitle}
									icon={
										<FormatListNumberedRounded fontSize="small" />
									}
									color="primary"
									label={
										lang.NR_REG[language] +
										": " +
										customer.NR_REG
									}
								/>
							)}
							{customer &&
								customer.TYPE === "PJ" &&
								customer.ADMINISTRATOR && (
									<Chip
										className={classes.subtitle}
										icon={
											<AccountCircleRounded fontSize="small" />
										}
										color="primary"
										label={
											lang.ADMINISTRATOR[language] +
											": " +
											customer.ADMINISTRATOR
										}
									/>
								)}
							{customer && customer.TYPE === "PF" && customer.CNP_CUI && (
								<Chip
									className={classes.subtitle}
									icon={
										<FormatListNumberedRounded fontSize="small" />
									}
									color="primary"
									label={
										lang.CNP[language] + ": " + customer.CNP_CUI
									}
								/>
							)}
						</Grid>
					</Grid>
				</Grow>
			</Grid>
		</React.Fragment>
	);
}

export function SkeletonSiteCust(props) {
	const { classes } = props;
	return (
		<Grid item xs={12} md={4} style={{ paddingRight: "0px" }}>
			<Skeleton component={Paper} className={classes.paperClear}>
				<Grid
					container
					justify="center"
					alignItems="center"
					direction="column"
				>
					<Skeleton width="15%" className={classes.title} />
					<Grid
						container
						justify="center"
						alignItems="center"
						direction="column"
					>
						<Skeleton className={classes.subtitle} width="50%" />
						<Skeleton className={classes.subtitle} width="50%" />
					</Grid>
				</Grid>
			</Skeleton>
		</Grid>
	);
}
