import { useState } from "react";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import moment from "moment";
import axios from "axios";

import * as requestHeaders from "../../../Constants/requestHeaders";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";

const TransferItem = (props) => {
    const {
        id,
        fromName,
        toName,
        toType,
        quantity,
        um,
        status,
        createdBy,
        createdAt,
        language = "ro",
        productName,
        userId,
        reload
    } = props;

    const [denyConfirmationModal, setDenyConfirmationModal] = useState(false);
    const [acceptTransferConfirmationModal, setAcceptTransferConfirmationModal] = useState(false);

    const deny = async (transferId) => {
        try {
            await axios.put("/api/external/deny/transfer/"+transferId, null, {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: userId
                }
            });
            if(reload) {
                reload();
                setDenyConfirmationModal(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const accept = async (transferId) => {
        try {
            await axios.put("/api/external/accept/transfer/" + transferId, null, {
                ...requestHeaders.getGetHeader(), params: {
                    id: userId
                }
            });
            if (reload) {
                reload();
                setAcceptTransferConfirmationModal(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Paper style={{margin: "10px", padding: "10px", border: "2px solid #ffffff60"}}>
            <CustomModal
                open={denyConfirmationModal}
                title={lang.CONFIRM_ACTION[language]}
                content={<Typography variant="h5">{lang.DENY_TRANSFER_CONFIRMATION_MESSAGE[language]}</Typography>}
                close={() => setDenyConfirmationModal(false)}
                language={language}
                execute={() => deny(id)}
                validate={true}
            />
            <CustomModal
                open={acceptTransferConfirmationModal}
                title={lang.CONFIRM_ACTION[language]}
                content={<Typography variant="h5">{lang.ACCEPT_TRANSFER_CONFIRMATION_MESSAGE[language]}</Typography>}
                close={() => setAcceptTransferConfirmationModal(false)}
                language={language}
                execute={() => accept(id)}
                validate={true}
            />
            <Grid container alignItems="center">
                <Grid xl={5} xs={12}>
                    <Grid container>
                        <Grid xs={12}>
                            <Typography variant="h6" gutterBottom>
                                {productName}
                            </Typography>
                        </Grid>


                        <Grid xs={12} style={{ marginTop: "10px" }}>
                            <Grid container>
                                <Grid item xs={5}>
                                    {fromName}
                                </Grid>
                                <Grid item xs={1}>
                                    <ArrowRightAltIcon style={{marginLeft: "5px", marginRight: "5px", marginTop: "0px"}} color="secondary"/>
                                </Grid>
                                <Grid item xs={5}>
                                    {toName}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xl={2} xs={6} style={{marginTop: "10px"}}>
                    {`Cantitate: ${quantity} ( ${um} )`}
                </Grid>

                <Grid xl={2} xs={6} style={{marginTop: "10px"}}>
                    {`${createdBy} ( ${moment(createdAt).format('YYYY-MM-DD HH:mm')} )`}
                </Grid>

                <Grid xl={3} xs={12} style={{marginTop: "10px"}}>
                    <Grid container justifyContent="space-between">
                        <Grid xs={12} md={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => setAcceptTransferConfirmationModal(true)}
                            >
                                {lang.ACCEPT_TRANSFER[language]}
                            </Button>
                        </Grid>

                        <Grid xs={12} md={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => setDenyConfirmationModal(true)}
                            >
                                {lang.DENY_TRANSFER[language]}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default TransferItem;