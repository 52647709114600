function checkParent(subPermissions, permission) {
    for (let i = 0; i < subPermissions.length; i++) {
        if (subPermissions[i].PERMISSION === permission) {
            return subPermissions[i].VALUE === 1;
        }
    }
    return false;
}

function checkOnePermission(userPermissions, permissionToCheck) {
    if (permissionToCheck.parent) {
        if (userPermissions[permissionToCheck.parent]) {
            return checkParent(userPermissions[permissionToCheck.parent].PERMISSIONS, permissionToCheck.name)
        }
        else {
            return false;
        }
    }
    else
        if (userPermissions[permissionToCheck.name])
            return userPermissions[permissionToCheck.name].VALUE === 1;
        else
            return false;
}

/**
 *
 * @param userPermissions
 * @param permissionsToCheck
 * @returns {boolean}
 */
export function checkPermissions(userPermissions, ...permissionsToCheck) {
    for (let i = 0; i < permissionsToCheck.length; i++) {
        if (checkOnePermission(userPermissions, permissionsToCheck[i]) === false) {
            return false;
        }
    }
    return true;
}