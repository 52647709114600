import React, {useState, useEffect} from "react";
import {
    TextField,
    Grid,
    MenuItem,
    FormControl,
    FormHelperText,
    InputAdornment,
    Typography,
    Divider,
    Select,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";

import * as lang from "../../../Constants/strings";
import * as requestHeaders from "../../../Constants/requestHeaders";

export default function ContractNewForm(props) {
    const {addData, language, updateFieldRaw} = props;

    const [typeList, setTypeList] = useState([]);
    const [execList, setExecList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [maintenanceServices, setMaintenanceServices] = useState([]);

    const updateType = (e) => {
        const {value} = e.target;
        updateFieldRaw("TYPE", value);
        updateFieldRaw("SUB_TYPE", 1);
        updateFieldRaw("EXECUTOR", "");
    }

    const updateSubType = (e) => {
        const {value} = e.target;
        updateFieldRaw("SUB_TYPE", value);
        updateFieldRaw("EXECUTOR", "");
    }

    function getNumberOfMinuts() {
        let menuItemList = [];

        for (let i = 5; i <= 50; i++) {
            menuItemList.push(<MenuItem key={i} value={i}>{`${i} min`}</MenuItem>);
        }
        return menuItemList;
    }

    useEffect(() => {
        if (addData.TYPE === 2 && addData.SUB_TYPE !== 1) {
            (async () => {
                await getMaintenanceServicesTypes();
                updateFieldRaw("MAINTENANCE_TYPE", maintenanceServices[maintenanceServices.findIndex(e => e.mainType.TYPE === 2)]);
            })();
        }
    }, [addData.TYPE, addData.SUB_TYPE]);

    useEffect(() => {
        if (typeList.length === 0) {
            fetch(`/api/contract_types`, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.token,
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setTypeList(response);
                })
                .catch((err) => console.log(err));
        }

        if (assigneeList.length === 0) {
            fetch(`/api/contract_assignee_list`, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.token,
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setAssigneeList(response);
                })
                .catch((err) => console.log(err));
        }

        if (areaList.length === 0) {
            fetch(`/api/areas_active`, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.token,
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setAreaList(response);
                })
                .catch((err) => console.log(err));
        }

    }, []);

    const getMaintenanceServicesTypes = async () => {
        try {
            const response = await axios.get("/api/get_maintenance_services", {...requestHeaders.getGetHeader()});
            if (response.status === 200) return await response.data.services;
        } catch (error) {
            console.log(error);
        }
    };

    const getContractExtraServices = async () => {
        try {
            const response = await axios.get("/api/get_contract_extra_service", {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: addData.OBJID
                }
            });

            const index = response.data.extraServicesTypes.findIndex(s => s.mainType.OBJID === response.data.extraServices.mainType.OBJID);
            let servicesCopy = [...response.data.extraServicesTypes];
            servicesCopy[index] = response.data.extraServices;

            setMaintenanceServices(servicesCopy);
            updateFieldRaw("LAST_MAINTENANCE_DATE", new Date(response.data.lastMaintenance));
            updateFieldRaw("MAINTENANCE_TYPE", response.data.extraServices);

            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    useEffect(() => {
        (async () => {
            if (props.addData.OBJID) {
                const servicesTypes = await getMaintenanceServicesTypes();
                const gettingExtraServices = await getContractExtraServices();

                setMaintenanceServices(servicesTypes);
                if (!gettingExtraServices) console.error(`Can't get the getContractExtraServices`);
            }
        })()
    }, [props.addData.OBJID]);

    useEffect(() => {
        (async () => {
            if (!props.addData.OBJID) {
                const servicesTypes = await getMaintenanceServicesTypes();
                setMaintenanceServices(servicesTypes);
            } else {
                updateFieldRaw("SEND_EMAIL", true);
            }
        })();
    }, []);

    useEffect(() => {
        if (addData.TYPE && addData.SUB_TYPE)
            fetch(
                `/api/executors_valid?type=${addData.TYPE}&subtype=${addData.SUB_TYPE}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: localStorage.token,
                    },
                }
            )
                .then((response) => response.json())
                .then((response) => {
                    setExecList(response.data);
                })
                .catch((err) => console.log(err));
    }, [addData.SUB_TYPE, addData.TYPE]);

    const handleMaintenanceServicesType = (index) => {
        const localCopy = addData.MAINTENANCE_TYPE;
        localCopy.subTypes[index].checked = localCopy.subTypes[index].checked ? !localCopy.subTypes[index].checked : true;
        updateFieldRaw("MAINTENANCE_TYPE", localCopy);
    };

    /**
     * Return a bool base on the existing of the type and one of the sub types in the addData object
     * @param {number = 1 | 2 | 3} type
     * @param {number[]} subTypes
     * @returns {bool}
     */
    const renderBaseOnContractType = (subTypes = [], type = 1) => {
        return addData ? (addData.TYPE === type && subTypes.findIndex(t => t === addData.SUB_TYPE) !== -1) : false
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel>{lang.TYPE[language]}</InputLabel>
                    <Select
                        name="TYPE"
                        value={addData.TYPE ? addData.TYPE : ""}
                        onChange={updateType}
                    >
                        {typeList
                            .filter(type => type.TYPE === 1)
                            .map((type) => {
                                return (
                                    <MenuItem key={type.ID} value={type.SUB_TYPE}>
                                        {lang[type.DESCRIPTION_TEXT][language]}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel>
                        {lang.SUBTYPE[language]}
                    </InputLabel>
                    <Select
                        name="SUB_TYPE"
                        value={addData.SUB_TYPE ? addData.SUB_TYPE : ""}
                        onChange={updateSubType}
                    >
                        {typeList
                            .filter(subtype => subtype.SUB_TYPE === addData?.TYPE && subtype.TYPE === 2)
                            .map((subtype) => {
                                return (
                                    <MenuItem key={subtype.ID} value={subtype.CODE}>
                                        {lang[subtype.DESCRIPTION_TEXT][language]}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel>
                        {lang.EXEC_NAME2[language]}
                    </InputLabel>
                    <Select
                        name="CONTRACTOR_OBJID"
                        value={addData.CONTRACTOR_OBJID ? addData.CONTRACTOR_OBJID : ""}
                        onChange={(e) => updateFieldRaw("CONTRACTOR_OBJID", e.target.value)}
                        disabled={execList.length === 0}
                    >
                        {execList?.length > 0 &&
                            execList.map((exec) => {
                                return (
                                    <MenuItem key={exec.OBJID} value={exec.OBJID}>
                                        {exec.NAME}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {execList.length === 0 && (
                        <FormHelperText>
                            {lang.NO_EXECUTOR_AVAILABLE[language]}
                        </FormHelperText>
                    )}
                    <input
                        readOnly
                        tabIndex={-1}
                        autoComplete="off"
                        style={{opacity: 0, height: 0}}
                        value={addData.CONTRACTOR_OBJID ? addData.CONTRACTOR_OBJID : ""}
                        required
                    />
                </FormControl>
            </Grid>
            {
                !(renderBaseOnContractType([4, 3], 1) || renderBaseOnContractType([2, 5], 2) || renderBaseOnContractType([4], 3)) && (
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            required
                            fullWidth
                            variant="outlined"

                        >
                            <TextField
                                select
                                name="INTERVENTION_TIME"
                                variant="outlined"
                                label={lang.MINIMUM_INTERVETION_TIME[language]}
                                value={addData.INTERVENTION_TIME ? addData.INTERVENTION_TIME : ""}
                                onChange={(e) =>
                                    updateFieldRaw("INTERVENTION_TIME", e.target.value)
                                }
                            >
                                {getNumberOfMinuts()}
                            </TextField>
                        </FormControl>
                    </Grid>
                )
            }

            {!(renderBaseOnContractType([1], 2) || renderBaseOnContractType([4], 3)) && addData.MAINTENANCE_TYPE && (
                <FormControl
                    fullWidth
                    variant="outlined"
                >
                    <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                        <Divider style={{width: "100%"}}/>
                        <Typography style={{margin: "10px 0px"}}>
                            {lang.SERVICES[language]}
                        </Typography>
                        {
                            addData.MAINTENANCE_TYPE.subTypes.map((type, index) =>
                                <FormControlLabel
                                    key={`checkbox_${index}`}
                                    required={true}
                                    control={
                                        <Checkbox
                                            checked={type.checked}
                                            onClick={() => handleMaintenanceServicesType(index)}
                                        />}
                                    label={type.NAME}
                                />)
                        }
                    </Grid>
                </FormControl>
            )}
            {
                (renderBaseOnContractType([1], 2) && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                required
                                name="MAINTENANCE_TYPE"
                                variant="outlined"
                                label={lang.MAINTENANCE_TYPE[language]}
                                value={addData.MAINTENANCE_TYPE ? addData.MAINTENANCE_TYPE : ""}
                                onChange={(e, value) => {
                                    updateFieldRaw("MAINTENANCE_TYPE", value.props.value)
                                }}
                            >
                                <MenuItem value={undefined}>None</MenuItem>
                                {
                                    maintenanceServices.map((service, index) =>
                                        <MenuItem
                                            key={`maintenance_service_${index}`}
                                            value={service}
                                        >
                                            {service.mainType.NAME}
                                        </MenuItem>)
                                }
                            </TextField>
                        </Grid>
                        {
                            addData.MAINTENANCE_TYPE &&
                            <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                                <Divider style={{width: "100%"}}/>
                                <Typography style={{margin: "10px 0px"}}>
                                    {lang.MAINTENANCE_SERVICES[language]}
                                </Typography>
                                {
                                    addData.MAINTENANCE_TYPE.subTypes.map((type, index) =>
                                        <FormControlLabel
                                            key={`checkbox_${index}`}
                                            control={
                                                <Checkbox
                                                    checked={type.checked}
                                                    onClick={() => handleMaintenanceServicesType(index)}
                                                />}
                                            label={type.NAME}
                                        />)
                                }
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        required
                                        inputVariant="outlined"
                                        fullWidth
                                        name="LAST_MAINTENANCE_DATE"
                                        label={lang.LAST_MAINTENANCE_DATE[props.language]}
                                        InputProps={{readOnly: true}}
                                        format="dd/MM/yyyy"
                                        KeyboardButtonProps={{
                                            "aria-label": "change LAST_MAINTENANCE_DATE",
                                        }}
                                        value={
                                            addData?.LAST_MAINTENANCE_DATE
                                                ? addData.LAST_MAINTENANCE_DATE
                                                : null
                                        }

                                        onChange={(value) => updateFieldRaw("LAST_MAINTENANCE_DATE", value)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }
                    </>
                ))
            }

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="CONTRACT_NO"
                    label={lang.CONTRACT_NUMBER[language]}
                    value={addData.CONTRACT_NO ? addData.CONTRACT_NO : ""}
                    onChange={(e) => props.updateFieldRaw("CONTRACT_NO", e.target.value)}
                />
            </Grid>

            {
                renderBaseOnContractType([1], 2) &&
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <InputLabel>
                            {lang.INTERVENTION_CYCLE[language]}
                        </InputLabel>
                        <Select
                            name="INTERVENTION_CYCLE"
                            value={addData.INTERVENTION_CYCLE ? addData.INTERVENTION_CYCLE : ""}
                            onChange={(e) => updateFieldRaw("INTERVENTION_CYCLE", e.target.value)}
                            disabled={execList.length === 0}
                        >
                            <MenuItem value={1}>{lang.BILL_CURRENT_MONTH[language]}</MenuItem>
                            <MenuItem value={-1}>{lang.BILL_LAST_MONTH[language]}</MenuItem>
                            <MenuItem value={3}>{lang.BILL_QUARTER[language]}</MenuItem>
                            <MenuItem value={2}>{lang.BILL_SEMESTER[language]}</MenuItem>
                            <MenuItem value={4}>{lang.BILL_YEARLY[language]}</MenuItem>
                        </Select>
                        {execList.length === 0 && (
                            <FormHelperText>
                                {lang.NO_EXECUTOR_AVAILABLE[language]}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            }

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="SIGNATURE_DATE"
                        label={lang.SIGNATURE_DATE[props.language]}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change SIGNATURE_DATE",
                        }}
                        value={addData.SIGNATURE_DATE ? addData.SIGNATURE_DATE : null}
                        onChange={(value) => updateFieldRaw("SIGNATURE_DATE", value)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>

            {
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            required
                            inputVariant="outlined"
                            fullWidth
                            name="START_DATE"
                            label={lang.CONTRACT_START_DATE[props.language]}
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            value={addData.START_DATE && addData.START_DATE}

                            onChange={(value) => updateFieldRaw("START_DATE", value)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            }
            {
                !(renderBaseOnContractType([3], 2) || renderBaseOnContractType([2, 5], 2) || renderBaseOnContractType([4], 3)) &&
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            disabled={addData.INDETERMINATE}
                            required={!addData.INDETERMINATE}
                            inputVariant="outlined"
                            fullWidth
                            name="END_DATE"
                            label={lang.CONTRACT_END_DATE[props.language]}
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            value={addData.END_DATE && addData.END_DATE}

                            onChange={(value) => updateFieldRaw("END_DATE", value)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            }
            {
                !(renderBaseOnContractType([3], 2) || renderBaseOnContractType([2], 2) || renderBaseOnContractType([4], 3)) &&
                <Grid item xs={12}>
                    <FormControlLabel
                        style={{margin: 10}}
                        control={
                            <Checkbox
                                checked={addData.INDETERMINATE === true}
                                value={addData.INDETERMINATE}
                                onChange={() =>
                                    updateFieldRaw("INDETERMINATE", !addData.INDETERMINATE)
                                }
                            />
                        }
                        label={lang.CONTRACT_INDETERMINATE[props.language]}
                    />
                </Grid>
            }

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="VALUE"
                    label={lang.CONTRACT_VALUE[language]}
                    value={addData.VALUE ? addData.VALUE : ""}
                    onChange={(e) => props.updateField(e, 50)}
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                    }}
                />
            </Grid>

            {
                !(renderBaseOnContractType([3], 2) || renderBaseOnContractType([2, 5], 2) || renderBaseOnContractType([4], 3)) &&
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="TRUCK_ROLL_VALUE"
                        label={lang.TRUCK_ROLL_VALUE[language]}
                        value={addData.TRUCK_ROLL_VALUE ? addData.TRUCK_ROLL_VALUE : ""}
                        onChange={(e) => props.updateField(e, 50)}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                        }}
                    />
                </Grid>
            }
            {
                !(renderBaseOnContractType([3], 2) || renderBaseOnContractType([2, 5], 2) || renderBaseOnContractType([4], 3)) &&
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="FREE_TRUCK_ROLLS"
                        label={lang.FREE_TRUCK_ROLLS[language]}
                        value={addData.FREE_TRUCK_ROLLS ? addData.FREE_TRUCK_ROLLS : ""}
                        onChange={(e) => props.updateField(e, 50)}
                        type="number"
                    />
                </Grid>
            }

            {
                !(renderBaseOnContractType([3], 2) || renderBaseOnContractType([2, 5], 2) || renderBaseOnContractType([4], 3)) &&
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <InputLabel>
                            {lang.BILL_CYCLE[language]}
                        </InputLabel>
                        <Select
                            name="BILL_CYCLE"
                            value={addData.BILL_CYCLE ? addData.BILL_CYCLE : ""}
                            onChange={(e) => updateFieldRaw("BILL_CYCLE", e.target.value)}
                            disabled={execList.length === 0}
                        >
                            <MenuItem value={1}>{lang.BILL_CURRENT_MONTH[language]}</MenuItem>
                            <MenuItem value={-1}>{lang.BILL_LAST_MONTH[language]}</MenuItem>
                            <MenuItem value={3}>{lang.BILL_QUARTER[language]}</MenuItem>
                            <MenuItem value={2}>{lang.BILL_SEMESTER[language]}</MenuItem>
                            <MenuItem value={4}>{lang.BILL_YEARLY[language]}</MenuItem>
                        </Select>
                        {execList.length === 0 && (
                            <FormHelperText>
                                {lang.NO_EXECUTOR_AVAILABLE[language]}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            }

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel>
                        {lang.ASSIGNED_TEAM[language]}
                    </InputLabel>
                    <Select
                        name="CONTRACT_ASSIGNEE"
                        value={addData.ASSIGNED_TEAM ? addData.ASSIGNED_TEAM : ""}
                        onChange={(e) => updateFieldRaw("ASSIGNED_TEAM", e.target.value)}
                    >
                        {areaList?.length > 0 &&
                            areaList.map((item) => {
                                return (
                                    <MenuItem key={item.AREA_CODE} value={item.AREA_CODE}>
                                        {item.AREA}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{opacity: 0, height: 0}}
                    value={addData.CONTRACT_ASSIGNEE}
                    required
                />
            </Grid>

            {
                !(renderBaseOnContractType([3, 5], 2) || renderBaseOnContractType([4], 3)) &&
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="NFC"
                        label={lang.NFC[language]}
                        value={addData.NFC ? addData.NFC : ""}
                        onChange={(e) => props.updateField(e, 150)}
                    />
                </Grid>
            }

            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel>
                        {lang.BILLING_ASSIGNEE[language]}
                    </InputLabel>
                    <Select
                        name="CONTRACT_ASSIGNEE"
                        value={addData.CONTRACT_ASSIGNEE ? addData.CONTRACT_ASSIGNEE : ""}
                        onChange={(e) =>
                            updateFieldRaw("CONTRACT_ASSIGNEE", e.target.value)
                        }
                    >
                        {assigneeList?.length > 0 &&
                            assigneeList.map((item) => {
                                return (
                                    <MenuItem key={item.OBJID} value={item.OBJID}>
                                        {item.USERNAME}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <input
                    readOnly
                    tabIndex={-1}
                    autoComplete="off"
                    style={{opacity: 0, height: 0}}
                    value={addData.CONTRACT_ASSIGNEE ? addData.CONTRACT_ASSIGNEE : ""}
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    name="OBSERVATION"
                    label={lang.OBSERVATION[language]}
                    value={addData.OBSERVATION ? addData.OBSERVATION : ""}
                    onChange={(e) => props.updateFieldRaw("OBSERVATION", e.target.value)}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {props.resetCloseComment && (
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    addData?.RESET_CLOSE_COMMENT
                                        ? addData.RESET_CLOSE_COMMENT
                                        : false
                                }
                                onChange={() =>
                                    updateFieldRaw(
                                        "RESET_CLOSE_COMMENT",
                                        addData?.RESET_CLOSE_COMMENT
                                            ? !addData.RESET_CLOSE_COMMENT
                                            : true
                                    )
                                }
                                name="RESET_CLOSE_COMMENT"
                            />
                        }
                        label={lang.RESET_CLOSE_COMMENT[language]}
                    />
                </Grid>
            )}
            {addData?.OBJID &&
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    addData?.SEND_EMAIL
                                        ? addData.SEND_EMAIL === true
                                        : false
                                }
                                onChange={() =>
                                    updateFieldRaw(
                                        "SEND_EMAIL",
                                        addData?.SEND_EMAIL ? !addData.SEND_EMAIL : true
                                    )
                                }
                                name="SEND_EMAIL"
                            />
                        }
                        label={lang.SEND_EMAIL[language]}
                    />
                </Grid>
            }
        </>
    );
}
