import React, { useState } from "react";
import axios from "axios";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import MaterialTable from "../../../../Components/Misc/Tables/MaterialTableSimple";
import { modelVi } from "../../../../Components/Misc/Tables/TableHeaders";
import SortBar from '../SortBar';
import TaskCommentHistory from "../../../../Components/Modals/TaskCommentHistory";

const ReportsExecutedInstallation = (props) => {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [data, setData] = useState([]);
    const [taskCommentModal, setTaskCommentModal] = useState(false);
    const [history, setHistory] = useState([]);

    const getTaskHistory = async ({ id }) => {
        const response = await axios.get('/api/task_history', {
            ...requestHeaders.getGetHeader(),
            params: {
                id
            }
        });
        if (response.status === 200 || response.status === 204) {
            setHistory(response.data.history);
        } else {
            setHistory([]);
        }
    };

    const getUserTaskActivity = async ({ startDate, endDate, selectedContractor }) => {
        const response = await axios.get('/api/register_task_exec', {
            ...requestHeaders.getGetHeader(),
            params: {
                startInterval: startDate,
                endInterval: endDate,
                contractorId: selectedContractor
            }
        });
        if (response.status === 200 || response.status === 204) {
            setData(response.data.data);
        } else {
            setData([]);
        }
    };

    const downloadAction = ({ startDate, endDate, selectedContractor }) => {
        fetch(`/api/register_task_exec_excel?startInterval=${startDate}&endInterval=${endDate}&contractorId=${selectedContractor}`,
            {
                ...requestHeaders.getGetHeader(),
                responseType: 'arraybuffer',
            }
        )
            .then(response => response.blob())
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = `Registru_cu_evidanta_lucrarilor_executate_${new Date().getDate()}.xlsx`;
                link.click();
            })
            .catch(err => console.log(err))
    };

    return (
        <>
            <TaskCommentHistory
                open={taskCommentModal}
                tiny={true}
                classes={{}}
                data={history}
                language={props.language}
                close={() => {
                    setTaskCommentModal(false);
                }}
            />
            <SortBar
                language={language}
                filter={getUserTaskActivity}
                onDownload={downloadAction}
            />
            <MaterialTable
                tiny={true}
                title="Registru cu evidenta lucrarilor executate"
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={modelVi(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={(id) => {
                    setTaskCommentModal(true);
                    getTaskHistory({ id });
                }}
                clickLabel="TASK_ID"
                hideAddBtn={true}
            />
        </>
    )
}

export default ReportsExecutedInstallation;
