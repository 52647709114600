import React, { useCallback } from "react";
import { Grid, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import * as lang from "../../../Constants/strings";
import { intervention } from "../../../Components/Misc/Tables/TableHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";

export default function InterventionTable(props) {
	const {
		data,
		language,
		tiny,
		loading,
		hasPermissionToAdd,
		hasPermissionToViewStatus
	} = props;
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// get the table header
	const getHeader = useCallback(() => {
		let header = intervention(language);
		// if the user has the permission insert in the header the column for intervention status
		if(hasPermissionToViewStatus) {
			header.splice(4, 0,{
		    label: lang.BILLING[language],
		    id: 'IS_FREE_TRANS',
		    numeric: false,
		    disablePadding: false,
		  });
		};
		return header;
	}, [hasPermissionToViewStatus, language, data]);

	return (
		<React.Fragment>
			{loading ? (
				<SkeletonInterventionTable {...props} />
			) : (
				<Grid
					item
					xs={12}
					md={12}
					container
					style={{ paddingRight: "0px" }}
					direction="column"
				>
					<MaterialTable
						hideAddBtn={!hasPermissionToAdd}
						tiny={tiny}
						title={lang.ALARMS[language]}
						language={language}
						data={props.data && props.data.length > 0 ? data : []}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={(value) => setRowsPerPage(value)}
						header={getHeader()}
						dense={false}
						// openAddModal={() => console.log("ADD")}
						openAddModal={props.openAddModal}
						openRowModal={props.openRowModal}
						// openRowModal={props.openRowModal}
						clickLabel="OBJID"
					/>
				</Grid>
			)}
		</React.Fragment>
	);
}

export function SkeletonInterventionTable(props) {
	const { classes } = props;
	return (
		<Grid item xs={12} md={12} container direction="column">
			<Skeleton component={Paper} height="45vh">
				<Grid
					container
					justify="center"
					alignItems="center"
					direction="column"
				>
					<Skeleton width="80%" />
					<Skeleton width="80%" />
					<Skeleton width="80%" />
					<Skeleton width="80%" />
				</Grid>
			</Skeleton>
		</Grid>
	);
}
