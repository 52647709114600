import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import qs from "qs";
import moment from "moment";

import * as actionCreator from "../../actions/actions";
import * as lang from "../../Constants/strings";
import {
    makeStyles,
    CssBaseline,
    useMediaQuery,
    useTheme,
    Grid,
    Paper,
    MenuItem,
    Divider,
    Select,
    FormControl,
    InputLabel,
    Typography
} from "@material-ui/core";

import MaterialTableSimple from "../../Components/Misc/Tables/MaterialTableSimple";
import * as requestHeaders from "../../Constants/requestHeaders";
import { technic_management_view } from "../../Components/Misc/Tables/TableHeaders";
import MaintenanceModal from "../Maintenance/MaintenanceModal/MaintenanceModal";
import TaskModal from "../Site/SiteSingleViewComponents/Modals/TaskModal";
import { downloadFile } from "../../Functions/fileDownload";
import { formatDateFromDb } from "../../Functions/dateFunctions";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: "auto",
    },
    paper: {
        padding: '5px'
    },
    filter: {
        marginLeft: '5px',
    },
    areaSelect: {
        maxHeight: "50vh",
        "& *::-webkit-scrollbar": {
            display: "none",
            scrollbarWidth: "none"
        }
    },
    formControl: {
        margin: theme.spacing(1),
        padding: "5px"
    },
}));

const TechnicManagement = (props) => {
    // props and other hooks
    const {
        language,
        permissions,
        userAuth,
        actions,
        isAuthenticated
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down("xs"));

    // state
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [data, setData] = useState([]);

    const [selectedService, setSelectedServices] = useState([]);

    const [selectedArea, setSelectedArea] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const [selectedMaintenanceId, setSelectedMaintenanceId] = useState(null);

    const [taskModal, setTaskModal] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);

    /* 
        this state is for the TaskModal
        the logic was complex i did not have time to reimplement the mess
    */

    const [journalData, setJournalData] = useState({
        NO: "",
        DATE: new Date(),
        POS: "",
    });
    const [opt, setOpt] = useState([false, false, false, false]);
    const [project, setProject] = useState({
        NAME: "",
        NUMBER: "",
        SIGN: "",
    });
    const [journal, setJournal] = useState({
        NAME: "",
        NUMBER: "",
        SIGN: "",
    });
    const [showProject, setShowProject] = useState(false);
    const [showJournal, setShowJournal] = useState(false);
    const [trainees, setTrainees] = useState([
        {
            NAME: "",
            SIGN: "",
        },
    ]);

    // End here 

    // functions
    const fetchAreaList = async () => {
        const response = await axios.get(`/api/areas`, requestHeaders.getGetHeader());

        if (response.status === 200) {
            setAreaList(response.data.data);
        } else {
            setAreaList([]);
        }
    };

    const handelAreaSelect = (newValue) => {
        if (newValue.props.value === 0) {
            setSelectedArea([]);
        } else if (selectedArea.indexOf(newValue.props.value) === -1) {
            setSelectedArea([...selectedArea, newValue.props.value]);
        }
    };

    const handelServicesSelect = (newValue) => {
        if (newValue.props.value === 0) {
            setSelectedServices([])
        } else if (selectedService.indexOf(newValue.props.value) === -1) {
            setSelectedServices([...selectedService, newValue.props.value])
        }
    };

    const fetchData = async () => {
        const res = await axios.get('/api/technic_management', {
            ...requestHeaders.getGetHeader(),
            params: {
                areas: selectedArea.map(q => q.AREA_CODE),
                services: selectedService
            },
            paramsSerializer: params => qs.stringify(params)
        });

        if (res.status === 200) {
            let l = [];

            res.data.forEach(item => {
                l.push({
                    ...item,
                    TYPE_TRANS: item.TYPE_ID === '3' ? lang.MAINTENANCE[language] : lang.TASK[language],
                    STATUS_TRANS: lang[item.STATUS] ? lang[item.STATUS][language] : '-',
                    LAST_UPDATE_TRANS: moment(item.LAST_UPDATE).format('DD-MM-YYYY HH:mm'),
                    CREATE_TIME_TRANS: moment(item.CREATE_TIME).format('DD-MM-YYYY HH:mm'),
                });
            });

            setData(l);
        } else {
            setData([]);
        }
    };

    const handleRowPress = (id) => {
        const index = data.findIndex(e => e.TASK_ID === id);

        if (data[index].TYPE_ID === "3") {
            setMaintenanceModal(true);
            setSelectedMaintenanceId(id);
        } else {
            setTaskModal(true);
            setSelectedTaskId(id);
        }
    };

    // So this is copy paste from TaskView
    // Is a mess this
    const updateProject = (field, value) => {
        if (field === "SHOW")
            setShowProject(!showProject)
        else {
            let placeholder = { ...project };
            placeholder[field] = value;
            setProject(placeholder);
        }
    };
    const updateJournal = (field, value) => {
        if (field === "SHOW")
            setShowJournal(!showJournal)
        else {
            let placeholder = { ...journal };
            placeholder[field] = value;
            setJournal(placeholder);
        }
    };
    const resetProject = () => {
        setProject({ NAME: "", NUMBER: "", SIGN: "" });
        setShowProject(false);
    };
    const resetJournal = () => {
        setJournal({ NAME: "", NUMBER: "", SIGN: "" });
        setShowJournal(false);
    };

    const addRemoveTrainee = (index) => {
        let placeholder = [...trainees];
        if (index === false) placeholder.push({ NAME: "", SIGN: "" })
        else placeholder.splice(index, 1);
        setTrainees(placeholder);
    };
    const updateTrainee = (index, field, value) => {
        let placeholder = [...trainees];
        placeholder[index][field] = value;
        setTrainees(placeholder);
    };
    const resetTrainees = () => {
        setTrainees([{ NAME: "", SIGN: "" }]);
    };
    const updateOpt = (index) => {
        let placeholder = [...opt];
        placeholder[index] = !placeholder[index];
        setOpt(placeholder);
    };
    const resetOpt = () => setOpt([false, false, false, false]);

    const updateJournalData = (field, value) => {
        let placeholder = { ...journalData };
        placeholder[field] = value;
        setJournalData(placeholder);
    };
    const resetJournalData = () => {
        setJournalData({
            NO: "",
            DATE: new Date(),
            POS: "",
        },
        )
    };

    const newTaskAction = async (
        changeType,
        changeFrom,
        changeTo,
        note,
        siteContact,
        signature,
        serviceNo,
        warranty,
        opt,
        trainees,
        project,
        journal,
        dispatcher,
        type,
        products,
        teamSize,
        deadLine,
        pvImage
    ) => {
        props.actions.newTaskAction(props.userAuth.id, {
            TASK_OBJID: props.taskData.task.OBJID,
            REASON: props.taskData.task.REASON,
            CHG_USER: props.userAuth.id,
            CHG_TYPE: changeType,
            CHG_FROM: changeFrom,
            CHG_TO: changeType !== 2 ? changeTo : props.userAuth.id,
            NOTE: note,
            CONTRACT_NO: props.taskData.task.CONTRACT_NO,
            CONTRACT_TYPE: props.taskData.task.CONTRACT_TYPE
                ? lang[props.taskData.task.CONTRACT_TYPE][language]
                : "",
            SITE_CONTACT: siteContact,
            SIGNATURE: signature,
            SERVICE_NO: serviceNo,
            WARRANTY: warranty,
            OPT: opt,
            TRAINEES: trainees,
            PROJECT: project,
            JOURNAL: journal,
            DISPATCHER_ID: dispatcher,
            TYPE: type,
            PRODUCTS: products,
            TEAM_SIZE: teamSize,
            JOURNAL_DATA: journalData,
            DEAD_LINE: deadLine
                ? formatDateFromDb(deadLine, 12)
                : formatDateFromDb(new Date(), 12),
            PV_IMAGE: pvImage,
        });
        await props.actions.getActiveTaskData(selectedTaskId);
        if (
            props.taskData &&
            props.taskData.task &&
            props.taskData.task.CONTACT_OBJID
        ) {
            await props.actions.getCustomerData(
                props.taskData.task.CONTACT_OBJID
            );
        }
    };

    // Effects
    useEffect(() => {
        fetchAreaList();
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedArea, selectedService]);

    useEffect(() => {
        if (props.taskData.task) props.actions.getSiteData(props.taskData.task.SITE_OBJID);
    }, [props.taskData.task])

    return (
        <>
            <CssBaseline />
            <Grid
                container
                component="main"
                className={classes.root}
                spacing={tiny ? 1 : 4}
            >
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid
                            container
                            alignItems="center"
                            direction="row"
                            justify="space-between"
                        >
                            <Grid xs={5}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="outlined-type">{lang.SERVICES[language]}</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedService}
                                        MenuProps={{
                                            className: classes.areaSelect,
                                            getContentAnchorEl: () => null
                                        }}
                                        inputProps={{
                                            name: 'type',
                                            id: 'outlined-type'
                                        }}
                                        onChange={(e, newValue) => handelServicesSelect(newValue)}
                                    >
                                        <MenuItem value={0}>{lang.NONE[language]}</MenuItem>
                                        <MenuItem value="maintenances">{lang.MAINTENANCE[language]}</MenuItem>
                                        <MenuItem value="tasks">{lang.TASKS[language]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid xs={6}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    style={{ position: "relative" }}
                                >
                                    <InputLabel htmlFor="outlined-area">{lang.AREA[language]}</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedArea}
                                        MenuProps={{
                                            className: classes.areaSelect,
                                            getContentAnchorEl: () => null
                                        }}
                                        inputProps={{
                                            name: 'area',
                                            id: 'outlined-area',
                                        }}
                                        onChange={(e, newValue) => handelAreaSelect(newValue)}
                                    >
                                        <MenuItem value={0}>{lang.NONE[language]}</MenuItem>
                                        {areaList && areaList.sort((a, b) => a.AREA.toUpperCase() < b.AREA.toUpperCase() ? -1 : a.AREA.toUpperCase() > b.AREA.toUpperCase() ? 1 : 0).map(area => {

                                            return <MenuItem key={area.AREA} value={area}>{area.AREA}</MenuItem>
                                        })}
                                    </Select>
                                    <Typography variant="h6" style={{ position: "absolute", right: "45px", top: "12px" }} color="secondary">
                                        Nr. intrari {data.length}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Maintenance Modal */}
                <MaintenanceModal
                    open={maintenanceModal}
                    close={() => setMaintenanceModal(false)}
                    language={language}
                    userPermissions={permissions}
                    isAuthenticated={isAuthenticated}
                    onNotificationOpen={(type, message) =>
                        actions.onNotificationOpen(
                            type,
                            lang[message],
                            6000
                        )}
                    maintenanceId={selectedMaintenanceId}
                    user={userAuth}
                />

                {/* Task Modal */}
                <TaskModal
                    siteData={props.siteData}
                    language={language}
                    open={taskModal}
                    close={() => setTaskModal(false)}
                    taskId={selectedTaskId}
                    user={userAuth}
                    userPermissions={permissions}
                    isAuthenticated={isAuthenticated}
                    onNotificationOpen={(type, message) =>
                        props.actions.onNotificationOpen(type, lang[message], 6000)
                    }
                    addCustomerContract={(data) => {
                        props.actions.addCustomerContract(
                            props.userAuth.id,
                            {
                                ...data,
                                SITE_OBJID: props.siteData.OBJID,
                                SALE_CONTACT: props.userAuth.id,
                            },
                            props.language
                        );
                    }}
                    isUploadingFile={props.isUploadingFile}
                    cust={{
                        data: props.customerData,
                        loading:
                            props.isFetchingCustomerData ||
                            props.isFetchingSiteData,
                        taskData: props.taskData,
                        initialLoad: props.taskData.task
                            ? () =>
                                props.actions.getCustomerData(
                                    props.taskData.task.CONTACT_OBJID
                                )
                            : () => console.log("No customer id"),
                    }}
                    task={{
                        opt: opt,
                        updateOpt: updateOpt,
                        resetOpt: resetOpt,
                        project: project,
                        journal: journal,
                        showProject: showProject,
                        showJournal: showJournal,
                        updateProject: updateProject,
                        updateJournal: updateJournal,
                        journalData: journalData,
                        updateJournalData: updateJournalData,
                        resetJournalData: resetJournalData,
                        resetProject: resetProject,
                        resetJournal: resetJournal,
                        trainees: trainees,
                        updateTrainee: updateTrainee,
                        addRemoveTrainee: addRemoveTrainee,
                        resetTrainees: resetTrainees,
                        userId: props.userAuth.id,
                        data: props.taskData,
                        loading: props.isFetchingTaskData,
                        downloadFile: downloadFile,
                        fetchData: () => props.actions.getTaskData(selectedTaskId),
                        newTaskAction: newTaskAction,
                        resetTaskAction: (changeType, changeFrom, changeTo, note) =>
                            props.actions.resetTaskAction(props.userAuth.id, {
                                TASK_OBJID: props.taskData.task.OBJID,
                                REASON: props.taskData.task.REASON,
                                CHG_USER: props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : props.userAuth.id,
                                NOTE: note,
                            }),
                        newTaskComment: (changeType, changeFrom, changeTo, note, file) =>
                            props.actions.newTaskComment(props.userAuth.id, {
                                TASK_OBJID: props.taskData.task.OBJID,
                                REASON: props.taskData.task.REASON,
                                CHG_USER: props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : props.userAuth.id,
                                NOTE: note,
                                FILE: file,
                            }),
                    }}
                />

                <Grid item xs={12}>
                    <MaterialTableSimple
                        tiny={true}
                        title={lang.TECHNIC_MANAGEMENT[language]}
                        language={language}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={technic_management_view(language)}
                        dense={false}
                        openAddModal={() => { }}
                        openRowModal={(id) => handleRowPress(id)}
                        clickLabel="TASK_ID"
                        hideAddBtn={true}
                    />
                </Grid>
            </Grid>
        </>
    )
};


const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isFetchingTaskList: state.task.isFetchingTaskList,
        taskList: state.task.taskList,
        loadedPages: state.config.loadedPages,
        rowsPerPage: state.config.rowsPerPage,
        sortColumn: state.config.sortColumn,
        currentPage: state.config.currentPage,
        order: state.config.order,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,
        isFetchingTaskData: state.task.isFetchingTaskData,
        taskData: state.task.taskData,
        selectedSortItem: state.config.selectedSortItem,
        siteData: state.cust.siteData,
        isFetchingCustomerData: state.cust.isFetchingCustomerData,
        isFetchingSiteData: state.cust.isFetchingSiteData,
        customerData: state.cust.customerData,
        isUploadingFile: state.task.isUploadingFile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TechnicManagement);
