import React from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from '../../../actions/actions';
import {
    Grid,
    TextField,
    MenuItem,
    Fab,
    Divider,
    Typography,
    Chip,
    Box
} from '@material-ui/core';

import CustomModal from '../../../Components/Modals/CustomModal';
import * as lang from '../../../Constants/strings';
import * as requestHeaders from "../../../Constants/requestHeaders";
import TaskCommentHistory from "../../../Components/Modals/TaskCommentHistory";

function ScoreValidationModal(props) {
    const { open, language, close, id, lockAllFields, reopen, lockOverwrite } = props;
    // Use to make the initial REST call and ensure that will be done only for th initial load of the componet
    const [firstLoad, setFirstLoad] = React.useState(true);
    // The user list
    const [userList, setUserList] = React.useState([]);

    // Task Data
    const [taskReason, setTaskReason] = React.useState('');
    const [closedBy, setClosedBy] = React.useState('');
    const [teamMembers, setTeamMembers] = React.useState([]);
    const [score, setScore] = React.useState('');
    const [obs, setObs] = React.useState('');
    const [distance, setDistance] = React.useState('');

    const [lastNote, setLastNote] = React.useState("");
    const [commentsHistoryModal, setCommnetsHistoryModal] = React.useState(false);
    const [history, setHistory] = React.useState([]);
    // Effects

    // Initial Effect for fetching data
    React.useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            getUserList();
        }
    }, [props.open]);


    React.useEffect(() => {
        console.log('Do something after counter has changed', teamMembers);
    }, [teamMembers]);

    React.useEffect(() => {
        if (id)
            getTaskData(id)
    }, [id])

    // Functions

    const getUserList = () => {
        fetch('/api/users', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.userList.length > 0) {
                    setUserList(response.data.userList.map(user => ({ OBJID: user.USER_ID, USERNAME: user.USERNAME })));
                } else {
                    setUserList([]);
                }
            })
    };

    const getCloseComment = (taskHistory) => {
        let note = "";
        taskHistory.forEach(event => {
            if (event.CHG_TO === 8) {
                note = event.NOTE;
            }
        })

        return note;
    };

    const getCloseBy = (taskHistory) => {
        let closedBy = "";
        taskHistory.forEach(event => {
            if (event.CHG_TO === 8) {
                closedBy = event.USERNAME;
            }
        })

        return closedBy;
    };

    const updateInstallTeamScore = async () => {
        try {
            const response = await axios.post("/api/update_installation_team_score", {
                id: id,
                team: teamMembers,
                score: score,
                distance: distance,
                obs: obs
            }, {
                ...requestHeaders.getPostHeader(),
            });
            close();
        } catch (error) {
            console.log(error);
        }
    };

    const getTaskData = async (id) => {
        const response = await axios.get(`/api/get_installation_with_team`, {
            ...requestHeaders.getGetHeader(),
            params: {
                id: id
            }
        });

        if (response.status === 200) {
            if (response.data) {
                let taskHistory = response.data.taskHistory;
                let taskData = response.data.taskData;
                let teamMembers = response.data.team.map(member => member.OBJID);
                let score = response.data.score;

                setTaskReason(taskData.REASON ? taskData.REASON : '');
                setDistance(score ? score.DISTANCE : '');
                setObs(score ? score.OBS : '');
                setScore(score ? score.SCORE : '');
                setLastNote(getCloseComment(taskHistory));
                setClosedBy(getCloseBy(taskHistory));
                setHistory(taskHistory);
                setTeamMembers(teamMembers);
            }
        }
    };


    return (
        <React.Fragment>
            <TaskCommentHistory
                open={commentsHistoryModal}
                tiny={true}
                classes={{}}
                data={history}
                language={language}
                close={() => {
                    setCommnetsHistoryModal(false);
                    reopen();
                }}
            />
            <CustomModal
                fullWidth
                open={open}
                title={lang.SCORE_VALIDATION[language]}
                content={
                    <React.Fragment>
                        <Grid container alignItems='center' alignContent="center" justify="center" spacing={2}>
                            <Typography variant="h4">{lang.TASK_DETAILS[language]}</Typography>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    variant='outlined'
                                    name='reason'
                                    label={lang.TASK_REASON[language]}
                                    onChange={() => { }}
                                    value={taskReason}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    variant='outlined'
                                    name='reason'
                                    label={lang.TASK_CLOSE_COMMENT[language]}
                                    onChange={() => { }}
                                    value={lastNote}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    variant='outlined'
                                    name='closedBy'
                                    label={lang.TASK_CLOSED_BY[language]}
                                    onChange={() => { }}
                                    value={closedBy}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockAllFields}
                                    select
                                    variant='outlined'
                                    name='teamMembers'
                                    label={lang.USERS[language]}
                                    fullWidth
                                    SelectProps={{
                                        multiple: true,
                                        value: teamMembers || [],
                                        onChange: (e) => teamMembers.length <= 5 && setTeamMembers(e.target.value),
                                        renderValue: (selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {Array.isArray(selected) && selected.map(id => <Chip key={id} label={userList.find(user => user.OBJID === id).USERNAME} />)}
                                            </Box>)
                                    }}
                                >
                                    {
                                        userList.map(user => {
                                            return <MenuItem key={user.OBJID} value={user.OBJID}>{user.USERNAME}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockAllFields}
                                    variant='outlined'
                                    multiline
                                    name='distance'
                                    label={lang.DISTANCE[language]}
                                    onChange={(e) => setDistance(e.target.value)}
                                    value={distance}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockAllFields || lockOverwrite}
                                    variant='outlined'
                                    name='score'
                                    label={lang.SCORE[language]}
                                    onChange={(e) => setScore(e.target.value)}
                                    value={score}
                                    type="number"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={lockAllFields}
                                    variant='outlined'
                                    multiline
                                    name='obs'
                                    label={lang.OBSERVATION[language]}
                                    onChange={(e) => setObs(e.target.value)}
                                    value={obs}
                                    fullWidth
                                />
                            </Grid>
                            <Fab
                                style={{ margin: '10px' }}
                                color="primary"
                                aria-label="add"
                                variant="extended"
                                onClick={() => {
                                    setCommnetsHistoryModal(true)
                                    close();
                                }}
                            >
                                {lang.COMMENT_TASK_HISTORY[language]}
                            </Fab>
                        </Grid>
                    </React.Fragment>
                }
                close={close}
                execute={() => {
                    updateInstallTeamScore();
                    // close();
                }}
                language={language}
                formValidate={
                    true
                    // selectedBillType !== 3
                }
                validate={
                    !lockAllFields
                }
            />
        </React.Fragment >
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScoreValidationModal);