import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";

import * as requestHeaders from "../../../Constants/requestHeaders";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";

const ExternalUserSelectModal = (props) => {
    const {
        isOpen,
        onClose,
        language,
        selectedUserId,
        userId
    } = props;

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const fetchExternalUsers = async () => {
        try {
            const res = await axios.get("/api/external/users", requestHeaders.getGetHeader());
            setUsers(res.data);
        } catch (e) {
            console.error(e);
        }
    };

    const setUserAccountId = async () => {
        if (!selectedUser) {
            console.warn("Illegal call to execut function!");
            onClose();
            return;
        }

        try {
            await axios.post("/api/user/setExternalAccountId",
                {
                    id: userId,
                    accountId: selectedUser.id,
                },
                requestHeaders.getPostHeader()
            );

            onClose();
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchExternalUsers().then(() => { });
    }, []);

    useEffect(() => {
        const index = users.findIndex((x) => x.id === selectedUserId);
        if (index === -1) {
            setSelectedUser(null);
            return;
        }

        setSelectedUser(users[index]);
    }, [selectedUserId, users]);

    return (
        <CustomModal
            open={isOpen}
            title={"Selecteaza utilizatorul extern"}
            content={
                <FormControl style={{ width: "100%" }}>
                    <Select
                        variant="outlined"
                        value={selectedUser}
                        onChange={e => setSelectedUser(e.target.value)}
                        inputProps={{ id: 'account_select' }}
                        placeholder={lang.SELECT_EXTERNAL_ACCOUNT[props.language]}
                    >
                        {users.map((c) => <MenuItem key={c.NAME} value={c}>{`${c.displayName} (${c.email})`}</MenuItem>)}
                    </Select>
                </FormControl>
            }
            close={() => onClose()}
            execute={() => setUserAccountId()}
            validate={selectedUser !== null}
            language={language}
        />
    )
}

export default ExternalUserSelectModal;
