import React, { useState, useEffect, useCallback } from "react";
import { useMediaQuery, useTheme, Grid, Divider, makeStyles } from '@material-ui/core';
import axios from "axios";

import { downloadFile } from '../../../../Functions/fileDownload';
import CommentList from "./CommentList";
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as requestHeaders from "../../../../Constants/requestHeaders";
import ProjectDetails from "./ProjectDetails";
import UploadFiles from "../../../../Components/Modals/UploadFiles";
import ProjectActionButtons from "./ProjectActionButtons";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        width: "100%",
    },
    avatar: {
        width: 50,
        height: 50,
    },
    title: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    comment: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    commentAuto: {
        color: "gray",
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    timestamp: {
        marginTop: theme.spacing(1.5),
    },
    divider: {
        margin: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        marginTop: theme.spacing(1),
    },
    iconRight: {
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    signature: {
        backgroundColor: "white",
        width: "100%",
        height: "50vh",
    },
    float: {
        margin: theme.spacing(1),
    },
}));

const ProjectActionModal = (props) => {
    const {
        language,
        open,
        close,
        projectId,
        userId,
        userPermission
    } = props;

    const classes = useStyles();

    const [projectData, setProjectData] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [commentFileUploadModal, setCommentFileUlpoadModal] = useState(false);
    const [sendingComment, setSendingComment] = useState(false);
    const [commentText, setCommentText] = React.useState("");
    const [showCommentBox, setShowCommentBox] = React.useState(false);

    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const getProjectById = async () => {
        try {
            const response = await axios.get(`api/project/${projectId}`, requestHeaders.getGetHeader());
            setProjectData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const createProjectComment = async (note = '', file = null) => {
        setSendingComment(true);
        try {
            await axios.post('/api/project_comment', {
                CHG_FROM: null,
                CHG_TO: null,
                CHG_TYPE: 'COMMENT',
                CHG_USER: userId,
                FILE: file,
                NOTE: note,
                REASON: "PROJECT",
                PROJECT_TASK_OBJID: projectId
            },
                requestHeaders.getPostHeader()
            );

            await getProjectById();
            setSendingComment(false);
            setCommentText("");
            showCommentBox(false);
        } catch (error) {
            setSendingComment(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (projectId) {
                getProjectById(projectId)
            }
        })();
    }, [projectId]);

    /**
     * The action executed depends on the project task current state
     */
    const executeAction = useCallback(async (newData) => {
        /**
         * If on the last step the projecting task doesn't have a bill,
         * we just alert the user that he can't perform the action
         * and return
         */
        if (!projectData?.hasBill && projectData?.project?.STATUS === "ASSIGN") {
            await getProjectById();
            if (!projectData?.hasBill && projectData?.project?.STATUS === "ASSIGN") {
                alert("Pentru a incepe proiectul asigurate ca ai adaugat factura!");
                return;
            }
        }

        if (projectData) {
            const data = {
                id: projectData.project.ID,
                ...newData,
                userId
            };

            try {
                await axios.put('/api/projects', data, requestHeaders.getPostHeader())
            } catch (error) {
                console.error(error);
            }

            // Make sure to refetch
            await getProjectById();
        }
    }, [projectData]);

    return (
        <CustomModal
            open={open}
            fullScreen
            title={projectData ? `Proiectare pe baza contractului numarul ${projectData?.contractData?.CONTRACT_NO}` : 'Proiectare'}
            content={
                <Grid container spacing={2}>

                    <UploadFiles
                        open={commentFileUploadModal}
                        close={() => setCommentFileUlpoadModal(false)}
                        execute={(base64File) => setUploadedFile(base64File)}
                        language={props.language}
                    />

                    <Grid xs={12}>
                        <ProjectDetails data={projectData} />
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={12}>
                        <ProjectActionButtons
                            userId={userId}
                            reFetch={getProjectById}
                            projectId={projectData?.project?.ID}
                            doAction={executeAction}
                            projectStatus={projectData?.project?.STATUS}
                            language={language}
                            disabledActionButton={projectData?.project?.OWNER_CURR !== userId && projectData?.project?.STATUS !== 'CREATED'}
                            userPermission={userPermission}
                            currentOwnerId={projectData?.project?.OWNER_CURR}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={12}>
                        <CommentList
                            downloadFile={downloadFile}
                            task={projectData}
                            data={projectData && projectData.history}
                            language={language}
                            tiny={tiny}
                            classes={classes}
                            isIntervention={false}
                            openFileUploadModal={() => setCommentFileUlpoadModal(true)}
                            uploadedFile={uploadedFile}
                            addComment={(note, uploadedFile) => createProjectComment(note, uploadedFile)}
                            sendingComment={sendingComment}
                            commentText={commentText}
                            showCommentBox={showCommentBox}
                            handleCommentTextChange={value => setCommentText(value)}
                            handleCommentBoxShow={value => setShowCommentBox(value)}
                        />
                    </Grid>
                </Grid>
            }
            close={close}
            execute={() => close()}
            language={language}
            validate={null}
        />
    )
};

export default ProjectActionModal;