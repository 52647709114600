import * as lang from './strings';

export const customerSearch = (language) => [
    { id: 1, label: lang.ID[language], value: '', render: false },
    { id: 2, label: lang.COD[language], value: '', render: false },
    { id: 3, label: lang.PHONE[language], value: '', render: false },
    { id: 4, label: lang.NAME[language], value: '', render: false },
    { id: 5, label: lang.CUI[language], value: '', render: false },
    { id: 6, label: 'General', value: '', render: false },
]

export const customerData = (language) => {
    return {
        PJ: [
            { id: 1, label: lang.NAME[language], value: '', render: false, required: true, numeric: false, },
            { id: 2, label: lang.CUI[language], value: '', render: false, required: true, numeric: false, },
            { id: 3, label: lang.NR_REG[language], value: '', render: false, required: true, numeric: false, },
            { id: 4, label: lang.ADMINISTRATOR[language], value: '', render: false, required: true, numeric: false, },
            { id: 5, label: lang.PHONE[language], value: '', render: false, required: true, numeric: true, },
            { id: 6, label: lang.EMAIL[language], value: '', render: false, required: false, numeric: false, },
        ],
        PF: [
            { id: 1, label: lang.LAST_NAME[language], value: '', render: false, required: true, numeric: false },
            { id: 2, label: lang.FIRST_NAME[language], value: '', render: false, required: true, numeric: false },
            { id: 3, label: lang.CNP[language], value: '', render: false, required: true, numeric: true },
            { id: 4, label: lang.PHONE[language], value: '', render: false, required: true, numeric: true },
            { id: 5, label: lang.EMAIL[language], value: '', render: false, required: false, numeric: false },
        ],
    }
}

export const customerAddressData = (language) => {
    return {
        county: { id: 1, label: lang.REGION[language], value: '', render: false, required: true },
        locality: { id: 2, label: lang.CITY[language], value: '', render: false, required: true },
        street: { id: 3, label: lang.STREET[language], value: '', render: false, required: true },
        nrStreet: { id: 4, label: lang.STREET_NO[language], value: '', render: false, required: false },
        building: { id: 5, label: lang.BUILDING[language], value: '', render: false, required: false },
        scale: { id: 6, label: lang.ENTRANCE[language], value: '', render: false, required: false },
        apartment: { id: 7, label: lang.APARTMENT[language], value: '', render: false, required: false },
        gps: { id: 8, label: lang.GPS[language], value: '', render: false, required: false }
    }
}
export const department = {
    DEPARTMENT: "",
    CONTRACT_ASSIGNEE: false,
    DISPATCH_ASSIGNEE: false,
}
export const custData = {
    PJ: {
        CUSTOMER_CODE: "",
        NAME: "",
        CUI: "",
        NR_REG: "",
        ADMINISTRATOR: "",
        PHONE: "",
        EMAIL: "",
        HOME: true,
        BILL: false,
    },
    PF: {
        CUSTOMER_CODE: "",
        LAST_NAME: "",
        FIRST_NAME: "",
        CNP: "",
        PHONE: "",
        EMAIL: "",
        HOME: true,
        BILL: true,
    }
}

export const custAddress = {
    REGION: "",
    CITY: "",
    STREET_NAME: "",
    STREET_NO: "",
    BUILDING: "",
    ENTRANCE: "",
    FLOOR: "",
    APARTMENT: "",
    ZIP_CODE: "",
    LATITUDE: "",
    LONGITUDE: "",
    AREA_CODE: "",
}
export const custSite = {
    CONTACT_FIRST_NAME: "",
    CONTACT_LAST_NAME: "",
    CONTACT_PHONE: "",
    PHONE: "",
    EMAIL: "",
    SITE_NAME: "",
    SITE_CODE: "",
    CENTRAL_TYPE: "",
    CENTRAL_PASSWORD: "",
    CENTRAL_PHONE: "",
    OBS: "",
}

export const contract = {
    TYPE: 1,
    SUB_TYPE: 1,
    CONTRACTOR_OBJID: "",
    CONTRACT_NO: "",
    START_DATE: null,
    END_DATE: null,
    VALUE: "",
    FREE_TRUCK_ROLLS: "",
    TRUCK_ROLL_VALUE: "",
    NFC: "",
    BILL_CYCLE: 1,
    ASSIGNED_TEAM: "",
    CONTRACT_ASSIGNEE: "",
    INDETERMINATE: false,
}

export const task = {
    TITLE: "TASK_TITLE",
    SUBTITLE: "TASK_SUBTITLE",
    REASON: "",
    TYPE: "",
    CONTRACT_OBJID: "",
}

export const intervention = {
    TITLE: "INTERVENTION_TITLE",
    SUBTITLE: "INTERVENTION_SUBTITLE",
    TYPE: null,
    SUBTYPE: null,
    CONTRACT_OBJID: null,
    START_TIME: null,
    END_TIME: null,
    AREA_CODE: null,
    NOTE: "",
}

// export const siteType = {
//     CNP: {
//         value: '',
//         rules: [
//             {
//                 minLength: 13,
//                 errorText: lang.MIN_CHARS_ERROR(13),
//             },
//             {
//                 maxLength: 13,
//                 errorText: lang.MIN_CHARS_ERROR(13),
//             },
//         ],
//     }
// }