import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Paper,
    IconButton,
    TextField,
    InputAdornment,
    Grow,
    Typography,
} from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import moment from "moment";

import SearchIcon from '@material-ui/icons/SearchRounded';
import AddIcon from '@material-ui/icons/AddBoxRounded';
import ClearIcon from '@material-ui/icons/ClearRounded';
import * as lang from '../../../Constants/strings';
import { formatDateFromDb } from '../../../Functions/dateFunctions';

function desc(a, b, orderBy, isDate) {
    // console.log(orderBy, a, b)
    if (orderBy === 'PRIORITY') {
        if (a.PRIORITY.props.title > b.PRIORITY.props.title) return 1;
        if (a.PRIORITY.props.title < b.PRIORITY.props.title) return -1;
        return 0;
    }
    if (isDate) {
        console.log("a[orderBy]?.replace(',',''): ", a[orderBy]?.replace(',', ''));
        /**
         * possible formats:
         *  DD-MM-YYYY
         *  DD-MM-YYYY HH:mm
         *  DD-MM-YYYY HH:mm:ss
         */

        const formats = ["DD-MM-YYYY HH:mm:ss", "DD-MM-YYYY", "DD-MM-YYYY HH:mm"];

        const findFormat = () => {
            for (const f of formats) {
                if (moment(a[orderBy]?.replace(',', '').trim(), f).isValid()) {
                    return f;
                }
            };
            return null;
        }

        const format = findFormat();

        if (format) {
            const formattedA = moment(a[orderBy]?.replace(',', '').trim(), format);
            const formattedB = moment(b[orderBy]?.replace(',', '').trim(), format);

            if (formattedA < formattedB) {
                return -1;
            }

            if (formattedA > formattedB) {
                return 1;
            }
            return 0;
        } else {
            return 0;
        }
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    if (typeof array[0]?.PRIORITY !== 'object') {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }
    const arrayPrio = array.filter(i => i.PRIORITY.props.title === 'Urgentă');
    const arrayNonPrio = array.filter(i => i.PRIORITY.props.title !== 'Urgentă');

    const stabilizedThisPrio = arrayPrio.map((el, index) => [el, index]);
    stabilizedThisPrio.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    const stabilizedThisNonPrio = arrayNonPrio.map((el, index) => [el, index]);
    stabilizedThisNonPrio.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThisPrio.concat(stabilizedThisNonPrio).map(el => el[0]);
}

function getSorting(order, orderBy, isDate) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, isDate) : (a, b) => -desc(a, b, orderBy, isDate);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, header } = props;
    const createSortHandler = (property, isDate) => event => {
        onRequestSort(event, property, isDate);
    };

    return (
        <TableHead>
            <TableRow>
                {header.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id, headCell.isDate)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: "space-between",
        padding: theme.spacing(2),
    },
    wrapper: {
        display: 'flex',
        justifyContent: "flex-start",
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    clear: {
        position: 'absolute',
        right: 0,
        marginRight: theme.spacing(3)
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const [text, setText] = React.useState(false);

    const { searchText, tiny, hideAddBtn } = props;

    return (
        <Toolbar className={classes.root}>
            {text
                ?
                <React.Fragment>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        placeholder={lang.SEARCH[props.language]}
                        onFocus={() => setText(true)}
                        onBlur={() => setText(false)}
                        value={searchText}
                        onChange={props.handleSearch}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape')
                                setText(false);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton onClick={() => setText(false)} className={classes.clear}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                :
                <React.Fragment>
                    {tiny === true
                        ?
                        <React.Fragment>
                            <div className={classes.wrapper}>
                                <span>
                                    {hideAddBtn === true ?
                                        null
                                        :
                                        <IconButton onClick={props.openAddModal} className={classes.margin}>
                                            <AddIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </span>
                                <Typography className={classes.title} variant="h6" id="tableTitle">
                                    {props.title}
                                </Typography>
                            </div>
                            <span>
                                <IconButton onClick={() => setText(true)} className={classes.margin}>
                                    <SearchIcon fontSize="inherit" />
                                </IconButton>
                            </span>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className={classes.wrapper}>
                                <span>
                                    {hideAddBtn === true ?
                                        null
                                        :
                                        <IconButton onClick={props.openAddModal} className={classes.margin}>
                                            <AddIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </span>
                                <Typography className={classes.title} variant="h6" id="tableTitle">
                                    {props.title}
                                </Typography>
                            </div>
                            <TextField
                                variant="outlined"
                                placeholder={lang.SEARCH[props.language]}
                                onFocus={() => setText(true)}
                                value={searchText}
                                onChange={props.handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </Toolbar>
    );
};

const style = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        width: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

class EnhancedTable extends Component {
    state = {
        order: 'asc',
        orderBy: 'ID',
        isDate: undefined,
        page: 0,
        searchText: "",
        searchResults: this.props.data,
        hideAddBtn: this.props.hideAddBtn || false
    }
    handleRequestSort = (event, property, isDate) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property, isDate })
    };

    handleChangeRowsPerPage = event => {
        this.props.onRowsPerPageChange(parseInt(event.target.value, 10));
        this.setState({ page: 0 })
    }

    componentDidUpdate(prevProp) {
        console.log("componentDidUpdate: ", this.state.data)
        if (prevProp.data !== this.props.data) {
            this.searchData(this.state.searchText);
        }
    }

    handleChangePage = (event, newPage) => this.setState({ page: newPage });

    getRow = (row, header, index) => {
        let results = [];
        header.forEach(field => {
            results.push(<TableCell key={field.id}>{field.date ? formatDateFromDb(row[field.id], 12) : row[field.id]}</TableCell>)
        })
        return results;
    }
    searchData = (searchText) => {
        this.setState({ searchText: searchText.toLowerCase() })
        let results = [];
        let ok = 0;
        for (let i = 0; i < this.props.data.length; i++) {
            ok = 1;
            let placeholder = "";
            for (let key in this.props.data[i]) {
                placeholder = placeholder + this.props.data[i][key] + ";";
            }
            let searchQueries = searchText.trim().split(" ");
            for (let i in searchQueries) {
                if (searchQueries[i].length > 0 && placeholder.toLowerCase().indexOf(searchQueries[i].toLowerCase().trim()) === -1) {
                    ok = 0;
                }
            }
            if (ok === 1)
                results.push(this.props.data[i])
        }
        this.setState({ searchResults: results, page: 0 });
    }


    render() {
        const { header, data, classes, title, tiny, clickLabel, loadingData } = this.props;
        const emptyRows = this.props.rowsPerPage - Math.min(this.props.rowsPerPage, data.length - this.state.page * this.props.rowsPerPage);
        return (
            <div className={classes.root}>
                <Grow in={true} style={{ transitionDelay: '100ms' }}>
                    <Paper className={classes.paper}>
                        <EnhancedTableToolbar
                            tiny={tiny}
                            title={title}
                            searchText={this.state.searchText}
                            hideAddBtn={this.state.hideAddBtn}
                            handleSearch={(e) => this.searchData(e.target.value)}
                            language={this.props.language}
                            openAddModal={this.props.openAddModal}
                        />
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={this.props.dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    header={header}
                                    classes={classes}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {!loadingData && stableSort(this.state.searchResults.length === 0
                                        && this.state.searchText === "" ?
                                        this.props.data
                                            .filter(i => i.PRIORITY === undefined ? true : i?.PRIORITY.props?.title === 'Urgentă')
                                            .concat(this.props.data
                                                .filter(i => i.PRIORITY === undefined ? false : i?.PRIORITY.props?.title !== 'Urgentă'))
                                        : this.state.searchResults
                                            .filter(i => i.PRIORITY === undefined ? true : i?.PRIORITY.props?.title === 'Urgentă')
                                            .concat(this.state.searchResults
                                                .filter(i => i.PRIORITY === undefined ? false : i?.PRIORITY.props?.title !== 'Urgentă')),
                                        getSorting(this.state.order, this.state.orderBy, this.state.isDate))
                                        .slice(this.state.page * this.props.rowsPerPage, this.state.page * this.props.rowsPerPage + this.props.rowsPerPage)
                                        .map((row, index) =>
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.props.openRowModal(row[clickLabel], row)}
                                            >
                                                {this.getRow(row, header, index)}
                                            </TableRow>
                                        )}
                                    {!loadingData && emptyRows > 0 && (
                                        <TableRow style={{ height: (this.props.dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.searchResults.length === 0 && this.state.searchText === "" ? data.length : this.state.searchResults.length}
                            rowsPerPage={this.props.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            labelRowsPerPage={lang.ROWS_PER_PAGE[this.props.language]}
                            labelDisplayedRows={lang.ROWS_FUNC[this.props.language]}
                        />
                    </Paper>
                </Grow>
            </div >
        );

    }

}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EnhancedTable));