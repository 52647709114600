const defaultRefresh = 60000;
let intervalId;

export const startInterval = (fn, clear, refreshValue) => {
    clearInterval(intervalId);
    let intervalMs = refreshValue ? refreshValue : defaultRefresh;
    intervalId = setInterval(() => {
        fn();
    }, intervalMs);
    if (clear) {
        clearInterval(intervalId);
    }
};