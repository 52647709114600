import React from 'react';
import CustomModal from '../../../Components/Modals/CustomModal';
import { Grid, Typography } from '@material-ui/core';

export default function ChangeCutomerStatusModal(props) {
    const { open, language, execute, close, status } = props;

    return (
        <CustomModal
        open={open}
        fullWidth
        title=''
        content={
            <Grid container spacing={2} justify='center'>
                <Typography variant='h5' color='secondary'>
                    {
                        status === 1 ? "Clientul va fi dezactivat!" : "Clientul va fi activat!"
                    }
                </Typography>
            </Grid>
        }
        execute={ () => {
            execute();
            close();
        }}
        language={language}
        validate={true}
        close={close}
        />
    )
}
