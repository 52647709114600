import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { FormControlLabel, Switch, InputLabel, Grid } from '@material-ui/core';
import * as lang from '../../../../Constants/strings';

function EquipmentModal(props) {
    const { siteId, open } = props;
    const [equipmentList, setEquipmentList] = React.useState([]);
    const [siteEquipment, setSiteEquipment] = React.useState([]);

    React.useEffect(() => {
        fetch(`/api/get_equipment`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    'authorization': localStorage.token,
                }
            })
            .then(response => {
                if (response.status >= 400) {
                    props.openNotificcation('warning', response.message)
                }
                return response.json();
            })
            .then(response => {
                setEquipmentList(response.data)
            })

    }, []);

    React.useEffect(() => {
        fetch(`/api/get_site_equipment?siteId=${siteId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'authorization': localStorage.token,
            }
        })
            .then(response => {
                if (response.status >= 400) {
                    props.openNotificcation('warning', response.message)
                }
                return response.json();
            })
            .then(response => {
                if (response.data.length === 0) {
                    let newData = equipmentList.map(data => {
                        return { FK2SITE_OBJID: siteId, FK2EQUIPMENT_OBJID: data.OBJID, INSTALED: 0 }
                    })
                    setSiteEquipment(newData);
                } else {
                    // Check if new items was added to the equipmentList and get the existing data and then add the new data as objects with no OBJID KEY
                    if (response.data.length === equipmentList.length) {
                        setSiteEquipment(response.data);
                    } else {
                        let data = []
                        response.data.map(dataItem => {
                            equipmentList.map(equipment => {
                                if (equipment.OBJID === dataItem.FK2EQUIPMENT_OBJID) {
                                    return data.push(dataItem);
                                } else {
                                    return data.push({ FK2SITE_OBJID: siteId, FK2EQUIPMENT_OBJID: dataItem.OBJID, INSTALED: 0 })
                                }
                            })
                        })
                        setSiteEquipment(data);
                    }
                }
            })
    }, [equipmentList, siteId]);

    function updateSiteEquipment(equipmentId) {
        let data = siteEquipment;
        if (data[equipmentId].INSTALED === 1) {
            data[equipmentId].INSTALED = 0;
        } else {
            data[equipmentId].INSTALED = 1;
        }
        setSiteEquipment([...data]);
    };

    function updateReqSiteEquipment() {
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ data: siteEquipment })
        };
        fetch(`/api/update_site_equipment`, options)
            .then(response => {
                if (response.status >= 400) {
                    props.openNotificcation('warning', response.message)
                }
                return response.json();
            })
            .then(response => {
                props.openNotificcation('success', response.message)
            })
    }

    return (
        <React.Fragment>
            <CustomModal
                // fullWidth
                open={open}
                title={lang.ISNTALED_EQUIPMENT[props.language]}
                content={
                    <Grid container justify='center' alignItems='center' spacing={2}>
                        {siteEquipment.map(eq =>
                            <FormControlLabel
                                key={'controle_' + siteEquipment.indexOf(eq)}
                                label={equipmentList[siteEquipment.indexOf(eq)] ? equipmentList[siteEquipment.indexOf(eq)].EQUIPMENT_TYPE : '#null'}
                                control={
                                    <Switch
                                        key={'check_' + siteEquipment.indexOf(eq)}
                                        checked={siteEquipment[siteEquipment.indexOf(eq)] ? siteEquipment[siteEquipment.indexOf(eq)].INSTALED === 1 : false}
                                        onChange={() => updateSiteEquipment(siteEquipment.indexOf(eq))}
                                    />}
                            />
                        )}
                    </Grid>
                }
                close={() => props.close()}
                execute={() => {
                    updateReqSiteEquipment()
                    props.close()
                }}
                validate
                language={props.language}
            />
        </React.Fragment>
    )

}

export default EquipmentModal;