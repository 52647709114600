import React, { Component } from 'react';
import {
    Grid,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    InputAdornment,
} from '@material-ui/core';
import { Lock, CheckCircleRounded } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import imageCompression from 'browser-image-compression';

class InterventionAction extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    };

    state = {
        signDialog: false,
        trainees: this.props.trainees,
        userList: [],
        roleList: []
    }

    componentDidMount() {
        this.getUsers();
    };

    getUsers() {
        fetch('/api/users', {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                let relativeUserNumber = 0;
                let userList = response.data.userList.map(user => {
                    relativeUserNumber++;
                    user["NR"] = relativeUserNumber;
                    if (user.LOCKED === 1)
                        user.LOCKED = <Lock color='secondary' />
                    else
                        user.LOCKED = <CheckCircleRounded color='primary' />
                    return user;
                })
                this.setState({ userList: userList, roleList: response.data.roleList })
            })
            .catch(err => console.log(err))
    };

    resetCanvas() {
        if (this.canvasRef.current) {
            const canvas = this.canvasRef.current;
            const ctx = canvas.getContext('2d');
            canvas.width = 0;
            canvas.height = 0;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.props.onImageLoad(null);
        }
    };

    render() {
        const { docNo,
            updateDocNo,
            note,
            updateNote,
            language,
            newStatusId,
            alarm,
            updateAlarm,
            isAlarm,
            offender,
            updateOffender,
            report,
            updateReport,
            theft,
            updateTheft
        } = this.props;
        const imageUploaderRef = React.createRef();
        const compressionOption = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        const handelCanvasInput = async (e) => {
            this.props.onImageLoad(null);
            e.persist()
            if (e.target.files.length > 0) {
                let compressImg;
                let img = new Image();
                img.crossOrigin = 'Anonymous';

                const canvas = this.canvasRef.current;
                const ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                try {
                    compressImg = await imageCompression(e.target.files[0], compressionOption);
                } catch (error) {
                    console.log('Can not compress the image: ', error);
                };

                let url = URL.createObjectURL(compressImg !== undefined ? compressImg : e.target.files[0]);

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                }

                img.src = url;
                await this.props.onImageLoad(compressImg !== undefined ? compressImg : e.target.files[0]);
            }

        }

        return (
            <React.Fragment>
                {newStatusId !== 5 && newStatusId !== 6 &&
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="OBSERVATION"
                            label={newStatusId === 8 && isAlarm ? lang.RESOLUTION[language] : lang.OBSERVATION[language]}
                            value={note ? note : ""}
                            onChange={updateNote}
                        />
                    </Grid>
                }
                {
                    newStatusId === 8 &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                label='Membrii echipaj'
                                value={this.props.teamMembers}
                                onChange={e => this.props.addNewTeamMember(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant='outlined'
                                label={lang.CONTACT_PERSON[this.props.language]}
                                fullWidth
                                value={this.props.presentPerson}
                                onChange={e => this.props.setPresentPerson(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant='outlined'
                                label={lang.NR_PHONE[this.props.language]}
                                type='number'
                                fullWidth
                                value={this.props.presentPersonContact}
                                onChange={e => this.props.setPresentPersonContact(e.target.value)}
                            />
                        </Grid>
                    </React.Fragment>
                }
                {
                    newStatusId === 8 && isAlarm &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={alarm} onChange={() => updateAlarm(!alarm)} />}
                                label={<Typography variant="caption">Alarmă confirmată (reală)</Typography>}
                            />
                        </Grid>
                        {alarm !== false &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={report} onChange={() => updateReport(!report)} />}
                                        label={<Typography variant="caption">Sesizare poliție</Typography>}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {newStatusId === 8 &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                required
                                variant="outlined"
                                name="DOC_NO"
                                label={lang.CONFIRM_DOC_NO[language]}
                                value={docNo ? docNo : ""}
                                onChange={updateDocNo}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={theft !== false} onChange={() => updateTheft(theft === false ? 0 : false)} />}
                                label={<Typography variant="caption">Sustrageri depistate</Typography>}
                            />
                        </Grid>
                        {theft !== false &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="PREJUDICE"
                                        label={lang.PREJUDICE[language]}
                                        value={theft ? theft : ""}
                                        onChange={(e) => updateTheft(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">RON</InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={offender} onChange={() => updateOffender(!offender)} />}
                                        label={<Typography variant="caption">Prindere autor</Typography>}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {
                    this.props.loadImage && <React.Fragment>
                        <Grid container justify='space-evenly' alignItems='center' alignContent='space-around'>
                            <Grid item>
                                <Button
                                    style={{ marginTop: 10 }}
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => imageUploaderRef.current.click()}
                                >
                                    {lang.LOAD_IMAGE[this.props.language]}
                                </Button>
                                <input
                                    readOnly
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0, width: 0 }}
                                    value={this.props.pvImage ? this.props.pvImage : null}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <form method="post" enctype="multipart/form-data">
                            <input
                                type="file"
                                accept="image/png, image/jpeg"
                                style={{ display: "none" }}
                                ref={imageUploaderRef}
                                name="image"
                                tabIndex={-1}
                                onChange={handelCanvasInput}
                            />
                        </form>
                        <Grid item xs={12} >
                            <canvas
                                style={{ maxWidth: 550, background: "none" }}
                                ref={this.canvasRef}
                            />
                        </Grid>
                    </React.Fragment>
                }
            </ React.Fragment >
        );
    }
}

export default InterventionAction;
