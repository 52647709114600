import {Divider, Grid, TextField, Typography} from "@material-ui/core";
import * as lang from "../../../Constants/strings";

const UserScoreForm = (params) => {
  const {
    userScoreHistory,
    language,
    value,
    setValue,
    editable
  } = params;

  return (
    <Grid style={{margin: "10px"}} xs={12}>
      <Divider xs={12}/>
      <Typography variant="h6" style={{ margin: "10px", width: '90%'}}>
        {userScoreHistory.USERNAME.toUpperCase()}
      </Typography>
      <TextField
        type="number"
        label={lang.POINTS[language]}
        variant="outlined"
        style={{ margin: "10px", width: '90%'}}
        value={value}
        onChange={setValue}
        disabled={!editable}
      />
    </Grid>
  )
}

export default UserScoreForm;