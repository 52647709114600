import React, { useState } from 'react';
import { Grid, TextField, ButtonGroup, Button, Grow, Typography, CircularProgress, makeStyles } from '@material-ui/core';
import { ReplyRounded, AddRounded, AttachFile } from '@material-ui/icons';

import Comment from './Comment';
import * as lang from '../../../../Constants/strings';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        marginTop: 4,
        marginLeft: -70,
    },
}));

const CommentList = (props) => {
    const { language, tiny, data, task, classes, isIntervention, addComment, sendingComment, commentText, handleCommentTextChange, showCommentBox, handleCommentBoxShow } = props;
    const loadingClasses = useStyles();

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.divider}>
                <Typography variant={tiny ? "h6" : "h5"}>{lang.HISTORY[language]}</Typography>
            </Grid>
            {data && data.length >= 0 ?
                data.map((item, index) =>
                    <Comment isIntervention={isIntervention} classes={classes} key={index} data={item} language={language}
                        downloadFile={props.downloadFile} tiny={tiny} />
                )
                :
                <p>Nici un comentariu</p>
            }
            {showCommentBox &&
                <Grid item xs={12}>
                    <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                        <TextField
                            fullWidth
                            multiline
                            className={classes.textField}
                            variant="outlined"
                            label={lang.COMMENT[language]}
                            value={commentText}
                            onChange={e => handleCommentTextChange(e.target.value)}
                        />
                    </Grow>
                </Grid>
            }
            <Grid item xs={12}>
                <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                    {showCommentBox
                        ?
                        <ButtonGroup
                            className={classes.buttonGroup}
                            color="primary"
                        >
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => { handleCommentBoxShow(false); handleCommentTextChange(""); }}
                            >
                                {lang.CANCEL_REPLY[language]}
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => props.openFileUploadModal()}
                            >
                                {lang.FILE_UPLOAD[props.language]}
                                <AttachFile className={classes.leftIcon} />
                            </Button>
                            <div style={{ position: 'relative' }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        addComment(
                                            commentText,
                                            props.uploadedFile
                                        );
                                    }}
                                >
                                    {lang.REPLY[language]}
                                    <ReplyRounded className={classes.iconRight} />
                                </Button>
                                {sendingComment && <CircularProgress size={24} className={loadingClasses.buttonProgress} />}
                            </div>
                        </ButtonGroup>
                        :
                        <Button
                            className={classes.buttonGroup}
                            color="primary"
                            variant="contained"
                            onClick={() => handleCommentBoxShow(true)}
                        >
                            <AddRounded className={classes.leftIcon} />
                            {lang.REPLY[language]}
                        </Button>
                    }
                </Grow>
            </Grid>
        </React.Fragment>
    );
}

export default CommentList;