import { makeStyles } from "@material-ui/core";

export const useBillingStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(6, 2, 6, 2),
        width: 'auto'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fabGrid: {
        margin: theme.spacing(-5, 0, 0, 0),
        position: 'absolute',
    },
    fab: {
        marginRight: theme.spacing(4),
    },
    filterButtonsContainer: {
        marginBottom: "0.5vh",
        display: "flex",
        justifyContent: "end"
    },
    filterButtons: {
        marginRight: "5px",
    }
}));
