import React, { useState } from "react";
import * as lang from "../../../Constants/strings";
import * as requestHeaders from "../../../Constants/requestHeaders";

import CustomModal from "../../../Components/Modals/CustomModal";
import {
	useMediaQuery,
	useTheme,
	Grid,
	makeStyles,
} from "@material-ui/core";

import MaintenanceDetails from "./MaintenanceDetails";
import AssignMeModal from "./Modals/AssignMeModal";
import ActionModal from "./Modals/ActionModal";
import CancelModal from "./Modals/CancelModal";
import ResetAlocationModal from "./Modals/ResetAlocationModal";
import RegisterInventory from "../../Installation/Modals/RegisterInventory";

import axios from "axios";
import CloseWithImage from "../components/CloseWithImage";
import GPSGuard from "../../../Components/GPSGuard";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		width: "100%",
	},
	avatar: {
		width: 50,
		height: 50,
	},
	title: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	comment: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	commentAuto: {
		color: "gray",
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	timestamp: {
		marginTop: theme.spacing(1.5),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	buttonGroup: {
		marginTop: theme.spacing(1),
	},
	iconRight: {
		marginLeft: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	divider: {
		margin: theme.spacing(1),
	},
	signature: {
		backgroundColor: "white",
		width: "100%",
		height: "50vh",
	},
	float: {
		margin: theme.spacing(1),
	},
}));


const MaintenanceModal = (props) => {
	const {
		open,
		close,
		language,
		userPermissions,
		isAuthenticated,
		onNotificationOpen,
		maintenanceId,
		user,
		gps
	} = props;
	const classes = useStyles();

	const [maintenance, setMaintenance] = useState({});
	const [file, setUploadedFile] = useState(null);
	const [uploadModal, setUploadModal] = useState(false);
	const [actionModal, setActionModal] = useState(false);
	const [assignToMeModal, setAssignToMeModal] = useState(false);
	const [cancelModal, setCancelModal] = useState(false);
	const [resetAlocationModal, setResetAlocationModal] = useState(false)
	const [sendingComment, setSendingComment] = useState(false);
	const [commentText, setCommentText] = React.useState("");
	const [showCommentBox, setShowCommentBox] = React.useState(false);
	const [closeMaintenanceWithImage, setCloseMaintenanceWithImage] = React.useState(false);

	const [registerInventory, setRegisterInventory] = useState(false);

	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const isSm = useMediaQuery(theme.breakpoints.down("sm"));

	// GetMaintenance
	const getMaintenance = async () => {
		try {
			const response = await axios.get("/api/get_maintenance", {
				...requestHeaders.getGetHeader(),
				params: {
					id: maintenanceId
				}
			});
			console.log(response.data);
			setMaintenance(response.data);
		} catch (error) {
			setMaintenance({})
		}
	}

	React.useEffect(() => {
		if (maintenanceId && maintenanceId !== null) getMaintenance();
	}, [maintenanceId]);

	const newComment = async (note) => {
		setSendingComment(true);
		try {
			const res = await axios.post("/api/maintenance_comment", {
				TASK_OBJID: maintenanceId,
				CHG_USER: user.id,
				NOTE: note,
				FILE: file !== null ? file : null,
				GPS: gps
			}, requestHeaders.getPostHeader);
			getMaintenance();
			setUploadedFile(false);
			setSendingComment(false);
			setCommentText("");
			showCommentBox(false);
		} catch (error) {
			setSendingComment(false);
		}
	};

	return (
		<>
			<GPSGuard />
			<CustomModal
				key={"hello"}
				fullScreen
				open={open}
				title={`#${maintenanceId} - ${lang.MAINTENANCE[language]}`}
				content={
					<Grid container spacing={2}>
						{open && (
							<>
								<MaintenanceDetails
									classes={classes}
									site={maintenance && maintenance.site ? maintenance.site : {}}
									data={maintenance}
									language={language}
									taskId={maintenanceId}
									userId={user.id}
									tiny={tiny}
									isSm={isSm}
									customer={maintenance && maintenance.customer ? maintenance.customer : {}}
									userPermissions={userPermissions}
									isAuthenticated={isAuthenticated}
									onNotificationOpen={(data, message) => onNotificationOpen(data, message)}
									refetchTaskData={() => getMaintenance()}
									newComment={(note) => newComment(note)}
									setUploadModal={(value) => setUploadModal(value)}
									setFile={(base64File) => setUploadedFile(base64File)}
									uploadModalStatus={uploadModal}
									openActionModal={() => setActionModal(true)}
									openAsignToMeModal={() => setAssignToMeModal(true)}
									openActionCancelModal={() => setCancelModal(true)}
									openResetAlocationModal={() => setResetAlocationModal(true)}
									sendingComment={sendingComment}
									commentText={commentText}
									showCommentBox={showCommentBox}
									handleCommentTextChange={value => setCommentText(value)}
									handleCommentBoxShow={value => setShowCommentBox(value)}
									openCloseMaintenanceWithImage={() => setCloseMaintenanceWithImage(true)}
									openRegisterProgress={() => setRegisterInventory(true)}
								/>
								<ActionModal
									open={actionModal}
									close={() => setActionModal(false)}
									language={language}
									maintenance={maintenance.maintenance}
									refetchTaskData={() => getMaintenance()}
									onNotificationOpen={(data, message) => onNotificationOpen(data, message)}
									userId={user.id}
									gps={gps}
								/>
								<AssignMeModal
									open={assignToMeModal}
									close={() => setAssignToMeModal(false)}
									language={language}
									maintenanceId={maintenanceId}
									userId={user.id}
									refetch={() => getMaintenance()}
								/>
								<CancelModal
									open={cancelModal}
									close={() => setCancelModal(false)}
									language={language}
									maintenanceId={maintenanceId}
									userId={user.id}
									refetch={() => getMaintenance()}
								/>
								<ResetAlocationModal
									open={resetAlocationModal}
									close={() => setResetAlocationModal(false)}
									language={language}
									maintenanceId={maintenanceId}
									userId={user.id}
									refetch={() => getMaintenance()}
									gps={gps}
								/>
								<CloseWithImage
									open={closeMaintenanceWithImage}
									close={() => setCloseMaintenanceWithImage(false)}
									maintenanceId={maintenanceId}
									language={language}
									refetch={() => getMaintenance()}
									userId={user.id}
								/>
							</>
						)}
					</Grid>
				}
				close={close}
				language={language}
				execute={() => console.log("here")}
				noButtons
				onlyBack
			/>
			<RegisterInventory
				open={registerInventory}
				onClose={async () => {
					setRegisterInventory(false);
					await getMaintenance();
				}}
				language={language}
				taskId={maintenanceId}
				contractorId={maintenance && maintenance.maintenance ? maintenance.maintenance.CONTRACTOR_OBJID : null}
				userId={user.id}
				type={"MAINTENANCE"}
			/>
		</>
	)
};

export default MaintenanceModal;