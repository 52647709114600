import React from "react";
import {
	makeStyles,
	Grid,
	Button,
	Fab,
	MenuItem,
	Menu,
	Typography,
	Paper,
	Grow,
} from "@material-ui/core";
import GroupButtonSet from "../../../Components/Misc/GroupButtonSet";
import {
	AddRounded,
	EditRounded,
	VisibilityRounded,
	CheckRounded,
	VerifiedUserRounded,
	DescriptionRounded,
	LocalShippingRounded,
	AssignmentLateRounded,
	AssignmentIndRounded,
	PauseRounded,
	ForwardRounded,
	DoneRounded,
	CancelRounded,
	RoomRounded,
	SendRounded,
} from "@material-ui/icons";
import * as lang from "../../../Constants/strings";
import CustomDrawer from "../../../Components/Modals/CustomDrawer";
import Skeleton from "@material-ui/lab/Skeleton";
import { checkPermissions } from "../../../Functions/permissionHide";

const useStyles = makeStyles((theme) => ({
	leftIcon: {
		marginRight: theme.spacing(2),
	},
	rightIcon: {
		marginLeft: theme.spacing(2),
	},
	float: {
		position: "fixed",
		bottom: theme.spacing(3),
		right: theme.spacing(3),
		zIndex: 99,
	},
	menuIcon: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(-0.5),
	},
	paperClear: {
		padding: theme.spacing(2),
		height: "100%",
	},
}));

export default function InterventionButtons(props) {
	const classes = useStyles();
	const { language, tiny, task } = props;

	return (
		<React.Fragment>
			{task.loading ? (
				<SkeletonInterventionButtons classes={classes} />
			) : (
				<Grid
					item
					container
					direction="row"
					xs={12}
					lg={12}
					style={{ paddingRight: "0px" }}
				>
					{task && task.data && task.data.task && (
						<GroupButtonSet
							tiny={tiny}
							language={language}
							style={{
								// fullWidth: true,
								size: "large",
								color: "default",
								variant: "contained",
								disabled: false,
							}}
							list={[
								{
									primary:
										task.userId ===
										task.data.task.OWNER_CURR,
									text: lang.NEXT_STATUS_INTER(
										task.data.task.STATUS
									)[language],
									style: {
										// variant: 'contained',
										disabled:
											["CLOSED", "CANCELLED"].indexOf(
												task.data.task.STATUS
											) !== -1,
									},
									icon: <ForwardRounded />,
									render:
										(task.userId ===
											// OWNER
											task.data.task.OWNER_CURR &&
											checkPermissions(
												// Are drepturi basic de actiuni pe interventie
												props.userPermissions,
												{
													parent: "INTERVENTIONS",
													name:
														"ACTION_INTERVENTION_BASIC",
												}
											) &&
											// Nu este in stadiul de VALIDATING
											["VALIDATING"].indexOf(
												task.data.task.STATUS
											) === -1) ||
										(checkPermissions(
											// Are drepturi dispatcher de actiuni pe interventie
											props.userPermissions,
											{
												parent: "INTERVENTIONS",
												name:
													"ACTION_INTERVENTION_DISPATCHER",
											}
										) &&
											// Este in stadiul de VALIDATING
											["VALIDATING"].indexOf(
												task.data.task.STATUS
											) !== -1),
									action: () =>
										props.openAction(
											task.data.task.STATUS_ID,
											false
										),
								},
								{
									primary:
										task.userId !==
										task.data.task.OWNER_CURR,
									text: lang.ASSIGN_TO_ME[language],
									style: {
										// variant: 'contained',
										disabled:
											["OPEN"].indexOf(
												task.data.task.STATUS
											) === -1,
									},
									icon: <AssignmentIndRounded />,
									leftIcon: (
										<AssignmentIndRounded
											fontSize="small"
											className={classes.leftIcon}
										/>
									),
									render:
										task.userId !==
											task.data.task.OWNER_CURR &&
										checkPermissions(
											props.userPermissions,
											{
												parent: "INTERVENTIONS",
												name:
													"ACTION_INTERVENTION_BASIC",
											}
										) /* si permisiune de interventie */,
									action: () =>
										props.openAction(
											"ASSIGN",
											task.data.task.STATUS_ID
										),
								},
								{
									text: lang.CHECK_IN[language],
									style: {
										// variant: 'contained',
										disabled:
											task.userId !==
											task.data.task.OWNER_CURR,
									},
									icon: <RoomRounded />,
									leftIcon: (
										<RoomRounded
											fontSize="small"
											className={classes.leftIcon}
										/>
									),
									render:
										["STARTED"].indexOf(
											task.data.task.STATUS
										) !== -1 &&
										task.data.task.TYPE_ID === 1 &&
										checkPermissions(
											props.userPermissions,
											{
												parent: "INTERVENTIONS",
												name:
													"ACTION_INTERVENTION_BASIC",
											}
										) /* si permisiune de interventie */,
									action: () =>
										props.openAction(
											"CHECK_IN",
											task.data.task.STATUS_ID
										),
								},
								{
									text: lang.WRONG_PV[language],
									style: {
										// variant: 'contained',
										color: "secondary",
										disabled:
											["VALIDATING"].indexOf(
												task.data.task.STATUS
											) === -1,
									},
									render:
										task.data.task.STATUS ===
											"VALIDATING" &&
										checkPermissions(
											// Are drepturi dispatcher de actiuni pe interventie
											props.userPermissions,
											{
												parent: "INTERVENTIONS",
												name:
													"ACTION_INTERVENTION_DISPATCHER",
											}
										),
									action: () =>
										props.openAction("WRONG_PV", 10),
								},
								{
									text: lang.LOAD_IMAGE[language],
									style: {
										color: "secondary",
									},
									render:
										task.data.task.STATUS !== "VALIDATING" &&
										["CLOSED", "CANCELLED"].indexOf(
											task.data.task.STATUS
										) === -1 &&
										checkPermissions(
										// Are drepturi dispatcher de actiuni pe interventie
										props.userPermissions,
										{
											parent: "INTERVENTIONS",
											name:
												"ACTION_INTERVENTION_DISPATCHER",
										}
									),
									action: () => 	props.openAction(
										"VALIDATING",
										9
									),
								},
								{
									text: lang.CANCEL[language],
									style: {
										color: "secondary",
									},
									icon: <CancelRounded />,
									rightIcon: (
										<CancelRounded
											fontSize="small"
											className={classes.rightIcon}
										/>
									),
									render:
										["CANCELLED", "CLOSED"].indexOf(
											task.data.task.STATUS
										) === -1 &&
										checkPermissions(
											// Are drepturi dispatcher de actiuni pe interventie
											props.userPermissions,
											{
												parent: "INTERVENTIONS",
												name:
													"ACTION_INTERVENTION_DISPATCHER",
											}
										),
									action: () =>
										props.openAction("CANCELLED", 7),
								},
							]}
						/>
					)}
				</Grid>
			)}
		</React.Fragment>
	);
}
export function SkeletonInterventionButtons(props) {
	const { classes } = props;
	return (
		<Grid item xs={12} md={8} style={{ paddingRight: "0px" }}>
			<Skeleton component={Paper} className={classes.paperClear}>
				<Grid
					container
					justify="center"
					alignItems="center"
					direction="column"
				>
					{/* <Skeleton width="15%" className={classes.title} /> */}
				</Grid>
			</Skeleton>
		</Grid>
	);
}
