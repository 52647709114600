import {
    SignInUser,
    // SignInFailure,
    OpenConfirmWindow,
    LogoutAndRedirect
} from './authActions';
import { onNotificationOpen } from './configActions';
import * as lang from '../Constants/strings';

export const SP_DATA_REQUEST = "SP_DATA_REQUEST";
export const SP_DATA_RECIEVE = "SP_DATA_RECIEVE";


export function onServerPaginatedDataRequest() {
    return {
        type: SP_DATA_REQUEST
    }
};

export function onServerPaginatedDataRecieve(data) {
    return {
        type: SP_DATA_RECIEVE,
        payload: data
    }
};

export function getAccountingCustomersSP(filters) {
    return (dispatch) => {
        dispatch(onServerPaginatedDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        let stringFilters = `orderBy=${filters.orderBy + 1}&orderDirection=${filters.orderDirection}&pageNumber=${filters.pageNumber}&pageRows=${filters.pageRows}`;
        for (let key in filters)
            if (['orderBy', 'orderDirection', 'pageNumber', 'pageRows'].indexOf(key) === -1)
                stringFilters += `&${key}=${filters[key]}`;

        return fetch(`/api/accounting_customers?${stringFilters}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                dispatch(onServerPaginatedDataRecieve({ ...response.data, ...filters }));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onServerPaginatedDataRecieve({ results: [], count: 0 }));
                console.log('caught it!', err);
            })
    }
}

export function invoiceCustomer(OBJID, filters, DISCOUNT) {
    return (dispatch) => {
        dispatch(onServerPaginatedDataRequest());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/invoice_extension?OBJID=${OBJID}&YEAR=${filters.YEAR}&MONTH=${filters.MONTH}&DISCOUNT=${DISCOUNT}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.blob();
                }
            })
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = '';
                link.click();
            })
            .then(() => {
                dispatch(getAccountingCustomersSP(filters));
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => console.log(err))
    }
}