// TASK TYPE
export const ISU = {
    "en": "ISU",
    "ro": "ISU",
};
export const SECURITY = {
    "en": "SECURITY",
    "ro": "SECURITATE",
};

// TASK TABLE COLUMNS
export const REASON = {
    "en": "Reason",
    "ro": "Motiv",
};
export const LAST_UPDATE = {
    "en": "Last Update",
    "ro": "Ultima actualizare",
};
export const CREATE_TIME = {
    "en": "Create Time",
    "ro": "Dată creare",
};
export const TASK_TABLE = {
    "en": "Tasks",
    "ro": "Tickete",
};
export const CONTRACT_TABLE = {
    "en": "Contracts",
    "ro": "Contracte",
};
export const TASK = {
    "en": "Task",
    "ro": "Ticket",
}
export const PRIORITY = {
    "en": "Priority",
    "ro": "Prioritate"
}
// TASK STATUS
export const NEW = {
    "en": "New",
    "ro": "Nou",
};
export const OPEN = {
    "en": "Open",
    "ro": "Deschis",
};
export const ASSIGNED = {
    "en": "Assigned",
    "ro": "Alocat",
};
export const ON_HOLD = {
    "en": "On Hold",
    "ro": "În Așteptare",
};
export const ON_DRIVE = {
    "en": "On Drive",
    "ro": "Am plecat",
};
export const STARTED = {
    "en": "In Progress",
    "ro": "Am ajuns",
};
export const CANCELLED = {
    "en": "Cancelled",
    "ro": "Anulat",
};
export const CLOSED = {
    "en": "Closed",
    "ro": "Închis",
};
export const VALIDATING = {
    "en": "Waiting for validation",
    "ro": "Așteaptă validare",
};
export const WRONG_PV = {
    "en": "Wrong PV",
    "ro": "PV Eronat",
};
export const CHECK_IN = {
    "en": "Check In",
    "ro": "Check In",
};

// TASK CONTENT

export const TASK_TITLE = {
    "en": "Task",
    "roz": "Ticket",
}
export const COMMENTS = {
    "en": "Comments",
    "ro": "Comentarii",
}
export const COMMENT = {
    "en": "Comment",
    "ro": "Comentariu",
}
export const CANCEL_REPLY = {
    "en": "Cancel",
    "ro": "Anulează",
}
export const REPLY = {
    "en": "Reply",
    "ro": "Adaugă",
}
export const OBSERVATION = {
    "en": "Observation",
    "ro": "Observații",
}
export const CONFIRM_DOC_NO = {
    "en": "Confirm document number",
    "ro": "Confirmă nr. fișă",
}
export const PREJUDICE = {
    "en": "Prejudice",
    "ro": "Pagube",
}
export const SERVICE_NO = {
    "en": "Service number",
    "ro": "Număr service",
}
export const WARRANTY = {
    "en": "Warranty",
    "ro": "Garanție",
}
export const JOURNAL_DATA = {
    "en": "Journal data",
    "ro": "Notare jurnal service",
}
export const TRAINED_PERSONS = {
    "en": "Trained persons",
    "ro": "Persoane instruite",
}
export const SYSTEM_PROJECT = {
    "en": "System project handover",
    "ro": "Predare proiect sistem",
}
export const SYSTEM_JOURNAL = {
    "en": "System journal handover",
    "ro": "Predare jurnal sistem",
}
export const NUMBER = {
    "en": "Number",
    "ro": "Număr",
}
export const PERSON = {
    "en": "Person",
    "ro": "Persoană",
}
export const SIGN_ACTION = {
    "en": "Sign",
    "ro": "Semnătură beneficiar",
}
export const SIGN_DONE = {
    "en": "Signed",
    "ro": "Semnat",
}

// TASK COMMENT

export const SYSTEM_COMMENT_INTERVENTION = (CHANGE, FROM, TO) => {
    switch (CHANGE) {
        case "PROGRESS": {
            if (FROM === "STARTED" && TO === "STARTED")
                return {
                    "en": "[CHECK IN]",
                    "ro": "[CHECK IN]",
                }
            switch (TO) {
                case "OPEN":
                    if (FROM === "NEW")
                        return {
                            "en": `[NEW] A new intervention has been created`,
                            "ro": `[NOU] A fost creată intervenția`,
                        }
                    else
                        return {
                            "en": `[ON HOLD] -> [OPEN] Intervention resumed`,
                            "ro": `[ÎN AȘTEPTARE] - [DESCHIS] Intervenție reluată`,
                        }
                case "CLOSED":
                    return {
                        "en": `[CLOSED] The intervention has been closed`,
                        "ro": `[ÎNCHIS] Intervenția a fost rezolvată`,
                    }
                default:
                    return {
                        "en": `[${eval(FROM).en.toUpperCase()}] - [${eval(TO).en.toUpperCase()}]`,
                        "ro": `[${eval(FROM).ro.toUpperCase()}] - [${eval(TO).ro.toUpperCase()}]`,
                    }
            }
        }
        case "ASSIGN":
            return {
                "en": `[ASSIGNED] The intervention has been reassigned: `,
                "ro": `[ALOCAT] Responsabilul intervenției a fost schimbat: `,
            }
        default: return "";
    }
}

export const SYSTEM_COMMENT_MAINTENANCE = (CHANGE, FROM, TO) => {
    switch (CHANGE) {
        case "PROGRESS": {
            if (FROM === "STARTED" && TO === "STARTED")
                return {
                    "en": "[CHECK IN]",
                    "ro": "[CHECK IN]",
                }
            switch (TO) {
                case "OPEN":
                    if (FROM === "NEW")
                        return {
                            "en": `[NEW] A new maintenance has been created`,
                            "ro": `[NOU] A fost creată mentenața`,
                        }
                    else
                        return {
                            "en": `[ON HOLD] -> [OPEN] Mentenance resumed`,
                            "ro": `[ÎN AȘTEPTARE] - [DESCHIS] Mentenanță reluată`,
                        }
                case "CLOSED":
                    return {
                        "en": `[CLOSED] The mentenance has been closed`,
                        "ro": `[ÎNCHIS] Mentenanța a fost rezolvată`,
                    }
                default:
                    return {
                        "en": `[${eval(FROM).en.toUpperCase()}] - [${eval(TO).en.toUpperCase()}]`,
                        "ro": `[${eval(FROM).ro.toUpperCase()}] - [${eval(TO).ro.toUpperCase()}]`,
                    }
            }
        }
        case "ASSIGN":
            return {
                "en": `[ASSIGNED] The mentenance has been reassigned: `,
                "ro": `[ALOCAT] Responsabilul mentenanței a fost schimbat: `,
            }
        default: return "";
    }
}

export const SYSTEM_COMMENT_INSTALLATION = (CHANGE, FROM, TO) => {
    switch (CHANGE) {
        case "PROGRESS": {
            if (FROM === "STARTED" && TO === "STARTED")
                return {
                    "en": "[CHECK IN]",
                    "ro": "[CHECK IN]",
                }
            switch (TO) {
                case "OPEN":
                    if (FROM === "NEW")
                        return {
                            "en": `[NEW] A new installation has been created`,
                            "ro": `[NOU] A fost creată instalare`,
                        }
                    else
                        return {
                            "en": `[ON HOLD] -> [OPEN] Installation resumed`,
                            "ro": `[ÎN AȘTEPTARE] - [DESCHIS] Instalare reluată`,
                        }
                case "CLOSED":
                    return {
                        "en": `[CLOSED] The installation has been closed`,
                        "ro": `[ÎNCHIS] Instalarea a fost rezolvată`,
                    }
                default:
                    return {
                        "en": `[${eval(FROM).en.toUpperCase()}] - [${eval(TO).en.toUpperCase()}]`,
                        "ro": `[${eval(FROM).ro.toUpperCase()}] - [${eval(TO).ro.toUpperCase()}]`,
                    }
            }
        }
        case "ASSIGN":
            return {
                "en": `[ASSIGNED] The mentenance has been reassigned: `,
                "ro": `[ALOCAT] Responsabilul mentenanței a fost schimbat: `,
            }
        default: return "";
    }
}

export const SYSTEM_COMMENT = (CHANGE, FROM, TO) => {
    switch (CHANGE) {
        case "PROGRESS": {
            switch (TO) {
                case "OPEN":
                    if (FROM === "NEW")
                        return {
                            "en": `[NEW] A new task has been created`,
                            "ro": `[NOU] A fost creat Ticketul`,
                        }
                    else
                        return {
                            "en": `[ON HOLD] -> [OPEN] Task resumed`,
                            "ro": `[ÎN AȘTEPTARE] - [DESCHIS] Ticket reluat`,
                        }
                case "CLOSED":
                    return {
                        "en": `[CLOSED] The task has been closed`,
                        "ro": `[ÎNCHIS] Ticketul a fost rezolvat`,
                    }
                default:
                    return {
                        "en": `[${eval(FROM).en.toUpperCase()}] - [${eval(TO).en.toUpperCase()}]`,
                        "ro": `[${eval(FROM).ro.toUpperCase()}] - [${eval(TO).ro.toUpperCase()}]`,
                    }
            }
        }
        case "ASSIGN":
            return {
                "en": `[ASSIGNED] The task has been reassigned: `,
                "ro": `[ALOCAT] Responsabilul Ticketului a fost schimbat: `,
            }
        default: return "";
    }
}

// TASK INFO

export const OWNER = {
    "en": "Owner",
    "ro": "Deschis de"
}
export const ASSIGNEE = {
    "en": "Assignee",
    "ro": "Responsabil"
}
export const DATE = {
    "en": "Date",
    "ro": "Dată"
}
export const DATE_LIMIT = {
    "en": "Task deadline",
    "ro": "Timp limită intervenție"
}
export const POSITION = {
    "en": "Position",
    "ro": "Poziție"
}
export const NUMBER_TEAM_MEMBERS = {
    "en": "Number of team members",
    "ro": "Număr membri echipă"
}

// TASK ACTIONS

export const ASSIGN_TO_ME = {
    "en": "Assign to me",
    "ro": "Alocă mie"
}
export const HOLD_TASK = {
    "en": "On Hold",
    "ro": "În Așteptare"
}
export const CLOSE = {
    "en": "Close",
    "ro": "Finalizează"
}
export const CANCEL = {
    "en": "Cancel",
    "ro": "Anulează"
}
export const CONFIRM_ACTION = {
    "en": "Are you sure you want to do this?",
    "ro": "Ești sigur că vrei să execuți acțiunea?"
}
export const CONFIRM_ACTION_CONTENT = {
    "en": "You can not undo this change.",
    "ro": "Acțiunea nu va putea fi anulată ulterior."
}
export const CONFIRM_ASSIGN = {
    "en": "Assign to myself",
    "ro": "Confirmați preluare Ticketului"
}
export const LOAD_IMAGE = {
    "en": "Load image",
    "ro": "Încarcă imagine PV"
}

export const NEXT_STATUS = (STATUS) => {
    switch (STATUS) {
        case "NEW":
            return {
                "en": "Open task",
                "ro": "Deschide",
            }
        case "OPEN":
            return {
                "en": "Start",
                "ro": "Pornește",
            }
        case "ASSIGNED":
            return {
                "en": "Start",
                "ro": "Pornește",
            }
        case "ON_HOLD":
            return {
                "en": "Resume",
                "ro": "Continuă",
            }
        case "ON_DRIVE":
            return {
                "en": "Arrived",
                "ro": "Am ajuns",
            }
        case "STARTED":
            return {
                "en": "Done",
                "ro": "Rezolvat",
            }
        case "CANCELLED":
            return {
                "en": "Cancelled",
                "ro": "Anulat",
            }
        case "CLOSED":
            return {
                "en": "Closed",
                "ro": "Închis",
            }
        default:
            return {
                "en": "",
                "ro": "",
            }
    }
};

export const NEXT_STATUS_INTER = (STATUS) => {
    switch (STATUS) {
        case "NEW":
            return {
                "en": "Open task",
                "ro": "Deschide",
            }
        case "OPEN":
            return {
                "en": "Start",
                "ro": "Pornește",
            }
        case "ASSIGNED":
            return {
                "en": "Start",
                "ro": "Pornește",
            }
        case "ON_DRIVE":
            return {
                "en": "Arrived",
                "ro": "Am ajuns",
            }
        case "STARTED":
            return {
                "en": "Send PV",
                "ro": "Trimite PV",
            }
        case "WRONG_PV":
            return {
                "en": "Send PV",
                "ro": "Trimite PV",
            }
        case "VALIDATING":
            return {
                "en": "Done",
                "ro": "Rezolvat",
            }
        case "CLOSED":
            return {
                "en": "Closed",
                "ro": "Închis",
            }
        case "CANCELLED":
            return {
                "en": "Cancelled",
                "ro": "Anulat",
            }
        case "SENT":
            return {
                "en": "Sent",
                "ro": "Trimis"
            }
        default:
            return {
                "en": "",
                "ro": "",
            }
    }
};
export const SEND = {
    "en": "Send",
    "ro": "Trimite"
};
export const NORMAL = {
    "en": "Normal",
    "ro": "Normală"
};

export const SELECT_PRIORITY = {
    "en": "Select priority",
    "ro": "Selecteză prioritatea"
};

export const SET_PRIORITY = {
    "en": "Set priority",
    "ro": "Seteză prioritatea"
};

export const URGENT = {
    "en": "Urgent",
    "ro": "Urgentă"
};
export const TASK_DETAILS = {
    "en": "Task details",
    "ro": "Detalii Ticket"
};
export const INTERVENTION_DETAILS = {
    "en": "Intervention details",
    "ro": "Detalii intervenție"
};
export const TASK_HISTORY = {
    "en": "Task History",
    "ro": "Istoric Tickete"
};
export const ACTIVE_TASK = {
    "en": "Active Task",
    "ro": "Tickete active"
};
export const DATE_SELECT = {
    "en": "Select Date",
    "ro": "Alege intervalul"
};
export const AREA_SELECT = {
    "en": "Select area",
    "ro": "Alege zona"
};
export const START_INTERVAL_DATE = {
    "en": "Start intervval date",
    "ro": "Dată de începere interval "
};
export const LAST_INTERVAL_DATE = {
    "en": "Last interval date",
    "ro": "Dată încheiere interval"
};
export const SELECT_INTERVAL_DATE = {
    "en": "Select interval date",
    "ro": "Selectează intervalul dorit"
};
export const CANCEL_ALOCATION = {
    "en": "Cancel alocation",
    "ro": "Anulează alocare"
};
export const UPDATE_REASON = {
    "en": "Update reason",
    "ro": "Actualizează motiv"
};
export const TASK_REASON = {
    en: "Task reason",
    ro: "Motiv Ticket"
};
export const PARTIAL_PAYED_SUM = {
    en: "Partial payed sum",
    ro: "Valoare chitanță [RON]"
};
export const GET_TASKS = {
    en: "Get tasks",
    ro: "Tickete"
};
export const USER_THAT_MADE_VALIDATION_BILL_ACTION = {
    en: "The user that made the bill validation action",
    ro: "Utilizatorul ce a efectuat acțiunea de validare a facturi"
};
export const USER_THAT_MADE_VALIDATION_CASHING_ACTION = {
    en: "The user that made the cashing validation action",
    ro: "Utilizatourl ce a efectuat acțiunea de validare a încasării"
};