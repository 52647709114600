import * as actionTypes from '../actions/nomenclatureActions';
import { contractOptions } from '../Constants/misc';

function decodeOpt(param) {
    let results = "";
    let aux = param.split('_');
    for (let i = 0; i < aux.length; i++)
        if (parseInt(aux[i], 10) === 1)
            results += contractOptions[i].NAME + ", ";
    return results;
}

const initialState = {
    isFetchingExecutorList: false,
    isSubmittingExecutor: false,
    executorList: [],

    isFetchingRoleList: false,
    isSubmittingRole: false,
    roleList: [],

    isFetchingAreaList: false,
    isSubmittingArea: false,
    areaList: [],

    isFetchingDepartmentList: false,
    isSubmittingDepartment: false,
    departmentList: [],
    areaTypeList: [],

    isFetchingSiteTypeList: false,
    isSubmittingSiteType: false,
    siteTypeList: [],

    isFetchingSiteAreaList: false,
    isSubmittingSiteArea: false,
    siteAreaList: [],
};

export default function nomenclatureReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.EXECUTOR_LIST_REQUEST: {
            return {
                ...state,
                isFetchingExecutorList: true,
            }
        }
        case actionTypes.EXECUTOR_LIST_RECIEVE: {
            let _executorList = [];
            action.payload.data.map(item => _executorList.push({ ...item, IS_DEFAULT: item.IS_DEFAULT === 1 }))
            return {
                ...state,
                isFetchingExecutorList: false,
                executorList: _executorList,
            }
        }
        case actionTypes.UPDATE_EXECUTOR_BEGIN:
        case actionTypes.ADD_EXECUTOR_BEGIN: {
            return {
                ...state,
                isSubmittingExecutor: true,
            }
        }
        case actionTypes.ADD_EXECUTOR_SUCCESS: {
            return {
                ...state,
                isSubmittingExecutor: false,
                executorList: [...state.executorList, {
                    ...action.payload.data,
                    // OPT: decodeOpt(action.payload.data.OPT)
                }]
            }
        }
        case actionTypes.UPDATE_EXECUTOR_SUCCESS:
        case actionTypes.UPDATE_EXECUTOR_FAILED:
        case actionTypes.ADD_EXECUTOR_FAILED: {
            return {
                ...state,
                isSubmittingExecutor: false,
            }
        }
        case actionTypes.ROLE_LIST_REQUEST: {
            return {
                ...state,
                isFetchingRoleList: true,
            }
        }
        case actionTypes.ROLE_LIST_RECIEVE: {
            return {
                ...state,
                isFetchingRoleList: false,
                roleList: action.payload.data,
            }
        }
        case actionTypes.AREA_LIST_REQUEST: {
            return {
                ...state,
                isFetchingAreaList: true,
            }
        }
        case actionTypes.AREA_LIST_RECIEVE: {
            return {
                ...state,
                isFetchingAreaList: false,
                areaList: action.payload.data,
            }
        }
        case actionTypes.ADD_AREA_BEGIN: {
            return {
                ...state,
                isSubmittingArea: true,
            }
        }
        case actionTypes.ADD_AREA_FAILED: {
            return {
                ...state,
                isSubmittingArea: false,
            }
        }
        case actionTypes.ADD_AREA_SUCCESS: {
            return {
                ...state,
                isSubmittingArea: false,
                areaList: [...state.areaList, {
                    ...action.payload.data
                }]
            }
        }
        case actionTypes.DEPARTMENT_LIST_REQUEST: {
            return {
                ...state,
                isFetchingDepartmentList: true,
            }
        }
        case actionTypes.DEPARTMENT_LIST_RECIEVE: {
            return {
                ...state,
                isFetchingDepartmentList: false,
                departmentList: action.payload.data,
            }
        }
        case actionTypes.ADD_DEPARTMENT_BEGIN: {
            return {
                ...state,
                isSubmittingDepartment: true,
            }
        }
        case actionTypes.ADD_DEPARTMENT_SUCCESS:
        case actionTypes.ADD_DEPARTMENT_FAILED: {
            return {
                ...state,
                isSubmittingDepartment: false,
            }
        }
        // case actionTypes.AREA_TYPE_LIST_RECIVE: {
        //     return {
        //         ...state,
        //         areaTypeList: action.payload.data
        //     }
        // }
        case actionTypes.SITE_TYPE_LIST_REQUEST: {
            return {
                ...state,
                isFetchingSiteTypeList: true,
            }
        }
        case actionTypes.SITE_TYPE_LIST_RECIEVE: {
            return {
                ...state,
                isFetchingSiteTypeList: false,
                siteTypeList: action.payload.data,
            }
        }
        case actionTypes.ADD_SITE_TYPE_BEGIN: {
            return {
                ...state,
                isSubmittingSiteType: true,
            }
        }
        case actionTypes.ADD_SITE_TYPE_SUCCESS:
        case actionTypes.ADD_SITE_TYPE_FAILED: {
            return {
                ...state,
                isSubmittingSiteType: false,
            }
        }
        case actionTypes.SITE_AREA_LIST_REQUEST: {
            return {
                ...state,
                isFetchingSiteAreaList: true,
            }
        }
        case actionTypes.SITE_AREA_LIST_RECIEVE: {
            return {
                ...state,
                isFetchingSiteAreaList: false,
                siteAreaList: action.payload.data,
            }
        }
        case actionTypes.ADD_SITE_AREA_BEGIN: {
            return {
                ...state,
                isSubmittingSiteArea: true,
            }
        }
        case actionTypes.ADD_SITE_AREA_SUCCESS:
        case actionTypes.ADD_SITE_AREA_FAILED: {
            return {
                ...state,
                isSubmittingSiteArea: false,
            }
        }
        default:
            return state;
    }
}