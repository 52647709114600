import React, { Component } from 'react';
import moment from 'moment';
import axios from "axios";

import { makeStyles, CssBaseline, Grid } from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';

import * as lang from '../../Constants/strings';
import * as requestHeaders from "../../Constants/requestHeaders";
import SortBar from './Componets/SortBar';
import BillHistoryTable from "./Componets/BillHistoryTable";
import CashingValidationModal from "../Tasks/Modals/CashingValidationModal";

import ProjectCashingValidationModal from '../Projects/CashingValidationModal';
import RiscEvaluationCashingValidationModal from '../RiscEvaluation/CashingValidationModal';

import { getBillPayStatus } from "../../Functions/getIcon";

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        padding: theme.spacing(1),
        height: '100%',
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(10)
        }
    },
    title: {
        margin: theme.spacing(4)
    },
    table: {
        margin: theme.spacing(0, 1, 0, 1)
    },
    marginTop: {
        marginTop: theme.spacing(10),
    }
}));

function BillingHistoryWidget(props) {
    const { language } = props;
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [billModal, setBillModal] = React.useState(false);
    const [projectBillModal, setProjectBillModal] = React.useState(false);
    const [evaluationBillModal, setEvaluationBillModal] = React.useState(false);

    const [billId, setBillId] = React.useState();

    const getData = async (filters) => {
        let response = await axios.get("/api/getBillingHistory", {
            ...requestHeaders.getGetHeader(),
            params: {
                ...filters
            }
        });

        if (response.data.length > 0) {
            let billList = response.data.map(bill => {
                bill.TYPE = lang[bill.TYPE][language];
                bill.BILLING_TYPE = bill.BILLING_TYPE !== null ? lang[bill.BILLING_TYPE][language] : bill.BILLING_TYPE;
                bill.PAYMENT_TYPE = bill.PAYMENT_TYPE !== null ? lang[bill.PAYMENT_TYPE][language] : bill.PAYMENT_TYPE;
                bill.CREATE_IN = bill.CREATE_IN !== null ? moment(bill.CREATE_IN).format('YYYY-MM-DD HH:mm:ss') : '';
                bill.CLOSE_IN = bill.CLOSE_IN !== null ? moment(bill.CLOSE_IN).format('YYYY-MM-DD HH:mm:ss') : '';
                bill.PAY = bill.FK2BILL_TYPE !== 1 ? (bill.IS_PARTIAL_PAYED === 1 ? "Tranșe multiple" : "O singură tranșă") : "";
                bill.COMPLETED_PAYED = getBillPayStatus(bill);
                bill.BILL_DATE = bill.BILL_DATE !== null ? moment(bill.BILL_DATE).format('YYYY-MM-DD') : "";
                bill.RECIPE_DATE = bill.RECIPE_DATE !== null ? moment(bill.RECIPE_DATE).format('YYYY-MM-DD') : "";
                return bill
            })
            setData(billList);
        } else {
            setData([]);
        }
    };

    const getBillById = async (id, type) => {
        setBillId(id);
        switch (type) {
            case "Instalare":
            case "Ticket":
                setBillModal(true);
                break;
            case "Proiect":
                setProjectBillModal(true);
                break;
            case "Evaluare de risc":
                setEvaluationBillModal(true);
                break;
            default: break;
        }
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <CashingValidationModal
                open={billModal}
                reopen={() => setBillModal(true)}
                language={language}
                billId={billId}
                lockAllFields={true}
                lockFields={true}
                refeatchTaskData={() => { }}
                close={() => setBillModal(false)}
            />
            <ProjectCashingValidationModal
                open={projectBillModal}
                reopen={() => setProjectBillModal(true)}
                language={language}
                billId={billId}
                lockAllFields={true}
                lockFields={true}
                refeatchTaskData={() => { }}
                close={() => setProjectBillModal(false)}
            />
            <RiscEvaluationCashingValidationModal
                open={evaluationBillModal}
                reopen={() => setProjectBillModal(true)}
                language={language}
                billId={billId}
                lockAllFields={true}
                lockFields={true}
                refeatchTaskData={() => { }}
                close={() => setEvaluationBillModal(false)}
            />
            <div className={classes.marginTop} />
            <SortBar
                language={language}
                hideType={false}
                filterData={(filters) => {
                    getData(filters);
                }} />
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.table}>
                    <BillHistoryTable
                        data={data}
                        language={language}
                        openRow={(id, type) => getBillById(id, type)}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

class BillingHistory extends Component {

    componentDidMount() {
        document.title = "RPG - " + lang.TASK_BILL_HISTORY[this.props.language];
        this.props.actions.checkSession(false);
    };

    render() {
        return (
            <React.Fragment>
                <BillingHistoryWidget
                    language={this.props.language}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        userAuth: state.auth.userData,
        newCustomerID: state.cust.newCustomerID,
        siteData: state.cust.siteData,
        isFetchingTaskData: state.task.isFetchingTaskData,
        taskData: state.task.taskData,
        lastID: state.site.lastID,
        rowsPerPage: state.config.rowsPerPage
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
