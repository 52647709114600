import React, {useState} from "react";
import {
    Grid,
    TextField,
} from "@material-ui/core";

import axios from "axios";
import CustomModal from "../../../../Components/Modals/CustomModal";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import * as lang from "../../../../Constants/strings";

const Cancel = (props) => {
    const {
        open,
        close,
        language,
        riscEvaluationId,
        userId,
        refetch
    } = props;

    const [note, setNote] = useState("");

    const cancel = async () => {
        try {
            await axios.post("/api/cancel_risc_evaluation", {
                id: riscEvaluationId,
                userId: userId,
                note: note
            },{
                ...requestHeaders.getPostHeader(),
            });
            await refetch();
            await close();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <CustomModal 
            open={open}
            close={close}
            fullWidth
            title={`Anulare evaluare de risc`}
            content ={
                <Grid container spacing={2}>
                    <TextField 
                        label={lang.OBSERVATION[language]}
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            }
            execute={() => cancel()}
            validate={true}
            language={language}
        />
    )
};

export default Cancel;
