import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as lang from '../../../../Constants/strings';
import SiteTypeEditForm from './SiteTypeEditForm';

export default function EditModal(props) {
    const { selectedIndex, language } = props;
    const [editData, setEditData] = React.useState({ OBJID: "", SITE_TYPE: "" });
    const [active, setActive] = React.useState(false);

    function getItem(id) {
        fetch(`/api/get_site_type?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setEditData({ ...response })
                setActive(response.ACTIVE === 1 ? true : false)
            })
            .catch(err => console.log(err))
    }

    React.useEffect(() => {
        if (selectedIndex)
            getItem(selectedIndex);
        // setEditData({ SITE_TYPE: "" });
    }, [selectedIndex])

    return (
        <CustomModal
            fullWidth
            title={lang.EDIT[language]}
            open={selectedIndex !== null}
            content={
                <SiteTypeEditForm
                    toggleExecutor={() => props.toggleExecutor(editData)}
                    active={active}
                    editData={editData}
                    id={selectedIndex}
                    language={language}
                    updateField={(e, lenght) => { setEditData({ ...editData, [e.target.name]: e.target.value.toString().slice(0, lenght) }) }}
                    close={props.close}
                />
            }
            close={props.close}
            execute={() => {
                props.updateExecutor({
                    SITE_TYPE: editData.SITE_TYPE,
                    OBJID: editData.OBJID,
                })
                props.close();
            }}
            language={language}
            validate={true}
        />
    );
}