import * as actionTypes from '../actions/actions';
import { getVerifyIcon } from '../Functions/getIcon';


const initialState = {
    isFetchingSiteList: false,
    siteList: [],
    lastID: null,
}

export default function siteReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SITE_LIST_REQUEST:
            return {
                ...state,
                isFetchingSiteList: true
            }
        case actionTypes.SITE_LISTE_RECIVE:
            return {
                ...state,
                isFetchingSiteList: false,
                siteList: getVerifyIcon(action.payload.data)
            }
        case actionTypes.NEW_FETCH_SITES:
            let oldList = state.siteList
            return {
                ...state,
                isFetchingSiteList: false,
                siteList: oldList.concat(getVerifyIcon(action.payload.data))
            }
        case actionTypes.UPDATE_LAST_ID:
            return {
                ...state,
                lastID: action.payload.data
            }
        default:
            return state;
    }
}