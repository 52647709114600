export const TECHNICAL_TEAMS = {
  en: 'Technical teams',
  ro: 'Echipe tehnic'
}

export const CAR_NUMBER = {
  en: 'Car number',
  ro: 'Număr mașină'
}

export const ADD_TECHNICAL_TEAM = {
  en: 'Add technical team',
  ro: 'Crează echipaj'
}

export const TEAM_MEMBERS = {
  en: 'Team Members',
  ro: 'Membrii echipaj'
}

export const TEAM_NAME = {
  en: "Team name ",
  ro: "Nume echipaj "
}

export const CAN_NOT_CLOSE_SELECTING_TEAM_MODAL = {
  en: "Can't close the Selecting team modal",
  ro: "Nu poți să închizi modalul fără să selectezi membri echipajului!"
}

export const TECHNICAL_TEAM_SCORING = {
  en: "Technical team scoring",
  ro: "Punctaj echipaj technic"
}

export const POINTS = {
  en: "Points",
  ro: "Punctaj"
}

export const TECHNICAL_TEAM_MODAL_SCORE_MESSAGE = {
  en: "Adding team scores for technical task with id ",
  ro: "Adăugare scor echipă pentru task-ul cu id "
}

export const DISTANCE_KM = {
  en: "Distance (KM)",
  ro: "Distanță parcursă (KM)"
}

export const TASK_COMPLETE_VALUE = {
  en: "Task value",
  ro: "Valoare totală ticket"
}

export const TASK_REMAINING_VALUE = {
  en: "Task remaining value",
  ro: "Valoare rămasă ticket"
}

export const TASK_EXCEED_VALUE = {
  en: "The scores are bigger that the task value! Do you wish to continue?",
  ro: "Valorea totală este mai mare ca valoare task-ului! Vrei să continui?"
}

export const TECHNICAL_USER_HISTORY = {
  en: "Technical user history",
  ro: "Istoric punctaje utilizator technic"
}

export const TECHNICAL_USER = {
  en: "Technical user",
  ro: "Utilizator technic"
}

export const TECHNICAL_USER_SCORE = {
  en: "Technical user score",
  ro: "Punctaj echipaj technic"
}

export const TASK_SCORING_INCOMPLETE = {
  en: "Task score",
  ro: "Punctaj Echipaj"
}

export const TASK_SCORING_COMPLETE = {
  en: "Edit task score complete",
  ro: " Editare Punctaj Echipaj"
}

export const TECHNICAL_SCORES_VALIDATIONS = {
  en: "Technical scores validations",
  ro: "Validare punctaj"
}

export const BILL_VALIDATION_WITH_SCORE_UPDATE = {
  ro: "Validare factură / Validare punctaje",
  en: "Bill validation with score update"
}

export const DOWNLOAD_EXCEL = {
  ro: "Descarcă excel",
  en: "Download excel"
}

export const CAR_INVENTORY_TRANSFERS = {
  ro: "Transferuri inventar masină",
  en: "Car invnetory transfer"
}

export const CAR_INVENTORY = {
  ro: "Inventar masină",
  en: "Car inventory"
}
export const ACCEPT_TRANSFER = {
  ro: "Aceptă transferul",
  en: "Accept transfer",
}

export const DENY_TRANSFER = {
  ro: "Refuza transferul",
  en: "Deny transfer",
}

export const TEAM_NOT_SELECTED_TODAY = {
  ro: "Nici o echipa selectata astazi",
  en: "The team was not selected today"
}

export const NO_TRANSFERS_MESSAGE = {
  ro: "Nu exista transferuri in momentul de fata",
  en: "No transfers"
}

export const DENY_TRANSFER_CONFIRMATION_MESSAGE = {
  ro: "Refuza transferul",
  en: "Deny transfer"
}

export const ACCEPT_TRANSFER_CONFIRMATION_MESSAGE = {
  ro: "Aceptă transferul",
  en: "Accept the transfer"
}

export const NO_INVENTORY_ITEMS = {
  ro: "Nu exista intrari in inventar",
  en: "No entries in the inventory"
}

export const ALLOW_CAMERA_FOR_BARCODE_SCANING = {
  ro: "Activeaza camera pentru a scana codul de bare!",
  en: "Allow cammera access for scaning the barcode"
}

export const SCAN_ITEM = {
  ro: "Scaneaza item",
  en: "Scan product"
}

export const SEARCH_ITEM_BY_NAME = {
  ro: "Cauta dupa denumire",
  en: "Search item by name"
}

export const REGISTER_ITEMS_TO_TASK = {
  ro: "Adauga echipamente montate",
  en: "Register items to task"
}

export const SEARCH_ITEM_BY_BARCODE = {
  ro: "Cauta dupa cod de bare",
  en: "Search after the barcode"
}
