import React from 'react';
import axios from "axios";
import * as requestHeaders from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import * as lang from "../../../Constants/strings";
import { wrong_pv_raport } from "../../../Components/Misc/Tables/TableHeaders";
import SortBar from "./Componets/SortBar";
import UserWrongPvModal from "./Componets/UserWrongPvModal";

export default function WrongPvReport(props) {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data, setData] = React.useState([]);
    const [intervalStart, setIntervalStart] = React.useState(new Date());
    const [intervalEnd, setIntervalEnd] = React.useState(new Date());
    const [viewModal, setViewModal] = React.useState(false);
    const [selectedUserId, setSelectedUserId] = React.useState(null);

    const getWrongPvList = async (intervalStart, intervalEnd) => {
        const response = await axios.get("/api/get_wrong_pv_list", {
            ...requestHeaders.getGetHeader(),
            params: {
                intervalStart: intervalStart,
                intervalEnd: intervalEnd
            }
        });

        if (response.status === 200) {
            if (response.data.length > 0) setData(response.data)
        } else {
            console.log("error: ", response.data.message);
        }
    };

    return (
        <React.Fragment>
            <SortBar language={language}
                filter={(filters) => {
                    setIntervalStart(filters.startDate);
                    setIntervalEnd(filters.endDate);
                    getWrongPvList(filters.startDate, filters.endDate);
                }}
            />
            <UserWrongPvModal
                language={language}
                userId={selectedUserId}
                startDate={intervalStart}
                endDate={intervalEnd}
                open={viewModal}
                close={() => setViewModal(false)}
            />
            <MaterialTable
                tiny={true}
                title={lang.WRONG_PV_REPORT[language]}
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={wrong_pv_raport(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={(id) => {
                    setSelectedUserId(id);
                    setViewModal(true);
                }}
                clickLabel="USER_ID"
                hideAddBtn={true}
            />
        </React.Fragment>
    )
};
