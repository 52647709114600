import { useState, createRef } from "react";
import {
    Button,
    Grid,
    TextField,
    useTheme,
    useMediaQuery,
    Select,
    MenuItem,
    Divider,
    Typography
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {getPostHeader} from "../../../../Constants/requestHeaders";

import * as lang from "../../../../Constants/strings";
import CustomModal from '../../../../Components/Modals/CustomModal';
import PvModal from './PvModal';
import { makeStyles } from "@material-ui/styles";
import BillFields from "./BillFields";
import Cancel from "./CancelModal";
import {checkPermissions} from "../../../../Functions/permissionHide";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: theme.spacing(2)
    },
    mainGrid: {
        margin: theme.spacing(1)
    },
    testFiled: {
        margin: theme.spacing(1)
    },
    signature: {
        backgroundColor: "white",
        width: "100%",
        height: "50vh",
    },
    float: {
        margin: theme.spacing(1),
    },
}));

const ProjectActionButtons = (props) => {
    const {
        projectStatus,
        doAction,
        language,
        disabledActionButton = false,
        projectId,
        reFetch,
        userId,
        userPermission,
        currentOwnerId
    } = props;

    const [note, setNote] = useState('');
    const [modalState, setModalState] = useState(false);
    const [pvModalState, setPvModalState] = useState(false);

    const [projectNo, setProjectNo] = useState('');
    const [permitNo, setPermitNo] = useState('');
    const [permitDate, setPermitDate] = useState(new Date());
    const [projectDate, setProjectDate] = useState(new Date());
    const [cancelModal, setCancelModal] = useState(false);

    const [awbNumber, setAwbNumber] = useState('');
    const [sendToPoliceAttachmentFile, setEndToPoliceAttachmentFile] = useState(null);

    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    const [fileType, setFileType] = useState(null);

    // use only for displaying the uploaded file name 
    const [fileName, setFileName] = useState('');

    // On the change of the filetype reset the uploaded file
    // So the type of the file and actuale file are the same
    const setNewFileType = (filetype) => {
        setFileName('');
        setFileType(filetype);
    };

    // Creat a ref for the hide input
    const fileUploadRef = createRef();

    // The method takes a binary string and convert it to base64 encoded ASCII string
    // Using the WindowOrWorkerGlobalScope.btoa() method, see the MDN web docs
    function getData(file) {
        const reader = new FileReader();
        reader.onload = (event) => {
            let data = event.target.result.split(',');
            let decodedImageData = btoa(data[1]);                    // the actual conversion of data from binary to base64 format
            setEndToPoliceAttachmentFile({ data: decodedImageData, name: file.name, fileType: file.type, }); // Generating the object for the req
        };
        reader.readAsDataURL(file);
    }

    const getNextStatus = (status) => {
        switch (status) {
            case "CREATED": return "ASSIGN";
            case "ASSIGN": return "START_DESIGN";
            case "START_DESIGN": return "FINISH_DESIGN";
            case "FINISH_DESIGN": return "SIGN_PV"
            case "SIGN_PV": return "SEND_TO_POLICE"
            case "SEND_TO_POLICE": return "FINISH"
            default: return 'NONE';
        };
    };

    const validateData = () => {
        switch (getNextStatus(projectStatus)) {
            case "FINISH_DESIGN":
                return projectNo !== '';
            case "SEND_TO_POLICE":
                return awbNumber !== '' && setEndToPoliceAttachmentFile !== null
            case "FINISH":
                return permitNo !== "";
            case "SIGN_PV": return false;
            default: return true;
        }
    }

    const updateOwner = async () => {
        try {
            await axios.post('/api/project_owner', { userId, projectId } , getPostHeader());
            await reFetch();
        } catch (e) {
            console.error(e);
        }
    }

    const finishDesign = () => {
        return (
            <>
                <Grid xs={12}>
                    <TextField
                        label="Nr. proiect"
                        variant="outlined"
                        className={classes.testFiled}
                        fullWidth
                        defaultValue={""}
                        value={projectNo}
                        onChange={e => setProjectNo(e.target.value)}
                    />
                </Grid>
                <Grid xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            className={classes.testFiled}
                            required
                            inputVariant="outlined"
                            fullWidth
                            name="permit_date"
                            label="Dată aviz"
                            InputProps={{ readOnly: true }}
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                "aria-label": "change permit date",
                            }}
                            value={projectDate}
                            onChange={(value) => setProjectDate(value)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </>
        )
    };

    const awb = () => {
        return (
            <>
                <Grid xs={12}>
                    <TextField
                        label="AWB"
                        variant="outlined"
                        className={classes.testFiled}
                        fullWidth
                        defaultValue={""}
                        value={awbNumber}
                        onChange={e => setAwbNumber(e.target.value)}
                    />
                </Grid>
                <Grid xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                label={lang.FILE_TYPE[language]}
                                value={fileType}
                                className={classes.testFiled}
                                onChange={e => setNewFileType(e.target.value)}
                                variant='outlined'
                            >
                                <MenuItem value={null}>NONE</MenuItem>
                                <MenuItem value={'image/*'}>IMAGINE</MenuItem>
                                <MenuItem value={'application/pdf'}>PDF</MenuItem>
                                <MenuItem value={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}> MICROSOFT EXCEL </MenuItem>
                                <MenuItem value={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'}> MICROSOFT WORD </MenuItem>
                                <MenuItem value={'text/html'}>HTML</MenuItem>
                                <MenuItem value={'audio/*'}>AUDIO</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.testFiled}
                                fullWidth
                                style={{ height: "55px", width: "90%", marginLeft: "10%" }}
                                onClick={() => fileUploadRef.current.click()}
                                disabled={fileType === null}
                            >
                                {lang.FILE_UPLOAD[language]}
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        fileName !== '' && <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    }
                    {
                        fileName !== '' && <Typography variant='h5'>{fileName}</Typography>
                    }
                    <input
                        type="file"
                        accept={fileType}
                        style={{ display: "none" }}
                        ref={fileUploadRef}
                        tabIndex={-1}
                        onChange={async (e) => {
                            e.persist();
                            // Get the base64 encoded file
                            getData(e.target.files[0]);
                            // Get the name for displaying 
                            setFileName(e.target.files[0].name);
                        }}
                    />
                </Grid>
            </>
        )
    }

    const lastStep = () => {
        return (
            <>
                <Grid xs={12}>
                    <TextField
                        label="Nr. Aviz Poliție"
                        variant="outlined"
                        className={classes.testFiled}
                        type="number"
                        fullWidth
                        defaultValue={""}
                        value={permitNo}
                        onChange={e => setPermitNo(e.target.value)}
                    />
                </Grid>
                <Grid xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            className={classes.testFiled}
                            required
                            inputVariant="outlined"
                            fullWidth
                            name="permit_date"
                            label="Dată aviz"
                            InputProps={{ readOnly: true }}
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                "aria-label": "change permit date",
                            }}
                            value={permitDate}
                            onChange={(value) => setPermitDate(value)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container justifyContent="space-around" spacing={12} className={classes.mainGrid}>
                <Grid xs={12} md={2}>
                    <Button
                        className={classes.buttons}
                        fullWidth
                        disabled={["FINISH", "CANCEL"].indexOf(projectStatus) !== -1 || getNextStatus(projectStatus) === "SIGN_PV" || disabledActionButton}
                        onClick={() => setModalState(true)}
                        variant="contained"
                        color="primary"
                    >
                        {lang[getNextStatus(projectStatus)][language]}
                    </Button>
                </Grid>

                <Grid xs={12} md={2}>
                    <Button
                        className={classes.buttons}
                        fullWidth
                        disabled={disabledActionButton || !(getNextStatus(projectStatus) === 'SIGN_PV')}
                        variant="contained"
                        color="secondary"
                        value={pvModalState}
                        onClick={() => setPvModalState(true)}
                    >
                        Proces verbal predare
                    </Button>
                </Grid>

                {
                    ["FINISH", "CANCEL"].indexOf(projectStatus) === -1 &&
                    checkPermissions(userPermission, { parent: "PROJECTS", name: "OVERWRITE_PROJECT_OWNER" }) &&
                    <Grid xs={12} md={2}>
                        <Button
                            className={classes.buttons}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={!currentOwnerId || currentOwnerId === userId}
                            onClick={() => updateOwner()}
                        >
                            {lang.TAKE_PROJECT[language]}
                        </Button>
                    </Grid>
                }

                <Grid xs={12} md={2}>
                    <Button
                        className={classes.buttons}
                        fullWidth
                        disabled={
                            ["FINISH", "CANCEL"].indexOf(projectStatus) !== -1 ||
                            !checkPermissions(userPermission, {parent: "PROJECTS", name: "CANCEL_PROJECT"})
                        }
                        variant="contained"
                        color="secondary"
                        value={cancelModal}
                        onClick={() => setCancelModal(true)}
                    >
                        {lang.CANCEL[language]}
                    </Button>
                </Grid>

            </Grid>
            <PvModal
                open={pvModalState}
                close={() => {
                    reFetch();
                    setPvModalState(false);
                }}
                projectId={projectId}
                language={language}
                classes={classes}
                tiny={tiny}
            />
            <CustomModal
                open={modalState}
                fullScreen={tiny}
                fullWidth
                title={projectStatus ? `${lang[projectStatus][language]} : ${lang[getNextStatus(projectStatus)][language]}` : "_"}
                content={
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <TextField
                                fullWidth
                                label="Observație"
                                className={classes.testFiled}
                                defaultValue={""}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        {projectStatus && getNextStatus(projectStatus) === "FINISH_DESIGN" && finishDesign()}
                        {projectStatus && getNextStatus(projectStatus) === "SEND_TO_POLICE" && awb()}
                        {projectStatus && getNextStatus(projectStatus) === "FINISH" && lastStep()}
                        {projectStatus &&
                            (
                                getNextStatus(projectStatus) !== "ASSIGN" ||
                                getNextStatus(projectStatus) !== "NONE"
                            ) &&
                            <BillFields
                                language={language}
                                taskId={projectId}
                                contractor={null}
                                userId={userId}
                                refetchTask={reFetch}
                            />
                        }
                    </Grid>
                }
                close={() => setModalState(false)}
                execute={() => {
                    doAction({
                        note,
                        projectNo,
                        projectDate,
                        permitDate,
                        permitNo,
                        awbNumber,
                        sendToPoliceAttachmentFile
                    });
                    setModalState(false);
                }}
                language={language}
                validate={validateData()}
            />
            <Cancel
                open={cancelModal}
                close={() => setCancelModal(false)}
                language={language}
                projectId={projectId}
                refetch={reFetch}
                userId={userId}
            />
        </>
    )
};

export default ProjectActionButtons;