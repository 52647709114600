export const MAINTENANCE = {
  ro: "Mentenanță",
  en: "Maintenance",
};

export const MAINTENANCES = {
  ro: "Mentenanțe",
  en: "Maintenances",
};
export const TASK_MAINTENANCES = {
  ro: "Tickete Mentenanțe",
  en: "Maintenances Tickets",
};

export const ADD_MAINTENANCE = {
  ro: "Adaugă mentenanță",
  en: "Add maintenance",
};

export const MAINTENANCE_HISTORY = {
  ro: "Istoric Mentenanță",
  en: "Maintenance History",
};

export const MAINTENANCE_TYPE = {
  en: "Maintenance type",
  ro: "Tip mententenață"
};

export const MAINTENANCE_SERVICES = {
  en: "Maintenance services",
  ro: "Servicii mentenață"
};

export const LAST_MAINTENANCE_DATE = {
  en: "Last maintenance data",
  ro: "Ultima mentenață efectuată"
};

export const GET_MAINTENANCE = {
  en: "Get maintenance",
  ro: "Menatenante"
}

export const CLOSE_MAINTENANCE_WITH_IMAGE  = {
  en: "Close with image",
  ro: "Închide mentenață cu poză P.V."
}
