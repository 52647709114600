import { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios";
import moment from "moment";

import MaterialTable from "../../Components/Misc/Tables/MaterialTableSimple";
import { riscEvaluations } from "../../Components/Misc/Tables/TableHeaders";
import * as requestHeaders from "../../Constants/requestHeaders";
// import ProjectActionModal from "../Site/SiteSingleViewComponents/Projects/ProjectActionModal";
import RiscEvaluationActionModal from "../Site/SiteSingleViewComponents/RiscEvaluation/RiscEvaluationActionModal";
import * as lang from "../../Constants/strings";

const RiscEvaluationTable = (props) => {
    const {
        language,
        userId
    } = props;

    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedEntry, setSelectEntry] = useState(null)

    const getData = useCallback(async () => {
        try {
            const response = await axios.get(`/api/evaluations`, requestHeaders.getGetHeader());
            setData(response.data.map(e => ({
                ...e,
                STATUS_TRANS: e.STATUS ? lang[e.STATUS + "_t"][language] : "",
                LAST_UPDATE_TRANS: moment(e.LAST_UPDATE).format("DD-MM-YYYY HH:mm"),
                CREATE_TIME_TRANS: moment(e.CREATE_TIME).format("DD-MM-YYYY HH:mm")
            })));
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <RiscEvaluationActionModal
                open={selectedEntry !== null}
                language={language}
                close={() => {
                    setSelectEntry(null);
                    getData(); // Refetch the list on modal close
                }}
                riscEvaluationId={selectedEntry}
                userId={userId}
            />
            <Grid
                item
                xs={12}
                md={12}
                container
                style={{ paddingRight: "0px" }}
                direction="column"
            >
                <MaterialTable
                    hideAddBtn={true}
                    tiny={false}
                    title={lang.RISC_EVALUATIONS[language]}
                    language={language}
                    data={data}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(value) => setRowsPerPage(value)}
                    header={riscEvaluations(language)}
                    dense={false}
                    openRowModal={(id) => setSelectEntry(id)}
                    clickLabel="ID"
                />
            </Grid>
        </>
    )
};

export default RiscEvaluationTable;