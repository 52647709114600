import React, { useEffect, useState } from "react";
import {Grid, Paper, Typography} from "@material-ui/core";
import axios from "axios";

import * as reqHeaders from "../../../Constants/requestHeaders";
import TransferItem from "./TransferItem";
import * as lang from "../../../Constants/strings";

const TransferList = (props) => {
    const {
        id,
        companyName,
        companyId,
        userId,
        language,
        onNumberOfItemsChange
    } = props;

    const [transfers, setTransfers] = useState([]);

    const fetchTransfers = async () => {
        try {
            const res = await axios.get("/api/team/transfer/inventory", {
                ...reqHeaders.getGetHeader(),
                params: {
                    id: userId,
                    placeOfBusinessId: id,
                }
            });

            const formated = [];

            for(const entry of res.data.to) {
                formated.push({
                    ...entry,
                    fromName: entry.fromName.replace(`(${companyName})`, ""),
                    toName: entry.toName.replace(`(${companyName})`, ""),
                });
            }

            if (onNumberOfItemsChange) onNumberOfItemsChange(formated.length);
            setTransfers(formated);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchTransfers().then(() => {});
    }, [id]);

    return (
        <Paper elevation={4} style={{ padding: "10px", marginTop: "10px", border: "1px solid #00000050" }}>
            <Grid container>
                <Grid item xs={12}>
                    <div>{companyName.toUpperCase()}</div>
                </Grid>
                <Grid item xs={12}>
                    {
                        transfers.map((transfer, index) =>
                            <TransferItem
                                key={`transfer-item-${index}`}
                                id={transfer.id}
                                fromName={transfer.fromName}
                                toName={transfer.toName}
                                toType={transfer.toType}
                                quantity={transfer.quantity}
                                um={transfer.um}
                                status={transfer.status}
                                createdBy={transfer.createdBy}
                                createdAt={transfer.createdAt}
                                productName={transfer.productName}
                                userId={userId}
                                reload={() => fetchTransfers()}
                            />
                        )
                    }
                    {
                        transfers.length === 0 && <Typography variant="h5" color="secondary" style={{ marginTop: "10px", textAlign: "center" }}>{lang.NO_TRANSFERS_MESSAGE[language].toUpperCase()}</Typography>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default TransferList;