import * as actionTypes from '../actions/taskActions';
import { getPriorityIcon, getStatusIcon } from '../Functions/getIcon';

const initialState = {
    isFetchingTaskList: false,
    isSubmittingTask: false,
    isFetchingTaskData: true,
    taskList: [],
    taskData: [],

    isFetchingInterventionList: false,
    isSubmittingIntervention: false,
    isFetchingInterventionData: true,
    interventionList: [],
    interventionData: [],
    isUploadingFile: false
};

export default function taskReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TASK_ADD_BEGIN: {
            return {
                ...state,
                isSubmittingTask: true,
            }
        }
        case actionTypes.TASK_ADD_SUCCESS:
        case actionTypes.TASK_ADD_FAILED: {
            return {
                ...state,
                isSubmittingTask: false,
            }
        }
        case actionTypes.TASK_LIST_REQUEST: {
            return {
                ...state,
                isFetchingTaskList: true,
            }
        }
        case actionTypes.TASK_LIST_RECIEVE: {
            let data = getStatusIcon(getPriorityIcon(action.payload.data));
            return {
                ...state,
                isFetchingTaskList: false,
                taskList: data,
            }
        }
        case actionTypes.TASK_DATA_REQUEST: {
            return {
                ...state,
                isFetchingTaskData: true,
            }
        }
        case actionTypes.TASK_DATA_RECIEVE: {
            return {
                ...state,
                isFetchingTaskData: false,
                taskData: action.payload.data,
            }
        }
        case actionTypes.FETCH_NEW_TASKS: {
            let oldList = state.taskList;
            return {
                ...state,
                isFetchingTaskList: false,
                taskList: oldList.concat(getStatusIcon(getPriorityIcon(action.payload.data)))
            }
        }
        case actionTypes.INTERVENTION_ADD_BEGIN: {
            return {
                ...state,
                isSubmittingIntervention: true,
            }
        }
        case actionTypes.INTERVENTION_ADD_SUCCESS:
        case actionTypes.INTERVENTION_ADD_FAILED: {
            return {
                ...state,
                isSubmittingIntervention: false,
            }
        }
        case actionTypes.INTERVENTION_LIST_REQUEST: {
            return {
                ...state,
                isFetchingInterventionList: true,
            }
        }
        case actionTypes.INTERVENTION_LIST_RECIEVE: {
            return {
                ...state,
                isFetchingInterventionList: false,
                interventionList: action.payload.data,
            }
        }
        case actionTypes.INTERVENTION_DATA_REQUEST: {
            return {
                ...state,
                isFetchingInterventionData: true,
            }
        }
        case actionTypes.INTERVENTION_DATA_RECIEVE: {
            return {
                ...state,
                isFetchingInterventionData: false,
                interventionData: action.payload.data,
            }
        }
        case actionTypes.UPLOAD_FILE_BEGINS: {
            return {
                ...state,
                isUploadingFile: true,
            }
        }
        case actionTypes.UPLOAD_FILE_ENDS: {
            return {
                ...state,
                isUploadingFile: false,
            }
        }
        default:
            return state;
    }
};