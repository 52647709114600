import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import * as lang from "../Constants/strings";

const qrcodeRegionId = "html5qr-code-full-region";

// @param props : {
//     fps: number;
//     qrbox?: any;
//     aspectRatio?: number;
//     disableFlip?: boolean;
//     verbose?: boolean;
//     qrCodeSuccessCallback: (decodedText: any, decodedResult: any) => void;
//     qrCodeErrorCallback: (e: any) => void;
//      language
// }

const Html5QrcodePlugin = (props) => {
    const [html5QrcodeScanner, setHtml5QrcodeScanner] = useState(null);
    const [hasVideoPermission, setHasVideoPermission] = useState(false);
    const [showAllowPermissionMessage, setShowAllowPermissionMessage] = useState(false);

    const checkForCamera = () => {
        navigator.mediaDevices.getUserMedia({ video : true })
            .then((result) => {
                setHasVideoPermission(true);
                setShowAllowPermissionMessage(false);
            })
            .catch((e) => {
                console.error(e);
                setHasVideoPermission(false);
                setShowAllowPermissionMessage(true);
            });
    };

    useEffect(() => {
        checkForCamera();
    }, []);

    useEffect(() => {
        if(!html5QrcodeScanner && hasVideoPermission) {
            const config = {
                fps: props.fps,
                qrbox: props.qrbox,
                aspectRatio: props.aspectRatio,
                disableFlip: props.disableFlip,
            };
            const verbose = props.verbose === true;
            let html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
            setHtml5QrcodeScanner(html5QrcodeScanner);
            html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

            // cleanup function when component will unmount
            return () => {
                html5QrcodeScanner.clear().catch(error => {
                    console.error("Failed to clear html5QrcodeScanner. ", error);
                });
            };
        }
    }, [hasVideoPermission]);

    useEffect(() => {
        if(html5QrcodeScanner) {
            const d = document.getElementById("html5-qrcode-button-camera-permission");
            if(d) {
                d.click();
            }
        }
    }, [html5QrcodeScanner]);
    
    return (
        <>
            <div id={qrcodeRegionId} />
            {showAllowPermissionMessage && <div style={{ marginTop: "10px" }}>
                {lang.ALLOW_CAMERA_FOR_BARCODE_SCANING[props.language]}
            </div>}
        </>
    );
};

export default Html5QrcodePlugin;