import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as lang from '../../../../Constants/strings';
import RoleNewForm from '../../../../Components/Misc/Forms/RoleNewForm';

export default function RoleEditModal(props) {
    const { selectedIndex, language, updateRole, close } = props;
    const [editData, setEditData] = React.useState({ NAME: "", PERMISSIONS: [] });

    function getIndeterminate(title) {
        let total = editData.PERMISSIONS.filter(permission => permission.PARENT_PERMISSION === title).length;
        let checked = editData.PERMISSIONS.filter(permission => permission.PARENT_PERMISSION === title && permission.VALUE === 1).length;

        if (total === checked)
            return 1;
        else
            if (checked === 0)
                return 0;
            else
                return -1;
    }

    function updateCheckBox(e, type) {
        let placeholder = [];
        if (type === 1) {
            let parentName = editData.PERMISSIONS.filter(permission => permission.PERMISSION === e.target.value)[0].PARENT_PERMISSION;
            editData.PERMISSIONS.forEach(permission => {
                if (permission.PERMISSION === e.target.value)
                    placeholder.push({ ...permission, VALUE: permission.VALUE === 1 ? 0 : 1, EDITED: 1 })
                else
                    placeholder.push(permission);
            });
            let total = placeholder.filter(permission => permission.PARENT_PERMISSION === parentName).length;
            let checked = placeholder.filter(permission => permission.PARENT_PERMISSION === parentName && permission.VALUE === 1).length;
            placeholder.forEach((permission, index) => {
                if (permission.PERMISSION === parentName)
                    placeholder[index].VALUE = total === checked ? 1 : 0;
            })
        }
        else {
            let parent = editData.PERMISSIONS.filter(permission => permission.PERMISSION === e.target.value)[0];
            editData.PERMISSIONS.forEach(permission => {
                if (permission.PARENT_PERMISSION === e.target.value)
                    placeholder.push({ ...permission, EDITED: parent.VALUE === permission.VALUE ? 1 : 0, VALUE: parent.VALUE === 0 ? 1 : 0 })
                else
                    if (permission.PERMISSION === e.target.value) {
                        placeholder.push({ ...permission, VALUE: parent.VALUE === 0 ? 1 : 0 })
                    }
                    else
                        placeholder.push(permission);

            })
        }

        setEditData({
            ...editData,
            PERMISSIONS: placeholder
        })
    }


    React.useEffect(() => {
        setEditData({ NAME: "", PERMISSIONS: [] });
        if (props.selectedIndex !== null)
            fetch(`/api/role_data?id=${props.selectedIndex}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setEditData({ ...response.data })
                })
                .catch(err => console.log(err))
    }, [props.selectedIndex])

    return (
        <CustomModal
            fullScreen
            title={lang.EDIT_ROLE[language]}
            open={selectedIndex !== null}
            content={
                <RoleNewForm
                    lock
                    language={language}
                    updateCheckBox={updateCheckBox}
                    roleData={editData}
                    getIndeterminate={getIndeterminate}
                    updateField={(e) => setEditData({ ...editData, NAME: e.target.value })}
                />
            }
            close={close}
            execute={() => {
                editData.PERMISSIONS.filter(e => e.EDITED === 1).forEach(e => console.log(e));
                updateRole(editData);
                close();
            }}
            language={language}
            validate={true}
        />
    );
}