import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Chip, Input, Button, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../../Constants/strings';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import axios from "axios";
import * as reqHeaders from "../../../../Constants/requestHeaders";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ExecEditForm(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(35);
    const { editData, language, active, typeList, updateFieldRaw } = props;
    const [edited, setEdited] = React.useState(false);
    const [externalCompanies, setExternalCompanies] = React.useState([]);

    const fetchExternalCompanies = async () => {
        try {
            const res = await axios.get("/api/external/companies", reqHeaders.getGetHeader());
            setExternalCompanies(res.data.data);
        } catch (e) {
            console.error(e);
        }
    }

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        setEdited(false);
        fetchExternalCompanies().then(() => {});
    }, []);

    React.useEffect(() => {
        setEdited(false);
    }, [editData.OBJID]);

    function getLabel(id) {
        let subtype = parseInt(id.split('-')[0], 10);
        let code = parseInt(id.split('-')[1], 10);

        let entry = typeList.filter(type => type.SUB_TYPE === subtype && type.CODE === code)[0];

        return entry ? lang[entry.DESCRIPTION_TEXT][language]: "";
    }
    function handleDefaultChange() {
        setEdited(true);
        updateFieldRaw('IS_DEFAULT', !editData.IS_DEFAULT);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    disabled={!active}
                    required
                    fullWidth
                    variant="outlined"
                    name="NAME"
                    label={lang.EXEC_NAME[language]}
                    value={editData.NAME ? editData.NAME : ""}
                    onChange={(e) => props.updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={!active}
                    fullWidth
                    variant="outlined"
                    name="PHONE"
                    label={lang.PHONE[language]}
                    value={editData.PHONE ? editData.PHONE : ""}
                    onChange={(e) => props.updateField(e, 20)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={!active}
                    fullWidth
                    variant="outlined"
                    name="EMAIL"
                    label={lang.EMAIL[language]}
                    value={editData.EMAIL ? editData.EMAIL : ""}
                    onChange={(e) => props.updateField(e,50)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={!active}
                    fullWidth
                    variant="outlined"
                    name="ADDRESS"
                    label={lang.ADDRESS[language]}
                    value={editData.ADDRESS ? editData.ADDRESS : ""}
                    onChange={(e) => props.updateField(e, 250)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled={!active}
                    fullWidth
                    variant="outlined"
                    name="NR_REG"
                    label={lang.NR_REG[language]}
                    value={editData.NR_REG ? editData.NR_REG : ""}
                    onChange={(e) => props.updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled={!active}
                    fullWidth
                    variant="outlined"
                    name="CUI"
                    label={lang.CUI[language]}
                    value={editData.CUI ? editData.CUI : ""}
                    onChange={(e) => props.updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel ref={inputLabel} id="demo-mutiple-chip-label">{lang.OPTIONS[language]}</InputLabel>
                    <Select
                        disabled={!active}
                        multiple
                        name="OPT"
                        labelWidth={labelWidth}
                        value={editData.OPT ? editData.OPT : []}
                        onChange={(e) => props.updateField(e, false)}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map((value, index) => (
                                    <Chip key={index} label={getLabel(value)} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {typeList.length > 0 && typeList.filter(type => type.TYPE === 2).map(type =>
                            <MenuItem key={type.ID} value={type.SUB_TYPE + "-" + type.CODE}>
                                {lang[type.DESCRIPTION_TEXT][language]}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel ref={inputLabel} id="demo-mutiple-chip-label">{"Selecteaza companie platforma inventar"}</InputLabel>
                    <Select
                        fullWidth
                        name="EXTERNAL_ID"
                        labelWidth={labelWidth}
                        placeHolder={"Selecteaza companie platforma inventar"}
                        value={editData.EXTERNAL_ID ? editData.EXTERNAL_ID : null}
                        onChange={(e) => props.updateField(e)}
                    >
                        {
                            externalCompanies.map((c) =>
                                <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={editData.IS_DEFAULT}
                            disabled={editData.IS_DEFAULT && !edited}
                            onChange={handleDefaultChange}
                        />
                    }
                    label={
                        <Typography variant='body1'>{lang.IS_DEFAULT[language]}</Typography>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color={active ? "secondary" : "primary"}
                    onClick={() => { props.toggleExecutor(); props.close(); }}
                >
                    {active ?
                        <BlockRoundedIcon fontSize="small" className={classes.leftIcon} />
                        :
                        <CheckCircleOutlineRoundedIcon fontSize="small" className={classes.leftIcon} />
                    }
                    {lang.TOGGLE_EXECUTOR(active)[language]}
                </Button>
            </Grid>
        </Grid>
    );
}