import React, { Component } from 'react';
import { CssBaseline, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../Constants/strings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundImage: `url(/api/image?image=paperHeader-02.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        transform: 'rotate(180deg)',
    },
}));

function DashboardWidget(props) {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
        </Grid>
    );
}


class DashboardView extends Component {
    componentDidMount() {
        this.props.actions.checkSession(false);
        document.title = "RPG - " + lang["HOME"][this.props.language];
    }
    render() {
        return (
            <React.Fragment>
                <DashboardWidget />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        language: state.config.language,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);