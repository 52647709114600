import React, {useEffect} from "react";
import {
    Typography,
    TextField,
    Checkbox,
    Grid,
    FormControlLabel,
    Divider
} from "@material-ui/core";

const RenderField = (props) => {
    const {
        type,
        object,
        change
    } = props;
    return (
        <>
        {
            type === "boolean" && 
            <FormControlLabel
                style={{margin: "10px", display: "flex", justifyContent: "flex-end"}}
                control={<Checkbox checked={object} onClick={() => change(!object)}/>}
                label="Verificat"
            />
        }
        {
            type === "object" && object !== null &&
            <TextField 
                fullWidth
                variant="outlined"
                type={object.type === "number" ? object.type : "text"}
                value={object.value}
                placeholder={object.placeholder}
                onChange={(e) => change({...object, value: e.target.value})}
                style={{margin: "10px"}}
            />
        }
        {
            type === "string" &&
            <Typography style={{margin: "10px", fontSize: "18px"}}>{object}</Typography>
        }
        </>
    )
};

const RenderQuestion = (props) => {
    const {questions, updateFields} = props;

    const onChange = (value, e, q) => {
        updateFields(value, e, q);
    }

    return (
        <>
        <Divider style={{width: "100%"}}/>
        <Grid item xs={12}>
            <Typography style={{color: "red", fontSize: "25px", alignSelf: "center", marginTop: "20px", marginBottom: "20px"}}>
                {questions.title}
            </Typography>
            {
                questions.questions.map((question, qIndex) => {
                    return (
                        <>
                            <Divider key={`question_question_${qIndex}_${questions.title}`} style={{height: "3px"}}/>
                            {
                                question.map((e, eIndex) => {
                                    return <RenderField 
                                                key={`question_field_${eIndex}_${questions.title}`}
                                                type={typeof(e)} 
                                                object={e}
                                                change={value => onChange(value, eIndex, qIndex)}
                                            />
                                })
                            }
                        </>
                    )
                })
            }
        </Grid>
        </>
    )
};

export default RenderQuestion;