import React from 'react';
import axios from "axios";
import * as requestHeders from "../../../../Constants/requestHeaders";
import * as lang from "../../../../Constants/strings";
import CustomModal from "../../../../Components/Modals/CustomModal";
import { TextField } from "@material-ui/core";

export default function UpdateTaskReason(props) {
    const { language, open, close, taskId, refeatchTaskData, onNotificationOpen, userObj } = props;
    const [updateReason, setUpdateReason] = React.useState('');

    const patchReason = async () => {
        let response = await axios.patch('/api/update_task_reason', { reason: updateReason }, {
            ...requestHeders.getPatchHeader(),
            params: {
                taskId,
                userObj
            }
        });

        if (response.status === 200) {
            refeatchTaskData();
            onNotificationOpen("success", "DATA_UPDATE_SUCCESS");
            close();
        } else {
            onNotificationOpen("error", "ERROR_OCCURED");
            close();
        }
    };

    return (
        <CustomModal
            open={open}
            fullWidth
            title={lang.UPDATE_REASON[language]}
            content={
                <TextField
                    variant="outlined"
                    fullWidth
                    name="reason"
                    label={lang.REASON[language]}
                    value={updateReason}
                    onChange={(e) => setUpdateReason(e.target.value)}
                    required
                    multiline
                />
            }
            close
            formValidate
            execute={() => patchReason()}
            language={language}
            close={() => close()}
        />
    )
}
