import React from 'react';
import { Tabs, Paper, Tab, makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { HomeWork, NotificationsActive } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import Can from '../../../Components/Misc/Can';
import { checkPermissions } from '../../../Functions/permissionHide';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        width: 'auto'
    },
}));

export default function CustomerTabs(props) {
    const classes = useStyles();
    const { selectedIndex, handelIndexChange, language, permissions } = props;
    return (
        <React.Fragment>
            <Grid container spacinf={12}>
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <Tabs value={selectedIndex} onChange={(e, newValue) => handelIndexChange(newValue)} variant='fullWidth'>
                            <Tab icon={<HomeWork color='primary' />} label={lang.CUSTOMER_SITES[language]} />
                            <Tab icon={<NotificationsActive color='secondary' />} label={lang.CUSTOMER_INTERVENTIONS[language]} disabled={!checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "VIEW_CUSTOMER_INTERVENTION" })} />
                        </Tabs>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};
