import React, {useEffect, useState} from 'react';
import {
    makeStyles,
    Grid,

} from '@material-ui/core';
import {
    Replay,
    ForwardRounded,
    CancelRounded,
} from '@material-ui/icons';
import GroupButtonSet from '../../../Components/Misc/GroupButtonSet';
import { checkPermissions } from '../../../Functions/permissionHide';
import * as lang from '../../../Constants/strings';
import axios from "axios";
import {getGetHeader} from "../../../Constants/requestHeaders";
import * as requestHeaders from "../../../Constants/requestHeaders";

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(2),
    },
    float: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 99,
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.5),
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
}));

export default function InstallationButtons(props) {
    const classes = useStyles();
    const { language, tiny, installation, userId, userPermissions, isAuthenticated } = props;
    const [hasTechnicalTeam, setHasTechnicalTeam] = useState(false);
    const [hasBill, setHasBill] = useState(false);

    const checkIfUserHasTeam = async () => {
        try {
            const res = await axios.get('/api/user_team', getGetHeader());
            setHasTechnicalTeam(res.data.hasTeam && res.data.isTechnicalUser);
        } catch (e) {
            console.log(e);
            setHasTechnicalTeam(false);
        }
    };

    const checkForBill = async () => {
        try {
            const { data: { data } } = await axios.get('/api/installation_bill_count', {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: props.installation ? props.installation.OBJID : ""
                }
            })
            setHasBill(data > 0);
        } catch (error) {
            setNoBill(false);
            console.log("The installation doesn't have a bill!")
        }
    }

    useEffect(() => {
        checkIfUserHasTeam();
        checkForBill();
    }, [props]);

    return (
        <React.Fragment>
            <Grid item container direction="row" xs={12} lg={12} style={{ paddingRight: '0px' }}>
                {installation &&
                    <GroupButtonSet
                        tiny={tiny}
                        language={language}
                        style={{
                            size: "large",
                            color: "default",
                            variant: "contained",
                            disabled: false,
                        }}
                        list={[
                            {
                                primary: userId === installation.OWNER_CURR,
                                text: lang.NEXT_STATUS(installation.STATUS)[language],
                                style: {
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(installation.STATUS) !== -1 ||
                                        ["STARTED"].indexOf(installation.STATUS) !== -1 && installation.CONTRACT_OBJID === null,
                                },
                                icon: <ForwardRounded />,
                                render: userId === installation.OWNER_CURR && isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "INSTALLATION",
                                        name: "TAKE_INSTALLATION",
                                    }) === true,
                                action: () => props.openAction(installation.STATUS_ID, false),
                            },
                            {
                                primary: userId === installation.OWNER_CURR,
                                text: lang.CLOSE_INSTALLATION_WITH_IMAGE[language],
                                style: {
                                  disabled: !hasBill,
                                },
                                icon: <ForwardRounded />,
                                render: userId === installation.OWNER_CURR && isAuthenticated && installation.STATUS === 'STARTED',
                                action: () => props.openCloseWithImage(),
                            },
                            {
                                primary: userId === installation.OWNER_CURR,
                                text: lang.ASSIGN_TO_ME[language],
                                style: {
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(installation.STATUS) !== -1 ||
                                        ["STARTED"].indexOf(installation.STATUS) !== -1 && installation.CONTRACT_OBJID === null,
                                },
                                icon: <ForwardRounded />,
                                render: userId !== installation.OWNER_CURR &&
                                    isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "INSTALLATION",
                                        name: "TAKE_INSTALLATION",
                                    }) === true,
                                action: () => props.openAsignToMeModal(),
                            },
                            {
                                text: lang.CANCEL[language],
                                style: {
                                    color: "secondary",
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(installation.STATUS) !== -1
                                },
                                icon: <CancelRounded />,
                                rightIcon: <CancelRounded fontSize="small" className={classes.rightIcon} />,
                                render: ["CANCELLED"].indexOf(installation.STATUS) === -1 && isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "INSTALLATION",
                                        name: "CANCEL_INSTALLATION",
                                    }) === true,
                                action: () => props.openActionCancelModal(),
                            },
                            {
                                text: lang.UPDATE_REASON[language],
                                style: {
                                    color: "primary",
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(installation.STATUS) !== -1
                                },
                                icon: <Replay />,
                                rightIcon: <Replay fontSize="small" className={classes.rightIcon} />,
                                render: isAuthenticated && checkPermissions(userPermissions, { parent: "INSTALLATION", name: "RESET_INSTALLATION" }),
                                action: () => props.openUpdateReasonModal(),
                            },
                            {
                                primary: userId === installation.OWNER_CURR,
                                text: "Înregistrare progress",
                                style: {
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(installation.STATUS) !== -1 ||
                                        ["STARTED"].indexOf(installation.STATUS) !== -1 && installation.CONTRACT_OBJID === null,
                                },
                                icon: <ForwardRounded />,
                                render: userId === installation.OWNER_CURR && isAuthenticated && installation.STATUS === 'STARTED' && hasTechnicalTeam,
                                action: () => props.openProgressModal(),
                            },
                            {
                                text: lang.RESET_ALOCATION[language],
                                style: {
                                    color: "primary",
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(installation.STATUS) !== -1
                                },
                                icon: <Replay />,
                                rightIcon: <Replay fontSize="small" className={classes.rightIcon} />,
                                render: ["CANCELLED"].indexOf(installation.STATUS) === -1 && isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "INSTALLATION",
                                        name: "RESET_INSTALLATION",
                                    }) === true,
                                action: () => props.openResetAlocationModal(),
                            },
                        ]}
                    />
                }
            </Grid>
        </React.Fragment>
    );
}
