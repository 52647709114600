import React, { useState, useEffect } from "react";
import {Divider, Grid, Paper, Typography} from "@material-ui/core";
import axios from "axios";

import * as reqHeaders from "../../../Constants/requestHeaders";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";
import InventoryItem from "./InventoryItem";

const InventoryModal = (props) => {
    const {
        open,
        onClose,
        language,
        userId
    } = props;

    const [inv, setInv] = useState(null);
    const [entries, setEntries] = useState([]);

    const fetchTeamInventory = async () => {
        try {
            const res = await axios.get("/api/team/inventory", {
                ...reqHeaders.getGetHeader(), params: {
                    id: userId
                }
            });

            setInv(res.data);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchPlaceOfBusinessInventory = async (placeOfBusinessId) => {
        try {
            const res = await axios.get("/api/external/placeOfBusiness/inventory", {
                ...reqHeaders.getGetHeader(), params: {
                    id: userId,
                    placeOfBusinessId
                }
            });

            const orderList = res.data.data.sort((a, b) => {
                const nameA = a.productName.toUpperCase();
                const nameB = b.productName.toUpperCase();
                if (nameA < nameB)
                    return -1;

                if (nameA > nameB)
                    return 1;

                return 0;
            });

            return {
                placeOfBusinessId,
                data: orderList
            };
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (userId) fetchTeamInventory().then(() => {});
    }, [userId, open]);

    useEffect(() => {
        if (inv && inv.data.length > 0) {
            (async () => {
                let acc = [];
                // call the fetchPlaceOfBusinessInventory function for all the entries in the inv.data
                for (const e of inv.data) {
                    const tmp = await fetchPlaceOfBusinessInventory(e.id);
                    acc.push({
                        companyName: e.companyName,
                        ...tmp
                    });
                }

                setEntries([...acc]);
            })();
        }
    }, [inv]);

    return (
        <CustomModal
            fullScreen={true}
            open={open}
            title={lang.CAR_INVENTORY[language]}
            content={
                <Grid container spacing={2}>
                    {
                        entries.length > 0 && entries.map((x, i) => <Grid key={`inv_${i}`} item xs={12}>
                            {x.companyName}
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={12}>
                                {x && x.data.map((y, index) => <InventoryItem key={`inv_entry_${index}`}  {...y} />)}
                                {x.data.length === 0 &&
                                    <Grid item xs={12}>
                                        <Paper spacing={2} elevation={2} style={{ padding: "10px", margin: "10px" }}>
                                            <Typography varaint="h6" color="secondary" align={"center"}>
                                                {lang.NO_INVENTORY_ITEMS[language].toUpperCase()}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>)
                    }
                    {
                        entries.length === 0 &&
                        <Grid container direction="row" justifyContent="center" alignContent="center" style={{ marginTop: "10px" }}>
                            <Typography varaint="h4">
                                {lang.TEAM_NOT_SELECTED_TODAY[language]}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            }
            close={() => onClose()}
            language={language}
            onlyBack
            noButtons
        />
    )
}

export default InventoryModal;
