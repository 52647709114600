import React from 'react';
import { TextField, Grid, MenuItem, InputLabel, FormControl, Select, FormHelperText, makeStyles, InputAdornment, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
    formControl: {
        // marginTop: theme.spacing(2),
    },
}));

export default function ContractNewForm(props) {
    const classes = useStyles();
    const { addData, language, updateField, updateFieldRaw } = props;

    const inputLabelType = React.useRef(null);
    const [labelWidthType, setLabelWidthType] = React.useState(35);
    const inputLabelSubType = React.useRef(null);
    const [labelWidthSubType, setLabelWidthSubType] = React.useState(35);
    const inputLabelExec = React.useRef(null);
    const [labelWidthExec, setLabelWidthExec] = React.useState(35);
    // const inputLabelArea = React.useRef(null);
    // const [labelWidthArea, setLabelWidthArea] = React.useState(35);
    const inputLabelAssign = React.useRef(null);
    const [labelWidthAssign, setLabelWidthAssign] = React.useState(35);
    const inputLabelBilling = React.useRef(null);
    const [labelWidthBilling, setLabelWidthBilling] = React.useState(35);

    const [typeList, setTypeList] = React.useState([]);
    const [execList, setExecList] = React.useState([]);
    const [areaList, setAreaList] = React.useState([]);
    const [assigneeList, setAssigneeList] = React.useState([]);

    function updateType(e) {
        const { value } = e.target;
        updateFieldRaw('TYPE', value);
        updateFieldRaw('SUB_TYPE', 1);
        updateFieldRaw('EXECUTOR', "");
    }
    function updateSubType(e) {
        const { value } = e.target;
        updateFieldRaw('SUB_TYPE', value);
        updateFieldRaw('EXECUTOR', "");
    }
    
    function getNumberOfMinuts() {
        let i = 15;
        let menuItemList = [];

        for (i; i < 45; i++) {
            menuItemList.push(
                <MenuItem key={i} value={i}>{`${i} min`}</MenuItem>
            )
        };
        return menuItemList
    }

    React.useEffect(() => {
        setLabelWidthType(inputLabelType.current.offsetWidth);
        setLabelWidthSubType(inputLabelSubType.current.offsetWidth);
        setLabelWidthExec(inputLabelExec.current.offsetWidth);
        setLabelWidthAssign(inputLabelAssign.current.offsetWidth);
        setLabelWidthBilling(inputLabelBilling.current.offsetWidth);

        if (typeList.length === 0) {
            fetch(`/api/contract_types`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setTypeList(response);
                })
                .catch(err => console.log(err))
        }

        if (assigneeList.length === 0) {
            fetch(`/api/contract_assignee_list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setAssigneeList(response);
                })
                .catch(err => console.log(err))
        }

        if (areaList.length === 0) {
            fetch(`/api/areas_active`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setAreaList(response);
                })
                .catch(err => console.log(err))
        }
    }, []);

    React.useEffect(() => {
        if (addData.TYPE && addData.SUB_TYPE)
            fetch(`/api/executors_valid?type=${addData.TYPE}&subtype=${addData.SUB_TYPE}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setExecList(response.data);
                })
                .catch(err => console.log(err))
    }, [addData.SUB_TYPE])

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelType}>{lang.TYPE[language]}</InputLabel>
                    <Select
                        name="TYPE"
                        labelWidth={labelWidthType}
                        value={addData.TYPE ? addData.TYPE : ""}
                        onChange={updateType}
                    >
                        {typeList && typeList.length > 0 && typeList.filter(type => type.TYPE === 1).map(type => {
                            return <MenuItem key={type.ID} value={type.SUB_TYPE}>{lang[type.DESCRIPTION_TEXT][language]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelSubType}>{lang.SUBTYPE[language]}</InputLabel>
                    <Select
                        name="SUB_TYPE"
                        labelWidth={labelWidthSubType}
                        value={addData.SUB_TYPE ? addData.SUB_TYPE : ""}
                        onChange={updateSubType}
                    >
                        {typeList && typeList.length > 0 && typeList.filter(subtype => subtype.SUB_TYPE === addData.TYPE && subtype.TYPE === 2).map(subtype => {
                            return <MenuItem key={subtype.ID} value={subtype.CODE}>{lang[subtype.DESCRIPTION_TEXT][language]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelExec}>{lang.EXEC_NAME2[language]}</InputLabel>
                    <Select
                        name="CONTRACTOR_OBJID"
                        labelWidth={labelWidthExec}
                        value={addData.CONTRACTOR_OBJID ? addData.CONTRACTOR_OBJID : ""}
                        onChange={(e) => updateFieldRaw('CONTRACTOR_OBJID', e.target.value)}
                    >
                        {execList && execList.length > 0 && execList.map(exec => {
                            return <MenuItem key={exec.OBJID} value={exec.OBJID}>{exec.NAME}</MenuItem>
                        })}
                    </Select>
                    {execList.length === 0 &&
                        <FormHelperText>{lang.NO_EXECUTOR_AVAILABLE[language]}</FormHelperText>
                    }
                </FormControl>
            </Grid>

            {
                // for the contex please see the table_lookup_map table from the DB
                (addData.TYPE && addData.TYPE === 1 && addData.SUB_TYPE && addData.SUB_TYPE === 2 ||
                    addData.TYPE && addData.TYPE === 1 && addData.SUB_TYPE && addData.SUB_TYPE === 3) &&
                <Grid item xs={12} sm={6} >
                    <FormControl
                        required
                        fullWidth
                        disabled
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <TextField
                            select
                            disabled
                            name="INTERVENTION_TIME"
                            variant='outlined'
                            label={lang.MINIMUM_INTERVETION_TIME[language]}
                            value={addData.INTERVENTION_TIME ? addData.INTERVENTION_TIME : ""}
                            onChange={(e) => updateFieldRaw('INTERVENTION_TIME', e.target.value)}
                        >
                            {
                                getNumberOfMinuts()
                            }
                        </TextField>
                    </FormControl>
                </Grid>
            }

            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>


            <Grid item xs={12} sm={6} >
                <TextField
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    name="CONTRACT_NO"
                    label={lang.CONTRACT_NUMBER[language]}
                    value={addData.CONTRACT_NO ? addData.CONTRACT_NO : ""}
                    onChange={(e) => props.updateField(e, 50)}
                    type="number"
                    className={classes.formControl}
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelBilling}>{lang.BILL_CYCLE[language]}</InputLabel>
                    <Select
                        name="BILL_CYCLE"
                        labelWidth={labelWidthBilling}
                        value={addData.BILL_CYCLE ? addData.BILL_CYCLE : ""}
                        onChange={(e) => updateFieldRaw('BILL_CYCLE', e.target.value)}
                        disabled={true}
                    >
                        <MenuItem value={1}>{lang.BILL_CURRENT_MONTH[language]}</MenuItem>
                        <MenuItem value={-1}>{lang.BILL_LAST_MONTH[language]}</MenuItem>
                        <MenuItem value={3}>{lang.BILL_QUARTER[language]}</MenuItem>
                        <MenuItem value={2}>{lang.BILL_SEMESTER[language]}</MenuItem>
                        <MenuItem value={4}>{lang.BILL_YEARLY[language]}</MenuItem>
                    </Select>
                    {execList.length === 0 &&
                        <FormHelperText>{lang.NO_EXECUTOR_AVAILABLE[language]}</FormHelperText>
                    }
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12} sm={6} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        required
                        disabled={true}
                        inputVariant="outlined"
                        fullWidth
                        name="START_DATE"
                        label={lang.CONTRACT_START_DATE[props.language]}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        value={addData.START_DATE && addData.START_DATE}
                        className={classes.formControl}
                        onChange={(value) => updateFieldRaw('START_DATE', value)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        // disabled={addData.INDETERMINATE}
                        disabled={true}
                        required={!addData.INDETERMINATE}
                        inputVariant="outlined"
                        fullWidth
                        name="END_DATE"
                        label={lang.CONTRACT_END_DATE[props.language]}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        value={addData.END_DATE && addData.END_DATE}
                        className={classes.formControl}
                        onChange={(value) => updateFieldRaw('END_DATE', value)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} >
                <FormControlLabel
                    disabled={true}
                    style={{ margin: 10 }}
                    control={<Checkbox checked={addData.INDETERMINATE === true} value={addData.INDETERMINATE} onChange={() => updateFieldRaw('INDETERMINATE', !addData.INDETERMINATE)} />}
                    label={lang.CONTRACT_INDETERMINATE[props.language]}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12} sm={6} >
                <TextField
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    name="VALUE"
                    label={lang.CONTRACT_VALUE[language]}
                    value={addData.VALUE ? addData.VALUE : ""}
                    onChange={(e) => props.updateField(e, 50)}
                    type="number"
                    className={classes.formControl}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">RON</InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <TextField
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    name="TRUCK_ROLL_VALUE"
                    label={lang.TRUCK_ROLL_VALUE[language]}
                    value={addData.TRUCK_ROLL_VALUE ? addData.TRUCK_ROLL_VALUE : ""}
                    onChange={(e) => props.updateField(e, 50)}
                    type="number"
                    className={classes.formControl}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">RON</InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <TextField
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    name="FREE_TRUCK_ROLLS"
                    label={lang.FREE_TRUCK_ROLLS[language]}
                    value={addData.FREE_TRUCK_ROLLS ? addData.FREE_TRUCK_ROLLS : ""}
                    onChange={(e) => props.updateField(e, 50)}
                    type="number"
                    className={classes.formControl}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    disabled={true}
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelAssign}>{lang.ASSIGNED_TEAM[language]}</InputLabel>
                    <Select
                        name="CONTRACT_ASSIGNEE"
                        labelWidth={labelWidthAssign}
                        value={addData.ASSIGNED_TEAM ? addData.ASSIGNED_TEAM : ""}
                        onChange={(e) => updateFieldRaw('ASSIGNED_TEAM', e.target.value)}
                    >
                        {areaList && areaList.length > 0 && areaList.map(item => {
                            return <MenuItem key={item.AREA_CODE} value={item.AREA_CODE}>{item.AREA}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} >
                <TextField
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    name="NFC"
                    label={lang.NFC[language]}
                    value={addData.NFC ? addData.NFC : ""}
                    onChange={(e) => props.updateField(e, 150)}
                    className={classes.formControl}
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelAssign}>{lang.ASSIGNEE[language]}</InputLabel>
                    <Select
                        name="CONTRACT_ASSIGNEE"
                        labelWidth={labelWidthAssign}
                        value={addData.CONTRACT_ASSIGNEE ? addData.CONTRACT_ASSIGNEE : ""}
                        onChange={(e) => updateFieldRaw('CONTRACT_ASSIGNEE', e.target.value)}
                    >
                        {assigneeList && assigneeList.length > 0 && assigneeList.map(item => {
                            return <MenuItem key={item.OBJID} value={item.OBJID}>{item.USERNAME}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            {/* 
            <Grid item xs={12} sm={6} >
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel ref={inputLabelArea}>{lang.AREA_NAME[language]}</InputLabel>
                    <Select
                        name="AREA"
                        labelWidth={labelWidthArea}
                        value={addData.AREA ? addData.AREA : ""}
                        onChange={(e) => updateFieldRaw('AREA', e.target.value)}
                    >
                        {areaList && areaList.length > 0 && areaList.map(item => {
                            return <MenuItem key={item.AREA_CODE} value={item.AREA_CODE}>{item.AREA}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid> */}
        </React.Fragment>
    );
}
