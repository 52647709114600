import { useEffect, useState } from "react";
import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import axios from "axios";

import * as lang from "../../Constants/strings";
import * as reqHeaders from "../../Constants/requestHeaders";
import AddItem from "./AddItem";

const SearchItemByName = (props) => {
    const {
        setValue,
        placeOfBusinessId,
        userId,
        language
    } = props;

    const [items, setItems] = useState([]);
    const [name, setName] = useState("");

    const fetch = async () => {
        try {
            const res = await axios.get('/api/external/placeOfBusiness/inventoryByName/' + name, {
                ...reqHeaders.getGetHeader(),
                params: {
                    placeOfBusinessId,
                    id: userId
                }
            });

            if (res.data) setItems([...res.data.data]);
        } catch (e) {
            console.log(e);
        }
    }

    const selectItem = (item) => {
        setValue(item);
        setName("");
        setItems([])
    }

    useEffect(() => {
        if (name.length > 3) fetch().then(() => { });
    }, [name]);

    return (
        <div>
            <Typography>
                {lang.SEARCH_ITEM_BY_NAME[language]}
            </Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <TextField
                fullWidth
                variant="outlined"
                placeholder={lang.SEARCH_ITEM_BY_NAME[language]}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {items.length > 0 && <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />}
            <Grid container spacing={2} style={{ maxHeight: "350px", overflowY: "scroll" }}>
                {
                    items.map((x, index) => <Grid item xs={12}>
                        <AddItem
                            key={`add_item_${index}`}
                            productName={x.productName}
                            um={x.um}
                            quantity={x.quantity}
                            onClick={() => selectItem(x)}
                        />
                    </Grid>)
                }
            </Grid>
        </div>
    )
}

export default SearchItemByName;
