import React, {useState} from "react";
import CustomModal from "./CustomModal";
import {CircularProgress, Grid, TextField} from "@material-ui/core";
import ImageUploadField from "../ImageUploadField";
import { OBSERVATION } from "../../Constants/strings";

/**
 * A modal that will let us standardize the closing of a task
 * with an image and a commentary
 *
 * @param open boolean
 * @param close: function that will close the modal
 * @param title: the id of the maintenance
 * @param loading: boolean
 * @param execute: function to execute, receives as argument the { image, observation };
 * @param language: the current language ("ro" | "en")
 *
 * @returns {JSX.Element}
 */
const CloseTaskWithImage = (props) => {
    const [image, setImage] = useState(null);
    const [observation, setObservation] = useState('');

    const execute = async () => {
        props.execute({
            image,
            observation
        });
    }

    return (
        <CustomModal
            fullWidth={false}
            open={props.open}
            close={() => props.close()}
            title={props.title}
            language={props.language}
            content={
                <Grid conatiner spacing={2} style={{ position: "relative", height: "100%"}}>
                    {props.loading &&
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                background: "#ffffff50",
                                position: "absolute",
                                zIndex: 99,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    }
                    <TextField
                        label={OBSERVATION[props.language]}
                        variant="outlined"
                        value={observation}
                        fullWidth
                        onChange={(e) => setObservation(e.target.value)}
                    />
                    <ImageUploadField
                        onImageLoad={(data) => setImage(data)}
                        language={props.language}
                        loading={true}
                        maxWidth={550}
                    />
                </Grid>
            }
            execute={() => execute()}
            validate={!!image}
        />
    )
}

export default CloseTaskWithImage;