import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
    makeStyles,
    TextField,
    Grid,
    Paper,
    Typography,
    Grow,
    Chip,
    IconButton,
    useMediaQuery,
    useTheme,
    Tooltip
} from '@material-ui/core';
import {
    PhoneRounded,
    EmailRounded,
    Note,
    LocationOn,
    RemoveFromQueue,
    VpnKey,
    ReceiptRounded,
    SettingsRounded,
    RemoveCircleRounded, Image
} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from "axios";
import ImageIcon from '@material-ui/icons/Image';

import * as lang from '../../../Constants/strings';
import {addressToString} from '../../../Functions/objectManipulation';
import CustomDrawer from '../../../Components/Modals/CustomDrawer';
import {EditRounded} from '@material-ui/icons';
import CustomModal from '../../../Components/Modals/CustomModal';
import CustomButtonList from '../../../Components/Modals/CustomButtonList';
import {checkPermissions} from '../../../Functions/permissionHide';
import Can from '../../../Components/Misc/Can';
import getGoogleMapLink, {getGoogleMapRouteLink} from '../../../Functions/getGoogleMapLink';
import history from "../../../history";
import * as requestHeaders from "../../../Constants/requestHeaders";
import {getGetHeader} from "../../../Constants/requestHeaders";

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-01.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
        height: '100%',
    },
    paperCust: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
        height: '100%',
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
    links: {
        textDecoration: "none",
        color: 'white',
    },
    title: {
        margin: theme.spacing(3),
    },
    subtitle: {
        margin: theme.spacing(1),
    },
    accountBadge: {
        margin: theme.spacing(1),
        fontSize: '20px',
        padding: '5px',
        height: '50px',
        borderRadius: '25px'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

function SiteSettingsModal(props) {
    const {site, tiny, data, language, classes, userPermissions, isAuthenticated} = props;

    const [siteDeleteModal, setSiteDeleteModal] = useState(false);
    const [key, setKey] = useState("");

    const deleteSite = async () => {
        try {
            await axios.delete('/api/site', {
                headers: {
                    authorization: `Beare ${key}`
                },
                params: {
                    id: data.OBJID
                }
            });
            history.push("/sites");
        } catch (e) {
            console.error(e);
        }
        ;
    };

    return (
        <React.Fragment>
            {
                props.isAuthenticated && checkPermissions(props.userPermissions, {
                    parent: "SUPER",
                    name: "SUPER_DELETE"
                }) &&
                <CustomModal
                    open={siteDeleteModal}
                    fullWidth
                    close={() => setSiteDeleteModal(false)}
                    content={
                        <Grid alignItems="center" container spacing={2}>
                            <TextField
                                value={key}
                                fullWidth
                                variant="outlined"
                                onChange={e => setKey(e.target.value)}
                                placeholder="Key-ul de ștergere"
                            />
                        </Grid>
                    }
                    language={props.language}
                    execute={deleteSite}
                    validate={key.length > 0}
                />
            }
            <CustomDrawer
                open={site.settingsModal && tiny}
                close={site.closeSettingsModal}
                list={[
                    {
                        primary: lang.EDIT_SITE[language],
                        secondary: "",
                        icon: <EditRounded/>,
                        action: site.openEditModal,
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                            parent: "SITE",
                            name: "EDIT_SITE"
                        }) === true,
                    },
                    {
                        primary: lang.SYSTEM_LOGING_INFO[language],
                        secondary: "",
                        icon: <VpnKey/>,
                        action: site.openSystemLoginInfoModal,
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                            parent: "SITE",
                            name: "SITE_ADD_SYSTEM_LOGIN_INFO"
                        }) === true,
                    },
                    {
                        primary: lang.DELETE_ACCOUNT[language],
                        secondary: "",
                        icon: <RemoveCircleRounded/>,
                        action: site.openRemoveSiteAccountModal,
                        disabled: data.ACTIVE === "Activ" || data.SITE_CODE === null,
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                            parent: "SITE",
                            name: "EDIT_SITE"
                        }) === true,
                    },
                    {
                        primary: lang.SITE_CENTRAL_INFO[language],
                        secondary: "",
                        icon: <VpnKey/>,
                        action: props.openCentralInfo,
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                            parent: "SITE",
                            name: "SITE_VIEW_CENTRAL_INFO"
                        }) === true,
                    },
                    {
                        primary: lang.INFO_PAPERS[language],
                        secondary: "",
                        icon: <Note/>,
                        action: props.openInfoPapers,
                        render: true
                    }
                ]}
            />
            <CustomModal
                open={site.settingsModal && !tiny}
                title={lang.QUICK_SETTINGS[language]}
                content={
                    <Grid container spacing={2}>
                        <CustomButtonList
                            close={site.closeSettingsModal}
                            language={language}
                            tiny={tiny}
                            list={[
                                {
                                    text: lang.EDIT_SITE[language],
                                    style: {
                                        icon: <EditRounded className={classes.leftIcon}/>,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: site.openEditModal,
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                                        parent: "SITE",
                                        name: "EDIT_SITE"
                                    }) === true,
                                },
                                {
                                    text: lang.SYSTEM_LOGING_INFO[language],
                                    style: {
                                        icon: <VpnKey className={classes.leftIcon}/>,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: site.openSystemLoginInfoModal,
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                                        parent: "SITE",
                                        name: "SITE_ADD_SYSTEM_LOGIN_INFO"
                                    }) === true,
                                },
                                {
                                    text: lang.DELETE_ACCOUNT[language],
                                    style: {
                                        icon: <RemoveCircleRounded className={classes.leftIcon}/>,
                                        color: "secondary",
                                        variant: "contained",
                                    },
                                    action: site.openRemoveSiteAccountModal,
                                    disabled: data.ACTIVE === "Activ" || data.SITE_CODE === null,
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                                        parent: "SITE",
                                        name: "EDIT_SITE"
                                    }) === true,
                                },
                                {
                                    text: lang.SITE_CENTRAL_INFO[language],
                                    style: {
                                        icon: <VpnKey className={classes.leftIcon}/>,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: props.openCentralInfo,
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                                        parent: "SITE",
                                        name: "SITE_VIEW_CENTRAL_INFO"
                                    }) === true,
                                },
                                {
                                    text: "Șterge Obiectiv",
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, {
                                        parent: "SUPER",
                                        name: "SUPER_DELETE"
                                    }) === true,
                                    style: {
                                        icon: <RemoveCircleRounded className={classes.leftIcon}/>,
                                        color: "secondary",
                                        variant: "contained"
                                    },
                                    action: () => setSiteDeleteModal(true)
                                },
                                {
                                    action: props.openInfoPapers,
                                    style: {
                                        icon: <Note className={classes.leftIcon}/>,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    render: true,
                                    text: lang.INFO_PAPERS[language],
                                }
                            ]}
                            withBack
                        />
                    </Grid>
                }
                close={site.closeSettingsModal}
                execute={site.closeSettingsModal}
                noButtons
                validate={true}
                language={language}
            />
        </React.Fragment>
    )
}

const SiteMain = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const img = useRef();
    const [extra, setExtra] = useState('');
    const [imgLink, setImgLink] = useState(null);
    const {data, language, openSettingsModal, site, tiny} = props;

    useEffect(() => {
        fetchData();
    }, [props.data]);

    const fetchData = useCallback(
        async () => {
            try {
                const result = await axios.get('/api/site_extra', {
                    ...requestHeaders.getGetHeader(),
                    params: {
                        siteId: props.data.OBJID
                    }
                });
                setExtra(result.data);
                getSiteImage();
            } catch (e) {
                console.error(e);
            }
        },
        [props.data]
    );

    return (
        <React.Fragment>
            {site.loading
                ?
                <SkeletonSiteMain classes={classes}/>
                :
                <Grid item xs={12} md={8} style={{
                    paddingRight: sm ? '0px' : '16px',
                    transition: theme.transitions.create("all", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    })
                }}>
                    <SiteSettingsModal
                        site={site}
                        data={data}
                        tiny={tiny}
                        language={language}
                        classes={classes}
                        userPermissions={props.userPermissions}
                        isAuthenticated={props.isAuthenticated}
                        openCentralInfo={props.openCentralInfo}
                        openInfoPapers={props.openInfoPapers}
                    />
                    <Grow in={true} style={{transitionDelay: true ? '100ms' : '0ms'}}>
                        <Paper className={classes.paper}>
                            <Grid container justify="center" alignItems="center" direction="column">
                                <Grid container justify="center" alignItems="center" direction="row">
                                    {data && <Tooltip className={classes.indicator}
                                                      title={data && data.ACTIVE === 1 ? "Activ" : "Inactiv"}>
                                        <Typography className={classes.title} variant="h4"
                                                    color={data && data.ACTIVE === 1 ? "inherit" : "secondary"}>{data.SITE_NAME}</Typography>
                                    </Tooltip>}
                                    <IconButton onClick={openSettingsModal}>
                                        <SettingsRounded/>
                                    </IconButton>
                                    <Can neededPermissions={{parent: "SITE", name: "EDIT_SITE"}}>
                                        <IconButton aria-label="equipment" onClick={() => props.openModalEquipment()}>
                                            <RemoveFromQueue/>
                                        </IconButton>
                                    </Can>
                                    <IconButton onClick={props.setAddSiteImageModal}>
                                        <ImageIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    {
                                        data &&
                                        <Chip
                                            className={classes.accountBadge}
                                            color="secondary"
                                            label={`${lang.SITE[language]} #${data.SITE_CODE}`}
                                        />
                                    }
                                    {
                                        data &&
                                        data.EMAIL &&
                                        <Chip
                                            className={classes.subtitle}
                                            icon={<EmailRounded fontSize="small"/>}
                                            color="primary"
                                            label={
                                                <a
                                                    className={classes.links}
                                                    href={`mailto:${data.EMAIL}`}
                                                >
                                                    {data.EMAIL}
                                                </a>
                                            }
                                        />
                                    }
                                    {
                                        data &&
                                        <Chip
                                            className={classes.subtitle}
                                            color="primary"
                                            label={`${lang.SITE_CONTACT_NAME[language]}: ${data.SITE_CONTACT_NAME} `}
                                        />
                                    }
                                    {
                                        data &&
                                        data.PHONE &&
                                        <Chip
                                            className={classes.subtitle}
                                            icon={<PhoneRounded fontSize="small"/>}
                                            color="primary"
                                            label={
                                                <a
                                                    className={classes.links}
                                                    href={`tel:${data.CONTACT_PHONE}`}
                                                >
                                                    {`${lang.CONTACT_PHONE[props.language]}: ${data.CONTACT_PHONE}`}
                                                </a>
                                            }
                                        />}
                                </Grid>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    {
                                        data &&
                                        data.LATITUDE &&
                                        data.LONGITUDE &&
                                        <Chip
                                            className={classes.subtitle}
                                            icon={<LocationOn fontSize="small"/>}
                                            color="primary"
                                            label={
                                                <a
                                                    className={classes.links}
                                                    target="_blank"
                                                    href={getGoogleMapLink(data.LATITUDE, data.LONGITUDE)}
                                                >
                                                    {lang.GPS[language]}
                                                </a>}
                                        />
                                    }
                                    {
                                        data &&
                                        <Chip
                                            className={classes.subtitle}
                                            icon={<ReceiptRounded fontSize="small"/>}
                                            color="secondary"
                                            label={
                                                <a
                                                    className={classes.links}
                                                    target="_blank"
                                                    href={getGoogleMapRouteLink(addressToString(data, 3))}
                                                >
                                                    {addressToString(data, 1)}
                                                </a>}
                                        />
                                    }
                                    {
                                        data &&
                                        data.SITE_LANDMARKS &&
                                        <Chip
                                            className={classes.subtitle}
                                            color="primary"
                                            label={`${lang.SITE_LANDMARKS[language]}: ${data.SITE_LANDMARKS} `}
                                        />
                                    }
                                    {
                                        data &&
                                        data.OBS &&
                                        <Chip
                                            className={classes.subtitle}
                                            color="primary"
                                            label={`${lang.OBSERVATION[language]}: ${data.OBS}`}
                                        />
                                    }
                                    {
                                        //   evaluation: evaluations[0],
                                        //   permit: permits[0],
                                        //   project: projects[0]
                                        extra &&
                                        <>
                                            {extra.extra &&
                                                <Chip
                                                    className={classes.subtitle}
                                                    color="primary"
                                                    label={`${lang.SITE_LANDMARKS[language]}: ${data.SITE_LANDMARKS} `}
                                                />
                                            }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grow>
                </Grid>
            }
        </React.Fragment>
    );
}

export function SkeletonSiteMain(props) {
    const {classes} = props;
    return (
        <Grid item xs={12} md={8} style={{paddingRight: '0px'}}>
            <Skeleton component={Paper} className={classes.paperClear}>
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="15%" className={classes.title}/>
                    <Grid container justify="center" alignItems="center" direction="column">
                        <Skeleton className={classes.subtitle} width='50%'/>
                        <Skeleton className={classes.subtitle} width='50%'/>
                    </Grid>
                </Grid>
            </Skeleton>
        </Grid>
    );
}

export default SiteMain;