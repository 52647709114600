import React from 'react';
import { CssBaseline, makeStyles, Checkbox, Grid, FormControlLabel, TextField, Typography, Divider } from '@material-ui/core';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as lang from '../../../../Constants/strings';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import axios from 'axios';
import { checkSpecialChar } from '../../../../Functions/stringManipulation';

const useStyles = makeStyles(theme => ({
    root: {

    },
    textField: {
        margin: theme.spacing(2, 2, 0, 0),
    },
    divider: {
        margin: theme.spacing(4, 2, 2, 2)
    },
    chip: {
        margin: 2,
    },
    clear: {
        position: 'absolute',
        right: 0,
    },
}));

export default function AreaEditModal(props) {
    const classes = useStyles();
    const { open, close, execute, language, addData, updateField, error, updateError } = props;

    const [errorText, setError] = React.useState();

    function checkNameAvailability(name) {
        if (name)
            axios.get(`/api/check_department?name=${name}`)
                .then(res => {
                    if (parseInt(res.data, 10) > 0 && parseInt(res.data, 10) !== 0) {
                        setError("VALUE_EXISTS");
                        updateError(parseInt(res.data, 10) > 0);
                    }
                    if (parseInt(res.data, 10) === 0) {
                        setError(null);
                        updateError(parseInt(res.data, 10) > 0);
                    }
                    if (checkSpecialChar(name.trim(), "*|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        updateError(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });
        else {
            setError("EMPTY_FIELD");
            updateError(true);
        }
    };

    function focus() {
        updateError(null);
        setError(null);
    };

    React.useEffect(() => {
        setError('VALUE_EXISTS');
        updateError(true);
    }, [addData.DEPARTMENT]);

    const updateAreaStatus = (e) => {
        e.persist();
        console.log(e.target.value)
        if (Number(e.target.value) === 1) {
            e.target.value = 0;
        } else {
            e.target.value = 1;
        }
        props.updateField(e, 1)
    };

    const changeValue = (e) => {
        e.persist();
        checkNameAvailability(e.target.value);
        props.updateField(e, 50);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <CustomModal
                fullWidth
                open={open}
                title={lang.EDIT_DEPARTMENT[language]}
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ position: 'relative' }}>
                                <Typography className={classes.textField} variant='h5'>{lang.DEPARTMENT_NAME[language]}</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    className={classes.textField}
                                    name="DEPARTMENT"
                                    value={addData.DEPARTMENT}
                                    onChange={(e) => changeValue(e)}
                                    onFocus={() => focus()}
                                    error={error}
                                    helperText={errorText ? lang[errorText][language] : null}
                                    onBlur={() => checkNameAvailability(addData.DEPARTMENT)}
                                />
                                {error === true
                                    ?
                                    <ErrorRoundedIcon className={classes.clear} color="secondary" />
                                    :
                                    error === false
                                        ?
                                        <CheckCircleRoundedIcon className={classes.clear} />
                                        :
                                        null
                                }
                                <Divider className={classes.divider} />
                                <Typography className={classes.textField} variant='h5'>{lang.DETAILS[language]}</Typography>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            checked={Number(addData.ACTIVE) === 1}
                                            name='ACTIVE'
                                            value={addData.ACTIVE}
                                            onChange={(e) => updateAreaStatus(e)}
                                        />
                                    }
                                    label="Activ"
                                />
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            checked={Number(addData.CONTRACT_ASSIGNEE) === 1}
                                            name='CONTRACT_ASSIGNEE'
                                            value={addData.CONTRACT_ASSIGNEE}
                                            onChange={(e) => updateAreaStatus(e)}
                                        />
                                    }
                                    label={lang.CONTRACT_ASSIGNEE[language]} />
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            checked={Number(addData.DISPATCH_ASSIGNEE) === 1}
                                            name='DISPATCH_ASSIGNEE'
                                            value={addData.DISPATCH_ASSIGNEE}
                                            onChange={(e) => updateAreaStatus(e)}
                                        />
                                    }
                                    label={lang.DISPATCH_ASSIGNEE[language]} />
                            </div>
                        </Grid>
                    </Grid>
                }
                close={close}
                execute={() => execute()}
                validate={!error}
                language={language}
            />
        </React.Fragment>
    )
}