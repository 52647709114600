import { useState } from 'react';
import { makeStyles, Paper, InputBase } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ScanningModal from "./ScanningModal";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        border: "1px solid #ffffff40"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const BarCodeInput = (props) => {
    const {
        value,
        onChange,
        placeholder,
        language
    } = props;

    const classes = useStyles();

    const [barCodeModal, setBarCodeModal] = useState(false);

    return (
        <Paper className={classes.root} elevation={2}>
            <IconButton className={classes.iconButton} aria-label="menu" onClick={() => setBarCodeModal(true)}>
                <CropFreeIcon />
            </IconButton>
            <InputBase
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'scanner' + JSON.stringify(placeholder ?? "") }}
            />
            {barCodeModal &&
                <ScanningModal
                    isOpen={barCodeModal}
                    onClose={() => setBarCodeModal(false)}
                    onRead={(e) => {
                        onChange(e);
                        setBarCodeModal(false);
                    }}
                    language={language}
                />
            }
        </Paper>
    );
}

export default BarCodeInput;
