import React, { Component, useState } from 'react';
import * as lang from '../../Constants/strings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';
import {
    CssBaseline,
    useTheme,
    useMediaQuery,
    Grid,
    makeStyles,
    Paper,
    Checkbox,
    FormControlLabel,
    Button
} from '@material-ui/core';
import {
    VisibilityRounded,
    ArrowForwardIos,
    ArrowBackIos,
    History
} from "@material-ui/icons";
import { extractQueryFrontend } from '../../Functions/stringManipulation';
import CustomerMain, { SkeletonCustomerMain } from './CustomerSingleViewComponents/CustomerMain';
import CustomerSites, { SkeletonCustomerSites } from './CustomerSingleViewComponents/CustomerSites';
import AddressNewForm from './CustomerViewComponents/AddressNewForm';
import { custAddress, custSite, custData } from '../../Constants/forms';
import CustomModal from '../../Components/Modals/CustomModal';
import CustomDrawer from '../../Components/Modals/CustomDrawer';
import SiteNewForm from './CustomerSingleViewComponents/SiteNewForm';
import { siteAddFormat } from '../../Functions/objectManipulation';
import CustomerNewForm from './CustomerViewComponents/CustomerNewForm';
import CustomButtonList from '../../Components/Modals/CustomButtonList';
import { redirectTo } from '../../Functions/linkFunctions';
import { checkPermissions } from '../../Functions/permissionHide';
import CustomerTabs from './CustomerSingleViewComponents/CustomerTabs';
import CustomerSiteIntervention from './CustomerViewComponents/CustomerSiteIntervention';
import SiteInterventionModal from './CustomerSingleViewComponents/SiteInterventionModal';
import ContractSelect from './CustomerSingleViewComponents/Components/ContractSelect';
import ChangeCutomerStatusModal from './CustomerSingleViewComponents/ChangeCutomerStatusModal';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ro, enUS } from 'date-fns/esm/locale';


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    table: {
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    filterPaper: {
        padding: "5px",
        width: '75px',
        height: '50px',
        transition: "width 1s ease",
        overflow: 'hidden',
        marginBottom: "10px"
    },
    bigger: {
        width: '100%',
        padding: "5px",
        transition: "width 1s ease",
        overflow: 'hidden',
        marginBottom: "10px"
    },
    checkbox: {
        padding: "0px 10px",
    },
}));

function CustomerSingleSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        data,
        language,
        filterForActiveSites,
        setFilterForActiveSites,
        filterForValidateSites,
        setFilterForValidatedSites,
        filterOpen,
        setFilterOpen
    } = props;
    const [selectedTab, seSelectedTab] = React.useState(0);

    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <CustomerMain
                customer={props.customer}
                data={data.customer}
                address={data.address}
                sites={data.site}
                language={language}
                tiny={tiny}
                openSettingsModal={props.customer.openSettingsModal}
                userPermissions={props.permissions}
            />
            <CustomerTabs userPermissions={props.permissions} selectedIndex={selectedTab} handelIndexChange={(value) => seSelectedTab(value)} language={language} />
            {
                selectedTab === 0 ? <Grid container className={classes.table}>
                    <Grid item xs={12}>
                        <Paper className={filterOpen ? classes.bigger : classes.filterPaper}>
                            <Grid container>
                                <Button style={{ height: '40px' }} onClick={() => setFilterOpen(!filterOpen)}>
                                    {filterOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
                                </Button>
                                    {filterOpen &&
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filterForActiveSites === 'active'}
                                                        onClick={() => setFilterForActiveSites('active')}
                                                    />
                                                }
                                                label={lang.ACTIVE_SITES[language]}
                                                className={classes.checkbox}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filterForActiveSites === 'inactive'}
                                                        onClick={() => setFilterForActiveSites('inactive')}
                                                    />
                                                }
                                                label={lang.INACTIVE_SITES[language]}
                                                className={classes.checkbox}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filterForValidateSites === 'validate'}
                                                        onClick={() => setFilterForValidatedSites('validate')}
                                                    />
                                                }
                                                label={lang.VALIDATE_SITES[language]}
                                                className={classes.checkbox}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filterForValidateSites === 'invalidate'}
                                                        onClick={() => setFilterForValidatedSites('invalidate')}
                                                    />
                                                }
                                                label={lang.NOVALIDATE_SITES[language]}
                                                className={classes.checkbox}
                                            />
                                        </>
                                    }
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerSites
                            data={data.site}
                            language={language}
                            tiny={tiny}
                            openAddModal={props.site.openAddModal}
                            openRowModal={props.site.openSettingsModal}
                        />
                    </Grid>
                </Grid> :
                    <Grid item xs={12}>
                        <CustomerSiteIntervention customerId={data.customer.ID} language={language} />
                    </Grid>
            }
        </Grid>
    );
}

function PlaceholderSkeleton() {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <CssBaseline />
            <SkeletonCustomerMain classes={classes} />
            <SkeletonCustomerSites classes={classes} />
        </Grid>
    );
}

function CustomerSettingsModal(props) {
    const { language, close, selectedIndex, open } = props;
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();
    return (
        <React.Fragment>
            <CustomDrawer
                open={open && tiny}
                close={close}
                list={[
                    {
                        primary: lang.GO_TO(lang.SITE[language])[language],
                        secondary: "",
                        icon: <VisibilityRounded />,
                        action: () => { redirectTo(`/site?id=${selectedIndex}`) },
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "SITE", name: "VIEW_SITE" }) === true ? true : false,
                    },
                    {
                        primary: lang.VIEW_SITE_ALARMS[language],
                        secondary: "",
                        icon: <History />,
                        action: () => props.openSiteInterventon(),
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "VIEW_SITE_INTERVENTION" }) === true ? true : false,
                    },
                ]}
            />
            <CustomModal
                open={open && !tiny}
                title={lang.SITE_DETAILS[language]}
                content={
                    <Grid container spacing={2}>
                        <CustomButtonList
                            close={close}
                            language={language}
                            tiny={tiny}
                            list={[
                                {
                                    text: lang.GO_TO(lang.SITE[language])[language],
                                    style: {
                                        icon: <VisibilityRounded className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: () => { redirectTo(`/site?id=${selectedIndex}`) },
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "SITE", name: "VIEW_SITE" }) === true ? true : false,
                                },
                                {
                                    text: lang.VIEW_SITE_ALARMS[language],
                                    style: {
                                        icon: <History className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: () => props.openSiteInterventon(),
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "VIEW_SITE_INTERVENTION" }) === true ? true : false,
                                },
                            ]}
                            withBack
                        />
                    </Grid>
                }
                close={close}
                execute={close}
                language={language}
                noButtons
            />
        </React.Fragment>
    );
}


class CustomerSingleView extends Component {
    state = {
        addressModal: false,
        addressData: { ...custAddress },
        siteModal: false,
        siteData: { ...custSite, ...custAddress },
        editModal: false,
        editModalError: null,
        customerData: {},

        error: null,
        edite: false,
        settingsModal: false,
        siteIndex: null,
        persistenSiteIndex: null,
        siteInterventionModalStatus: false,

        invoiceModal: false,
        invoiceData: {
            CONTRACT_OBJID: null,
            START_DATE: new Date(),
            END_DATE: new Date(),
        },
        customerStatusModal: false,
        filterForActiveSites: 'active',
        filterForValidateSites: false,
        filterOpen: false
    }

    downloadInterventionTable = () => {
        fetch(`/api/interventions_report?OBJID=${this.state.invoiceData.CONTRACT_OBJID}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ START_DATE: this.state.invoiceData.START_DATE, END_DATE: this.state.invoiceData.END_DATE })
        })
            .then(response => response.blob())
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = '';
                link.click();
            })
            .catch(err => console.log(err))
    }

    updateCustomerStatus = (status) => {
        fetch('/api/set_customer_status', {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({
                isActive: status,
                id: this.props.customerData.customer.ID
            })
        })
            .then(response => response.json())
            .then(response => {
                switch (response.status) {
                    case response.status > 400:
                        this.props.actions.onNotificationOpen('warning', lang[response.message], 6000);
                        break;
                    default:
                        this.props.actions.onNotificationOpen('success', lang[response.message], 6000);
                        this.props.actions.getCustomerData(extractQueryFrontend(this.props.location.search).id);
                        break;
                }
            })
    };

    getEntries({ filterForValidateSites, filterForActiveSites }) {
        this.props.actions.getCustomerData(extractQueryFrontend(this.props.location.search).id, filterForActiveSites, filterForValidateSites)
    }

    componentDidUpdate = (nextProps) => {
        if (this.props.location.search !== nextProps.location.search) {
            const query = extractQueryFrontend(this.props.location.search);
            document.title = `RPG - ${lang.CUSTOMER[this.props.language]} #${query.id}`;

            this.props.actions.onCustomerDataRecieve({});
            this.props.actions.getCustomerData(query.id);
        }
        return (this.props.location.search !== nextProps.location.search)
    };

    componentDidMount = () => {
        this.props.actions.checkSession(false);
        const query = extractQueryFrontend(this.props.location.search);
        document.title = `RPG - ${lang.CUSTOMER[this.props.language]} #${query.id}`;
        this.props.actions.onCustomerDataRecieve({});
        this.props.actions.getCustomerData(query.id, this.state.filterForActiveSites, this.state.filterForValidateSites);
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomerSettingsModal
                    open={this.state.siteIndex !== null}
                    close={() => this.setState({ siteIndex: null })}
                    selectedIndex={this.state.siteIndex}
                    language={this.props.language}
                    userPermissions={this.props.permissions}
                    isAuthenticated={this.props.isAuthenticated}
                    openSiteInterventon={() => this.setState({ siteInterventionModalStatus: true })}
                />
                {/* Site Intervention modal */}
                <SiteInterventionModal
                    open={this.state.siteInterventionModalStatus}
                    close={() => this.setState({ siteInterventionModalStatus: false })}
                    language={this.props.language}
                    selectedIndex={this.state.persistenSiteIndex}
                />
                <CustomModal
                    fullWidth
                    open={this.state.addressModal}
                    title={lang.ADD_ADDRESS[this.props.language]}
                    content={
                        <Grid container spacing={2}>
                            <AddressNewForm
                                addData={this.state.addressData}
                                language={this.props.language}
                                updateFieldRaw={(field, value) => this.setState({ addressData: { ...this.state.addressData, [field]: value } })}
                                updateField={(e, length) => this.setState({ addressData: { ...this.state.addressData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                            />
                        </Grid>
                    }
                    close={() => {
                        this.setState({
                            addressModal: false,
                        })
                    }}
                    execute={() => {
                        this.props.actions.addCustomerAddress(this.props.userAuth.id, { ...this.state.addressData, CONTACT_OBJID: this.props.customerData.customer.ID });
                        this.setState({ addressModal: false })
                    }}
                    validate={(this.state.addressData.REGION.length > 0 && this.state.addressData.CITY.length > 0 && this.state.addressData.STREET_NAME.length > 0)}
                    language={this.props.language}
                />
                <CustomModal
                    fullWidth
                    open={this.state.siteModal}
                    title={lang.ADD_SITE[this.props.language]}
                    content={
                        <Grid container spacing={2}>
                            <SiteNewForm
                                gps
                                addData={this.state.siteData}
                                language={this.props.language}
                                updateFieldRaw={(field, value) => this.setState({ siteData: { ...this.state.siteData, [field]: value } })}
                                updateField={(e, length) => this.setState({ siteData: { ...this.state.siteData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                                error={this.state.error}
                                updateError={(value) => this.setState({ error: value })}
                            />
                        </Grid>
                    }
                    close={() => {
                        this.setState({
                            siteModal: false,
                        })
                    }}
                    execute={() => {
                        this.props.actions.addCustomerSite(this.props.userAuth.id, siteAddFormat({ ...this.state.siteData, CONTACT_OBJID: this.props.customerData.customer.ID }));
                        this.setState({ siteModal: false })
                    }}
                    validate={this.state.error === false && (this.state.siteData.SITE_CODE.length > 0 && this.state.siteData.SITE_NAME.length > 0) &&
                        (this.state.siteData.REGION.length > 0 && this.state.siteData.CITY && this.state.siteData.STREET_NAME.length > 0) &&
                        (this.state.siteData.CONTACT_LAST_NAME.length > 0 && this.state.siteData.CONTACT_FIRST_NAME.length > 0 && this.state.siteData.CONTACT_PHONE.length > 0)}
                    language={this.props.language}
                />
                <CustomModal
                    fullWidth
                    open={this.state.invoiceModal}
                    title={lang.ACTION_INVOICE[this.props.language]}
                    content={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ContractSelect
                                    language={this.props.language}
                                    fieldName="CONTRACT_OBJID"
                                    data={this.state.invoiceData}
                                    id={this.props.customerData.customer ? this.props.customerData.customer.ID : null}
                                    update={(fieldName, value) => this.setState({ invoiceData: { ...this.state.invoiceData, [fieldName]: value } })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.props.language === "en" ? enUS : ro}>
                                    <DatePicker
                                        variant="inline"
                                        autoOk
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        label={lang.REPORT_START_DATE[this.props.language]}
                                        maxDate={this.state.invoiceData.END_DATE !== null ? this.state.invoiceData.END_DATE : Date('2100-01-01')}
                                        disableFuture
                                        inputVariant="outlined"
                                        value={this.state.invoiceData.START_DATE}
                                        onChange={(value) => this.setState({ invoiceData: { ...this.state.invoiceData, "START_DATE": value } })}
                                        animateYearScrolling
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.props.language === "en" ? enUS : ro}>
                                    <DatePicker
                                        variant="inline"
                                        autoOk
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        label={lang.REPORT_END_DATE[this.props.language]}
                                        minDate={this.state.invoiceData.START_DATE !== null ? this.state.invoiceData.START_DATE : Date('2020-04-22')}
                                        disableFuture
                                        inputVariant="outlined"
                                        value={this.state.invoiceData.END_DATE}
                                        onChange={(value) => this.setState({ invoiceData: { ...this.state.invoiceData, "END_DATE": value } })}
                                        animateYearScrolling
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    }
                    close={() => {
                        this.setState({
                            invoiceModal: false,
                            invoiceData: {
                                CONTRACT_OBJID: null,
                                START_DATE: new Date(),
                                END_DATE: new Date(),
                            },
                        })
                    }}
                    execute={() => {
                        console.log(this.state.invoiceData)
                        this.downloadInterventionTable();
                        this.setState({
                            invoiceModal: false,
                            invoiceData: {
                                CONTRACT_OBJID: null,
                                START_DATE: new Date(),
                                END_DATE: new Date(),
                            },
                        })
                    }}
                    validate={this.state.invoiceData.CONTRACT_OBJID !== null}
                    language={this.props.language}
                />
                <CustomModal
                    fullWidth
                    open={this.state.editModal}
                    title={lang.EDIT_CUSTOMER[this.props.language]}
                    content={
                        <CustomerNewForm
                            edit
                            address
                            type={this.props.customerData.customer ? this.props.customerData.customer.TYPE : ""}
                            addData={this.state.customerData}
                            language={this.props.language}
                            updateFieldRaw={(field, value) => this.setState({ customerData: { ...this.state.customerData, [field]: value }, edited: true })}
                            updateField={(e, length) => this.setState({ customerData: { ...this.state.customerData, [e.target.name]: e.target.value.toString().slice(0, length) }, edited: true })}
                            updateError={value => this.setState({ editModalError: value })}
                            error={this.state.editModalError}
                        />
                    }
                    close={() => {
                        console.log(this.state.customerData)
                        this.setState({
                            editModal: false,
                        })
                    }}
                    execute={() => {
                        this.props.actions.editCustomer(this.props.userAuth.id, this.state.customerData);
                        this.setState({ editModal: false })
                    }}
                    validate={this.state.edited && this.state.editModalError !== true &&
                        (
                            (this.state.customerData ? this.state.customerData.TYPE === 'PF' : false) &&
                            (this.state.customerData.FIRST_NAME ? this.state.customerData.FIRST_NAME.length > 0 : false) &&
                            (this.state.customerData.LAST_NAME ? this.state.customerData.LAST_NAME.length > 0 : false) &&
                            (this.state.customerData.PHONE ? this.state.customerData.PHONE.length > 0 : false) &&
                            (this.state.customerData.REGION ? this.state.customerData.REGION.length > 0 : false) &&
                            (this.state.customerData.CITY ? this.state.customerData.CITY.length > 0 : false) &&
                            (this.state.customerData.STREET_NAME ? this.state.customerData.STREET_NAME.length > 0 : false)
                            || (this.state.customerData ? this.state.customerData.TYPE === 'PJ' : false) &&
                            (this.state.customerData.NAME ? this.state.customerData.NAME.length > 0 : false) &&
                            (this.state.customerData.PHONE ? this.state.customerData.PHONE.length > 0 : false) &&
                            (this.state.customerData.REGION ? this.state.customerData.REGION.length > 0 : false) &&
                            (this.state.customerData.CITY ? this.state.customerData.CITY.length > 0 : false) &&
                            (this.state.customerData.STREET_NAME ? this.state.customerData.STREET_NAME.length > 0 : false)
                        )
                    }
                    language={this.props.language}
                />
                <ChangeCutomerStatusModal
                    open={this.state.customerStatusModal}
                    close={() => this.setState({ customerStatusModal: false })}
                    language={this.props.language}
                    status={this.props.customerData.customer ? this.props.customerData.customer.IS_ACTIVE : false}
                    execute={() => this.updateCustomerStatus(!(this.props.customerData.customer && this.props.customerData.customer.IS_ACTIVE === 1))}
                />
                {this.props.isFetchingCustomerData === false
                    ?
                    <CustomerSingleSetup
                        language={this.props.language}
                        data={this.props.customerData}
                        permissions={this.props.permissions}
                        address={{
                            openAddModal: () => this.setState({ addressModal: true, addressData: { ...custAddress } })
                        }}
                        site={{
                            openAddModal: () => this.setState({ siteModal: true, siteData: { ...custSite, ...custAddress } }),
                            openSettingsModal: (value) => this.setState({ siteIndex: value, persistenSiteIndex: value }),
                        }}
                        customer={{
                            settingsModal: this.state.settingsModal,
                            openSettingsModal: () => this.setState({ settingsModal: true }),
                            closeSettingsModal: () => this.setState({ settingsModal: false }),
                            openInvoiceAppendixModal: () => this.setState({ invoiceModal: true }),
                            openCustomerStatusModal: () => this.setState({ customerStatusModal: true }),
                            openEditModal: () => {
                                var primaryAddress = {};
                                this.props.customerData.address.map(address => {
                                    if (address.OBJID === this.props.customerData.customer.FK2PRIMARY_ADDRESS) {
                                        primaryAddress = { ...address };
                                    }
                                })
                                delete primaryAddress.CREATE_TIME;
                                delete primaryAddress.TYPE
                                this.setState({ editModal: true, editModalError: false, customerData: { ...this.props.customerData.customer, ...primaryAddress }, edited: false })
                            },
                        }}

                        filterForActiveSites={this.state.filterForActiveSites}
                        setFilterForActiveSites={(value) => {
                            this.setState({ filterForActiveSites: value })
                            this.getEntries({ filterForActiveSites: value, filterForValidateSites: this.state.filterForValidateSites })
                        }}
                        filterForValidateSites={this.state.filterForValidateSites}
                        setFilterForValidatedSites={(value) => {
                            this.setState({ filterForValidateSites: value })
                            this.getEntries({ filterForValidateSites: value, filterForActiveSites: this.state.filterForActiveSites })
                        }}
                        filterOpen={this.state.filterOpen}
                        setFilterOpen={() => this.setState({ filterOpen: !this.state.filterOpen })}
                    />
                    :
                    <PlaceholderSkeleton />
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,
        userAuth: state.auth.userData,
        isFetchingCustomerData: state.cust.isFetchingCustomerData,
        customerData: state.cust.customerData,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSingleView);
