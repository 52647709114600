import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as lang from '../../../../Constants/strings';
import SiteAreaEditForm from './SiteAreaEditForm';

export default function EditModal(props) {
    const { selectedIndex, language } = props;
    const [editData, setEditData] = React.useState({ OBJID: "", AREA_NAME: "", AREA_CODE: "", AREA_DESCRIPTION: '' });
    const [active, setActive] = React.useState(false);

    function getItem(id) {
        fetch(`/api/site_area?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                setEditData({ ...response })
                setActive(response.ACTIVE === 1 ? true : false)
            })
            .catch(err => console.log(err))
    }

    React.useEffect(() => {
        if (selectedIndex)
            getItem(selectedIndex);
        // setEditData({ SITE_TYPE: "" });
    }, [selectedIndex])

    return (
        <CustomModal
            fullWidth
            title={lang.EDIT[language]}
            open={selectedIndex !== null}
            content={
                <SiteAreaEditForm
                    toggleExecutor={() => props.toggleExecutor(editData)}
                    active={active}
                    editData={editData}
                    id={selectedIndex}
                    language={language}
                    updateField={(e, lenght) => { setEditData({ ...editData, [e.target.name]: e.target.value.toString().slice(0, lenght) }) }}
                    close={props.close}
                />
            }
            close={props.close}
            execute={() => {
                props.updateExecutor({
                    OBJID: editData.OBJID,
                    AREA_CODE: editData.AREA_CODE,
                    AREA_NAME: editData.AREA_NAME,
                    AREA_DESCRIPTION: editData.AREA_DESCRIPTION
                })
                props.close();
            }}
                validate={editData.AREA_NAME !== '' && editData.AREA_CODE !== ''}
            language={language}

        />
    );
}