export const INSTALLATION = {
    en: "Installation",
    ro: "Instalare"
}
export const TASK_INSTALLATION = {
    en: "Installation Tickets",
    ro: "Tickete Instalare"
}

export const ADD_INSTALLATION = {
    en: "Add installation",
    ro: "Adaugă instalare"
}

export const INSTALLATION_HISTORY = {
    en: "History installation",
    ro: "Istoric instalări"
}

export const DISTANCE = {
    en: "Distance",
    ro: "Distanță"
}

export const INSTALLATION_SCORE = {
    en: "Installation score",
    ro: "Punctaje instalări"
}

export const SCORE = {
    en: "Score",
    ro: "Punctaj"
}

export const SCORE_VALIDATION = {
    en: "Score validation",
    ro: "Verificare punctaj"
}

export const ACTIVE_INSTALLATION = {
    en: "Active installation",
    ro: "Instalări active"
}

export const INSTALLS = {
    "en": 'Installs',
    "ro": "Instalări"
};

export const CLOSE_INSTALLATION_WITH_IMAGE = {
    en: 'Close instalaltion with image',
    ro: 'Închide instalare cu poză pv',
}