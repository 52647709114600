import * as actionTypes from '../actions/actions';


const initialState = {
    data: [],
    dataRows: 0,
    isFetchingData: false,
    pageNumber: 0,
    pageRows: 10,
    orderBy: 0,
    orderDirection: 'asc',
}

export default function serverPaginationReducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.SP_DATA_REQUEST:
            return {
                ...state,
                isFetchingData: true,
            }
        case actionTypes.SP_DATA_RECIEVE:
            // console.log(action.payload)
            return {
                ...state,
                data: action.payload.results,
                dataRows: action.payload.count,
                pageNumber: action.payload.pageNumber,
                pageRows: action.payload.pageRows,
                orderBy: action.payload.orderBy,
                orderDirection: action.payload.orderDirection,
                isFetchingData: false,
            }

        default:
            return state;
    }
}