import React from "react";
import {
    Paper,
    MenuItem,
    Select,
    Grid,
    makeStyles
} from "@material-ui/core";
import CustomModal from "../../../Components/Modals/CustomModal";
import *  as lang from "../../../Constants/strings";
import { getGetHeader } from "../../../Constants/requestHeaders";
import axios from "axios";

const AreaSelectModal = (props) => {
    const {
        open,
        onClose,
        callback,
        language,
        selectedArea,
        setSelectedArea
    } = props;

    const [areaList, setAreaList] = React.useState([]);

    const getAreaList = async () => {
        let response = await axios.get(`/api/areas`, getGetHeader);

        if (response.status === 200) {
            setAreaList(response.data.data);
        } else {
            setAreaList([]);
        }
    };

    React.useEffect(() => {
        getAreaList();
    }, []);

    return (
        <CustomModal
            open={open}
            fullWidth
            title={lang.AREA_SELECT[language]}
            close={() => onClose()}
            content={
                <Grid container spacing={2} justify='center' alignItems='stretch'>
                    <Select
                        value={selectedArea !== null && selectedArea ? selectedArea : 0}
                        onChange={setSelectedArea}
                        fullWidth
                        variant="outlined"
                        label={lang.AREA_SELECT[language]}
                    >
                        <MenuItem value={0}>Niciuna</MenuItem>
                        {areaList.map(area => {
                            return <MenuItem key={area.AREA} value={area}>{area.AREA}</MenuItem>
                        })}
                    </Select>
                </Grid>
            }
            execute={callback}
            validate
            language={language}
        />

    )
}

export default AreaSelectModal;