import React, { Component } from "react";
import { Grid, CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../actions/actions";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MaterialTableDemo from "../../../Components/Misc/Tables/MaterialTableSimple";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { areaNom } from "../../../Components/Misc/Tables/TableHeaders";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";
import TableSkeleton from "../Skeleton";
import AreaNewForm from "./AreasNomComponents/AreasNewForm";
import AreaEditModal from "./AreasNomComponents/AreaEditModal";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(10),
		width: "auto",
	},
	paper: {
		padding: theme.spacing(2),
	},
	buttonStd: {
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	divier: {
		marginTop: theme.spacing(2),
	},
	formControl: {
		marginTop: theme.spacing(2),
	},
}));

function AreaNomSetup(props) {
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	return (
		<Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
			<Grid item xs={12}>
				<MaterialTableDemo
					tiny={tiny}
					title={lang.AREA_TABLE[props.language]}
					language={props.language}
					data={props.areaList}
					rowsPerPage={props.rowsPerPage}
					onRowsPerPageChange={props.onRowsPerPageChange}
					header={areaNom(props.language)}
					dense={false}
					openAddModal={props.openAddModal}
					openRowModal={props.openRowModal}
					clickLabel="AREA_CODE"
				/>
			</Grid>
		</Grid>
	);
}

class AreasNom extends Component {
	state = {
		addModal: false,
		editModal: false,
		selectedIndex: null,
		areaData: {},
		error: null,
	};
	componentDidMount() {
		this.props.actions.getAreaList();
	}

	getAreaData() {
		fetch(`/api/get_area?id=${this.state.selectedIndex}`, {
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.token,
			},
		})
			.then((response) => response.json())
			.then((response) => this.setState({ areaData: response }))
			.catch((err) => console.log(err));
	}

	updateAreaData() {
		axios
			.post(
				`/api/update_area`,
				{ ...this.state.areaData },
				{
					headers: {
						Accept: "application/json, text/plain, */*",
						"Content-Type": "application/json",
						authorization: localStorage.token,
					},
				}
			)
			.then((res) => {
				switch (res.status) {
					case 200:
						console.log(res);
						this.props.actions.onNotificationOpen("success", lang[res.data.message], 6000);
						this.props.actions.getAreaList();
						this.setState({ editModal: false });
						break;
					case 400:
						this.props.actions.onNotificationOpen("warning", lang[res.data.message], 6000);
						break;
					default:
						this.props.actions.onNotificationOpen("warning", lang[res.data.message], 6000);
				}
			});
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<CustomModal
					fullWidth
					open={this.state.addModal}
					title={lang.ADD_AREA[this.props.language]}
					content={
						<AreaNewForm
							updateField={(e, length) =>
								this.setState({
									areaData: { ...this.state.areaData, [e.target.name]: e.target.value.toString().slice(0, length) },
								})
							}
							addData={this.state.areaData}
							language={this.props.language}
							error={this.state.error}
							updateError={(value) => this.setState({ error: value })}
							open={this.state.addModal}
						/>
					}
					close={() => {
						this.setState({
							addModal: false,
							areaData: {},
							error: null,
						});
					}}
					execute={() => {
						this.props.actions.addNewArea(this.props.userAuth.id, this.state.areaData);
						console.log(this.state.areaData);
						this.setState({ addModal: false });
					}}
					validate={this.state.error === false}
					language={this.props.language}
				/>

				<AreaEditModal
					open={this.state.editModal}
					close={() => this.setState({ editModal: false })}
					execute={() => this.updateAreaData()}
					language={this.props.language}
					updateField={(e, length) =>
						this.setState({
							areaData: { ...this.state.areaData, [e.target.name]: e.target.value.toString().slice(0, length) },
						})
					}
					removeUser={(removeIndex) =>
						this.setState({
							areaData: {
								...this.state.areaData,
								USERS: this.state.areaData.USERS.filter((item, index) => index !== removeIndex),
							},
						})
					}
					addUser={(user) =>
						this.setState({
							areaData: {
								...this.state.areaData,
								USERS: [...this.state.areaData.USERS, user],
							},
						})
					}
					addData={this.state.areaData}
					error={this.state.error}
					updateError={(value) => this.setState({ error: value })}
				/>

				{this.props.isFetchingAreaList === false ? (
					<AreaNomSetup
						language={this.props.language}
						areaList={this.props.areaList}
						rowsPerPage={this.props.rowsPerPage}
						onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
						openAddModal={() => {
							this.setState({
								addModal: true,
								areaData: {
									...this.state.areaData,
									NAME: "",
								},
							});
						}}
						openRowModal={async (value) => {
							await this.setState({ selectedIndex: value, editModal: true });
							await this.getAreaData();
						}}
					/>
				) : (
					<TableSkeleton />
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.config.language,
		theme: state.config.theme,
		rowsPerPage: state.config.rowsPerPage,
		permissions: state.auth.permissions,
		userAuth: state.auth.userData,

		areaList: state.nom.areaList,
		isFetchingAreaList: state.nom.isFetchingAreaList,
	};
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AreasNom);
