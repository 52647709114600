import React, { Component } from 'react';
import { Grid, CssBaseline, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTableDemo from '../../../Components/Misc/Tables/MaterialTableSimple';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { siteAreaNom } from '../../../Components/Misc/Tables/TableHeaders'
import * as lang from '../../../Constants/strings';
import CustomModal from '../../../Components/Modals/CustomModal';
import TableSkeleton from '../Skeleton';
// import AreaNewForm from './AreasNomComponents/AreasNewForm';
// import AreaEditModal from './AreasNomComponents/AreaEditModal';
import { checkPermissions } from '../../../Functions/permissionHide';
import SiteAreaNewForm from './SiteAreaNomComponents/SiteAreaNewForm';
import EditModal from '../SiteArea/SiteAreaNomComponents/SiteAreaEditModal';
import { HighlightOff, CheckCircleRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}));

function SiteAreaNomSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.SITE_AREA_TABLE[props.language]}
                    language={props.language}
                    data={props.data}
                    rowsPerPage={props.rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={siteAreaNom(props.language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="ID"
                />
            </Grid>
        </Grid>
    );
}

export default function SiteAreaNom(props) {
    const { language, userAuth, siteId } = props;

    const [addModal, setAddModal] = React.useState(false);
    const [editModal, setEditModal] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [errorName, setErrorName] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(null);
    const [addData, setAddData] = React.useState({ AREA_NAME: "", AREA_CODE: "", AREA_DESCRIPTION: "" });
    const [siteAreaList, setSiteAreaList] = React.useState([]);
    const [isFetchingSiteAreaList, setIsFetchingSiteAreaList] = React.useState(true);
    const [rowsPerPage, onRowsPerPageChange] = React.useState(5);

    React.useEffect(() => {
        setIsFetchingSiteAreaList(true)
        fetch(`/api/site_areas?type=0&id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    setSiteAreaList([]);
                } else {
                    response.data.map(data => {
                        if (data.STATUS === "inactive") {
                            data.STATUS = <HighlightOff color='secondary' />
                        } else {
                            data.STATUS = <CheckCircleRounded color='primary' />
                        }
                    })
                    setSiteAreaList(response.data);
                    setIsFetchingSiteAreaList(false)
                }
            })
    }, []);

    function getSiteAreaListReq() {
        setIsFetchingSiteAreaList(true)
        fetch(`/api/site_areas?type=0&id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    setSiteAreaList([]);
                } else {
                    setSiteAreaList(response.data);
                    setIsFetchingSiteAreaList(false)
                }
            })
    }

    function addSiteAreaReq() {
        fetch(`/api/add_site_area?id=${userAuth.id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...addData, ...userAuth.id, FK2SITE_OBJID: siteId })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    props.onNotificationOpen('warning', response.message);
                } else {
                    props.onNotificationOpen('success', response.message);
                    getSiteAreaListReq()
                }
            });
    };

    function updateSiteArea(id, data) {
        fetch(`/api/update_site_area`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    props.onNotificationOpen('warning', response.message);
                } else {
                    props.onNotificationOpen('success', response.message);
                    getSiteAreaListReq()
                }
            })
    };

    function toggleSiteArea(id, data) {
        fetch(`/api/toggle_site_area?id=${id}&ACTIVE=${data.ACTIVE}&OBJID=${data.OBJID}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    props.onNotificationOpen('warning', response.message);
                } else {
                    props.onNotificationOpen('success', response.message);
                    getSiteAreaListReq();
                }
            })
    };

    return (
        <React.Fragment>
            {/* <CssBaseline /> */}
            <CustomModal
                fullWidth
                open={addModal}
                title={lang.ADD_AREA[language]}
                content={
                    <SiteAreaNewForm
                        updateField={(e, length) => {
                            let data = { ...addData };
                            data[e.target.name] = e.target.value.toString().slice(0, length);
                            setAddData(data);
                        }}
                        addData={addData}
                        language={language}
                        errorName={errorName}
                        errorCode={errorCode}
                        updateErrorName={(value) => setErrorName(value)}
                        updateErrorCode={(value) => setErrorCode(value)}
                        open={addModal}
                    />
                }
                close={() => setAddModal(false)}
                execute={() => {
                    addSiteAreaReq();
                    setAddModal(false);
                }}
                validate={addData.AREA_NAME !== '' && addData.AREA_CODE !== ''}
                language={language}
            />

            <EditModal
                edit
                authID={userAuth.id}
                language={language}
                selectedIndex={selectedIndex}
                updateExecutor={(data) => updateSiteArea(userAuth.id, { ...data, selectedIndex: selectedIndex })}
                toggleExecutor={(data) => toggleSiteArea(userAuth.id, data)}
                close={() => setSelectedIndex(null)}
            />

            {isFetchingSiteAreaList === false
                ?
                <SiteAreaNomSetup
                    language={language}
                    data={siteAreaList}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(value) => onRowsPerPageChange(value)}
                    openAddModal={() => {
                        if (props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "SITE", name: "ADD_SITE_AREA" })) {
                            setAddModal(true);
                            setAddData({
                                AREA_NAME: "",
                                AREA_CODE: "",
                                AREA_DESCRIPTION: "",
                            });
                            setErrorCode(null);
                            setErrorName(null);
                        }
                    }}
                    openRowModal={(value) => {
                        if (props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "SITE", name: "EDIT_SITE_AREA" })) {
                            setSelectedIndex(value);
                        }
                    }}
                />
                :
                <TableSkeleton />
            }
        </React.Fragment>
    );
};