import {bindActionCreators} from "redux";
import * as actionCreators from "../actions/actions";
import {connect} from "react-redux";
import CustomModal from "./Modals/CustomModal";
import {Grid, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import axios from "axios";
import {getGetHeader} from "../Constants/requestHeaders";

const GPS_ERRORS = {
    1: "Poziția GPS este blocată! Pentru a putea să execuți acțiuni pe tickete activează poziția GPS pentru crm.rpgsuceava.ro!",
    2: "Poziția GPS nu se poate fi determinată!",
    3: "Nu s-a putut citi poziția GPS! Te rugăm să reîncarci pagina.",
};

const GPSGuard = (props) => {
    const [open, setOpen] = useState(false);
    const [isTechnicalUser, setIsTechnicalUser] = useState(false);

    const displayPosition = (position) => {
        console.log("Location: ", JSON.stringify(position));
        props.actions.onGeolocationUpdate({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            error: false,
        });
        setOpen(false);
    };

    const displayError = (error) => {
        props.actions.onGeolocationUpdate({
            latitude: null,
            longitude: null,
            accuracy: null,
            error: GPS_ERRORS[error.code],
        });
        setOpen(true);
    };

    const checkGPS = () => {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if (result.state !== "granted") {
                if (navigator.geolocation) {
                    navigator.geolocation.watchPosition(
                      displayPosition,
                      displayError,
                      {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0
                      }
                    );
                }
                setOpen(true);
            }
        });
    }

    const checkUserIsTechnicalTeam = async () => {
        try {
            const res = await axios.get('/api/user_team', getGetHeader());
            setIsTechnicalUser(res.data.isTechnicalUser);
        } catch (e) {
            setIsTechnicalUser(false);
        }
    }

    useEffect(() => {
        if(isTechnicalUser) checkGPS();
    }, [isTechnicalUser]);

    useEffect(() => {
        checkUserIsTechnicalTeam();
    }, []);


    useEffect(() => {
        const timer = setInterval(() => {
            navigator.permissions.query({ name: "geolocation" })
              .then(res => {
                  if(res.state !== "granted") {
                      setOpen(true);
                  }
              })
        }, 10000);
        // clearing interval
        return () => clearInterval(timer);
    });

    return (
        <>
            <CustomModal
                fullScreen
                open={open && isTechnicalUser}
                title={"Activare poziție GPS"}
                close={() => alert('Modalul nu se poate închide! Urmează instrucțiunile pentru a putea continua!')}
                content={
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ color: "red", margin: "10px" }}>
                                Pentru a putea efectua acțiuni pe ticket activează poziția GPS pentru crm.rpgsuceava.ro.<br/><br/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ margin: "10px" }}>
                                Pași pentru activare GPS se pot găsi <a href="https://support.google.com/chrome/answer/142065?hl=ro&co=GENIE.Platform%3DAndroid">aici</a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ margin: "10px" }}>
                                După activarea poziției GPS reîncarcă pagina pentru a continua.
                            </Typography>
                        </Grid>
                    </Grid>
                }
                language={props.language}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    language: state.config.language
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GPSGuard);
