import * as lang from "../../../Constants/strings";

export const user = (language) => [
  {
    label: "Nr",
    id: "NR",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USERNAME[language],
    id: "USERNAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_NAME[language],
    id: "LAST_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.FIRST_NAME[language],
    id: "FIRST_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ROLE[language],
    id: "ROLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.DEPARTMENT[language],
    id: "DEPARTMENT_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.HAS_ACCOUNT_ID[language],
    id: 'HAS_ACCOUNT_ID',
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "LOCKED",
    numeric: false,
    disablePadding: false,
  },
];

export const execNom = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.EXEC_NAME[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PHONE[language],
    id: "PHONE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.EMAIL[language],
    id: "EMAIL",
    numeric: false,
    disablePadding: false,
  },

  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];

export const roleNom = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ROLE[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USERS[language],
    id: "COUNT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];

export const departmentNom = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USERS[language],
    id: "COUNT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];

export const areaNom = (language) => [
  {
    label: "ID",
    id: "AREA_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA_NAME[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITES[language],
    id: "COUNT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];
export const siteTypeNom = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_TYPE[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITES[language],
    id: "COUNT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];
export const siteAreaNom = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_AREA_NAME[language],
    id: "AREA_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_AREA_CODE[language],
    id: "AREA_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA_DESCRIPTION[language],
    id: "AREA_DESCRIPTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];

export const cust = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "CNP_CUI",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUST_TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PHONE[language],
    id: "PHONE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.EMAIL[language],
    id: "EMAIL",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ACTIVE[language],
    id: "IS_ACTIVE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.VERIFIED[language],
    id: "VERIFY",
    numeric: false,
    disablePadding: false,
  },
];

export const smallCust = (language) => [
  {
    label: lang.NAME[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PHONE[language],
    id: "PHONE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "ADDRESS",
    numeric: false,
    disablePadding: false,
  },
];

export const address = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ACTIVE[language],
    id: "IS_ACTIVE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA_NAME[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
];

export const site = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CONTACT_NAME[language],
    id: "SITE_CONTACT_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PHONE[language],
    id: "CONTACT_PHONE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA_NAME[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ACTIVE[language],
    id: "ACTIVE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.VERIFIED[language],
    id: "VERIFY",
    numeric: false,
    disablePadding: false,
  },
];

export const smallSite = (language) => [
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PHONE[language],
    id: "CONTACT_PHONE",
    numeric: false,
    disablePadding: false,
  },
];

export const siteSearchResult = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "ADDRESS",
    numeric: false,
    disablePadding: false,
  },
];

export const custSerchResult = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "CUSTOMER_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "ADDRESS",
    numeric: false,
    disablePadding: false,
  },
];

export const task = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON_SHORT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const taskS = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON_SHORT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILLING_STATUS[language],
    id: "BILLING_STATUS_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.SCORE[language],
    id: "SCORE",
    numeric: false,
    disablePadding: false,
  },
];
export const intervention = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SUBTYPE[language],
    id: "SUBTYPE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];
export const contracts = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACT_NUMBER[language],
    id: "CONTRACT_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "SUB_TYPE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR_NAME[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACT_START_DATE[language],
    id: "START_DATE_FORMATED",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CONTRACT_END_DATE[language],
    id: "END_DATE_FORMATED",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.STATUS[language],
    id: "ACTIVE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACT_ASSIGNEE[language],
    id: "ASSIGNEE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CLOSE_COMMENT[language],
    id: "CLOSE_COMMENT",
    numeric: false,
    disablePadding: false,
  },
];


export const installation_view = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "MAINTENACE_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.PRIORITY[language],
    id: "PRIORITY",
    numeric: false,
    disablePadding: false,
  },
];

export const tasks_view = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.PRIORITY[language],
    id: "PRIORITY",
    numeric: false,
    disablePadding: false,
  },
];

export const small_tasks_view = (language, priority) => [
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PRIORITY[language],
    id: "PRIORITY",
    numeric: false,
    disablePadding: false,
  },
];

export const tasks_history = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR_NAME[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.END_DATE[language],
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.SCORE[language],
    id: "SCORE",
    numeric: false,
    disablePadding: false,
  },
];
export const accountingCustomers = (language) => [
  {
    label: lang.COD[language],
    id: "CUSTOMER_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.NAME[language],
    id: "NAME",
  },
  {
    label: lang.BILL[language],
    id: "BILLED",
    numeric: false,
    disablePadding: false,
  },
];

export const site_warning_phone = (language, priority) => [
  {
    label: lang.ID[language],
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COMMENT[language],
    id: "CONTENT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.WAS_MAILED[language],
    id: "WAS_MAILED",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const intervention_data = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SUBTYPE[language],
    id: "SUBTYPE",
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    date: true,
    disablePadding: false,
    isDate: true
  },
];

export const patrol_header = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    date: true,
    disablePadding: false,
    isDate: true
  },
];

export const intervention_history_data = (language, priority) => [
  {
    label: "ID",
    id: "INTERVENTION_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
  },
  {
    label: lang.AREA[language],
    id: "AREA",
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.END_DATE[language],
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const tasks_bill_validation = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILLING_TYPE[language],
    id: "BILLING_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PAYMENT_TYPE[language],
    id: "PAYMENT_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_CREATE_BILL[language],
    id: "USER_THAT_CREATE_BILL",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_APPROVE_FREE[language],
    id: "USER_THAT_APPROVE_FREE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_WILL_BILL[language],
    id: "USER_THAT_WILL_BILL",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_BILL_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_BILL_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_CASHING_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_CASHING_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_IN[language],
    id: "CREATE_IN",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const tasks_cashing_validation = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "ACCOUNT",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILLING_TYPE[language],
    id: "BILLING_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PAYMENT_TYPE[language],
    id: "PAYMENT_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_CREATE_BILL[language],
    id: "USER_THAT_CREATE_BILL",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Mod achitare",
    id: "PAY",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Status achitare",
    id: "COMPLETED_PAYED",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_BILL_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_BILL_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_CASHING_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_CASHING_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_IN[language],
    id: "CREATE_IN",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];
export const tasks_score_validation = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "ACCOUNT",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SCORE[language],
    id: "SCORE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.DISTANCE[language],
    id: "DISTANCE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.OBSERVATION[language],
    id: "OBS",
    numeric: false,
    disablePadding: false,
  },
];

export const task_bill_history = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Activitate facturată",
    id: "TYPE",
    numeric: false,
    disablePadding: false
  },
  {
    label: "ACCOUNT",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.PAYMENT_RECIPE_OR_FREE[language],
    id: "PAYMENT_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_CREATED_BY[language],
    id: "USER_THAT_CREATE_BILL",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_NO[language],
    id: "BILL_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: `${lang.BILL_VALUE[language]} (RON)`,
    id: "BILL_VALUE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_DATE[language],
    id: "BILL_DATE",
    numericL: false,
    disablePadding: false,
  },
  {
    label: lang.RECIPE_NO[language],
    id: "RECEIPT_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.RECIPE_VALUE[language],
    id: "RECIPE_VALUE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.RECIPE_DATE[language],
    id: "RECIPE_DATE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: `${lang.UNPAY_SUM[language]} (RON)`,
    id: "UNPAY_SUM",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Mod achitare",
    id: "PAY",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Status achitare",
    id: "COMPLETED_PAYED",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_BILL_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_BILL_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_CASHING_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_CASHING_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_IN[language],
    id: "CREATE_IN",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CLOSE_IN[language],
    id: "CLOSE_IN",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const file_table = (language) => [
  {
    label: lang.TEMPLATE_FILES[language],
    id: "FILE_NAME",
    numeric: false,
    disablePadding: false,
  },
];

export const wrong_pv_raport = (language) => [
  {
    label: "ID",
    id: "USER_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.EMPLOYEE_NAME[language],
    id: "EMPLOYEE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USERNAME[language],
    id: "USERNAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.WRONG_PV_NO[language],
    id: "NO_WRONG",
    numeric: false,
    disablePadding: false,
  },
];

export const wrong_pv_files = (language) => [
  {
    label: "ID",
    id: "INTERVENTION_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.EMPLOYEE_NAME[language],
    id: "EMPLOYEE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COMMENT[language],
    id: "NOTE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.DATE[language],
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const task_bill_history_site = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Activitate facturată",
    id: "TYPE",
    numeric: false,
    disablePadding: false
  },
  {
    label: lang.PAYMENT_RECIPE_OR_FREE[language],
    id: "PAYMENT_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COMPLETED_BILL_PAY[language],
    id: "COMPLETED_PAYED",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_CREATED_BY[language],
    id: "USER_THAT_CREATE_BILL",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CONTRACTOR[language],
    id: "CONTRACTOR_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_NO[language],
    id: "BILL_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: `${lang.BILL_VALUE[language]} (RON)`,
    id: "BILL_VALUE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_DATE[language],
    id: "BILL_DATE",
    numericL: false,
    disablePadding: false,
  },
  {
    label: lang.RECIPE_NO[language],
    id: "RECEIPT_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.RECIPE_VALUE[language],
    id: "RECIPE_VALUE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.RECIPE_DATE[language],
    id: "RECIPE_DATE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: `${lang.UNPAY_SUM[language]} (RON)`,
    id: "UNPAY_SUM",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_BILL_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_BILL_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.USER_THAT_MADE_VALIDATION_CASHING_ACTION[language],
    id: "USER_THAT_MADE_VALIDATION_CASHING_ACTION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_IN[language],
    id: "CREATE_IN",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CLOSE_IN[language],
    id: "CLOSE_IN",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const maintenance_basic_view = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const maintenance_view = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "MAINTENACE_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const small_maintenance_view = (language, priority) => [
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];

export const maintenance_history_view = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.END_DATE[language],
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const small_maintenance_history_view = (language, priority) => [
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
];

export const maintenance_view_site = (language, priority) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "MAINTENACE_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const user_activity_task = (language) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "ACCOUNT",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectiv",
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Client",
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Adresă",
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Creare",
    id: "creationActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Creare - Alocare",
    id: "creationToAssign",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Alocare",
    id: "assignedActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Alocare - Pornire",
    id: "assignedToDrive",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Am pornit",
    id: "driveActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Am pornit - Am ajuns",
    id: "driveToStart",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Am ajuns",
    id: "startActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp rezolvare",
    id: "startToClose",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Rezolvat",
    id: "closeActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp amânare",
    id: "startToOnHold",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Amânare",
    id: "onHoldTimeStamp",
    numeric: false,
    disablePadding: false,
  },
];

export const user_activity_intervention = (language) => [
  {
    label: "ID",
    id: "INTERVENTION_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "ACCOUNT",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectiv",
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Client",
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Adresă",
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Creare",
    id: "creationActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Creare - Alocare",
    id: "creationToAssign",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Alocare",
    id: "assignedActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Alocare - Pornire",
    id: "assignedToDrive",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Am pornit",
    id: "driveActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Am pornit - Am ajuns",
    id: "driveToStart",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Am ajuns",
    id: "startActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp rezolvare",
    id: "startToValidate",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Validare",
    id: "validateTimeStamp",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Timp Validare - Închidere dispecerat",
    id: "validateToClose",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Închidere dispecerat",
    id: "closeActionTimeStamp",
    numeric: false,
    disablePadding: false,
  },
];

export const contract_reporting_header = (language) => [
  {
    label: "Nr. Crt.",
    id: "NOT_APPLICABLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data și numărul contractului de prestări servicii",
    id: "NR_AND_DATE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul contractului (subsistem de alarme la efracție, subsistem TVCI, subsistem control acces) ANALIZA RISC NR. ____ Nr.Evaluator RNERSF ___",
    id: "CONTRACT_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul de activitate și adresa obiectivului",
    id: "SITE_TYPE_AND_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Beneficiarul (denumirea și adresa)",
    id: "CUSTOMER_NAME_AND_SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Subsisteme componente (antiefracție, TVCI, control acces)",
    id: "SERVICES",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Avizul poliției",
    id: "PERMIT_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nr. și data de efectuare a recepției de către reprezentantul poliției",
    id: "NO_DATE_REC",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Observații (data încetării/account)",
    id: "CLOSE_INFO_AND_ACCOUNT",
    numeric: false,
    disablePadding: false,
  },
];

export const task_report_customer = (language) => [
  {
    label: "Nr. Crt.",
    id: "NOT_APPLICABLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data si ora semnalarii de catre beneficiar si modul de comunicare",
    id: "ASSIGNED",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Beneficiar (denumire si adresa montajului)",
    id: "CUSTOMER_AND_SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data și ora interventiei de către prestator",
    id: "DRIVE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Tipul defectiunii (se specifica si subsistemul la care s-a intervenit)",
    id: "SERVICES",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Mod de rezolvare a defectului",
    id: "CLOSE_COMMENT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume, prenume prestator (inginer sau tehnician)",
    id: "CLOSE_USER",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nr. proces verbal de constatare și remediere defect",
    id: "FILE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obs.",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
];

export const register_task_exec = (language) => [
  {
    label: "Nr. Crt.",
    id: "NOT_APPLICABLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data și nr. contractului de prestări servicii",
    id: "DATE_AND_CONTRACT_NUMBER",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul contractului",
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul de activitate si adresa obiectivului",
    id: "CUSTOMER_AND_SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume Beneficiar",
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Sisteme componete",
    id: "NOT_APPLICABLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Avizul politiei",
    id: "NOT_APPLICABLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data finalizarii",
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const contract_intervention_reporting_header = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data și nr. contractului de prestări servicii",
    id: "NR_AND_DATE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Beneficiar",
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Adresa locatiei contractului",
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul contractului",
    id: "CONTRACT_TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data încetării contractului",
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: "Motivul",
    id: "CLOSE_COMMENT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Observații",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
];

export const pv_register = (language) => [
  {
    label: "Nr. Crt.",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nr. proces verbal de primire si instruire pentru sistemul executat si data predarii",
    id: "DOC_DATE_AND_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul contractului (efractie, subsistem TVCI, subsistem control acces)",
    id: "SERVICES",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Beneficiar (denumire si adresa montajului)",
    id: "CUSTOMER_NAME_AND_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nr. aviz politie/data emiterii (daca este cazul)",
    id: "PERMIT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Data si numarul contractului de prestari servicii",
    id: "CONTRACT_DATE_AND_NO",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume, prenume beneficiar",
    id: "TRAINEES",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume, prenume prestator",
    id: "EMPLOYEE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume prenume invitati (din partea IPJ)",
    id: "NOT_APPLICABLE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Observații",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
]

export const technic_management_view = (language, priority) => [
  {
    label: "ID",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.COD[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.AREA[language],
    id: "AREA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.TYPE[language],
    id: "TYPE_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const area_management = (language) => [
  {
    label: "ID",
    id: "OBJID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_ADDRESS[language],
    id: "SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.GPS_POSITION[language],
    id: "GPS_POSITION",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ASSIGNED_TEAM[language],
    id: "ASSIGNED_TEAM_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: 'Numar contract / data contract',
    id: "CONTRACT_NO_DATE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.VALUE[language],
    id: "TOTAL_VALUE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.BILL_CYCLE[language],
    id: "BILL_CYCLE_DESC",
    numeric: false,
    disablePadding: false,
  }
];

export const intervention_history_data_management = (language, priority) => [
  {
    label: "ID",
    id: "INTERVENTION_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "CUSTOMER_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.REASON[language],
    id: "REASON",
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.ADDRESS[language],
    id: "SITE_ADDRESS",
  },
  {
    label: lang.AREA[language],
    id: "AREA",
  },
  {
    label: lang.TYPE[language],
    id: "TYPE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.END_DATE[language],
    id: "END_DATE",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.BILLING[language],
    id: "IS_FREE",
    numeric: false,
    disablePadding: false,
  },
];

export const projectsT = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const riscEvalSite = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const riscEvaluations = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];
export const project = (language) => [
  {
    label: "ID",
    id: "ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.CUSTOMER[language],
    id: "NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_NAME[language],
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.SITE_CODE[language],
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.STATUS[language],
    id: "STATUS_TRANS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: lang.LAST_UPDATE[language],
    id: "LAST_UPDATE_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
  {
    label: lang.CREATE_TIME[language],
    id: "CREATE_TIME_TRANS",
    numeric: false,
    disablePadding: false,
    isDate: true
  },
];

export const modelVi = (language) => [
  {
    label: "ID", //TASKID
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nr. process verbal de predare primire a sistemului executat si data predări", //DOC_NO + DOC_DATE
    id: "DOC_DATA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectul contractului (efractie, subsistem TVCI, subsistem control acces)", //REASON
    id: "REASON",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Beneficiar (denumire si adresa montajului)", // CUSTOMER_NAME
    id: "CUSTOMER_AND_SITE_ADDRESS",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nr. aviz politie/data emiterii (daca este cazul)",
    id: "PERMIT",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Numarul si data contractului sau a facturii emise", //CONTRACT_DATA -> CONTRACT_NO + START_DATE
    id: "DATE_AND_CONTRACT_NUMBER",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume, prenume beneficiar (reprezentant)", //  CUSTOMER_NAME
    id: "DISPLAY_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume, prenume prestator (reprezentant)", //EMPLOYEE_NAME
    id: "EMPLOYEE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Nume prenume invitați", // - 
    id: "NO_DATA",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Observatii", // -
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
];

export const technicalUserHistory = (language) => [
  {
    label: "Id Ticket",
    id: "TASK_ID",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Obiectiv",
    id: "SITE_NAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Account Obiectiv",
    id: "SITE_CODE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Utilizator",
    id: "USERNAME",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Echipă",
    id: 'TEAM_USER_NAMES',
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Status închidere ticket",
    id: 'CLOSING_STATUS_t',
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Punctaj",
    id: "SCORE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Punctaj (instalare)",
    id: "INSTALLATION_SCORE",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Valoare ticket",
    id: 'TASK_VALUE',
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Status ticket",
    id: 'TASK_CLOSING_STATUS',
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Distanță (km)",
    id: 'DISTANCE_KM',
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Număr mașină",
    id: "name",
    numeric: false,
    disablePadding: false,
  },
  {
    label: "Motiv intervenție",
    id: 'REASON',
    numeric: false,
    disablePadding: false,
  },

  {
    label: "Dată intervenție",
    id: 'createdAt_f',
    numeric: false,
    disablePadding: false,
  }
]
