import React from 'react';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { Grid, TextField } from '@material-ui/core';
import * as lang from '../../../../Constants/strings';

export default function CentralInfoModal(props) {
    const { data, language } = props;
    return (
        <CustomModal
            fullWidth
            open={props.open}
            title={lang.SITE_CENTRAL_INFO[language]}
            content={
                <Grid container alignItems='center' justify='center' spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            style={{ margin: 5 }}
                            value={data.CENTRAL_PHONE ? data.CENTRAL_PHONE : ''}
                            // onChange={(e) => updateField('SYSTEM_IP', e.target.value, 15)}
                            variant='outlined'
                            label={lang.CENTRAL_PHONE[language]}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            style={{ margin: 5 }}
                            value={data.CENTRAL_TYPE ? data.CENTRAL_TYPE : ''}
                            // onChange={(e) => updateField('SYSTEM_IP', e.target.value, 15)}
                            variant='outlined'
                            label={lang.CENTRAL_TYPE[language]}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            style={{ margin: 5 }}
                            value={data.CENTRAL_PASSWORD ? data.CENTRAL_PASSWORD : ''}
                            // onChange={(e) => updateField('SYSTEM_IP', e.target.value, 15)}
                            variant='outlined'
                            label={lang.CENTRAL_PASSWORD[language]}
                            disabled
                        />
                    </Grid>
                </Grid>
            }
            close={props.close}
            validate
            noButtons
            onlyBack
            language={props.language}
        />
    )
}
