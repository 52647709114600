import React from 'react';
import { TextField, Grid, Select, InputLabel, MenuItem, FormControl, Typography, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import * as lang from '../../../Constants/strings';

const useStyles = makeStyles(theme => ({
    btn: {
        margin: theme.spacing(1)
    },
    title: {
        margin: theme.spacing(2),
    },
    formIndent: {
        marginLeft: theme.spacing(2),
    }
}));

export default function AddressNewForm(props) {
    const classes = useStyles();

    const inputLabelRegion = React.useRef(null);
    const [labelWidthRegion, setLabelWidthRegion] = React.useState(35);
    const inputLabelCity = React.useRef(null);
    const [labelWidthCity, setLabelWidthCity] = React.useState(35);
    const inputLabelArea = React.useRef(null);
    const [labelWidthArea, setLabelWidthArea] = React.useState(35);
    const { addData, language, updateFieldRaw, updateField, gps, address, edit, disableEdit } = props;

    const [regionList, setRegionList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [areaList, setAreaList] = React.useState([]);

    const abortController = new AbortController();
    const signal = abortController.signal;

    function updateRegion(e) {
        const { value } = e.target;
        updateFieldRaw('CITY', '');
        updateFieldRaw('REGION', value);
    }

    function updateCity(e) {
        const { value } = e.target;
        updateFieldRaw('CITY', value);
        // console.log(`UPDATE CITY: '${addData.CITY}' -> '${value}'`)
    }
    function updateArea(e) {
        const { value } = e.target;
        updateFieldRaw('AREA_CODE', value);
    }

    React.useEffect(() => {
        setLabelWidthRegion(inputLabelRegion.current.offsetWidth);
        setLabelWidthCity(inputLabelCity.current.offsetWidth);

        if (regionList.length === 0) {
            fetch(`/api/region_list`, {
                signal: signal,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setRegionList(response);
                    if (addData.REGION) {

                        const id = response.filter(e => e.REGION === addData.REGION)[0].ID;

                        fetch(`/api/city_list/${id}`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'authorization': localStorage.token,
                            }
                        })
                            .then(res => res.json())
                            .then(res => {
                                setCityList(res);
                            })
                            .catch(err => console.log(err))
                    }
                })
                .catch(err => console.log(err))
        }
        else {
            if (addData.REGION) {
                const id = regionList.filter(e => e.REGION === addData.REGION)[0].ID;

                fetch(`/api/city_list/${id}`, {
                    signal: signal,
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': localStorage.token,
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        setCityList(response);
                    })
                    .catch(err => console.log(err))
            }
        }

        return () => abortController.abort();
    }, [addData.REGION]);

    React.useEffect(() => {
        gps &&
            setLabelWidthArea(inputLabelArea.current.offsetWidth);
        fetch(`/api/areas_active`, {
            signal: signal,
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setAreaList(response);
            })
            .catch(err => console.log(err))

        return () => abortController.abort();
    }, [gps]);

    return (
        <React.Fragment>
            {edit === false && address &&
                <React.Fragment>
                    <Grid item container xs={6} sm={6} justify="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={addData.HOME ? addData.HOME : false}
                                    onChange={() => updateFieldRaw('HOME', !addData.HOME)}
                                />
                            }
                            label={
                                <Typography variant="caption">{lang.HOME_ADDRESS[language]}</Typography>
                            }
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} justify="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={addData.BILL ? addData.BILL : false}
                                    onChange={() => updateFieldRaw('BILL', !addData.BILL)}
                                />
                            }
                            label={
                                <Typography variant="caption">{lang.BILLING_ADDRESS[language]}</Typography>
                            }
                        />
                    </Grid>
                </React.Fragment>
            }
            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    disabled={disableEdit}
                >
                    <InputLabel ref={inputLabelRegion}>{lang.REGION[language]}</InputLabel>
                    <Select
                        name="REGION"
                        labelWidth={labelWidthRegion}
                        value={addData.REGION ? addData.REGION : ""}
                        onChange={updateRegion}
                    >
                        {regionList && regionList.length > 0 && regionList.map(region => {
                            return <MenuItem key={region.ID} value={region.REGION}>{region.REGION}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    disabled={addData.REGION === "" || disableEdit}
                >
                    <InputLabel ref={inputLabelCity}>{lang.CITY[language]}</InputLabel>
                    <Select
                        name="CITY"
                        labelWidth={labelWidthCity}
                        value={(cityList.length > 0 ? (addData.CITY ? addData.CITY : "") : "")}
                        // value={""}
                        onChange={updateCity}
                    >
                        {cityList && cityList.length && cityList.map(city => {
                            return <MenuItem key={city.ID} value={city.CITY}>{city.CITY}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    required
                    fullWidth
                    variant="outlined"
                    name="STREET_NAME"
                    label={lang.STREET_NAME[language]}
                    value={addData.STREET_NAME ? addData.STREET_NAME : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="STREET_NO"
                    label={lang.STREET_NO[language]}
                    value={addData.STREET_NO ? addData.STREET_NO : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="BUILDING"
                    label={lang.BUILDING[language]}
                    value={addData.BUILDING ? addData.BUILDING : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="ENTRANCE"
                    label={lang.ENTRANCE[language]}
                    value={addData.ENTRANCE ? addData.ENTRANCE : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="FLOOR"
                    label={lang.FLOOR[language]}
                    value={addData.FLOOR ? addData.FLOOR : ""}
                    onChange={(e) => updateField(e, 4)}
                    type="number"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="APARTMENT"
                    label={lang.APARTMENT[language]}
                    value={addData.APARTMENT ? addData.APARTMENT : ""}
                    onChange={(e) => updateField(e, 4)}
                    type="number"
                />
            </Grid>
            {gps &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <TextField
                            disabled={disableEdit}
                            fullWidth
                            multiline
                            variant="outlined"
                            name="SITE_LANDMARKS"
                            label={lang.SITE_LANDMARKS[props.language]}
                            value={addData.SITE_LANDMARKS ? addData.SITE_LANDMARKS : ""}
                            onChange={(e) => updateField(e, 500)}
                        />
                    </Grid>
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Typography className={classes.title} variant='h6'>{lang.LOCALIZATION[language]}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            disabled={disableEdit}
                            fullWidth
                            variant="outlined"
                            name="LATITUDE"
                            label={"Lat."}
                            value={addData.LATITUDE ? addData.LATITUDE : ""}
                            onChange={(e) => updateField(e, 100)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            disabled={disableEdit}
                            fullWidth
                            variant="outlined"
                            name="LONGITUDE"
                            label={"Long."}
                            value={addData.LONGITUDE ? addData.LONGITUDE : ""}
                            onChange={(e) => updateField(e, 100)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            disabled={disableEdit}
                        >
                            <InputLabel ref={inputLabelArea}>{lang.AREA_NAME[language]}</InputLabel>
                            <Select
                                name="AREA_CODE"
                                labelWidth={labelWidthArea}
                                value={addData.AREA_CODE ? addData.AREA_CODE : ""}
                                onChange={updateArea}
                            >
                                {areaList && areaList.length > 0 && areaList.map(area => {
                                    return <MenuItem key={area.AREA_CODE} value={area.AREA_CODE}>{area.AREA}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    );
}