import {
    SignInUser,
    OpenConfirmWindow,
    LogoutAndRedirect
} from './authActions';
import { onNotificationOpen, setLoadedPages, setNoOfItems } from './configActions';
import * as lang from '../Constants/strings';
import { formatDateFromDb } from '../Functions/dateFunctions';
import axios from "axios";

export const TASK_LIST_REQUEST = "TASK_LIST_REQUEST";
export const TASK_LIST_RECIEVE = "TASK_LIST_RECIEVE";

export function onTaskListRequest() {
    return {
        type: TASK_LIST_REQUEST,
    }
};
export function onTaskListRecieve(data) {
    let list = [];
    data.map((obj) => {
        obj.CREATE_TIME = formatDateFromDb(obj.CREATE_TIME, 13);
        if (obj.LAST_UPDATE) {
            obj.LAST_UPDATE = formatDateFromDb(obj.LAST_UPDATE, 13);
        }
        list.push(obj);
    });
    return {
        type: TASK_LIST_RECIEVE,
        payload: {
            data: list
        }
    }
};

export function getTaskList(id, language) {
    return (dispatch) => {
        dispatch(onTaskListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_task_list?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onTaskListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    let list = [];
                    response.data.forEach(item => {
                        list.push({
                            ...item,
                            TYPE_TRANS: item.TYPE !== null ? lang[item.TYPE][language] : item.TYPE !== null,
                            STATUS_TRANS: lang[item.STATUS][language],
                            BILLING_STATUS_TRANS: item.BILLING_STATUS === '-' ? item.BILLING_STATUS : lang[item.BILLING_STATUS][language],
                            LAST_UPDATE_TRANS: formatDateFromDb(item.LAST_UPDATE, 12),
                            CREATE_TIME_TRANS: formatDateFromDb(item.CREATE_TIME, 12),
                        })
                    }
                    )
                    dispatch(onTaskListRecieve(list));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onTaskAddFailed());
                console.log('caught it!', err);
            })
    }
}

export const TASK_DATA_REQUEST = "TASK_DATA_REQUEST";
export const TASK_DATA_RECIEVE = "TASK_DATA_RECIEVE";
export const FETCH_NEW_TASKS = "FETCH_NEW_TASKS";

export function addNewTasks(data) {
    return {
        type: FETCH_NEW_TASKS,
        payload: {
            data: data
        }
    }
}

export function onTaskDataRequest() {
    return {
        type: TASK_DATA_REQUEST,
    }
};
export function onTaskDataRecieve(data) {
    return {
        type: TASK_DATA_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getTaskData(id) {
    return (dispatch) => {
        dispatch(onTaskDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_task?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onTaskDataRecieve({}));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onTaskDataRecieve(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onTaskDataRecieve({}));
                console.log('caught it!', err);
            })
    }
}

export function getActiveTaskData(id) {
    return (dispatch) => {
        dispatch(onTaskDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_active_task?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onTaskDataRecieve({}));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onTaskDataRecieve(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onTaskDataRecieve({}));
                console.log('caught it!', err);
            })
    }
}

export const TASK_ADD_BEGIN = "TASK_ADD_BEGIN";
export const TASK_ADD_SUCCESS = "TASK_ADD_SUCCESS";
export const TASK_ADD_FAILED = "TASK_ADD_FAILED";

export function onTaskAddBegin() {
    return {
        type: TASK_ADD_BEGIN,
    }
};
export function onTaskAddSuccess() {
    return {
        type: TASK_ADD_SUCCESS,
    }
};
export function onTaskAddFailed() {
    return {
        type: TASK_ADD_FAILED,
    }
};

export function addNewTask(id, data, language) {
    return (dispatch) => {
        dispatch(onTaskAddBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/add_task?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onTaskAddFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onTaskAddSuccess());
                    dispatch(getTaskList(response.data, language));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onTaskAddFailed());
                console.log('caught it!', err);
            })
    }
}

export function getNewActiveTaskList(rowsPerPage, currentPage, orderBy, order, area) {
    return (dispatch) => {
        dispatch(onTaskListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/get_active_task_list?rows=${rowsPerPage}&currentPage=${currentPage}&orderBy=${orderBy}&order=${order}&area=${area}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(addNewTasks([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(addNewTasks(response.data));
                    dispatch(setLoadedPages(response.loadedPages));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(addNewTasks([]));
                console.log('caught it!', err);
            })
    }
};

export function countActiveTasks(area) {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/get_active_tasks_count?area=${area}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(setNoOfItems(0));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(setNoOfItems(response.data));
                }
            })
            .catch(err => {
                dispatch(setNoOfItems(0));
                console.log('caught it!', err);
            })
    }
};

export function setTaskPriority(priority, id) {
    return (dispatch) => {
        const options = {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ priority: priority, taskID: id })
        }
        return fetch(`/api/set_task_priority`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}
export const TASK_ACTION_BEGIN = "TASK_ACTION_BEGIN";
export const TASK_ACTION_SUCCESS = "TASK_ACTION_SUCCESS";
export const TASK_ACTION_FAILED = "TASK_ACTION_FAILED";

export function onTaskActionBegin() {
    return {
        type: TASK_ACTION_BEGIN,
    }
};
export function onTaskActionSuccess() {
    return {
        type: TASK_ACTION_SUCCESS,
    }
};
export function onTaskActionFailed() {
    return {
        type: TASK_ACTION_FAILED,
    }
};

export function newTaskAction(id, data) {
    return (dispatch) => {
        dispatch(onTaskActionBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/task_action?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onTaskActionFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onTaskActionSuccess());
                    dispatch(getTaskData(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(() => dispatch(onTaskActionFailed()))
    }
};

export function newTaskComment(id, data) {
    return (dispatch) => {
        dispatch(onTaskActionBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/task_comment?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onTaskActionFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onTaskActionSuccess());
                    dispatch(getTaskData(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(() => dispatch(onTaskActionFailed()))
    }
};

export function resetTaskAction(id, data) {
    return (dispatch) => {
        dispatch(onTaskActionBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/reset_task?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onTaskActionFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onTaskActionSuccess());
                    dispatch(getTaskData(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onTaskActionFailed());
                console.log('caught it!', err);
            })
    }
};

export const INTERVENTION_ACTION_BEGIN = "INTERVENTION_ACTION_BEGIN";
export const INTERVENTION_ACTION_SUCCESS = "INTERVENTION_ACTION_SUCCESS";
export const INTERVENTION_ACTION_FAILED = "INTERVENTION_ACTION_FAILED";

export function onInterventionActionBegin() {
    return {
        type: INTERVENTION_ACTION_BEGIN,
    }
};
export function onInterventionActionSuccess() {
    return {
        type: INTERVENTION_ACTION_SUCCESS,
    }
};
export function onInterventionActionFailed() {
    return {
        type: INTERVENTION_ACTION_FAILED,
    }
};

export function newInterventionAction(id, data) {
    return (dispatch) => {
        dispatch(onInterventionActionBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'authorization': localStorage.token,
            },
            body: data
        }
        return fetch(`/api/intervention_action?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onInterventionActionFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onInterventionActionSuccess());
                    dispatch(getInterventionData(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onInterventionActionFailed());
            })
    }
};

export function newInterventionComment(id, data) {
    return (dispatch) => {
        dispatch(onInterventionActionBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/new_intervention_comment?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onInterventionActionFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onInterventionActionSuccess());
                    dispatch(getInterventionData(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onInterventionActionFailed());
            })
    }
};

export const INTERVENTION_ADD_BEGIN = "INTERVENTION_ADD_BEGIN";
export const INTERVENTION_ADD_SUCCESS = "INTERVENTION_ADD_SUCCESS";
export const INTERVENTION_ADD_FAILED = "INTERVENTION_ADD_FAILED";

export function onInterventionAddBegin() {
    return {
        type: INTERVENTION_ADD_BEGIN,
    }
};
export function onInterventionAddSuccess() {
    return {
        type: INTERVENTION_ADD_SUCCESS,
    }
};
export function onInterventionAddFailed() {
    return {
        type: INTERVENTION_ADD_FAILED,
    }
};

export function addNewIntervention(id, data, language) {
    return (dispatch) => {
        dispatch(onInterventionAddBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/add_intervention?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onInterventionAddFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onInterventionAddSuccess());
                    dispatch(getInterventionList(response.data, language));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(() => dispatch(onInterventionAddFailed()))
    }
}

export const INTERVENTION_LIST_REQUEST = "INTERVENTION_LIST_REQUEST";
export const INTERVENTION_LIST_RECIEVE = "INTERVENTION_LIST_RECIEVE";

export function onInterventionListRequest() {
    return {
        type: INTERVENTION_LIST_REQUEST,
    }
};
export function onInterventionListRecieve(data) {
    return {
        type: INTERVENTION_LIST_RECIEVE,
        payload: {
            data
        }
    }
};

export function getInterventionList(id, language) {
    return (dispatch) => {
        dispatch(onInterventionListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_intervention_list?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onInterventionListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    let list = [];
                    response.data.forEach(item => {
                        list.push({
                            ...item,
                            TYPE_TRANS: lang[item.TYPE][language],
                            SUBTYPE_TRANS: item.SUBTYPE ? lang[item.SUBTYPE][language] : "",
                            STATUS_TRANS: lang[item.STATUS][language],
                            LAST_UPDATE_TRANS: formatDateFromDb(item.LAST_UPDATE, 12),
                            CREATE_TIME_TRANS: formatDateFromDb(item.CREATE_TIME, 12),
                            IS_FREE_TRANS: item.IS_FREE === 1 ? 'Gratuit' : lang.NON_FREE[language]
                        })
                    }
                    )
                    dispatch(onInterventionListRecieve(list));
                }
            })
            .then(() => dispatch(SignInUser(false, false, false, true)))
            .catch(err => console.log(err))
    }
}

export const INTERVENTION_DATA_REQUEST = "INTERVENTION_DATA_REQUEST";
export const INTERVENTION_DATA_RECIEVE = "INTERVENTION_DATA_RECIEVE";

export function onInterventionDataRequest() {
    return {
        type: INTERVENTION_DATA_REQUEST,
    }
};
export function onInterventionDataRecieve(data) {
    return {
        type: INTERVENTION_DATA_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getInterventionData(id) {
    return (dispatch) => {
        dispatch(onInterventionDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_intervention?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onInterventionDataRecieve({}));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onInterventionDataRecieve(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(() => dispatch(onInterventionDataRecieve({})))
    }
}

export const UPLOAD_FILE_BEGINS = 'UPLOAD_FILE_BEGINS';
export const UPLOAD_FILE_ENDS = 'UPLOAD_FILE_ENDS';

export function startUpload() {
    return {
        type: UPLOAD_FILE_BEGINS
    }
}

export function endUpload() {
    return {
        type: UPLOAD_FILE_ENDS
    }
}

export function addQuickTask(usrId, custData, siteData, taskData) {
    return (dispatch) => {
        fetch(`/api/add_quick_task?id=${usrId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({
                custData,
                usrId,
                siteData,
                taskData,
            })
        })
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        } else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
    }
}

export const interventionPvUpload = (formData) => {
    return async (dispatch) => {
        console.log(formData.getAll("image"));
        let response = await axios.post('/api/intervention_pv_upload', formData, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'authorization': localStorage.token,
            },
        });
        await console.log(response);
    }
};
