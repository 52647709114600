import {
    SignInUser,
    OpenConfirmWindow,
    LogoutAndRedirect,
} from './authActions';
import { onNotificationOpen, setLoadedPages, setNoOfItems, } from './configActions';
import { updateLastID } from './siteActions';
import { getTaskList } from './taskActions';
import * as lang from '../Constants/strings';
import { formatDateFromDb } from '../Functions/dateFunctions';

export const ADD_CUSTOMER_BEGIN = "ADD_CUSTOMER_BEGIN";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILED = "ADD_CUSTOMER_FAILED";


export function onCustomerAddBegin() {
    return {
        type: ADD_CUSTOMER_BEGIN
    }
};
export function onCustomerAddSuccess(data) {
    return {
        type: ADD_CUSTOMER_SUCCESS,
        payload: {
            data: data
        }
    }
};
export function onCustomerAddFailed(data) {
    return {
        type: ADD_CUSTOMER_FAILED,
    }
};

export function addCustomer(id, data, rowsPerPage) {
    return (dispatch) => {
        dispatch(onCustomerAddBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_customer`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onCustomerAddFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onCustomerAddSuccess({ ...data, response, OBJID: response.data, ACTIVE: 1, STATUS: "active" }));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                    dispatch(getTaskList(response.insertSiteId, "ro"));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerAddFailed());
                console.log('caught it!', err);
            })
    }
}
export const CONTRACT_LIST_REQUEST = "CONTRACT_LIST_REQUEST";
export const CONTRACT_LIST_RECIEVE = "CONTRACT_LIST_RECIEVE";

export function onContractListRequest() {
    return {
        type: CONTRACT_LIST_REQUEST,
    }
};
export function onContractListRecieve(data) {
    return {
        type: CONTRACT_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
};
export function getContractList(id, language) {
    return (dispatch) => {
        dispatch(onContractListRequest());
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/get_contract_list?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                let list = [];
                response.data.map(item => list.push({
                    ...item,
                    SUB_TYPE_TRANS: lang[item.SUB_TYPE][language],
                    START_DATE_FORMATED: formatDateFromDb(item.START_DATE, 8),
                    END_DATE_FORMATED: formatDateFromDb(item.END_DATE, 8),
                    CANCELLED: formatDateFromDb(item.CANCELLED, 8),
                    ACTIVE_TRANS: item.ACTIVE === 1 ? lang.CONTRACT_ACTIVE[language] : lang.CONTRACT_INACTIVE[language],
                }))
                dispatch(onContractListRecieve(list));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onContractListRecieve([]));
                console.log('caught it!', err);
            })
    }
}

export const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_RECIEVE = "CUSTOMER_LIST_RECIEVE";
export const NEW_FETCH_CUSTOMER = "NEW_FETCH_CUSTOMER";


export function onCustomerListRequest() {
    return {
        type: CUSTOMER_LIST_REQUEST
    }
};
export function onCustomerListRecieve(data) {
    return {
        type: CUSTOMER_LIST_RECIEVE,
        payload: {
            data: data
        }
    }
};
export function addNewCustomersToList(data) {
    return {
        type: NEW_FETCH_CUSTOMER,
        payload: {
            data: data
        }
    }
};

export function getCustomerList(rowsPerPage, currentPage, orderBy, order, isActive = false, isValidate = false) {
    return (dispatch) => {
        dispatch(onCustomerListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
                'pragma': 'no-cache',
                'cache-control': 'no-cache'
            },
        }
        return fetch(
            `/api/get_customers?rows=${rowsPerPage}&currentPage=${currentPage}&orderBy=${orderBy}&order=${order}&isActive=${isActive}&isValidate=${isValidate}`,
            options
        )
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onCustomerListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onCustomerListRecieve(response.data));
                    dispatch(setLoadedPages(response.loadedPages));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerListRecieve([]));
                console.log('caught it!', err);
            })
    }
}

export function loadNewCustomer(rowsPerPage, currentPage, orderBy, order, isActive = false, isValidate = false) {
    return (dispatch) => {
        dispatch(onCustomerListRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/get_customers?rows=${rowsPerPage}&currentPage=${currentPage}&orderBy=${orderBy}&order=${order}&isActive=${isActive}&isValidate=${isValidate}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(addNewCustomersToList([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(addNewCustomersToList(response.data));
                    dispatch(setLoadedPages(response.loadedPages));
                }
            })
            .catch(err => {
                dispatch(addNewCustomersToList([]));
                console.log('caught it!', err);
            })
    }
}

export function getCustomerCount(isActive = false, isValidate = false) {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
                'pragma': 'no-cache',
                'cache-control': 'no-cache'
            }
        }
        return fetch(`/api/get_customers_count?isActive=${isActive}&isValidate=${isValidate}`, options)
            .then(response => { return response.json() })
            .then(response => {
                if (response.error === 1) {
                    dispatch(setNoOfItems(0));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                } else {
                    dispatch(setNoOfItems(response.data));
                }
            })
            .catch(err => {
                dispatch(setNoOfItems(0));
                console.log('caught it!', err);
            })
    }
}

export const CUSTOMER_DATA_REQUEST = "CUSTOMER_DATA_REQUEST";
export const CUSTOMER_DATA_RECIEVE = "CUSTOMER_DATA_RECIEVE";


export function onCustomerDataRequest() {
    return {
        type: CUSTOMER_DATA_REQUEST
    }
};

export function onCustomerDataRecieve(data) {
    return {
        type: CUSTOMER_DATA_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getCustomerData(id, getActive = false, getValidated = false) {
    return (dispatch) => {
        dispatch(onCustomerDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/customer?id=${id}&getActive=${getActive}&getValidated=${getValidated}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onCustomerDataRecieve({}));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onCustomerDataRecieve(response.data));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerDataRecieve({}));
                console.log('caught it!', err);
            })
    }
}


export const CUSTOMER_DATA_UPDATE_BEGIN = "CUSTOMER_DATA_UPDATE_BEGIN";
export const CUSTOMER_DATA_UPDATE_SUCCESS = "CUSTOMER_DATA_UPDATE_SUCCESS";
export const CUSTOMER_DATA_UPDATE_FAILED = "CUSTOMER_DATA_UPDATE_FAILED";

export function onCustomerDataUpdateBegin() {
    return {
        type: CUSTOMER_DATA_UPDATE_BEGIN
    }
};
export function onCustomerDataUpdateSuccess() {
    return {
        type: CUSTOMER_DATA_UPDATE_SUCCESS,
    }
};
export function onCustomerDataUpdateFailed() {
    return {
        type: CUSTOMER_DATA_UPDATE_FAILED,
    }
};

export function addCustomerAddress(id, data) {
    return (dispatch) => {
        dispatch(onCustomerDataUpdateBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ address: { ...data } })
        }
        return fetch(`/api/add_customer_address?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onCustomerDataUpdateFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onCustomerDataUpdateSuccess());
                    dispatch(getCustomerData(response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerDataUpdateFailed());
                console.log('caught it!', err);
            })
    }
}

export function addCustomerSite(id, data) {
    return (dispatch) => {
        dispatch(onCustomerDataUpdateBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/add_site?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onCustomerDataUpdateFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    console.log(response.insertSiteId);
                    dispatch(updateLastID(response.insertSiteId));
                    dispatch(onCustomerDataUpdateSuccess());
                    dispatch(getCustomerData(response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerDataUpdateFailed());
                console.log('caught it!', err);
            })
    }
}

export function editCustomer(id, data) {
    return (dispatch) => {
        dispatch(onCustomerDataUpdateBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/edit_customer?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                console.log(response)
                if (response.error === 1) {
                    dispatch(onCustomerDataUpdateFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onCustomerDataUpdateSuccess());
                    dispatch(getCustomerData(response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerDataUpdateFailed());
                console.log('caught it!', err);
            })
    }
}

export const SITE_DATA_REQUEST = "SITE_DATA_REQUEST";
export const SITE_DATA_RECIEVE = "SITE_DATA_RECIEVE";


export function onSiteDataRequest() {
    return {
        type: SITE_DATA_REQUEST
    }
};
export function onSiteDataRecieve(data) {
    return {
        type: SITE_DATA_RECIEVE,
        payload: {
            data: data
        }
    }
};

export function getSiteData(id) {
    return (dispatch) => {
        dispatch(onSiteDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/site?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteDataRecieve({}));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    if (response.data.AREA_TYPE === 'URBAN') {
                        response.data.AREA_TYPE = true
                    } else {
                        response.data.AREA_TYPE = false
                    }
                    dispatch(onSiteDataRecieve(response.data));
                    // dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onSiteDataRecieve({}));
                console.log('caught it!', err);
            })
    }
}

export const SITE_DATA_UPDATE_BEGIN = "SITE_DATA_UPDATE_BEGIN";
export const SITE_DATA_UPDATE_SUCCESS = "SITE_DATA_UPDATE_SUCCESS";
export const SITE_DATA_UPDATE_FAILED = "SITE_DATA_UPDATE_FAILED";


export function onSiteDataUpdateBegin() {
    return {
        type: SITE_DATA_UPDATE_BEGIN
    }
};
export function onSiteDataUpdateSuccess() {
    return {
        type: SITE_DATA_UPDATE_SUCCESS,
    }
};
export function onSiteDataUpdateFailed() {
    return {
        type: SITE_DATA_UPDATE_FAILED,
    }
};

export function editSiteData(id, data) {
    return (dispatch) => {
        dispatch(onSiteDataUpdateBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/edit_site?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteDataUpdateFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onSiteDataUpdateSuccess());
                    dispatch(getSiteData(response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onSiteDataUpdateFailed());
                console.log('caught it!', err);
            })
    }
}
export function removeSiteAccount(id, data) {
    return (dispatch) => {
        dispatch(onSiteDataUpdateBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/remove_site_account?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onSiteDataUpdateFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onSiteDataUpdateSuccess());
                    dispatch(getSiteData(response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onSiteDataUpdateFailed());
                console.log('caught it!', err);
            })
    }
}

export function onSearchCustomer(searchTerm, orderBy, order, getActive = false, getValidated = false) {
    return (dispatch) => {
        dispatch(onCustomerListRequest());

        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        };

        return fetch(
            `/api/search_customer?searchTerm=${searchTerm}&orderBy=${orderBy}&order=${order}&getActive=${getActive}&getValidated=${getValidated}`,
            options
        )
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onCustomerListRecieve([]));
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onCustomerListRecieve(response));
                    dispatch(setNoOfItems(response.length));
                    dispatch(setLoadedPages(30));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onCustomerListRecieve([]));
                console.log('caught it!', err);
            })
    }
};

export const CONTRACT_ADD_BEGIN = "CONTRACT_ADD_BEGIN";
export const CONTRACT_ADD_SUCCESS = "CONTRACT_ADD_SUCCESS";
export const CONTRACT_ADD_FAILED = "CONTRACT_ADD_FAILED";

export function onContractAddBegin() {
    return {
        type: CONTRACT_ADD_BEGIN,
    }
};
export function onContractAddSuccess() {
    return {
        type: CONTRACT_ADD_SUCCESS,
    }
};
export function onContractAddFailed() {
    return {
        type: CONTRACT_ADD_FAILED,
    }
};

export function addCustomerContract(id, data, language) {
    return (dispatch) => {
        dispatch(onContractAddBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/add_contract?id=${id}`, options)
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    dispatch(onContractAddFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onContractAddSuccess());
                    dispatch(onContractListRequest());
                    dispatch(getContractList(response.data, language));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onContractAddFailed());
                console.log('caught it!', err);
            })
    }
}
export function addUpdatedContract(id, origId, data) {
    return (dispatch) => {
        dispatch(onContractAddBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data })
        }
        return fetch(`/api/update_contract?id=${id}&origId=${origId}`, options)
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    dispatch(onContractAddFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onContractAddSuccess());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .catch(err => {
                dispatch(onContractAddFailed());
                console.log('caught it!', err);
            })
    }
}
export function terminateContract(id, origId, date, comment) {
    return (dispatch) => {
        dispatch(onContractAddBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/terminate_contract?id=${id}&origId=${origId}&date=${date}&comment=${comment}`, options)
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .catch(err => {
                console.log('caught it!', err);
            })
        //  this can be the root of the problem
        //             .then(() => {
        // dispatch(SignInUser(false, false, false, true));
        // })
    }
}

export function onCustomerValidation(id) {
    return (dispatch) => {
        const options = {
            method: "GET",
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/customer_validation?id=${id}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}
