import {useEffect, useState} from "react";
import {Button, Divider, Grid, TextField, Typography} from "@material-ui/core";
import {bindActionCreators} from "redux";
import { connect } from "react-redux";

import * as actionCreator from "../../actions/actions";
import * as lang from "../../Constants/strings";
import CustomModal from "../Modals/CustomModal";
import {getGetHeader, getPostHeader} from "../../Constants/requestHeaders";
import axios from "axios";
import UserScoreForm from "./Components/UserScoreForm";
import TaskCommentHistory from "../Modals/TaskCommentHistory";
import {checkPermissions} from "../../Functions/permissionHide";
import {googleMapRouteLink} from "../../Functions/getGoogleMapLink";

const GpsButton = (props) => {
  const [startPosition, setStartPosition] = useState('');
  const [endPosition, setEndPosition] = useState('');
  const [valid, setValid] = useState(false);

  const { gps } = props;

  useEffect(() => {
    const startIndex = gps.findIndex(e => e.CHG_TO === 5);
    const endIndex = gps.findIndex(e => e.CHG_TO === 6);

    if(startIndex === -1 || endIndex === -1) {
      setValid(false);
      setEndPosition('');
      setStartPosition('');
    }

    setStartPosition(gps[startIndex]);
    setEndPosition(gps[endIndex]);
    setValid(true);
  }, [gps]);

  const pushToGoogleMaps = () => {
    const win = window.open(googleMapRouteLink(startPosition.LATITUDE, startPosition.LONGITUDE, endPosition.LATITUDE, endPosition.LONGITUDE), '_blank');
    if (win) {
      win.focus();
    }
  }
  return (
    <div style={{ width: "100%" }}>
      {!valid && <Typography variant="h5" style={{ marginLeft: "50px", color: "red" }}>Nu s-a putut detecta locația acțiunilor!</Typography>}
      {valid && <Button variant="contained" color="primary" fullWidth onClick={() => pushToGoogleMaps()}>Vezi traseu</Button>}
    </div>
  )
}

/**
 * The modal use to validate the soring of the user
 * @param props { taskId }
 * @returns {JSX.Element}
 * @constructor
 */
const ScoringModal = (props) => {
  const {
    language,
    taskId,
    open,
    close,
    value
  } = props;

  const [history, setHistory] = useState([]);
  const [userThatDontHaveScores, setUsersThatDontHaveScores] = useState(0);
  const [historyEntriesKey, setHistoryEntriesKey] = useState([]);
  const [taskValue, setTaskValue] = useState(0);
  const [remainingTaskValue, setRemainingTaskValue] = useState(0);

  const [teamObservation, setTeamObservation] = useState('');

  const [teamDistance, setTeamDistance] = useState({});
  const [teamScores, setTeamScores] = useState({});

  const [taskHistory, setTaskHistory] = useState([])
  const [commentModal, setCommentModal] = useState(false);
  
  const getTaskHistory = async (id) => {
    try {
      const res = await axios.get('/api/technical/task/history/' + id, getGetHeader());
      setTaskHistory(res.data.history);
    } catch (e) {
      console.log(e);
    }
  }

  const getScoringHistory = async () => {
    try{
      const res = await axios.get('/api/history/' + taskId, getGetHeader());
      setHistory(res.data.history);
      setHistoryEntriesKey(Object.keys(res.data.history));

      let index = 0;
      const distances = {};
      const observations = {};

      for(const c of Object.keys(res.data.history)) {
        console.log(res.data.history[c])
        distances[c] = res.data.history[c][0].DISTANCE_KM ? res.data.history[c][0].DISTANCE_KM : 0;
        observations[c] = res.data.history[c][0].OBSERVATION ? res.data.history[c][0].OBSERVATION : '';
        index += res.data.history[c].length;
      }

      setUsersThatDontHaveScores(index);
      setTeamDistance(distances);
      setTeamObservation(observations)
    } catch (e) {
      console.log(e);
    }
  }

  const getTaskValue = async () => {
    try {
      const res = await axios.get('/api/task/value/' + taskId, getGetHeader());
      setTaskValue(res.data.task_value.value);
    } catch (e) {
      console.log(e);
    }
  }

  const setUsersDistances = (event, key) => {
    const tmp = {...teamDistance};
    tmp[key] = parseInt(event.target.value, 10);
    setTeamDistance(tmp);
  }

  const setUsersObservation = (event, key) => {
    const tmp = {...teamObservation};
    tmp[key] = event.target.value;
    setTeamObservation(tmp);
  };

  const setUserScore = (value, key, index) => {
    const tmp = { ...teamScores};
    const newVal = parseInt(value, 10);
    tmp[key][index].value = newVal;
    setTeamScores(tmp);
  }

  const commitScores = async () => {
    if(remainingTaskValue < 0) {
      if(!window.confirm(lang.TASK_EXCEED_VALUE[language])) {
        return;
      }
    }

    const reqBody = {
      taskId,
      scores: [],
    };

    for(const key of historyEntriesKey) {
      const team_scores = {
        teamId: history[key].length > 0 ? history[key][0].TEAM_ID : null,
        distance: teamDistance[key],
        observation: teamObservation[key],
        scores: []
      };

      let index = 0;
      for(const score of teamScores[key]) {
        team_scores.scores.push({
          score: teamScores[key][index].value,
          userId: history[key][index].USER_ID,
        });
        index++;
      }
      reqBody.scores.push(team_scores);
    }

    try {
      await axios.post('/api/task/technical/scores', reqBody, getPostHeader());
      props.actions.onNotificationOpen("success", lang.DATA_UPDATE_SUCCESS, 6000);
      close();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getScoringHistory();
    getTaskValue();
  }, []);

  /**
   * Compute the score for team base on task Value
   */
  useEffect(() => {

    const scores = {};
    let numberOfUsers = userThatDontHaveScores;
    let remaingValue = value ? value : taskValue;

    /**
     * Get the number of users that don't have a score
     * Add the remaining value of the ticket
     */
    for(const c of Object.keys(history)) {
      for (const e of history[c]) {
        if (e.SCORE !== 0) {
          numberOfUsers = numberOfUsers - 1;
          remaingValue = remaingValue - e.SCORE;
        }
      }
    }

    for(const c of Object.keys(history)) {
      scores[c] = [];
      for (const e of history[c]) {
        scores[c].push({
          value: e.SCORE !== 0 ? e.SCORE : +(remaingValue  / numberOfUsers ).toFixed(2),
          editable: true
        });
      }
    }

    setTeamScores(scores);
  }, [value, taskValue, userThatDontHaveScores, history])

  /**
   * Will update the recalculated task value at every team score update
   */
  useEffect(() => {
    if(Object.keys(teamScores).length !== 0 || Object.keys(history) != 0) {
      let tmp_value = value ? +value : +taskValue;
      for (const c of Object.keys(history)) {
        if(teamScores[c]) {
          for (const e of teamScores[c]) {
            if (e.value) {
              tmp_value = tmp_value - e.value;
            }
          }
        }
      }
      setRemainingTaskValue(+tmp_value.toFixed(2));
    }
  }, [teamScores, history]);

  useEffect(() => {
    const id = taskId ? taskId : historyEntriesKey.length > 0 ? history[historyEntriesKey[0]][0].TASK_ID : null;
    if(id) {
      getTaskHistory(id);
    }
  }, [taskId]);

  return(
    <CustomModal
      fullWidth
      open={open}
      close={() => {
        close()
      }}
      title={"#" + (taskId)}
      content={
        <Grid container spacing={2}>
          <Typography variant="h5">
            {lang.TECHNICAL_TEAM_MODAL_SCORE_MESSAGE[language] + (historyEntriesKey.length > 0 ? history[historyEntriesKey[0]][0].TASK_ID : '-')}
          </Typography>
          {historyEntriesKey.map((key, index) => (
            <Grid style={{marginTop: "15px"}} key={`User-score-${index}`} container>
              <Divider xs={12}/>
              <Typography variant="h6" style={{ margin: "10px" }}>
                {key.toUpperCase()}
              </Typography>
              <TextField
                type="number"
                label={lang.DISTANCE_KM[language]}
                variant="outlined"
                style={{ margin: "10px", width: '90%'}}
                value={teamDistance[key]}
                onChange={(e) => setUsersDistances(e, key)}
              />
              <TextField
                label={lang.OBSERVATION[language]}
                variant="outlined"
                style={{ margin: "10px", width: '90%'}}
                value={teamObservation[key]}
                onChange={(e) => setUsersObservation(e, key)}
              />
              {
                history[key][0].GPS && <GpsButton gps={history[key][0].GPS}/>
              }
              {
                history[key].map((e, index) => (
                  <UserScoreForm
                    userScoreHistory={e}
                    language={language}
                    value={teamScores[key][index].value}
                    editable={teamScores[key][index].editable}
                    setValue={(event) => setUserScore(event.target.value, key, index)}
                  />
                ))
              }
            </Grid>
          ))}
          <Grid container alignContent="center">
            <Grid xs={6}>
              <Typography variant={"subtitle1"}>
                {lang.TASK_COMPLETE_VALUE[language] + " : " + (value ? value : (taskValue ? taskValue: "0")) + " (RON)"}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography variant={"subtitle1"}>
                {lang.TASK_REMAINING_VALUE[language] + " : " + (remainingTaskValue ? remainingTaskValue : "0") + " (RON)"}
              </Typography>
            </Grid>
          </Grid>
          <Grid contaienr style={{ marginTop: "20px", width: "100%" }} alignContent="center">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setCommentModal(true)}
            >
              {lang.COMMENT_TASK_HISTORY[language]}
            </Button>
          </Grid>
          <TaskCommentHistory
            open={commentModal}
            tiny={true}
            calasses={{}}
            data={taskHistory}
            language={language}
            close={() => setCommentModal(false)}
          />
        </Grid>
      }
      execute={() => commitScores()}
      language={language}
      validate={checkPermissions(props.permissions, { parent: "TECHNICAL_TEAM", name: "VIEW_AND_EDIT_TECHNICAL_SCORE"}) || checkPermissions(props.permissions, { parent: "TECHNICAL_TEAM", name: "VIEW_AND_EDIT_TECHNICAL_SCORE_HISTORY"})}
    />
  )
}

const mapStateToProps = (state) => ({
  language: state.config.language,
  theme: state.config.theme,
  isAuthenticated: state.auth.isAuthenticated,
  permissions: state.auth.permissions,
  userAuth: state.auth.userData
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScoringModal);
