import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "./actions/actions";
import * as lang from "./Constants/strings";
import history from "./history";
import "./App.css";

import Layout from "./Components/Misc/Layout";
import PasswordUpdateForm from "./Components/Misc/PasswordUpdateForm";
import SessionExpiredForm from "./Components/Misc/SessionExpiredForm";
import CustomizedSnackbars from "./Components/Misc/CustomizedSnackbars";
import CustomModal from "./Components/Modals/CustomModal";

import SignInView from "./MainView/SignIn/SignInView";
import HomeView from "./MainView/Home/HomeView";
import ProfileView from "./MainView/Profile/ProfileView";
import NotFoundView from "./MainView/NotFound/NotFoundView";
import UserView from "./MainView/User/UserView";
import ExecutorNom from "./MainView/Nomenclature/Executors/ExecutorNom";
import RoleNom from "./MainView/Nomenclature/Roles/RoleNom";
import AreaNom from "./MainView/Nomenclature/Areas/AreasNom";
import SiteTypeNom from "./MainView/Nomenclature/SiteType/SiteTypeNom";
import DepartmentsNom from "./MainView/Nomenclature/Departments/DepartmentsNom";
import DashboardView from "./DashboardView/Dashboard/DashboardView";
import CustomersView from "./MainView/Customer/CustomersView";
import CustomerSingleView from "./MainView/Customer/CustomerSingleView";
import SiteSingleView from "./MainView/Site/SiteSingleView";
import SiteView from "./MainView/Site/SitesView";
import TaskView from "./MainView/Tasks/TaskView";
import ReportsView from "./MainView/Reports/ReportsView";
import InterventionsView from "./MainView/Interventions/InterventionsView";
import AccountingView from "./MainView/Accounting";
import MailingNom from "./MainView/Nomenclature/Mailing/MailingNom";
import BillHistory from "./MainView/BillingHistory/BillingHistory";
import TemplateFilesView from "./MainView/TemplateFiles/TemplateFilesView";
import MaintenanceView from "./MainView/Maintenance/MaintenanceView";
import TechnicManagement from "./MainView/TechnicManagement";
import AreaManagement from "./MainView/AreaManagement";
import Project from "./MainView/Projects";
import RiscEvaluation from "./MainView/RiscEvaluation";
import ChangelogPage from "./MainView/Changelog";
import InstallationView from "./MainView/Installation/InstallationView"
import UserScoreView from "./MainView/TechnicalTeams/UserScoreView";
import ImageViewer from "./MainView/ImageViewer";

const MainRoute = ({ match }) => {
  return (
    <Layout>
      <Switch>
        <Route exact path={match.url} component={HomeView} />
        <Route exact path={"/profile"} component={ProfileView} />
        <Route exact path={"/customers"} component={CustomersView} />
        <Route exact path={"/customer"} component={CustomerSingleView} />
        <Route exact path={"/site"} component={SiteSingleView} />
        <Route exact path={"/sites"} component={SiteView} />
        <Route exact path={"/tasks"} component={TaskView} />
        <Route exact path={"/reports"} component={ReportsView} />
        <Route exact path={"/interventions"} component={InterventionsView} />
        <Route exact path={"/accounting"} component={AccountingView} />
        <Route exact path={"/bill_history"} component={BillHistory} />
        <Route exact path={"/templates"} component={TemplateFilesView} />
        <Route exact path={"/maintenance"} component={MaintenanceView} />
        <Route exact path={"/technic_management"} component={TechnicManagement} />
        <Route exact path={"/area_management"} component={AreaManagement} />
        <Route exact path={"/project"} component={Project} />
        <Route exact path={"/risc_evaluation"} component={RiscEvaluation} />
        <Route exact path={"/changelog"} component={ChangelogPage} />
        <Route exact path={"/installation"} component={InstallationView} />
        {/*<Route exact path={"/technical-teams"} component={TechnicalTeamsPage} />*/}
        <Route exact path={"/technical-user-score"} component={UserScoreView} />
        <Route path={"/site-image-viewer/:id"} component={ImageViewer} />
        <Route component={NotFoundView} />
      </Switch>
    </Layout>
  );
};
const DashboardRoute = ({ match }) => {
  return (
    <Layout admin>
      <Switch>
        <Route exact path={match.url} component={DashboardView} />
        <Route exact path={match.url + "/users"} component={UserView} />
        <Route exact path={match.url + "/executors"} component={ExecutorNom} />
        <Route exact path={match.url + "/roles"} component={RoleNom} />
        <Route exact path={match.url + "/areas"} component={AreaNom} />
        <Route
          exact
          path={match.url + "/departments"}
          component={DepartmentsNom}
        />
        <Route exact path={match.url + "/site_types"} component={SiteTypeNom} />
        <Route
          exact
          path={match.url + "/mailing_list"}
          component={MailingNom}
        />
        <Route component={NotFoundView} />
      </Switch>
    </Layout>
  );
};

class App extends Component {
  state = {
    confirmPassword: "",
    password: {
      OLD_PASSWORD: {
        value: "",
        helperText: "",
        error: false,
      },
      NEW_PASSWORD: {
        value: "",
        helperText: "",
        error: false,
      },
      NEW_PASSWORD2: {
        value: "",
        helperText: "",
        error: false,
      },
    },
  };

  updatePasswordField = (e) => {
    const { name, value } = e.target;
    this.setState({
      password: {
        ...this.state.password,
        [name]: {
          ...this.state.password[name],
          value: value,
        },
      },
    });
  };

  onFocus = (e) => {
    const { name } = e.target;
    this.setState({
      password: {
        ...this.state.password,
        [name]: {
          ...this.state.password[name],
          helperText: false,
          error: false,
        },
      },
    });
  };

  onBlur = (e) => {
    const { name } = e.target;
    this.setState({
      password: {
        ...this.state.password,
        [name]: {
          ...this.state.password[name],
          helperText: this.validateField(e),
          error: this.validateField(e) !== "",
        },
      },
    });
  };

  validateField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "OLD_PASSWORD": {
        if (value.length < 1) return lang.EMPTY_FIELD[this.props.language];
        return "";
      }
      case "NEW_PASSWORD": {
        if (value.length < 1) return lang.EMPTY_FIELD[this.props.language];
        if (value.length < 8) return lang.SHORT_FIELD[this.props.language];
        if (value === this.state.password.OLD_PASSWORD.value)
          return lang.SAME_FIELD[this.props.language];
        return "";
      }
      case "NEW_PASSWORD2": {
        if (value.length < 1) return lang.EMPTY_FIELD[this.props.language];
        if (value.length < 8) return lang.SHORT_FIELD[this.props.language];
        if (value !== this.state.password.NEW_PASSWORD.value)
          return lang.CONFIRM_FIELD[this.props.language];
        return "";
      }
      default:
        return;
    }
  };

  displayPosition = (position) => {
    this.props.actions.onGeolocationUpdate({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
      error: false,
    });
    console.log(
      `Tracking (${Math.floor(position.coords.accuracy) + 1}m accuracy)...`
    );
  };

  displayError = (error) => {
    var errors = {
      1: "Permission denied",
      2: "Position unavailable",
      3: "Request timeout",
    };
    this.props.actions.onGeolocationUpdate({
      latitude: null,
      longitude: null,
      accuracy: null,
      error: errors[error.code],
    });
    console.log("Error: " + errors[error.code]);
  };

  componentWillMount = () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        this.displayPosition,
        this.displayError,
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    } else console.log("Geolocation is not supported!");
  };

  render() {
    const theme = createMuiTheme({
      palette: {
        type: this.props.theme,
      },
    });
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <CustomModal
            open={
              this.props.isAuthenticated &&
              this.props.userAuth.pass_update === null
            }
            title={lang.UPDATE_PASSWORD_PRIMARY[this.props.language]}
            content={lang.UPDATE_PASSWORD_SECONDARY[this.props.language]}
            content2={
              <PasswordUpdateForm
                password={this.state.password}
                language={this.props.language}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                updatePasswordField={this.updatePasswordField}
              />
            }
            close={() => {
              this.props.actions.LogoutAndRedirect();
            }}
            execute={() => {
              this.props.actions.updateUserPassword(
                this.props.userAuth.id,
                this.state.password
              ) && this.props.actions.LogoutAndRedirect();
            }}
            nonDismissible
            twoStep
            language={this.props.language}
            validate={true}
            noButtons
          />
          <CustomModal
            open={this.props.confirmLogout}
            title={lang.EXIPIRED_SESSION[this.props.language]}
            content={lang.CONFIRM_PASSWORD[this.props.language]}
            aux={
              <SessionExpiredForm
                value={this.state.confirmPassword}
                onChange={(e) =>
                  this.setState({ confirmPassword: e.target.value })
                }
                language={this.props.language}
                execute={() => {
                  this.props.actions.SignInUser(
                    this.props.username,
                    this.state.confirmPassword,
                    false,
                    false
                  );
                }}
              />
            }
            close={() => {
              this.props.actions.LogoutAndRedirect();
            }}
            execute={() => {
              this.props.actions.SignInUser(
                this.props.username,
                this.state.confirmPassword,
                false,
                false
              );
            }}
            nonDismissible
            validate={true}
            language={this.props.language}
          />
          <CustomizedSnackbars
            open={this.props.open}
            message={this.props.message}
            variant={this.props.variant}
            autoHide={this.props.autoHide}
            closeFunction={this.props.actions.onNotificationClose}
          />
          <Switch history={history}>
            <Route exact path="/sign_in" component={SignInView} />
            <Route path="/home" component={MainRoute} />
            <Route path="/dashboard" component={DashboardRoute} />
            <Route path="/" component={MainRoute} />
            <Route path="/clients" component={MainRoute} />
            <Route component={NotFoundView} />
          </Switch>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.config.theme,
    open: state.config.notificationOpen,
    message: state.config.notificationMessage,
    variant: state.config.notificationVariant,
    autoHide: state.config.notificationAutoHide,
    language: state.config.language,
    isAuthenticated: state.auth.isAuthenticated,
    userAuth: state.auth.userData,
    confirmLogout: state.auth.confirmLogout,
    username: state.auth.username,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

