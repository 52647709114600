import React from 'react';
import { TextField, IconButton, Grid } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import { CreateRounded, CheckRounded } from '@material-ui/icons';
import CustomModal from '../../../Components/Modals/CustomModal';
import SignaturePad from 'react-signature-canvas';

export default function TaskHandover(props) {
    const {
        data,
        language,
        update,
        classes,
        index,
        tiny
    } = props;

    const [sign, setSign] = React.useState(false);
    const signCanvas = React.useRef({});

    return (
        <React.Fragment>
            <CustomModal
                fullWidth
                fullScreen={tiny}
                open={sign === index}
                content={
                    <Grid item xs={12}>
                        <SignaturePad
                            ref={signCanvas}
                            canvasProps={{
                                className: classes.signature
                            }}
                        />
                    </Grid>
                }
                execute={() => {
                    update('SIGN', signCanvas.current.getTrimmedCanvas().toDataURL("image/svg+xml"));
                    signCanvas.current.clear()
                    setSign(false);
                }}
                close={() => {
                    signCanvas.current.clear()
                    setSign(false);
                }}
                language={language}
                validate={true}
            />
            <Grid container justify="space-between" className={classes.float} spacing={2}>
                <Grid item xs={12} md={5}>
                    <TextField
                        fullWidth
                        required
                        disabled={data.SIGN !== ""}
                        variant="outlined"
                        value={data.NUMBER}
                        label={lang.NUMBER[language]}
                        onChange={(e) => update('NUMBER', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <TextField
                        fullWidth
                        required
                        disabled={data.SIGN !== ""}
                        variant="outlined"
                        value={data.NAME}
                        label={lang.FULL_NAME[language]}
                        onChange={(e) => update('NAME', e.target.value)}
                    />
                </Grid>
                <input
                    onChange={() => 1 + 0}
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, width: 0 }}
                    value={data.SIGN === null ? "" : data.SIGN}
                    required
                />
                <Grid item xs='auto'>
                    <IconButton
                        color={data.SIGN === "" ? "primary" : "default"}
                        disabled={data.SIGN !== "" || data.NAME.length < 5 || data.NUMBER.length === 0}
                        onClick={() => setSign(index)}
                    >
                        {data.SIGN === "" ? <CreateRounded /> : <CheckRounded />}
                    </IconButton>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
