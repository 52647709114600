import React, { useCallback } from 'react';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { intervention_data } from '../../../Components/Misc/Tables/TableHeaders';
import * as lang from '../../../Constants/strings';
import { getStatusIcon } from '../../../Functions/getIcon';
import { startInterval } from './helpers';
import axios from "axios";
import * as headers from "../../../Constants/requestHeaders";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
        width: 'auto'
    },
}));

export default function AlarmTable(props) {
    const theme = useTheme();
    const classes = useStyles();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const [alarms, setAlarms] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        if (props.userAreaList.length > 0) {
            fetchDataForAreaList();
            startInterval(fetchDataForAreaList, false);

            return () => {
                startInterval(fetchData, true);
            }
        }
    }, [props.firstLoad]);

    React.useEffect(() => {
        if (!props.firstLoad && props.userAreaList.length < 1) {
            fetchData()
            startInterval(fetchData, false);

            return () => {
                startInterval(fetchData, true);
            }
        };
    }, [props.selectedArea, props.firstLoad]);

    React.useEffect(() => {
        if (!props.interModalStatus) {
            fetchData();
        }
    }, [props.interModalStatus])

    const fetchDataForAreaList = async () => {
        const result = await axios.get("/api/get_active_inter_list_by_area_list", {
            ...headers.getDeleteHeader(),
            params: {
                areaList: props.userAreaList,
                type: "ALARM"
            }
        });

        if (result.status === 200 || result.status === 304) {
            setAlarms(result.data.data)
        } else {
            setAlarms([]);
        }
    };

    const fetchData = () => {
        let url = props.selectedArea && props.selectedArea !== null
            ? `/api/get_active_inter_list?type=ALARM&area_code=${props.selectedArea}`
            : `/api/get_active_inter_list?type=ALARM`;

        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    setAlarms([]);
                } else {
                    response.data.map(data => {
                        data.SUBTYPE = data.SUBTYPE ? lang[data.SUBTYPE][props.language] : "";
                        data.IS_FREE_TRANS = data.IS_FREE === 1 ? 'Gratuit' : lang.NON_FREE[props.language];
                    });
                    let data = getStatusIcon(response.data)
                    setAlarms(data);
                }
            })
    };

    // get the table header
    const getHeader = useCallback(() => {
        let header = intervention_data(props.language);
        // if the user has the permission insert in the header the column for intervention status
        if (props.hasPermissionToViewStatus) {
            header.splice(6, 0, {
                label: lang.BILLING[props.language],
                id: 'IS_FREE_TRANS',
                numeric: false,
                disablePadding: false,
            });
        };
        return header;
    }, [props.hasPermissionToViewStatus, props.language]);

    return (
        <React.Fragment>
            <Grid container component="main" className={classes.margin} spacing={tiny ? 1 : 4}>
                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.ACTIVE_ALARMS[props.language]}
                        language={props.language}
                        data={alarms}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={setRowsPerPage}
                        header={getHeader()}
                        hideAddBtn
                        openRowModal={props.openRowModal}
                        clickLabel="OBJID"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
