export const ALARMS = {
    "en": 'Alarms',
    "ro": "Alarme"
};

export const ALARM_TABLE = {
    "en": "Alarm table",
    "ro": "Tabel alarme"
};

export const NEW_ALARM = {
    "en": "New alarm",
    "ro": "Alarmă nouă"
};

export const  WATCH_AND_ALARM_HISTORY = {
    "en": "Alarm History",
    "ro": "Istoric alarme/patrulare"
};

export const RESOLUTION = {
    "en": "Resolutio",
    "ro": "Rezoluție"
};

export const VIEW_SITE_ALARMS =  {
    "en": "View site alarms",
    "ro": "Vezi alarmele obiectivului"
};

export const VIEW_INTERVENTION_DOCS = {
    "en": "View intervention docs",
    "ro": "Vizualizare documente alarme"
};

export const ALARM_TYPE = {
    "en": "Alarm type",
    "ro": "Tip alarmă"
};

export const ALARM_HISTORY = {
    "en": "Alarm history",
    "ro": "Istoric alarme"
};