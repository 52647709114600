import { Grid, Button, Typography } from "@material-ui/core";

const AddItem = (props) => {
    const {
        quantity,
        productName,
        um,
        onClick
    } = props;

    return (
        <Button 
            fullWidth
            variant="contained"
            color="primary"
            style={{ padding: "10px", marign: "15px", borderRadius: "1px solid #fff" }}
            onClick={() => onClick()}
        >
            <Grid container style={{ padding: "10px" }}>
                <Grid item xs={6} style={{ textAlign: "start" }}>
                    <Typography variant="h6">+ {productName}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "end"}}>
                    <Typography>{`${quantity} (${um})`}</Typography>
                </Grid>
            </Grid>
        </Button>
    )
} 

export default AddItem;