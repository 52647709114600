import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../Constants/strings';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import axios from 'axios';
import { checkSpecialChar } from '../../../Functions/stringManipulation';

const useStyles = makeStyles(theme => ({
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));

export default function UserNewForm(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(35);
    const inputLabelDep = React.useRef(null);
    const [labelWidthDep, setLabelWidthDep] = React.useState(35);
    const [roleRender, setRoleRender] = React.useState([]);
    const [depRender, setDepRender] = React.useState([]);
    const { addUserData, language, roleList, departmentList, userErrorUpdate, userError } = props;
    const [error, setError] = React.useState();

    function checkUserAvailability(user) {
        if (user)
            axios.get(`/api/check_user?user=${user}`)
                .then(res => {
                    userErrorUpdate(parseInt(res.data, 10) > 0);
                    if (parseInt(res.data, 10) > 0)
                        setError("USER_DUPLICATE");
                    if (user.trim().length < 8) {
                        setError("SHORT_FIELD");
                        userErrorUpdate(true);
                    }
                    if (checkSpecialChar(user.trim(), "*|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        userErrorUpdate(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });
        else {
            setError("EMPTY_FIELD");
            userErrorUpdate(true);
        }
    }
    function focus() {
        userErrorUpdate(null);
        setError(null);
    }

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        setLabelWidthDep(inputLabelDep.current.offsetWidth);
    }, []);

    React.useEffect(() => {
        let placeholder = [];
        Object.keys(roleList).forEach((key, index) => {
            placeholder.push(
                <MenuItem key={index} value={key}>
                    {roleList[key]}
                </MenuItem>
            )
        })
        setRoleRender(placeholder);
    }, [roleList]);

    React.useEffect(() => {
        let placeholder = [];
        if (departmentList)
            departmentList.forEach((e, index) =>
                placeholder.push(
                    <MenuItem key={index} value={e.ID}>
                        {e.NAME}
                    </MenuItem>
                )
            )
        setDepRender(placeholder);
    }, [departmentList]);

    React.useEffect(() => {
        setError(null);
    }, [addUserData.USERNAME]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <div style={{ position: 'relative' }}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="USERNAME"
                        label={lang.USERNAME[language]}
                        value={addUserData.USERNAME ? addUserData.USERNAME : ""}
                        onChange={(e) => props.updateField(e, 100)}
                        onFocus={focus}
                        error={userError}
                        helperText={error ? lang[error][language] : null}
                        onBlur={() => checkUserAvailability(addUserData.USERNAME)}
                    />
                    {userError === true
                        ?
                        <ErrorRoundedIcon className={classes.clear} color="secondary" />
                        :
                        userError === false
                            ?
                            <CheckCircleRoundedIcon className={classes.clear} />
                            :
                            null
                    }
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel ref={inputLabelDep} id="demo-simple-select-outlined-label">
                        {lang.DEPARTMENT_NAME[props.language]}
                    </InputLabel>
                    <Select
                        name="DEPARTMENT_OBJID"
                        value={addUserData.DEPARTMENT_OBJID ? addUserData.DEPARTMENT_OBJID : ""}
                        labelWidth={labelWidthDep}
                        // onChange={(e) => console.log(e.target)}
                        onChange={(e) => props.updateField(e, 10)}
                    >
                        <MenuItem value={0}><em>niciunul</em></MenuItem>
                        {depRender}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="LAST_NAME"
                    label={lang.LAST_NAME[language]}
                    value={addUserData.LAST_NAME ? addUserData.LAST_NAME : ""}
                    onChange={(e) => props.updateField(e, 100)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="FIRST_NAME"
                    label={lang.FIRST_NAME[language]}
                    value={addUserData.FIRST_NAME ? addUserData.FIRST_NAME : ""}
                    onChange={(e) => props.updateField(e, 100)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="PHONE_PERSONAL"
                    label={lang.PHONE_PERSONAL[language]}
                    value={addUserData.PHONE_PERSONAL ? addUserData.PHONE_PERSONAL : ""}
                    type="number"
                    onChange={(e) => props.updateField(e, 20)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="PHONE_WORK"
                    label={lang.PHONE_WORK[language]}
                    value={addUserData.PHONE_WORK ? addUserData.PHONE_WORK : ""}
                    type="number"
                    onChange={(e) => props.updateField(e, 20)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="EMAIL"
                    label={lang.EMAIL[language]}
                    value={addUserData.EMAIL ? addUserData.EMAIL : ""}
                    onChange={(e) => props.updateField(e, 100)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                        {lang.ROLE[props.language]}
                    </InputLabel>
                    <Select
                        name="ROLE"
                        value={addUserData.ROLE ? addUserData.ROLE : ""}
                        labelWidth={labelWidth}
                        // onChange={(e) => console.log(e.target)}
                        onChange={(e) => props.updateField(e, 10)}
                    >
                        <MenuItem key={-1} value=""><em>niciunul</em></MenuItem>
                        {roleRender}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}