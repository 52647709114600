import React, { useState } from 'react';
import { makeStyles, Grid, TextField, Box, Paper, Typography, Grow, Chip, IconButton, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PhoneRounded, EmailRounded, FaceRounded, BusinessRounded, HomeRounded, ReceiptRounded, SettingsRounded, PersonRounded } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import { addressToString } from '../../../Functions/objectManipulation';
import CustomDrawer from '../../../Components/Modals/CustomDrawer';
import { EditRounded, BusinessCenterRounded, AccountCircleRounded, FormatListNumberedRounded, VerifiedUser, RemoveCircleRounded } from '@material-ui/icons';
import CustomModal from '../../../Components/Modals/CustomModal';
import CustomButtonList from '../../../Components/Modals/CustomButtonList';
import Can from '../../../Components/Misc/Can';
import { checkPermissions } from '../../../Functions/permissionHide';
import axios from "axios";
import history from "../../../history";

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
    },
    title: {
        margin: theme.spacing(3),
    },
    subtitle: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    links: {
        textDecoration: "none",
        color: 'white',
    },
}));

function CustomerSettingsModal(props) {
    const { customer, tiny, language, classes, data, userPermissions } = props;
    const [customerDeleteModal, setCustomerDeleteModal] = useState(false);
    const [key, setKey] = useState("");

    const deleteCustomer = async () => {
        try {
            const result = await axios.delete('/api/customer', {
                headers: {
                    authorization: `Beare ${key}`
                },
                params: {
                    id: data.ID
                }
            });
            history.push("/customers");
        } catch (e) {
            console.error(e);
        };
    };

    return (
        <React.Fragment>
            {
                checkPermissions(props.userPermissions, { parent: "SUPER", name: "SUPER_DELETE" }) &&
                <CustomModal
                    open={customerDeleteModal}
                    fullWidth
                    close={() => setCustomerDeleteModal(false)}
                    content={
                        <Grid alignItems="center" container spacing={2}>
                            <TextField
                                value={key}
                                fullWidth
                                variant="outlined"
                                onChange={e => setKey(e.target.value)}
                                placeholder="Key-ul de ștergere"
                            />
                        </Grid>
                    }
                    language={props.language}
                    execute={deleteCustomer}
                    validate={key.length > 0}
                />
            }
            <CustomDrawer
                open={customer.settingsModal && tiny}
                close={customer.closeSettingsModal}
                list={[
                    {
                        primary: lang.EDIT_CUSTOMER[language],
                        secondary: "",
                        icon: <EditRounded />,
                        action: customer.openEditModal,
                        render: true,
                    },
                    {
                        primary: lang.ACTION_INVOICE[language],
                        secondary: "",
                        icon: <ReceiptRounded />,
                        action: customer.openInvoiceAppendixModal,
                        render: true,
                    },
                    {
                        primary: lang.GHANGE_USER_STATUS[language],
                        secondary: "",
                        icon: <VerifiedUser />,
                        action: customer.openCustomerStatusModal,
                        render: true,
                    },
                ]}
            />
            <CustomModal
                open={customer.settingsModal && !tiny}
                title={lang.QUICK_SETTINGS[language]}
                content={
                    <Grid container spacing={2}>
                        <CustomButtonList
                            close={customer.closeSettingsModal}
                            language={language}
                            tiny={tiny}
                            list={[
                                {
                                    text: lang.EDIT_CUSTOMER[language],
                                    style: {
                                        icon: <EditRounded className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: customer.openEditModal,
                                    render: true,
                                },
                                {
                                    text: lang.ACTION_INVOICE[language],
                                    style: {
                                        icon: <ReceiptRounded className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: customer.openInvoiceAppendixModal,
                                    render: true,
                                },
                                {
                                    text: lang.GHANGE_USER_STATUS[language],
                                    style: {
                                        icon: <VerifiedUser className={classes.leftIcon} />,
                                        color: "secondary",
                                        variant: "contained",
                                    },
                                    action: customer.openCustomerStatusModal,
                                    render: true,
                                },
                                {
                                    text: "Șterge client",
                                    render: checkPermissions(userPermissions, { parent: "SUPER", name: "SUPER_DELETE" }) === true,
                                    style: {
                                        icon: <RemoveCircleRounded className={classes.leftIcon} />,
                                        color: "secondary",
                                        variant: "contained"
                                    },
                                    action: () => setCustomerDeleteModal(true)
                                }
                            ]}
                            withBack
                        />
                    </Grid>
                }
                close={customer.closeSettingsModal}
                execute={customer.closeSettingsModal}
                noButtons
                validate={true}
                language={language}
            />
        </React.Fragment>
    )
}

export default function CustomerMain(props) {
    const classes = useStyles();
    const { data, language, address, openSettingsModal, customer, tiny } = props;

    return (
        <React.Fragment>
            <CustomerSettingsModal data={data} customer={customer} tiny={tiny} userPermissions={props.userPermissions} language={language} classes={classes} />
            <Grid item xs={12} container direction="column">
                <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                    <Paper className={classes.paper} >
                        <Grid container justify="center" alignItems="center" direction="column">
                            <Grid container justify="center" alignItems="center" direction="row">
                                {data && <Tooltip className={classes.indicator} title={data && data.IS_ACTIVE === 1 ? "Activ" : "Inactiv"}><Typography className={classes.title} variant={tiny ? "h6" : "h4"} color={data && data.IS_ACTIVE === 1 ? "inherit" : "secondary"}>{data.NAME}</Typography></Tooltip>}
                                <Can neededPermissions={{ parent: "CUSTOMERS", name: "EDIT_CUSTOMER" }}>
                                    <IconButton onClick={openSettingsModal}>
                                        <SettingsRounded fontSize={tiny ? "small" : "default"} />
                                    </IconButton>
                                </Can>
                            </Grid>
                            <Grid container justify="center" alignItems="center" direction="row">
                                {data && data.CUSTOMER_CODE && data.TYPE &&
                                    <Chip className={classes.subtitle} icon={data.TYPE === "PF" ? <FaceRounded fontSize="small" /> : <BusinessRounded fontSize="small" />} color="secondary" label={`${lang["CLIENT_TYPE_" + data.TYPE][language]} #${data.CUSTOMER_CODE}`} />}
                                {data && data.PHONE &&
                                    <Chip className={classes.subtitle} icon={<PhoneRounded fontSize="small" />} color="primary" label={<a className={classes.links} href={`tel:${data.PHONE}`}>{data.PHONE}</a>} />}
                                {data && data.EMAIL &&
                                    <Chip className={classes.subtitle} icon={<EmailRounded fontSize="small" />} color="primary" label={<a className={classes.links} href={`mailto:${data.EMAIL}`}>{data.EMAIL}</a>} />}
                                {address && address.filter(a => a.TYPE && a.TYPE.length > 0).map((a, index) =>
                                    <Chip key={index} className={classes.subtitle} icon={a.TYPE === "FACTURARE" ? <ReceiptRounded fontSize="small" /> : <HomeRounded fontSize="small" />} color="default" label={addressToString(a, 1)} />
                                )}
                            </Grid>
                            <Grid container justify="center" alignItems="center" direction="row">
                                {data && data.TYPE === "PJ" && data.CNP_CUI &&
                                    <Chip className={classes.subtitle} icon={<BusinessCenterRounded fontSize="small" />} color="primary" label={lang.CUI[language] + ": " + data.CNP_CUI} />}
                                {data && data.TYPE === "PJ" && data.NR_REG &&
                                    <Chip className={classes.subtitle} icon={<FormatListNumberedRounded fontSize="small" />} color="primary" label={lang.NR_REG[language] + ': ' + data.NR_REG} />}
                                {data && data.TYPE === "PJ" && data.ADMINISTRATOR &&
                                    <Chip className={classes.subtitle} icon={<AccountCircleRounded fontSize="small" />} color="primary" label={lang.ADMINISTRATOR[language] + ': ' + data.ADMINISTRATOR} />}
                                {data && data.TYPE === "PF" && data.CNP_CUI &&
                                    <Chip className={classes.subtitle} icon={<FormatListNumberedRounded fontSize="small" />} color="primary" label={lang.CNP[language] + ": " + data.CNP_CUI} />}
                            </Grid>

                        </Grid>
                    </Paper>
                </Grow>
            </Grid>
        </React.Fragment>
    );
}

export function SkeletonCustomerMain(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} container direction="column">
            <Skeleton component={Paper} className={classes.paper} height="20vh">
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="15%" className={classes.title} />
                    <Grid container justify="center" alignItems="center" direction="row">
                        <Skeleton component={Chip} className={classes.subtitle} width='10%' />
                        <Skeleton component={Chip} className={classes.subtitle} width='10%' />
                    </Grid>
                </Grid>
            </Skeleton>
        </Grid>
    );
}
