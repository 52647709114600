import React, { Component } from "react";
import * as lang from "../../Constants/strings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actions";
import {
	CssBaseline,
	useTheme,
	useMediaQuery,
	Grid,
	makeStyles,
	Chip,
	Grow,
	TextField,
	InputAdornment,
} from "@material-ui/core";
import CustomModal from "../../Components/Modals/CustomModal";
import ServerPaginatedTable from "../../Components/Misc/Tables/ServerPaginatedTable";
import { accountingCustomers } from "../../Components/Misc/Tables/TableHeaders";
import AccountingCustomersFilters from "./AccountingComponents.js/AccountingCustomersFilters";
import {
	ReceiptRounded,
	VisibilityRounded,
	CloudDownloadRounded,
} from "@material-ui/icons";
import CustomButtonList from "../../Components/Modals/CustomButtonList";
import CustomDrawer from "../../Components/Modals/CustomDrawer";
import { redirectTo } from "../../Functions/linkFunctions";
import { checkPermissions } from "../../Functions/permissionHide";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		width: "auto",
	},
	paper: {
		padding: theme.spacing(2),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(1),
	},
	chips: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	textField: {
		margin: theme.spacing(1),
	},
}));
function FilterChips(props) {
	const classes = useStyles();
	const { language, filters, removeFilterChip } = props;
	let chips = [];
	for (let key in filters)
		if (["YEAR", "MONTH"].indexOf(key) === -1)
			chips.push(
				<Grow
					key={key}
					in={true}
					style={{ transitionDelay: true ? "100ms" : "0ms" }}
				>
					<Chip
						color="primary"
						className={classes.chip}
						label={`${lang[key][language]}: ${filters[key]}`}
						onDelete={() => removeFilterChip(key)}
					/>
				</Grow>
			);
	return <React.Fragment>{chips}</React.Fragment>;
}
function DiscountMenu(props) {
	const classes = useStyles();
	const {
		language,
		updateDiscount,
		DISCOUNT,
		OBJID,
		YEAR,
		MONTH,
		showDiscount,
	} = props;
	const [total, setTotal] = React.useState(null);

	React.useEffect(() => {
		if (OBJID !== null)
			fetch(
				`/api/invoice_total?OBJID=${OBJID}&YEAR=${YEAR}&MONTH=${MONTH}`,
				{
					headers: {
						"Content-Type": "application/json",
						authorization: localStorage.token,
					},
				}
			)
				.then((response) => response.json())
				.then((response) => {
					setTotal(response.data);
				})
				.catch((err) => console.log(err));
	}, [OBJID, MONTH, YEAR]);

	return (
		<React.Fragment>
			<Grid item xs={12} md={showDiscount ? 6 : 12}>
				<TextField
					className={classes.textField}
					onClick={() => console.log(props)}
					disabled
					fullWidth
					variant="outlined"
					label={lang.INVOICE_TOTAL[language]}
					value={total ? total : ""}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">RON</InputAdornment>
						),
					}}
				/>
			</Grid>
			{showDiscount && (
				<Grid item xs={12} md={6}>
					<TextField
						className={classes.textField}
						required
						fullWidth
						variant="outlined"
						label={lang.DISCOUNT[language]}
						value={DISCOUNT ? DISCOUNT : ""}
						onChange={(e) => updateDiscount(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									RON
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			)}
		</React.Fragment>
	);
}
function AccountingMenu(props) {
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const { language, close, selectedIndex, open, data } = props;

	function downloadFile(name) {
		fetch(`/api/download_invoice?file=${name}`, {
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.token,
			},
			method: "GET",
			responseType: "arraybuffer",
		})
			.then((response) => response.blob())
			.then((response) => {
				const file = new Blob([response], {
					type:
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				const fileURL = URL.createObjectURL(file);
				var link = document.createElement("a");
				link.href = fileURL;
				link.download = name;
				link.click();
			})
			.catch((err) => console.log(err));
	}
	return (
		<React.Fragment>
			<CustomDrawer
				open={open && tiny}
				close={close}
				list={[
					{
						primary: lang.DOWNLOAD_FILE[language],
						secondary: "",
						icon: <CloudDownloadRounded />,
						action: () => downloadFile(data.DOC_NAME),
						render: data && data.DOC_NAME !== null,
					},
					{
						primary: lang.ACTION_INVOICE[language],
						secondary: "",
						icon: <ReceiptRounded />,
						action: () => props.billingAction(false),
						render: data && data.DOC_NAME === null,
					},
					{
						primary: lang.ACTION_INVOICE_ADJUST[language],
						secondary: "",
						icon: <ReceiptRounded />,
						action: () => props.billingAction(true),
						render: data && data.DOC_NAME === null,
					},
				]}
				secondList={[
					{
						primary: lang.GO_TO(lang.CUSTOMER[language])[language],
						secondary: "",
						icon: <VisibilityRounded />,
						action: () => {
							redirectTo(`/customer?id=${data.OBJID}`);
						},
						render: true,
					},
				]}
			/>
			<CustomModal
				open={open && !tiny}
				title={lang.CUSTOMER_DETAILS[language]}
				content={
					<Grid container spacing={2}>
						<CustomButtonList
							close={close}
							language={language}
							tiny={tiny}
							list={[
								{
									text:
										lang.DOWNLOAD_INVOICE_APPENDIX[
										language
										],
									style: {
										icon: (
											<CloudDownloadRounded
												className={classes.leftIcon}
											/>
										),
										color: "secondary",
										variant: "contained",
									},
									action: () => downloadFile(data.DOC_NAME),
									render: data && data.DOC_NAME !== null,
								},
								{
									text: lang.ACTION_INVOICE[language],
									style: {
										icon: (
											<ReceiptRounded
												className={classes.leftIcon}
											/>
										),
										color: "primary",
										variant: "contained",
									},
									action: () => props.billingAction(false),
									render: data && data.DOC_NAME === null,
								},
								{
									text: lang.ACTION_INVOICE_ADJUST[language],
									style: {
										icon: (
											<ReceiptRounded
												className={classes.leftIcon}
											/>
										),
										color: "primary",
										variant: "contained",
									},
									action: () => props.billingAction(true),
									render: data && data.DOC_NAME !== null,
								},
								{
									text: lang.GO_TO(lang.CUSTOMER[language])[
										language
									],
									style: {
										icon: (
											<VisibilityRounded
												className={classes.leftIcon}
											/>
										),
										color: "primary",
										variant: "contained",
									},
									action: () => {
										redirectTo(
											`/customer?id=${data.OBJID}`
										);
									},
									render: true,
								},
							]}
							withBack
						/>
					</Grid>
				}
				close={close}
				execute={close}
				language={language}
				noButtons
			/>
		</React.Fragment>
	);
}
function AccountingViewSetup(props) {
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const { language, filters } = props;
	return (
		<Grid
			container
			component="main"
			className={classes.root}
			spacing={tiny ? 1 : 4}
		>
			<Grid
				className={classes.chips}
				container
				justify="flex-start"
				alignItems="center"
				direction="row"
			>
				<Grow
					in={true}
					style={{ transitionDelay: true ? "100ms" : "0ms" }}
				>
					<Chip
						className={classes.chip}
						label={`${lang.MONTH[language]}: ${filters.YEAR}/${filters.MONTH}`}
					/>
				</Grow>
				<FilterChips
					language={language}
					filters={filters}
					removeFilterChip={props.removeFilterChip}
				/>
			</Grid>
			<Grid item xs={12}>
				<ServerPaginatedTable
					tiny={tiny}
					title={lang.CLIENTS[language]}
					language={language}
					header={accountingCustomers(language)}
					dense={false}
					filters={filters}
					openRowModal={props.openRowModal}
					openFilterModal={props.openFilterModal}
				/>
			</Grid>
		</Grid>
	);
}

class AccountingView extends Component {
	state = {
		filters: {
			YEAR: `${new Date().getFullYear()}`,
			MONTH: `${new Date().getMonth() + 1}`,
		},
		DISCOUNT: 0,
		editedFilters: {},
		modalDiscount: false,
		selectedIndex: null,
		showDiscount: false,
	};
	componentWillMount = () => {
		if (
			!checkPermissions(this.props.permissions, {
				parent: "ACCOUNTING",
				name: "ACCOUNTING_ACCESS",
			})
		)
			redirectTo("/");
	};
	componentDidMount = () => {
		this.props.actions.getAccountingCustomersSP({
			orderBy: 0,
			orderDirection: "asc",
			pageNumber: 0,
			pageRows: this.props.pageRows,
			YEAR: `${new Date().getFullYear()}`,
			MONTH: `${new Date().getMonth() + 1}`,
		});
	};

	updateFilter = (field, value) => {
		let newFilters = this.state.editedFilters;
		let checkedValue =
			typeof value === "string"
				? value.replace(/[^a-zA-Z0-9-@/., ]/g, "")
				: value;
		newFilters[field] =
			typeof value === "string"
				? `${checkedValue.toLowerCase()}`.slice(0, 30)
				: checkedValue;
		this.setState({ editedFilters: newFilters });
	};

	removeFilter = (field) => {
		let newFilters = this.state.editedFilters;
		delete newFilters[field];
		this.setState({ editedFilters: newFilters });
	};

	removeFilterChip = (field) => {
		let newFilters = this.state.filters;
		delete newFilters[field];
		this.setState({ filters: newFilters }, () =>
			this.props.actions.getAccountingCustomersSP({
				orderBy: 0,
				orderDirection: "asc",
				pageNumber: 0,
				pageRows: this.props.pageRows,
				...this.state.filters,
			})
		);
	};

	updateDiscount = (value) => {
		this.setState({ DISCOUNT: value.replace(/[^0-9.]/g, "") });
	};

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<CustomModal
					fullWidth
					open={this.state.modal === "filters"}
					content={
						<Grid container spacing={2}>
							{this.state.modal === "filters" && (
								<AccountingCustomersFilters
									language={this.props.language}
									filters={this.state.editedFilters}
									updateFilter={this.updateFilter}
									removeFilter={this.removeFilter}
								/>
							)}
						</Grid>
					}
					title={lang.FILTERS[this.props.language]}
					close={() => this.setState({ modal: null })}
					language={this.props.language}
					validate={true}
					execute={() => {
						this.setState(
							{
								modal: null,
								filters: { ...this.state.editedFilters },
								editedFilters: {},
							},
							() =>
								this.props.actions.getAccountingCustomersSP({
									orderBy: 0,
									orderDirection: "asc",
									pageNumber: 0,
									pageRows: this.props.pageRows,
									...this.state.filters,
								})
						);
					}}
				/>
				<CustomModal
					fullWidth
					open={this.state.modalDiscount}
					content={
						<Grid container spacing={2}>
							{this.state.modalDiscount && (
								<DiscountMenu
									language={this.props.language}
									DISCOUNT={this.state.DISCOUNT}
									OBJID={
										this.props.data && this.state.selectedIndex !== null ?
											this.props.data[this.state.selectedIndex].OBJID :
											null
									}
									YEAR={this.state.filters.YEAR}
									MONTH={this.state.filters.MONTH}
									updateDiscount={this.updateDiscount}
									showDiscount={this.state.showDiscount}
								/>
							)}
						</Grid>
					}
					formValidate
					title={
						this.state.showDiscount
							? lang.ACTION_INVOICE_ADJUST[this.props.language]
							: lang.ACTION_INVOICE[this.props.language]
					}
					close={() =>
						this.setState({ modalDiscount: false, DISCOUNT: 0 })
					}
					language={this.props.language}
					validate={true}
					execute={() => {
						this.props.actions.invoiceCustomer(
							this.props.data[this.state.selectedIndex].OBJID,
							{
								orderBy: this.props.orderBy,
								orderDirection: this.props.orderDirection,
								pageNumber: this.props.pageNumber,
								pageRows: this.props.pageRows,
								...this.state.filters,
							},
							this.state.DISCOUNT
						);
						this.setState({ modalDiscount: false, DISCOUNT: 0 });
					}}
				/>
				<AccountingMenu
					open={this.state.modal === "billing"}
					close={() => this.setState({ modal: null })}
					language={this.props.language}
					selectedIndex={this.state.selectedIndex}
					data={this.props.data[this.state.selectedIndex]}
					billingAction={(value) =>
						this.setState({
							modalDiscount: true,
							showDiscount: value,
						})
					}
				/>
				<AccountingViewSetup
					language={this.props.language}
					filters={this.state.filters}
					openRowModal={(index) =>
						this.setState({
							modal: "billing",
							selectedIndex: index,
						})
					}
					openFilterModal={() =>
						this.setState({
							modal: "filters",
							editedFilters: { ...this.state.filters },
						})
					}
					removeFilterChip={this.removeFilterChip}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.config.language,
		theme: state.config.theme,
		geolocation: state.config.geolocation,
		permissions: state.auth.permissions,
		rowsPerPage: state.config.rowsPerPage,
		userAuth: state.auth.userData,
		data: state.sp.data,
		dataRows: state.sp.dataRows,
		pageNumber: state.sp.pageNumber,
		pageRows: state.sp.pageRows,
		orderBy: state.sp.orderBy,
		orderDirection: state.sp.orderDirection,
		isFetchingData: state.sp.isFetchingData,
	};
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingView);
