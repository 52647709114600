import React from 'react';
import { Grid, TextField, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../../Constants/strings';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function SiteAreaEditForm(props) {
    const classes = useStyles();
    const { editData, language, active, updateField } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    disabled={!active}
                    required
                    fullWidth
                    variant="outlined"
                    name="AREA_CODE"
                    label={lang.SITE_AREA_CODE[language]}
                    value={editData.AREA_CODE ? editData.AREA_CODE : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={!active}
                    required
                    fullWidth
                    variant="outlined"
                    name="AREA_NAME"
                    label={lang.SITE_AREA_NAME[language]}
                    value={editData.AREA_NAME ? editData.AREA_NAME : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={!active}
                    required
                    fullWidth
                    variant="outlined"
                    name="AREA_DESCRIPTION"
                    label={lang.AREA_DESCRIPTION[language]}
                    value={editData.AREA_DESCRIPTION ? editData.AREA_DESCRIPTION : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color={active ? "secondary" : "primary"}
                    onClick={() => { props.toggleExecutor(); props.close(); }}
                >
                    {active ?
                        <BlockRoundedIcon fontSize="small" className={classes.leftIcon} />
                        :
                        <CheckCircleOutlineRoundedIcon fontSize="small" className={classes.leftIcon} />
                    }
                    {lang.TOGGLE_ACTION(active)[language]}
                </Button>
            </Grid>
        </Grid>
    );
}