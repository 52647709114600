import React, { Component, useState } from "react";
import {
  CssBaseline,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import * as lang from "../../Constants/strings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actions";
import ShortcutDisplay from "./Shortcuts/ShortcutDisplay";
import CustomerNewForm from "../Customer/CustomerViewComponents/CustomerNewForm";
import CustomModal from "../../Components/Modals/CustomModal";
import { custData, custAddress, custSite } from "../../Constants/forms";
import { custAddFormat2 } from "../../Functions/objectManipulation";
import { redirectTo } from "../../Functions/linkFunctions";
import SiteNewForm from "../Customer/CustomerSingleViewComponents/SiteNewForm";
import { siteAddFormat } from "../../Functions/objectManipulation";
import StickyHeadMaterialTable from "../../Components/Misc/Tables/StickyHeadMaterialTable";
import {
  custSerchResult,
  siteSearchResult,
} from "../../Components/Misc/Tables/TableHeaders";
import axios from "axios";
import TaskNewForm from "../Site/SiteSingleViewComponents/TaskNewForm";
import { getVerifyIcon } from "../../Functions/getIcon";
import TransferModal from "./TechnicalTeam/TransferModal";
import InventoryModal from "./TechnicalTeam/InventoryModal";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center",
    padding: theme.spacing(1),
    height: "100%",
    width: "95vw",
    marginTop: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(10),
    },
  },
  title: {
    margin: theme.spacing(4),
  },
  searchField: {
    width: "40vw",
  },
}));

function HomeWidget(props) {
  const classes = useStyles();

  const [transferModal, setTransferModal] = useState(false);
  const [inventoryModal, setInventoryModal] = useState(false);

  const { language, openNewCustomerModal, searchSite, searchCustomer } = props;
  return (
    <Grid
      container
      component="main"
      justify="center"
      alignItems="center"
      direction="column"
    >
      <CssBaseline />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid item xs={12}>
            <Typography className={classes.title} align="center" variant="h2">
              {lang["HOME"][language]}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs="auto">
        <ShortcutDisplay
          buttons={[
            {
              image: "/api/image?image=site-search.svg",
              title: lang.SITE_SEARCH[language],
              width: "40%",
              action: () => searchSite(),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_SITE_SEARCH",
              },
            },
            {
              image: "/api/image?image=customer-search.svg",
              title: lang.CUSTOMER_SEARCH[language],
              width: "40%",
              action: () => searchCustomer(),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_CUSTOMER_SEARCH",
              },
            },
            {
              image: "/api/image?image=add-user.svg",
              title: lang.QUICK_CUSTOMER_ADD[language],
              width: "40%",
              action: () => openNewCustomerModal(),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_CUSTOMER_ADD",
              },
            },
            {
              image: "/api/image?image=warning.png",
              title: lang.ALARMS[language],
              width: "60%",
              action: () => redirectTo("/interventions"),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_INTERVENTION",
              },
            },
            {
              image: "/api/image?image=notification.svg",
              title: lang.TASKS_REPAIR[language],
              width: "40%",
              action: () => redirectTo("/tasks"),
              neededPermissions: { parent: "TASKS", name: "VIEW_TASK" },
            },
            {
              image: "/api/image?image=installation.png",
              title: lang.TASK_INSTALLATION[language],
              width: "20%",
              action: () => redirectTo('/installation'),
              neededPermissions: {
                parent: "INSTALLATION",
                name: "QUICK_INSTALLATION_VIEW",
              },
            },
            {
              image: "/api/image?image=maintenance.png",
              title: lang.TASK_MAINTENANCES[language],
              width: "40%",
              action: () => redirectTo("/maintenance"),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_VIEW_MAINTENANCE_SHORTCUT",
              },
            },
            {
              image: "/api/image?image=history.png",
              title: lang.TASK_BILL_HISTORY[language],
              width: "40%",
              action: () => redirectTo("/bill_history"),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_ACTION_VIEW_TASK_BILL_HISTORY",
              },
            },
            {
              image: "/api/image?image=evaluation.png",
              title: lang.RISC_EVALUATIONS[language],
              width: "20%",
              action: () => redirectTo('/risc_evaluation'),
              neededPermissions: {
                parent: "RISC_EVALUATIONS",
                name: "QUICK_VIEW_RISC_EVALUATION",
              },
            },
            {
              image: "/api/image?image=projects.png",
              title: lang.PROJECTS[language],
              width: "20%",
              action: () => redirectTo('/project'),
              neededPermissions: {
                parent: "PROJECTS",
                name: "QUICK_PROJECTS_VIEW",
              },
            },
            {
              image: "/api/image?image=docs.png",
              title: lang.TEMPLATE_FILES[language],
              width: "40%",
              action: () => redirectTo("/templates"),
              neededPermissions: {
                parent: "QUICK_ACTION",
                name: "QUICK_ACTION_VIEW_TEMPLATE_FILES",
              },
            },
            {
              image: "/api/image?image=inventory-transfer.png",
              title: lang.CAR_INVENTORY_TRANSFERS[language],
              width: "1%",
              action: () => setTransferModal(true),
              neededPermissions: {
                parent: "TECHNICAL_TEAM",
                name: "EXTERNAL_INVENTORY",
              },
            },
            {
              image: "/api/image?image=inventory.png",
              title: lang.CAR_INVENTORY[language],
              width: "20%",
              action: () => setInventoryModal(true),
              neededPermissions: {
                parent: "TECHNICAL_TEAM",
                name: "EXTERNAL_INVENTORY",
              },
            },

          ]}
        />
        <TransferModal
          userId={props.userId}
          language={language}
          open={transferModal}
          onClose={() => setTransferModal(false)}
        />
        <InventoryModal
          userId={props.userId}
          language={language}
          open={inventoryModal}
          onClose={() => setInventoryModal(false)}
        />
      </Grid>
    </Grid>
  );
}

function SearchResultsModal(props) {
  const { language, modalStatus, openRowModal, data, header } = props;
  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <CustomModal
      open={modalStatus}
      close={() => props.close()}
      tiny={tiny}
      title={lang.SEARCH_RESULT[language]}
      content={
        <Grid container spacing={2}>
          <StickyHeadMaterialTable
            title={lang.CLIENTS[language]}
            data={data}
            language={language}
            header={header}
            onClickRow={(id) => openRowModal(id)}
          />
        </Grid>
      }
      language={language}
      onlyBack={tiny}
      noButtons
    />
  );
}

class HomeView extends Component {
  state = {

    addData: {
      TYPE: "PJ",
      ...custData.PJ,
      ...custAddress,
    },
    addModal: false,
    error: null,
    addSiteError: null,
    settingsModal: false,
    selectedIndex: null,

    addressModal: false,
    addressData: { ...custAddress },
    siteModal: false,
    siteData: { ...custSite, ...custAddress },
    editModal: false,
    customerData: {},

    searchSiteModal: false,
    siteSearch: "",

    searchCustomerModal: false,
    customerSearch: "",
    customerSearchResult: [],
    customerResultsModal: false,

    siteSearchResult: [],
    siteResultsModal: false,
    noFoundModal: false,
    addTaskModal: false,
    selectedTask: 0,
    taskData: {},
  };

  searchSite(isActive = false, isValidate = false) {
    axios
      .get(`/api/search_site?searchTerm=${this.state.siteSearch}&isActive=${isActive}&isValidate=${isValidate}`, {
        method: "GET",
        headers: {
          authorization: localStorage.token,
        },
      })
      .then((response) => {
        switch (response.status) {
          case 200:
            this.setState({
              siteSearchResult: getVerifyIcon(response.data),
              siteResultsModal: true,
              searchSiteModal: false,
            });
            console.log(getVerifyIcon(response.data));
            break;
          default:
            console.log("No result");
        }
      })
      .catch((err) => {
        this.setState({ noFoundModal: true, searchSiteModal: false });
      });
  }

  searchCustomer(getActive = false, getValidated = false) {
    axios
      .get(`/api/search_customer?searchTerm=${this.state.customerSearch}&getActive=${getActive}&getValidated=${getValidated}`, {
        method: "GET",
        headers: {
          authorization: localStorage.token,
        },
      })
      .then((response) => {
        switch (response.status) {
          case 200:
            this.setState({
              customerSearchResult: getVerifyIcon(response.data),
              customerResultsModal: true,
              searchCustomerModal: false,
            });
            break;
          default:
            console.log("No result");
        }
      })
      .catch((err) => {
        this.setState({ noFoundModal: true, searchCustomerModal: false });
      });
  }

  componentDidMount() {
    this.props.actions.checkSession(false);
    document.title = "RPG - " + lang["HOME"][this.props.language];
  }

  render() {
    return (
      <React.Fragment>
        <HomeWidget
          openModalAddPartialClient={() =>
            this.setState({ modalAddPartialClient: true })
          }
          language={this.props.language}
          openNewCustomerModal={() =>
            this.setState({
              addModal: true,
              addData: { TYPE: "PJ", ...custData.PJ, ...custAddress },
              error: null,
            })
          }
          searchSite={() => this.setState({ searchSiteModal: true })}
          searchCustomer={() => this.setState({ searchCustomerModal: true })}
          userId={this.props.userAuth?.id}
        />
        {/* Add a new customer, after is added will open the new site modal */}
        <CustomModal
          fullWidth
          open={this.state.addModal}
          title={lang.ADD_CUSTOMER[this.props.language]}
          content={
            <Grid container spacing={2} >
              <CustomerNewForm
                address
                type={this.state.addData.TYPE}
                addData={this.state.addData}
                language={this.props.language}
                updateFieldRaw={(field, value) =>
                  this.setState({
                    addData: { ...this.state.addData, [field]: value },
                  })
                }
                updateField={(e, length) =>
                  this.setState({
                    addData: {
                      ...this.state.addData,
                      [e.target.name]: e.target.value
                        .toString()
                        .slice(0, length),
                    },
                  })
                }
                toggleType={(value) =>
                  this.setState({
                    addData: {
                      TYPE: value,
                      ...custData[value],
                      ...custAddress,
                    },
                  })
                }
                error={this.state.error}
                updateError={(value) => this.setState({ error: value })}
              />
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container alignContent="center" justify="center">
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ margin: 20 }}
                >
                  {lang.ADD_SITE[this.props.language]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <SiteNewForm
                gps
                addData={this.state.siteData}
                language={this.props.language}
                updateFieldRaw={(field, value) =>
                  this.setState({
                    siteData: { ...this.state.siteData, [field]: value },
                  })
                }
                updateField={(e, length) =>
                  this.setState({
                    siteData: {
                      ...this.state.siteData,
                      [e.target.name]: e.target.value
                        .toString()
                        .slice(0, length),
                    },
                  })
                }
                error={this.state.addSiteError}
                updateError={(value) => this.setState({ addSiteError: value })}
              />
              <Grid
                container
                component="main"
                justify="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.taskData.INCLUDE_TASK === true}
                      onChange={() =>
                        this.setState({
                          taskData: {
                            ...this.state.taskData,
                            INCLUDE_TASK: !this.state.taskData.INCLUDE_TASK,
                          },
                        })
                      }
                    />
                  }
                  label={
                    <Typography variant="h6">
                      {lang.ADD_TASK[this.props.language]}
                    </Typography>
                  }
                />
              </Grid>
              {this.state.taskData.INCLUDE_TASK && (
                <TaskNewForm
                  baseData={{
                    userId:
                      this.props.userAuth === null
                        ? null
                        : this.props.userAuth.id,
                    siteId: this.props.lastID,
                  }}
                  addData={this.state.taskData}
                  language={this.props.language}
                  open={this.state.addTaskModal}
                  close={() => this.setState({ addTaskModal: false })}
                  updateField={(e, length) =>
                    this.setState({
                      taskData: {
                        ...this.state.taskData,
                        [e.target.name]: e.target.value
                          .toString()
                          .slice(0, length),
                      },
                    })
                  }
                  updateFieldRaw={(field, value) => {
                    let placeholder = this.state.taskData;
                    placeholder[field] = value;
                    this.setState({ taskData: placeholder });
                  }}
                  tiny={false}
                />
              )}
            </Grid>
          }
          close={() => {
            this.setState({
              addModal: false,
            });
          }}
          execute={() => {
            this.props.actions.addQuickTask(
              this.props.userAuth.id,
              custAddFormat2(this.state.addData),
              siteAddFormat({
                ...this.state.siteData,
                CONTACT_OBJID: this.props.newCustomerID,
              }),
              this.state.taskData
            );
            this.setState({ addModal: false });
          }}
          validate={
            this.state.error === false &&
            this.state.addData.REGION.length > 0 &&
            this.state.addData.CITY.length > 0 &&
            this.state.addData.STREET_NAME.length > 0 &&
            ((this.state.addData.TYPE === "PF" &&
              this.state.addData.PHONE.length > 0) ||
              (this.state.addData.TYPE === "PJ" &&
                this.state.addData.PHONE.length > 0)) &&
            this.state.addSiteError === false &&
            this.state.siteData.SITE_CODE.length > 0 &&
            this.state.siteData.SITE_NAME.length > 0 &&
            this.state.siteData.REGION.length > 0 &&
            this.state.siteData.CITY &&
            this.state.siteData.STREET_NAME.length > 0 &&
            this.state.siteData.CONTACT_LAST_NAME.length > 0 &&
            this.state.siteData.CONTACT_FIRST_NAME.length > 0 &&
            this.state.siteData.CONTACT_PHONE.length > 0
          }
          language={this.props.language}
        />
        {/* Search for sites */}
        <CustomModal
          open={this.state.searchSiteModal}
          title={lang.SEARCH[this.props.language]}
          content={
            <Grid container alignItems="center" justify="center">
              <TextField
                label={lang.SEARCH[this.props.language]}
                value={this.state.siteSearch}
                variant="outlined"
                onChange={(e) => this.setState({ siteSearch: e.target.value })}
              />
            </Grid>
          }
          execute={() => {
            this.searchSite();
          }}
          close={() => this.setState({ searchSiteModal: false })}
          validate={this.state.siteSearch !== ""}
          language={this.props.language}
        />
        <CustomModal
          open={this.state.searchCustomerModal}
          title={lang.SEARCH[this.props.language]}
          content={
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <TextField
                  label={lang.SEARCH[this.props.language]}
                  value={this.state.customerSearch}
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({ customerSearch: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          execute={() => {
            this.searchCustomer();
          }}
          close={() => this.setState({ searchCustomerModal: false })}
          validate={this.state.customerSearch !== ""}
          language={this.props.language}
        />
        <SearchResultsModal
          language={this.props.language}
          modalStatus={this.state.customerResultsModal}
          openRowModal={(resultId) => redirectTo("/customer?id=" + resultId)}
          data={this.state.customerSearchResult}
          header={custSerchResult(this.props.language)}
          close={() => this.setState({ customerResultsModal: false })}
        />
        <SearchResultsModal
          language={this.props.language}
          modalStatus={this.state.siteResultsModal}
          openRowModal={(resultId) => redirectTo("/site?id=" + resultId)}
          data={this.state.siteSearchResult}
          header={siteSearchResult(this.props.language)}
          close={() => this.setState({ siteResultsModal: false })}
        />
        <CustomModal
          fullWidth
          open={this.state.noFoundModal}
          title={lang.SEARCH_RESULT[this.props.language]}
          content={
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="h4">
                  {lang.NO_RESULT[this.props.language]}
                </Typography>
              </Grid>
            </Grid>
          }
          close={() => this.setState({ noFoundModal: false })}
          validate={true}
          execute={() => this.setState({ noFoundModal: false })}
          language={this.props.language}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.config.language,
    userAuth: state.auth.userData,
    newCustomerID: state.cust.newCustomerID,
    siteData: state.cust.siteData,
    isFetchingTaskData: state.task.isFetchingTaskData,
    taskData: state.task.taskData,
    lastID: state.site.lastID,
    rowsPerPage: state.config.rowsPerPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
