import React from 'react';
import {
    CircularProgress,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function ActivityModalIndicator(props) {
    const classes = useStyles();
    const { open } = props;

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
            <DialogTitle >
                <Typography variant='subtitle1'>Se incarca fisierul, va rugam asteptati!</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container alignContent='center' justify='center'>
                    <div className={classes.root}>
                        <CircularProgress />
                        {/* <CircularProgress color="secondary" /> */}
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    )
} 
