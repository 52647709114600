export const RISC_EVALUATION = {
    en: "Risc Evaluation",
    ro: "Evaluare de risc"
};

export const RISC_EVALUATIONS = {
    en: "Risc Evaluations",
    ro: "Evaluări de risc"
};

export const ADD_DATA = {
    en: "Add data",
    ro: "Adăugare de date"
};

export const EVALUATION = {
    ro: "Evaluare",
    en: "Evaluation"
};

export const ACTIVE_EVALUATION_TASK_EXIST_ON_CONTRACT = {
    en: "A active evalaution task exist on this contract",
    ro: "Există un ticket de evaluare de risc deschis pe acest contract!"
};

export const SEND_TO_CUSTOMER = {
    en: "Send to customer",
    ro: "Trimis la client"
};

export const SEND_TO_CUSTOMER_t = {
    en: "Send to customer",
    ro: "Trimis la client"
};

export const CANCEL_t = {
    en: "Cancel",
    ro: "Anulat"
}