/**
 * The project task statuses translation
 */
export const CREATED = {
    en: "Created",
    ro: "Creat"
};

export const START_DESIGN = {
    en: "Start designing",
    ro: "Proiectare începută"
};

export const ASSIGN = {
    en: "Assign",
    ro: "Alocă mie"
};

export const FINISH_DESIGN = {
    en: "Finish design",
    ro: "Proiectare terminată"
};

export const SEND_TO_POLICE = {
    en: "Send to police",
    ro: "Trimis la poliție"
};


export const FINISH = {
    en: "Finish",
    ro: "Închis"
};

export const CREATED_t = {
    en: "Created",
    ro: "Creat"
}
export const ASSIGN_t = {
    en: "Assigned",
    ro: "Alocat"
}

export const START_DESIGN_t = {
    en: "Start design",
    ro: "Proiectare începută"
}
export const FINISH_DESIGN_t = {
    en: "Finish",
    ro: "Proiectare terminată"
}

export const SEND_TO_POLICE_t = {
    en: "Send to police",
    ro: "Trimis la poliție"
}

export const FINISH_t = {
    en: "Finish",
    ro: "Aviz eliberat"
}

export const ACTIVE_PROJECT_TASK_EXIST_ON_CONTRACT = {
    en: "A active design task exist on this contract",
    ro: "Există un ticket de proiectare deschis pe acest contract!"
};

export const TAKE_PROJECT = {
    en: "Take project",
    ro: "Preluare proiect",
}