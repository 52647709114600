import React from 'react';
import PropTypes from 'prop-types';
import * as lang from '../../../Constants/strings';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TablePagination,
    makeStyles
} from '@material-ui/core';

import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paper: {
        width: '100%',
        padding: '15px'
    },
    typography: {
        margin: '10px',
    },
});


const Row = (props) => {
    const {
        data,
        title,
        collapsingHeader,
        mainDataHeader,
        language,
        collapsingDataKey,
        page
    } = props;
    const [open, setOpen] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    const classes = useStyles();
    React.useEffect(() => {
        if (currentPage !== page) {
            setOpen(false);
            setCurrentPage(page);
        }
    }, [page])
    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    {
                        data && data[collapsingDataKey] && data[collapsingDataKey].length > 0 &&
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    }

                </TableCell>
                {
                    mainDataHeader.map(cell => <TableCell align="left">{data[cell.id]}</TableCell>)
                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                {title}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {
                                            collapsingHeader.map(headCell => {
                                                return <TableCell
                                                    align="left"
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data[collapsingDataKey] && data[collapsingDataKey].length > 0 && data[collapsingDataKey].map((historyRow, index) => {
                                        return <TableRow key={`collapsing_table_row_${zIndex}`}>
                                            {
                                                collapsingHeader.map(historyCell => {
                                                    return <TableCell align='left'>{historyRow[historyCell.id]}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    data: PropTypes.object,
    collapsingHeader: PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
        numeric: PropTypes.bool,
        disabledPadding: PropTypes.bool
    }),
    mainDataHeader: PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
        numeric: PropTypes.bool,
        disabledPadding: PropTypes.bool
    }),
    title: PropTypes.string,
    language: PropTypes.oneOf(['ro', 'en']),
    collapsingDataKey: PropTypes.string
};


const CollapsibleTable = (props) => {
    const classes = useStyles();
    const {
        tableTitle,
        tableHeader,
        data,
        collapsingHeader,
        collapsingTableTitle,
        language,
        navigation,
        collapsingDataKey,
        getCollapsingTableName
    } = props;

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.typography} variant="h6">{tableTitle}</Typography>
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {
                                tableHeader.map(headCell => <TableCell align="left">{headCell.label}</TableCell>)
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(
                            navigation.page * navigation.rowsPerPage,
                            navigation.page * navigation.rowsPerPage + navigation.rowsPerPage
                        ).map((row, index) => (
                            <Row
                                key={`row_${index}`}
                                data={row}
                                mainDataHeader={tableHeader}
                                collapsingHeader={collapsingHeader}
                                language={language}
                                title={getCollapsingTableName ? getCollapsingTableName(row) : collapsingTableTitle}
                                collapsingDataKey={collapsingDataKey}
                                page={navigation.page}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={navigation.itemsCount}
                rowsPerPage={navigation.rowsPerPage}
                page={navigation.page}
                onChangePage={navigation.setPage}
                onChangeRowsPerPage={(e) => navigation.setRowPerPage(e.target.value)}
            />
        </Paper>
    );
};

CollapsibleTable.propTypes = {
    tableTitle: PropTypes.string,
    data: PropTypes.array.isRequired,
    collapsingTableTitle: PropTypes.string,
    language: PropTypes.oneOf(['ro', 'en']),
    tableHeader: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            numeric: PropTypes.bool,
            disabledPadding: PropTypes.bool,
        })
    ).isRequired,
    collapsingHeader: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            numeric: PropTypes.bool,
            disabledPadding: PropTypes.bool
        })
    ).isRequired,
    navigation: PropTypes.shape({
        rowsPerPage: PropTypes.number,
        page: PropTypes.number,
        setRowPerPage: PropTypes.func,
        setPage: PropTypes.func,
        itemsCount: PropTypes.number
    }),
    collapsingDataKey: PropTypes.string.isRequired, // points to a iterable variable that contains the data display in the collapsing part of table
    getCollapsingTableName: PropTypes.func,
}

CollapsibleTable.defaultProps = {
    tableTitle: "Table Title",
    tableHeader: [],
    collapsingHeader: [],
    data: [],
    language: 'ro',
    collapsingTableTitle: "Collapsing Table Title",
    navigation: {
        rowsPerPage: 5,
        page: 1,
        setRowPerPage: () => { },
        setPage: () => { },
        itemsCount: 1
    },
    collapsingDataKey: 'history',
}

export default CollapsibleTable;