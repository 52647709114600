import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Link,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    CssBaseline
} from '@material-ui/core';
import DatasOfVersions from './changelog.json';
import { FiberManualRecord } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    main: {
        marginTop: theme.spacing(10),
        maxHeight: '90vh',
        overflowY: 'scroll'
    },
    mainGrid: {
        margin: '10px'
    },
    items: {
        marginTop: '10px'
    },
    text: {
        display: 'flex',
        alignItems: 'flex-start',
        alignText: 'center'
    },
    icon: {
        marginRight: '5px'
    }
}))
const AccountGeneralSettings = () => {
    const classes = useStyle();

    return (
        <div className={classes.main}>
            <CssBaseline />
            {
                DatasOfVersions.map((versionDetails) => {
                    return (
                        <Grid
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid
                                item
                                lg={2}
                                md={4}
                                xl={2}
                                xs={11}
                                className={classes.girdItemVersion}
                            >
                                <Card className={classes.items}>
                                    <CardContent>
                                        <Box
                                            sx={{
                                                alignItems: 'flex-start',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                color="textPrimary"
                                                sx={{ mt: 1 }}
                                                variant='h5'
                                            >
                                                Versiune: {versionDetails.version}
                                            </Typography>
                                            <Typography
                                                color="textPrimary"
                                                sx={{ mt: 1 }}
                                                variant='body1'
                                            >
                                                Data: {versionDetails.date}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                lg={8}
                                md={6}
                                xl={9}
                                xs={12}
                            >
                                {versionDetails.features.length > 0 ?
                                    <Card sx={{ mb: 2 }} className={classes.items}>
                                        <CardHeader title="Functionalitatii noi" />
                                        <Divider />
                                        <Table>
                                            <TableBody>
                                                {
                                                    versionDetails.features.map((feature) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell

                                                                >
                                                                    <Typography
                                                                        color="textPrimary"
                                                                        variant="subtitle2"
                                                                        className={classes.text}
                                                                    >
                                                                        <FiberManualRecord className={classes.icon} />  {feature.feature}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </Card> : <></>}
                                {versionDetails.fixes.length > 0 ?
                                    <Card className={classes.items}>
                                        <CardHeader title="Bugfix's" />
                                        <Divider />
                                        <Table>
                                            <TableBody>
                                                {
                                                    versionDetails.fixes.map((update) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography
                                                                        color="textPrimary"
                                                                        variant="subtitle2"
                                                                        className={classes.text}
                                                                    >
                                                                        <FiberManualRecord className={classes.icon} /> {update.fix}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>
                                    </Card> : <></>}
                            </Grid>
                        </Grid>
                    )
                })
            }
        </div>
    );
};

export default AccountGeneralSettings;
