import React, { Component } from 'react';
import { checkPermissions } from '../../Functions/permissionHide';
import { connect } from 'react-redux';

export function CanVisible(props) {
    return (
        props.visible === true &&
        <React.Fragment>
            {props.children}
        </React.Fragment >

    );
}

class Can extends Component {

    render() {
        return (
            this.props.isAuthenticated &&
                this.props.permissions &&
                this.props.neededPermissions &&
                checkPermissions(this.props.permissions, this.props.neededPermissions) === true
                ?
                <React.Fragment>
                    {this.props.children}
                </React.Fragment>
                :
                null

        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissions: state.auth.permissions,
        isAuthenticated: state.auth.isAuthenticated,
    }
}

export default connect(mapStateToProps, null)(Can);