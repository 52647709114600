import {Button, Grid, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import axios from "axios";

import {bindActionCreators} from "redux";
import * as actionCreators from "../../actions/actions";
import {connect} from "react-redux";
import * as lang from "../../Constants/strings";
import {getGetHeader} from "../../Constants/requestHeaders";
import AddSiteImage from "../../MainView/Site/SiteSingleViewComponents/Modals/AddSiteImage";

const SiteImageModal = (props) => {
    const [hasImage, setHasImage] = useState(true);
    const [modalState, setModalState] = useState(false);
    const {siteId, language} = props;

    const checkIfSiteHasImage = async (id) => {
        try {
            const res = await axios.get('api/site/has-image', {
                ...getGetHeader(),
                params: {
                    id,
                }
            });
            setHasImage(res.data.has);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        console.log(siteId)
        if (siteId) checkIfSiteHasImage(siteId);
    }, [siteId]);

    return (
        <Grid container spacing={2}>
            <AddSiteImage
                siteId={siteId}
                open={modalState}
                close={() => {
                    checkIfSiteHasImage(siteId);
                    setModalState(false);
                }}
            />
            {
                !hasImage &&
                <div style={{
                    width: "100%",
                    border: "2px solid red",
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px",
                    borderRadius: "20px",
                    margin: "20px 0px",
                    alignItems: "center"
                }}>
                    <Button variant="contained" color="primary" style={{maxWidth: "30%"}}
                            onClick={() => setModalState(true)}>
                        {lang.UPLOAD_SITE_IMAGE_CALL_TO_ACTION[language]}
                    </Button>
                    <div style={{marginLeft: "10px"}}>
                        {lang.SITE_HAS_NO_IMAGE_NOTIFICATION[language]}
                    </div>
                </div>
            }
            {
                hasImage &&
                <a href={"/site-image-viewer/"+siteId} style={{ textDecoration: "none", color: "#fff" }} target={"_blank"}>
                    <div style={{
                        width: "100%",
                        border: "2px solid #fff",
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                        borderRadius: "20px",
                        margin: "20px 0px",
                        alignItems: "center",
                    }}>
                        <Typography variant="h5">
                            {lang.GO_TO_THE_SITE_IMAGE_ACTION[language]}
                        </Typography>
                    </div>
                </a>
            }
        </Grid>
    )
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

const mapStateToProps = (state) => ({
    language: state.config.language,
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteImageModal);