import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../Constants/strings';


const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: theme.spacing(2),
    }
}));

export default function SessionExpiredForm(props) {
    const classes = useStyles();
    return (
        <Grid container direction="column">
            <TextField
                fullWidth
                className={classes.textField}
                name="PASSWORD"
                variant="outlined"
                type="password"
                label={lang.PASSWORD[props.language]}
                onChange={props.onChange}
                value={props.value}
                onKeyDown={(e) => {
                    if(e.key === 'Enter')
                        props.execute();
                }}
            />
        </Grid>
    );
}