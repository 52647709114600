import React, { useEffect, useState } from "react";
import CustomModal from "../../../../Components/Modals/CustomModal";
import {
    TextField,
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../actions/actions";

import * as lang from "../../../../Constants/strings";
import axios from "axios";

const useStyles = makeStyles(() => ({

}));

const InfoPapers = (props) => {
    const { open, close, language, siteId, siteData } = props;
    const classes = useStyles();

    const [contractors, setContractors] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('/api/executors_active');
                setContractors(response.data);
            } catch (error) {
                console.error(error);
            }
        })()
    }, []);

    return (
        <CustomModal
            open={open}
            title={lang.INFO_PAPERS[language]}
            close={close}
            content={
                <Grid alignItems="center" container spacing={2}>
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Typography className={classes.title} variant="h6">{lang.PERMIT[language]}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            name="permit_nr"
                            label={lang.PERMIT_NR[language]}
                            value={siteData.permit_nr ? siteData.permit_nr : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required
                                disabled
                                inputVariant="outlined"
                                fullWidth
                                name="permit_date"
                                label={lang.PERMIT_DATE[props.language]}
                                InputProps={{ readOnly: true }}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change permit_date",
                                }}
                                value={
                                    siteData.permit_date && siteData.permit_date !== null
                                        ? siteData.permit_date
                                        : null
                                }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Typography className={classes.title} variant="h6">{lang.PROJECT[language]}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            disabled
                            variant="outlined"
                            name="project_nr"
                            label={lang.PROJECT_NUMBER[language]}
                            value={siteData.project_nr ? siteData.project_nr : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required
                                disabled
                                inputVariant="outlined"
                                fullWidth
                                name="project_date"
                                label={lang.PROJECT_DATE[props.language]}
                                InputProps={{ readOnly: true }}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change permit_date",
                                }}
                                value={
                                    siteData.project_date && siteData.project_date !== null
                                        ? siteData.project_date
                                        : null
                                }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            freeSolo
                            disabled
                            variant="outlined"
                            name="project_contractor"
                            label={lang.PROJECT_CONTRACTOR[language]}
                            value={siteData.project_contractor ? siteData.project_contractor : ''}
                            renderInput={params => <TextField {...params} variant="outlined" name="project_contractor" />}
                            options={contractors}
                            getOptionLabel={o => typeof (o) === 'string' ? o : o.NAME}
                        />
                    </Grid>
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Typography className={classes.title} variant="h6">{lang.EVALUATION[language]}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            disabled
                            variant="outlined"
                            name="evaluation_no"
                            label={lang.EVALUATION_NUMBER[language]}
                            value={siteData.evaluation_no ? siteData.evaluation_no : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required
                                inputVariant="outlined"
                                fullWidth
                                disabled
                                name="evaluation_creation_date"
                                label={lang.EVALUATION_CREATION_DATE[props.language]}
                                InputProps={{ readOnly: true }}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change permit_date",
                                }}
                                value={
                                    siteData.evaluation_creation_date && siteData.evaluation_creation_date !== null
                                        ? siteData.evaluation_creation_date
                                        : null
                                }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required
                                disabled
                                inputVariant="outlined"
                                fullWidth
                                name="evaluation_expiration_date"
                                label={lang.EVALUATION_EXPIRATION_DATE[props.language]}
                                InputProps={{ readOnly: true }}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change permit_date",
                                }}
                                value={
                                    siteData.evaluation_expiration_date && siteData.evaluation_expiration_date !== null
                                        ? siteData.evaluation_expiration_date
                                        : null
                                }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            freeSolo
                            disabled
                            variant="outlined"
                            name="evaluation_contractor"
                            label={lang.EVALUATION_CONTRACTOR[language]}
                            value={siteData.evaluation_contractor ? siteData.evaluation_contractor : ''}
                            renderInput={params => <TextField {...params} variant="outlined" name="evaluation_contractor" />}
                            options={contractors}
                            getOptionLabel={o => typeof (o) === 'string' ? o : o.NAME}
                        />
                    </Grid>
                </Grid>
            }
            language={language}
            noButtons
        />
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        siteData: state.cust.siteData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPapers);