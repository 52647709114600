import React from 'react';
import { Grid, Button, makeStyles, Divider } from '@material-ui/core';
import * as lang from '../../Constants/strings';

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    button: {
        width: '100%',
        margin: theme.spacing(2),
    }
}));

export default function CustomButtonList(props) {
    const classes = useStyles();
    const { language, close, list, withBack, tiny } = props;
    return (
        <React.Fragment>
            {list.filter(b => b.render === true).map((e, index) =>
                <Grid container component='main' justify='center' alignItems='center' key={index}>
                    <Button
                        className={classes.button}
                        color={e.style.color}
                        variant={e.style.variant}
                        onClick={() => { e.action(); close(); }}
                        disabled={e.disabled ? true : false}
                    >
                        {e.style.icon}
                        {e.text}
                    </Button>
                </Grid>
            )}
            {withBack && tiny === true &&
                <React.Fragment>
                    <Divider className={classes.button} />
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Button
                            className={classes.button}
                            color="secondary"
                            variant="text"
                            onClick={close}
                        >
                            {lang.BACK[language]}
                        </Button>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    )
}