import React from 'react';
import { TextField, IconButton, Grid } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import { CreateRounded, DeleteRounded, CheckRounded } from '@material-ui/icons';
import CustomModal from '../../../Components/Modals/CustomModal';
import SignaturePad from 'react-signature-canvas';

export default function Trainee(props) {
    const { data, language, remove, update, classes, index, tiny } = props;
    const [traineeSign, setTraineeSign] = React.useState(false);
    const signCanvas = React.useRef({});

    return (
        <React.Fragment>
            <CustomModal
                fullWidth
                fullScreen={tiny}
                open={traineeSign === index}
                content={
                    <Grid item xs={12}>
                        <SignaturePad
                            ref={signCanvas}
                            canvasProps={{
                                className: classes.signature
                            }}
                        />
                    </Grid>
                }
                execute={() => {
                    update('SIGN', signCanvas.current.getTrimmedCanvas().toDataURL("image/svg+xml"));
                    signCanvas.current.clear()
                    setTraineeSign(false);
                }}
                close={() => {
                    signCanvas.current.clear()
                    setTraineeSign(false);
                }}
                language={language}
                validate={true}
            />
            <Grid container justify="space-between" className={classes.float} >
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        required
                        disabled={data.SIGN !== ""}
                        variant="outlined"
                        value={data.NAME}
                        label={`${lang.PERSON[language]} #${index + 1}`}
                        onChange={(e) => update('NAME', e.target.value)}
                    />
                </Grid>

                <Grid item xs='auto'>
                    <IconButton
                        color={data.SIGN === "" ? "primary" : "default"}
                        disabled={data.SIGN !== "" || data.NAME.length < 5}
                        onClick={() => setTraineeSign(index)}
                    >
                        {data.SIGN === "" ? <CreateRounded /> : <CheckRounded />}
                    </IconButton>
                    <input
                        onChange={() => 1+0}
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0, width: 0 }}
                        value={data.SIGN}
                        required
                    />
                </Grid>

                <Grid item xs='auto'>
                    <IconButton
                        color="secondary"
                        onClick={remove}
                    >
                        <DeleteRounded />
                    </IconButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
