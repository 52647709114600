import React, {useEffect, useState} from "react";
import axios from "axios";

import TransferList from "./TransferList";
import * as reqHeaders from "../../../Constants/requestHeaders";
import { Grid, Paper, Typography} from "@material-ui/core";
import * as lang from "../../../Constants/strings";
import {getGetHeader} from "../../../Constants/requestHeaders";

// Going to display all the transfers for the selected places of business (the team id's)
const InventoryTile = (props) => {
    const {
        userId,
        language
    } = props;

    const [inv, setInv] = useState(null);
    const [show, setShow] = useState(false);
    const [isTechnicalUser, setIsTechnicalUser] = useState(false);

    const fetchTeamInventory = async () => {
        try {
            const res = await axios.get("/api/team/inventory", {
                ...reqHeaders.getGetHeader(), params: {
                    id: userId
                }
            });

            setInv(res.data);
        } catch (e) {
            console.log(e);
        }
    }

    const checkIfUserHasTeam = async () => {
        try {
            const res = await axios.get('/api/user_team', getGetHeader());
            if (res.data.isTechnicalUser) {
                setIsTechnicalUser(res.data.isTechnicalUser);
                setShow(res.data.hasTeam);
            } else {
                setShow(false);
                setIsTechnicalUser(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (userId && isTechnicalUser) {
            fetchTeamInventory().then(() => {
            });
        }
    }, [userId, isTechnicalUser]);

    useEffect(() => {
        checkIfUserHasTeam().then(() => {});
    }, []);

    return (
        <>
            {
                isTechnicalUser && show && <div style={{width: "95vw", margin: "auto"}}>
                    <Grid container alignItems="center" justify="center" style={{ marginTop: "10px" }}>
                        <Typography variant="h4" >{inv?.name}</Typography>
                    </Grid>
                    <Paper style={{padding: "10px", marginTop: "10px"}}>
                        {inv && inv.data.length > 0 && inv.data.map((x, index) => <TransferList
                            key={`transfer-list-${index}`}
                            id={x.id}
                            companyName={x.companyName}
                            companyId={x.companyId}
                            userId={userId}
                            language={language}
                        />)}
                    </Paper>

                </div>
            }
            {
                isTechnicalUser && !show && <Paper style={{ padding: "10px", marginTop: "10px", width: "95vw" }}>
                    <Grid container direction="row" justify="center" justifyContent="center" alignContent="center">
                            <Typography varaint="h5">
                                {lang.TEAM_NOT_SELECTED_TODAY[language]}
                            </Typography>
                    </Grid>
                </Paper>
            }
        </>
    );
}

export default InventoryTile;