import React from "react";
import {
    Grid,
    Typography,
    makeStyles
} from "@material-ui/core";

import * as lang from "../../Constants/strings";
import CustomModal from "./CustomModal";
import Comment from "../../MainView/Site/SiteSingleViewComponents/Comment";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        width: "100%",
    },
    avatar: {
        width: 50,
        height: 50,
    },
    title: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    comment: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    commentAuto: {
        color: "gray",
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    timestamp: {
        marginTop: theme.spacing(1.5),
    },
    divider: {
        margin: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        marginTop: theme.spacing(1),
    },
    iconRight: {
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    signature: {
        backgroundColor: "white",
        width: "100%",
        height: "50vh",
    },
    float: {
        margin: theme.spacing(1),
    },
}));

export default function TaskCommentHistory(props) {
    const {
        language,
        tiny,
        data,
        open,
        close
    } = props;

    const classes = useStyles();
    return (
        <CustomModal
            open={open}
            title={lang.COMMENTS[language]}
            content={
                <React.Fragment>
                    <Grid item xs={12} >
                        <Typography variant={tiny ? "h6" : "h5"}>{lang.HISTORY[language]}</Typography>
                    </Grid>
                    {data !== undefined && data.length >= 0 ?
                        data.map((item, index) =>
                            <Comment isIntervention={true} classes={classes} key={index} data={item} language={language}
                                downloadFile={props.downloadFile} tiny={tiny} />
                        )
                        :
                        <p>Nici un comentariu</p>
                    }
                </React.Fragment>
            }
            close={() => close()}
            execute={() => { }}
            language={language}
            validate={false}
        />
    )
}
