import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Chip, Input, Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../../Constants/strings';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import axios from 'axios';
import { checkSpecialChar } from '../../../../Functions/stringManipulation';


const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));

export default function SiteAreaNewForm(props) {
    const classes = useStyles();
    const { addData, language, errorCode, errorName, updateErrorCode, updateErrorName } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{ position: 'relative' }}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="AREA_CODE"
                        label={lang.SITE_AREA_CODE[language]}
                        value={addData.AREA_CODE ? addData.AREA_CODE : ""}
                        onChange={(e) => props.updateField(e, 50)}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div style={{ position: 'relative' }}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="AREA_NAME"
                        label={lang.SITE_AREA_NAME[language]}
                        value={addData.AREA_NAME ? addData.AREA_NAME : ""}
                        onChange={(e) => props.updateField(e, 50)}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div style={{ position: "relative" }}>
                    <TextField
                        required
                        fullWidth
                        variant='outlined'
                        name="AREA_DESCRIPTION"
                        label={lang.AREA_DESCRIPTION[language]}
                        value={addData.AREA_DESCRIPTION ? addData.AREA_DESCRIPTION : ""}
                        onChange={(e) => props.updateField(e, 50)}
                    />
                </div>
            </Grid>
        </Grid>
    );
}