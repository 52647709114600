import React from 'react';
import { makeStyles, Grid, Button, Fab, MenuItem, Menu, Typography, Paper, Grow } from '@material-ui/core';
import GroupButtonSet from '../../../Components/Misc/GroupButtonSet';
import { Replay, EditRounded, VisibilityRounded, CheckRounded, Receipt, VerifiedUserRounded, DescriptionRounded, LocalShippingRounded, AssignmentLateRounded, AssignmentIndRounded, PauseRounded, ForwardRounded, DoneRounded, CancelRounded, ShortText } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import CustomDrawer from '../../../Components/Modals/CustomDrawer';
import Skeleton from '@material-ui/lab/Skeleton';
import { checkPermissions } from '../../../Functions/permissionHide';
import {CLOSE_MAINTENANCE_WITH_IMAGE} from "../../../Constants/strings";


const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(2),
    },
    float: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 99,
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.5),
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
}));

export default function MaintenanceButtons(props) {
    const classes = useStyles();
    const { language, tiny, maintenance, userId, userPermissions, isAuthenticated, openRegisterProgress } = props;

    console.log(maintenance ? maintenance.STATUS: '-');
    return (
        <React.Fragment>
            <Grid item container direction="row" xs={12} lg={12} style={{ paddingRight: '0px' }}>
                {maintenance &&
                    <GroupButtonSet
                        tiny={tiny}
                        language={language}
                        style={{
                            size: "large",
                            color: "default",
                            variant: "contained",
                            disabled: false,
                        }}
                        list={[
                            {
                                primary: userId === maintenance.OWNER_CURR,
                                text: lang.NEXT_STATUS(maintenance.STATUS)[language],
                                style: {
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(maintenance.STATUS) !== -1 ||
                                        ["STARTED"].indexOf(maintenance.STATUS) !== -1 && maintenance.CONTRACT_OBJID === null,
                                },
                                icon: <ForwardRounded />,
                                render: userId === maintenance.OWNER_CURR && isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "MAINTENANCE",
                                        name: "TAKE_MAINTENANCE",
                                    }) === true
                                    ? true
                                    : false,
                                action: () => props.openAction(maintenance.STATUS_ID, false),
                            },
                            {
                                primary: userId === maintenance.OWNER_CURR,
                                text: lang.CLOSE_MAINTENANCE_WITH_IMAGE[language],
                                style: {},
                                icon: <ForwardRounded />,
                                render: userId === maintenance.OWNER_CURR && isAuthenticated && maintenance.STATUS === 'STARTED',
                                action:  props.openCloseMaintenanceWithImage,
                            },
                            {
                                primary: userId === maintenance.OWNER_CURR,
                                text: lang.ASSIGN_TO_ME[language],
                                style: {
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(maintenance.STATUS) !== -1 ||
                                        ["STARTED"].indexOf(maintenance.STATUS) !== -1 && maintenance.CONTRACT_OBJID === null,
                                },
                                icon: <ForwardRounded />,
                                render: userId !== maintenance.OWNER_CURR &&
                                    isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "MAINTENANCE",
                                        name: "TAKE_MAINTENANCE",
                                    }) === true
                                    ? true
                                    : false,
                                action: () => props.openAsignToMeModal(),
                            },
                            // {
                            //     primary: userId !== maintenance.OWNER_CURR,
                            //     text: lang.ASSIGN_TO_ME[language],
                            //     style: {
                            //         disabled: ["OPEN", "ON_HOLD"].indexOf(maintenance.STATUS) === -1,
                            //     },
                            //     icon: <AssignmentIndRounded />,
                            //     leftIcon: <AssignmentIndRounded fontSize="small" className={classes.leftIcon} />,
                            //     action: () => props.openAction("ASSIGN", maintenance.STATUS_ID),
                            //     render: isAuthenticated &&
                            //         checkPermissions(userPermissions, {
                            //             parent: "MAINTENANCE",
                            //             name: "TAKE_MAINTENANCE",
                            //         }) === true
                            //         ? true
                            //         : false,
                            // },
                            {
                                text: lang.REGISTER_ITEMS_TO_TASK[language],
                                icon: <ShortText />,
                                style: {
                                    color: "primary",
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(maintenance.STATUS) !== -1
                                },
                                render: ["CLOSED", "CANCELLED"].indexOf(maintenance.STATUS) === -1 &&
                                    userId === maintenance.OWNER_CURR &&
                                    checkPermissions(userPermissions, { parent: "TECHNICAL_TEAM", name: "EXTERNAL_INVENTORY" }),
                                action: () => openRegisterProgress(),
                            },
                            {
                                text: lang.CANCEL[language],
                                style: {
                                    color: "secondary",
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(maintenance.STATUS) !== -1
                                },
                                icon: <CancelRounded />,
                                rightIcon: <CancelRounded fontSize="small" className={classes.rightIcon} />,
                                render: ["CANCELLED"].indexOf(maintenance.STATUS) === -1 && isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "MAINTENANCE",
                                        name: "CANCEL_MAINTENANCE",
                                    }) === true
                                    ? true
                                    : false,
                                action: () => props.openActionCancelModal(),
                            },
                            {
                                text: lang.RESET_ALOCATION[language],
                                style: {
                                    color: "primary",
                                    disabled: ["CLOSED", "CANCELLED"].indexOf(maintenance.STATUS) !== -1
                                },
                                icon: <Replay />,
                                rightIcon: <Replay fontSize="small" className={classes.rightIcon} />,
                                render: ["CANCELLED"].indexOf(maintenance.STATUS) === -1 && isAuthenticated &&
                                    checkPermissions(userPermissions, {
                                        parent: "MAINTENANCE",
                                        name: "RESET_MAINTENANCE",
                                    }) === true
                                    ? true
                                    : false,
                                action: () => props.openResetAlocationModal(),
                            },
                        ]}
                    />
                }
            </Grid>
        </React.Fragment>
    );
}
export function SkeletonmaintenanceButtons(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={8} style={{ paddingRight: '0px' }} >
            <Skeleton component={Paper} className={classes.paperClear}>
                <Grid container justify="center" alignItems="center" direction="column">
                    {/* <Skeleton width="15%" className={classes.title} /> */}
                </Grid>
            </Skeleton>
        </Grid>
    );
}