import React, {useState} from "react";
import {
    Grid,
    TextField,
} from "@material-ui/core";

import axios from "axios";
import CustomModal from "../../../../Components/Modals/CustomModal";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import * as lang from "../../../../Constants/strings";

const CancelModal = (props) => {
    const {
        open,
        close,
        language,
        maintenanceId,
        userId,
        refetch
    } = props;

    const [note, setNote] = useState("");

    const cancelMaintenance = async () => {
        try {
            let res = await axios.post("/api/cancel_maintenance", {
                id: maintenanceId, userId: userId, note: note },{
                ...requestHeaders.getPostHeader(),
            });
            refetch();
            close();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <CustomModal 
            open={open}
            close={close}
            fullWidth
            title={`Anulare mentenanță`}
            content ={
                <Grid container spacing={2}>
                    <TextField 
                        label={lang.OBSERVATION[language]}
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            }
            execute={() => cancelMaintenance()}
            validate={true}
            language={language}
        />
    )
};

export default CancelModal;