// This object containe the Billing Cycle end and start months
// Will be use to get a specific interval for billing
const billingDates = {
    BILL_CURRENT_MONTH: [
        {
            startMonth: 0, endMonth: 0, name: {
                'en': 'January',
                'ro': 'Ianuarie'
            }
        },
        {
            startMonth: 1, endMonth: 1, name: {
                'en': 'February',
                'ro': 'Februarie'
            }
        },
        {
            startMonth: 2, endMonth: 2, name: {
                'en': 'March',
                'ro': 'Martie'
            }
        },
        {
            startMonth: 3, endMonth: 3, name: {
                'en': 'April',
                'ro': 'Aprilie'
            }
        },
        {
            startMonth: 4, endMonth: 4, name: {
                'en': 'May',
                'ro': 'Mai'
            }
        },
        {
            startMonth: 5, endMonth: 5, name: {
                'en': 'June',
                'ro': 'Iunie'
            }
        },
        {
            startMonth: 6, endMonth: 6, name: {
                'en': 'July',
                'ro': 'Iulie'
            }
        },
        {
            startMonth: 7, endMonth: 7, name: {
                'en': 'August',
                'ro': 'August'
            }
        },
        {
            startMonth: 8, endMonth: 8, name: {
                'en': 'September',
                'ro': 'Septembrie'
            }
        },
        {
            startMonth: 9, endMonth: 9, name: {
                'en': 'October',
                'ro': 'Octombrie'
            }
        },
        {
            startMonth: 10, endMonth: 10, name: {
                'en': 'November',
                'ro': 'Noiembrie'
            }
        },
        {
            startMonth: 11, endMonth: 11, name: {
                'en': 'December',
                'ro': 'Decembrie'
            }
        },
    ],
    BILL_SEMESTER: [
        {
            startMonth: 0, endMonth: 5, name: {
                'en': 'First semester',
                'ro': 'Primul semenstru'
            }
        },
        {
            startMonth: 6, endMonth: 11, name: {
                'en': 'Second semester',
                'ro': 'Semestrul al doilea'
            }
        },
    ],
    BILL_QUARTER: [
        {
            startMonth: 0, endMonth: 2, name: {
                'en': 'First quarter',
                'ro': 'Primul trimestru'
            }
        },
        {
            startMonth: 3, endMonth: 5, name: {
                'en': 'Second quarter',
                'ro': 'Trimestrul al doilea'
            }
        },
        {
            startMonth: 6, endMonth: 8, name: {
                'en': 'Third quarter',
                'ro': 'Semestrul al treilea'
            }
        },
        {
            startMonth: 9, endMonth: 11, name: {
                'en': 'Forth quarter',
                'ro': 'Semestrul al patrulea'
            }
        },
    ],
    BILL_YEARLY: [
        {
            startMonth: 0, endMonth: 11, name: {
                'en': 'Year',
                'ro': 'Anul'
            }
        },
    ]
};

export default billingDates;
