import React, { useEffect, useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    TextField,
    makeStyles,
    Paper,
    Grid,
    MenuItem
} from "@material-ui/core";

import * as lang from "../../../Constants/strings";
import { getGetHeader } from "../../../Constants/requestHeaders";

const useStyles = makeStyles(() => ({
    textField: {
        width: "95%",
        margin: "0% 2.5%"
    }
}));

const MaintenanceHistoryFilters = (props) => {
    const classes = useStyles();
    const {
        language,
        fetchHistory
    } = props;

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedArea, setSelectedArea] = useState();

    const [areaList, setAreaList] = React.useState([]);

    const getAreaList = async () => {
        let response = await axios.get(`/api/areas`, getGetHeader);

        if (response.status === 200) {
            setAreaList(response.data.data);
        } else {
            setAreaList([]);
        }
    };

    useEffect(() => {
        getAreaList();

    }, []);

    useEffect(() => {
        fetchHistory(startDate, endDate, selectedArea ? selectedArea.AREA_CODE : undefined)
    }, [startDate, endDate, selectedArea]);

    return (
        <>
            <Paper>
                <Grid container spacing={6} justify="center">
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            variant="outlined"
                            className={classes.textField}
                            label={lang.AREA[language]}
                            value={selectedArea ? selectedArea : 0}
                            onChange={(e, newValue) => setSelectedArea(newValue.props.value)}
                        >
                            <MenuItem value={0}>Niciuna</MenuItem>
                            {areaList.map(area => {
                                return <MenuItem key={area.AREA} value={area}>{area.AREA}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                className={classes.textField}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.START_INTERVAL_DATE[language]}
                                value={startDate}
                                onChange={date => setStartDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                className={classes.textField}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.LAST_INTERVAL_DATE[language]}
                                value={endDate}
                                onChange={date => setEndDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
};

export default MaintenanceHistoryFilters;