import { format, getISOWeek } from 'date-fns';
import { ro } from 'date-fns/esm/locale';

export function formatDateFromDb(date, type) {
    if (date === null)
        return '-';
    var dateObject = new Date(date);
    var day = {
        y: dateObject.getFullYear(),
        mo: dateObject.getMonth(),
        d: dateObject.getDate(),
        h: dateObject.getHours(),
        m: dateObject.getMinutes(),
        s: dateObject.getSeconds(),
    }
    try {
        switch (type) {
            case 1: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dddd, Do MMM yyyy, hh:mm:ss ', { locale: ro });
            case 2: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd MMM yyyy', { locale: ro });
            case 3: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd MMM yyyy, hh:mm:ss ', { locale: ro });
            case 4: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dddd, dd MMM yyyy', { locale: ro });
            case 5: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd-MMM-yy', { locale: ro });
            case 6: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'hh:mm:ss ', { locale: ro });
            case 7: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dddd, hh:mm:ss ', { locale: ro });
            case 8: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd-MM-yyyy', { locale: ro });
            case 9: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'MMMM', { locale: ro });
            case 10: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'yyyy', { locale: ro }) + '/' + getISOWeek(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), { weekStartsOn: 1, firstWeekContainsDate: 4 });
            case 11: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'yyyy', { locale: ro });
            case 12: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd-MM-yyyy, HH:mm ', { locale: ro });
            case 13: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd-MM-yyyy, HH:mm:ss ', { locale: ro });
            case 14: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd-MM-yyyy, hh:mm ', { locale: ro });
            case 15: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'yyyy-MM-dd', { locale: ro });
            default: return format(new Date(day.y, day.mo, day.d, day.h, day.m, day.s), 'dd-mm-yy', { locale: ro });
        }
    } catch (e) {
        console.log(e);
        return '-';
    }
}

export function getTimeLine(then, now) {
    let nowTime = Math.round(now.getTime() / 1000);
    let thenTime = Math.round(then.getTime() / 1000);

    if (nowTime - thenTime < 300)
        return 0;
    else {
        thenTime += 300;

        if (nowTime - thenTime < 60)
            return "acum " + (nowTime - thenTime) + " secunde";
        else {
            if (Math.round(nowTime / 60) - Math.round(thenTime / 60) < 60)
                return "acum " + (Math.round(nowTime / 60) - Math.round(thenTime / 60)) + " minute"
            else {
                if (Math.round(nowTime / 3600) - Math.round(thenTime / 3600) < 12)
                    return "acum " + (Math.round(nowTime / 3600) - Math.round(thenTime / 3600)) + " ore";
                else {
                    if (Math.round(nowTime / 3600) - Math.round(thenTime / 3600) < 24) {
                        if (then.getDate() === now.getDate())
                            return "acum " + (Math.round(nowTime / 3600) - Math.round(thenTime / 3600)) + " ore";
                        else
                            return "ieri, " + formatDateFromDb(then, 6);
                    }
                    else {
                        if (Math.round((nowTime - thenTime) / 3600) < 48 && Math.round((nowTime - thenTime) / (3600 * 24) < 2))
                            return "ieri, " + formatDateFromDb(then, 6);
                        else {
                            if (Math.round(nowTime - thenTime) / (3600 * 24) < 7)
                                return formatDateFromDb(then, 7);
                            else
                                return formatDateFromDb(then, 1);
                        }
                    }
                }
            }
        }
    }
}

// This function will get a start year
// Will return a list of years from the start year till the current year or a end year
// If the startYear will not be define the the list will start with 1980
export function getRangeOfYears(startYear, endYear) {
    let currentYear = endYear ? endYear : new Date().getFullYear();
    let years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
        years.unshift(startYear++);
    };
    return years;
};


// This function will return a object that contain 2 keys a startDate and a endDate
// It will take minimum 2 atributs startMonth and startYear
export function getDateInterval(startMonth, startYear, endMonth, endYear, startDay, endDay) {
    let firstDay = startDay ? startDay : 1
    let finishYear = endYear ? endYear : startYear;
    let finishMonth = endMonth ? endMonth : startMonth;
    let finishDay = endDay ? endDay : new Date(finishYear, endMonth + 1, 0).getDate();

    return {
        startDate: new Date(startYear, startMonth, firstDay),
        endDate: new Date(finishYear, finishMonth, finishDay)
    }
};

// Add the specife number of months to the date
export function addMonthToDate(date, months) {
    // Create a new date base on the gived date
    // So we can avoid the change of passed object
    let newDate = new Date(date);
    newDate.setMonth(date.getMonth() + Number(months));
    return newDate;
}
