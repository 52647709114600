import React from "react";
import InterventionManagement from "./InterventionManagement";
import {
    CssBaseline,
    makeStyles,
    Grid
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: "auto",
    }
}));

const AreaManagement = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <CssBaseline />
            <InterventionManagement />
        </Grid>
    )
};

export default AreaManagement;
