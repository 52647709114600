import { getNameForBlob } from './fileNameForBlobs';
// This method will decode the ASCII string to binary and retunr it as a atribut on the callback function
// Using the WindowOrWorkerGlobalScope.atob() method, see the MDN web docs
function getDataURL(b64Data, type) {
    const byteCharacters = atob(b64Data); // the actuale converting from base 64 to binary
    let dataUrl = `data:${type};base64,` + byteCharacters; // add the missing bits
    return dataUrl;
};

export function downloadFile(name) {
    fetch(`/api/download_pdf?file=${name}`, {
        headers: {
            'Content-Type': 'application/pdf',
            'authorization': localStorage.token,
        }
    })
        .then(response => {
            if (response.status >= 400) {
                console.log("Bad response from server");
            }
            try {
                response.json()
                    .then(res => {
                        let dataUrl = getDataURL(res.data.fileBase64, res.data.fileInfo.MIME_TYPE);
                        let B = new Blob([dataUrl], { type: `${res.data.fileInfo.MIME_TYPE};` });
                        var link = document.createElement('a');
                        link.href = dataUrl;
                        link.download = getNameForBlob(res.data.fileInfo.MIME_TYPE);
                        link.click();
                    });
            } catch (e) {
                console.log('err')
            }
        })
};