export function custAddFormat(type, cust, address, city, region) {
    let custData = {};
    type === 'PF' ?
        custData = {
            FIRST_NAME: cust[type][0].value,
            LAST_NAME: cust[type][1].value,
            CNP: cust[type][2].value,
            PHONE: cust[type][3].value,
            EMAIL: cust[type][4].value,
        }
        :
        custData = {
            NAME: cust[type][0].value,
            CUI: cust[type][1].value,
            NRC: cust[type][2].value,
            ADMINISTRATOR: cust[type][3].value,
            PHONE: cust[type][4].value,
            EMAIL: cust[type][5].value,
        };

    return {
        type: type,
        cust: custData,
        address: {
            REGION: region.REGION,
            CITY: city.CITY,
            STREET_NAME: address.street.value,
            STREET_NO: address.nrStreet.value,
            BUILDING: address.building.value,
            ENTRANCE: address.scale.value,
            APARTMENT: address.apartment.value,
            GPS: address.gps.value,
        }
    };
}

export function custAddFormat2(data) {
    return {
        type: data.TYPE,
        cust: data.TYPE === 'PF' ? {
            CUSTOMER_CODE: data.CNP,
            FIRST_NAME: data.FIRST_NAME,
            LAST_NAME: data.LAST_NAME,
            CNP: data.CNP,
            PHONE: data.PHONE,
            EMAIL: data.EMAIL,
            HOME: data.HOME,
            BILL: data.BILL,
        }
            : {
                CUSTOMER_CODE: data.CUI,
                NAME: data.NAME,
                CUI: data.CUI,
                NRC: data.NR_REG,
                ADMINISTRATOR: data.ADMINISTRATOR,
                PHONE: data.PHONE,
                EMAIL: data.EMAIL,
                HOME: data.HOME,
                BILL: data.BILL,
            },
        address: {
            REGION: data.REGION,
            CITY: data.CITY,
            STREET_NAME: data.STREET_NAME,
            STREET_NO: data.STREET_NO,
            BUILDING: data.BUILDING,
            ENTRANCE: data.ENTRANCE,
            FLOOR: data.FLOOR,
            APARTMENT: data.APARTMENT,
        }
    }
}

export function siteAddFormat(data) {
    return {
        contact: {
            FIRST_NAME: data.CONTACT_FIRST_NAME,
            LAST_NAME: data.CONTACT_LAST_NAME,
            PHONE: data.CONTACT_PHONE,
        },
        address: {
            REGION: data.REGION,
            CITY: data.CITY,
            STREET_NAME: data.STREET_NAME,
            STREET_NO: data.STREET_NO,
            BUILDING: data.BUILDING,
            ENTRANCE: data.ENTRANCE,
            FLOOR: data.FLOOR,
            APARTMENT: data.APARTMENT,
            LATITUDE: data.LATITUDE,
            LONGITUDE: data.LONGITUDE,
            AREA_CODE: data.AREA_CODE,
            CONTACT_OBJID: data.CONTACT_OBJID,
        },
        site: {
            SITE_CODE: data.SITE_CODE,
            SITE_NAME: data.SITE_NAME,
            OBS: data.OBS,
            PHONE: data.PHONE,
            EMAIL: data.EMAIL,
            CENTRAL_TYPE: data.CENTRAL_TYPE,
            CENTRAL_PASSWORD: data.CENTRAL_PASSWORD,
            CENTRAL_PHONE: data.CENTRAL_PHONE,
            AREA_TYPE: data.AREA_TYPE,
            FK2SITE_TYPE: data.FK2SITE_TYPE,
            SITE_LANDMARKS: data.SITE_LANDMARKS,
            permit_nr: data.permit_nr,
            permit_date: data.permit_date,
            project_nr: data.project_nr,
            project_date: data.project_date,
            project_contractor: data.project_contractor,
            evaluation_no: data.evaluation_no,
            evaluation_creation_date: data.evaluation_creation_date,
            evaluation_expiration_date: data.evaluation_expiration_date,
            evaluation_contractor: data.evaluation_contractor
        }
    }
}

export function addressToString(address, type) {
    switch (type) {
        case 1: return `${address.REGION}, ${address.CITY}, ${address.STREET_NAME}${address.STREET_NO ? " Nr. " + address.STREET_NO : ""}${address.BUILDING ? ", Bloc " + address.BUILDING : ""}${address.ENTRANCE ? ", Scara " + address.ENTRANCE : ""}${address.APARTMENT ? ", Ap. " + address.APARTMENT : ""}`;
        case 2: return `${address.REGION}, ${address.CITY}, ${address.STREET_NAME}${address.STREET_NO ? " Nr. " + address.STREET_NO : ""}`;
        case 3: return `${address.REGION}, ${address.CITY}, ${address.STREET_NAME}${address.STREET_NO ? " Nr. " + address.STREET_NO : ""}${address.BUILDING ? "," + address.BUILDING : ""}`;
        default: return `${address.REGION}, ${address.CITY}, ${address.STREET_NAME}`;
    }
}

export function fetchFunction(url, setResult) {
    fetch(`/api/${url}`, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': localStorage.token,
        }
    })
        .then(response => response.json())
        .then(response => {
            setResult(response);
        })
        .catch(err => console.log(err))
}
