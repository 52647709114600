import React from 'react';
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { checkPermissions } from '../../../../Functions/permissionHide';
import { Grid, InputAdornment, TextField, FormControl, MenuItem, FormHelperText, InputLabel, Select, Button } from '@material-ui/core';
import { ContactSupportOutlined } from '@material-ui/icons';

export default function TaskContract(props) {
    const { open, language, siteId, close } = props;
    const [contractList, setContractList] = React.useState([]);
    const [contract, setContract] = React.useState(null);

    React.useEffect(() => {
        fetch(`/api/get_site_contracts?id=${siteId}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
            .then(response => response.json())
            .then(response => {
                setContract('')
                setContractList(response)
            })
            .catch(err => console.log(err))
    }, [siteId, open]);

    React.useEffect(() => {
        if (contractList.length !== 0) {
            setContract((props.taskData && props.taskData.CONTRACT_OBJID !== null && props.taskData.CONTRACT_OBJID !== undefined) ? props.taskData.CONTRACT_OBJID : '')
        }
    }, [contractList])

    function updateTaskContract(contractObjid, taskObjid) {
        fetch(`/api/update_task_contract`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                },
                body: JSON.stringify({ CONTRACT_OBJID: contractObjid, OBJID: taskObjid })
            })
            .then(response => {
                if (response.status >= 400) {
                    props.onNotificationOpen('warning', "ERROR_OCCURED")
                } else {
                    props.onNotificationOpen('success', 'DATA_UPDATE_SUCCESS')
                }
            })
    };

    return (
        <React.Fragment>
            <CustomModal
                fullWidth
                open={open}
                title={lang.CONTRACTS[language]}
                content={
                    <Grid container justify='center' alignItems='center' spacing={2}>
                        <Grid item xs={12}  >
                            <FormControl
                                fullWidth
                                variant="outlined"
                                required
                            >
                                <InputLabel >{lang.CONTRACT_ACTIVE[language]}</InputLabel>
                                <Select
                                    name="CONTRACTOR"
                                    fullWidth
                                    value={contract}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setContract(e.target.value)
                                    }}
                                >
                                    <MenuItem value={""}><em>niciunul</em></MenuItem>
                                    {contractList.length > 0 &&
                                        contractList.map(contract => {
                                            if (contract.TYPE === "CONTRACT_TYPE_TECHNIC")
                                                return <MenuItem key={contract.OBJID} value={contract.OBJID}>{`${lang[contract.SUB_TYPE][language]} - ${contract.CONTRACTOR_NAME} #${contract.CONTRACT_NO}`}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item  >
                            <Button
                                variant='contained'
                                color='secondary'
                                disabled={!(props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CONTRACTS", name: "ADD_CONTRACT" }))}
                                onClick={() => {
                                    props.openNewContract();
                                    close();
                                }}
                            >
                                {lang.ADD_CONTRACT[language]}
                            </Button>
                        </Grid>
                    </Grid>
                }
                close={() => close()}
                execute={async () => {
                    close();
                    await updateTaskContract(contract, props.taskData.OBJID);
                    await props.refeatchTaskData();
                }}
                validate={contract !== ''}
                language={language}
            />
        </React.Fragment>
    )
}
