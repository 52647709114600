import React from 'react';
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { useMediaQuery, useTheme, Grid } from '@material-ui/core';
import ContractNewForm from '../ContractNewForm';


export default function AddContractModal(props) {
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const { language, open, close, validate, addData, addContract } = props;

    const renderBaseOnContractType = (subTypes = [], type = 1) => {
        return addData ? (addData.TYPE === type && subTypes.findIndex(t => t === addData.SUB_TYPE) !== -1) : false
    };

    return (
        <CustomModal
            open={open}
            title={lang.ADD_CONTRACT[language]}
            content={
                <Grid container spacing={2}>
                    {props.open
                        ?
                        <ContractNewForm
                            {...props}
                            addData={addData}
                            tiny={tiny}
                        />
                        : null
                    }
                </Grid>
            }
            close={close}
            execute={async () => {
                await addContract();
                await close();
            }}
            language={language}
            formValidate={true}
          // validate={(renderBaseOnContractType([1], 2) || renderBaseOnContractType([4], 3)) || (addData?.MAINTENANCE_TYPE && addData.MAINTENANCE_TYPE.subTypes.find(item => item.checked === true) !== undefined)}
        />
    )
}