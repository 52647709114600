import React, { useState } from 'react';
import { Grid, TextField, ButtonGroup, Button, Grow, Typography } from '@material-ui/core';
import { ReplyRounded, AddRounded, AttachFile } from '@material-ui/icons';

import Comment from './Comment';
import * as lang from '../../../../Constants/strings';

const CommentList = (props) => {
    const { language, tiny, data, task, classes, isIntervention, addComment } = props;
    const [note, setNote] = useState("");
    const [show, setShow] = useState(false);

    function handleChange(e) {
        const { value } = e.target;
        setNote(value);
    }

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.divider}>
                <Typography variant={tiny ? "h6" : "h5"}>{lang.HISTORY[language]}</Typography>
            </Grid>
            {data && data.length >= 0 ?
                data.map((item, index) =>
                    <Comment isIntervention={isIntervention} classes={classes} key={index} data={item} language={language}
                        downloadFile={props.downloadFile} tiny={tiny} />
                )
                :
                <p>Nici un comentariu</p>
            }
            {show &&
                <Grid item xs={12}>
                    <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                        <TextField
                            fullWidth
                            multiline
                            className={classes.textField}
                            variant="outlined"
                            label={lang.COMMENT[language]}
                            value={note}
                            onChange={handleChange}
                        />
                    </Grow>
                </Grid>
            }
            <Grid item xs={12}>
                <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                    {show
                        ?
                        <ButtonGroup
                            className={classes.buttonGroup}
                            color="primary"
                        >
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => { setShow(false); setNote(""); }}
                            >
                                {lang.CANCEL_REPLY[language]}
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => props.openFileUploadModal()}
                            >
                                {lang.FILE_UPLOAD[props.language]}
                                <AttachFile className={classes.leftIcon} />
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    addComment(
                                        note,
                                        props.uploadedFile
                                    );
                                    setNote("");
                                    setShow(false)
                                }}
                            >
                                {lang.REPLY[language]}
                                <ReplyRounded className={classes.iconRight} />
                            </Button>
                        </ButtonGroup>
                        :
                        <Button
                            className={classes.buttonGroup}
                            color="primary"
                            variant="contained"
                            onClick={() => setShow(true)}
                        >
                            <AddRounded className={classes.leftIcon} />
                            {lang.REPLY[language]}
                        </Button>
                    }
                </Grow>
            </Grid>
        </React.Fragment>
    );
}

export default CommentList;