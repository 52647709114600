export const REGION = {
    "en": "Region",
    "ro": "Județ",
};
export const CITY = {
    "en": "City",
    "ro": "Oraș",
};
export const STREET = {
    "en": "Street",
    "ro": "Stradă",
};
export const STREET_NAME = {
    "en": "Street",
    "ro": "Stradă",
};
export const STREET_NO = {
    "en": "Street number",
    "ro": "Nr. Stradă",
};
export const BUILDING = {
    "en": "Building",
    "ro": "Bloc",
};
export const ENTRANCE = {
    "en": "Entrance",
    "ro": "Scară",
};
export const FLOOR = {
    "en": "Floor",
    "ro": "Etaj",
};
export const APARTMENT = {
    "en": "Apartment",
    "ro": "Apartament",
};
export const LATITUDE = {
    "en": "Latitude",
    "ro": "Latitudine",
};
export const LONGITUDE = {
    "en": "Longitude",
    "ro": "Longitudine",
};

export const ADDRESS_TYPE_CECKBOX = {
    "en": "Use this address as: ",
    "ro": "Folosiți această adresă ca: ",
};
export const BILLING_ADDRESS = {
    "en": "Billing address",
    "ro": "Adresă de facturare",
};
export const HOME_ADDRESS = {
    "en": "Home address",
    "ro": "Adresă de domiciliu",
};
export const SITE_ADDRESS_DISABLE = {
    "en": "Update the address also",
    "ro": "Actualizați și adresa",
};
export const AREA = {
    "en": "Area",
    "ro": "Zonă",
};