import React from 'react';
import * as lang from '../../Constants/strings';
import { Select, Grid, Button, MenuItem, Typography, Divider } from '@material-ui/core';
import CustomModal from '../../Components/Modals/CustomModal';

export default function UploadFiles(props) {

    // The 'execute' is a function that will set the file buffer with the selected file or null
    const { open, close, execute, language } = props;

    // This determin what type of file can be uploaded;
    // If the fileType is null then the upload btn will be disabled
    const [fileType, setFileType] = React.useState(null);

    // use only for displaying the uploaded file name 
    const [fileName, setFileName] = React.useState('');

    // On a the change of the filetype reset the uploaded file 
    // So the type of the file and actuale file are the same
    const setNewFileType = (filetype) => {
        execute(null);
        setFileName('');
        setFileType(filetype);
    };

    // Creat a ref for the hide input
    const fileUploadRef = React.createRef();

    // The method takes a binary string and convert it to base64 encoded ASCII string
    // Using the WindowOrWorkerGlobalScope.btoa() method, see the MDN web docs
    function getData(file, callback) {
        let reader = new FileReader();
        reader.onload = (event) => {
            let data = event.target.result.split(',');
            console.log(data[0]);
            let decodedImageData = btoa(data[1]);                    // the actual conversion of data from binary to base64 format
            callback({ data: decodedImageData, name: file.name, fileType: file.type, }); // Generating the object for the req
        };
        reader.readAsDataURL(file);
    }

    return (
        <React.Fragment>
            <CustomModal
                // fullWidth

                open={props.open}
                title={lang.FILE_UPLOAD[language]}
                content={
                    <Grid container spacing={2} alignItems='center' justify='center' alignContent='center'>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                label={lang.FILE_TYPE[language]}
                                value={fileType}
                                onChange={e => setNewFileType(e.target.value)}
                                variant='outlined'
                            >
                                <MenuItem value={null}>NONE</MenuItem>
                                <MenuItem value={'image/*'}>IMAGINE</MenuItem>
                                <MenuItem value={'application/pdf'}>PDF</MenuItem>
                                <MenuItem value={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}> MICROSOFT EXCEL </MenuItem>
                                <MenuItem value={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'}> MICROSOFT WORD </MenuItem>
                                <MenuItem value={'text/html'}>HTML</MenuItem>
                                <MenuItem value={'audio/*'}>AUDIO</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => fileUploadRef.current.click()}
                                disabled={fileType === null}
                            >
                                {lang.FILE_UPLOAD[language]}
                            </Button>
                        </Grid>
                        {
                            fileName !== '' && <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        }
                        {
                            fileName !== '' && <Typography variant='h5'>{fileName}</Typography>
                        }
                        <input
                            type="file"
                            accept={fileType}
                            style={{ display: "none" }}
                            ref={fileUploadRef}
                            tabIndex={-1}
                            onChange={async (e) => {
                                e.persist();
                                // Get the base64 encoded file
                                getData(e.target.files[0], execute);
                                // Get the name for displaying 
                                setFileName(e.target.files[0].name);
                            }
                            }
                        />
                    </Grid>
                }
                close={close}
                execute={
                    () => {
                        close()
                    }
                }
                validate
                language={language}
            />
        </React.Fragment>
    )
}
