export const PATROL = {
    "en": "Patrol",
    "ro": "Patrulare",
}
export const ALARM = {
    "en": "Alarm",
    "ro": "Alarmă",
}
export const ALARM_PANIC = {
    "en": "Panic",
    "ro": "Panică",
}
export const ALARM_BURGLARY = {
    "en": "Burglary",
    "ro": "Efracție",
}
export const ALARM_TECHNICAL = {
    "en": "Technical",
    "ro": "Tehnic",
}
export const START_TIME = {
    "en": "Start Time",
    "ro": "Start",
}
export const END_TIME = {
    "en": "End Time",
    "ro": "Final",
}
export const INTERVENTION_TABLE = {
    "en": "Interventions",
    "ro": "Intervenții",
}
export const INTERVENTION = {
    "en": "Intervention",
    "ro": "Intervenție",
}
export const ACTIVE_INTERVENTION = {
    "en": "Active intervention",
    "ro": "Intervenții active"
}
export const INTERVENTION_HISTORY = {
    "en": "Intervention history",
    "ro": "Istoric intervenții"
}
export const ACTIVE_ALARMS = {
    "en": "Active alarms",
    "ro": "Alarme active"
}
export const ACTIVE_PATROL = {
    "en": "Active patrol",
    "ro": "Patrulări active"
}
export const SENT = {
    "en": "Sent",
    "ro": "Trimis"
}
export const INTERVENTION_TYPE = {
    'en': 'Intervention type',
    'ro': 'Tip intervenție'
};
export const INTERVENTION_DOCS = {
    'en': 'Intervention docs',
    'ro': 'Documente intervenție'
};
export const VIEW_SITE_INTERVENTION = {
    'en': 'View site interention',
    'ro': 'Vezi interventile obiectivului'
};
export const ADDITIONAL_INTERVENTIONS = {
    'en': 'Additional interventions',
    'ro': 'Interveții suplimnetare'
};
export const DONE_INTERVENTIONS = {
    'en': 'Done interventions',
    'ro': 'Intervenții realizate'
};
export const INTERVENTION_EXTRA_COST = {
    'en': 'Extra intervention cost',
    'ro': 'Costul suplimentare'
};
export const ADDITIONAL_INTERVENTIONS_SITUATION_FOR = {
    'en': 'Additional intervention situation for',
    'ro': 'Situație intervenții suplimentare pentru'
};
export const PERIOD = {
    'en': 'Period',
    'ro': 'Periodă'
};
export const MINIMUM_INTERVETION_TIME = {
    'en': 'Minimum intervention time',
    'ro': 'Timp maxim de interventie'
};
export const INTERVENTION_AREA_NAME = {
    en: 'Intervention area name',
    ro: 'Zonă alarmă'
};
export const INTERVENTION_AREA_CODE = {
    en: 'Intervention area code',
    ro: 'Număr zonă alarmă'
};
export const TECHNIC_MANAGEMENT = {
    en: 'Technic management',
    ro: 'Management activitate departament tehnic'
};
export const FREE = {
    en: "Free",
    ro: "Gratuita"
};

export const NON_FREE = {
    en: "Not free",
    ro: "Cu plata"
};
