// This will translate a mimetype to a string that the user can understand
export function getMimeType(mimeType) {
	switch (mimeType) {
		case "application/pdf":
			return "PDF";
		case "application/vnd.ms-excel" ||
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			return "EXCEL";
		case "application/msword" ||
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			return "WORD";
		case "text/html":
			return "HTML";
		case "image/bmp":
			return "IMAGE";
		case "image/gif":
			return "IMAGE";
		case "image/jpeg":
			return "IMAGE";
		case "image/png":
			return "IMAGE";
		case "audio/wav":
			return "AUDIO";
		case "audio/mpeg":
			return "AUDIO";
		case "audio/midi":
			return "AUDIO";
		case "audio/x-midi":
			return "AUDIO";
		case null:
			return "";
		default:
			return mimeType;
	}
}
