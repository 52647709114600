import React from 'react';
import { TextField, Grid, MenuItem, InputLabel, FormControl, Select, makeStyles, Divider, Hidden, Chip, Avatar, Typography } from '@material-ui/core';
import { Lock, AccountCircle } from '@material-ui/icons';
import Can from '../../../Components/Misc/Can';
import * as lang from '../../../Constants/strings';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const useStyles = makeStyles(theme => ({
    formControl: {
        // marginTop: theme.spacing(2),
    },
}));

export default function InterventionNewForm(props) {
    const classes = useStyles();
    const { addData, language, updateField, updateFieldRaw, baseData } = props;

    const [typeList, setTypeList] = React.useState([]);
    const [contractList, setContractList] = React.useState([]);
    const [areaList, setAreaList] = React.useState([]);
    const [siteAreaList, setSiteAreaList] = React.useState([]);
    const [systemLogin, setSystemLogin] = React.useState({});

    function fetchFunction(url, setResult) {
        fetch(`/api/${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setResult(response);
            })
            .catch(err => console.log(err))
    };

    React.useEffect(() => {
        if (typeList.length === 0)
            fetchFunction('inter_typelist', setTypeList);
    }, []);
    React.useEffect(() => {
        if (areaList.length === 0)
            fetchFunction('areas_active', setAreaList);
    }, []);

    React.useEffect(() => {
        if (contractList.length === 0)
            fetchFunction(`get_contracts_list_summary?id=${baseData.siteId}`, filterContracts);
    }, [baseData]);

    React.useEffect(() => {
        if (siteAreaList.length === 0)
            fetchFunction(`site_areas?type=0&id=${baseData.siteId}`, getSiteArea);
    }, [baseData]);

    React.useEffect(() => {
        fetchFunction(`get_system_info?id=${baseData.siteId}`, (res) => {
            console.log(res)
            setSystemLogin(res.data)
        })
    }, [baseData]);

    function getSiteArea(response) {
        setSiteAreaList(response.data)
    };

    // Filter the contracts
    function filterContracts(data) {
        let watchContract = []
        data.map(contract => {
            console.log(contract)
            if (contract.TYPE === "CONTRACT_TYPE_WATCH") {
                console.log(contract)
                watchContract.push(contract)
            }
        });
        setContractList(watchContract);
    }


    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} md={6} >
                <TextField
                    className={classes.formControl}
                    fullWidth
                    required
                    select
                    variant="outlined"
                    label={lang.TYPE[language]}
                    onChange={(e) => {
                        updateFieldRaw('TYPE', e.target.value);
                        updateFieldRaw('SUBTYPE', null);
                        if (e.target.value === 2) {
                            updateFieldRaw('START_TIME', null);
                            updateFieldRaw('END_TIME', null);
                        }
                        else {
                            updateFieldRaw('START_TIME', new Date());
                            updateFieldRaw('END_TIME', new Date());
                        }
                    }}
                    value={addData.TYPE ? addData.TYPE : ""}
                >
                    {typeList && typeList.filter(item => item.FK2PARENT === null).map((item, index) =>
                        <MenuItem key={index} value={item.OBJID}>{lang[item.TYPE][language]}</MenuItem>
                    )}
                </TextField>
            </Grid>
            {
                addData.TYPE && addData.TYPE === 2 &&
                <React.Fragment>
                    <Grid item xs={12} md={6} >
                        <TextField
                            className={classes.formControl}
                            fullWidth
                            required
                            select
                            variant="outlined"
                            label={lang.SUBTYPE[language]}
                            onChange={(e) => updateFieldRaw('SUBTYPE', e.target.value)}
                            value={addData.SUBTYPE ? addData.SUBTYPE : ""}
                        >
                            {typeList && typeList.filter(item => item.FK2PARENT === addData.TYPE).map((item, index) =>
                                <MenuItem key={index} value={item.OBJID}>{lang[item.TYPE][language]}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <TextField
                            className={classes.formControl}
                            fullWidth
                            required
                            select
                            variant="outlined"
                            label={lang.SITE_ALARM_AREA[language]}
                            onChange={(e) => updateFieldRaw('FK2SITE_AREA', e.target.value)}
                            value={addData.FK2SITE_AREA ? addData.FK2SITE_AREA : ""}
                        >
                            {siteAreaList.length > 0 && siteAreaList.map((item, index) =>
                                <MenuItem key={index} value={item.ID}>{`${item.AREA_NAME}`}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                </React.Fragment>
            }
            <Hidden smDown>
                <Grid item md={6} />
            </Hidden>
            {
                addData.TYPE && addData.TYPE === 1 &&
                <React.Fragment>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                    <Grid item xs={6} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                fullWidth
                                autoOk
                                // ampm={false}
                                inputVariant="outlined"
                                openTo="hours"
                                label={lang.START_TIME[language]}
                                value={addData.START_TIME ? addData.START_TIME : ""}
                                onChange={(value) => updateFieldRaw('START_TIME', value)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                fullWidth
                                autoOk
                                inputVariant="outlined"
                                openTo="hours"
                                label={lang.END_TIME[language]}
                                value={addData.END_TIME ? addData.END_TIME : ""}
                                onChange={(value) => updateFieldRaw('END_TIME', value)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                </React.Fragment>
            }
            <Grid item xs={12} >
                <TextField
                    className={classes.formControl}
                    fullWidth
                    select
                    variant="outlined"
                    label={lang.CONTRACT[language]}
                    onChange={(e) => updateFieldRaw('CONTRACT_OBJID', e.target.value)}
                    value={addData.CONTRACT_OBJID ? addData.CONTRACT_OBJID : ""}
                >
                    <MenuItem value={null}><em>niciunul</em></MenuItem>
                    {contractList && contractList.map((item, index) =>
                        <MenuItem key={index} value={item.OBJID}>{`#${item.CONTRACT_NO} - ${lang[item.SUB_TYPE][language]}`}</MenuItem>
                    )}
                </TextField>
            </Grid>
            <Grid item xs={12} >
                <TextField
                    className={classes.formControl}
                    fullWidth
                    required
                    select
                    variant="outlined"
                    label={lang.AREA[language]}
                    onChange={(e) => updateFieldRaw('AREA_CODE', e.target.value)}
                    value={addData.AREA_CODE ? addData.AREA_CODE : ""}
                >
                    {areaList && areaList.map((item, index) =>
                        <MenuItem key={index} value={item.AREA_CODE}>{item.AREA}</MenuItem>
                    )}
                </TextField>
            </Grid>
            <Grid item xs={12} >
                <TextField
                    multiline
                    className={classes.formControl}
                    fullWidth
                    variant="outlined"
                    label={lang.OBSERVATION[language]}
                    onChange={(e) => {
                        updateFieldRaw('NOTE', e.target.value);
                    }}
                    value={addData.NOTE ? addData.NOTE : ""}
                >
                </TextField>
            </Grid>
        </React.Fragment >
    );
}