import React, { useState, useEffect, useRef } from "react";
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SignaturePad from "react-signature-canvas";
import axios from "axios";
import CustomModal from "../../../../Components/Modals/CustomModal";

import RenderQuestion from "./RenderQuestionList";
import * as requestHeaders from "../../../../Constants/requestHeaders";

const useStyles = makeStyles(() => ({
  signature: {
    backgroundColor: "white",
    width: "100%",
    height: "50vh",
  },
}));

const CloseFields = (props) => {
  const classes = useStyles();
  const { maintenanceId, language, userId, refetchTaskData } = props;
  const [questionList, setQuestionList] = useState([]);
  const [obs, setObs] = useState("");
  const [serviceNumber, setServNumber] = useState("");
  const [revTeh, setRevTeh] = useState("");
  const [signModal, setSignModal] = useState(false);
  const [pers, setPers] = useState("");
  const [noService, setNoService] = useState("");
  const [serviceDate, setServiceDate] = useState(new Date());
  const [noOfPersonsInTeam, setNoOfPersonsInTeam] = useState("");
  const [dispatchers, setDispatchers] = useState([]);
  const [selectedDispatcher, setSelectedDispatcher] = useState();

  const getContractExtraServices = async () => {
    try {
      let response = await axios.get("/api/get_maintenance_close_questions", {
        ...requestHeaders.getGetHeader(),
        params: {
          id: maintenanceId,
        },
      });
      setQuestionList(response.data.questionList);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetch(`/api/dispatch_assignee_list`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setDispatchers(response);
      })
      .catch((err) => console.log(err));
  }, []);

  const closeMaintenance = async () => {
    try {
      await axios.post(
        "/api/close_maintenance",
        {
          maintenanceId: maintenanceId,
          userId: userId,
          questionList: questionList,
          serviceNumber: serviceNumber,
          observation: obs,
          contractType: revTeh,
          sign: imageURL,
          person: pers,
          noTeam: noOfPersonsInTeam,
          serviceDate: serviceDate,
          noService: noService,
          dispatcher: selectedDispatcher
        },
        requestHeaders.getPostHeader(),
      );
      await refetchTaskData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContractExtraServices();
  }, []);

  const setQuestionObject = (object, e, q, l) => {
    let copy = [...questionList];
    copy[l].questions[q][e] = object;
    setQuestionList(copy);
  };

  const sigCanvas = React.useRef({});
  const [imageURL, setImageURL] = React.useState(null);

  const saveSign = () =>
    setImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/svg+xml")
    );

  const clear = () => sigCanvas.current.clear();

  return (
    <Grid item xs={12}>
      <CustomModal
        fullWidth
        open={signModal}
        content={
          <Grid item xs={12}>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: classes.signature,
              }}
            />
          </Grid>
        }
        execute={() => {
          saveSign();
          clear();
          setSignModal(false);
        }}
        close={() => {
          clear();
          setSignModal(false);
        }}
        language={language}
        validate={true}
      />
      {questionList.map((questionObject, index) => {
        return (
          <RenderQuestion
            key={`render_question_parent_${index}`}
            questions={questionObject}
            updateFields={(value, e, q) =>
              setQuestionObject(value, e, q, index)
            }
          />
        );
      })}
      <form onSubmit={closeMaintenance}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            style={{ width: "100%" }}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            id="date-picker-inline-1"
            label={"Dată jurnal service"}
            value={serviceDate}
            onChange={date => setServiceDate(date)}
          />
        </MuiPickersUtilsProvider>
        <TextField
          required
          select
          variant="outlined"
          label={"Dispecer de serviciu"}
          onChange={(e, newValue) => setSelectedDispatcher(newValue.props.value)}
          value={selectedDispatcher}
          fullWidth
          style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          {dispatchers.map(item => {
            return <MenuItem key={item.OBJID} value={item}>{item.USERNAME}</MenuItem>
          })}
        </TextField>
        <TextField
          placeholder="Nr. Jurnal Service"
          required
          value={serviceNumber}
          onChange={(e) => setServNumber(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginTop: "5px", marginBottom: "5px" }}
        />
        <TextField
          placeholder="Poziție Jurnal Service"
          required
          value={noService}
          onChange={(e) => setNoService(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginTop: "5px", marginBottom: "5px" }}
        />
        <TextField
          required
          placeholder="Revizie tehnica periodica (S1, S2, L1 ....)"
          value={revTeh}
          onChange={(e) => setRevTeh(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginTop: "5px", marginBottom: "5px" }}
        />
        <TextField
          required
          placeholder="Persona prezentă beneficiar"
          value={pers}
          onChange={(e) => setPers(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginTop: "5px", marginBottom: "5px" }}
        />
        <TextField
          required
          placeholder="Număr persoane echipaj"
          value={noOfPersonsInTeam}
          onChange={(e) => setNoOfPersonsInTeam(e.target.value)}
          variant="outlined"
          type="number"
          fullWidth
          style={{ marginTop: "5px", marginBottom: "5px" }}
        />
        <TextField
          placeholder="Alte mențiuni"
          value={obs}
          onChange={(e) => setObs(e.target.value)}
          variant="outlined"
          fullWidth
          multiline
          style={{ marginTop: "5px", marginBottom: "5px" }}
        />
        <input
          readOnly
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, width: 0 }}
          value={imageURL ? imageURL : undefined}
          required
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            fullWidth
            disabled={imageURL !== null}
            variant="contained"
            color="primary"
            onClick={() => setSignModal(true)}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            Semnătură client
        </Button>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            fullWidth
            disabled={imageURL === null}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Finalizează
        </Button>
        </div>
      </form>
    </Grid>
  );
};

export default CloseFields;
