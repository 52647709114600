import axios from "axios";
import * as requestHeaders from "../../../Constants/requestHeaders";

export const createMaintenance = async ({ userId, data }) => {
    try {
        const response = axios.post("/api/create_maintenance", data, {
            ...requestHeaders.getPostHeader(),
            params: {
                id: userId
            }
        });

        if (response.status === 200) {
            return true;
        } else {
            return false;
        };
    } catch (error) {
        console.log(error);
    }
};

export const getSiteMaintenance = async ({ siteId, active = false }) => {
    try {
        const response = await axios.get("/api/get_site_maintenances", {
            ...requestHeaders.getGetHeader(),
            params: {
                id: siteId,
                active: active
            }
        });

        if (response.status === 200 || response.status === 304) {
            return response.data.maintenanceList;
        } else {
            return [];
        };
    } catch (error) {
        console.log(error);
    }
};

export const getMaintenanceData = async ({ maintenanceId, active = false }) => {
    try {
        const response = axios.get("/api/get_maintenance_data", {
            ...requestHeaders.getPostHeader(),
            params: {
                id: maintenanceId,
                active: active
            }
        });

        if (response.status === 200) {
            return response.data.maintenanceData;
        } else {
            return {};
        };
    } catch (error) {
        console.log(error);
    }
};
