import React, { useState } from "react";
import {
    Grid,
    TextField,
} from "@material-ui/core";

import axios from "axios";
import CustomModal from "../../../../Components/Modals/CustomModal";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import * as lang from "../../../../Constants/strings";

const ResetAlocationModal = (props) => {
    const {
        open,
        close,
        language,
        maintenanceId,
        userId,
        refetch,
        gps
    } = props;

    const [note, setNote] = useState("");

    const assignMaintenanceToMe = async () => {
        try {
            let res = await axios.post("/api/reste_maintenance_alocation", {
                id: maintenanceId,
                userId: userId,
                note: note,
                GPS: gps
            }, {
                ...requestHeaders.getPostHeader(),
            });
            refetch();
            close();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <CustomModal
            open={open}
            close={close}
            fullWidth
            title={`Resetare alocare`}
            content={
                <Grid container spacing={2}>
                    <TextField
                        label={lang.OBSERVATION[language]}
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            }
            execute={() => assignMaintenanceToMe()}
            validate={true}
            language={language}
        />
    )
};

export default ResetAlocationModal;