import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actions";

import {
    CssBaseline,
    makeStyles
} from "@material-ui/core";
import AccountingView from "./AccountingView";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        margin: theme.spacing(1)
    }
}))
const AccountingRouter = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AccountingView />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingRouter);