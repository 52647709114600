import React from 'react';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Input,
    Checkbox,
    Typography,
    FormControlLabel
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import * as lang from '../../../../Constants/strings';
import * as reqHeaders from '../../../../Constants/requestHeaders';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import axios from 'axios';
import {checkSpecialChar} from '../../../../Functions/stringManipulation';
import {getGetHeader} from "../../../../Constants/requestHeaders";


const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ExecNewForm(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(35);
    const {addData, language, error, updateError, updateFieldRaw} = props;
    const [errorText, setError] = React.useState();
    const [typeList, setTypeList] = React.useState([]);
    const [externalCompanies, setExternalCompanies] = React.useState([]);

    const fetchExternalCompanies = async () => {
        try {
            const res = await axios.get("/api/external/companies", reqHeaders.getGetHeader());
            setExternalCompanies(res.data.data);
        } catch (e) {
            console.error(e);
        }
    }

    function checkNameAvailability(name) {
        if (name)
            axios.get(`/api/check_contractor?name=${name}`)
                .then(res => {
                    updateError(parseInt(res.data, 10) > 0);
                    if (parseInt(res.data, 10) > 0)
                        setError("VALUE_EXISTS");
                    if (checkSpecialChar(name.trim(), "*|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        updateError(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });
        else {
            setError("EMPTY_FIELD");
            updateError(true);
        }
    }

    function focus() {
        updateError(null);
        setError(null);
    }

    function getLabel(id) {
        let subtype = parseInt(id.split('-')[0], 10);
        let code = parseInt(id.split('-')[1], 10);

        return lang[typeList.filter(type => type.SUB_TYPE === subtype && type.CODE === code)[0].DESCRIPTION_TEXT][language];
    }

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        if (typeList.length === 0) {
            fetch(`/api/contract_types`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setTypeList(response);
                })
                .catch(err => console.log(err))
        }
        fetchExternalCompanies().then(() => {});
    }, []);

    React.useEffect(() => {
        setError(null);
    }, [addData.NAME]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{position: 'relative'}}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="NAME"
                        label={lang.EXEC_NAME[language]}
                        value={addData.NAME ? addData.NAME : ""}
                        onChange={(e) => props.updateField(e, 50)}
                        onFocus={focus}
                        error={error}
                        helperText={errorText ? lang[errorText][language] : null}
                        onBlur={() => checkNameAvailability(addData.NAME)}
                    />
                    {error === true
                        ?
                        <ErrorRoundedIcon className={classes.clear} color="secondary"/>
                        :
                        error === false
                            ?
                            <CheckCircleRoundedIcon className={classes.clear}/>
                            :
                            null
                    }
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    // required
                    fullWidth
                    variant="outlined"
                    name="PHONE"
                    label={lang.PHONE[language]}
                    value={addData.PHONE ? addData.PHONE : ""}
                    type="number"
                    onChange={(e) => props.updateField(e, 20)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    // required
                    fullWidth
                    variant="outlined"
                    name="EMAIL"
                    label={lang.EMAIL[language]}
                    value={addData.EMAIL ? addData.EMAIL : ""}
                    onChange={(e) => props.updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    // required
                    fullWidth
                    variant="outlined"
                    name="ADDRESS"
                    label={lang.ADDRESS[language]}
                    value={addData.ADDRESS ? addData.ADDRESS : ""}
                    onChange={(e) => props.updateField(e, 200)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    // required
                    fullWidth
                    variant="outlined"
                    name="NR_REG"
                    label={lang.NR_REG[language]}
                    value={addData.NR_REG ? addData.NR_REG : ""}
                    onChange={(e) => props.updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    // required
                    fullWidth
                    variant="outlined"
                    name="CUI"
                    label={lang.CUI[language]}
                    value={addData.CUI ? addData.CUI : ""}
                    onChange={(e) => props.updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel ref={inputLabel} id="demo-mutiple-chip-label">{lang.OPTIONS[language]}</InputLabel>
                    <Select
                        multiple
                        name="OPT"
                        labelWidth={labelWidth}
                        value={addData.OPT ? addData.OPT : []}
                        onChange={(e) => props.updateField(e)}
                        input={<Input id="select-multiple-chip"/>}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip key={value} label={getLabel(value)} className={classes.chip}/>
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {typeList.length > 0 && typeList.filter(type => type.TYPE === 2).map(type =>
                            <MenuItem key={type.ID} value={type.SUB_TYPE + "-" + type.CODE}>
                                {lang[type.DESCRIPTION_TEXT][language]}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel ref={inputLabel} id="demo-mutiple-chip-label">{"Selecteaza companie platforma inventar"}</InputLabel>
                    <Select
                        fullWidth
                        name="EXTERNAL_ID"
                        labelWidth={labelWidth}
                        placeHolder={"Selecteaza companie platforma inventar"}
                        value={addData.EXTERNAL_ID ? addData.EXTERNAL_ID : null}
                        onChange={(e) => props.updateField(e)}
                    >
                        {
                            externalCompanies.map((c) =>
                                <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={addData.IS_DEFAULT}
                            onChange={() => updateFieldRaw('IS_DEFAULT', !addData.IS_DEFAULT)}
                        />
                    }
                    label={
                        <Typography variant='body1'>{lang.IS_DEFAULT[language]}</Typography>
                    }
                />
            </Grid>
        </Grid>
    );
}