import React from 'react';
import {
    TextField,
    Grid,
    Typography,
    makeStyles,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Divider
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as lang from '../../../Constants/strings';
import AddressNewForm from '../CustomerViewComponents/AddressNewForm';
import SiteContactNewForm from './SiteContactNewForm';
import { ErrorRounded, CheckCircleRounded } from '@material-ui/icons';
import { checkSpecialChar } from '../../../Functions/stringManipulation';
import axios from 'axios';
import SiteCentralForm from './SiteCentralForm';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    title: {
        margin: theme.spacing(2),
    },
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));

export default function SiteNewForm(props) {
    const classes = useStyles();
    const { addData, language, updateField, updateFieldRaw, edit, error, updateError, siteAreaType } = props;
    const [errorText, setError] = React.useState();
    const [siteType, setSiteType] = React.useState([]);
    const [contractors, setContractors] = React.useState([]);

    React.useEffect(() => {
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        }
        fetch(`/api/get_site_type_list`, options)
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    console.log(response.error)
                    setSiteType([])
                } else {
                    setSiteType(response.data)
                }
            })
    }, []);

    React.useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('/api/executors_active');
                setContractors(response.data);
            } catch (error) {
                console.error(error);
            }
        })()
    }, []);

    function checkNameAvailability(name) {
        if (name.trim())
            axios.get(`/api/check_site?name=${name.trim()}${edit ? `&objid=${addData.OBJID}` : ""}`)
                .then(res => {
                    updateError(parseInt(res.data, 10) > 0);
                    if (parseInt(res.data, 10) > 0)
                        setError("VALUE_EXISTS");
                    if (checkSpecialChar(name.trim(), "*|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        updateError(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });
        else {
            setError("EMPTY_FIELD");
            updateError(true);
        }
    }
    function focus() {
        updateError(null);
        setError(null);
    }
    React.useEffect(() => {
        setError(null);
    }, [addData.SITE_CODE]);

    return (
        <React.Fragment>
            <Grid item xs={12} md={6}>
                <div style={{ position: 'relative' }}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="SITE_CODE"
                        label={lang.SITE_CODE[language]}
                        value={addData.SITE_CODE ? addData.SITE_CODE : ""}
                        onChange={(e) => updateField(e, 50)}
                        onFocus={focus}
                        error={error}
                        helperText={errorText ? lang[errorText][language] : null}
                        onBlur={() => checkNameAvailability(addData.SITE_CODE)}
                    />
                    {error === true
                        ?
                        <ErrorRounded className={classes.clear} color="secondary" />
                        :
                        error === false
                            ?
                            <CheckCircleRounded className={classes.clear} />
                            :
                            null
                    }
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="SITE_NAME"
                    label={lang.SITE_NAME[language]}
                    value={addData.SITE_NAME ? addData.SITE_NAME : ""}
                    onChange={(e) => updateField(e, 250)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="PHONE"
                    label={lang.PHONE[language]}
                    value={addData.PHONE ? addData.PHONE : ""}
                    onChange={(e) => updateField(e, 20)}
                    type="number"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="EMAIL"
                    label={lang.EMAIL[language]}
                    value={addData.EMAIL ? addData.EMAIL : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    name="OBS"
                    label={lang.OBSERVATION[language]}
                    value={addData.OBS ? addData.OBS : ""}
                    onChange={(e) => updateField(e, 250)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl
                    required
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel >{lang.SITE_TYPE[language]}</InputLabel>
                    <Select
                        name="FK2SITE_TYPE"
                        required
                        value={addData.FK2SITE_TYPE ? addData.FK2SITE_TYPE : ""}
                        onChange={(e) => updateFieldRaw('FK2SITE_TYPE', e.target.value)}
                    >
                        {
                            siteType.map((site, index) => {
                                return < MenuItem key={index} value={site.ID}>{site.NAME}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>


            </Grid>
            <Grid container component='main' justify='center' alignItems='center'>
                {edit
                    ?
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addData.EDIT_CONTACT === true}
                                onChange={() => updateFieldRaw('EDIT_CONTACT', !addData.EDIT_CONTACT)}
                            />
                        }
                        label={
                            <Typography variant='h6'>{lang.CONTACT_PERSON[language]}</Typography>
                        }
                    />
                    :
                    <Typography className={classes.title} variant='h6'>{lang.CONTACT_PERSON[language]}</Typography>
                }
            </Grid>
            <SiteContactNewForm disableEdit={addData.EDIT_CONTACT === false} addData={addData} language={language} updateField={updateField} />

            <Grid container component='main' justify='center' alignItems='center'>
                {edit
                    ?
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addData.EDIT_ADDRESS === true}
                                onChange={() => updateFieldRaw('EDIT_ADDRESS', !addData.EDIT_ADDRESS)}
                            />
                        }
                        label={
                            <Typography variant='h6'>{lang.ADDRESS[language]}</Typography>
                        }
                    />
                    :
                    <Typography className={classes.title} variant='h6'>{lang.ADDRESS[language]}</Typography>
                }
            </Grid>
            <AddressNewForm disableEdit={addData.EDIT_ADDRESS === false} edit={edit} gps addData={addData} language={language} updateFieldRaw={updateFieldRaw} updateField={updateField} />
            <Grid item xs={12} md={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={addData.AREA_TYPE === true}
                            onChange={() => updateFieldRaw('AREA_TYPE', !addData.AREA_TYPE)}
                        />
                    }
                    label={
                        <Typography variant='h6'>URBAN</Typography>
                    }
                />
            </Grid>
            <Grid container component='main' justify='center' alignItems='center'>
                {edit
                    ?
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addData.EDIT_CENTRAL === true}
                                onChange={() => updateFieldRaw('EDIT_CENTRAL', !addData.EDIT_CENTRAL)}
                            />
                        }
                        label={
                            <Typography variant='h6'>{lang.ADDITIONAL_INFO[language]}</Typography>
                        }
                    />
                    :
                    <Typography className={classes.title} variant='h6'>{lang.ADDITIONAL_INFO[language]}</Typography>
                }
            </Grid>

            <SiteCentralForm
                disableEdit={edit ? addData.EDIT_CENTRAL !== true : false}
                edit={edit}
                addData={addData}
                language={language}
                updateFieldRaw={updateFieldRaw}
                updateField={updateField}
            />

            <Grid container component='main' justify='center' alignItems='center'>
                <Typography className={classes.title} variant="h6">{lang.PERMIT[language]}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="permit_nr"
                    label={lang.PERMIT_NR[language]}
                    value={addData.permit_nr ? addData.permit_nr : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="permit_date"
                        label={lang.PERMIT_DATE[props.language]}
                        InputProps={{ readOnly: true }}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change permit_date",
                        }}
                        value={
                            addData.permit_date && addData.permit_date !== null
                                ? addData.permit_date
                                : null
                        }
                        onChange={(value) => updateField({ target: { name: "permit_date", value: value } }, 100)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid container component='main' justify='center' alignItems='center'>
                <Typography className={classes.title} variant="h6">{lang.PROJECT[language]}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="project_nr"
                    label={lang.PROJECT_NUMBER[language]}
                    value={addData.project_nr ? addData.project_nr : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="project_date"
                        label={lang.PROJECT_DATE[props.language]}
                        InputProps={{ readOnly: true }}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change permit_date",
                        }}
                        value={
                            addData.project_date && addData.project_date !== null
                                ? addData.project_date
                                : null
                        }
                        onChange={(value) => updateField({ target: { name: "project_date", value: value } }, 100)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    freeSolo
                    variant="outlined"
                    name="project_contractor"
                    label={lang.PROJECT_CONTRACTOR[language]}
                    value={addData.project_contractor ? addData.project_contractor : ''}
                    onChange={(e, newValue) => {
                        if (newValue.NAME) {
                            updateField({ target: { name: 'project_contractor', value: newValue.NAME } }, 50)
                        } else {
                            updateField({ target: { name: 'project_contractor', value: e.target.value } }, 50)
                        }
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" name="project_contractor" />}
                    options={contractors}
                    getOptionLabel={o => typeof (o) === 'string' ? o : o.NAME}
                />
            </Grid>
            <Grid container component='main' justify='center' alignItems='center'>
                <Typography className={classes.title} variant="h6">{lang.EVALUATION[language]}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="evaluation_no"
                    label={lang.EVALUATION_NUMBER[language]}
                    value={addData.evaluation_no ? addData.evaluation_no : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="evaluation_creation_date"
                        label={lang.EVALUATION_CREATION_DATE[props.language]}
                        InputProps={{ readOnly: true }}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change permit_date",
                        }}
                        value={
                            addData.evaluation_creation_date && addData.evaluation_creation_date !== null
                                ? addData.evaluation_creation_date
                                : null
                        }
                        onChange={(value) => updateField({ target: { name: "evaluation_creation_date", value: value } }, 100)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="evaluation_expiration_date"
                        label={lang.EVALUATION_EXPIRATION_DATE[props.language]}
                        InputProps={{ readOnly: true }}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change permit_date",
                        }}
                        value={
                            addData.evaluation_expiration_date && addData.evaluation_expiration_date !== null
                                ? addData.evaluation_expiration_date
                                : null
                        }
                        onChange={(value) => updateField({ target: { name: "evaluation_expiration_date", value: value } }, 100)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    freeSolo
                    required
                    variant="outlined"
                    name="evaluation_contractor"
                    label={lang.EVALUATION_CONTRACTOR[language]}
                    value={addData.evaluation_contractor ? addData.evaluation_contractor : ''}
                    onChange={(e, newValue) => {
                        if (newValue && newValue.NAME) {
                            updateField({ target: { name: 'evaluation_contractor', value: newValue.NAME } }, 50)
                        } else if (!newValue) {
                            updateField({ target: { name: 'evaluation_contractor', value: '' } }, 50)
                        } else {
                            updateField({ target: { name: 'evaluation_contractor', value: e.target.value } }, 50)
                        }
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" name="evaluation_contractor" />}
                    options={contractors}
                    getOptionLabel={o => typeof (o) === 'string' ? o : o.NAME}
                />
            </Grid>
        </React.Fragment>
    );
}
