import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import moment from "moment";

import { task_bill_history_site } from "../../../Components/Misc/Tables/TableHeaders";
import { checkPermissions } from "../../../Functions/permissionHide";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import BillValidationModal from "../../Tasks/Modals/BillValidationModal";
import SortBar from '../../BillingHistory/Componets/SortBar';
import * as requestHeaders from "../../../Constants/requestHeaders"
import * as lang from "../../../Constants/strings";
import { formatDateFromDb } from "../../../Functions/dateFunctions";
import { getBillPayStatus } from "../../../Functions/getIcon";
import ProjectCashingValidationModal from "../../Projects/CashingValidationModal";
import RiscEvaluationCashingValidationModal from "../../RiscEvaluation/CashingValidationModal";

const useStyles = makeStyles(theme => ({
	margin: {
		marginTop: theme.spacing(2)
	}
}));

export default function SiteTaskBillHistory(props) {
	const { language, tiny, loading } = props;
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [data, setData] = React.useState([]);
	const [selectedId, setSelectedId] = React.useState(null);
	const [filter, setFilter] = React.useState();
	const classes = useStyles();

	const [billModal, setBillModal] = React.useState(false);
	const [projectBillModal, setProjectBillModal] = React.useState(false);
	const [evaluationBillModal, setEvaluationBillModal] = React.useState(false);

	React.useEffect(() => {
		getTaskBillHistory({
			dateInterval: {
				dateFrom: formatDateFromDb(new Date(), 15),
				dateTo: formatDateFromDb(new Date(), 15)
			},
			billIntervalFilter: {
				billNoTo: "",
				billNoFrom: "",
				billValueTo: "",
				billValueFrom: ""
			},
			recipeIntervalFilter: {
				recipeDateTo: "",
				recipeDateFrom: "",
				recipeNoFrom: "",
				recipeNoTo: ""
			},
			contractor: "",
			billType: "",
			filterByBillNo: "",
			filterByBillValue: "",
			filterByDate: false,
			filterByRecipeNo: false,
			filterByRecipeDate: false,
			filterByContractor: false,
			filterByType: false
		});
	}, []);

	const getTaskBillHistory = async (filters) => {
		const response = await axios.get("/api/getBillingHistory", {
			...requestHeaders.getGetHeader(),
			params: {
				...filters,
				siteId: props.siteId
			}
		});

		if (response.data.length > 0) {
			let billList = response.data.map(bill => {
				bill.BILLING_TYPE = bill.BILLING_TYPE !== null ? lang[bill.BILLING_TYPE][language] : bill.BILLING_TYPE;
				bill.PAYMENT_TYPE = bill.PAYMENT_TYPE !== null ? lang[bill.PAYMENT_TYPE][language] : bill.PAYMENT_TYPE;
				bill.PAY = bill.FK2BILL_TYPE !== 1 ? (bill.IS_PARTIAL_PAYED === 1 ? "Tranșe multiple" : "O singură tranșă") : "";
				bill.COMPLETED_PAYED = getBillPayStatus(bill);
				bill.BILL_DATE = bill.BILL_DATE !== null ? moment(bill.BILL_DATE).format("DD-MM-YYYY") : bill.BILL_DATE;
				bill.RECIPE_DATE = bill.RECIPE_DATE !== null ? moment(bill.RECIPE_DATE).format("DD-MM-YYYY") : bill.RECIPE_DATE;
				bill.CREATE_IN = bill.CREATE_IN !== null ? moment(bill.CREATE_IN).format("DD-MM-YYYY") : bill.CREATE_IN;
				bill.CLOSE_IN = bill.CLOSE_IN !== null ? moment(bill.CLOSE_IN).format("DD-MM-YYYY") : bill.CLOSE_IN;
				bill.o_TYPE = bill.TYPE;
				bill.TYPE = lang[bill.TYPE][language];
				return bill
			})
			setData(billList);
		} else {
			setData([]);
		}

	};

	const updateBill = async (data) => {
		const response = await axios.patch("/api/update_task_bill", data, {
			...requestHeaders.getPatchHeader(),
			params: {
				billId: selectedId
			},
		});
		if (response.status === 200 || response.status === 200 || response.status === 304) {
			await getTaskBillHistory(filter);
			setSelectedId(null);
		} else {
			console.error(response.data.message);
		}
	}

	const getBillById = async (id, type) => {
		setSelectedId(id);
		switch (type) {
			case "INSTALLATION":
			case "TASK":
				setBillModal(true);
				break;
			case "PROJECT":
				setProjectBillModal(true);
				break;
			case "EVALUATION":
				setEvaluationBillModal(true);
				break;
			default: break;
		}
	};

	return (
		<React.Fragment>
			{loading ? (
				<SkeletonSiteBillTable {...props} />
			) : (
				<Grid
					item
					xs={12}
					md={12}
					container
				>
					<BillValidationModal
						language={language}
						open={billModal}
						reopen={() => setBillModal(true)}
						close={() => setBillModal(false)}
						billId={selectedId}
						lockFields={!checkPermissions(props.userPermissions, { parent: "TASKS", name: "EDIT_TASK_BILL" })}
						lockAllFields={!checkPermissions(props.userPermissions, { parent: "TASKS", name: "EDIT_TASK_BILL" })}
						refeatchTaskData={() => { }}
						execute={(data) => updateBill(data)}
					/>
					<ProjectCashingValidationModal
						open={projectBillModal}
						reopen={() => setProjectBillModal(true)}
						language={language}
						billId={selectedId}
						lockAllFields={!checkPermissions(props.userPermissions, { parent: "TASKS", name: "EDIT_TASK_BILL" })}
						lockFields={!checkPermissions(props.userPermissions, { parent: "TASKS", name: "EDIT_TASK_BILL" })}
						refeatchTaskData={() => { }}
						close={() => setProjectBillModal(false)}
					/>
					<RiscEvaluationCashingValidationModal
						open={evaluationBillModal}
						reopen={() => setProjectBillModal(true)}
						language={language}
						billId={selectedId}
						lockAllFields={!checkPermissions(props.userPermissions, { parent: "TASKS", name: "EDIT_TASK_BILL" })}
						lockFields={!checkPermissions(props.userPermissions, { parent: "TASKS", name: "EDIT_TASK_BILL" })}
						refeatchTaskData={() => { }}
						close={() => setEvaluationBillModal(false)}
					/>
					<Grid item xs={12}>
						<SortBar
							language={language}
							filterData={filter => {
								getTaskBillHistory(filter);
								setFilter(filter);
							}}
							hideType={true}
						/>
					</Grid>
					<div className={classes.margin} />
					<Grid item xs={12} className={classes.margin}>
						<MaterialTable
							tiny={tiny}
							title={lang.BILL_VALIDATION[language]}
							language={language}
							data={data}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={(value) => setRowsPerPage(value)}
							header={task_bill_history_site(language)}
							dense={false}
							openRowModal={(id, data) => getBillById(id, data.o_TYPE)}
							clickLabel="OBJID"
							hideAddBtn={true}
						/>
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	);
}

export function SkeletonSiteBillTable() {
	return (
		<Grid item xs={12} md={12} container direction="column">
			<Skeleton component={Paper} height="45vh">
				<Grid
					container
					justify="center"
					alignItems="center"
					direction="column"
				>
					<Skeleton width="80%" />
					<Skeleton width="80%" />
					<Skeleton width="80%" />
					<Skeleton width="80%" />
				</Grid>
			</Skeleton>
		</Grid>
	);
}
