import React, { useState, useRef } from "react";
import {
  Grid,
  Button,
} from "@material-ui/core";
import imageCompression from "browser-image-compression";
import * as lang from "../../../../Constants/strings";

const LoadPvImage = (props) => {
  const { language, pvImage, onImageLoad } = props;
  const canvasRef = useRef();
  const imageUploaderRef = React.createRef();

  const compressionOption = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  return (
    <>
      <Grid
        container
        justify="space-evenly"
        alignItems="center"
        alignContent="space-around"
      >
        <Grid item>
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => imageUploaderRef.current.click()}
          >
            {lang.LOAD_IMAGE[language]}
          </Button>
          <input
            readOnly
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0, width: 0 }}
            value={pvImage ? pvImage : null}
            required
          />
        </Grid>
      </Grid>
      <form method="post" enctype="multipart/form-data">
        <input
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          ref={imageUploaderRef}
          name="image"
          tabIndex={-1}
          onChange={async (e) => {
            onImageLoad(null);
            e.persist();
            if (e.target.files.length > 0) {
              let compressImg;
              let img = new Image();
              img.crossOrigin = "Anonymous";

              const canvas = canvasRef.current;
              const ctx = canvas.getContext("2d");
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, canvas.width, canvas.height);

              try {
                compressImg = await imageCompression(
                  e.target.files[0],
                  compressionOption
                );
              } catch (error) {
                console.log("Can not compress the image: ", error);
              }

              let url = URL.createObjectURL(
                compressImg !== undefined ? compressImg : e.target.files[0]
              );

              img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
              };

              img.src = url;
              await onImageLoad(
                compressImg !== undefined ? compressImg : e.target.files[0]
              );
            }
          }}
        />
      </form>
      <Grid item xs={12}>
        <canvas
          style={{ maxWidth: 550, background: "none" }}
          ref={this.canvasRef}
        />
      </Grid>
    </>
  );
};

export default LoadPvImage;
