import React from 'react';
import { Grid, Avatar, Typography, Divider, Box, Button } from '@material-ui/core';

import getAvatarUri from '../../../../Functions/getDataUriAvatar';
import { formatDateFromDb } from '../../../../Functions/dateFunctions';
import { openPageNewTab } from '../../../../Functions/linkFunctions';
import * as lang from '../../../../Constants/strings';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { getMimeType } from '../../../../Functions/mimeType';

const Comment = (props) => {
    const { data, language, classes } = props;

    const downloadPdfFile = async (name) => {
        try {
            fetch(`/api/download_pdf?file=${name}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => {
                    if (response.status >= 400) {
                        console.log("Bad response from server");
                    }
                    return response.blob();
                })
                .then(response => {
                    try {
                        const file = new Blob(
                            [response],
                            { type: 'application/pdf;' });
                        const fileURL = URL.createObjectURL(file);
                        var link = document.createElement('a');
                        link.href = fileURL;
                        link.download = `pv.pdf`
                        link.click()
                    } catch (e) {
                        console.log('err')
                    }
                })
                .catch(err => console.log(err))
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid container direction="row">
                <Grid container item xs={1} justify="center">
                    <Avatar onClick={() => openPageNewTab(`/profile?id=${data.CHG_USER}`)} style={{ cursor: "pointer" }} alt={data.AVATAR_ID.toString()} src={getAvatarUri(data.AVATAR_ID)} className={classes.avatar} />
                </Grid>
                <Grid item xs={11} container direction="column">
                    <Grid item xs={'auto'}>
                        <Grid container direction="row">
                            <Box style={{ cursor: "pointer" }} onClick={() => openPageNewTab(`/profile?id=${data.CHG_USER}`)}>
                                <Typography variant="body1" className={classes.title}>
                                    <strong>
                                        {data.USERNAME}
                                    </strong>
                                </Typography>
                            </Box>
                            <Typography variant="caption" className={classes.timestamp}>
                                {formatDateFromDb(data.CREATE_TIME, 13)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {data.CHG_TYPE && data.CHG_TYPE === "ACTION" &&
                        <Grid item xs={'auto'}>
                            <Typography variant="body2" className={classes.commentAuto} gutterBottom>
                                {`${data.CHG_FROM === null ? "-" : lang[data.CHG_FROM][language]} : ${data.CHG_TO === null ? "-" : lang[data.CHG_TO][language]}`}
                            </Typography>
                        </Grid>
                    }
                    {data.NOTE && data.NOTE.length > 0 &&
                        <Grid item xs={'auto'}>
                            <Typography variant="body2" className={classes.comment} gutterBottom>
                                {data.NOTE}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={'auto'} className={classes.comment}>
                        {data.PDF_NAME && data.PDF_NAME.length > 0 &&
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.commentButton}
                                onClick={() => {
                                    if (data.PDF_NAME.split('_')[0] === 'PV') {
                                        downloadPdfFile(data.PDF_NAME);
                                    } else {
                                        props.downloadFile(data.PDF_NAME);
                                    }
                                }}
                            >
                                <PictureAsPdfIcon fontSize="small" className={classes.leftIcon} />
                                {`${data.PDF_NAME} ${getMimeType(data.MIME_TYPE)}`}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Comment;