import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MainList, { AdminList } from '../../../Constants/drawerList';
import { gv } from '../../../static/gitInfo';
import { Typography, Link, Box } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    margin: {
        margin: theme.spacing(1)
    },
    itemsContainer: {
        height: '85vh',
        overflowY: 'scroll',
        '&::-ms-overflow-style': 'none',
        '&::scrollbar-width': 'none'
    }
}));

function Copyright() {
    return (
        <React.Fragment>
            <Typography variant="body2" color="textPrimary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.facebook.com/pages/category/Security-Guard-Service/RPG-Suceava-236795097260877/">
                    RPG
                </Link>
                {' '}{new Date().getFullYear()}{'.'}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Powered by '}{' '}
                <Link color="inherit" href="raisissoftware.ro">
                    Raisis Software
                </Link>
            </Typography>
        </React.Fragment>
    );
}


export default function TemporaryDrawer(props) {
    const classes = useStyles();

    const sideList = (admin) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={props.toggleDrawer}
            onKeyDown={props.toggleDrawer}
        >
            <List>
                {admin ?
                    <AdminList language={props.language} />
                    :
                    <MainList language={props.language} />
                }
            </List>
            <Divider />
        </div>
    );

    return (
        <div>
            <Drawer open={props.open} onClose={props.toggleDrawer}>
                <div className={classes.list} style={{ height: "65px", display: "flex", alignItems: 'center', justifyContent: 'center', }}>
                    <Typography variant="caption" align="center">
                        <RouterLink
                            active="true"
                            to={'/changelog'}
                            style={{ textDecoration: "none", color: "inherit" }}
                            onClick={() => props.toggleDrawer()}
                        >
                            Versiunea: {gv}
                        </RouterLink>
                    </Typography>
                </div>
                <Divider />
                <div className={classes.itemsContainer}>
                    {sideList(props.admin)}
                </div>
                <Box
                    bottom={10}
                    position="absolute"
                    left={30}
                >
                    <Copyright />
                </Box>
            </Drawer>
        </div>
    );
}
