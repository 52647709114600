import React from "react";
import * as lang from "../../../../Constants/strings";
import CustomModal from "../../../../Components/Modals/CustomModal";
import {
	useMediaQuery,
	useTheme,
	Grid,
	makeStyles,
	Paper,
	LinearProgress,
} from "@material-ui/core";
import CommentList from "../CommentList";
import TaskInfo from "../TaskInfo";
import CustTaskDetails from "../../../Site/SiteSingleViewComponents/CusTaskDetails";
import SiteInfo from "../SiteInfo";
import InterventionButtons from "../InterventionButtons";
import InterventionAction from "../InterventionAction";
import UploadFiles from "../../../../Components/Modals/UploadFiles";
import ActivityModalIndicator from "./ActivityModalIndicator";
import SiteImageModal from "../../../../Components/Modals/SiteImageModal";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		width: "100%",
	},
	avatar: {
		width: 50,
		height: 50,
	},
	title: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	comment: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	commentButton: {
		margin: theme.spacing(1),
	},
	commentAuto: {
		color: "gray",
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	timestamp: {
		marginTop: theme.spacing(1.5),
	},
	divider: {
		margin: theme.spacing(2),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	buttonGroup: {
		marginTop: theme.spacing(1),
	},
	iconRight: {
		marginLeft: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	signature: {
		backgroundColor: "white",
		width: "100%",
		height: "50vh",
	},
	float: {
		margin: theme.spacing(1),
	},
}));

function InterventionDetails(props) {
	const classes = useStyles();
	const {
		language,
		tiny,
		data,
		isSm,
		inter,
		userPermissions,
		isAuthenticated,
	} = props;

	const [newStatus, setNewStatus] = React.useState("OPEN");
	const [newStatusId, setNewStatusId] = React.useState(null);
	const [pvImage, setPvImage] = React.useState(null);
	const [note, setNote] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [imageUploaded, setImageUploaded] = React.useState(false);
	const [alarm, setAlarm] = React.useState(false);
	const [offender, setOffender] = React.useState(false);
	const [report, setReport] = React.useState(false);
	const [theft, setTheft] = React.useState(false);
	const [docNo, setDocNo] = React.useState("");

	// This will hold the OBJID of the users that where selected as being in the intervention Team
	// Use for the InterventionAction on the confirmation of the PV image (closing the intervention)
	const [teamMembers, setTeamMembers] = React.useState([]);
	// Information about the person present on the intervention site (client or someone else that is sent by the client)
	const [presentPerson, setPresentPerson] = React.useState("");
	const [presentPersonContact, setPresentPersonContact] = React.useState("");

	// This will controller the Upload Modal for the Comments
	const [commentFileUploadModal, setCommentFileUploadModal] = React.useState(
		false
	);

	// This will hold the uploaded file for the Comment Upload Modal
	// Default is null
	const [uploadedFile, setUploadedFile] = React.useState(null);

	// Get the user ID check if it exists, remove it from the teamMembers list and set the state
	function deleteTeamMember(userId) {
		if (teamMembers.includes(userId)) {
			let placeholder = [...teamMembers];
			placeholder.splice(teamMembers.indexOf(userId), 1);
			setTeamMembers(placeholder);
		}
	}

	function updateTheft(value) {
		let _value = value;
		if (_value !== false) {
			_value = parseInt(
				_value
					.toString()
					.replace(/[^0-9]/g, "")
					.slice(0, 10),
				10
			);
			if (
				_value.toString().indexOf("0") === 0 &&
				_value.toString().length > 1
			) {
				_value = parseInt(
					_value.toString().slice(1, _value.toString().length)
				);
			}
		}
		setTheft(_value);
	}

	function updateDocNo(e) {
		let { value } = e.target;
		value = value
			.toString()
			.replace(/[^0-9]/g, "")
			.slice(0, 20);
		setDocNo(value);
	}

	function updateNote(e) {
		const { value } = e.target;
		setNote(value);
	}

	function openDialog(status, id) {
		setOpen(true);
		setNote("");
		setAlarm(false);
		setOffender(false);
		setReport(false);
		setTheft(false);
		setDocNo("");
		if (status === "ASSIGN") {
			setNewStatus("ASSIGNED");
			setNewStatusId(id);
		} else {
			setNewStatus(getNextStatus(status, inter.data.task.TYPE_ID));
			setNewStatusId(
				id ? id : getNextStatusId(status, inter.data.task.TYPE_ID)
			);
		}
	}

	function getNextStatusId(status, type) {
		switch (status) {
			case 1:
				return 2;
			case 2:
			case 3:
				if (type === 1) return 6;
				else return 5;
			case 5:
				return 6;
			case 6:
			case 10:
				return 9;
			case 9:
				return 8;
			default:
				return status;
		}
	}
	function getNextStatus(status, type) {
		switch (status) {
			case 1:
				return "OPEN";
			case 2:
				if (type === 1) return "STARTED";
				else return "ON_DRIVE";
			case 3:
				if (type === 1) return "STARTED";
				else return "ON_DRIVE";
			case 5:
				return "STARTED";
			case 6:
			case 10:
				return "VALIDATING";
			case 9:
				return "CLOSED";
			default:
				return status;
		}
	}

	const executeInterventionAction = () => {
		inter.newTaskAction(
			newStatus === "ASSIGNED" ? 2 : 3,
			newStatus === "ASSIGNED"
				? inter.data.task.OWNER_CURR
				: inter.data.task.STATUS_ID,
			newStatus === "ASSIGNED" ? null : newStatusId,
			note,
			pvImage,
			alarm,
			offender,
			report,
			theft,
			{
				TEAM_MEMBERS: teamMembers,
				PRESENT_PERSON: presentPerson,
				PRESENT_PERSON_CONTACT: presentPersonContact,
			}
		);
		setOpen(false);
	}

	return (
		<Paper className={classes.paper}>
			<CustomModal
				fullWidth
				open={open}
				title={
					inter && inter.data && inter.data.task && ["CHECK_IN", "ASSIGNED"].indexOf(newStatus) === -1 ?
						`${lang.CONFIRM_ACTION[language]} : [${inter.data.task.STATUS ? lang[inter.data.task.STATUS][language].toUpperCase() : lang.OPEN[language]}] - [${lang[newStatus][language].toUpperCase()}]`:
						lang.CONFIRM_ACTION[language]
				}
				content={
					<Grid container spacing={2}>
						<InterventionAction
							classes={classes}
							updateNote={updateNote}
							note={note}
							language={language}
							tiny={tiny}
							pvImage={pvImage}
							loadImage={newStatus === "VALIDATING"}
							onImageLoad={(blob) => {
								setPvImage(blob);
								setImageUploaded(true);
							}}
							imageUploaded={imageUploaded}
							onNextStateChange={(status) => {
								setNewStatus(status);
								if (status === "CLOSED") {
									setNewStatusId(8);
								}
							}}
							teamMembers={teamMembers}
							addNewTeamMember={setTeamMembers}
							deleteTeamMember={deleteTeamMember}
							presentPerson={presentPerson}
							setPresentPerson={setPresentPerson}
							presentPersonContact={presentPersonContact}
							setPresentPersonContact={setPresentPersonContact}
							newStatusId={newStatusId}
							isAlarm={
								inter &&
								inter.data &&
								inter.data.task &&
								inter.data.task.TYPE_ID === 2
							}
							isAlarmTechnic={
								inter &&
								inter.data &&
								inter.data.task &&
								inter.data.task.TYPE_ID === 2 &&
								inter.data.task.SUBTYPE_ID === 5
							}
							alarm={alarm}
							updateAlarm={(value) => setAlarm(value)}
							offender={offender}
							updateOffender={(value) => setOffender(value)}
							report={report}
							updateReport={(value) => setReport(value)}
							theft={theft}
							updateTheft={updateTheft}
							docNo={docNo}
							updateDocNo={updateDocNo}
						/>
					</Grid>
				}
				formValidate={newStatus === "VALIDATING" ? false : true}
				close={() => setOpen(false)}
				execute={() => executeInterventionAction()}
				language={language}
				validate={newStatus === "VALIDATING" && pvImage !== null && note !== ""}
			/>
			<UploadFiles
				open={commentFileUploadModal}
				close={() => setCommentFileUploadModal(false)}
				execute={(base64File) => setUploadedFile(base64File)}
				language={props.language}
			/>
			<ActivityModalIndicator open={props.isUploadingFile} />
			{props.inter.loading ? (
				<LinearProgress />
			) : (
				<React.Fragment>
					<CustTaskDetails
						data={data}
						language={language}
						cust={props.cust}
						tiny={tiny}
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
					/>
					<SiteInfo
						data={props.siteData}
						language={language}
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
					/>
					<SiteImageModal siteId={props.siteData.OBJID} />
					<TaskInfo
						type={props.inter ? `INTERVENTION` : "TASK"}
						classes={classes}
						task={inter}
						data={data && data.task}
						language={language}
						tiny={tiny}
						isSm={isSm}
					/>
					<Grid
						item
						xs={12}
						container
						direction="row"
						spacing={4}
						className={classes.buttonGroup}
					>
						<InterventionButtons
							language={language}
							tiny={tiny}
							task={inter}
							openAction={openDialog}
							userPermissions={userPermissions}
							isAuthenticated={isAuthenticated}
						/>
					</Grid>
					<CommentList
						downloadFile={props.downloadFile}
						classes={classes}
						task={inter}
						data={data && data.history}
						language={language}
						tiny={tiny}
						isIntervention={true}
						openFileUploadModal={() =>
							setCommentFileUploadModal(true)
						}
						uploadedFile={uploadedFile}
					/>
				</React.Fragment>
			)}
		</Paper>
	);
}

export default function DeprecatedInterventionModal(props) {
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const isSm = useMediaQuery(theme.breakpoints.down("sm"));
	const {
		language,
		open,
		close,
		interventionId,
		inter,
		userPermissions,
		isAuthenticated,
	} = props;

	React.useEffect(() => {
		if (interventionId !== null) inter.fetchData();
	}, [interventionId]);

	return (
		<CustomModal
			fullScreen
			open={open}
			title={`#${interventionId} - ${lang.INTERVENTION[language]}`}
			content={
				<Grid container spacing={2}>
					<InterventionDetails
						isUploadingFile={props.isUploadingFile}
						siteData={props.siteData}
						downloadFile={inter.downloadFile}
						inter={inter}
						data={inter.data}
						language={language}
						tiny={tiny}
						isSm={isSm}
						cust={props.cust}
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
					/>
				</Grid>
			}
			close={close}
			language={language}
			execute={() => { }}
			noButtons
			onlyBack
		/>
	);
}
