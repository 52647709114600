import React, { Component, useState } from 'react';
import {
    CssBaseline,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
    Paper,
    Checkbox,
    FormControlLabel,
    Button,
} from '@material-ui/core';

import {
    VisibilityRounded,
    Edit,
    ArrowForwardIos,
    ArrowBackIos
} from '@material-ui/icons';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';
import * as lang from '../../Constants/strings';

import { cust, smallCust } from '../../Components/Misc/Tables/TableHeaders';
import { custData, custAddress } from '../../Constants/forms';
import MaterialTableDemo from '../../Components/Misc/Tables/MaterialTable';
import TableSkeleton from '../Nomenclature/Skeleton';
import CustomModal from '../../Components/Modals/CustomModal';
import CustomerNewForm from './CustomerViewComponents/CustomerNewForm';
import { custAddFormat2 } from '../../Functions/objectManipulation';
import CustomDrawer from '../../Components/Modals/CustomDrawer';
import { redirectTo } from '../../Functions/linkFunctions';
import CustomButtonList from '../../Components/Modals/CustomButtonList';
import { checkPermissions } from '../../Functions/permissionHide';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    filterPaper: {
        padding: "5px",
        width: '75px',
        height: '50px',
        transition: "width 1s ease",
        overflow: 'hidden',
    },
    bigger: {
        width: '100%',
        padding: "5px",
        overflow: 'hidden',
    },
    checkbox: {
        padding: "0px 10px",
    },
}));

function CustomersSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        language,
        rowsPerPage,
        data,
        onSearch,
        getSearchTerm,
        searchTerm,
        filterForActiveClients,
        setFilterForActiveClients,
        filterForValidateClients,
        setFilterForValidatedClients,
        filterOpen,
        setFilterOpen
    } = props;

    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <Paper className={filterOpen ? classes.bigger : classes.filterPaper}>
                    <Grid container justify="space-between">
                        <Grid item xs={4} sm={1}>
                            <Button style={{ height: '40px' }} onClick={() => setFilterOpen(!filterOpen)}>
                                {filterOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
                            </Button>
                        </Grid>
                        <Grid item xs={8} sm={11}>
                            {filterOpen &&
                                <Grid container>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filterForActiveClients === 'active'}
                                                onClick={() => setFilterForActiveClients('active')}
                                            />
                                        }
                                        label={lang.ACTIVE_CLIENTS[language]}
                                        className={classes.checkbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filterForActiveClients === 'inactive'}
                                                onClick={() => setFilterForActiveClients('inactive')}
                                            />
                                        }
                                        label={lang.INACTIVE_CLIENTS[language]}
                                        className={classes.checkbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filterForValidateClients === 'validate'}
                                                onClick={() => setFilterForValidatedClients('validate')}
                                            />
                                        }
                                        label={lang.VALIDATE_CLIENTS[language]}
                                        className={classes.checkbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filterForValidateClients === 'invalidate'}
                                                onClick={() => setFilterForValidatedClients('invalidate')}
                                            />
                                        }
                                        label={lang.NOVALIDATE_CLIENTS[language]}
                                        className={classes.checkbox}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.CLIENTS[language]}
                    language={language}
                    data={data}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={tiny === true ? smallCust(language) : cust(language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="ID"
                    onSearch={(searchTerm) => onSearch(searchTerm)}
                    getSearchTerm={value => getSearchTerm(value)}
                    searchTerm={searchTerm}
                    hideSettings={true}
                />
            </Grid>
        </Grid>
    );
}

function CustomerSettingsModal(props) {
    const { language, close, selectedIndex, open } = props;
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();
    return (
        <React.Fragment>
            <CustomDrawer
                open={open && tiny}
                close={close}
                list={[
                    {
                        primary: lang.GO_TO(lang.CUSTOMER[language])[language],
                        secondary: "",
                        icon: <VisibilityRounded />,
                        action: () => { redirectTo(`/customer?id=${selectedIndex}`) },
                        render: props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "VIEW_CUSTOMER_DETAIL" }) === true ? true : false,
                    },
                    {
                        primary: lang.VALIDATES[language],
                        secondary: "",
                        icon: <Edit />,
                        action: () => { props.onValidateCustomer() },
                        render: props.customer === null ? false : props.customer.VERIFY === 0 ? props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "EDIT_CUSTOMER" }) === true ? true : false : false,
                    },
                ]}
            />
            <CustomModal
                open={open && !tiny}
                title={lang.CUSTOMER_DETAILS[language]}
                content={
                    <Grid container spacing={2}>
                        <CustomButtonList
                            close={close}
                            language={language}
                            tiny={tiny}
                            list={[
                                {
                                    text: lang.GO_TO(lang.CUSTOMER[language])[language],
                                    style: {
                                        icon: <VisibilityRounded className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: () => { redirectTo(`/customer?id=${selectedIndex}`) },
                                    render: props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "VIEW_CUSTOMER_DETAIL" }) === true ? true : false,
                                },
                                {
                                    text: lang.VALIDATES[language],
                                    style: {
                                        icon: <Edit className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: () => { props.onValidateCustomer() },
                                    render: props.customer === null ? false : props.customer.VERIFY === 0 ? props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "CUSTOMERS", name: "EDIT_CUSTOMER" }) === true ? true : false : false,
                                },
                            ]}
                            withBack
                        />
                    </Grid>
                }
                close={close}
                execute={close}
                language={language}
                noButtons
            />
        </React.Fragment>
    );
}


class CustomersView extends Component {
    state = {
        addData: {
            TYPE: 'PJ',
            ...custData.PJ,

            ...custAddress,
        },
        addModal: false,

        error: null,
        settingsModal: false,
        selectedIndex: null,
        index: null,
        searchTerm: '',
        customerData: {},
        editModal: false,
        editModalValidation: false,
        filterForActiveClients: false,
        filterForValidateClients: false,
        filterOpen: false
    }

    componentDidMount() {
        document.title = "RPG - " + lang.CLIENTS[this.props.language];
        this.props.actions.setCurrentPage(0);
        this.props.actions.getCustomerCount();
        this.props.actions.setSortColumn1('ID');
        this.props.actions.getCustomerList(
            this.props.rowsPerPage,
            0,
            'ID',
            'ASC',
            this.state.filterForActiveClients,
            this.state.filterForValidateClients
        );
    };
    // @FIX_ME when you can pls fix it 
    componentDidUpdate(prevProps) {
        if (this.state.searchTerm === '') {
            if (
                prevProps.rowsPerPage !== this.props.rowsPerPage ||
                prevProps.sortColumn !== this.props.sortColumn ||
                prevProps.order !== this.props.order
            ) {
                this.props.actions.getCustomerList(
                    this.props.rowsPerPage,
                    this.props.currentPage,
                    this.props.sortColumn,
                    this.props.order,
                    this.state.filterForActiveClients,
                    this.state.filterForValidateClients
                );
            }
            if (prevProps.currentPage !== this.props.currentPage) {
                if (this.props.currentPage === this.props.loadedPages - 1)
                    this.props.actions.loadNewCustomer(
                        this.props.rowsPerPage,
                        this.props.currentPage,
                        this.props.sortColumn,
                        this.props.order,
                        this.state.filterForActiveClients,
                        this.state.filterForValidateClients
                    );
            }
        } else {
            if (
                prevProps.rowsPerPage !== this.props.rowsPerPage ||
                prevProps.sortColumn !== this.props.sortColumn ||
                prevProps.order !== this.props.order
            ) {
                this.props.actions.onSearchCustomer(
                    this.state.searchTerm,
                    this.props.sortColumn,
                    this.props.order,
                    this.state.filterForActiveClients,
                    this.state.filterForValidateClients
                )
            }
        }
    };

    async onValidateCustomer() {
        var primaryAddress = {};
        this.props.customerData.address.map(address => {
            if (address.OBJID === this.props.customerData.customer.FK2PRIMARY_ADDRESS) {
                primaryAddress = { ...address };
            }
        })
        delete primaryAddress.CREATE_TIME;
        delete primaryAddress.TYPE;
        await this.setState({ editModal: true, customerData: { ...this.props.customerData.customer, ...primaryAddress } });
    }

    async onOpenRowModal(value) {
        await this.props.actions.getCustomerData(value);
        await this.setState({ selectedIndex: value, index: value });
    }

    getEntries({ filterForValidateClients, filterForActiveClients }) {
        this.props.actions.setCurrentPage(0);
        if (this.state.searchTerm !== '') {
            this.props.actions.onSearchCustomer(
                this.state.searchTerm,
                this.props.sortColumn,
                this.props.order,
                filterForActiveClients,
                filterForValidateClients
            )
        } else if (this.state.searchTerm === '') {
            this.props.actions.getCustomerList(
                this.props.rowsPerPage,
                this.props.currentPage,
                this.props.sortColumn,
                this.props.order,
                filterForActiveClients,
                filterForValidateClients
            );
        }
        this.props.actions.getCustomerCount(
            filterForActiveClients,
            filterForValidateClients
        );
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    fullWidth
                    open={this.state.addModal}
                    title={lang.ADD_CUSTOMER[this.props.language]}
                    content={
                        <CustomerNewForm
                            address
                            type={this.state.addData.TYPE}
                            addData={this.state.addData}
                            language={this.props.language}
                            updateFieldRaw={(field, value) => this.setState({ addData: { ...this.state.addData, [field]: value } })}
                            updateField={(e, length) => this.setState({ addData: { ...this.state.addData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                            toggleType={(value) => this.setState({ addData: { TYPE: value, ...custData[value], ...custAddress }, error: null, })}
                            error={this.state.error}
                            updateError={(value) => this.setState({ error: value })}
                        />
                    }
                    close={() => {
                        this.setState({
                            addModal: false,
                        })
                    }}
                    execute={async () => {
                        this.setState({ addModal: false })
                        await this.props.actions.addCustomer(this.props.userAuth.id, custAddFormat2(this.state.addData), this.props.rowsPerPage);
                        await this.props.actions.getCustomerList(this.props.rowsPerPage, 0, this.props.sortColumn, this.props.order);
                    }}
                    /* 
                        @TOOD
                        The fuck is this,
                        I just format it, this will need to be fix
                    */
                    validate={
                        this.state.error === false &&
                        this.state.addData.REGION.length > 0 &&
                        this.state.addData.CITY.length > 0 &&
                        this.state.addData.STREET_NAME.length > 0
                        && (
                            (this.state.addData.TYPE === 'PF' &&
                                this.state.addData.CNP.length > 0 &&
                                this.state.addData.FIRST_NAME &&
                                this.state.addData.FIRST_NAME.length > 0 &&
                                this.state.addData.LAST_NAME &&
                                this.state.addData.LAST_NAME.length > 0 &&
                                this.state.addData.PHONE.length > 0) ||
                            (this.state.addData.TYPE === 'PJ' &&
                                this.state.addData.CUI.length > 0 &&
                                this.state.addData.NAME &&
                                this.state.addData.NAME.length > 0 &&
                                this.state.addData.PHONE.length > 0)
                        )
                    }
                    language={this.props.language}
                />
                <CustomerSettingsModal
                    open={this.state.selectedIndex !== null}
                    close={() => this.setState({ selectedIndex: null })}
                    selectedIndex={this.state.selectedIndex}
                    language={this.props.language}
                    onValidateCustomer={() => this.onValidateCustomer()}
                    customer={this.props.customerData.customer || null}
                    userPermissions={this.props.permissions}
                    isAuthenticated={this.props.isAuthenticated}
                />
                <CustomModal
                    fullWidth
                    open={this.state.editModal}
                    title={lang.EDIT_CUSTOMER[this.props.language]}
                    content={
                        <CustomerNewForm
                            edit
                            address
                            type={this.state.customerData ? this.state.customerData.TYPE : ""}
                            addData={this.state.customerData}
                            language={this.props.language}
                            updateFieldRaw={(field, value) => this.setState({ customerData: { ...this.state.customerData, [field]: value }, edited: true })}
                            updateField={(e, length) => this.setState({ customerData: { ...this.state.customerData, [e.target.name]: e.target.value.toString().slice(0, length) }, edited: true })}
                            error={this.state.editModalValidation}
                            updateError={value => this.setState({ editModalValidation: value })}
                        />
                    }
                    close={() => {
                        this.setState({
                            editModal: false,
                        })
                    }}
                    execute={async () => {
                        this.setState({ editModal: false });
                        await this.props.actions.editCustomer(this.props.userAuth.id, this.state.customerData);
                        await this.props.actions.onCustomerValidation(this.state.index);
                        await this.props.actions.getCustomerList(this.props.rowsPerPage, 0, 'ID', 'ASC');
                    }}
                    validate={true}
                    language={this.props.language}
                />
                {this.props.isFetchingCustomerList === false
                    ?
                    <CustomersSetup
                        language={this.props.language}
                        data={this.props.customerList}
                        theme={this.props.theme}
                        rowsPerPage={this.props.rowsPerPage}
                        onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
                        openAddModal={() => this.setState({ addModal: true, addData: { TYPE: 'PJ', ...custData.PJ, ...custAddress } })}
                        openRowModal={(value) => this.onOpenRowModal(value)}
                        onSearch={() => {
                            this.props.actions.onSearchCustomer(
                                this.state.searchTerm,
                                'ID',
                                'ASC',
                                this.state.filterForActiveClients,
                                this.state.filterForValidateClients
                            );
                            this.props.actions.setCurrentPage(0);
                        }}
                        getSearchTerm={value => this.setState({ searchTerm: value })}
                        searchTerm={this.state.searchTerm}
                        filterForActiveClients={this.state.filterForActiveClients}
                        setFilterForActiveClients={(value) => {
                            this.setState({ filterForActiveClients: value })
                            this.getEntries({ filterForActiveClients: value, filterForValidateClients: this.state.filterForValidateClients })
                        }}
                        filterForValidateClients={this.state.filterForValidateClients}
                        setFilterForValidatedClients={(value) => {
                            this.setState({ filterForValidateClients: value })
                            this.getEntries({ filterForValidateClients: value, filterForActiveClients: this.state.filterForActiveClients })
                        }}
                        filterOpen={this.state.filterOpen}
                        setFilterOpen={() => this.setState({ filterOpen: !this.state.filterOpen })}
                    />
                    :
                    <TableSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        order: state.config.order,
        loadedPages: state.config.loadedPages,
        rowsPerPage: state.config.rowsPerPage,
        sortColumn: state.config.sortColumn,
        currentPage: state.config.currentPage,
        customerList: state.cust.customerList,
        isFetchingCustomerList: state.cust.isFetchingCustomerList,
        userAuth: state.auth.userData,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        customerData: state.cust.customerData,
    }
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView);