export const OPEN_SNACKBAR_NOTIFICATION = "OPEN_SNACKBAR_NOTIFICATION";
export const CLOSE_SNACKBAR_NOTIFICATION = "CLOSE_SNACKBAR_NOTIFICATION";

export function onNotificationOpen(variant, message, autoHide) {
    return {
        type: OPEN_SNACKBAR_NOTIFICATION,
        payload: {
            variant,
            message,
            autoHide,
        }
    }
};
export function onNotificationClose() {
    return {
        type: CLOSE_SNACKBAR_NOTIFICATION,
    }
};

export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";

export function onDarkModeToggle() {
    return {
        type: TOGGLE_DARK_MODE,
    }
};

export const UPDATE_GEOLOCATION = "UPDATE_GEOLOCATION";

export function onGeolocationUpdate(data) {
    return {
        type: UPDATE_GEOLOCATION,
        payload: {
            data
        }
    }
};

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export function onLanguageChange(language) {
    return {
        type: CHANGE_LANGUAGE,
        payload: {
            language
        }
    }
}

export const CHANGE_ROWS_PER_PAGE = "CHANGE_ROWS_PER_PAGE";

export function onRowsPerPageChange(data) {
    return {
        type: CHANGE_ROWS_PER_PAGE,
        payload: {
            data
        }
    }
}

export const SET_NO_ITEMS = 'SET_NO_ITEMS';

export function setNoOfItems(data) {
    return {
        type: SET_NO_ITEMS,
        payload: {
            data: data
        }
    }
}

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export function setCurrentPage(data) {
    return {
        type: SET_CURRENT_PAGE,
        payload: {
            data: data
        }
    }
}

export const SET_SORT_COLUMN = 'SET_SORT_COLUMN';

export function setSortColumn(data, oldColumn, order) {
    return (dispatch) => {
        if (data === oldColumn) {
            dispatch(setOrder(order === 'ASC' ? 'DESC' : 'ASC'));
            dispatch(setSortColumn1(data));
        } else {
            dispatch(setOrder('ASC'));
            dispatch(setSortColumn1(data));
        }
    }

}

export function setSortColumn1(data) {
    return {
        type: SET_SORT_COLUMN,
        payload: {
            data: data
        }
    }
}

export const SET_ORDER = 'SET_ORDER';

export function setOrder(data) {
    return {
        type: SET_ORDER,
        payload: {
            data: data
        }
    }
}

export const SET_LOADED_PAGES = 'SET_LOADED_PAGES';

export function setLoadedPages(data) {
    return {
        type: SET_LOADED_PAGES,
        payload: {
            data: data,
        }
    }
}

export const RECEIVE_SORT_ITEMS = 'RECEIVE_SORT_ITEMS';
export const SET_SORT_ITEM = 'SET_SORT_ITE';

export function setSortItem(data) {
    return {
        type: SET_SORT_ITEM,
        payload: {
            data: data
        }
    }
};

export function sortItemListRecive(data) {
    return {
        type: RECEIVE_SORT_ITEMS,
        payload: {
            data: data
        }
    }
}

export function getActiveAreaList() {
    return (dispatch) => {
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/areas`, options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                dispatch(sortItemListRecive(response.data));
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}

export const CLEAR_SORT_ITEM = 'CLEAR_SORT_ITEM';

export function resetSortItem() {
    return {
        type: CLEAR_SORT_ITEM,
    };
};