import { useState, useEffect } from "react";
import { Paper, Grid, Select, MenuItem, InputLabel, Button, FormControl, makeStyles } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from "axios";

import * as lang from "../../../Constants/strings";
import * as requestHeaders from "../../../Constants/requestHeaders";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(6, 2, 6, 2),
        width: 'auto',
        borderRadius: "2px 0px 2px 0px",
    },
    grid: {
        padding: "20px"
    },
    button: {
        height: "100%",
    }
}));

/**
 * Select for time frame, area and contractor for filtering the task history 
 * NOTE: Use for Repair, Maintenace and Instalation task
 */
const HistoryFilterBar = (props) => {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedArea,
        setSelectedArea,
        selectedContractor,
        setSelectedContractor,
        areaList,
        reset
    } = props;

    const classes = useStyles();

    const [contractors, setContractors] = useState([]);

    const fetchContractors = async () => {
        try {
            const res = await axios.get(`/api/executors_active`, { ...requestHeaders.getGetHeader() });
            setContractors([...res.data]);
        } catch (e) {
            console.error("Failed to fetch the active contractors");
        }
    }

    useEffect(() => {
        fetchContractors().then(() => { });
    }, []);

    return (
        <Paper className={classes.margin}>
            <Grid container spacing={4} justifyContent="space-between" justifyContent="center" className={classes.grid}>
                <Grid item xs={11} md={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            required
                            inputVariant="outlined"
                            fullWidth
                            name="START_DATE"
                            label={lang.START_INTERVAL_DATE[props.language]}
                            format="dd-MM-yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            value={startDate}
                            onChange={(value) => setStartDate(value)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={11} md={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            required
                            inputVariant="outlined"
                            fullWidth
                            name="START_DATE"
                            label={lang.LAST_INTERVAL_DATE[props.language]}
                            format="dd-MM-yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            value={endDate}
                            onChange={(value) => setEndDate(value)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={11} md={3}>
                    <FormControl style={{ width: "100%" }} variant="outlined">
                        <InputLabel htmlFor="area_select">{lang.AREA_SELECT[props.language]}</InputLabel>
                        <Select
                            variant="outlined"
                            value={selectedArea}
                            onChange={(e) => setSelectedArea(e.target.value)}
                            label={lang.AREA_SELECT[props.language]}
                            inputProps={{ id: 'area_select' }}
                        >
                            {areaList.map((area) => <MenuItem key={area.AREA} value={area}>{area.AREA}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={11} md={3}>
                    <FormControl style={{ width: "100%" }} variant="outlined">
                        <InputLabel htmlFor="contractor_select">{lang.SELECT_CONTRACTOR[props.language]}</InputLabel>
                        <Select
                            variant="outlined"
                            value={selectedContractor}
                            onChange={e => setSelectedContractor(e.target.value)}

                            inputProps={{ id: 'contractor_select' }}
                            label={lang.SELECT_CONTRACTOR[props.language]}
                        >
                            {contractors.map((c) => <MenuItem key={c.NAME} value={c}>{c.NAME}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => reset()}
                    >
                        {lang.RESET_FILTER[props.language]}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default HistoryFilterBar;
