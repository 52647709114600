import * as actionTypes from '../actions/configActions';

const initialState = {
    theme: "dark",
    language: "ro",
    notificationOpen: false,
    notificationMessage: "",
    notificationVariant: "info",
    rowsPerPage: 5,
    currentPage: 0,
    loadedPages: 11,
    sortColumn: '',
    order: 'ASC',
    numbersOfItems: null,
    sortItems: [],
    selectedSortItem: [],
    geolocation: {
        latitude: 44.4354,
        longitude: 26.1033,
        altitude: null,
        accuracy: 5000,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
        error: false,
    },
};

export default function configReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.OPEN_SNACKBAR_NOTIFICATION: {
            return {
                ...state,
                notificationOpen: true,
                notificationMessage: action.payload.message[state.language],
                notificationVariant: action.payload.variant,
            }
        }
        case actionTypes.CLOSE_SNACKBAR_NOTIFICATION: {
            return {
                ...state,
                notificationOpen: false,
                notificationMessage: "",
            }
        }
        case actionTypes.TOGGLE_DARK_MODE: {
            return {
                ...state,
                theme: state.theme === "dark" ? "light" : "dark",
            }
        }
        case actionTypes.UPDATE_GEOLOCATION: {
            return {
                ...state,
                geolocation: {
                    latitude: action.payload.data.latitude,
                    longitude: action.payload.data.longitude,
                    accuracy: Math.floor(action.payload.data.accuracy) + 1,
                    error: action.payload.data.error,
                },
            }
        }
        case actionTypes.CHANGE_LANGUAGE: {
            return {
                ...state,
                language: action.payload.language,
            }
        }
        case actionTypes.CHANGE_ROWS_PER_PAGE: {
            return {
                ...state,
                rowsPerPage: action.payload.data,
            }
        }
        case actionTypes.SET_NO_ITEMS: {
            return {
                ...state,
                numbersOfItems: action.payload.data
            }
        }
        case actionTypes.SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.payload.data
            }
        }
        case actionTypes.SET_SORT_COLUMN: {
            return {
                ...state,
                sortColumn: action.payload.data
            }
        }
        case actionTypes.SET_ORDER: {
            return {
                ...state,
                order: action.payload.data
            }
        }
        case actionTypes.SET_LOADED_PAGES: {
            return {
                ...state,
                loadedPages: action.payload.data
            }
        }
        case actionTypes.RECEIVE_SORT_ITEMS: {
            return {
                ...state,
                sortItems: action.payload.data
            }
        }
        case actionTypes.SET_SORT_ITEM: {
            // filter the null values
            if (action.payload.data !== null) {
                let placeholder = state.selectedSortItem;
                let duplicate = false;
                // Check for a duplicated value
                state.selectedSortItem.map(item => {
                    if (item === action.payload.data)
                        duplicate = true
                });
                if (!duplicate) {
                    placeholder.push(action.payload.data);
                    return {
                        ...state,
                        selectedSortItem: placeholder
                    }
                } else {
                    return state
                }
            } else {
                return state
            }
        }
        case actionTypes.CLEAR_SORT_ITEM: {
            return {
                ...state,
                selectedSortItem: []
            }
        }
        default:
            return state;
    }
}