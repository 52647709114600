import React, {useEffect, useState} from "react";
import * as lang from "../../../../Constants/strings";
import CustomModal from "../../../../Components/Modals/CustomModal";
import {
	useMediaQuery,
	useTheme,
	Grid,
	makeStyles,
	Paper,
	LinearProgress,
	Typography,
	Button
} from "@material-ui/core";
import CommentList from "../CommentList";
import TaskInfo from "../TaskInfo";
import TaskButtons from "../TaskButtons";
import TaskAction from "../TaskAction";
import CustTaskDetails from "../../../Site/SiteSingleViewComponents/CusTaskDetails";
import TaskContract from "./TaskContract";
import AddContractModal from "./AddContractModal";
import SiteInfo from "../SiteInfo";
import TaskResetModal from "./TaskResetModal";
import UploadFiles from "../../../../Components/Modals/UploadFiles";
import ActivityModalIndicator from "./ActivityModalIndicator";
import UpdateTaskReason from "./UpdateTaskReason";
import NoGpsModal from "./NoGpsModal";

import axios from "axios";
import { redirectTo } from "../../../../Functions/linkFunctions";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import moment from "moment";
import SiteImageModal from "../../../../Components/Modals/SiteImageModal";
import RegisterInventory from "../../../Installation/Modals/RegisterInventory";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		width: "100%",
	},
	avatar: {
		width: 50,
		height: 50,
	},
	title: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	comment: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	commentAuto: {
		color: "gray",
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	timestamp: {
		marginTop: theme.spacing(1.5),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	buttonGroup: {
		marginTop: theme.spacing(1),
	},
	iconRight: {
		marginLeft: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	divider: {
		margin: theme.spacing(1),
	},
	signature: {
		backgroundColor: "white",
		width: "100%",
		height: "50vh",
	},
	float: {
		margin: theme.spacing(1),
	},
}));

function TaskDetails(props) {
	const classes = useStyles();
	const {
		language,
		tiny,
		data,
		isSm,
		task,
		siteData,
		userPermissions,
		isAuthenticated,
		openRegisterProgress
	} = props;

	const sigCanvas = React.useRef({});
	const [imageURL, setImageURL] = React.useState(null);
	const [siteContact, setSiteContact] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [note, setNote] = React.useState("");
	const [serviceNo, setServiceNo] = React.useState("");
	const [warranty, setWarranty] = React.useState(false);
	const [newStatus, setNewStatus] = React.useState("OPEN");
	const [newStatusId, setNewStatusId] = React.useState(null);
	const [dispatchers, setDispatchers] = React.useState([]);
	const [selectedDispatcher, setSelectedDispatcher] = React.useState(null);
	const [type, setType] = React.useState("INSTALARE");
	const [subtype, setSubtype] = React.useState([]);
	const [teamSize, setTeamSize] = React.useState(1);
	const [deadLine, setDeadLine] = React.useState(new Date());

	const [pvImage, setPvImage] = React.useState(null);
	const [noNetModal, setNoNetModal] = React.useState(false);
	const [imageUploaded, setImageUploaded] = React.useState("");

	const [contractModalStatus, setContractModalStatus] = React.useState(false);
	const [billingModalStatus, setBillingModalStatus] = React.useState(false);
	const [newContractData, setNewContractData] = React.useState({
		CONTRACTOR_OBJID: "",
	});
	const [newContractModal, setNewContractModal] = React.useState(false);

	const [resetOwnerModal, setResetOwnerModal] = React.useState(false);

	const [commentFileUploadModal, setCommentFileUlpoadModal] = React.useState(false);

	const [updateReasonModal, setUpdateReasonModal] = React.useState(false);
	const [uploadedFile, setUploadedFile] = React.useState(null);

	const [noGpsWarning, setNoGpsWarning] = React.useState(false);
	const [registerInventory, setRegisterInventory] = React.useState(false);

	const [activeSiteMaintenances, setActiveSiteMaintenances] = React.useState([]);

	React.useEffect(() => {
		if (
			siteData &&
			siteData.CONTACT_FIRST_NAME &&
			siteData.CONTACT_LAST_NAME
		)
			setSiteContact(
				`${siteData.CONTACT_LAST_NAME} ${siteData.CONTACT_FIRST_NAME}`
			);
	}, [siteData]);

	function updateSiteContact(e) {
		const { value } = e.target;
		setSiteContact(value);
	}

	function updateNote(e) {
		const { value } = e.target;
		setNote(value);
	}

	function updateWarranty(value) {
		setWarranty(value);
	}

	function updateServiceNo(e) {
		const { value } = e.target;
		setServiceNo(value);
	}

	function openDialog(status, id) {
		setOpen(true);
		setNote("");
		setImageURL(null);
		setServiceNo("");
		task.resetOpt();
		task.resetTrainees();
		task.resetProject();
		task.resetJournal();
		task.resetJournalData();
		setDeadLine(new Date());
		setSubtype([]);
		setTeamSize(1);
		setPvImage(null);
		setSelectedDispatcher(null);
		if (status === "ASSIGN") {
			setNewStatus("ASSIGNED");
			setNewStatusId(id);
		} else {
			setNewStatus(getNextStatus(status));
			setNewStatusId(id ? id : getNextStatusId(status));
		}
		if (status === "LOAD_IMAGE") {
			setNoNetModal(true);
		} else {
			setNoNetModal(false);
		}
	}

	React.useEffect(() => {
		fetch(`/api/dispatch_assignee_list`, {
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.token,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				setDispatchers(response);
			})
			.catch((err) => console.log(err));
	}, []);

	function getNextStatusId(status) {
		switch (status) {
			case 1:
			case 4:
				return 2;
			case 2:
			case 3:
				return 5;
			case 5:
				return 6;
			case 6:
				return 8;
			default:
				return null;
		}
	};

	function getNextStatus(status) {
		switch (status) {
			case 1:
			case 4:
				return "OPEN";
			case 2:
			case 3:
				return "ON_DRIVE";
			case 5:
				return "STARTED";
			case 6:
				return "CLOSED";
			default:
				return status;
		}
	}

	function updateField(e, length) {
		e.persist();
		if (e.reason === "clickaway") {
			return;
		}
		if (
			setNewContractData[e.target.value] + e.target.value !==
			setNewContractData[e.target.value]
		) {
			let data = newContractData;
			data[e.target.name] = e.target.value.toString().slice(0, length);
			setNewContractData({ ...data });
		}
	}

	const getTaskModalTitle = () => {
		let title = task && task.data && task.data.task && newStatus !== "ASSIGNED"
			? `${lang.CONFIRM_ACTION[language]}: [${task.data.task.STATUS ? lang[
				task.data.task.STATUS
			][language].toUpperCase() : lang.OPEN[language]}] - [${lang[newStatus][
				language
			].toUpperCase()}]`
			: lang.CONFIRM_ASSIGN[language];

		return title;
	}

	const getSiteActiveMaintenances = React.useCallback(
		async () => {
			try {
				let response = await axios.get('/api/get_active_site_maintenances', {
					...requestHeaders.getGetHeader(),
					params: {
						id: task.data.task.SITE_OBJID
					}
				});
				setActiveSiteMaintenances(response.data.activeMaintenanceList);
			} catch (error) {
				console.error(error);
				setActiveSiteMaintenances([]);
			}
		}
		, [task.data.task]);

	useEffect(() => {
		getSiteActiveMaintenances().then(() => {});
	}, [task.data.task]);

	console.log(task);
	return (
		<Paper className={classes.paper}>
			<CustomModal
				fullWidth
				open={open}
				title={getTaskModalTitle()}
				content={
					<Grid container spacing={2}>
						<TaskAction
							classes={classes}
							updateNote={updateNote}
							note={note}
							siteContact={siteContact}
							updateSiteContact={updateSiteContact}
							warranty={warranty}
							updateWarranty={updateWarranty}
							serviceNo={serviceNo}
							updateServiceNo={updateServiceNo}
							sign={{
								imageURL,
								sigCanvas,
								saveSign: () =>
									setImageURL(
										sigCanvas.current
											.getTrimmedCanvas()
											.toDataURL("image/svg+xml")
									),
								clear: () => sigCanvas.current.clear(),
							}}
							language={language}
							tiny={tiny}
							confirm={newStatus === "CLOSED"}
							toHold={newStatus === "ON_HOLD"}
							updateOpt={task.updateOpt}
							opt={task.opt}
							updateTrainee={task.updateTrainee}
							addRemoveTrainee={task.addRemoveTrainee}
							trainees={task.trainees}
							project={task.project}
							showProject={task.showProject}
							updateProject={task.updateProject}
							journal={task.journal}
							showJournal={task.showJournal}
							updateJournal={task.updateJournal}
							taskBill={task.data.BILL_OBJID}
							dispatchers={dispatchers}
							selectDispatcher={(e) =>
								setSelectedDispatcher(e.target.value)
							}
							selectedDispatcher={selectedDispatcher}
							type={type}
							subtype={subtype}
							setType={(e) => setType(e)}
							setSubtype={(e) => setSubtype(e.target.value)}
							teamSize={teamSize}
							setTeamSize={(e) => setTeamSize(e.target.value)}
							journalData={task.journalData}
							updateJournalData={task.updateJournalData}
							deadLine={deadLine}
							updateDeadLine={(value) => setDeadLine(value)}
							pvImage={pvImage}
							contractor={data && data.task && data.task.CONTRACTOR_OBJID ? data.task.CONTRACTOR_OBJID : null}
							loadImage={newStatus === "LOAD_IMAGE" || noNetModal}
							onImageLoad={(blob) => {
								setPvImage(blob);
								setImageUploaded(1);
							}}
							onNotificationOpen={(type, data) =>
								props.onNotificationOpen(type, data)
							}
							imageUploaded={imageUploaded}
							onNextStateChange={(status) => {
								setNewStatus(status);
								if (status === "ON_HOLD") {
									setNewStatusId(4);
								} else if (status === "CLOSED") {
									setNewStatusId(8);
								}
							}}
							newStatusId={newStatusId}
							taskId={props.taskId}
							userId={props.userId}
						/>
					</Grid>
				}
				formValidate
				close={() => setOpen(false)}
				execute={() => {
					task.newTaskAction(
						newStatus === "ASSIGNED" ? 2 : 3,
						newStatus === "ASSIGNED"
							? task.data.task.OWNER_CURR
							: task.data.task.STATUS_ID,
						newStatus === "ASSIGNED" ? null : newStatusId,
						note,
						siteContact,
						imageURL,
						serviceNo,
						warranty,
						task.opt,
						task.trainees,
						{
							data: task.project,
							show: task.showProject,
						},
						{
							data: task.journal,
							show: task.showJournal,
						},
						selectedDispatcher,
						type,
						subtype,
						teamSize,
						deadLine,
						pvImage
					);
					setOpen(false);
					if ((props.siteData.LATITUDE === null || props.siteData.LONGITUDE === null) && (newStatusId === 8 || newStatusId === 4)) {
						setNoGpsWarning(true);
					}
				}}
				language={language}
				validate={
					(task &&
						task.data &&
						task.data.task &&
						["CLOSED", "ON_HOLD"].indexOf(newStatus) === -1) ||
					(note.length > 0 &&
						siteContact.length > 0 &&
						imageURL !== null) ||
					pvImage !== null
				}
			/>
			<NoGpsModal
				language={language}
				open={noGpsWarning}
				close={() => setNoGpsWarning(false)}
			/>
			<UpdateTaskReason
				language={language}
				open={updateReasonModal}
				close={() => setUpdateReasonModal(false)}
				taskId={props.taskId}
				userObj={task.userId}
				refeatchTaskData={() => props.refeatchTaskData()}
				onNotificationOpen={(type, data) =>
					props.onNotificationOpen(type, data)
				}
			/>
			<TaskContract
				open={contractModalStatus}
				language={language}
				close={() => setContractModalStatus(false)}
				siteId={task.data.task ? task.data.task.SITE_OBJID : null}
				userPermissions={userPermissions}
				isAuthenticated={isAuthenticated}
				taskData={task.data.task}
				openNewContract={() => {
					setContractModalStatus(false);
					setNewContractModal(true);
				}}
				onNotificationOpen={(type, data) =>
					props.onNotificationOpen(type, data)
				}
				refeatchTaskData={() => props.refeatchTaskData()}
			/>
			<AddContractModal
				addData={newContractData}
				language={language}
				open={newContractModal}
				close={() => setNewContractModal(false)}
				updateField={(e, length) => updateField(e, length)}
				updateFieldRaw={(field, value) => {
					let placeholder = newContractData;
					placeholder[field] = value;
					setNewContractData({ ...placeholder });
				}}
				validate={
					newContractData &&
					newContractData.CONTRACTOR_OBJID &&
					newContractData.CONTRACTOR_OBJID.length !== "" &&
					newContractData.CONTRACT_NO &&
					newContractData.CONTRACT_NO.length !== "" &&
					newContractData.START_DATE &&
					newContractData.START_DATE.length !== "" &&
					(newContractData.INDETERMINATE ||
						(newContractData.END_DATE &&
							newContractData.END_DATE.length !== "")) &&
					newContractData.VALUE &&
					newContractData.VALUE.length !== "" &&
					newContractData.CONTRACT_ASSIGNEE &&
					newContractData.CONTRACT_ASSIGNEE !== ""
				}
				addContract={async () => {
					props.addCustomerContract(newContractData);
				}}
			/>
			<ActivityModalIndicator open={props.isUploadingFile} />
			<TaskResetModal
				open={resetOwnerModal}
				note={note}
				updatenote={(e) => updateNote(e)}
				close={() => setResetOwnerModal(false)}
				language={language}
				classes={classes}
				resetTask={() => {
					task.resetTaskAction(3, task.data.task.STATUS_ID, 4, note);
					setResetOwnerModal(false);
					console.log("Reset");
				}}
			/>
			<UploadFiles
				open={commentFileUploadModal}
				close={() => setCommentFileUlpoadModal(false)}
				execute={(base64File) => setUploadedFile(base64File)}
				language={props.language}
			/>
			{props.task.loading ? (
				<LinearProgress />
			) : (
				<React.Fragment>
					<CustTaskDetails
						data={data.task}
						language={language}
						cust={props.cust}
						tiny={tiny}
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
					/>
					<SiteInfo
						data={props.siteData}
						language={language}
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
					/>

					{activeSiteMaintenances.map(maintenance => {
						return <div style={{
							width: "100%",
							border: "2px solid red",
							display: "flex",
							flexDirection: "column",
							alignContent: "center",
							padding: "15px",
							borderRadius: "20px",
							margin: "20px 0px"
						}}>
							<Typography variant="h4" style={{ color: "red", alignSelf: "center", margin: "10px" }}>{`Există mentenanță activă pentru acest obiectiv.`}</Typography>
							<Typography variant="h5" style={{ color: "red", fontWeight: "bold", marginBottom: "10px" }}>Detalii Mentenanță:</Typography>
							<Typography variant="h5" style={{ marginBottom: "5px", marginLeft: "20px" }}>{`Mentenanța cu numărul ${maintenance.OBJID} creată în data de ${moment(maintenance.CREATE_TIME).format("DD-MM-YYYY")}`}</Typography>
							<Typography variant="h5" style={{ marginBottom: "5px", marginLeft: "20px" }}>{`Tipul mentenanței: ${maintenance.MAINTENACE_TYPE}`}</Typography>
							<Typography variant="h5" style={{ marginBottom: "10px", marginLeft: "20px" }}>{`Motivul mentenanței: ${maintenance.REASON}`}</Typography>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => redirectTo("/maintenance")}
								style={{
									margin: "5px",
									borderRadius: "20px"
								}}
							>
								Mentenanțe active
							</Button>
						</div>
					})}
                    <SiteImageModal siteId={props.siteData.OBJID}/>
					<TaskInfo
						type={props.inter ? "INTERVENTION" : "TASK"}
						classes={classes}
						task={task}
						data={data && data.task}
						language={language}
						tiny={tiny}
						isSm={isSm}
					/>
					<Grid
						item
						xs={12}
						container
						direction="row"
						spacing={4}
						className={classes.buttonGroup}
					>
						<TaskButtons
							language={language}
							tiny={tiny}
							task={task}
							openAction={openDialog}
							userPermissions={userPermissions}
							isAuthenticated={isAuthenticated}
							openContract={() => setContractModalStatus(true)}
							openBilling={() => setBillingModalStatus(true)}
							taskData={task.data.task}
							openResetTask={() => setResetOwnerModal(true)}
							openBillingModal={() => setBillingModalStatus(true)}
							openUpdateReason={() => setUpdateReasonModal(true)}
							openRegisterProgress={() => openRegisterProgress()}
						/>
					</Grid>
					<CommentList
						downloadFile={props.downloadFile}
						classes={classes}
						task={task}
						data={data && data.history}
						language={language}
						tiny={tiny}
						isIntervention={false}
						openFileUploadModal={() =>
							setCommentFileUlpoadModal(true)
						}
						uploadedFile={uploadedFile}
					/>
				</React.Fragment>
			)}
		</Paper>
	);
}

export default function TaskModal(props) {
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const isSm = useMediaQuery(theme.breakpoints.down("sm"));
	const {
		language,
		open,
		close,
		taskId,
		task,
		userPermissions,
		isAuthenticated,
		user
	} = props;

	const [registerInventory, setRegisterInventory] = useState(false);

	React.useEffect(() => {
		if (taskId !== null) task.fetchData();
	}, [taskId]);

	return (
		<>
			<CustomModal
				key={"hello"}
				fullScreen
				open={open}
				title={`#${taskId} - ${lang.TASK[language]}`}
				content={
					<Grid container spacing={2}>
						{open && (
							<TaskDetails
								isUploadingFile={props.isUploadingFile}
								siteData={props.siteData}
								downloadFile={task.downloadFile}
								task={task}
								data={task.data}
								language={language}
								taskId={taskId}
								userId={user.id}
								tiny={tiny}
								isSm={isSm}
								cust={props.cust}
								userPermissions={userPermissions}
								isAuthenticated={isAuthenticated}
								addCustomerContract={(data) =>
									props.addCustomerContract(data)
								}
								onNotificationOpen={(data, message) =>
									props.onNotificationOpen(data, message)
								}
								refeatchTaskData={() => task.fetchData()}
								openRegisterProgress={() => setRegisterInventory(true)}
							/>
						)}
					</Grid>
				}
				close={close}
				language={language}
				execute={() => console.log("here")}
				noButtons
				onlyBack
			/>
			<RegisterInventory
				open={registerInventory}
				onClose={async () => {
					setRegisterInventory(false);
					await task.fetchData();
				}}
				language={language}
				taskId={task?.data?.task?.OBJID}
				contractorId={task && task.data && task.data.task ? task.data.task.CONTRACTOR_OBJID : null}
				userId={task.userId}
				type={"TASK"}
			/>
		</>
	);
}
