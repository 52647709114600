export const CLIENTS = {
    "en": "Clients",
    "ro": "Clienți",
};

export const ACTIVE_CLIENTS = {
    "en": "Active clients",
    "ro": "Clienți activi",
};

export const INACTIVE_CLIENTS = {
    "en": "Inactive clients",
    "ro": "Clienți inactivi",
};

export const VALIDATE_CLIENTS = {
    "en": "Valids clients",
    "ro": "Clienți validați",
};
export const NOVALIDATE_CLIENTS = {
    "en": "Novalid clients",
    "ro": "Clienți nevalidați",
};

export const ID = {
    "en": "ID",
    "ro": "Nr.",
};
export const NAME = {
    "en": "Name",
    "ro": "Denumire",
};
export const CUI = {
    "en": "CRN",
    "ro": "CUI",
}
export const CNP = {
    "en": "CNP",
    "ro": "CNP",
}
export const NR_REG = {
    "en": "Tr. Reg. No.",
    "ro": "Nr. Reg. Com.",
}
export const ADDRESS = {
    "en": "Address",
    "ro": "Adresă",
};
export const ADD_ADDRESS = {
    "en": "Add address",
    "ro": "Adăugare adresă",
};
export const ADMINISTRATOR = {
    "en": "Administrator",
    "ro": "Administrator",
};
export const CLIENTS_SEARCH = {
    "en": "Search clients",
    "ro": "Căutare clienți",
};
export const SEARCH_CLIENT_BTN_NAME = {
    "en": "Search",
    "ro": "Căutare",
};
export const ADD_CLIENT_BTN_NAME = {
    "en": "ADD",
    "ro": "Adăugare",
};
export const ADD_BTN_NAME = {
    "en": "Add Client",
    "ro": "Adăugare client",
};
export const CLIENT_TYPE_PF = {
    "en": "Customer (Individual)",
    "ro": "Client (Personă fizică)",
};
export const CLIENT_TYPE_PJ = {
    "en": "Customer (Legal entity)",
    "ro": "Client (Personă juridică)",
};
export const CUST_TYPE = {
    "en": "Type",
    "ro": "Tip",
}
export const ADD_CUSTOMER = {
    "en": "Add customer",
    "ro": "Adăugare client",
}
export const EDIT_CUSTOMER = {
    "en": "Edit customer",
    "ro": "Editare client",
}
export const CUSTOMER_DETAILS = {
    "en": "Customer details",
    "ro": "Detalii client",
}
export const CUSTOMER = {
    "en": "Customer",
    "ro": "Client",
}
export const COD = {
    "en": "Code",
    "ro": "Account",
};
export const VALIDATION = {
    "en": "Validation",
    "ro": "Validare",
};
export const NR_PHONE = {
    "en": "Phone number",
    "ro": "Număr de telefon",
};
export const PERSON_OF_CONTACT = {
    "en": "Contact person",
    "ro": "Personă de contact",
};
export const COMPANY_NAME = {
    "en": "Company name",
    "ro": "Numele companiei",
};
export const OBJECTIV_LIST = {
    "en": "Objective list",
    "ro": "Listă obiective",
};
export const SAVE_MODIFICATIO = {
    "en": "Save modification",
    "ro": "Salvează modificările",
};
export const DELETE_OBJ = {
    "en": "DELETE OBJECTIV",
    "ro": "Șterge obiectivul",
};
export const GPS = {
    "en": "GPS Position",
    "ro": "Poziție GPS",
};
export const LOCALIZATION = {
    "en": "Localization",
    "ro": "Localizare",
};
export const TIMETABLE = {
    "en": "Timetable",
    "ro": "Orar",
};
export const PHONE_OBJECTIV = {
    "en": "Objectiv phone nr.",
    "ro": "Nr. telefon obiectiv",
};

export const TRADE_NAME = {
    "en": "Trade name",
    "ro": "Denumire comercială"
};
export const CONTACT_PERSON = {
    "en": "Contact person",
    "ro": "Persoană contact",
};
export const IDENTIFICATION_COD = {
    "en": "Identification cod",
    "ro": "Cod identificare",
};
export const CUSTOMER_CODE = {
    "en": "Customer code",
    "ro": "Account",
};
export const CUSTOMER_SITES = {
    "en": "Customer sites",
    "ro": "Obiective client"
};
export const CUSTOMER_INTERVENTIONS = {
    "en": "Customer interventions",
    "ro": "Intervenții client"
};
export const GHANGE_USER_STATUS = {
    "en": "Change user status",
    "ro": "Activare / Deazactivare client"
};
export const SEND_EMAIL = {
    en: "Send email",
    ro: "Trimite email"
};
