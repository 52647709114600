import React from 'react';
import { makeStyles, Grid, Box, Paper, Typography, Grow, Chip, Link, useTheme, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PhoneRounded, EmailRounded, FaceRounded, BusinessRounded, BusinessCenterRounded, AccountCircleRounded, FormatListNumberedRounded } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import { redirectTo } from '../../../Functions/linkFunctions';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
        height: '100%',
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
    title: {
        margin: theme.spacing(3),
    },
    subtitle: {
        margin: theme.spacing(1),
    },
    links: {
        textDecoration: "none",
        color: 'white',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    link: {
        textDecoration: "none",
        color: "inherit",
        cursor: "pointer",
    }
}));
export default function SiteCust(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { data, language, tiny, cust } = props;

    React.useEffect(() => {
        cust.initialLoad();
    }, []);

    return (
        <React.Fragment>
            {cust.loading
                ?
                <SkeletonSiteCust classes={classes} />
                :
                <Grid item xs={12} md={4} style={{ paddingRight: '0px', transition: theme.transitions.create("all", { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen, }) }} >
                    <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                        <Paper className={classes.paper} >
                            <Grid container justify="center" alignItems="center" direction="column">
                                <Grid container justify="center" alignItems="center" direction="row">
                                    {data && <Tooltip title={data && data.IS_ACTIVE === 1 ? "Activ" : "Inactiv"}>
                                        <Typography className={classes.title} variant="h5" color={data && data.IS_ACTIVE === 1 ? "inherit" : "secondary"}>
                                            <Link underline="none" className={classes.link} onClick={() => redirectTo(`customer?id=${data.ID}`)}>
                                                {data.NAME}
                                            </Link>
                                        </Typography>
                                    </Tooltip>}
                                </Grid>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    {data && data.CUSTOMER_CODE && data.TYPE &&
                                        <Chip className={classes.subtitle} icon={data.TYPE === "PF" ? <FaceRounded fontSize="small" /> : <BusinessRounded fontSize="small" />} color="secondary" label={`${lang["CLIENT_TYPE_" + data.TYPE][language]} #${data.CUSTOMER_CODE}`} />}
                                    {data && data.PHONE &&
                                        <Chip className={classes.subtitle} icon={<PhoneRounded fontSize="small" />} color="primary" label={<a className={classes.links} href={`tel:${data.PHONE}`}>{data.PHONE}</a>} />}
                                    {data && data.EMAIL &&
                                        <Chip className={classes.subtitle} icon={<EmailRounded fontSize="small" />} color="primary" label={<a className={classes.links} href={`mailto:${data.EMAIL}`}>{data.EMAIL}</a>} />}
                                </Grid>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    {data && data.TYPE === "PJ" && data.CNP_CUI &&
                                        <Chip className={classes.subtitle} icon={<BusinessCenterRounded fontSize="small" />} color="primary" label={lang.CUI[language] + ": " + data.CNP_CUI} />}
                                    {data && data.TYPE === "PJ" && data.NR_REG &&
                                        <Chip className={classes.subtitle} icon={<FormatListNumberedRounded fontSize="small" />} color="primary" label={lang.NR_REG[language] + ': ' + data.NR_REG} />}
                                    {data && data.TYPE === "PJ" && data.ADMINISTRATOR &&
                                        <Chip className={classes.subtitle} icon={<AccountCircleRounded fontSize="small" />} color="primary" label={lang.ADMINISTRATOR[language] + ': ' + data.ADMINISTRATOR} />}
                                    {data && data.TYPE === "PF" && data.CNP_CUI &&
                                        <Chip className={classes.subtitle} icon={<FormatListNumberedRounded fontSize="small" />} color="primary" label={lang.CNP[language] + ": " + data.CNP_CUI} />}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grow>
                </Grid>
            }
        </React.Fragment>
    );
}

export function SkeletonSiteCust(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={4} style={{ paddingRight: '0px' }} >
            <Skeleton component={Paper} className={classes.paperClear}>
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="15%" className={classes.title} />
                    <Grid container justify="center" alignItems="center" direction="column">
                        <Skeleton className={classes.subtitle} width='50%' />
                        <Skeleton className={classes.subtitle} width='50%' />
                    </Grid>
                </Grid>
            </Skeleton>
        </Grid>
    );
}