// This componet will display the number for free intervention for a month and will display the number of intervention
// Will show the number of intervention that exced the free intervention and will diplay the cost
import React from 'react';
import { useTheme, makeStyles } from '@material-ui/styles';
import { Grid, Typography, Divider, useMediaQuery, MenuItem, Chip, TextField } from '@material-ui/core';
import { } from '@material-ui/icons';
import { formatDateFromDb, getRangeOfYears, getDateInterval, addMonthToDate } from '../../../Functions/dateFunctions';
import * as lang from '../../../Constants/strings';
import billingDates from '../../../Constants/billingDatesObject';

const useStyles = makeStyles(theme => ({
    margin: {
        width: 'auto'
    },
    dateSelect: {
        margin: theme.spacing(1)
    },
    dividerStyle: {
        margin: theme.spacing(1, 1, 1, 1)
    },
    subtitle: {
        margin: theme.spacing(1)
    },
    textStyle: {
        margin: theme.spacing(1, 1, 1, 1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    chipStyle: {
        margin: theme.spacing(1)
    }
}));

export default function InterventionCount(props) {
    const theme = useTheme();
    const classes = useStyles();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const { language, contractData } = props;

    const [years, setYears] = React.useState(getRangeOfYears(2020));
    const [selectedYear, setSelectedYear] = React.useState(getRangeOfYears(2020)[0]);
    const [dateOption, setDateOption] = React.useState(billingDates[contractData.BILL_CYCLE_DESC]);
    const [selectedDate, setSelectedDate] = React.useState(dateOption[0]);
    const [interventionsCount, setInterventionCount] = React.useState({ free: 0, done: 0, extra: 0, cost: 0 });

    React.useEffect(() => {
        if (props.contractData !== null) {
            setDateOption(billingDates[props.contractData.BILL_CYCLE_DESC]);
            setSelectedDate(billingDates[props.contractData.BILL_CYCLE_DESC][0]);
        }
    }, [props.contractData]);

    React.useEffect(() => {
        let date;
        if (props.contractData.BILL_CYCLE_DESC === "BILL_CURRENT_MONTH") {
            date = getDateInterval(selectedDate.startMonth, selectedYear, selectedDate.endMonth);
        } else {
            let contractStartDate = new Date(props.contractData.START_DATE);
            contractStartDate.setFullYear(selectedYear);
            date = {
                startDate: addMonthToDate(contractStartDate, Number(selectedDate.startMonth)),
                endDate: addMonthToDate(contractStartDate, Number(selectedDate.endMonth) + 1)
            };
        }

        let startDate = formatDateFromDb(date.startDate, 15);
        let endDate = formatDateFromDb(date.endDate, 15);

        fetch(`/api/get_site_intervention_count?contractId=${contractData.OBJID}&startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                // setting the intervention object
                if (response.data && response.data !== null) {
                    let placeholder = { ...interventionsCount };
                    placeholder.free = contractData.FREE_TRUCK_ROLLS;
                    placeholder.done = response.data.numberOfInterventions;
                    placeholder.extra = placeholder.done > placeholder.free ? Number(placeholder.done) - Number(placeholder.free) : 0;
                    placeholder.cost = Number(placeholder.extra) * Number(contractData.TRUCK_ROLL_VALUE);
                    setInterventionCount(placeholder);
                } else {
                    let placeholder = { ...interventionsCount };
                    placeholder.free = contractData.FREE_TRUCK_ROLLS;
                    placeholder.done = 'No response';
                    placeholder.extra = 'No response';
                    placeholder.cost = `No response`;
                    setInterventionCount(placeholder);
                }
            })
    }, [selectedDate, selectedYear]);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.textStyle} variant='h5'>{lang.ADDITIONAL_INTERVENTIONS[language]}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerStyle} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={classes.formControl}
                    select
                    fullWidth
                    variant='outlined'
                    label={lang.YEAR[language]}
                    value={selectedYear}
                    onChange={e => setSelectedYear(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                >
                    {
                        years.map(year => {
                            return <MenuItem key={year} value={year}>{year}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={classes.formControl}
                    select
                    fullWidth
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    label={lang.PERIOD[language]}
                    value={selectedDate}
                    onChange={e => setSelectedDate(e.target.value)}
                >
                    {
                        dateOption.map(date => {
                            return <MenuItem key={date.name[language]} value={date}>{date.name[language]}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerStyle} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.textStyle} variant='h5'>{`${lang.ADDITIONAL_INTERVENTIONS_SITUATION_FOR[language]} ${selectedDate.name[language]} ${selectedYear}`}</Typography>
            </Grid>
            <Grid container direction='row'>
                <Grid >
                    <Chip className={classes.chipStyle} label={`${lang.FREE_TRUCK_ROLLS[language]}: ${interventionsCount.free}`} color='primary'></Chip>
                </Grid>
                <Grid >
                    <Chip className={classes.chipStyle} label={`${lang.DONE_INTERVENTIONS[language]}: ${interventionsCount.done}`} color='primary'></Chip>
                </Grid>
                <Grid >
                    <Chip className={classes.chipStyle} label={`${lang.ADDITIONAL_INTERVENTIONS[language]}: ${interventionsCount.extra}`} color='secondary'></Chip>
                </Grid>
                <Grid >
                    <Chip className={classes.chipStyle} label={`${lang.INTERVENTION_EXTRA_COST[language]}: ${interventionsCount.cost} RON`} color='secondary'></Chip>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
