import { combineReducers } from "redux";
import authReducer from './authReducer';
import configReducer from './configReducer';
import userReducer from './userReducer';
import nomenclatureReducer from './nomenclatureReducer';
import custReducer from './custReducer';
import siteReducer from './siteReducer';
import taskReducer from './taskReducer';
import serverPaginationReducer from './serverPaginationReducer';

export const appReducer = combineReducers({
    auth: authReducer,
    config: configReducer,
    user: userReducer,
    nom: nomenclatureReducer,
    cust: custReducer,
    site: siteReducer,
    task: taskReducer,
    sp: serverPaginationReducer,
});