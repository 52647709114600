import React, {useEffect, useState} from "react";
import {
    makeStyles,
    Grid,
    Button,
    Fab,
    MenuItem,
    Menu,
    Typography,
    Paper,
    Grow,
} from "@material-ui/core";
import {
    AddRounded,
    VisibilityRounded,
    DescriptionRounded,
    LocalShippingRounded,
    AssignmentLateRounded,
    PhoneAndroidRounded,
    AccountTree,
    Warning
} from "@material-ui/icons";

import CustomDrawer from "../../../Components/Modals/CustomDrawer";
import Skeleton from "@material-ui/lab/Skeleton";
import GroupButtonSet from "../../../Components/Misc/GroupButtonSet";

import {checkPermissions} from "../../../Functions/permissionHide";
import * as lang from "../../../Constants/strings";
import axios from "axios";
import {getGetHeader} from "../../../Constants/requestHeaders";

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    float: {
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 99,
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.5),
    },
    paperClear: {
        padding: theme.spacing(2),
        height: "100%",
    },
}));

function SiteAddMenu(props) {
    const {anchorEl, open, handleClose, list, close} = props;
    return (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {list &&
                list
                    .filter((e) => e.render === true)
                    .map((e, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                e.action();
                                close();
                            }}
                        >
                            <Typography>{e.leftIcon}</Typography>
                            <Typography>{e.primary}</Typography>
                        </MenuItem>
                    ))}
        </Menu>
    );
}

export default function SiteButtons(props) {
    const classes = useStyles();
    const {
        language,
        tiny,
        site,
        contract,
        task,
        intervention,
        userPermissions,
        isAuthenticated,
        setProjectAddModal,
        setEvaluationAddModal = () => {
        },
        setInstallationAddModal,
        siteId
    } = props;
    const [anchorEl, setAnchor] = useState(null);
    const [addDrawer, setAddDrawer] = useState(false);
    const [hasImage, setHasImage] = useState(true);

    function closeMenu() {
        setAnchor(null);
    }

    function openMenu(e) {
        if (tiny) {
            setAddDrawer(true);
            setAnchor(null);
        } else {
            setAddDrawer(false);
            setAnchor(e.currentTarget);
        }
    }

    const checkIfSiteHasImage = async (id) => {
        try {
            const res = await axios.get('api/site/has-image', {
                ...getGetHeader(),
                params: {
                    id,
                }
            });
            setHasImage(res.data.has);
        } catch (e) {
            console.log(e);
        }
    }


    const menuList = [
        {
            primary: lang.ADD_CONTRACT[language],
            leftIcon: <DescriptionRounded className={classes.menuIcon}/>,
            icon: <DescriptionRounded/>,
            action: contract.openAddContractModal,
            render:
                isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "CONTRACTS",
                    name: "ADD_CONTRACT",
                }),
        },
        {
            primary: lang.NEW_ALARM[language],
            leftIcon: <LocalShippingRounded className={classes.menuIcon}/>,
            icon: <LocalShippingRounded/>,
            action: intervention.openAddInterventionModal,
            render:
                isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "INTERVENTIONS",
                    name: "ADD_INTERVENTION",
                }),
        },
        {
            primary: lang.ADD_TASK[language],
            leftIcon: <AssignmentLateRounded className={classes.menuIcon}/>,
            icon: <AssignmentLateRounded/>,
            action: task.openAddTaskModal,
            render:
                isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "TASKS",
                    name: "ADD_TASK",
                }),
        },
        {
            primary: lang.NEW_MAINTENANCE[language],
            leftIcon: <AssignmentLateRounded className={classes.menuIcon}/>,
            icon: <AssignmentLateRounded/>,
            action: task.openAddMaintenaceModal,
            render:
                isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "MAINTENANCE",
                    name: "ADD_MAINTENANCE",
                }),
        },
        {
            primary: lang.NEW_PHONE_NOTIFICATION[language],
            leftIcon: <PhoneAndroidRounded className={classes.menuIcon}/>,
            icon: <PhoneAndroidRounded/>,
            action: props.addSitePhoneWarning,
            render:
                isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "INTERVENTIONS",
                    name: "ADD_PHONE_WARNING",
                }),
        },
        {
            primary: lang.NEW_PROJECT[language],
            leftIcon: <AccountTree className={classes.menuIcon}/>,
            icon: <AccountTree/>,
            action: () => setProjectAddModal(true),
            render: isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "PROJECTS",
                    name: "ADD_PROJECTS",
                }),
        },
        {
            primary: lang.NEW_RISK_ASSESSMENT[language],
            leftIcon: <Warning className={classes.menuIcon}/>,
            icon: <Warning/>,
            action: () => setEvaluationAddModal(true),
            render: isAuthenticated &&
                checkPermissions(userPermissions, {
                    parent: "RISC_EVALUATIONS",
                    name: "ADD_RISC_EVALUATIONS",
                }),
        },
        {
            primary: lang.NEW_INSTALLATION[language],
            leftIcon: <Warning className={classes.menuIcon}/>,
            icon: <Warning/>,
            action: () => setInstallationAddModal(true),
            render: isAuthenticated &&
                !checkPermissions(userPermissions, {
                    parent: "INSTALLATIONS",
                    name: "ADD_INSTALLATIONS",
                }),
        },
    ];

    useEffect(() => {
        console.log(siteId)
        if (siteId) checkIfSiteHasImage(siteId);
    }, [siteId]);

    return (
        <>
            <CustomDrawer
                open={addDrawer && tiny}
                close={() => setAddDrawer(false)}
                list={menuList}
            />
            {site.loading ? (
                <SkeletonSiteButtons classes={classes}/>
            ) : (
                <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    lg={6}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{paddingRight: "0px"}}
                >
                    {tiny ? (
                        <Grow in={true} style={{transitionDelay: "100ms"}}>
                            <Fab
                                className={classes.float}
                                color="secondary"
                                onClick={openMenu}
                                disabled={!menuList.some((item) => item.render === true)}
                            >
                                <AddRounded/>
                            </Fab>
                        </Grow>
                    ) : (
                        <Grow in={true} style={{transitionDelay: "100ms"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.leftIcon}
                                onClick={openMenu}
                                disabled={!menuList.some((item) => item.render === true)}
                            >
                                <AddRounded className={classes.leftIcon}/>
                                {lang.NEW[language]}
                            </Button>
                        </Grow>
                    )}

                    { hasImage && <a href={"/site-image-viewer/" + siteId} style={{textDecoration: "none", color: "#fff"}}
                                     target={"_blank"}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.leftIcon}
                        >
                            {lang.GO_TO_THE_SITE_IMAGE[language]}
                        </Button>
                    </a> }

                    <SiteAddMenu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && !tiny}
                        close={closeMenu}
                        handleClose={closeMenu}
                        list={menuList}
                    />
                    <GroupButtonSet
                        tiny={tiny}
                        language={language}
                        style={{
                            size: "medium",
                            color: "primary",
                            variant: "contained",
                            disabled: false,
                        }}
                        list={[
                            {
                                primary: true,
                                text: lang.ACTIVE_CONTRACTS[language],
                                icon: <VisibilityRounded/>,
                                render: isAuthenticated && checkPermissions(userPermissions, {
                                    parent: "CONTRACTS",
                                    name: "VIEW_CONTRACT",
                                }),
                                action: () => {
                                    contract.openActiveContractsModal();
                                },
                            },
                        ]}
                    />
                </Grid>
            )}
        </>
    );
}

export function SkeletonSiteButtons(props) {
    const {classes} = props;
    return (
        <Grid item xs={12} md={8} style={{paddingRight: "0px"}}>
            <Skeleton component={Paper} className={classes.paperClear}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                ></Grid>
            </Skeleton>
        </Grid>
    );
}

