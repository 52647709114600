// This componet will be use in the TaskModal to display the site details
import React from 'react';
import { Grid, Link, Chip, makeStyles, Divider, Typography } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import { PhoneRounded, EmailRounded, LocationOn, ReceiptRounded } from '@material-ui/icons';
import { addressToString } from '../../../Functions/objectManipulation';
import { redirectTo } from "../../../Functions/linkFunctions";
import { checkPermissions } from "../../../Functions/permissionHide";
import getGoogleMapLink, { getGoogleMapRouteLink } from "../../../Functions/getGoogleMapLink";

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-01.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
        height: '100%',
    },
    paperCust: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        padding: theme.spacing(2),
        height: '100%',
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
    links: {
        textDecoration: "none",
        color: 'white',
    },
    title: {
        margin: theme.spacing(3),
    },
    subtitle: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    accountBadge: {
        margin: theme.spacing(1),
        fontSize: '20px',
        padding: '5px',
        height: '50px',
        borderRadius: '25px'
    },
}));

export default function SiteInfo(props) {
    const { data, language } = props;
    const classes = useStyles();
    console.log("Site info: ", data);

    return (
        <React.Fragment>
            <Grid container justify="flex-start" alignItems="center" direction="column">
                <Grid container justify="flex-start" alignItems="center" direction="row">
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "SITE", name: "VIEW_SITE" }) ?
                                <Link color="textPrimary" onClick={() => redirectTo(`/site?id=${data.OBJID}`)}>
                                    {`${lang.SITE_DETAILS[language]}: ${data && data.SITE_NAME}`}
                                </Link>
                                :
                                `${lang.SITE_DETAILS[language]}: ${data && data.SITE_NAME}`
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {
                        data &&
                        <Chip className={classes.accountBadge} color="secondary" label={`${lang.SITE[language]} #${data.SITE_CODE}`} />
                    }
                    {
                        data &&
                        <Chip className={classes.subtitle} color="secondary" label={`${lang.NAME[language]}: ${data.SITE_NAME}`} />
                    }
                    {
                        data && data.EMAIL &&
                        <Chip className={classes.subtitle} icon={<EmailRounded fontSize="small" />} color="primary" label={<a className={classes.links} href={`mailto:${data.EMAIL}`}>{data.EMAIL}</a>} />
                    }
                    {
                        data &&
                        <Chip className={classes.subtitle} color="primary" label={`${lang.SITE_CONTACT_NAME[language]}: ${data.SITE_CONTACT_NAME} `} />
                    }
                    {
                        data && data.PHONE &&
                        <Chip className={classes.subtitle} icon={<PhoneRounded fontSize="small" />} color="primary" label={<a className={classes.links} href={`tel:${data.CONTACT_PHONE}`}>{`${lang.CONTACT_PHONE[props.language]}: ${data.CONTACT_PHONE}`}</a>} />
                    }
                    {
                        data && data.LATITUDE && data.LONGITUDE &&
                        <Chip className={classes.subtitle} icon={<LocationOn fontSize="small" />} color="primary" label={<a className={classes.links} target="_blank" href={`${getGoogleMapLink(data.LATITUDE, data.LONGITUDE)}`}>{lang.GPS[language]}</a>} />
                    }
                    {
                        data &&
                        <Chip className={classes.subtitle} icon={<ReceiptRounded fontSize="small" />} color="secondary" label={<a className={classes.links} target="_blank" href={`${getGoogleMapRouteLink(addressToString(data, 3))}`}>{addressToString(data, 1)}</a>} />
                    }
                    {
                        data && data.SITE_LANDMARKS &&
                        <Chip className={classes.subtitle} color="primary" label={`${lang.SITE_LANDMARKS[language]}: ${data.SITE_LANDMARKS} `} />
                    }
                    {
                        data && data.OBS &&
                        <Chip className={classes.subtitle} color="primary" label={`${lang.OBSERVATION[language]}: ${data.OBS} `} />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
