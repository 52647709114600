import React, { useEffect, useState } from "react";
import axios from "axios";
import * as requestHeaders from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import * as lang from "../../../Constants/strings";
import { user_activity_task } from "../../../Components/Misc/Tables/TableHeaders";
import { useDispatch } from "react-redux"
import SortBar from './SortBar';
import TaskCommentHistory from "../../../Components/Modals/TaskCommentHistory";

const ActivityReportsTask = (props) => {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const [commentsHistoryModal, setCommnetsHistoryModal] = useState(false);
    const [history, setHistory] = useState([]);
    const [taskId, setTaskId] = useState(null);

    const getUserTaskActivity = async ({ startDate, endDate, selectedUser }) => {
        const response = await axios.get('/api/get_user_task_actions', {
            ...requestHeaders.getGetHeader(),
            params: {
                startDate: startDate,
                endDate: endDate,
                id: selectedUser
            }
        });
        if (response.status === 200 || response.status === 204) {
            setData(response.data.data);
        } else {
            setData([]);
        }
    };

    const getTaskHistory = async ({ id }) => {
        const response = await axios.get('/api/task_history', {
            ...requestHeaders.getGetHeader(),
            params: {
                id
            }
        });

        if (response.status === 200 || response.status === 204) {
            setHistory(response.data.history);
        } else {
            setHistory([]);
        }
    };

    const downloadAction = ({ startDate, endDate, selectedUser }) => {
        fetch(`/api/download_activity_report_task?startDate=${startDate}&endDate=${endDate}&id=${selectedUser}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                },
                method: 'GET',
                responseType: 'arraybuffer',
            }
        )
            .then(response => response.blob())
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = `Activitate_desfasurata_tehnic.xlsx`;
                link.click();
            })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        if (taskId !== null)
            getTaskHistory({ id: taskId })
    }, [taskId]);

    return (
        <>
            <TaskCommentHistory
                open={commentsHistoryModal}
                tiny={true}
                classes={{}}
                data={history}
                language={language}
                close={() => {
                    setCommnetsHistoryModal(false);
                }}
            />
            <SortBar
                language={language}
                filter={getUserTaskActivity}
                onDownload={downloadAction}
                usersDepartment={12}
            />
            <MaterialTable
                tiny={true}
                title="Raport activitate tehnic"
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={user_activity_task(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={async (id) => {
                    await setTaskId(id);
                    await setCommnetsHistoryModal(true);
                }}
                clickLabel="TASK_ID"
                hideAddBtn={true}
            />
        </>
    )
}

export default ActivityReportsTask;