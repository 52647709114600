import {Button, CssBaseline, Grid, Paper, TextField} from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";

import * as actionsCreators from "../../actions/actions";
import * as lang from "../../Constants/strings";
import MaterialTableSimple from "../../Components/Misc/Tables/MaterialTableSimple";
import {small_tasks_view, tasks_view, technicalUserHistory} from "../../Components/Misc/Tables/TableHeaders";
import React, {useEffect, useState} from "react";
import {getGetHeader} from "../../Constants/requestHeaders";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import SetSingleScore from "./Modals/SetSingleScore";
import ScoringModal from "../../Components/TechnicalUser/ScoringModal";

const UserScoreView = (props) => {
  const {
    language
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [history, setHistory] = useState([]);

  const [selectedTask, setSelectedTask] = useState(null);
  const [scoreModal, setScoreModal] = useState(false);

  const getHistory = async (id) => {
    if(new Date(startDate) > new Date(endDate)) {
      alert("Interval gresit. Data de incepere interval este mai mare ca data incheiere interval!")
      return;
    }
      try {
        const res = await axios.get('/api/technical/history/' + id, {
          ...getGetHeader(),
          params: {
            startDate,
            endDate
          }
        });

        const formatedHistory = [];

        for (const c of res.data.history) {
          formatedHistory.push({
            ...c,
            CLOSING_STATUS_t: c.CLOSING_STATUS === "CLOSE" ? "Intervenție închis" : "Intervenție amânat",
            createdAt_f: moment(c.CREATE_AT).format("DD-MM-YYYY HH:mm")
          })
        }

        setHistory(formatedHistory);
      } catch (e) {
        console.log(e);
      }
  }

  const getTechnicalUsers = async () => {
    try {
      const res = await axios.get('/api/users', {
        ...getGetHeader(),
        params: {
          department: 12 // 12 is the technical department
        },
      });

      const data = []

      for(const u of res.data.data.userList) {
        if(u.LOCKED !== 1) {
          data.push(u);
        }
      }

      setUsers(data);
    } catch (e) {
      console.log(e);
    }
  }

  const downloadExcel = async () => {
    const formatedStartDate = moment(startDate).format("DD-MM-YYYY");
    const formatedEndDate = moment(endDate).format("DD-MM-YYYY");
    fetch(`/api/technical/user-score/excel/${selectedUser.USER_ID}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'authorization': localStorage.token,
        },
        method: 'GET',
        responseType: 'arraybuffer',
      }
    )
      .then(response => response.blob())
      .then(response => {
        const file = new Blob(
          [response],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = `Raport - ${selectedUser.USERNAME} ${formatedStartDate}-${formatedEndDate}.xlsx`;
        link.click();
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getTechnicalUsers();
  }, []);

  useEffect(() => {
    if(selectedUser) {
      getHistory(selectedUser.USER_ID);
    }
  }, [startDate, endDate, selectedUser])

  return (
    <Grid container style={{ paddingLeft: '30px', paddingRight: '30px', marginTop: "100px" }}>
      <CssBaseline/>
      <Paper style={{ padding: "10px", width: "100%", marginBottom: "10px" }}>
        <Grid container justify='space-between' spacing={2}>
          <Grid xs={12} md={5}>
            <Autocomplete
              id="combobox-user"
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={lang.TECHNICAL_USER[language]}
                  variant="outlined"
                />}
              options={users}
              getOptionLabel={(option) => option.USERNAME}
              onInputChange={(t, v) => {
                if(v) {
                  const findIndex = users.findIndex(u => u.USERNAME === v);
                  if(findIndex !== -1) {
                    setSelectedUser(users[findIndex]);
                  } else {
                    setSelectedUser(undefined);
                    setHistory([]);
                  }
                } else {
                  setHistory([]);
                }
              }}
            />
          </Grid>
          <Grid xs={12} md={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                required
                inputVariant="outlined"
                fullWidth
                label={lang.START_INTERVAL_DATE[language]}
                format="dd-MM-yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                value={startDate}
                onChange={setStartDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={12} md={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                required
                inputVariant="outlined"
                fullWidth
                label={lang.LAST_INTERVAL_DATE[language]}
                format="dd-MM-yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                value={endDate}
                onChange={setEndDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={!selectedUser}
              onClick={() => downloadExcel()}
            >
              {lang.DOWNLOAD_EXCEL[language]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <MaterialTableSimple
        tiny={false}
        title={lang.TECHNICAL_USER_SCORE[language]}
        language={language}
        data={history}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(no) => setRowsPerPage(no)}
        header={technicalUserHistory(language)}
        dense={false}
        openAddModal={() => {}}
        openRowModal={(id) => {
          setSelectedTask(id);
          setScoreModal(true);
        }}
        clickLabel="TASK_ID"
        hideAddBtn={true}
      />

      {
        scoreModal &&
        <ScoringModal
          language={language}
          taskId={selectedTask}
          open={scoreModal}
          close={() => {
            setScoreModal(false);
            if(selectedUser) {
              getHistory(selectedUser.USER_ID);
            }
          }}
        />
      }
    </Grid>
  )
};

const mapStateToProps = (state) => ({
  language: state.config.language,
  isAuthenticated: state.auth.isAuthenticated,
  permissions: state.auth.permissions,
  userAuth: state.auth.userData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionsCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserScoreView);