import React from 'react';
import { Grid, Paper, Typography, Divider, TextField, Grow, MenuItem, Select, InputLabel, FormControl, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import * as lang from '../../../Constants/strings';
import Can, { CanVisible } from '../../../Components/Misc/Can';
import axios from 'axios';
import AddIcon from '@material-ui/icons/AddRounded'
import PasswordIcon from '@material-ui/icons/SecurityRounded';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        // marginBottom: theme.spacing(-0.5),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function ProfileDetails(props) {
    const classes = useStyles();
    const inputLabel1 = React.useRef(null);
    const [labelWidth1, setLabelWidth1] = React.useState(95);
    const inputLabel2 = React.useRef(null);
    const [labelWidth2, setLabelWidth2] = React.useState(30);
    const [roleList, setRoleList] = React.useState([]);
    const { departmentList, openDepartmentUpdateModal } = props;

    React.useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setRoleList([]);

        const loadData = () => {
            try {
                axios.get('/api/role_list', { cancelToken: source.token })
                    .then(res => {
                        setRoleList(res.data);
                    })
                    .catch(err => {
                        console.log(err.message);
                    })
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log("cancelled");
                } else {
                    throw error;
                }
            }
        }

        loadData();
        return () => {
            source.cancel();
        };
    }, [props.isSubmittingRole]);

    React.useEffect(() => {
        // setLabelWidth1(inputLabel1.current.offsetWidth);
        // setLabelWidth2(inputLabel2.current.offsetWidth);
    }, [])

    return (
        <React.Fragment>
            <Grid item sm={12} md={3} container direction="column" >
                <Grow in={props.edit === false} style={{ transitionDelay: props.edit === false ? '100ms' : '0ms' }}>
                    <Paper className={classes.paper}>
                        <Typography variant="body1" align="center">{lang.DETAILS[props.language]}</Typography>
                        <Divider className={classes.divier} />
                        <TextField
                            variant="outlined"
                            disabled
                            label={lang.FULL_NAME[props.language]}
                            value={props.userData ? `${props.userData.basic.FIRST_NAME} ${props.userData.basic.LAST_NAME}` : ""}
                            fullWidth
                            className={classes.textField}
                        />
                        <TextField
                            variant="outlined"
                            disabled
                            label={lang.USERNAME[props.language]}
                            value={props.userData ? props.userData.basic.USERNAME : ""}
                            fullWidth
                            className={classes.textField}
                        />
                        <TextField
                            variant="outlined"
                            disabled
                            label={lang.EMAIL[props.language]}
                            value={props.userData ? props.userData.basic.EMAIL : ""}
                            fullWidth
                            className={classes.textField}
                        />
                        {/* <CanVisible visible={props.userData && props.userData.basic.USER_ID !== props.userID}> */}
                        <Can neededPermissions={{ parent: "USERS", name: "EDIT_USER" }}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel2} id="demo-simple-select-outlined-label">
                                    {lang.ROLE[props.language]}
                                </InputLabel>
                                <Select
                                    // name="ROLE"
                                    value={(props.userData && roleList.length > 0 && props.userData.basic.ROLE) || ""}
                                    labelWidth={labelWidth2}
                                    onChange={(e) => {
                                        if (e.target.value === 0)
                                            props.openRoleModal();
                                        else
                                            props.openRoleUpdateModal(e)
                                    }}
                                // onChange={updateUserDataDetails}
                                >
                                    <MenuItem value={0}>
                                        <AddIcon fontSize="small" className={classes.menuIcon} />
                                        {lang.ADD_ROLE[props.language]}
                                    </MenuItem>
                                    {roleList.length > 0 && roleList.map((role, index) => {
                                        return (
                                            <MenuItem key={index} value={role.ROLE_NAME}>
                                                {role.ROLE_NAME}
                                            </MenuItem>)
                                        // console.log(roleList)
                                    })}
                                </Select>
                            </FormControl>
                        </Can>
                        {/* </CanVisible>
                        <CanVisible visible={props.userData && props.userData.basic.USER_ID !== props.userID}> */}
                        <Can neededPermissions={{ parent: "USERS", name: "EDIT_USER" }}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel1} id="demo-simple-select-outlined-label">
                                    {lang.DEPARTMENT[props.language]}
                                </InputLabel>
                                <Select
                                    // name="ROLE"
                                    value={(props.userData && departmentList.length > 0 && props.userData.basic.DEPARTMENT_ID) || ""}
                                    labelWidth={labelWidth1}
                                    onChange={openDepartmentUpdateModal}
                                // onChange={updateUserDataDetails}
                                >
                                    <MenuItem value={0}><em>niciunul</em></MenuItem>
                                    {departmentList.length > 0 && departmentList.map((e, index) => {
                                        return (
                                            <MenuItem key={index} value={e.ID}>
                                                {e.NAME}
                                            </MenuItem>)
                                        // console.log(roleList)
                                    })}
                                </Select>
                            </FormControl>
                        </Can>
                        {/* </CanVisible> */}
                        <Can neededPermissions={{ parent: "USERS", name: "EDIT_USER" }}>
                            <Grid container justify="center" >
                                <Button
                                    className={classes.formControl}
                                    variant="text"
                                    onClick={props.resetUserPassword}
                                >
                                    <PasswordIcon fontSize="small" className={classes.leftIcon} />
                                    {lang.RESET_PASSWORD[props.language]}
                                </Button>
                            </Grid>
                        </Can>
                    </Paper>
                </Grow>
            </Grid>
        </React.Fragment>
    );
}
export function SkeletonProfileDetails(props) {
    return (
        <React.Fragment>
            <Grid item sm={12} md={3} container direction="column" >
                <Skeleton component={Paper} className={props.classes.paper} height="30vh">
                    <Grid container justify="center" alignItems="center" direction="column">
                        <Skeleton width="50%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                    </Grid>
                </Skeleton>
            </Grid>
        </React.Fragment>
    );
}