import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Chip, Input, Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../../Constants/strings';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import axios from 'axios';
import { checkSpecialChar } from '../../../../Functions/stringManipulation';


const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));

export default function SiteTypeNewForm(props) {
    const classes = useStyles();
    const { addData, language, error, updateError } = props;
    const [errorText, setError] = React.useState();

    function checkNameAvailability(name) {
        if (name)
            axios.get(`/api/check_site_type?name=${name}`)
                .then(res => {
                    updateError(parseInt(res.data, 10) > 0);
                    if (parseInt(res.data, 10) > 0)
                        setError("VALUE_EXISTS");
                    if (checkSpecialChar(name.trim(), "*|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        updateError(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });
        else {
            setError("EMPTY_FIELD");
            updateError(true);
        }
    }
    function focus() {
        updateError(null);
        setError(null);
    }
    React.useEffect(() => {
        setError(null);
    }, [addData.SITE_TYPE]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{ position: 'relative' }}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="SITE_TYPE"
                        label={lang.EXEC_NAME[language]}
                        value={addData.SITE_TYPE ? addData.SITE_TYPE : ""}
                        onChange={(e) => props.updateField(e, 150)}
                        onFocus={focus}
                        error={error}
                        helperText={errorText ? lang[errorText][language] : null}
                        onBlur={() => checkNameAvailability(addData.SITE_TYPE)}
                    />
                    {error === true
                        ?
                        <ErrorRoundedIcon className={classes.clear} color="secondary" />
                        :
                        error === false
                            ?
                            <CheckCircleRoundedIcon className={classes.clear} />
                            :
                            null
                    }
                </div>
            </Grid>
        </Grid>
    );
}