import React from 'react';
import { Grid, Avatar, ButtonBase } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import getAvatarUri from '../../Functions/getDataUriAvatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles(theme => ({
    avatar: {
        width: 70,
        height: 70,
    },
    avatarBig: {
        width: 120,
        height: 120,
    },
    avatarButton: {
        margin: 'auto',
    },
}));
export default function AvatarSelectMenu(props) {
    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    let nrAvatars = 12;
    const getAvatars = () => {
        let content = [];
        for(let i = 1; i <= nrAvatars; i++) {
            content.push(
                <Grid key={i} item xs={4} sm={2}>
                    <ButtonBase
                        className={classes.avatarButton}
                        onClick={() => props.updateAvatarData(parseInt(i, 10))}
                    >
                        <Avatar alt={i + ""} src={getAvatarUri(i)} className={xs ? classes.avatarBig : classes.avatar} />
                    </ButtonBase>
                </Grid>
            );
        }
        return content;
    }
    return (
        <React.Fragment>
            <Grid container spacing={0} style={{ overflow: "hidden", margin: '0px auto' }}>
                {getAvatars()}
            </Grid>
        </React.Fragment>
    );
}