import React, { useState, useEffect } from 'react';
import {
    useTheme,
    Grid,
    makeStyles,
    useMediaQuery
} from "@material-ui/core";
import MaintenanceHistoryFilters from "./components/MaintenanceHistoryFilters";
import axios from "axios";

import * as lang from "../../Constants/strings";
import * as requestHeaders from "../../Constants/requestHeaders";

import MaterialTable from "../../Components/Misc/Tables/MaterialTableSimple";
import HistoryFilterBar from "../Tasks/Components/HistoryFilterBar";

import { formatDateFromDb } from "../../Functions/dateFunctions";
import {
    maintenance_history_view,
    small_maintenance_history_view,
} from "../../Components/Misc/Tables/TableHeaders";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: "auto",
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: "rotate(180deg)",
    },
    margin: {
        margin: theme.spacing(2),
        width: "auto",
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fabGrid: {
        margin: theme.spacing(-5, 0, 0, 0),
    },
}));

const MaintenanceHistory = (props) => {
    const [data, setData] = useState([]);
    const {
        language,
        openMaintenanceModal,
        areaList
    } = props;
    const [rowsPerPage, onRowsPerPageChange] = useState(5);

    const [area, setArea] = useState(null);
    const [contractor, setContractor] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    const fetchHistory = async (startIntervalDate, endIntervalDate, areaCode, contractorId) => {
        try {
            let response = await axios.get("/api/get_close_maintenance_list", {
                ...requestHeaders.getGetHeader(),
                params: {
                    intervalStart: startIntervalDate,
                    intervalEnd: endIntervalDate,
                    area: areaCode,
                    contractorId
                }
            });

            let list = [];
            response.data.closeMaintenanceList.map((item) => {
                list.push({
                    ...item,
                    CREATE_DATE: formatDateFromDb(item.CREATE_DATE, 12),
                    END_DATE: formatDateFromDb(item.END_DATE, 12),
                });
            });
            setData(list);
        } catch (error) {
            setData([]);
            console.log("error: ", error);
        }
    }

    return (
        <>
            <HistoryFilterBar
                language={language}
                startDate={startDate}
                setStartDate={(value) => {
                    setStartDate(value);
                    fetchHistory(formatDateFromDb(value, 15), formatDateFromDb(endDate, 15), area?.AREA, contractor?.OBJID);
                }}
                endDate={endDate}
                setEndDate={(value) => {
                    setEndDate(value);
                    fetchHistory(formatDateFromDb(startDate, 15), formatDateFromDb(value, 15), area?.AREA, contractor?.OBJID);
                }}
                selectedArea={area}
                setSelectedArea={(value) => {
                    setArea(value);
                    fetchHistory(formatDateFromDb(startDate, 15), formatDateFromDb(endDate, 15), value?.AREA, contractor?.OBJID);
                }}
                selectedContractor={contractor}
                setSelectedContractor={(value) => {
                    setContractor(value);
                    fetchHistory(formatDateFromDb(startDate, 15), formatDateFromDb(endDate, 15), area?.AREA, value?.OBJID);
                }}
                reset={() => {
                    const date = new Date();
                    fetchHistory(formatDateFromDb(date, 15), formatDateFromDb(date, 15));
                    setStartDate(date);
                    setEndDate(date);
                    setArea(null);
                    setContractor(null);
                }}
                areaList={areaList}
            />


            <Grid container component="main" className={classes.margin}>
                <Grid item xs={12} className={classes.fabGrid}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.MAINTENANCE[language]}
                        language={language}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={onRowsPerPageChange}
                        header={
                            tiny === true
                                ? small_maintenance_history_view(language)
                                : maintenance_history_view(language)
                        }
                        dense={false}
                        openAddModal={() => { }}
                        openRowModal={(id) => openMaintenanceModal(id)}
                        clickLabel="TASK_ID"
                        hideAddBtn={true}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default MaintenanceHistory;
