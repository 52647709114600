import { useEffect, useState } from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import axios from "axios";
import BarCodeInput from "../BarCodeInput";
import AddItem from "./AddItem";

import * as lang from "../../Constants/strings";
import * as reqHeaders from "../../Constants/requestHeaders";

const SearchItemByBarCode = (props) => {
    const {
        setValue,
        placeOfBusinessId,
        userId,
        language
    } = props;

    const [items, setItems] = useState([]);
    const [code, setCode] = useState("");

    const fetch = async () => {
        try {
            const res = await axios.get('/api/external/placeOfBusiness/inventoryByCode/' + code, {
                ...reqHeaders.getGetHeader(),
                params: {
                    placeOfBusinessId,
                    id: userId
                }
            });

            setItems([...res.data.data]);
        } catch (e) {
            console.log(e);
        }
    }

    const selectItem = (item) => {
        setValue(item);
        setCode("");
        setItems([]);
    }

    useEffect(() => {
        if (code.length > 3) fetch().then(() => { });
    }, [code]);

    return (
        <div style={{ marginTop: "20px" }} >
            <Typography style={{ marginBottom: "10px", marginTop: "10px" }}>
                {lang.SEARCH_ITEM_BY_BARCODE[language]}
            </Typography>
            <BarCodeInput
                value={code}
                onChange={(v) => setCode(v)}
                placeholder={lang.SEARCH_ITEM_BY_BARCODE[language]}
                language={language}
            />
            {items.length > 0 && <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />}
            <Grid container spacing={2} style={{ maxHeight: "350px", overflowY: "scroll" }}>
                {
                    items.map((x, index) => <Grid item xs={12}>
                        <AddItem
                            key={`add_item_barcode_${index}`}
                            productName={x.productName}
                            um={x.um}
                            quantity={x.quantity}
                            onClick={() => selectItem(x)}
                        />
                    </Grid>)
                }
            </Grid>
        </div>
    )
}

export default SearchItemByBarCode;
