export const MAILING_LISTS = {
    "en": "Mailing lists",
    "ro": "Liste email"
};
export const EMAIL_INTERVENTION_SOLVE = {
    "en": "Solve intervention",
    "ro": "Email rezolvare intervenție"
};
export const EMAIL_GPS_ERROR = {
    "en": "Email GPS error",
    "ro": "Email eroare GPS"
};
export const EMAIL_PHONE_WARNING = {
    "en": "Email phone warning",
    "ro": "Email înştiinţare telefonică"
};
export const ADD_USER_TO_EMAILING_LIST = {
    "en": "Add user to emailing list",
    "ro": "Adăugare utilizator în lista de emailing"
};
export const SELECT_CUSTOMER_EMAIL_LIST = {
    "en": "Select customer email to list",
    "ro": "Alegere email-uri implicite"
};
export const SITE_EMAIL = {
    "en": "Site email",
    "ro": "Email obiectiv"
};
export const CUSTOMER_EMAIL = {
    "en": "Customer email",
    "ro": "Email client"
};
export const CONTRACTOR_EMAIL = {
    "en": "Contractor email",
    "ro": "Email firmă contractoare"
};
export const MAILING_LIST = {
    "en": "Mailing list",
    "ro": "Listă email"
};
export const ADD_EMAIL = {
    en: "Add email",
    ro: "Adaugă email",
}
export const ADD_EMAIL_TO_MAILING_LIST = {
    en: "Add email to mailing list",
    ro: "Adaugă email în listă"
}
export const EMAILS = {
    en: "Emails",
    ro: "Email-uri"
}