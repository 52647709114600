import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    Grid,
    makeStyles,
    Paper,
    Button,
    Grow
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import * as lang from '../../../../Constants/strings';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 2, 0),
        width: 'auto'
    },
    fields: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(2, 1, 1, -2),
        width: '98%',
    },
    checkbox: {
        margin: theme.spacing(1)
    },
    text: {
        margin: theme.spacing(2)
    }
}));

export default function SortBar(props) {
    const classes = useStyles();
    const { language } = props;
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const applyFilters = () => {
        console.log("Filter");
        props.filter({ startDate: startDate, endDate: endDate });
    }

    return (
        <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
            <Paper className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.fields}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.START_INTERVAL_DATE[language]}
                                value={startDate}
                                onChange={date => setStartDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.fields}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.LAST_INTERVAL_DATE[language]}
                                value={endDate}
                                onChange={date => setEndDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={5}>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={() => applyFilters()}
                        >
                            {lang.FILTER[language]}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grow>
    )
}
