import React, { useState } from "react";
import axios from "axios";
import {
    Grid,
    Button
} from "@material-ui/core";
import * as requestHeaders from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import * as lang from "../../../Constants/strings";
import { task_report_customer } from "../../../Components/Misc/Tables/TableHeaders";
import SortBar from './SortBar';
import TaskCommentHistory from "../../../Components/Modals/TaskCommentHistory";
import CustomModal from "../../../Components/Modals/CustomModal";

const TaskUserReporting = (props) => {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [data, setData] = useState([]);
    const [taskId, setTaskId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [taskCommentModal, setTaskCommentModal] = useState(false);
    const [history, setHistory] = useState([]);

    const getTaskHistory = async ({ id }) => {
        const response = await axios.get('/api/task_history', {
            ...requestHeaders.getGetHeader(),
            params: {
                id
            }
        });

        if (response.status === 200 || response.status === 204) {
            setHistory(response.data.history);
        } else {
            setHistory([]);
        }
    };

    const getUserTaskActivity = async ({ startDate, endDate, selectedContractor }) => {
        const response = await axios.get('/api/report_task_customer', {
            ...requestHeaders.getGetHeader(),
            params: {
                startInterval: startDate,
                endInterval: endDate,
                contractorId: selectedContractor
            }
        });
        if (response.status === 200 || response.status === 204) {
            setData(response.data.result);
        } else {
            setData([]);
        }
    };


    const downloadAction = ({ startDate, endDate, selectedContractor }) => {
        fetch(`/api/report_task_customer_excel?startInterval=${startDate}&endInterval=${endDate}&contractorId=${selectedContractor}`,
            {
                ...requestHeaders.getGetHeader(),
                responseType: 'arraybuffer',
            }
        )
            .then(response => response.blob())
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = `Registru_consemnare_defectiunilor_semnalate_de_beneficiar_${new Date().getDate()}.xlsx`;
                link.click();
            })
            .catch(err => console.log(err))
    };

    const downloadFile = () => {
        let index = data.findIndex(e => e.TASK_ID === taskId);

        fetch(`/api/download_pdf?file=${data[index].FILE_NAME}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => {
                if (response.status >= 400) {
                    console.log("Bad response from server");
                }
                return response.blob();
            })
            .then(response => {
                try {
                    const file = new Blob(
                        [response],
                        { type: 'application/pdf;' });
                    const fileURL = URL.createObjectURL(file);
                    var link = document.createElement('a');
                    link.href = fileURL;
                    link.download = data[index].FILE_NAME;
                    link.click();
                }
                catch (e) {
                    console.error(e)
                }
            })
            .catch(err => console.log(err))
    };

    return (
        <>
            <CustomModal
                open={modalOpen}
                close={() => setModalOpen(false)}
                content={
                    <Grid direction="column" alignItems="center" justify="center" container spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    setModalOpen(false);
                                    downloadFile();
                                }}
                            >
                                {lang.DOWNLOAD_FILE[props.language]}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() => {
                                    setModalOpen(false);
                                    setTaskCommentModal(true);
                                }}
                            >
                                {lang.COMMENT_TASK_HISTORY[props.language]}
                            </Button>
                        </Grid>
                    </Grid>
                }
                language={props.language}
                noButtons
            />
            <TaskCommentHistory
                open={taskCommentModal}
                tiny={true}
                classes={{}}
                data={history}
                language={props.language}
                close={() => {
                    setTaskCommentModal(false);
                }}
            />
            <SortBar
                language={language}
                filter={getUserTaskActivity}
                onDownload={downloadAction}
            />
            <MaterialTable
                tiny={true}
                title="Registru pentru consemnarea defectiunilor semnalate de beneficiari"
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={task_report_customer(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={(id) => {
                    setModalOpen(true);
                    setTaskId(id);
                    getTaskHistory({ id });
                }}
                clickLabel="TASK_ID"
                hideAddBtn={true}
            />
        </>
    )
}

export default TaskUserReporting;
