import React from 'react';
import {
    Grid,
    Button,
    makeStyles
} from "@material-ui/core";
import PropTypes from "prop-types";

import CustomModal from "../../../Components/Modals/CustomModal";
import * as lang from "../../../Constants/strings";
import Can from "../../../Components/Misc/Can";

const useStyles = makeStyles(theme => ({
    button:  {
        margin: theme.spacing(1)
    }
}))

function FileModal({ open, fileName, language, downloadFile, deleteFile, close }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CustomModal 
                open={open}
                title={fileName}
                language={language}
                fullWidth
                content={
                    <Grid container spacing={2} alignItems="center" direction="column">
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={() => downloadFile()}
                            className={classes.button}
                            fullWidth
                        >
                          {lang.DOWNLOAD_FILE[language]}
                        </Button>
                        <Can neededPermissions={{ parent: "TEMPLATE_FILE", name: "DELETE_TEMPLATE_FILE" }}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={() => deleteFile()}
                            className={classes.button}
                            fullWidth
                        >
                            {lang.DELETE_FILE[language]}
                        </Button>
                        </Can>
                    </Grid>
                }
                close={() => close()}
                execute={() => {}}
                noButtons
            /> 
        </React.Fragment>
    )
}

FileModal.propsTypes = {
    open: PropTypes.bool,
    fileName: PropTypes.string,
    downloadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    close: PropTypes.func,
    language: PropTypes.string
};

export default FileModal;