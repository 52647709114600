import React from 'react';
import { Grid, Paper, makeStyles, Typography, Divider, TextField, Button } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import { BlockRounded } from '@material-ui/icons';
import { formatDateFromDb } from '../../../Functions/dateFunctions';
import Can from '../../../Components/Misc/Can';
import CustomModal from '../../../Components/Modals/CustomModal';
import ContractNewForm from './ContractNewForm';
import ViewContract from './ViewContract';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as requestHeaders from "../../../Constants/requestHeaders";
import axios from "axios"

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    title: {
        margin: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(2),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    }
}));

function ContractField(props) {
    const classes = useStyles();
    const { label, value, disabled } = props;

    return (
        <TextField
            className={classes.paragraph}
            disabled={disabled ? disabled : false}
            multiline
            fullWidth
            variant="outlined"
            label={label}
            value={value}
        />
    );
}

function SingleContract(props) {
    const classes = useStyles();
    const { tiny, language, data, openEditModal, openTerminateModal, closeParentModal, openViewModal } = props;

    const [serviceType, setServiceType] = React.useState();
    const [maintenanceLastDate, setMaintenanceLastDate] = React.useState(new Date());

    const getBillingCycle = (data) => {
        switch (data) {
            case 1:
                return lang.BILL_CURRENT_MONTH[language];
            case 2:
                return lang.BILL_SEMESTER[language];;
            case 3:
                return lang.BILL_QUARTER[language];
            case 4:
                return lang.BILL_YEARLY[language];
            case -1:
                return lang.BILL_LAST_MONTH[language];
        }
    }

    const getContractExtraServices = async () => {
        try {
            let response = await axios.get("/api/get_contract_extra_service", {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: data.OBJID
                }
            });

            setMaintenanceLastDate(new Date(response.data.lastMaintenance));
            setServiceType(response.data.extraServices);
        } catch (error) {
            console.log(error);
        }
    };
    // Just for maintenances contracts
    React.useEffect(() => {
        if (data && data.TYPE_ID === 2 && data.SUB_TYPE_ID === 1)
            getContractExtraServices()
    }, [data])

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.paper} elevation={3}>
                    <Typography align="center" className={classes.title} variant="h6" noWrap={true}>{lang[data.SUB_TYPE][language]}</Typography>
                    <Divider />
                    <ContractField label={lang.CONTRACTOR_NAME[language]} value={data.CONTRACTOR_NAME} />
                    <ContractField label={lang.CONTRACT_START_DATE[language]} value={formatDateFromDb(data.START_DATE, 8)} />
                    <ContractField label={lang.SIGNATURE_DATE[language]} value={formatDateFromDb(data.SIGNATURE_DATE, 8)} />
                    <ContractField label={lang.CONTRACT_NUMBER[language]} value={data.CONTRACT_NO} />
                    <ContractField label={lang.BILL_CYCLE[language]} value={getBillingCycle(data.BILL_CYCLE)} />
                    <ContractField label={lang.EXPIRATION_DATE[language]} value={formatDateFromDb(data.END_DATE, 8)} />
                    <ContractField label={lang.ASSIGNEE[language]} value={data.ASSIGNEE} />
                    <ContractField label={lang.FREE_TRUCK_ROLLS[language]} value={data.FREE_TRUCK_ROLLS} />
                    <ContractField label={lang.TRUCK_ROLL_VALUE[language]} value={data.TRUCK_ROLL_VALUE} />
                    {
                        data && data.TYPE_ID === 2 && data.SUB_TYPE_ID === 1 && <>
                            <ContractField label={lang.MAINTENANCE_TYPE[language]} value={serviceType && serviceType.mainType !== null ? serviceType.mainType.NAME : ""} />
                            <ContractField label={lang.LAST_MAINTENANCE_DATE[language]} value={maintenanceLastDate ? formatDateFromDb(maintenanceLastDate, 8) : ""} />
                            <ContractField label={lang.MAINTENANCE_SERVICES[language]} value={serviceType && serviceType.subTypes ? serviceType.subTypes.map(ser => ser.checked ? ` ${ser.NAME}` : "") : ""} />
                        </>
                    }
                    <Grid container justify="space-around" className={classes.paragraph}>
                        <Can neededPermissions={{ parent: "CONTRACTS", name: "VIEW_CONTRACT_DETAIL" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => openViewModal(data.OBJID)}
                                className={classes.button}
                            >
                                {lang.VIEW_CONTRACT[language]}
                            </Button>
                        </Can>
                        <Can neededPermissions={{ parent: "CONTRACTS", name: "EDIT_CONTRACT" }}>
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={() => openEditModal(data.OBJID)}
                                className={classes.button}
                            >
                                {lang.EDIT_CONTRACTS[language]}
                            </Button>
                        </Can>
                        <Can neededPermissions={{ parent: "CONTRACTS", name: "EDIT_CONTRACT" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => openTerminateModal(data.OBJID)}
                                className={classes.button}
                            >
                                <BlockRounded fontSize="small" className={classes.leftIcon} />
                                {lang.TERMINATION_CONTRACT[language]}
                            </Button>
                        </Can>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default function SiteContracts(props) {
    const { tiny, language, contractList, close, updateContract, terminateContract, setAddData, updateField, updateFieldRaw, services } = props;

    const [contractModal, setContractModal] = React.useState(false);
    const [viewContractModal, setViewContractModal] = React.useState(false);
    const [terminateModal, setTerminateModal] = React.useState(false);
    const [endDate, setEndDate] = React.useState(new Date());
    const [closeComment, setCloseComment] = React.useState('');

    return (
        <React.Fragment>
            <CustomModal
                open={contractModal !== false}
                title={lang.ADJUST_CONTRACT[language]}
                content={
                    <Grid container spacing={2}>
                        {contractModal ?
                            <ContractNewForm
                                {...props}
                                addData={props.addData}
                                tiny={tiny}
                            />
                            : null
                        }
                    </Grid>
                }
                close={() => setContractModal(false)}
                execute={() => {
                    updateContract(contractModal);
                    setContractModal(false);
                }}
                language={language}
                validate={true}
            />
            <CustomModal
                open={viewContractModal !== false}
                title={lang.VIEW_CONTRACT[language]}
                content={
                    <Grid container spacing={2}>
                        {viewContractModal ?
                            <ViewContract
                                {...props}
                                addData={props.addData}
                                tiny={tiny}
                            />
                            : null
                        }
                    </Grid>
                }
                close={() => setViewContractModal(false)}
                language={language}
                validate={false}
            />
            <CustomModal
                open={terminateModal !== false}
                title={lang.CONFIRM_ACTION[language]}
                close={() => setTerminateModal(false)}
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12}  >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    required={true}
                                    inputVariant="outlined"
                                    fullWidth
                                    name="END_DATE"
                                    label={lang.DISSOLUTION_DATE[props.language]}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={endDate}
                                    onChange={(value) => setEndDate(value)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}  >
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="CLOSE_COMMENT"
                                label={lang.CLOSE_COMMENT[language]}
                                value={closeComment}
                                onChange={(e) => setCloseComment(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                }
                execute={() => {
                    terminateContract(terminateModal, formatDateFromDb(endDate, 15), closeComment);
                    setTerminateModal(false);
                    close();
                }}
                language={language}
                validate={closeComment && closeComment !== ''}
            />
            <Grid container spacing={tiny ? 1 : 4} alignContent="stretch">
                {contractList.length > 0 && contractList.map((contract, index) =>
                    <SingleContract
                        key={contract.OBJID}
                        data={contract}
                        tiny={tiny}
                        language={language}
                        openEditModal={(value) => {
                            setContractModal(value);
                            setAddData(contractList[index])
                        }}
                        openViewModal={(value) => {
                            setViewContractModal(value);
                            setAddData(contractList[index])
                        }}
                        openTerminateModal={(value) => setTerminateModal(value)}
                        services={services}
                    />
                )}
            </Grid>
        </React.Fragment>
    );
}