import React from 'react';
import Can from '../../../Components/Misc/Can';
import { makeStyles, Grid, Paper, ButtonBase, Typography, Grow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        padding: theme.spacing(1),
        height: '100%',
        width: '95vw',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2)
        }
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '95vw',
    },
    imageTitle: {
        width: "200px"
    },
    image: {

        padding: theme.spacing(2),
        backgroundRepeat: 'no-repeat',
        margin: theme.spacing(2),
        [theme.breakpoints.down('xl')]: {
            width: '100% !important',
            height: 130,
        },
        '&:hover': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            focusVisible: {},
            imageButton: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.common.white,
            },
            imageSrc: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 100%',
            },
            imageBackdrop: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: theme.palette.common.black,
                opacity: 0.4,
                transition: theme.transitions.create('opacity'),
            },
            imageMarked: {
                height: 3,
                width: 18,
                backgroundColor: theme.palette.common.white,
                bottom: -2,
                transition: theme.transitions.create('opacity'),
            },
        },
    },


}))

export default function ShortcutDisplay(props) {
    const classes = useStyles();
    const { buttons } = props;
    return (
        <Grow in={true} timeout={150}>
            <Grid item xs={12}>
                <Paper style={{ marginTop: 10 }}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <div className={classes.root}>
                            {buttons.map(image => (
                                <Can key={image.title + '_@@'} neededPermissions={image.neededPermissions}>
                                    <Grid key={image.title + '_Grid'} item xs={12} md={3} spacing={2}>
                                        <ButtonBase
                                            focusRipple
                                            key={image.title}
                                            className={classes.image}
                                            focusVisibleClassName={classes.focusVisible}
                                            style={{
                                                width: image.width,
                                            }}
                                            style={{
                                                backgroundImage: `url(${image.image})`,
                                            }}
                                            onClick={e => image.action(e)}
                                        >
                                            <span
                                                className={classes.imageSrc}
                                                style={{
                                                    backgroundImage: `url(${image.image})`,
                                                }}
                                            />
                                            <span className={classes.imageBackdrop} />
                                            <span className={classes.imageButton}>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    className={classes.imageTitle}
                                                >
                                                    {image.title}
                                                    <span className={classes.imageMarked} />
                                                </Typography>
                                            </span>
                                        </ButtonBase>
                                    </Grid>
                                </Can>
                            ))}
                        </div>
                    </Grid>
                </Paper>
            </Grid>
        </Grow >
    )
};
