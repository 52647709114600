import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import history from './history';

// REDUX
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import store from './configureStore';


export default class BrowserRouter extends Component {
    render() {
        return <Router history={history} children={this.props.children} />
    }
}

const app = (
    <BrowserRouter>
        <Provider store={store}>
            {/* <MuiThemeProvider theme={theme}> */}
            <App />
            {/* </MuiThemeProvider> */}
        </Provider>
    </BrowserRouter>
);
persistStore(store, {}, () => {
    ReactDOM.render(app, document.getElementById('root'));
    serviceWorker.register();
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
