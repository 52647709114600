import React, { useEffect, useState } from "react";
import { TextField, Grid, Typography, Divider } from "@material-ui/core";
import * as lang from "../../../Constants/strings";
import { formatDateFromDb } from "../../../Functions/dateFunctions";
import axios from "axios";

export default function MaintenanceInfo(props) {
  const { language, tiny, data, classes, isSm, type } = props;
  const [contractCycle, setContractCycle] = useState("");

  useEffect(() => {
    if (data) getContract(data.CONTRACT_OBJID);
  }, [data]);

  const getContract = async (id) => {
    try {
      let response = await axios.get("/api/get_contract_data", {
        headers: {
          authorization: localStorage.token,
        },
        params: {
          id: id,
        },
      });

      setContractCycle(translateBillCycle(response.data[0].BILL_CYCLE));
    } catch (e) { }
  };

  const translateBillCycle = (id) => {
    switch (id) {
      case 1:
        return "Lunar";
      case 2:
        return "Semestrial";
      case 3:
        return "Trimestrial";
      case 4 || 0:
        return "Anual";
      case -1:
        return "?";
      default:
        return "?";
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid
          container
          justify="flex-start"
          alignItems="stretch"
          direction="column"
        >
          <Typography className={classes.title} variant="h5">
            {lang.MAINTENANCE[language]}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="flex-start"
          alignItems="stretch"
          direction="column"
          spacing={2}
        >
          <Divider className={classes.divider} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          spacing={2}
          style={{
            marginRight: isSm ? "0px" : "16px",
            paddingRight: "0px",
          }}
        >
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              variant="outlined"
              label={lang.STATUS[language]}
              value={
                data && data.STATUS
                  ? lang[data.STATUS][language].toUpperCase()
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              variant="outlined"
              label={lang.OWNER[language]}
              value={data && data.USERNAME_ORIG ? data.USERNAME_ORIG : ""}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              variant="outlined"
              label={lang.ASSIGNEE[language]}
              value={data && data.USERNAME_CURR ? data.USERNAME_CURR : ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              variant="outlined"
              label={lang.CREATE_TIME[language]}
              value={
                data && data.CREATE_TIME
                  ? formatDateFromDb(data.CREATE_TIME, 13)
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              variant="outlined"
              label={lang.LAST_UPDATE[language]}
              value={
                data && data.LAST_UPDATE
                  ? formatDateFromDb(data.LAST_UPDATE, 13)
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              variant="outlined"
              label="Perioada"
              value={contractCycle}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              multiline
              variant="outlined"
              label={lang.CONTRACTOR_NAME[language]}
              value={data && data.CONTRACTOR_NAME ? data.CONTRACTOR_NAME : ""}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          container
          spacing={2}
          style={{ paddingRight: "0px" }}
        >
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              disabled
              className={classes.textField}
              multiline
              variant="outlined"
              label={lang.REASON[language]}
              value={data && data.REASON ? data.REASON : ""}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
