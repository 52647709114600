import React from 'react';
import {
    Grid,
    makeStyles,
    Paper,
    TextField,
    Divider,
    Button,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Typography
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";

import { formatDateFromDb } from '../../../Functions/dateFunctions';
import * as lang from '../../../Constants/strings';
import * as requestHeaders from '../../../Constants/requestHeaders';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        // marginTop: theme.spacing(10),
        width: 'auto'
    },
    fields: {
        margin: theme.spacing(1),
        width: '95%'
    },
    button: {
        margin: theme.spacing(1),
        width: '50%',
        marginLeft: '49%'
    },
    checkbox: {
        margin: theme.spacing(1)
    },
    text: {
        margin: theme.spacing(2)
    }
}));

export default function SortBar(props) {
    const classes = useStyles();
    const { language, filterData } = props;
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [billTypes, setBillTypes] = React.useState([]);
    const [selectedBillType, setSelectedBillType] = React.useState(1);
    const [billNoFrom, setBillNoFrom] = React.useState('');
    const [billNoTo, setBillNoTo] = React.useState('');
    const [billValueFrom, setBillValueFrom] = React.useState('');
    const [billValueTo, setBillValueTo] = React.useState('');
    const [contractorList, setContractorList] = React.useState([]);
    const [selectedContractor, setSelectedContractor] = React.useState(null);
    const [recipeStartDate, setRecipeStartDate] = React.useState(new Date());
    const [recipeEndDate, setRecipeEndDate] = React.useState(new Date());
    const [recipeNoFrom, setRecipeNoFrom] = React.useState('');
    const [recipeNoTo, setRecipeNoTo] = React.useState('');
    const [type, setType] = React.useState(null);

    const [filterByDate, setFilterByDate] = React.useState(false);
    const [filterByBillNo, setFilterByBillNo] = React.useState(false);
    const [filterByBillValue, setFilterByBillValue] = React.useState(false);
    const [filterByRecipeNo, setFilterByRecipeNo] = React.useState(false);
    const [filterByRecipeDate, setFilterByRecipeDate] = React.useState(false);
    const [filterByContractor, setFilterByContractor] = React.useState(false);
    const [filterByType, setFilterByType] = React.useState(false);

    React.useEffect(() => {
        if (firstLoad) {
            getBillTypes();
            setFirstLoad(false);
            getContractor();
        }
    }, []);

    const getBillTypes = async () => {
        const response = await axios.get("/api/get_task_billing_types", requestHeaders.getGetHeader());

        if (response.data.data.length > 0) {
            let billTypeList = [];
            response.data.data.map(type => {
                if (type.OBJID !== 3) {
                    billTypeList.push(type)
                }
            });
            setBillTypes(billTypeList);
        } else {
            setBillTypes([]);
        }
    };


    const getContractor = async () => {
        const result = await axios.get('/api/get_executors', {
            ...requestHeaders.getGetHeader(),
            params: {
                active: true,
            }
        });
        if (result.data.length > 0) {
            setContractorList(result.data)
        } else {
            setContractorList([]);
        }
    };

    const applyFilters = () => {
        let filters = {
            dateInterval: {
                dateFrom: formatDateFromDb(startDate, 15),
                dateTo: formatDateFromDb(endDate, 15)
            },
            billIntervalFilter: {
                billNoTo: billNoTo,
                billNoFrom: billNoFrom,
                billValueTo: billValueTo,
                billValueFrom: billValueFrom
            },
            recipeIntervalFilter: {
                recipeDateTo: recipeEndDate,
                recipeDateFrom: recipeStartDate,
                recipeNoFrom: recipeNoFrom,
                recipeNoTo: recipeNoTo
            },
            type,
            contractor: selectedContractor,
            billType: selectedBillType,
            filterByBillNo: filterByBillNo,
            filterByBillValue: filterByBillValue,
            filterByDate: filterByDate,
            filterByRecipeNo: filterByRecipeNo,
            filterByRecipeDate: filterByRecipeDate,
            filterByContractor: filterByContractor,
            filterByType
        };

        filterData(filters);
    };

    return (
        <Paper className={classes.root}>
            <Grid container spacing={2} >
                <Grid item xs={2}>
                    <TextField
                        required
                        select
                        className={classes.fields}
                        variant='outlined'
                        name='BillType'
                        label={lang.PAYMENT_TYPE[language]}
                        onChange={(e) => setSelectedBillType(e.target.value)}
                        value={selectedBillType}
                        fullWidth
                    >
                        {
                            billTypes.map(type => {
                                return <MenuItem key={type.OBJID} value={type.OBJID}>{lang[type.BILLING_TYPE][language].toUpperCase()}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            className={classes.fields}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            id="date-picker-inline-1"
                            label={lang.START_INTERVAL_DATE[language]}
                            value={startDate}
                            onChange={date => setStartDate(date)}
                            disabled={!filterByDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            className={classes.fields}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            id="date-picker-inline-2"
                            label={lang.END_DATE[language]}
                            value={endDate}
                            onChange={date => setEndDate(date)}
                            disabled={!filterByDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        className={classes.fields}
                        name="BILL_NO_FROM"
                        variant='outlined'
                        onChange={(e) => setBillNoFrom(e.target.value)}
                        label={lang.BILL_NO_FROM[language]}
                        value={billNoFrom}
                        type="number"
                        disabled={!filterByBillNo}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        className={classes.fields}
                        name="BILL_NO_TO"
                        variant='outlined'
                        onChange={(e) => setBillNoTo(e.target.value)}
                        label={lang.BILL_NO_TO[language]}
                        value={billNoTo}
                        type="number"
                        disabled={!filterByBillNo}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        className={classes.fields}
                        name="BILL_VALUE_FROM"
                        variant='outlined'
                        onChange={(e) => setBillValueFrom(e.target.value)}
                        label={lang.BILL_VALUE_FROM[language]}
                        value={billValueFrom}
                        type="number"
                        disabled={!filterByBillValue}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        className={classes.fields}
                        name="BILL_VALUE_TO"
                        variant='outlined'
                        onChange={(e) => setBillValueTo(e.target.value)}
                        label={lang.BILL_VALUE_TO[language]}
                        value={billValueTo}
                        type="number"
                        disabled={!filterByBillValue}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={!filterByContractor}
                        select
                        className={classes.fields}
                        variant='outlined'
                        name='Contractor'
                        label={lang.CONTRACTOR[language]}
                        onChange={(e) => setSelectedContractor(e.target.value)}
                        value={selectedContractor}
                        fullWidth
                    >
                        {
                            contractorList.map(contractor => {
                                return <MenuItem key={contractor.OBJID} value={contractor.NAME}>{contractor.NAME}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>

                <Grid item xs={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            className={classes.fields}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            id="date-picker-inline-recipe-from"
                            label={lang.RECIPE_DATE_FROM[language]}
                            value={recipeStartDate}
                            onChange={date => setRecipeStartDate(date)}
                            disabled={!filterByRecipeDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            className={classes.fields}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            id="date-picker-inline-recipe-to"
                            label={lang.RECIPE_DATE_TO[language]}
                            value={recipeEndDate}
                            onChange={date => setRecipeEndDate(date)}
                            disabled={!filterByRecipeDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {
                    !props.hideType &&
                    <Grid item xs={2}>
                        <TextField
                            required
                            select
                            className={classes.fields}
                            variant='outlined'
                            name='BillType'
                            label="Serviciul"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            fullWidth
                            disabled={!filterByType}
                        >
                            <MenuItem value="TASK">{lang.TASK[language].toUpperCase()}</MenuItem>
                            <MenuItem value="PROJECT">{lang.PROJECT[language].toUpperCase()}</MenuItem>
                            <MenuItem value="EVALUATION">{lang.EVALUATION[language].toUpperCase()}</MenuItem>
                            <MenuItem value="INSTALLATION">{lang.INSTALLATION[language].toUpperCase()}</MenuItem>
                        </TextField>
                    </Grid>
                }
                <Grid item xs={2}>
                    <TextField
                        className={classes.fields}
                        name="RECIPE_NO_FROM"
                        variant='outlined'
                        onChange={(e) => setRecipeNoFrom(e.target.value)}
                        label={lang.RECIPE_NO_FROM[language]}
                        value={recipeNoFrom}
                        type="number"
                        disabled={!filterByRecipeNo}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        className={classes.fields}
                        name="RECIPE_NO_TO"
                        variant='outlined'
                        onChange={(e) => setRecipeNoTo(e.target.value)}
                        label={lang.RECIPE_NO_TO[language]}
                        value={recipeNoTo}
                        type="number"
                        disabled={!filterByRecipeNo}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Typography className={classes.text} variant="h5">{lang.FILTER_WITH[language]}</Typography>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={filterByDate === true}
                                    onChange={() => setFilterByDate(!filterByDate)}
                                    name="filterByDate"
                                    color="primary"
                                />
                            }
                            label={lang.DATE[language]}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={filterByBillNo === true}
                                    onChange={() => setFilterByBillNo(!filterByBillNo)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={lang.BILL_NO[language]}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={filterByBillValue === true}
                                    onChange={() => setFilterByBillValue(!filterByBillValue)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={lang.BILL_VALUE[language]}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={filterByRecipeNo === true}
                                    onChange={() => setFilterByRecipeNo(!filterByRecipeNo)}
                                    name="filterByRecipeNo"
                                    color="primary"
                                />
                            }
                            label={lang.RECIPE_NO[language]}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={filterByRecipeDate === true}
                                    onChange={() => setFilterByRecipeDate(!filterByRecipeDate)}
                                    name="filterByRecipeDate"
                                    color="primary"
                                />
                            }
                            label={lang.RECIPE_DATE[language]}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={filterByContractor === true}
                                    onChange={() => setFilterByContractor(!filterByContractor)}
                                    name="filterByContractor"
                                    color="primary"
                                />
                            }
                            label={lang.CONTRACTOR[language]}
                        />
                        {
                            !props.hideType &&
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        checked={filterByType === true}
                                        onChange={() => setFilterByType(!filterByType)}
                                        name="filterByContractor"
                                        color="primary"
                                    />
                                }
                                label={lang.SERVICES[language]}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        onClick={() => applyFilters()}
                    >
                        {lang.FILTER[language]}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}
