import React, { Component } from "react";
import * as lang from "../../Constants/strings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../actions/actions";
import {
	CssBaseline,
	makeStyles,
	Grid,
	useMediaQuery,
	useTheme,
	Paper,
	Checkbox,
	FormControlLabel,
	Button,
} from "@material-ui/core";
import {
	VisibilityRounded,
	Edit,
	ArrowForwardIos,
	ArrowBackIos
} from "@material-ui/icons";
import MaterialTableDemo from "../../Components/Misc/Tables/MaterialTable";
import CustomButtonList from "../../Components/Modals/CustomButtonList";
import CustomModal from "../../Components/Modals/CustomModal";
import CustomDrawer from "../../Components/Modals/CustomDrawer";
import TableSkeleton from "../Nomenclature/Skeleton";
import { siteAddFormat } from "../../Functions/objectManipulation";
import SiteNewForm from "../Customer/CustomerSingleViewComponents/SiteNewForm";
import { site, smallSite } from "../../Components/Misc/Tables/TableHeaders";
import { redirectTo } from "../../Functions/linkFunctions";
import { checkPermissions } from "../../Functions/permissionHide";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(10),
		width: "auto",
	},
	shortcut: {
		marginBottom: theme.spacing(10),
		margin: theme.spacing(1),
		transform: "rotate(180deg)",
	},
	margin: {
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	filterPaper: {
		padding: "5px",
		width: '75px',
		height: '50px',
		transition: "width 1s ease",
		overflow: 'hidden',
	},
	bigger: {
		width: '100%',
		padding: "5px",
		overflow: 'hidden',
	},
	checkbox: {
		padding: "0px 10px",
	},
}));

function SiteSetup(props) {
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const {
		language,
		onRowsPerPageChange,
		openRowModal,
		rowsPerPage,
		data,
		onSearch,
		getSearchTerm,
		searchTerm,
		area,
		filterForActiveSites,
		setFilterForActiveSites,
		filterForValidateSites,
		setFilterForValidatedSites,
		filterOpen,
		setFilterOpen
	} = props;

	return (
		<Grid
			container
			component="main"
			className={classes.root}
			spacing={tiny ? 1 : 4}
		>
			<Grid item xs={12}>
				<Paper className={filterOpen ? classes.bigger : classes.filterPaper}>
					<Grid container justify="space-between">
						<Grid item xs={4} sm={1}>
							<Button style={{ height: '40px' }} onClick={() => setFilterOpen(!filterOpen)}>
								{filterOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
							</Button>
						</Grid>
						<Grid item xs={8} sm={11}>
							{filterOpen &&
								<Grid container>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterForActiveSites === 'active'}
												onClick={() => setFilterForActiveSites(filterForActiveSites === 'active' ? false : 'active')}
											/>
										}
										label={lang.ACTIVE_SITES[language]}
										className={classes.checkbox}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterForActiveSites === 'inactive'}
												onClick={() => setFilterForActiveSites(filterForActiveSites === 'inactive' ? false : 'inactive')}
											/>
										}
										label={lang.INACTIVE_SITES[language]}
										className={classes.checkbox}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterForValidateSites === 'validate'}
												onClick={() => setFilterForValidatedSites(filterForValidateSites === 'validate' ? false : 'validate')}
											/>
										}
										label={lang.VALIDATE_SITES[language]}
										className={classes.checkbox}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterForValidateSites === 'invalidate'}
												onClick={() => setFilterForValidatedSites(filterForValidateSites === 'invalidate' ? false : 'invalidate')}
											/>
										}
										label={lang.NOVALIDATE_SITES[language]}
										className={classes.checkbox}
									/>
								</Grid>
							}
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<MaterialTableDemo
					tiny={tiny}
					title={
						area === null
							? lang.SITE[language]
							: lang.SITE[language] + " " + area
					}
					language={language}
					data={data}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={onRowsPerPageChange}
					header={
						tiny === true ? smallSite(language) : site(language)
					}
					dense={false}
					hideAddBtn={true}
					openRowModal={openRowModal}
					clickLabel="OBJID"
					onSearch={(searchTerm) => onSearch(searchTerm)}
					getSearchTerm={(value) => getSearchTerm(value)}
					searchTerm={searchTerm}
				/>
			</Grid>
		</Grid>
	);
}

function SiteSettingsModal(props) {
	const { language, close, selectedIndex, open } = props;
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const classes = useStyles();
	return (
		<React.Fragment>
			<CustomDrawer
				open={open && tiny}
				close={close}
				list={[
					{
						primary: lang.GO_TO(lang.SITE[language])[language],
						secondary: "",
						icon: <VisibilityRounded />,
						action: () => {
							redirectTo(`/site?id=${selectedIndex}`);
						},
						render:
							props.isAuthenticated &&
								checkPermissions(props.userPermissions, {
									parent: "SITE",
									name: "VIEW_SITE",
								}) === true
								? true
								: false,
					},
					{
						primary: lang.VALIDATES[language],
						secondary: "",
						icon: <Edit />,
						action: () => {
							props.onValidateSite();
						},
						render:
							props.site === null
								? false
								: props.site.VERIFY === 0
									? props.isAuthenticated &&
										checkPermissions(props.userPermissions, {
											parent: "SITE",
											name: "EDIT_SITE",
										}) === true
										? true
										: false
									: false,
					},
				]}
			/>
			<CustomModal
				open={open && !tiny}
				title={lang.SITE_DETAILS[language]}
				content={
					<Grid container spacing={2}>
						<CustomButtonList
							close={close}
							language={language}
							tiny={tiny}
							list={[
								{
									text: lang.GO_TO(lang.SITE[language])[
										language
									],
									style: {
										icon: (
											<VisibilityRounded
												className={classes.leftIcon}
											/>
										),
										color: "primary",
										variant: "contained",
									},
									action: () => {
										redirectTo(`/site?id=${selectedIndex}`);
									},
									render:
										props.isAuthenticated &&
											checkPermissions(
												props.userPermissions,
												{
													parent: "SITE",
													name: "VIEW_SITE",
												}
											) === true
											? true
											: false,
								},
								{
									text: lang.VALIDATES[language],
									style: {
										icon: (
											<Edit
												className={classes.leftIcon}
											/>
										),
										color: "primary",
										variant: "contained",
									},
									action: () => {
										props.onValidateSite();
									},
									render:
										props.site === null
											? false
											: props.site.VERIFY === 0
												? props.isAuthenticated &&
													checkPermissions(
														props.userPermissions,
														{
															parent: "SITE",
															name: "EDIT_SITE",
														}
													) === true
													? true
													: false
												: false,
								},
							]}
							withBack
						/>
					</Grid>
				}
				close={close}
				execute={close}
				language={language}
				noButtons
			/>
		</React.Fragment>
	);
}

class SitesView extends Component {
	state = {
		selectedIndex: null,
		index: null,
		searchTerm: "",
		editModal: false,
		siteData: {},
		error: null,
		filterForActiveSites: 'active',
		filterForValidateSites: false,
		filterOpen: false
	};

	componentDidMount() {
		document.title = "RPG - " + lang.SITE[this.props.language];
		this.props.actions.setSortItem(null);
		this.props.actions.setCurrentPage(0);
		this.props.actions.setSortColumn1("OBJID");
		this.props.actions.getSiteList(
			this.props.rowsPerPage,
			0,
			"OBJID",
			"ASC",
			this.props.selectedSortItem,
			this.state.filterForActiveSites,
			this.state.filterForValidateSites
		);
		this.props.actions.getSiteCount();
	}

	componentWillUnmount() {
		this.props.actions.resetSortItem(null);
	}

	componentDidUpdate(prevProps) {
		if (this.state.searchTerm === "" || !this.state.searchTerm) {
			if (
				prevProps.rowsPerPage !== this.props.rowsPerPage ||
				prevProps.sortColumn !== this.props.sortColumn ||
				prevProps.order !== this.props.order ||
				prevProps.selectedSortItem !== this.props.selectedSortItem
			) {
				this.props.actions.getSiteList(
					this.props.rowsPerPage,
					this.props.currentPage,
					this.props.sortColumn,
					this.props.order,
					this.props.selectedSortItem,
					this.state.filterForActiveSites,
					this.state.filterForValidateSites
				);
				this.props.actions.getSiteCount(
					this.props.selectedSortItem,
					this.state.filterForActiveSites,
					this.state.filterForValidateSites
				);
			}
			if (prevProps.currentPage !== this.props.currentPage) {
				if (this.props.currentPage === this.props.loadedPages - 1)
					this.props.actions.loadNewSites(
						this.props.rowsPerPage,
						this.props.currentPage,
						this.props.sortColumn,
						this.props.order,
						this.props.selectedSortItem,
						this.state.filterForActiveSites,
						this.state.filterForValidateSites
					);
			}
		} else {
			if (
				prevProps.rowsPerPage !== this.props.rowsPerPage ||
				prevProps.sortColumn !== this.props.sortColumn ||
				prevProps.order !== this.props.order ||
				prevProps.selectedSortItem !== this.props.selectedSortItem
			) {
				this.props.actions.onSearchSite(
					this.state.searchTerm,
					this.props.sortColumn,
					this.props.order,
					this.props.selectedSortItem,
					this.state.filterForActiveSites,
					this.state.filterForValidateSites
				);
			}
		}
	}

	async onValidateSite() {
		await this.setState({ editModal: true, siteData: { ...this.props.siteData, EDIT_CENTRAL: true, EDIT_ADDRESS: true, EDIT_CONTACT: true }, error: false });
	}

	async onOpenRowModal(value) {
		await this.props.actions.getSiteData(value);
		await this.setState({ selectedIndex: value, index: value });
	}

	getEntries({ filterForValidateSites, filterForActiveSites }) {
		if (this.state.searchTerm === '' || !this.state.searchTerm) {
			this.props.actions.getSiteList(
				this.props.rowsPerPage,
				this.props.currentPage,
				this.props.sortColumn,
				this.props.order,
				this.props.selectedSortItem,
				filterForActiveSites,
				filterForValidateSites
			);
			this.props.actions.getSiteCount(this.props.selectedSortItem, filterForActiveSites, filterForValidateSites);
		} else if (this.state.searchTerm !== '') {
			this.props.actions.onSearchSite(
				this.state.searchTerm,
				this.props.sortColumn,
				this.props.order,
				this.props.selectedSortItem,
				filterForActiveSites,
				filterForValidateSites
			);
		}
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<SiteSettingsModal
					open={this.state.selectedIndex !== null}
					close={() => this.setState({ selectedIndex: null })}
					selectedIndex={this.state.selectedIndex}
					language={this.props.language}
					onValidateSite={() => this.onValidateSite()}
					site={
						this.props.siteData === {} ? null : this.props.siteData
					}
					userPermissions={this.props.permissions}
					isAuthenticated={this.props.isAuthenticated}
				/>
				<CustomModal
					fullWidth
					open={this.state.editModal}
					title={lang.EDIT_SITE[this.props.language]}
					content={
						<Grid container spacing={2}>
							<SiteNewForm
								edit
								gps
								addData={this.state.siteData}
								language={this.props.language}
								updateFieldRaw={(field, value) => {
									let placeholder = this.state.siteData;
									placeholder[field] = value;
									this.setState({
										siteData: placeholder,
										edited: true,
									});
								}}
								updateField={(e, length) =>
									this.setState({
										siteData: {
											...this.state.siteData,
											[e.target
												.name]: e.target.value
													.toString()
													.slice(0, length),
										},
										edited: true,
									})
								}
								error={this.state.error}
								updateError={(value) =>
									this.setState({ error: value })
								}
							/>
						</Grid>
					}
					close={() => {
						this.setState({
							editModal: false,
						});
					}}
					execute={async () => {
						this.setState({ editModal: false });
						await this.props.actions.editSiteData(
							this.props.userAuth.id,
							{
								...siteAddFormat(this.state.siteData),
								EDIT_ADDRESS: this.state.siteData.EDIT_ADDRESS,
								EDIT_CONTACT: this.state.siteData.EDIT_CONTACT,
								EDIT_CENTRAL: this.state.siteData.EDIT_CENTRAL,
								OBJID: this.props.siteData.OBJID,
							}
						);
						await this.props.actions.onSiteValidation(
							this.state.index
						);
						await this.props.actions.getSiteList(
							this.props.rowsPerPage,
							0,
							"OBJID",
							"ASC"
						);
					}}
					validate={
						!this.state.error &&
						this.state.siteData.SITE_CODE &&
						this.state.siteData.SITE_CODE.length > 0 &&
						this.state.siteData.SITE_NAME &&
						this.state.siteData.SITE_NAME.length > 0 &&
						this.state.siteData.PHONE &&
						this.state.siteData.PHONE.length > 0 &&
						this.state.siteData.REGION &&
						this.state.siteData.REGION.length > 0 &&
						this.state.siteData.CITY &&
						this.state.siteData.CITY.length > 0 &&
						this.state.siteData.STREET_NAME &&
						this.state.siteData.STREET_NAME.length > 0 &&
						this.state.siteData.CONTACT_LAST_NAME &&
						this.state.siteData.CONTACT_LAST_NAME.length > 0 &&
						this.state.siteData.CONTACT_FIRST_NAME &&
						this.state.siteData.CONTACT_FIRST_NAME.length > 0 &&
						this.state.siteData.CONTACT_PHONE &&
						this.state.siteData.CONTACT_PHONE.length > 0
					}
					language={this.props.language}
				/>
				{this.props.isFetchingSiteList === false ? (
					<SiteSetup
						language={this.props.language}
						data={this.props.siteList}
						theme={this.props.theme}
						rowsPerPage={this.props.rowsPerPage}
						onRowsPerPageChange={(value) =>
							this.props.actions.onRowsPerPageChange(value)
						}
						openRowModal={(value) => this.onOpenRowModal(value)}
						onSearch={() => {
							this.props.actions.onSearchSite(
								this.state.searchTerm,
								"OBJID",
								"ASC",
								this.props.selectedSortItem,
								this.state.filterForActiveSites,
								this.state.filterForValidateSites
							);
							this.props.actions.setCurrentPage(0);
						}}
						getSearchTerm={(value) =>
							this.setState({ searchTerm: value })
						}
						searchTerm={this.state.searchTerm}
						area={this.props.selectedSortItem}

						filterForActiveSites={this.state.filterForActiveSites}
						setFilterForActiveSites={(value) => {
							this.setState({ filterForActiveSites: value })
							this.getEntries({ filterForActiveSites: value, filterForValidateSites: this.state.filterForValidateSites })
						}}
						filterForValidateSites={this.state.filterForValidateSites}
						setFilterForValidatedSites={(value) => {
							this.setState({ filterForValidateSites: value })
							this.getEntries({ filterForValidateSites: value, filterForActiveSites: this.state.filterForActiveSites })
						}}
						filterOpen={this.state.filterOpen}
						setFilterOpen={() => this.setState({ filterOpen: !this.state.filterOpen })}
					/>
				) : (
					<TableSkeleton />
				)}
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		language: state.config.language,
		theme: state.config.theme,
		rowsPerPage: state.config.rowsPerPage,
		currentPage: state.config.currentPage,
		siteList: state.site.siteList,
		isFetchingSiteList: state.site.isFetchingSiteList,
		sortColumn: state.config.sortColumn,
		order: state.config.order,
		loadedPages: state.config.loadedPages,
		siteData: state.cust.siteData,
		userAuth: state.auth.userData,
		isAuthenticated: state.auth.isAuthenticated,
		permissions: state.auth.permissions,
		selectedSortItem: state.config.selectedSortItem,
	};
};
const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actionCreator, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SitesView);
