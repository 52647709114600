import React, { useState, useEffect, useCallback } from 'react';

import { Grid, TextField, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import axios from "axios";
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as requestHeaders from "../../../../Constants/requestHeaders";
import moment from "moment";

const AddInstallationModal = (props) => {
    const {
        language,
        open,
        close,
        siteId,
        userId,
        sendNotification
    } = props;

    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [reason, setReason] = useState("");
    const [typeList, setTypeList] = useState([]);
    const [type, setType] = useState(null);

    /**
     * For the contracts with the subtype of installation
     * @type = 2
     * @subType = 2
     */
    const getInstallationProjects = useCallback(async () => {
        try {
            const response = await axios.get('/api/contracts_by_type', {
                ...requestHeaders.getGetHeader(),
                params: {
                    type: 2,
                    subType: 2,
                    siteId,
                    REASON: "Instalare",
                    SUBTITLE: "INSTALLATION"
                }
            });
            setContracts(response.data.contracts);
        } catch (error) {
            console.log(error);
        }
    },
        [siteId]
    );

    const getTypeList = async () => {
        try {
            const res = await axios.get('/api/task_typelist', requestHeaders.getGetHeader());

            setTypeList(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const createProject = useCallback(async () => {
        try {
            const response = await axios.post('/api/create_installation', {
                contractId: selectedContract.OBJID,
                ownerId: userId,
                SITE_OBJID: siteId,
                reason,
                type
            }, requestHeaders.getPostHeader()
            );
            sendNotification(response.status)
        } catch (error) {
            sendNotification(400)
            console.log(error);
        }
    },
        [selectedContract, userId, reason, type]
    );

    useEffect(() => {
        getInstallationProjects();
        getTypeList();
    }, [siteId, open]);

    return (
        <CustomModal
            open={open}
            title={lang.NEW_INSTALLATION[language]}
            fullWidth
            content={
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Contracte"
                            value={selectedContract}
                            onChange={(e) => setSelectedContract(e.target.value)}
                            variant="outlined"
                        >
                            {
                                contracts.map((c, index) => <MenuItem key={`contract_menu_item_${index}`} value={c}>
                                    {`${lang[c.SUB_TYPE][language]} Nr. ${c.CONTRACT_NO} / ${moment(c.START_DATE).format('DD-MM-YYYY')} (${c.CONTRACTOR_NAME})`}
                                </MenuItem>)
                            }
                            {
                                contracts.length === 0 && <div style={{ padding: "10px" }}>Nu exista contracte de instalare</div>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            required
                            fullWidth
                            variant="outlined"
                        >
                            <InputLabel>{lang.TYPE[language]}</InputLabel>
                            <Select
                                name="TYPE"
                                fullWidth
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {typeList &&
                                    typeList.length > 0 &&
                                    typeList.map((type) => {
                                        return (
                                            <MenuItem key={type.OBJID} value={type.OBJID}>
                                                {lang[type.TYPE][language]}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            <input
                                tabIndex={-1}
                                readOnly
                                autoComplete="off"
                                style={{ opacity: 0, height: 0, border: 0, padding: 0 }}
                                value={type}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="REASON"
                            multiline
                            rowsMax="4"
                            label={lang.REASON[language]}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </Grid>
                </Grid>
            }
            close={close}
            execute={() => {
                createProject();
                close();
            }}
            language={language}
            validate={selectedContract !== null}
        />
    )
};

export default AddInstallationModal;
