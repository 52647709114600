import React from 'react';
import { Grow, Button, Box } from '@material-ui/core';
import { InsertDriveFileRounded } from '@material-ui/icons';

export default function ReportSection(props) {
    const { language, data, classes, onClickHandler, index } = props;

    return (
        <React.Fragment>
            <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
                <Button
                    onClick={() => onClickHandler(index)}
                    className={classes.margin}
                    variant="outlined"
                >
                    <InsertDriveFileRounded className={classes.leftIcon} />
                    <Box component="div" whiteSpace="noWrap">
                        {data.TITLE}
                    </Box>
                </Button>
            </Grow>
        </React.Fragment>
    );
}