export const BILL_NO = {
    "en": "Bill number",
    "ro": "Număr factură"
};

export const IS_PAYED = {
    "en": "Paied",
    "ro": "Încasare"
};

export const RECIPE = {
    "en": "Recipe",
    "ro": "Chitanță"
};

export const RECIPE_NR = {
    en: "Recipe No",
    ro: "Plata numărul"
}

export const BANK = {
    "en": "Bank",
    "ro": "Bancă"
};

export const BF = {
    "en": "Recipe (PF)",
    "ro": "Bon fiscal"
};

export const PAY_METHOD = {
    "en": "Pay method",
    "ro": "Modalitate de plată"
};

export const RECIPE_NO = {
    "en": "Recipe number",
    "ro": "Număr chitanță"
};

export const RECIPE_DATE = {
    "en": "Recipe date",
    "ro": "Dată chitanță"
};

export const CASH_PF = {
    "en": "Cahs (PF)",
    "ro": "Numerar (PF)"
};

export const CASH_PJ = {
    "en": "Cahs (PJ)",
    "ro": "Numerar (PJ)"
};

export const RECIPE_NO_PF = {
    "en": "Recipe number",
    "ro": "Număr bon fiscal"
};

export const RECIPE_DATE_PF = {
    "en": "Recipe date",
    "ro": "Dată bon fiscal"
};

export const BANK_TRANSFER_DATE = {
    "en": "Bank transfer date",
    "ro": "Dată transfer bancar"
};

export const BILL_SUM = {
    "en": "Bill value",
    "ro": "Sumă facturată"
};

export const PARTIAL_PAYED = {
    "en": "Partial payed",
    "ro": "Plătit parțial"
};

export const PAY_VALIDATION = {
    "en": "Pay validation",
    "ro": "Validare încasare"
};

export const BILLING_TYPE = {
    "en": "Billing type",
    "ro": "Tip"
};

export const PAYMENT_TYPE = {
    "en": "Payment type",
    "ro": "Modalitate de plată"
};

export const PAYMENT_RECIPE_OR_FREE = {
    en: "Bill/Free",
    ro: "Facturat / Aprobat gratuit"
}

export const USER_THAT_CREATE_BILL = {
    "en": "Bill create by",
    "ro": "Factură creată de"
};

export const USER_THAT_APPROVE_FREE = {
    "en": "User that approved free",
    "ro": "Aprobat gratuit de"
};

export const USER_THAT_WILL_BILL = {
    "en": "User that will bill",
    "ro": "Utilizator ce va factura"
};

export const BANK_TRANSFER = {
    "en": "Bank transfer",
    "ro": "Transfer bancar"
};

export const PJ_CASH = {
    "en": "Cash pj",
    "ro": "Numerar (PJ - chitanță)"
};

export const PF_CASH = {
    "en": "Cash pf",
    "ro": "Numerar (PF - bon fiscal)"
};

export const WILL_BE_BILL_BY_USER = {
    "en": "Will be bill by user",
    "ro": "Va fi facturat"
};

export const WAS_APPROVE_FREE_BY_USER = {
    "en": "Approve free",
    "ro": "Aprobat gratuit"
};

export const CONTRACTOR = {
    "en": "Contractor",
    "ro": "Firmă"
};

export const TASK_BILL_HISTORY = {
    "en": "Task Bill history",
    "ro": "Istoric facturi/încasări tickete"
};

export const BILL_NO_FROM = {
    "en": "Bill no from",
    "ro": "Număr factură de la"
};

export const BILL_NO_TO = {
    "en": "Bill no to",
    "ro": "Număr factură până"
};

export const BILL_VALUE_FROM = {
    "en": "Bill value from",
    "ro": "Valoare factură de la (RON)"
};

export const BILL_VALUE_TO = {
    "en": "Bill value to",
    "ro": "Valoare factură până (RON)"
};

export const BILL_VALUE = {
    "en": "Bill value",
    "ro": "Valoare factură"
};

export const FILTER_WITH = {
    "en": "Filter with",
    "ro": "Filtrează după : "
};

export const RECIPE_NO_FROM = {
    en: "Recipe number from",
    ro: "Număr chitanță de la"
};

export const RECIPE_NO_TO = {
    en: "Recipe number to",
    ro: "Număr chitanță până la"
};

export const RECIPE_DATE_FROM = {
    en: "Recipe date from",
    ro: "Dată chitanță de la"
};

export const RECIPE_DATE_TO = {
    en: "Recipe date to",
    ro: "Dată chitanță până la"
};

export const BILL_CREATED_BY = {
    en: "Bill created by",
    ro: "Factură întocmită de "
}

export const CLOSE_IN = {
    en: "Close date",
    ro: "Dată finalizare"
}

export const CREATE_IN = {
    en: "Create date",
    ro: "Dată creare"
}

export const CASHING_VALIDATION = {
    en: "Cashing validation",
    ro: "Validare încasare"
}

export const ADD_RECIPE = {
    en: "Add recipe",
    ro: "Adaugă chitanță"
}

export const COMPLETED_BILL_PAY = {
    en: "Completed pay bill",
    ro: "Factură plătită complet"
}

export const TASK_CLOSE_COMMENT = {
    en: "Task close commnent",
    ro: "Comentariu închidere ticket"
}

export const TASK_CLOSED_BY = {
    en: "Closed by",
    ro: "Închis de"
}

export const COMMENT_TASK_HISTORY = {
    en: "View task comments",
    ro: "Vezi comentarii ticket"
}
export const BILL_DATE = {
    en: "Bill date",
    ro: "Dată factură"
}
export const RECIPE_VALUE = {
    en: "Recipe value",
    ro: "Valoare chitanță",
}
export const UNPAY_SUM = {
    en: "Unpaid sum",
    ro: "Sumă neachitată",
}

export const BILLING_STATUS = {
    en: "Billing status",
    ro: "Status factură"
}

export const BILL_VALIDATION = {
    en: "Bill validation",
    ro: "Validare încasare"
}

export const RECEIPT_VALIDATION = {
    en: "Receipt validation",
    ro: "Validare facturare"
}

export const COMPLETED_PAYED = {
    en: "Completed",
    ro: "Completată"
}