import React, { useState, useEffect } from "react";
import axios from "axios";
import * as requestHeaders from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import SortBar from "./SortBar";
import { user_activity_intervention } from "../../../Components/Misc/Tables/TableHeaders";
import TaskCommentHistory from "../../../Components/Modals/TaskCommentHistory";

const ActivityReportsIntervention = (props) => {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [data, setData] = useState([]);

    const [commentsHistoryModal, setCommentsHistoryModal] = useState(false);
    const [history, setHistory] = useState([]);
    const [interventionId, setInterventionId] = useState(null);

    const getUserInterventionActivity = async ({ startDate, endDate, selectedUser }) => {
        const response = await axios.get('/api/get_user_intervention_actions', {
            ...requestHeaders.getGetHeader(),
            params: {
                startDate: startDate,
                endDate: endDate,
                id: selectedUser
            }
        });
        if (response.status === 200 || response.status === 204) {
            setData(response.data.data);
        } else {
            setData([]);
        }
    }

    const getInterventionHistory = async ({ id }) => {
        const response = await axios.get('/api/intervention_history', {
            ...requestHeaders.getGetHeader(),
            params: {
                id
            }
        });

        if (response.status === 200 || response.status === 204) {
            setHistory(response.data.history);
        } else {
            setHistory([]);
        }
    };

    useEffect(() => {
        if (interventionId !== null)
            getInterventionHistory({ id: interventionId })
    }, [interventionId]);

    const downloadAction = ({ startDate, endDate, selectedUser }) => {
        fetch(`/api/download_activity_report_intervention?startDate=${startDate}&endDate=${endDate}&id=${selectedUser}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                },
                method: 'GET',
                responseType: 'arraybuffer',
            }
        )
            .then(response => response.blob())
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = `Activitate_desfasurata_Intervenție.xlsx`;
                link.click();
            })
            .catch(err => console.log(err))
    };

    return (
        <>
            <TaskCommentHistory
                open={commentsHistoryModal}
                tiny={true}
                classes={{}}
                data={history}
                language={language}
                close={() => {
                    setCommentsHistoryModal(false);
                }}
            />
            <SortBar
                language={language}
                filter={getUserInterventionActivity}
                onDownload={downloadAction}
                usersDepartment={14}
            />
            <MaterialTable
                tiny={true}
                title={"Raport activitate intervenții"}
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={user_activity_intervention(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={async (id) => {
                    await setInterventionId(id);
                    await setCommentsHistoryModal(true);
                }}
                clickLabel="INTERVENTION_ID"
                hideAddBtn={true}
            />
        </>
    )
}

export default ActivityReportsIntervention;