import React from 'react';
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import SiteContracts from '../SiteContracts';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { contractSorting } from '../../../../Functions/contractSorting';


export default function ActiveContracts(props) {
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const { language, open, close, site, siteId, updateContract, terminateContract, setAddData, updateField, updateFieldRaw } = props;


    const [contractList, setContractList] = React.useState([]);

    React.useEffect(() => {
        if (siteId !== undefined)
            fetch(`/api/get_site_contracts?id=${siteId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    let contracts = []
                    response.map(res => {
                        if (res.ACTIVE === 1) {
                            if (res.END_DATE === null) {
                                res.INDETERMINATE = true
                            }
                            contracts.push(res);
                        }
                    })
                    setContractList(contractSorting(props.userPermissions, contracts));
                })
                .catch(err => console.log(err))
    }, [siteId, open]);

    return (
        <CustomModal
            fullScreen
            open={open}
            title={lang.ACTIVE_CONTRACTS[language]}
            content={
                <SiteContracts
                    updateContract={updateContract}
                    terminateContract={terminateContract}
                    contractList={contractList}
                    language={language}
                    tiny={tiny}
                    close={close}
                    setAddData={setAddData}
                    updateField={updateField}
                    updateFieldRaw={updateFieldRaw}
                    addData={props.addData}
                />
            }
            close={close}
            execute={close}
            language={language}
            noButtons
            onlyBack
        />
    )
}