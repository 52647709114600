import React, { Component } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    VisibilityRounded,
    CheckCircle,
    RemoveCircleOutline,
    AttachMoney,
} from '@material-ui/icons';
import {
    makeStyles,
    CssBaseline,
    useTheme,
    Grid,
    useMediaQuery,
} from '@material-ui/core';

import * as actionCreators from '../../actions/actions';
import * as lang from '../../Constants/strings';
import * as requestHeaders from '../../Constants/requestHeaders';
import { tasks_history } from '../../Components/Misc/Tables/TableHeaders';
import { downloadFile } from '../../Functions/fileDownload';
import { formatDateFromDb } from '../../Functions/dateFunctions';
import CustomButtonList from '../../Components/Modals/CustomButtonList';
import CustomModal from '../../Components/Modals/CustomModal';
import CustomDrawer from '../../Components/Modals/CustomDrawer';
import MaterialTable from '../../Components/Misc/Tables/MaterialTableSimple';
import InstallationModal from "./InstallationModal";
import HistoryFilterBar from "../Tasks/Components/HistoryFilterBar.jsx";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(6, 2, 6, 2),
        width: 'auto'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fabGrid: {
        margin: theme.spacing(-5, 0, 0, 0),
    },
}));

function InstallationSetup(props) {
    const classes = useStyles();
    const { language, data, area, startDate, endDate } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container component="main" className={classes.margin} spacing={0}>
            <Grid item xs={12} className={classes.fabGrid}>
                <MaterialTable
                    tiny={tiny}
                    title={
                        area === null ? lang.TASKS[language] + ' din ' + startDate + '  până  ' + endDate : lang.TASKS[language] + ' din ' + startDate + '  până  ' + endDate + ` pe zona ${area.AREA}`
                    }
                    language={language}
                    data={data}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(value) => setRowsPerPage(value)}
                    header={tasks_history(language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={(id) => props.openRowModal(id)}
                    clickLabel="TASK_ID"
                    hideAddBtn={true}
                />
            </Grid>
        </Grid>
    )
};

function TaskSettingsModal(props) {
    const { language, close, open } = props;
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    return (
        <React.Fragment>
            <CustomDrawer
                open={open && tiny}
                close={close}
                list={[
                    {
                        primary: lang.GO_TO(lang.TASK[language])[language],
                        secondary: "",
                        icon: <VisibilityRounded />,
                        action: () => props.setTaskModal(),
                        render: props.permissionsCheck[0]
                    }
                ]}
            />
            <CustomModal
                open={open && !tiny}
                title={lang.TASKS[language]}
                content={
                    <Grid container spacing={2}>
                        <CustomButtonList
                            close={close}
                            language={language}
                            tiny={tiny}
                            list={[
                                {
                                    text: lang.GO_TO(lang.TASK[language])[language],
                                    style: {
                                        icon: <VisibilityRounded className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: () => props.setTaskModal(),
                                    render: props.permissionsCheck[0]
                                },
                            ]}
                            withBack
                        />
                    </Grid>
                }
                close={close}
                execute={close}
                language={language}
                noButtons
            />
        </React.Fragment>
    );
};

class InstallationHistory extends Component {
    state = {
        isFetchingHistory: true,
        taskList: [],
        searchTerm: null,
        sortColumn: null,
        selectedArea: null,
        selectedContractor: null,
        selectDateModal: false,
        selectAreaModal: false,
        startDate: null,
        endDate: null,
        selectedIndex: null,
        settingModal: false,
        taskModalOpen: false,
        trainees: [{
            NAME: "",
            SIGN: "",
        }],

        project: {
            NAME: "",
            NUMBER: "",
            SIGN: "",
        },
        showProject: false,
        journal: {
            NAME: "",
            NUMBER: "",
            SIGN: "",
        },
        opt: [false, false, false, false],
        showJournal: false,
        billModal: false,
        permissionsCheck: [],
        InstallationModalOpen: false
    };

    componentWillMount() {
        let date = new Date;
        this.props.actions.getAreaList();
        this.setState({ startDate: new Date(), endDate: date });
        this.getTaskHistoryList(formatDateFromDb(date, 8), formatDateFromDb(date, 8), 0);
    }

    async getTaskHistoryList(startDate, endDate, area, contractorId) {
        try {
            const res = await axios.get(`/api/close_installations`, {
                params: {
                    startDate,
                    endDate,
                    area,
                    contractorId
                },
                ...requestHeaders.getGetHeader()
            });

            let list = [];
            for (const item of res.data.data) {
                list.push({
                    ...item,
                    END_DATE: item ? formatDateFromDb(item.END_DATE, 8) : "",
                    BILL_STATUS: (item.BILL_VALIDATION === 1 && item.RECEIPT_VALIDATION === 1) && < CheckCircle color='primary' /> || item.RECEIPT_VALIDATION === 1 && <AttachMoney color='secondary' /> || <RemoveCircleOutline color='secondary' />,
                    TYPE_TRANS: item && item.TYPE !== null ? lang[item.TYPE][this.props.language] : '',
                })
            }

            this.setState({ taskList: [...list] });
        } catch (e) {
            this.setState({ taskList: [] });
            console.error("[FETCH_ERROR]: " + e);
        }
    }

    handleSelect(e) {
        e.persist();
        this.setState({ selectedArea: e.target.value });
    };

    updateProject = (field, value) => {
        if (field === 'SHOW')
            this.setState({ showProject: !this.state.showProject });
        else {
            let placeholder = this.state.project;
            placeholder[field] = value;
            this.setState({ project: placeholder });
        }
    };

    updateJournal = (field, value) => {
        if (field === 'SHOW')
            this.setState({ showJournal: !this.state.showJournal });
        else {
            let placeholder = this.state.journal;
            placeholder[field] = value;
            this.setState({ journal: placeholder });
        }
    };

    resetProject = () => {
        this.setState({ project: { NAME: "", NUMBER: "", SIGN: "" }, showProject: false })
    };

    resetJournal = () => {
        this.setState({ journal: { NAME: "", NUMBER: "", SIGN: "" }, showJournal: false })
    };

    addRemoveTrainee = (index) => {
        let placeholder = this.state.trainees;
        if (index === false)
            placeholder.push({ NAME: "", SIGN: "" });
        else
            placeholder.splice(index, 1);
        this.setState({ trainees: placeholder });
    };
    updateTrainee = (index, field, value) => {
        let placeholder = this.state.trainees;
        placeholder[index][field] = value;
        this.setState({ trainees: placeholder }, console.log(this.state.trainees));
    };

    resetTrainees = () => {
        this.setState({ trainees: [{ NAME: "", SIGN: "" }] });
    };

    updateOpt = (index) => {
        let placeholder = this.state.opt;
        placeholder[index] = !placeholder[index];
        this.setState({ opt: placeholder }, console.log(this.state.opt));
    };

    resetOpt = () => {
        this.setState({ opt: [false, false, false, false] })
    };

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <InstallationModal
                    siteData={this.props.siteData}
                    language={this.props.language}
                    open={this.state.InstallationModalOpen}
                    close={() => {
                        this.setState({ InstallationModalOpen: false });
                    }}
                    installationId={this.state.selectedIndex}
                    user={this.props.userAuth}
                    userPermissions={this.props.permissions}
                    isAuthenticated={this.props.isAuthenticated}
                    onNotificationOpen={(type, message) =>
                        this.props.actions.onNotificationOpen(type, lang[message], 6000)
                    }
                    addCustomerContract={(data) => {
                        this.props.actions.addCustomerContract(
                            this.props.userAuth.id,
                            {
                                ...data,
                                SITE_OBJID: this.props.siteData.OBJID,
                                SALE_CONTACT: this.props.userAuth.id,
                            },
                            this.props.language
                        );
                    }}
                    isUploadingFile={this.props.isUploadingFile}
                    cust={{
                        data: this.props.customerData,
                        loading:
                            this.props.isFetchingCustomerData ||
                            this.props.isFetchingSiteData,
                        taskData: this.props.taskData,
                        initialLoad: this.props.taskData.task
                            ? () =>
                                this.props.actions.getCustomerData(
                                    this.props.taskData.task.CONTACT_OBJID
                                )
                            : () => console.log("No customer id"),
                    }}
                    task={{
                        opt: this.state.opt,
                        updateOpt: this.updateOpt,
                        resetOpt: this.resetOpt,
                        project: this.state.project,
                        journal: this.state.journal,
                        showProject: this.state.showProject,
                        showJournal: this.state.showJournal,
                        updateProject: this.updateProject,
                        updateJournal: this.updateJournal,
                        journalData: this.state.journalData,
                        updateJournalData: this.updateJournalData,
                        resetJournalData: this.resetJournalData,
                        resetProject: this.resetProject,
                        resetJournal: this.resetJournal,
                        trainees: this.state.trainees,
                        updateTrainee: this.updateTrainee,
                        addRemoveTrainee: this.addRemoveTrainee,
                        resetTrainees: this.resetTrainees,
                        userId: this.props.userAuth.id,
                        data: this.props.taskData,
                        loading: this.props.isFetchingTaskData,
                        downloadFile: downloadFile,
                        fetchData: async () => {
                            await this.props.actions.getTaskData(this.state.selectedIndex);
                            await this.props.actions.getSiteData(this.props.taskData.task.SITE_OBJID);
                        },
                        newTaskAction: this.newTaskAction,
                        resetTaskAction: (changeType, changeFrom, changeTo, note) =>
                            this.props.actions.resetTaskAction(this.props.userAuth.id, {
                                TASK_OBJID: this.props.taskData.task.OBJID,
                                REASON: this.props.taskData.task.REASON,
                                CHG_USER: this.props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                                NOTE: note,
                            }),
                        newTaskComment: (changeType, changeFrom, changeTo, note, file) =>
                            this.props.actions.newTaskComment(this.props.userAuth.id, {
                                TASK_OBJID: this.props.taskData.task.OBJID,
                                REASON: this.props.taskData.task.REASON,
                                CHG_USER: this.props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                                NOTE: note,
                                FILE: file,
                            }),
                    }}
                />
                <TaskSettingsModal
                    open={this.state.settingModal}
                    close={() => this.setState({ settingModal: false })}
                    selectedIndex={this.state.selectedIndex}
                    language={this.props.language}
                    permissionsCheck={this.state.permissionsCheck}
                    isAuthenticated={this.props.isAuthenticated}
                    setTaskModal={() => this.setState({ taskModalOpen: true })}
                    setBillModal={() => this.setState({ billModal: true })}
                />

                <HistoryFilterBar
                    language={this.props.language}
                    startDate={this.state.startDate}
                    setStartDate={(value) => {
                        this.setState({ startDate: value });
                        this.getTaskHistoryList(formatDateFromDb(value, 15), formatDateFromDb(this.state.endDate, 15), this.state.selectedArea?.AREA, this.state.selectedContractor?.OBJID);
                    }}
                    endDate={this.state.endDate}
                    setEndDate={(value) => {
                        this.setState({ endDate: value })
                        this.getTaskHistoryList(formatDateFromDb(this.state.startDate, 15), formatDateFromDb(value, 15), this.state.selectedArea?.AREA, this.state.selectedContractor?.OBJID);
                    }}
                    selectedArea={this.state.selectedArea}
                    setSelectedArea={(value) => {
                        this.setState({ selectedArea: value });
                        this.getTaskHistoryList(formatDateFromDb(this.state.startDate, 15), formatDateFromDb(this.state.endDate, 15), value?.AREA, this.state.selectedContractor?.OBJID);
                    }}
                    selectedContractor={this.state.selectedContractor}
                    setSelectedContractor={(value) => {
                        this.setState({ selectedContractor: value });
                        this.getTaskHistoryList(formatDateFromDb(this.state.startDate, 15), formatDateFromDb(this.state.endDate, 15), this.state.selectedArea?.AREA, value?.OBJID);
                    }}
                    reset={() => {
                        const date = new Date();
                        this.getTaskHistoryList(formatDateFromDb(date, 15), formatDateFromDb(date, 15));
                        this.setState({ startDate: date, endDate: date, selectedArea: null, selectedContractor: null });
                    }}
                    areaList={this.props.areaList}
                />
                <InstallationSetup
                    data={this.state.taskList}
                    language={this.props.language}
                    tiny={false}
                    openRowModal={(value) => this.setState({ selectedIndex: value, InstallationModalOpen: true })}
                    area={this.state.selectedArea}
                    startDate={formatDateFromDb(this.state.startDate, 8)}
                    endDate={formatDateFromDb(this.state.endDate, 8)}
                    openDateModal={() => {
                        this.setState({ selectDateModal: true })
                    }}
                    openAreaModal={() => {
                        this.setState({ selectAreaModal: true })
                    }}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        areaList: state.nom.areaList,
        isUploadingFile: state.task.isUploadingFile,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        siteData: state.cust.siteData,
        userAuth: state.auth.userData,
        taskData: state.task.taskData,
        isFetchingTaskData: state.task.isFetchingTaskData,
        isFetchingCustomerData: state.cust.isFetchingCustomerData,
        customerData: state.cust.customerData,
    }
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallationHistory);
