import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LanguageButton from '../LanguageButton';
import DarkModeButton from '../DarkModeButton';
import * as lang from '../../../Constants/strings';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded'
import AccountIcon from '@material-ui/icons/AccountCircleRounded'
import VerifiedIcon from '@material-ui/icons/VerifiedUserRounded';
import {
    VpnKey
} from '@material-ui/icons';
import { Divider, Badge, Grid, Button } from '@material-ui/core';
import { redirectTo } from '../../../Functions/linkFunctions';
import Can from '../Can';
import CustomModal from "../../Modals/CustomModal";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
        margin: theme.spacing(0.5),
    },
    title: {
        flexGrow: 1,
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.5),
    },
    keyGeneration: {
        borderRadius: "41px",
        margin: "15px",
    }
}));

export default function MenuAppBar(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [keyGenerationModal, setKeyGenerationModal] = useState(false);
    const [key, setKey] = useState("");
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const myProfile = () => {
        redirectTo(`/profile?id=${props.userID}`)
        setAnchorEl(null);
    };
    const dashboard = () => {
        redirectTo(`/dashboard`);
        setAnchorEl(null);
    };

    const generateKey = async () => {
        try {
            let response = await axios.get("/api/generate_delete_token", {
                headers: {
                    authorization: localStorage.token
                }
            });
            setKey(response.data.token);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={classes.root}>
            <Can neededPermissions={{ parent: "SUPER", name: "SUPER_DELETE" }}>
                <CustomModal
                    open={keyGenerationModal}
                    fullWidth
                    close={() => setKeyGenerationModal(false)}
                    content={
                        <Grid alignItems="center" justify="center" container style={{ height: "auto"}}>
                            {key.length > 0 &&
                                <>
                                    <div
                                        style={{
                                            fontSize: "24px",
                                            wordWrap: "break-word",
                                            width: "100%",
                                            marginBottom: "10px",
                                            color: "red"
                                        }}
                                    >
                                        Key-ul are o durata de viata de 30 min
                                    </div>
                                    <div
                                        style={{
                                            fontSize: "16px",
                                            wordWrap: "break-word",
                                            width: "100%",
                                            marginBottom: "10px",
                                            color: "yellow",
                                            margin: "5px"
                                    }}
                                    >
                                        {key}
                                    </div>
                                </>
                            }
                            <Button
                                className={classes.keyGeneration}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={generateKey}
                            >
                                Generare key
                            </Button>
                        </Grid>
                    }
                    language={props.language}
                    noButtons
                />
            </Can>
            <AppBar position="absolute">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={props.toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        RPG
                    </Typography>
                    <div>
                        <Grid container justify='flex-end' alignItems='stretch' >
                            <Grid item xs='auto'>
                                <LanguageButton
                                    language={props.language}
                                    onLanguageChange={props.onLanguageChange}
                                    color="inherit"
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs='auto'>
                                <DarkModeButton
                                    theme={props.theme}
                                    onDarkModeToggle={props.onDarkModeToggle}
                                    color="inherit"
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs='auto'>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    className={classes.menuButton}
                                >
                                    <AccountCircle />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={myProfile}>
                                <Typography>
                                    <AccountIcon className={classes.menuIcon} />
                                </Typography>
                                <Typography>
                                    {lang.MY_PROFILE[props.language]}
                                </Typography>
                            </MenuItem>

                            <Divider />

                            <Can neededPermissions={{ parent: "DASHBOARD", name: "DASHBOARD" }}>
                                <MenuItem onClick={dashboard}>
                                    <Typography>
                                        <VerifiedIcon className={classes.menuIcon} />
                                    </Typography>
                                    <Typography>
                                        {lang.DASHBOARD[props.language]}
                                    </Typography>
                                </MenuItem>
                            </Can>
                            <Can neededPermissions={{ parent: "SUPER", name: "SUPER_DELETE" }}>
                                <MenuItem onClick={() => setKeyGenerationModal(true)}>
                                    <Typography color="secondary">
                                        <VpnKey className={classes.menuIcon} />
                                    </Typography>
                                    <Typography>
                                        Generare key de sterger
                                    </Typography>
                                </MenuItem>
                            </Can>
                            <Divider />

                            <MenuItem onClick={props.LogoutAndRedirect}>
                                <Typography color="secondary">
                                    <LogoutIcon className={classes.menuIcon} />
                                </Typography>
                                <Typography color="secondary">
                                    {lang.LOGOUT[props.language]}
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div >
    );
}
