import {CssBaseline} from "@material-ui/core";
import { useParams } from 'react-router-dom';

const Page = () => {
    const params = useParams();

    return (
        <div style={{ display: 'flex', justifyItems: "center", alignItems: "center"}}>
            <CssBaseline/>
            <div
                style={{ width: "100%", height:'90vh', marginTop: '8vh', background: `url(/api/site/image?id=${params.id}) center center no-repeat`, backgroundSize: 'contain' }}
            />
        </div>
    )
};


export default Page;