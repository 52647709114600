const getGoogleMapLink = (latitude, longitude) => {
    console.log(latitude, longitude);
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&zoom=14`
};

export const getGoogleMapRouteLink = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${address}&zoom=14`
};

export const googleMapRouteLink = (fromLatitude, fromLongitude, toLatitude, toLongitude) => {
    return `https://www.google.com/maps/dir/?api=1&origin=${fromLatitude},${fromLongitude}&destination=${toLatitude},${toLongitude}&travelmode=car`;
}

export default getGoogleMapLink;