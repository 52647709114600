import React, { useEffect, useState } from 'react';
import { Grid, Paper, TextField, FormControlLabel, Switch, Button, makeStyles, Typography, Divider, MenuItem } from '@material-ui/core';
import { Email, RemoveCircle } from '@material-ui/icons';
import * as lang from '../../../../Constants/strings';
import CustomModal from '../../../../Components/Modals/CustomModal';
import { formatDateFromDb } from '../../../../Functions/dateFunctions';
import ReactAudioPlayer from 'react-audio-player';

const useStyles = makeStyles(theme => ({
    uploadAudioBtn: {
        margin: theme.spacing(1),
    },
    playerStyle: {
        margin: theme.spacing(1)
    },
    textStyle: {
        margin: theme.spacing(1)
    }
}));

export default function AddPhoneWarning(props) {
    const classes = useStyles();
    const { siteId, language, addModal } = props;
    const [warningList, setWarningList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [comment, setComment] = useState('');
    const [wasMailed, setWasMailed] = useState(false);

    const [phoneWarningModalStatus, setPhoneWarningModalStatus] = useState(false);
    const [phoneWarningId, setPhoneWarningId] = useState(null);

    const [phoneWarningInfo, setPhoneWarningInfo] = useState(null);
    const [event, setEvent] = useState('NO_ALARM');
    const [answerd, setAnswerd] = useState(true);
    const [audioFileInfo, setAudioFileInfo] = useState(null);
    const [audioFileUrl, setAudioFileUrl] = useState(null);

    const [audioFileBase64, setAudioFileBase64] = useState(null);
    const [rawAudioFile, setRawAudioFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');

    const audioFileRef = React.createRef();

    useEffect(() => {
        fetch(`/api/get_site_warning?id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                response.data.map(d => {
                    d.CREATE_TIME = formatDateFromDb(d.CREATE_TIME, 12);
                    if (d.WAS_MAILED === 1) {
                        d.WAS_MAILED = <FormControlLabel control={<Email style={{ marginRight: 5 }} color='primary' />} label={lang.SEND[language]} />
                    } else {
                        d.WAS_MAILED = <FormControlLabel control={<RemoveCircle style={{ marginRight: 5 }} color='secondary' />} label={lang.NOT_SEND[language]} />
                    }

                })
                setWarningList(response.data);
                setIsLoad(true);
            })
    }, [siteId]);

    useEffect(() => {
        if (phoneWarningId !== null) {
            fetch(`/api/get_phone_warning?id=${phoneWarningId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                },
            })
                .then(response => response.json())
                .then(response => {
                    setPhoneWarningInfo(response.data.phoneWarningInfo);
                    setAudioFileInfo(response.data.audioFileInfo);
                    if (response.data.fileBase64 !== null) {
                        getDataURL(response.data.fileBase64, response.data.audioFileInfo.FILE_TYPE, setAudioFileUrl);
                    } else {
                        setAudioFileUrl(null);
                    }
                })
        }
    }, [phoneWarningId]);

    function refetch() {
        setIsLoad(false);
        fetch(`/api/get_site_warning?id=${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                response.data.map(d => {
                    d.CREATE_TIME = formatDateFromDb(d.CREATE_TIME, 12);
                    if (d.WAS_MAILED === 1) {
                        d.WAS_MAILED = <FormControlLabel control={<Email style={{ marginRight: 5 }} color='primary' />} label={lang.SEND[language]} />
                    } else {
                        d.WAS_MAILED = <FormControlLabel control={<RemoveCircle style={{ marginRight: 5 }} color='secondary' />} label={lang.NOT_SEND[language]} />
                    }

                })
                setWarningList(response.data);
                setIsLoad(true);
            })
    }

    function addWarning() {
        fetch(`/api/add_site_phone_warning`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify(
                {
                    FK2SITE_OBJID: siteId,
                    CONTENT: comment,
                    WAS_MAILED: wasMailed,
                    FILE: audioFileBase64,
                    FILE_TYPE: fileType,
                    EVENT: event,
                    ANSWERD: answerd,
                    SITE_ID: siteId,
                    USER_ID: props.userId
                }
            )
        })
            .then(response => response.json())
            .then(response => {
                if (response.status > 400) {
                    props.onNotificationOpen('warning', response.message)
                } else {
                    props.onNotificationOpen('success', response.message);
                    setComment('');
                    setWasMailed(false);
                    refetch();
                }
            })

    }

    function getData(audioFile, callback) {
        let reader = new FileReader();
        reader.onload = (event) => {
            let data = event.target.result.split(',');
            let decodedImageData = btoa(data[1]);
            callback(decodedImageData);
        };
        reader.readAsDataURL(audioFile);
    }

    function getDataURL(b64Data, type, callback) {
        const byteCharacters = atob(b64Data); // the actuale converting from base 64 to binary
        let dataUrl = `data:${type};base64,` + byteCharacters; // add the missing bits
        callback(dataUrl); // call the callback function with the dataUrl as atribute
    };

    return (
        <React.Fragment>
            <CustomModal
                open={addModal}
                fullWidth
                title={lang.NEW_PHONE_NOTIFICATION[language]}
                content={
                    <Grid container spacing={2} justify='center' alignItems='center' alignContent='center'>
                        <TextField className={classes.textStyle} fullWidth variant='outlined' multiline value={comment} onChange={(e) => setComment(e.target.value)} label={lang.COMMENT[language]} />
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                variant='outlined'
                                label={lang.EVENT[language]}
                                value={event}
                                onChange={e => setEvent(e.target.value)}
                            >
                                <MenuItem value='NO_ALARM'>{lang.NO_ALARM[language]}</MenuItem>
                                <MenuItem value='NO_DISARMAMENT'>{lang.NO_DISARMAMENT[language]}</MenuItem>
                                <MenuItem value='EFRACTON_SIGNAL'>{lang.EFRACTON_SIGNAL[language]}</MenuItem>
                                <MenuItem value='NO_ARMAMENT'>{lang.NO_ARMAMENT[language]}</MenuItem>
                                <MenuItem value='INCIDENT'>{lang.INCIDENT[language]}</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                variant='outlined'
                                label={lang.WAS_ANSWERD[language]}
                                value={answerd}
                                onChange={e => setAnswerd(e.target.value)}
                            >
                                <MenuItem value={true}>{lang.YES[language]}</MenuItem>
                                <MenuItem value={false}>{lang.NO[language]}</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid container justify='center' alignContent='center' alignItems='center'>
                            <Grid item xs={12}>
                                <Button className={classes.uploadAudioBtn} color='primary' variant='contained' fullWidth onClick={() => audioFileRef.current.click()} >{lang.UPLOAD_AUDIO_FILE[props.language]}</Button>
                            </Grid>
                        </Grid>
                        {
                            audioFileBase64 !== null &&
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Typography className={classes.textStyle} variant='h6'>{`${lang.UPLOADED_FILE[props.language]}: ${fileName}`}</Typography>
                                <ReactAudioPlayer
                                    src={rawAudioFile}
                                    controls
                                    className={classes.playerStyle}
                                />

                            </>
                        }
                        <input
                            type="file"
                            accept="audio/*"
                            style={{ display: "none" }}
                            ref={audioFileRef}
                            onChange={(e) => {
                                e.persist();
                                let mainType = e.target.files[0].type.split('/');
                                if (mainType[0] === 'audio') {
                                    getData(e.target.files[0], setAudioFileBase64);
                                    setFileName(e.target.files[0].name);
                                    setFileType(e.target.files[0].type);
                                    setRawAudioFile(URL.createObjectURL(e.target.files[0]));
                                }
                            }}
                            tabIndex={-1}
                        />
                    </Grid>
                }
                close={() => props.close()}
                execute={() => {
                    addWarning();
                    props.close();
                }}
                language={language}
                validate={comment.length > 0}
            />
        </React.Fragment>
    )
};
