import React from 'react';
import * as lang from '../../../../Constants/strings';
import axios from 'axios';
import * as requestHeaders from "../../../../Constants/requestHeaders";
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    MenuItem,
    FormControlLabel,
    Switch,
    Paper,
    List
} from '@material-ui/core';
import { RemoveCircle, PeopleAltRounded, EmailRounded, ReceiptRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    dividerMargin: {
        margin: theme.spacing(1)
    },
    addBtnStyle: {
        margin: theme.spacing(1),
        width: '95%',
    },
    selectFieldStyle: {
        margin: theme.spacing(1),
        width: '95%',
    },
    textStyle: {
        margin: theme.spacing(1),
    },
    formControlLabelStyle: {
        margin: theme.spacing(1)
    },
    paperStyle: {
        margin: theme.spacing(1),
        maxHeight: 340,
        overflow: 'auto'
    },
    userMailPaper: {
        margin: theme.spacing(1),
        width: '98%',
        height: '70',
        backgroundColor: '#90a4ae'
    },
    iconsStyle: {
        margin: theme.spacing(1),
    },
    labelText: {
        margin: theme.spacing(1)
    }
}));

function UserEmail(props) {
    const { language, userData, removeUser } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.userMailPaper}>
            <Grid container justify='space-between' alignItems='center' direction='row' >
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.iconsStyle}
                        control={<PeopleAltRounded className={classes.labelText} color='primary' />}
                        label={`${lang.USERNAME[language]}: ${userData.USERNAME}`}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        className={classes.iconsStyle}
                        control={<EmailRounded className={classes.labelText} color='secondary' />}
                        label={`${lang.EMAIL[language]}: ${userData.EMAIL}`}
                    />
                </Grid>

                <Button onClick={() => removeUser()}><RemoveCircle color='secondary' /></Button>

            </Grid>
        </Paper>
    )
};

const Email = (props) => {
    const classes = useStyles();
    const { removeEmail, email, language } = props;

    const remove = React.useCallback(
        () => {
            removeEmail();
        },
        [email],
    );

    return (
        <>
            <Paper className={classes.userMailPaper}>
                <Grid container justify='space-between' alignItems='center' direction='row' >
                    <Grid item xs={9}>
                        <FormControlLabel
                            className={classes.iconsStyle}
                            control={<EmailRounded className={classes.labelText} color='secondary' />}
                            label={`${lang.EMAIL[language]}: ${email}`}
                        />
                    </Grid>
                    <Button onClick={() => remove()}><RemoveCircle color='secondary' /></Button>
                </Grid>
            </Paper>
        </>
    );
};

export default function MailingListEditor(props) {
    const classes = useStyles();
    const { language, userList, mailListData, refetchMailList } = props;
    // Store the USER_ID
    // Is use to add a new user to the email list 
    const [selectedUserId, setSelectedUserId] = React.useState(null);

    // The Switch controls
    const [customerEmail, setCustomerEmail] = React.useState(false);
    const [siteEmail, setSiteEmail] = React.useState(false);
    const [contractorEmail, setContractorEmail] = React.useState(false);
    const [areaEmail, setAreaEmail] = React.useState(false);

    // The mailing list
    const [mailingList, setMailingList] = React.useState([]);
    const [emailList, setEmailList] = React.useState([]);

    // Email
    const [email, setEmail] = React.useState("");

    React.useEffect(() => {
        getMailingListContent();
        refetchMailList();
        getEmail();
    }, [props.mailListId]);

    React.useEffect(() => {
        setCustomerEmail(props.mailListData.MAIL_CUSTOMER === 1 ? true : false);
        setSiteEmail(props.mailListData.MAIL_SITE === 1 ? true : false);
        setContractorEmail(props.mailListData.MAIL_CONTRACTOR === 1 ? true : false);
        setAreaEmail(props.mailListData.MAIL_AREA_USERS === 1 ? true : false);
    }, [props.mailListData]);

    const updateDefaultEmails = (param, value) => {
        fetch(`/api/update_list_default_emails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ param: param, value: value, id: props.mailListId })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
            })
    };

    const AddUserToMailingList = () => {
        fetch(`/api/add_user_to_emailing_list`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ MAILING_LIST_OBJID: props.mailListId, USER_OBJID: selectedUserId })
        })
            .then(response => response.json)
            .then(response => {
                getMailingListContent();
            })
    };

    const getMailingListContent = () => {
        fetch(`/api/get_mailing_list?id=${props.mailListId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                console.log(response, " mailing List", props.mailListId)
                setMailingList(response.data);
            })
    };

    const getEmail = async () => {
        try {
            let response = await axios.get("/api/get_email_for_mailing_list", {
                ...requestHeaders.getGetHeader(),
                params: {
                    mailingList: props.mailListId
                }
            });

            if (response.status === 200) {
                setEmailList(response.data);
            } else {
                setEmail({});
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addEmailToMailingList = async (email, mailingList) => {
        try {
            let result = await axios.post("/api/add_email_to_mailing_list", {
                email: email,
                mailingList: mailingList
            }, requestHeaders.getPostHeader());

            if (result.status === 201) {
                getEmail();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const removeEmailFromMailingList = async (emailObjid) => {
        try {
            let result = await axios.delete("/api/remove_email", {
                ...requestHeaders.getDeleteHeader(),
                params: {
                    emailObjid: emailObjid,
                },
                withCredentials: true
            });

            if (result.status === 200) {
                getEmail();
            }
        } catch (error) {
            console.log(error);
        }
    };

    function removeUserFromMailList(ENTRY_OBJID, FK2_MAILING_LIST, FK2USER_OBJID) {
        console.log(ENTRY_OBJID, FK2_MAILING_LIST, FK2USER_OBJID);
        fetch(`/api/remove_user_from_email_list`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ OBJID: ENTRY_OBJID, FK2_MAILING_LIST: FK2_MAILING_LIST, FK2USER_OBJID: FK2USER_OBJID })
        })
            .then(response => response.json)
            .then(response => {
                console.log(response);
                getMailingListContent();
            })
    };

    /*  
        This are the email lists:
           - 'PHONE_WARNING'
           - 'CLOSE_INTERVENTION'
           - 'GPS_ERROR'
           - 'INTERVENTION_CREATION'
           - 'CLIENT_ADD_OR_EDIT'
           - 'SITE_ADD_OR_EDIT'
           - 'CONTRACT_ADD_OR_EDIT'
           - 'CONTRACT_RESILATION'
           - 'TASK_CREATION'
           - 'TASK_CLOSING'
           - 'TASK_POSTPONE'
           - 'MAINTENANCE_CREATION'
           - 'MAINTENANCE_CLOSING'
           - 'MAINTENANCE_POSTPONE'
    */

    return (
        <Grid container justify='space-between' alignItems='center' alignContent="space-around">

            { props.mailListData.ACTION !== "GPS_ERROR" &&
                <>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.textStyle}
                            variant='h6'
                        >
                            {lang.SELECT_CUSTOMER_EMAIL_LIST[language]}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider className={classes.dividerMargin} />
                    </Grid>

                    <Grid item xs={12}>
                        {
                            props.mailListData.ACTION !== "CLIENT_ADD_OR_EDIT" &&
                            <FormControlLabel
                                className={classes.formControlLabelStyle}
                                control={<Switch checked={siteEmail === true} onChange={() => {
                                    updateDefaultEmails('MAIL_SITE', !siteEmail);
                                    setSiteEmail(!siteEmail);
                                }} />}
                                label={lang.SITE_EMAIL[language]}
                            />
                        }

                        <FormControlLabel
                            className={classes.formControlLabelStyle}
                            control={<Switch checked={customerEmail === true} onChange={() => {
                                updateDefaultEmails('MAIL_CUSTOMER', !customerEmail);
                                setCustomerEmail(!customerEmail)
                            }} />}
                            label={lang.CUSTOMER_EMAIL[language]}
                        />

                        {
                            props.mailListData.ACTION !== "PHONE_WARNING" &&
                            props.mailListData.ACTION !== "CLIENT_ADD_OR_EDIT" &&
                            props.mailListData.ACTION !== "SITE_ADD_OR_EDIT" &&
                            <FormControlLabel
                                className={classes.formControlLabelStyle}
                                control={<Switch checked={contractorEmail === true} onChange={() => {
                                    updateDefaultEmails('MAIL_CONTRACTOR', !contractorEmail);
                                    setContractorEmail(!contractorEmail);
                                }} />}
                                label={lang.CONTRACTOR_EMAIL[language]}
                            />
                        }
                        {
                            props.mailListData.ACTION !== "CLIENT_ADD_OR_EDIT" &&
                            <FormControlLabel
                                className={classes.formControlLabelStyle}
                                control={<Switch checked={areaEmail === true} onChange={() => {
                                    updateDefaultEmails('MAIL_AREA_USERS', !areaEmail);
                                    setAreaEmail(!areaEmail);
                                }} />}
                                label="Email zonă"
                            />
                        }
                    </Grid>
                </>
            }

            <Grid item xs={12}>
                <Divider className={classes.dividerMargin} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6' className={classes.textStyle} >
                    {lang.ADD_USER_TO_EMAILING_LIST[language]}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerMargin} />
            </Grid>
            <Grid item xs={3}>
                <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    className={classes.addBtnStyle}
                    disabled={selectedUserId === null}
                    onClick={() => AddUserToMailingList()}
                >
                    {lang.ADD_USER[language]}
                </Button>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    select
                    fullWidth
                    className={classes.selectFieldStyle}
                    variant='outlined'
                    label={lang.USERS[language]}
                    value={selectedUserId}
                    onChange={e => setSelectedUserId(e.target.value)}
                >
                    <MenuItem value={null}>{lang.NONE[language]}</MenuItem>
                    {
                        userList && userList.map(user => {
                            return <MenuItem key={user.USER_ID + '_Menu_Item'} value={user.USER_ID}>{user.USERNAME}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerMargin} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6' className={classes.textStyle} >
                    {lang.ADD_EMAIL_TO_MAILING_LIST[language]}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerMargin} />
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    addEmailToMailingList(email, props.mailListId);
                    setEmail('');
                }}>
                    <Grid container justify='space-between' alignItems='center' alignContent="space-around">
                        <Grid item xs={3}>
                            <Button
                                color='primary'
                                variant='contained'
                                fullWidth
                                type="submit"
                                disabled={email === ""}
                                className={classes.addBtnStyle}
                                onSubmit={() => addEmailToMailingList(email, props.mailListId)}
                            >
                                {lang.ADD_EMAIL[language]}
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                required
                                className={classes.selectFieldStyle}
                                variant='outlined'
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                label={lang.EMAIL[language]}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerMargin} />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.textStyle} variant='h6'>{lang.MAILING_LIST[language]}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.dividerMargin} />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.textStyle} variant='h6'>{lang.USERS[language]}</Typography>
            </Grid>
            <Grid item xs={12}>
                <List className={classes.paperStyle}>
                    {
                        mailingList.map(list => {
                            return <UserEmail language={language} userData={list} removeUser={() => removeUserFromMailList(list.OBJID, list.FK2_MAILING_LIST, list.FK2USER_OBJID)} />
                        })
                    }
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.textStyle} variant='h6'>{lang.EMAILS[language]}</Typography>
            </Grid>
            <Grid item xs={12}>
                <List className={classes.paperStyle}>
                    {
                        emailList.map(email => {
                            return <Email language={language} email={email.EMAIL} removeEmail={() => removeEmailFromMailingList(email.OBJID)} />
                        })
                    }
                </List>
            </Grid>
        </Grid >
    )
};
