export const getGetHeader = () => {
    return {
        method: 'GET',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': localStorage.token,
        }
    };
};

export const getPostHeader = () => {
    return {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': localStorage.token,
        }
    };
};

export const getDeleteHeader = () => {
    return {
        method: "DELETE",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': localStorage.token,
        }
    }
};

export const getPatchHeader = () => {
    return {
        method: "PATCH",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': localStorage.token,
        }
    }
};
