import React from "react";
import {
    TextField,
    Grid,
    makeStyles
} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles({
    textField: {
        margin: '10px'
    }
})

const ProjectDetails = (props) => {
    const {
        data
    } = props;

    const classNames = useStyles();

    return (
        <Grid container spacing={2} justify="space-evenly" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Obiectiv"
                    defaultValue=""
                    className={classNames.textField}
                    value={data ? data.siteData.SITE_NAME : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Addresa"
                    className={classNames.textField}
                    defaultValue=""
                    value={data ? data.siteData.ADDRESS : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Nr. aviz politie"
                    className={classNames.textField}
                    defaultValue=""
                    value={data ? data.project.PERMIT_NO : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Data avizului"
                    className={classNames.textField}
                    defaultValue=""
                    value={data && data.project.PERMIT_DATE ? moment(data.project.PERMIT_DATE).format("DD-MM-YYYY") : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Nr. proiect"
                    className={classNames.textField}
                    defaultValue=""
                    value={data ? data.project.PROJECT_NO : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Data proiect"
                    className={classNames.textField}
                    defaultValue=""
                    value={data && data.project.PROJECT_DATE ? moment(data.project.PROJECT_DATE).format("DD-MM-YYYY") : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="AWB"
                    className={classNames.textField}
                    defaultValue=""
                    value={data ? data.project.AWB : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid xs={12} md={5}>
                <TextField
                    disabled
                    label="Ultimul update"
                    className={classNames.textField}
                    defaultValue=""
                    value={data ? moment(data.project.updateAt).format('DD-MM-YYYY HH:mm') : ""}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
        </Grid>
    )
};

export default ProjectDetails;
