import React from "react";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography, CircularProgress } from '@material-ui/core';

const LISTBOX_PADDING = 8;

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const renderRow = (props) => {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];

const VirtualizeAutocomplete = (props) => {
    const classes = useStyles();

    const {
        label,
        variant,
        onSelect,
        renderElementLabel,
        options,
        fullWidth,
        onInputChange,
        loading
    } = props;

    return (
        <Autocomplete
            id="virtualize-demo"
            fullWidth={fullWidth}
            disableListWrap
            classes={classes}
            ListboxComponent={ListboxComponent}
            renderGroup={renderGroup}
            options={options}
            onChange={(e, newValue) => onSelect(e, newValue)}
            onInputChange={onInputChange}
            getOptionLabel={e => e[renderElementLabel]}
            renderInput={(params) => <TextField
                {...params}
                variant={variant}
                label={label}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }} />}
            renderOption={(option) => <Typography noWrap>{option[renderElementLabel]}</Typography>}
        />
    );
};

VirtualizeAutocomplete.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.string,
    onSelect: PropTypes.func,
    renderElementLabel: PropTypes.string,
    onInputChange: PropTypes.func,
    options: PropTypes.array,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool
};

VirtualizeAutocomplete.defaultProps = {
    label: "",
    variant: "outlined",
    onSelect: () => { },
    onInputChange: () => { },
    renderElementLabel: "",
    options: [],
    fullWidth: false,
    loading: false
};

export default VirtualizeAutocomplete;