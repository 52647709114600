export function extractQueryFrontend(url) {
    let step1 = url.substring(1, url.length).split('&');
    let step2 = {};
    for (let i in step1) {
        let stepAux = step1[i].split('=');
        step2[stepAux[0]] = stepAux[1];
    }
    return step2;
}

export function checkSpecialChar(string, splChars) {
    for (var i = 0; i < string.length; i++) {
        if (splChars.indexOf(string.charAt(i)) !== -1) {
            return true;
        }
    }
    return false;
}