import React from 'react';
import { Grid, Paper, Avatar, Typography, Button, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import getAvatarUri from '../../../Functions/getDataUriAvatar';
import EditIcon from '@material-ui/icons/EditRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import * as lang from '../../../Constants/strings';
import Grow from '@material-ui/core/Grow';
import { CanVisible } from '../../../Components/Misc/Can';
import { checkPermissions } from '../../../Functions/permissionHide';

const useStyles = makeStyles(theme => ({
    bigAvatar: {
        margin: theme.spacing(-1.3),
        width: 150,
        height: 150,
    },
    paper: {
        backgroundImage: `url(/api/image?image=paperHeader-03.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        padding: theme.spacing(1),
    },
}));

export default function ProfileMain(props) {
    const classes = useStyles();
    return (
        <Grid item xs={12} md={6} container direction="column">
            <Grow in={props.edit === false} style={{ transitionDelay: props.edit === false ? '100ms' : '0ms' }}>
                <Paper className={classes.paper} >
                    <Grid container justify="center" alignItems="center" direction="column">
                        <Box border={5} borderRadius={100} color="white" marginTop="50px" >
                            <Avatar alt={props.userData ? props.userData.basic.AVATAR_ID + "" : ""} src={getAvatarUri(props.userData ? props.userData.basic.AVATAR_ID : 1)} className={classes.bigAvatar} />
                        </Box>
                        {props.userData && <Typography variant="h6">{props.userData.basic.FIRST_NAME}{" "}{props.userData.basic.LAST_NAME}</Typography>}
                        {props.userData && <Typography variant="body2">{props.userData.basic.USERNAME}</Typography>}
                        <div className={classes.buttonWrapper}>
                            <CanVisible
                                visible={props.userData && props.userID === props.userData.basic.USER_ID || checkPermissions(props.permissions, {parent: "USERS", name: "EDIT_USER"})}
                            >
                                {props.tiny
                                    ?
                                    <IconButton
                                        onClick={props.toggleEdit}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    :
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        className={classes.button}
                                        onClick={props.toggleEdit}
                                    >
                                        <EditIcon className={classes.leftIcon} />
                                        {lang.EDIT[props.language]}
                                    </Button>
                                }
                            </CanVisible>
                        </div>
                    </Grid>
                </Paper>
            </Grow>
        </Grid>
    );
}
export function SkeletonProfileMain(props) {
    return (
        <Grid item xs={12} md={6} container direction="column">
            <Skeleton component={Paper} className={props.classes.paper} height="45vh">
                <Grid container justify="center" alignItems="center" direction="column">
                    <Box marginTop="50px" >
                        <Skeleton variant="circle" height="150px" width="150px" />
                    </Box>
                    <Skeleton width="15%" />
                    <Skeleton width="10%" />
                </Grid>
            </Skeleton>
        </Grid>
    );
}