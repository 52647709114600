import {useState, useCallback, useEffect} from "react";
import { makeStyles,
    useTheme,
    Grid,
    useMediaQuery,
    Fab,
 } from "@material-ui/core";
 import {
    CalendarToday,
 } from '@material-ui/icons';

import axios from "axios";
import moment from "moment";

import MaterialTable from "../../Components/Misc/Tables/MaterialTableSimple";
import { project } from "../../Components/Misc/Tables/TableHeaders";
import * as requestHeaders from "../../Constants/requestHeaders";
import ProjectActionModal from "../Site/SiteSingleViewComponents/Projects/ProjectActionModal";
import * as lang from "../../Constants/strings";
import SelectIntervalModal from "./SelectIntervalModal";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(6, 2, 6, 2),
        width: 'auto'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fabGrid: {
        margin: theme.spacing(-5, 0, 0, 0),
    },
    fab: {
        width: '100%'
    },
}));

const ProjectTableHistory = (props) => {
    const {
        language,
        userId,
        userPermission
    } = props;

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedEntry, setSelectEntry] = useState(null);
    const [dateModal, setDateModal] = useState(false);
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const getData = useCallback(async (startDate, endDate) => {
        try {
            const resposne = await axios.get(`/api/history_project?startDate=${startDate}&endDate=${endDate}`, requestHeaders.getGetHeader());
            setData(resposne.data.map(e => ({
                ...e,
                STATUS_TRANS: lang[e.STATUS + "_t"][language],
                LAST_UPDATE_TRANS: moment(e.LAST_UPDATE).format("DD-MM-YYYY HH:mm"),
                CREATE_TIME_TRANS: moment(e.CREATE_TIME).format("DD-MM-YYYY HH:mm")
            })));
        } catch (error) {
            console.log(error);
        }
    }, []);
    return (
        <>
            <SelectIntervalModal
                open={dateModal}
                onClose={() => setDateModal(false)}
                language={props.language}
                callback={async (startDate, endDate) => {
                   await  getData(startDate, endDate)
                   setDateModal(false)
                }}
            />
            <ProjectActionModal
                open={selectedEntry !== null}
                language={language}
                close={() => {
                    setSelectEntry(null);
                }}
                projectId={selectedEntry}
                userId={userId}
                userPermission={userPermission}
            />
            <Grid container component="main" justify='flex-end' alignItems='stretch' spacing={0}>
                <Grid item xs={12} sm={4} md={2}>
                    <Fab
                        size={tiny ? 'medium' : 'small'}
                        variant="extended"
                        color="primary"
                        aria-label="add"
                        onClick={() => setDateModal(true)}
                        className={classes.fab}
                    >
                        <CalendarToday
                            className={classes.leftIcon}
                        />
                        {lang.DATE_SELECT[language]}
                    </Fab>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                container
                style={{ paddingRight: "0px" }}
                direction="column"
            >
                <MaterialTable
                    hideAddBtn={true}
                    tiny={false}
                    title="Istoric proiecte"
                    language={language}
                    data={data}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(value) => setRowsPerPage(value)}
                    header={project(language)}
                    dense={false}
                    openRowModal={(id) => setSelectEntry(id)}
                    clickLabel="ID"
                />
            </Grid>
        </>
    )
};

export default ProjectTableHistory;