import {Divider, Grid, Paper, Typography} from "@material-ui/core";

const InventoryItem = (props) => {
    const {
        productName,
        quantity,
        um
    } = props;

    return (
        <Paper style={{ padding: "10px", margin: "10px" }} elevation={2}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h6" color="textPrimary">
                        {productName}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h6" color="textPrimary" alignItems="center">
                        {`${quantity} (${um})`}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default InventoryItem;