import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import * as lang from '../../../Constants/strings';

export default function SiteContactNewForm(props) {
    const { addData, language, updateField, disableEdit } = props;

    return (
        <React.Fragment>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    required
                    fullWidth
                    variant="outlined"
                    name="CONTACT_LAST_NAME"
                    label={lang.LAST_NAME[language]}
                    value={addData.CONTACT_LAST_NAME ? addData.CONTACT_LAST_NAME : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    required
                    fullWidth
                    variant="outlined"
                    name="CONTACT_FIRST_NAME"
                    label={lang.FIRST_NAME[language]}
                    value={addData.CONTACT_FIRST_NAME ? addData.CONTACT_FIRST_NAME : ""}
                    onChange={(e) => updateField(e, 250)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    required
                    fullWidth
                    variant="outlined"
                    name="CONTACT_PHONE"
                    label={lang.PHONE[language]}
                    value={addData.CONTACT_PHONE ? addData.CONTACT_PHONE : ""}
                    onChange={(e) => updateField(e, 50)}
                    type="number"
                />
            </Grid>
        </React.Fragment>
    );
}