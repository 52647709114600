import { SignInUser, SignInFailure, OpenConfirmWindow, LogoutAndRedirect } from './authActions';
import { redirectTo } from '../Functions/linkFunctions';
import { onNotificationOpen } from './configActions';
import * as lang from '../Constants/strings';

export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_RECIEVE = "USER_DATA_RECIEVE";

export function onUserDataRequest() {
    return {
        type: USER_DATA_REQUEST,
    }
};

export function onUserDataRecieve(data) {
    return {
        type: USER_DATA_RECIEVE,
        payload: {
            data
        }
    }
};

export function getUserData(id, type) {
    return (dispatch) => {
        dispatch(onUserDataRequest());
        const options = {
            method: 'GET',
            headers: {
                'authorization': localStorage.token,
            }
        }
        return fetch(`/api/user_data?id=${id}&type=${type}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                dispatch(onUserDataRecieve(response.data));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUserDataRecieve(null));
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_USER_DATA_BEGIN = "UPDATE_USER_DATA_BEGIN";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";

export function onUpdateUserDataBegin() {
    return {
        type: UPDATE_USER_DATA_BEGIN,
    }
};

export function onUpdateUserDataSuccess(data) {
    return {
        type: UPDATE_USER_DATA_SUCCESS,
        payload: {
            data
        }
    }
};

export function updateUserData(id, data) {
    console.log(data);
    return (dispatch) => {
        dispatch(onUpdateUserDataBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify(data)
        }
        return fetch(`/api/update_user_data`, options)
            .then(response => {
                switch (response.status) {
                    case 400: {
                        // dispatch(onToastNotificationOpen(response.message));
                        console.log(response.message)
                        break;
                    }
                    case 404: {
                        // history.push('/404');
                        break;
                    }
                    case 403: {
                        if (localStorage.token) {
                            // dispatch(checkToken(permissions));
                        }
                        else {
                            dispatch(SignInFailure(response.status));
                            redirectTo('/sign_in');
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                dispatch(onUpdateUserDataSuccess(data));
                dispatch(onNotificationOpen("success", lang[response.message], 6000));
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUserDataRecieve(null));
                console.log('caught it!', err);
            })
    }
}

export const ADD_USER_BEGIN = "ADD_USER_BEGIN";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

export function onAddUserBegin() {
    return {
        type: ADD_USER_BEGIN,
    }
};
export function onAddUserSuccess() {
    return {
        type: ADD_USER_SUCCESS,
    }
};
export function onAddUserFailed() {
    return {
        type: ADD_USER_FAILED,
    }
};

export function addNewUser(id, data) {
    return (dispatch) => {
        dispatch(onAddUserBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_user`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onAddUserFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddUserSuccess());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddUserFailed());
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";

export function onUpdateUserPasswordSuccess(token) {
    return {
        type: UPDATE_USER_PASSWORD_SUCCESS,
        payload: {
            token
        }
    }
}

export function updateUserPassword(id, data) {
    console.log(data);
    return (dispatch) => {
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/update_user_password`, options)
            .then(response => {
                switch (response.status) {
                    case 400: {
                        // dispatch(onToastNotificationOpen(response.message));
                        console.log(response.message)
                        break;
                    }
                    case 404: {
                        // history.push('/404');
                        break;
                    }
                    case 403: {
                        if (localStorage.token) {
                            // dispatch(checkToken(permissions));
                        }
                        else {
                            dispatch(SignInFailure(response.status));
                            redirectTo('/sign_in');
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    localStorage.setItem('token', response.data);
                    dispatch(onUpdateUserPasswordSuccess(response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .catch(err => {
                dispatch(onUserDataRecieve(null));
                console.log('caught it!', err);
            })
    }
}
export function resetUserPassword(id, data) {
    // console.log(data);
    return (dispatch) => {
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }
        return fetch(`/api/reset_user_password?auth=${id}&id=${data}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                if (response.error === 1) {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .catch(err => {
                dispatch(onUserDataRecieve(null));
                console.log('caught it!', err);
            })
    }
}

export const ADD_ROLE_BEGIN = "ADD_ROLE_BEGIN";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILED = "ADD_ROLE_FAILED";

export function onAddRoleBegin() {
    return {
        type: ADD_ROLE_BEGIN,
    }
};
export function onAddRoleSuccess() {
    return {
        type: ADD_ROLE_SUCCESS,
    }
};
export function onAddRoleFailed() {
    return {
        type: ADD_ROLE_FAILED,
    }
};

export function addNewRole(id, data) {
    return (dispatch) => {
        dispatch(onAddRoleBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id })
        }
        return fetch(`/api/add_role`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect());
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    dispatch(onAddRoleFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
                else {
                    dispatch(onAddRoleSuccess());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onAddRoleFailed());
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_USER_ROLE_BEGIN = "UPDATE_USER_ROLE_BEGIN";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAILED = "UPDATE_USER_ROLE_FAILED";

export function onUpdateUserRoleBegin() {
    return {
        type: UPDATE_USER_ROLE_BEGIN,
    }
};
export function onUpdateUserRoleSuccess(roleName, roleID) {
    return {
        type: UPDATE_USER_ROLE_SUCCESS,
        payload: {
            roleName,
            roleID,
        }
    }
};
export function onUpdateUserRoleFailed() {
    return {
        type: UPDATE_USER_ROLE_FAILED,
    }
};

export function updateUserRole(authId, originalRoleId, id, data) {
    console.log(data);
    return (dispatch) => {
        dispatch(onUpdateUserRoleBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id, authId, originalRoleId })
        }
        return fetch(`/api/update_user_role`, options)
            .then(response => {
                switch (response.status) {
                    case 404: {
                        // history.push('/404');
                        return response.json();;
                    }
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                if (response.error === 0) {
                    dispatch(onUpdateUserRoleSuccess(data.roleSelected, response.data));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateUserRoleFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateUserRoleFailed());
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_USER_DEPARTMENT_BEGIN = "UPDATE_USER_DEPARTMENT_BEGIN";
export const UPDATE_USER_DEPARTMENT_SUCCESS = "UPDATE_USER_DEPARTMENT_SUCCESS";
export const UPDATE_USER_DEPARTMENT_FAILED = "UPDATE_USER_DEPARTMENT_FAILED";

export function onUpdateUserDepartmentBegin() {
    return {
        type: UPDATE_USER_DEPARTMENT_BEGIN,
    }
};
export function onUpdateUserDepartmentSuccess(name, id) {
    return {
        type: UPDATE_USER_DEPARTMENT_SUCCESS,
        payload: {
            name,
            id,
        }
    }
};
export function onUpdateUserDepartmentFailed() {
    return {
        type: UPDATE_USER_DEPARTMENT_FAILED,
    }
};

export function updateUserDepartment(authId, origId, id, data) {
    console.log(data);
    return (dispatch) => {
        dispatch(onUpdateUserDepartmentBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, id, authId, origId })
        }
        return fetch(`/api/update_user_department`, options)
            .then(response => {
                switch (response.status) {
                    case 404: {
                        // history.push('/404');
                        return response.json();;
                    }
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                if (response.error === 0) {
                    dispatch(onUpdateUserDepartmentSuccess(response.data, data.departmentSelected));
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
                else {
                    dispatch(onUpdateUserDepartmentFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateUserDepartmentFailed());
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_USER_LOCKED = "UPDATE_USER_LOCKED";

export function onUpdateUserLock(data) {
    return {
        type: UPDATE_USER_LOCKED,
        payload: {
            data,
        }
    }
};

export function updateUserLock(authId, USER_OBJID, LOCKED) {
    return (dispatch) => {
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ USER_OBJID, LOCKED, authId })
        }
        return fetch(`/api/update_user_lock`, options)
            .then(response => {
                switch (response.status) {
                    case 404: {
                        // history.push('/404');
                        return response.json();;
                    }
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                if (response.error === 0) {
                    if (LOCKED === 1) {
                        dispatch(onNotificationOpen("success", lang.USER_WAS_BLOCK, 6000));
                    } else if (LOCKED === 0) {
                        dispatch(onNotificationOpen("success", lang.USER_WAS_UNBLOCK, 6000));
                    }
                }
                else {
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                console.log('caught it!', err);
            })
    }
}

export const UPDATE_ROLE_BEGIN = "UPDATE_ROLE_BEGIN";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILED = "UPDATE_ROLE_FAILED";

export function onUpdateRoleBegin() {
    return {
        type: UPDATE_ROLE_BEGIN,
    }
};
export function onUpdateRoleSuccess() {
    return {
        type: UPDATE_ROLE_SUCCESS,
    }
};
export function onUpdateRoleFailed() {
    return {
        type: UPDATE_ROLE_FAILED,
    }
};

export function updateRole(authId, data, roleID) {
    return (dispatch) => {
        dispatch(onUpdateRoleBegin());
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ ...data, authId, roleID })
        }
        return fetch(`/api/update_role`, options)
            .then(response => {
                switch (response.status) {
                    case 404: {
                        // history.push('/404');
                        return response.json();;
                    }
                    case 403: {
                        if (localStorage.token) {
                            console.log("HAS TOKEN")
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            console.log("NO TOKEN")
                            dispatch(LogoutAndRedirect());
                        }
                        break;
                    }
                    default: return response.json();
                }
            })
            .then((response) => {
                if (response.error === 0) {
                    console.log("SUCCESS");
                    dispatch(onUpdateRoleSuccess());
                    dispatch(onNotificationOpen("success", lang[response.message], 6000));
                }
                else {
                    console.log("FAILS");
                    dispatch(onUpdateRoleFailed());
                    dispatch(onNotificationOpen("warning", lang[response.message], 6000));
                }
            })
            .then(() => {
                dispatch(SignInUser(false, false, false, true));
            })
            .catch(err => {
                dispatch(onUpdateRoleFailed());
                console.log('caught it!', err);
            })
    }
}