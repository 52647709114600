import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { Grow, Tooltip } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import { FilterListRounded } from '@material-ui/icons';


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function EnhancedTableHead(props) {
    const { classes, orderDirection, orderBy, onRequestSort, header } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {header.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === index ? orderDirection : false}
                    >
                        <TableSortLabel
                            active={orderBy === index}
                            direction={orderDirection}
                            onClick={createSortHandler(index)}
                        >
                            {headCell.label}
                            {orderBy === index ? (
                                <span className={classes.visuallyHidden}>
                                    {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: "space-between",
        padding: theme.spacing(2),
    },
    wrapper: {
        display: 'flex',
        justifyContent: "flex-start",
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    search: {
    },
    margin: {
    },
    clear: {
        position: 'absolute',
        right: 0,
        marginRight: theme.spacing(3)
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    return (
        <Toolbar className={classes.root}>
            <React.Fragment>
                <div className={classes.wrapper}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        {props.title}
                    </Typography>
                </div>
                <span>
                    <Tooltip title={lang.ADD_FILTER[props.language]}>
                        <IconButton onClick={props.openFilterModal} className={classes.margin}>
                            <FilterListRounded fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </span>
            </React.Fragment>
        </Toolbar>
    );
};

const style = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

class EnhancedTable extends Component {
    handleRequestSort = (event, property) => {
        // Launch search ordered by the clicked column (with the additional filters if it is the case)
        // BACK TO PAGE #1
        let newOrderDirection = this.props.orderBy === property && this.props.orderDirection === 'asc' ? 'desc' : 'asc';
        this.props.actions.getAccountingCustomersSP({ ...this.props.filters, pageNumber: 0, orderBy: property, orderDirection: newOrderDirection, pageRows: this.props.pageRows });
    };

    handleChangeRowsPerPage = event => {
        // Launch search with the selected number of rows per page (with the additional filters if it is the case)
        // BACK TO PAGE #1
        let newPageRows = parseInt(event.target.value, 10);
        this.props.actions.getAccountingCustomersSP({ ...this.props.filters, pageNumber: 0, pageRows: newPageRows, orderBy: this.props.orderBy, orderDirection: this.props.orderDirection });
    }

    handleChangePage = (event, newPage) => {
        // Launch search for that specific page (with the additional filters if it is the case)
        this.props.actions.getAccountingCustomersSP({ ...this.props.filters, pageNumber: newPage, orderBy: this.props.orderBy, pageRows: this.props.pageRows, orderDirection: this.props.orderDirection, orderDirection: this.props.orderDirection });
    }

    getRow = (row, header, indexUp) => {
        let results = [];
        header.forEach((field, index) => {
            results.push(<TableCell key={indexUp * 1000 + index}>{row[field.id]}</TableCell>)
        })
        return results;
    }


    render() {
        const { header, classes, title, tiny, clickLabel } = this.props;
        const emptyRows = this.props.data ? this.props.pageRows - Math.min(this.props.pageRows, this.props.dataRows - this.props.pageNumber * this.props.pageRows) : this.props.pageRows;
        return (
            <div className={classes.root}>
                <Grow in={true} style={{ transitionDelay: '100ms' }}>
                    <Paper className={classes.paper}>
                        <EnhancedTableToolbar
                            tiny={tiny}
                            title={title}
                            language={this.props.language}
                            openFilterModal={this.props.openFilterModal}
                        />
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={this.props.dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    header={header}
                                    classes={classes}
                                    orderDirection={this.props.orderDirection}
                                    orderBy={this.props.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={this.props.dataRows}
                                />
                                <TableBody>
                                    {this.props.data && this.props.data.map((row, index) =>
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => this.props.openRowModal(index)}
                                        >
                                            {this.getRow(row, header, index)}
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (this.props.dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.props.dataRows}
                            rowsPerPage={this.props.pageRows}
                            page={this.props.pageNumber}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            labelRowsPerPage={lang.ROWS_PER_PAGE[this.props.language]}
                            labelDisplayedRows={lang.ROWS_FUNC[this.props.language]}
                        />
                    </Paper>
                </Grow>
            </div >
        );

    }

}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        data: state.sp.data,
        dataRows: state.sp.dataRows,
        pageNumber: state.sp.pageNumber,
        pageRows: state.sp.pageRows,
        orderBy: state.sp.orderBy,
        orderDirection: state.sp.orderDirection,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EnhancedTable));