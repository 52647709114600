import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import * as lang from "../../../Constants/strings";
import { taskS } from "../../../Components/Misc/Tables/TableHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";

export default function TaskTable(props) {
	const { data, language, tiny, loading, hasPermissionToAdd } = props;
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	return (
		<React.Fragment>
			{loading ? (
				<SkeletonTaskTable {...props} />
			) : (
				<Grid
					item
					xs={12}
					md={12}
					container
					style={{ paddingRight: "0px" }}
					direction="column"
				>
					<MaterialTable
						hideAddBtn={!hasPermissionToAdd}
						tiny={tiny}
						title={lang.TASK_TABLE[language]}
						language={language}
						data={data && data.length > 0 ? [...data] : []}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={(value) => setRowsPerPage(value)}
						header={taskS(language)}
						dense={false}
						// openAddModal={() => console.log("ADD")}
						openAddModal={props.openAddModal}
						openRowModal={props.openRowModal}
						// openRowModal={props.openRowModal}
						clickLabel="OBJID"
					/>
				</Grid>
			)}
		</React.Fragment>
	);
}

export function SkeletonTaskTable(props) {
	return (
		<Grid item xs={12} md={12} container direction="column">
			<Skeleton component={Paper} height="45vh">
				<Grid
					container
					justify="center"
					alignItems="center"
					direction="column"
				>
					<Skeleton width="80%" />
					<Skeleton width="80%" />
					<Skeleton width="80%" />
					<Skeleton width="80%" />
				</Grid>
			</Skeleton>
		</Grid>
	);
}
