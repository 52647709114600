import React from 'react';
import { contractOptions } from '../../../../Constants/misc';
import ExecEditForm from './ExecEditForm';
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as lang from '../../../../Constants/strings';

export default function EditModal(props) {
    const { selectedIndex, language } = props;
    const [editData, setEditData] = React.useState({ NAME: "", EMAIL: "", PHONE: "", OPT: [], IS_DEFAULT: false });
    const [active, setActive] = React.useState(false);
    const [typeList, setTypeList] = React.useState([]);

    function getItem(id) {
        fetch(`/api/executor?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setEditData({ ...response.data, ACTIVE: response.data.ACTIVE, OBJID: response.data.OBJID, IS_DEFAULT: response.data.IS_DEFAULT === 1, NR_REG: response.data.NR_REG, CUI: response.data.CUI })
                setActive(response.data.ACTIVE === 1 ? true : false)
            })
            .catch(err => console.log(err))
    }

    React.useEffect(() => {
        if (typeList.length === 0) {
            fetch(`/api/contract_types`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setTypeList(response);
                })
                .catch(err => console.log(err))
        }
    }, []);

    React.useEffect(() => {
        if (props.selectedIndex)
            getItem(props.selectedIndex);
        setEditData({ NAME: "", EMAIL: "", PHONE: "", OPT: [], IS_DEFAULT: false });
    }, [props.selectedIndex])

    return (
        <CustomModal
            fullWidth
            title={lang.EDIT_EXEC[language]}
            open={selectedIndex !== null}
            content={
                <ExecEditForm
                    toggleExecutor={() => props.toggleExecutor(editData)}
                    active={active}
                    typeList={typeList}
                    editData={editData}
                    id={selectedIndex}
                    language={language}
                    updateField={(e, lenght) => {
                        if (e.target.name !== "OPT")
                            setEditData({ ...editData, [e.target.name]: e.target.value.toString().slice(0, lenght) })
                        else
                            setEditData({ ...editData, OPT: e.target.value })

                    }}
                    updateFieldRaw={(field, value) => setEditData({ ...editData, [field]: value })}
                    close={props.close}
                />
            }
            close={props.close}
            execute={() => {
                props.updateExecutor({
                    NAME: editData.NAME,
                    PHONE: editData.PHONE ? editData.PHONE : null,
                    EMAIL: editData.EMAIL ? editData.EMAIL : null,
                    ADDRESS: editData.ADDRESS ? editData.ADDRESS : null,
                    NR_REG: editData.NR_REG ? editData.NR_REG : null,
                    CUI: editData.CUI ? editData.CUI : null,
                    OPT: editData.OPT.map(item => {
                        return {
                            "TYPE": parseInt(item.split("-")[0], 10),
                            "SUB_TYPE": parseInt(item.split("-")[1], 10)
                        }
                    }),
                    IS_DEFAULT: editData.IS_DEFAULT ? 1 : 0,
                    EXTERNAL_ID: editData.EXTERNAL_ID ? editData.EXTERNAL_ID : null,
                })
                props.close();
            }}
            language={language}
            validate={true}
        />
    );
}