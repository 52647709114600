import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import * as lang from '../../../Constants/strings';
import { site } from '../../../Components/Misc/Tables/TableHeaders';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';

export default function CustomerSites(props) {
    const { data, language, tiny } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    return (
        <Grid item xs={12} md={12} container direction="column">
            <MaterialTable
                tiny={tiny}
                title={lang.SITES[language]}
                language={language}
                data={data && data.length > 0 ? data : []}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={site(language)}
                dense={false}
                openAddModal={props.openAddModal}
                openRowModal={props.openRowModal}
                clickLabel="OBJID"
            />
        </Grid>
    );
}

export function SkeletonCustomerSites(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={12} container direction="column">
            <Skeleton component={Paper} className={classes.paper} height="45vh">
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                </Grid>
            </Skeleton>
        </Grid>
    );
}