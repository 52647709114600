import { useState, createRef, useEffect } from "react";
import {
    Button,
    Grid,
    TextField,
    useTheme,
    useMediaQuery,
    MenuItem,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import * as lang from "../../../../Constants/strings";
import CustomModal from '../../../../Components/Modals/CustomModal';
import PvModal from './PvModal';
import { makeStyles } from "@material-ui/styles";
import BillFields from "./BillFields";
import Cancel from "./CancelModal";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: theme.spacing(2)
    },
    mainGrid: {
        margin: theme.spacing(1)
    },
    testFiled: {
        margin: theme.spacing(1)
    },
    signature: {
        backgroundColor: "white",
        width: "100%",
        height: "50vh",
    },
    float: {
        margin: theme.spacing(1),
    },
}));

const RiscEvaluationActionButtons = (props) => {
    const {
        riscEvaluationStatus,
        doAction,
        language,
        disabledActionButton = false,
        riscEvaluationId,
        reFetch,
        userId
    } = props;

    const [note, setNote] = useState('');
    const [modalState, setModalState] = useState(false);
    const [pvModalState, setPvModalState] = useState(false);
    const [isCurrier, setIsCurrier] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [cancelModal, setCancelModal] = useState(false);

    const [evaluationNo, setEvaluationNo] = useState('');

    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(new Date().setFullYear(new Date().getFullYear() + 3));

    const [awbNumber, setAwbNumber] = useState('');
    const [sendToPoliceAttachmentFile, setEndToPoliceAttachmentFile] = useState(null);

    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    // The changing of the startDate has as a side effect the update of the endData to startDate + 3 years
    useEffect(() => {
        const formattedStartDate = new Date(startDate);
        formattedStartDate.setFullYear(formattedStartDate.getFullYear() + 3);
        setEndDate(formattedStartDate);
    }, [startDate]);

    const getNextStatus = (status) => {
        switch (status) {
            case "CREATED": return "ASSIGN";
            case "ASSIGN": return "ADD_DATA";
            case "ADD_DATA": return "SEND_TO_CUSTOMER";
            case "SEND_TO_CUSTOMER": return "SIGN_PV";
            case "SIGN_PV": return "FINISH";
            default: return 'NONE';
        };
    };

    const validateData = () => {
        switch (getNextStatus(riscEvaluationStatus)) {
            case "ADD_DATA":
                return evaluationNo !== '';
            case "SEND_TO_CUSTOMER":
                return (!isCurrier && awbNumber !== '' && setEndToPoliceAttachmentFile !== null) || selectedUser !== null
            case "SIGN_PV": return false;
            default: return true;
        }
    }

    useEffect(() => {
        getUserList()
    }, []);

    const getUserList = () => {
        fetch('/api/users', {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.userList.length > 0) {
                    setUserList(response.data.userList);
                } else {
                    setUserList([]);
                }
            })
    };

    // Edit this steps
    const addData = () => (
        <>
            <Grid xs={12}>
                <TextField
                    label="Nr. evaluare de risc"
                    variant="outlined"
                    className={classes.testFiled}
                    fullWidth
                    defaultValue={""}
                    value={evaluationNo}
                    onChange={e => setEvaluationNo(e.target.value)}
                />
            </Grid>
            <Grid xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        className={classes.testFiled}
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="permit_date"
                        label="Dată evaluare de risc"
                        InputProps={{ readOnly: true }}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change permit date",
                        }}
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        className={classes.testFiled}
                        required
                        inputVariant="outlined"
                        fullWidth
                        name="permit_date"
                        label="Dată expirare evalaure de risc"
                        InputProps={{ readOnly: true }}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            "aria-label": "change permit date",
                        }}
                        value={endDate}
                        onChange={(value) => setEndDate(value)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </>
    );

    const awb = () => (
        <>
            <Grid item xs={12}>
                <TextField
                    required
                    select
                    variant='outlined'
                    name='user'
                    label="Utilizatorul ce a predat"
                    onChange={(e) => setSelectedUser(e.target.value)}
                    value={selectedUser}
                    className={classes.testFiled}
                    fullWidth
                >
                    {
                        userList.map(user => {
                            return <MenuItem key={user} value={user}>{user.USERNAME.replace('.', ' ').toUpperCase()}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
        </>
    );

    const lastStep = () => <></>;

    return (
        <>
            <Grid container justifyContent="space-around" spacing={12} className={classes.mainGrid}>
                <Grid xs={12} md={3}>
                    {
                        getNextStatus(riscEvaluationStatus) !== "SIGN_PV" &&
                        <Button
                            className={classes.buttons}
                            fullWidth
                            disabled={["FINISH", "CANCEL"].indexOf(riscEvaluationStatus) !== -1 || disabledActionButton}
                            onClick={() => setModalState(true)}
                            variant="contained"
                            color="primary"
                        >
                            {lang[getNextStatus(riscEvaluationStatus)][language]}
                        </Button>
                    }
                </Grid>

                <Grid xs={12} md={3}>
                    <Button
                        className={classes.buttons}
                        fullWidth
                        disabled={["FINISH", "CANCEL"].indexOf(getNextStatus(riscEvaluationStatus)) === -1 || disabledActionButton}
                        variant="contained"
                        color="secondary"
                        value={pvModalState}
                        onClick={() => setPvModalState(true)}
                    >
                        Proces verbal predare
                    </Button>
                </Grid>

                <Grid xs={12} md={4}>
                    <Button
                        className={classes.buttons}
                        fullWidth
                        disabled={["FINISH", "CANCEL"].indexOf(riscEvaluationStatus) !== -1}
                        variant="contained"
                        color="secondary"
                        onClick={() => setCancelModal(true)}
                    >
                        {lang.CANCEL[language]}
                    </Button>
                </Grid>
            </Grid>

            <PvModal
                open={pvModalState}
                close={() => {
                    reFetch();
                    setPvModalState(false);
                }}
                riscEvaluationId={riscEvaluationId}
                language={language}
                classes={classes}
                tiny={tiny}
            />

            <Cancel
                open={cancelModal}
                close={() => {
                    reFetch();
                    setCancelModal(false)
                }}
                language={language}
                riscEvaluationId={riscEvaluationId}
                userId={userId}
                refetch={reFetch}
            />
            <CustomModal
                open={modalState}
                fullScreen={tiny}
                fullWidth
                title={riscEvaluationStatus ? `${lang[riscEvaluationStatus][language]} : ${lang[getNextStatus(riscEvaluationStatus)][language]}` : "_"}
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Observație"
                                className={classes.testFiled}
                                defaultValue={""}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        {
                            riscEvaluationStatus &&
                            getNextStatus(riscEvaluationStatus) === "ADD_DATA" && addData()
                        }
                        {
                            riscEvaluationStatus &&
                            getNextStatus(riscEvaluationStatus) === "SEND_TO_CUSTOMER" && awb()
                        }
                        {
                            riscEvaluationStatus &&
                            getNextStatus(riscEvaluationStatus) === "FINISH" && lastStep()
                        }
                        {
                            riscEvaluationStatus &&
                            (getNextStatus(riscEvaluationStatus) !== "ASSIGN" || getNextStatus(riscEvaluationStatus) !== "NONE")
                            && <BillFields
                                language={language}
                                taskId={riscEvaluationId}
                                contractor={null}
                                userId={userId}
                                refetchTask={reFetch}
                            />
                        }
                    </Grid>
                }
                close={() => setModalState(false)}
                execute={() => {
                    doAction({
                        note,
                        evaluationNo,
                        startDate,
                        endDate,
                        awbNumber,
                        sendToPoliceAttachmentFile,
                        isCurrier,
                        selectedUser
                    });
                    setModalState(false);
                }}
                language={language}
                validate={validateData()}
            />
        </>
    )
};

export default RiscEvaluationActionButtons;