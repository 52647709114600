import React from 'react';
import {
    CheckCircleRounded,
    RadioButtonUncheckedRounded,
    Lens,
    AirportShuttle,
    ReportOff,
    NewReleases,
    AssignmentInd,
    HourglassFull,
    CheckCircle,
    Notifications,
    BuildRounded,
    NoteAdd,
    Replay,
    YoutubeSearchedFor,
    PeopleAltRounded,
    ApartmentRounded,
    Description,
    Payment,
    MoneyOff
} from '@material-ui/icons';
import { Tooltip, Typography } from '@material-ui/core';

export function getVerifyIcon(itemList) {
    let newList = itemList.map(item => {
        if (item.VERIFY === 1) {
            item.VERIFY = <Tooltip title="DA"><CheckCircleRounded color='primary' /></Tooltip>
        } else {
            item.VERIFY = <Tooltip title="Nu"><RadioButtonUncheckedRounded color='secondary' /></Tooltip>
        }
        if (item.IS_ACTIVE === 1) {
            item.IS_ACTIVE = <Tooltip title="Activ"><Typography variant="h6" color="primary"> <PeopleAltRounded color='primary' /> Activ</Typography></Tooltip>
        } else {
            item.IS_ACTIVE = <Tooltip title="Inactiv"><Typography variant="h6" color="secondary"><PeopleAltRounded color='secondary' /> Inactiv</Typography></Tooltip>
        }
        if (item.ACTIVE === 1) {
            item.ACTIVE = <Tooltip title="Activ"><Typography variant="h6" color="primary"><ApartmentRounded style={{ marginLeft: 10 }} color='primary' />Activ</Typography></Tooltip>
        } else {
            item.ACTIVE = <Tooltip title="Inactiv"><Typography variant="h6" color="secondary"><ApartmentRounded color='secondary' />Inactiv</Typography></Tooltip>
        }
        return item;
    });
    return newList;
};

export function getPriorityIcon(itemList) {
    let newList = [];
    itemList.map(item => {
        if (item.PRIORITY === 2) {
            item.PRIORITY = <Tooltip title="Normală"><Lens color='primary' /></Tooltip>
        } else {
            item.PRIORITY = <Tooltip title="Urgentă"><Lens color='secondary' /></Tooltip>
        }
        newList.push(item);
    });
    return newList;
};

export function getStatusIcon(itemList) {
    let newList = [];
    itemList.map(item => {
        switch (item.STATUS) {
            case "NEW":
                item.STATUS = <Tooltip title='Nou'><NewReleases color='secondary' /></Tooltip>
                break;
            case "OPEN":
                item.STATUS = <Tooltip title='Deschis'><Notifications color='secondary' /></Tooltip>
                break;
            case "ASSIGNED":
                item.STATUS = <Tooltip title='Atribuit'><AssignmentInd color='primary' /></Tooltip>
                break;
            case "ON_HOLD":
                item.STATUS = <Tooltip title='Amânat'><HourglassFull color='primary' /></Tooltip>
                break;
            case "ON_DRIVE":
                item.STATUS = <Tooltip title='Echipajul se deplaseză'><AirportShuttle color='primary' /></Tooltip>
                break;
            case "CANCELLED":
                item.STATUS = <Tooltip title='Anulat'><ReportOff color='secondary' /></Tooltip>
                break;
            case "STARTED":
                item.STATUS = <Tooltip title='În lucru'><BuildRounded color='secondary' /></Tooltip>
                break;
            case "CLOSED":
                item.STATUS = <Tooltip title='Închis'><CheckCircle color='primary' /></Tooltip>
                break;
            case "SENT":
                item.STATUS = <Tooltip title="PV primit"><NoteAdd color='primary' /></Tooltip>
                break;
            case "WRONG_PV":
                item.STATUS = <Tooltip title="Refacere PV"><Replay color='secondary' /></Tooltip>
                break;
            case "CHECK_IN":
                item.STATUS = <Tooltip title="Verificare"><YoutubeSearchedFor color='secondary' /></Tooltip>
                break;
            case "VALIDATING":
                item.STATUS = <Tooltip title="Verificare PV"><Description color='secondary' /></Tooltip>
                break;
            default: item.STATUS = <Tooltip title='Atribuit'><AssignmentInd color='primary' /></Tooltip>
        };
        newList.push(item);
    });
    return newList;
}

export function getBillPayStatus(item) {
    if (item.FK2BILL_TYPE !== 1) {
        if (item.IS_PARTIAL_PAYED === 1 && item.COMPLETED_PAYED !== 1) {
            return <Tooltip title="Achitat parțial"><Payment color="secondary" /></Tooltip>
        } else if (item.IS_PARTIAL_PAYED !== 1 && item.RECEIPT_VALIDATION !== 1 || item.IS_PAYED !== 1) {
            return <Tooltip title="Neachitat"><MoneyOff color="secondary" /></Tooltip>
        } else {
            return <Tooltip title="Achitat complet"><Payment color="primary" /></Tooltip>
        }
    } else {
        return <Tooltip title="Gratuit"><MoneyOff /></Tooltip>
    }
}