import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function CustomDrawer(props) {
    const classes = useStyles();
    const { open, close, list, secondList } = props;

    const fullList = side => (
        <div
            className={classes.fullList}
            role="presentation"
            onClick={(e) => console.log(e.target)}
            onKeyDown={close}
        >
            <List>
                {/* {console.log(list)} */}
                {list && list.filter(e => e.render === true).map((e, index) =>
                    <ListItem
                        key={index}
                        button
                        disabled={e.disabled ? true : false}
                        onClick={() => {
                            e.action();
                            close();
                        }}
                    >
                        <ListItemIcon>{e.icon}</ListItemIcon>
                        <ListItemText primary={e.primary} secondary={e.secondary} />
                    </ListItem>
                )}
            </List>
            {secondList &&
                <React.Fragment>
                    <Divider />
                    <List>
                        {secondList.filter(e => e.render === true).map((e, index) =>
                            <ListItem button onClick={() => { e.action(); close(); }} key={index}>
                                <ListItemIcon>{e.icon}</ListItemIcon>
                                <ListItemText primary={e.primary} secondary={e.secondary} />
                            </ListItem>
                        )}
                    </List>
                </React.Fragment>
            }
        </div>
    );

    return (
        <div>
            <Drawer anchor="bottom" open={open} onClose={close}>
                {fullList('bottom')}
            </Drawer>
        </div>
    );
}
