import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    Grid,
    makeStyles,
    Paper,
    Button,
    TextField,
    MenuItem,
    Grow
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import * as lang from '../../../Constants/strings';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 2, 0),
        width: 'auto'
    },
    fields: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(2, 1, 1, 2),
        width: '98%',
    },
    checkbox: {
        margin: theme.spacing(1)
    },
    text: {
        margin: theme.spacing(2)
    }
}));

export default function SortBar(props) {
    const classes = useStyles();
    const { language, usersDepartment } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedUser, setSelectedUser] = useState(null);
    const [userList, setUserList] = useState([]);

    const getUserList = () => {
        let url = '/api/users' + (usersDepartment ? `?department=${usersDepartment}` : '')
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.userList.length > 0) {
                    setUserList(response.data.userList);
                    console.log(response.data);
                } else {
                    setUserList([]);
                }
            });
    };

    const applyFilters = () => {
        props.filter({ startDate, endDate, selectedUser });
    };

    const download = () => {
        props.onDownload({ startDate, endDate, selectedUser });
    };

    useEffect(() => {
        getUserList();
    }, []);

    return (
        <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
            <Paper className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.fields}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.START_INTERVAL_DATE[language]}
                                value={startDate}
                                onChange={date => setStartDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.fields}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.LAST_INTERVAL_DATE[language]}
                                value={endDate}
                                onChange={date => setEndDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            required
                            select
                            className={classes.fields}
                            variant='outlined'
                            name='user'
                            label={"Utilizator"}
                            onChange={(e) => setSelectedUser(e.target.value)}
                            value={selectedUser}
                            fullWidth
                        >
                            {
                                userList.map(user => <MenuItem key={user.USER_ID} value={user.USER_ID}>{user.USERNAME}</MenuItem>)
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={selectedUser === null}
                            className={classes.button}
                            onClick={() => applyFilters()}
                        >
                            {lang.FILTER[language]}
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={selectedUser === null}
                            className={classes.button}
                            onClick={download}
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grow>
    )
};
