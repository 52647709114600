export function getNameForBlob(mimetype) {
    let name = 'file'
    switch (mimetype) {
        case 'audio/aac': return `${name}.aac`;
        case 'text/csv': return `${name}.csv`;
        case 'text/html': return `${name}.html`;
        case 'image/jpeg': return `${name}.jpeg`;
        case 'audio/midi': return `${name}.mid`;
        case 'audio/x-midi': return `${name}.midi`;
        case 'audio/mpeg': return `${name}.mp3`;
        case 'audio/ogg': return `${name}.oga`;
        case 'image/png': return `${name}.png`;
        case 'application/pdf': return `${name}.pdf`;
        case 'image/svg+xml': return `${name}.svg`;
        case 'text/plain': return `${name}.txt`;
        case 'audio/wav': return `${name}.wav`;
        case 'application/vnd.ms-excel': return `${name}.xls`;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return `${name}.xlsx`;
        case 'application/msword': return `${name}.doc`;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return `${name}.docx`;
        default: return `${name}.non`
    }
};