import React, {useEffect} from "react";
import {Button, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import moment from "moment/moment";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actionCreator from "../../actions/actions";

/**
 * @TODO: Create a new modal for milling validation that is more generalize
 */
import BillValidationModal from "../../MainView/Projects/BillValidationModal";
import BillingModal from "../../MainView/Tasks/Modals/BillValidationModal";
import RiscEvaluationBillingModal from "../../MainView/RiscEvaluation/BillValidationModal";

import AreaSelectModal from "../../MainView/Tasks/Modals/AreaSelect";
import SelectDateModal from "../../MainView/Tasks/Modals/SelectIntervalModal";
import * as lang from "../../Constants/strings";
import MaterialTable from "../Misc/Tables/MaterialTableSimple";
import { tasks_bill_validation } from "../Misc/Tables/TableHeaders";
import { useBillingStyles } from "../../Constants/styles/billValidation";

export const formatTaskBill = (bills, language) => {
    const returnList = [];

    for(const bill of bills) {
        returnList.push({
            ...bill,
            BILLING_TYPE: bill.BILLING_TYPE !== null ? lang[bill.BILLING_TYPE][language] : bill.BILLING_TYPE,
            PAYMENT_TYPE: bill.PAYMENT_TYPE !== null ? lang[bill.PAYMENT_TYPE][language] : bill.PAYMENT_TYPE,
            CREATE_IN: bill.CREATE_IN !== null ? moment(bill.CREATE_IN).format("DD-MM-YYYY HH:mm") : bill.CREATE_IN,
        })
    }

    return returnList;
};

const BillingValidationTable = (props) => {
    const {
        language,
        getUnvalidatedBillsByType,
        getUnvalidatedBills,
        bills,
        selectedArea,
        setSelectedArea,
        interval,
        setInterval,
        type,
        userAuth
    } = props;

    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const classes = useBillingStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const [modal, setModal] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);

    const [selectAreaModal, setSelectedAreaModal] = React.useState(false);
    const [selectDateIntervalModal, setSelectDateIntervalModal] = React.useState(false);
    const [changeFilter, setChangeFilter] = React.useState(false);

    useEffect(() => {
        getUnvalidatedBills();
    }, []);

    useEffect(() => {
        if (changeFilter) {
            getUnvalidatedBillsByType();
        } else {
            getUnvalidatedBills();
        }
    }, [selectedArea, interval]);

    const resetFilters = () => {
        setInterval(undefined);
        setSelectedArea(null);
        setChangeFilter(false);
    };

    return (
        <React.Fragment>
            {
                type === 'projects' &&
                    <BillValidationModal
                        language={language}
                        open={modal}
                        close={() => setModal(false)}
                        reopen={() => setModal(true)}
                        billId={selectedId}
                        lockAllFields={false}
                        refeatchTaskData={() => getUnvalidatedBills()}
                    />
            }
            {
                type === 'task' &&
                <BillingModal
                    language={language}
                    open={modal}
                    close={() => setModal(false)}
                    reopen={() => setModal(true)}
                    billId={selectedId}
                    lockAllFields={false}
                    refeatchTaskData={() => getUnvalidatedBills()}
                />
            }
            {
                type === 'risc-evaluation' &&
                <RiscEvaluationBillingModal
                    language={language}
                    open={modal}
                    close={() => setModal(false)}
                    reopen={() => setModal(true)}
                    billId={selectedId}
                    lockAllFields={false}
                    refeatchTaskData={() => getUnvalidatedBills()}
                    userAuth={userAuth}
                />
            }

            <AreaSelectModal
                open={selectAreaModal}
                onClose={() => setSelectedAreaModal(false)}
                language={language}
                selectedArea={selectedArea}
                setSelectedArea={(e) => {
                    setChangeFilter(true);
                    setSelectedArea(e.target.value);
                }}
                callback={() => {
                    setSelectedAreaModal(false)
                }}
            />
            <SelectDateModal
                open={selectDateIntervalModal}
                onClose={() => setSelectDateIntervalModal(false)}
                language={language}
                callback={(startDate, endDate) => {
                    setInterval({ startDate, endDate })
                    setSelectDateIntervalModal(false);
                    setChangeFilter(true);
                }}
            />
            <Grid container component="main" className={classes.margin}>
                <Grid item xs={12}>
                    <div className={classes.filterButtonsContainer}>
                        <Button
                            className={classes.filterButtons}
                            variant="contained"
                            color="primary"
                            onClick={() => setSelectDateIntervalModal(true)}
                        >
                            {lang.SELECT_INTERVAL_DATE[language]}
                        </Button>
                        <Button
                            className={classes.filterButtons}
                            variant="contained"
                            color="secondary"
                            onClick={() => setSelectedAreaModal(true)}
                        >
                            {lang.AREA_SELECT[language]}
                        </Button>
                        <Button
                            className={classes.filterButtons}
                            variant="contained"
                            color="primary"
                            onClick={() => resetFilters()}
                        >
                            {lang.RESET[language]}
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.BILL_VALIDATION[language]}
                        language={language}
                        data={bills}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={tasks_bill_validation(language)}
                        dense={false}
                        openRowModal={(id) => {
                            setSelectedId(id);
                            setModal(true);
                        }}
                        clickLabel="OBJID"
                        hideAddBtn={true}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.config.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingValidationTable);
