import React, { useState } from 'react';
import { Grid, Paper, Typography, Button, Divider, TextField, InputLabel, FormControl, Select, MenuItem, Hidden, Fab, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';
import DoneIcon from '@material-ui/icons/CheckRounded';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as lang from '../../../Constants/strings';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import CustomModal from '../../../Components/Modals/CustomModal';
import PasswordUpdateForm from '../../../Components/Misc/PasswordUpdateForm';
import AvatarSelectMenu from '../../../Components/Misc/AvatarSelectMenu';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        marginTop: 12,
        marginLeft: -90,
    },
    buttonProgress2: {
        color: green[500],
        position: 'absolute',
        marginTop: 4,
        marginLeft: -52,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}));

export default function ProfileEdit(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    const [userData, setUserData] = useState({ ...props.userData });
    const [loading, setLoading] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    const buttonClassname = clsx({
        [classes.buttonStd]: true,
        [classes.buttonSuccess]: success === true,
        [classes.buttonError]: success === false,
    });

    const updateUserDataBasic = e => {
        const { name, value } = e.target;
        setUserData(
            {
                ...userData,
                edited: true,
                basic: {
                    ...userData.basic,
                    [name]: [value]
                }
            });
    }
    const updateUserDataDetails = e => {
        const { name, value } = e.target;
        setUserData(
            {
                ...userData,
                edited: true,
                details: {
                    ...userData.details,
                    [name]: [value]
                }
            });
    }
    const updateAvatarData = value => {
        console.log("AVATAR " + value)
        props.toggleAvatarModal();
        setUserData(
            {
                ...userData,
                edited: true,
                basic: {
                    ...userData.basic,
                    AVATAR_ID: value
                }
            }
        )
    }
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <Grid item xs={12} md={6} container direction="column">
            <CustomModal
                open={props.avatarModal}
                title={lang.NEW_AVATAR[props.language]}
                content={
                    <AvatarSelectMenu
                        updateAvatarData={updateAvatarData}
                    />
                }
                close={props.toggleAvatarModal}
                execute={props.toggleAvatarModal}
                language={props.language}
                onlyBack
                noButtons
            />
            {/* <CustomModal
                open={props.avatarModal}
                title={"AVATAR"}
                // content={lang.UPDATE_PASSWORD_SECONDARY[this.props.language]}
                content={
                    <PasswordUpdateForm
                        password={props.changePassword.password}
                        language={props.language}
                        onFocus={props.changePassword.onFocus}
                        onBlur={props.changePassword.onBlur}
                        updatePasswordField={props.changePassword.updatePasswordField}
                    />
                }
                close={props.toggleAvatarModal}
                execute={props.toggleAvatarModal}
                language={props.language}
            /> */}
            <CustomModal
                open={props.changePassword.passwordModal}
                title={lang.UPDATE_PASSWORD_PRIMARY[props.language]}
                // content={lang.UPDATE_PASSWORD_SECONDARY[this.props.language]}
                content={
                    <PasswordUpdateForm
                        password={props.changePassword.password}
                        language={props.language}
                        onFocus={props.changePassword.onFocus}
                        onBlur={props.changePassword.onBlur}
                        updatePasswordField={props.changePassword.updatePasswordField}
                    />
                }
                close={props.changePassword.togglePassModal}
                execute={props.changePassword.execute}
                language={props.language}
                validate={props.changePassword.password.OLD_PASSWORD.value.length > 5 && props.changePassword.password.NEW_PASSWORD.value.length > 5 && props.changePassword.password.NEW_PASSWORD2.value.length > 5 && props.changePassword.password.NEW_PASSWORD.value !== props.changePassword.password.OLD_PASSWORD.value && props.changePassword.password.NEW_PASSWORD.value === props.changePassword.password.NEW_PASSWORD2.value}
            />
            <Slide in={props.edit === true} direction="down">
                <Paper className={classes.paper}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Fab
                            color="default"
                            size="small"
                            onClick={props.toggleEdit}
                            className={classes.buttonStd}
                        >
                            <BackIcon />
                        </Fab>
                        <Typography variant="h6" align="center" style={{ marginTop: '15px' }}>{lang.EDIT_PROFILE[props.language]}</Typography>
                        <Hidden smUp>
                            <div style={{ position: 'relative' }}>
                                <Fab
                                    size="small"
                                    color={"primary"}
                                    disabled={userData.edited !== true || loading === true}
                                    className={buttonClassname}
                                    onClick={() => {
                                        props.updateUserData(userData.basic.USER_OBJID, userData);
                                        setSuccess(false);
                                        setLoading(true);
                                        setTimeout(() => {
                                            setLoading(false);
                                            console.log(props.isUpdatingUserData)
                                            setSuccess(props.isUpdatingUserData === false);
                                        }, 2000);
                                        setTimeout(() => {
                                            props.toggleEdit();
                                        }, 2500);
                                    }}
                                >
                                    {loading === false ? props.isUpdatingUserData === true ? <ErrorIcon fontSize="small" /> : <DoneIcon fontSize="small" /> : <SaveIcon fontSize="small" />}
                                </Fab>
                                {loading && <CircularProgress size={48} className={classes.buttonProgress2} />}
                            </div>
                        </Hidden>
                        <Hidden xsDown>
                            <div style={{ position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    disabled={userData.edited !== true || loading === true}
                                    className={buttonClassname}
                                    onClick={() => {
                                        props.updateUserData(userData.basic.USER_OBJID, userData);
                                        setSuccess(false);
                                        setLoading(true);
                                        setTimeout(() => {
                                            setLoading(false);
                                            console.log(props.isUpdatingUserData)
                                            setSuccess(props.isUpdatingUserData === false);
                                        }, 2000);
                                        setTimeout(() => {
                                            props.toggleEdit();
                                        }, 2500);
                                    }}
                                >
                                    {loading === false ? props.isUpdatingUserData === true ? <ErrorIcon fontSize="small" className={classes.leftIcon} /> : <DoneIcon fontSize="small" className={classes.leftIcon} /> : <SaveIcon fontSize="small" className={classes.leftIcon} />}
                                    {lang.SAVE[props.language]}
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Hidden>
                    </div>
                    <Divider className={classes.divier} />
                    <br />
                    <Typography variant="body1" >{lang.ACOUNT_DETAILS[props.language]}</Typography>
                    <Divider className={classes.divier} />
                    <TextField
                        name="LAST_NAME"
                        variant="outlined"
                        label={lang.LAST_NAME[props.language]}
                        value={userData.basic && userData.basic.LAST_NAME}
                        fullWidth
                        className={classes.textField}
                        onChange={updateUserDataBasic}
                    />
                    <TextField
                        name="FIRST_NAME"
                        variant="outlined"
                        label={lang.FIRST_NAME[props.language]}
                        value={userData.basic && userData.basic.FIRST_NAME}
                        fullWidth
                        className={classes.textField}
                        onChange={updateUserDataBasic}
                    />
                    <TextField
                        name="USERNAME"
                        variant="outlined"
                        label={lang.USERNAME[props.language]}
                        value={userData.basic && userData.basic.USERNAME}
                        fullWidth
                        disabled
                        className={classes.textField}
                        onChange={updateUserDataBasic}
                    />
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="PHONE"
                                variant="outlined"
                                label={lang.PHONE[props.language]}
                                type="tel"
                                value={userData.details && userData.details.PHONE ? userData.details.PHONE : ""}
                                className={classes.formControl}
                                onChange={updateUserDataDetails}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="EMAIL"
                                variant="outlined"
                                label={lang.EMAIL[props.language]}
                                value={userData.details && userData.details.EMAIL ? userData.details.EMAIL : ""}
                                className={classes.formControl}
                                onChange={updateUserDataDetails}
                            />
                        </Grid>
                    </Grid>
                    <Divider className={classes.divier} />
                    <br />
                    <Typography variant="body1" >{lang.PERSONAL_DETAILS[props.language]}</Typography>
                    <Divider className={classes.divier} />
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    name="BIRTHDAY"
                                    label={lang.BIRTHDAY[props.language]}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={userData.details && userData.details.BIRTHDAY}
                                    className={classes.formControl}
                                    onChange={(value) => setUserData({ ...userData, edited: true, details: { ...userData.details, BIRTHDAY: value } })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginBottom: '0px' }}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                                    {lang.GENDER[props.language]}
                                </InputLabel>
                                <Select
                                    name="GENDER"
                                    value={userData.details && userData.details.GENDER}
                                    labelWidth={labelWidth}
                                    onChange={updateUserDataDetails}
                                >
                                    <MenuItem value="">
                                        <em>nespecificat</em>
                                    </MenuItem>
                                    <MenuItem value={'male'}>{lang.MALE[props.language]}</MenuItem>
                                    <MenuItem value={'female'}>{lang.FEMALE[props.language]}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                </Paper>
            </Slide>
        </Grid>
    );
}