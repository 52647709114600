import React from 'react';
import { Grid, TextField, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../../Constants/strings';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import axios from 'axios';
import { checkSpecialChar } from '../../../../Functions/stringManipulation';


const useStyles = makeStyles(theme => ({
    textField: {
        // margin: theme.spacing(1),
    },
    chip: {
        margin: 2,
    },
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));

export default function DepartmentNewForm(props) {
    const classes = useStyles();
    const { addData, language, error, updateError, updateFieldRaw, updateField } = props;
    const [errorText, setError] = React.useState();

    function checkNameAvailability(e) {
        const { value } = e.target;
        if (value)
            axios.get(`/api/check_department?name=${value}`)
                .then(res => {
                    updateError(parseInt(res.data, 10) > 0);
                    if (parseInt(res.data, 10) > 0)
                        setError("VALUE_EXISTS");
                    if (checkSpecialChar(value.trim(), "*|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        updateError(true);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                });
        else {
            setError("EMPTY_FIELD");
            updateError(true);
        }
    }
    function focus() {
        updateError(null);
        setError(null);
    }

    React.useEffect(() => {
        setError(null);
    }, [addData.DEPARTMENT]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{ position: 'relative' }}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                        name="DEPARTMENT"
                        label={lang.DEPARTMENT_NAME[language]}
                        value={addData.DEPARTMENT ? addData.DEPARTMENT : ""}
                        onChange={(e) => updateField(e, 50)}
                        onFocus={focus}
                        error={error}
                        helperText={errorText ? lang[errorText][language] : null}
                        onBlur={checkNameAvailability}
                    />
                    {error === true
                        ?
                        <ErrorRoundedIcon className={classes.clear} color="secondary" />
                        :
                        error === false
                            ?
                            <CheckCircleRoundedIcon className={classes.clear} />
                            :
                            null
                    }
                </div>
            </Grid>
            <Grid item container xs={12} justify="center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={addData.CONTRACT_ASSIGNEE ? addData.CONTRACT_ASSIGNEE : false}
                            onChange={() => updateFieldRaw('CONTRACT_ASSIGNEE', !addData.CONTRACT_ASSIGNEE)}
                        />
                    }
                    label={
                        <Typography variant="caption">{lang.CONTRACT_ASSIGNEE[language]}</Typography>
                    }
                />
            </Grid>
            <Grid item container xs={12} justify="center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={addData.DISPATCH_ASSIGNEE ? addData.DISPATCH_ASSIGNEE : false}
                            onChange={() => updateFieldRaw('DISPATCH_ASSIGNEE', !addData.DISPATCH_ASSIGNEE)}
                        />
                    }
                    label={
                        <Typography variant="caption">{lang.DISPATCH_ASSIGNEE[language]}</Typography>
                    }
                />
            </Grid>
        </Grid>
    );
}