import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    makeStyles,
    CssBaseline,
    useMediaQuery,
    useTheme,
    Grid,
    Tab,
    Tabs,
    Paper
} from "@material-ui/core";
import {
    NewReleases,
    Receipt,
    Euro,
    History
} from "@material-ui/icons";

import * as lang from "../../Constants/strings";
import * as actionCreator from "../../actions/actions";
import ProjectTable from "./Table";
import ProjectBillCashingValidation from "./Billing/ProjectBillCashingValidation";
import ProjectBillValidation from "./Billing/ProjectBillValidation";
import ProjectTableHistory from "./TableHistory";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: "auto",
    },
    table: {
        margin: theme.spacing(0, 2, 0, 0),
        width: "auto",
    },
    paper: {
        padding: '5px'
    },
    filter: {
        marginLeft: '5px',
    },
    areaSelect: {
        maxHeight: "50vh",
        "& *::-webkit-scrollbar": {
            display: "none",
            scrollbarWidth: "none"
        }
    },
    formControl: {
        margin: theme.spacing(1),
        padding: "5px"
    },
}));

const TabSelector = (props) => {
    const { value, handleChange, language } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={(e, newValue) => {
                        handleChange(newValue);
                    }}
                    aria-label="simple tabs example"
                    variant="fullWidth"
                >
                    <Tab
                        icon={<NewReleases color="secondary" />}
                        label={lang.PROJECT[language]}
                        value={1}
                    />
                    <Tab
                        icon={<History color="secondary" />}
                        label={lang.PROJECT_HISTORY[language]}
                        value={2}
                    />
                    <Tab
                        icon={<Receipt color="primary" />}
                        label={lang.BILL_VALIDATION[language]}
                        value={3}
                        disabled={false}
                    />
                    <Tab
                        icon={<Euro color="primary" />}
                        label={lang.PAY_VALIDATION[language]}
                        value={4}
                        disabled={false}
                    />
                </Tabs>
            </Paper>
        </React.Fragment>
    );
};

const Project = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down("xs"));
    const [selectedTab, setSelectedTab] = useState(1);

    return (
        <>
            <CssBaseline />
            <TabSelector
                language={props.language}
                value={selectedTab}
                handleChange={(newValue) => setSelectedTab(newValue)}
            // @TODO add permission validation to billing modules
            //   userPermissions={this.props.permissions}
            //   isAuthenticated={this.props.isAuthenticated}
            />
            <Grid
                container
                component="main"
                className={classes.table}
                spacing={tiny ? 1 : 4}
            >
                {
                    selectedTab === 1 &&
                    <ProjectTable
                        language={props.language}
                        userId={props.userAuth.id}
                        userPermission={props.permissions}
                    />
                }
                {
                    selectedTab === 2 &&
                    <ProjectTableHistory
                        language={props.language}
                        userId={props.userAuth.id}
                        userPermission={props.permissions}
                    />
                }
                {
                    selectedTab === 3 &&
                    <ProjectBillValidation language={props.language} />
                }
                {
                    selectedTab === 4 &&
                    <ProjectBillCashingValidation language={props.language} />
                }
            </Grid>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,
        siteData: state.cust.siteData,
        customerData: state.cust.customerData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);