/**
 * [AUTHOR]:      Tibulca, PAUL
 * [DETAILS]:     the button will switch between light and dark theme
 * [TIMESTAMP]:   2019-11-02, 08:06 PM
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";
import DarkModeIcon from '@material-ui/icons/Brightness4Rounded';
import LightModeIcon from '@material-ui/icons/Brightness7Rounded';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));


export default function DarkModeButton(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Tooltip title="Light/dark theme">
                <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={props.onDarkModeToggle}
                    color={props.color}
                >
                    {props.theme === "dark"
                        ?
                        <LightModeIcon fontSize={props.size} />
                        :
                        <DarkModeIcon fontSize={props.size} />
                    }
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}
