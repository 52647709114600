import React, {useState} from 'react';
import axios from "axios";

import * as requestHeaders from "../../../Constants/requestHeaders";
import BillingValidationTable, {formatTaskBill} from "../../../Components/Billing/BillingValidationTable";

export default function TaskBillValidation(props) {
    const [data, setData] = React.useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [interval, setInterval] = useState(undefined);

    const getUnvalidatedBillsByType = async () => {
        try {
            const response = await axios.get('/api/get_unvalidated_bill_by_type', {
                ...requestHeaders.getGetHeader(),
                params: {
                    type: "recipe",
                    areaCode: selectedArea !== null ? selectedArea.AREA_CODE : undefined,
                    interval: interval,
                }
            });

            let formatedBills = [];

            if (response.status === 200) {
                formatedBills = formatTaskBill(response.data.data, props.language);
            } else {
                console.error("Error: ", response.data.message);
            }
            setData(formatedBills);
        } catch (error) {
            console.log("Error: ", error);
        }
    }
    const getUnvalidatedBills = async () => {
        try {
            const response = await axios.get(
                '/api/get_unvalidated_bills',
                requestHeaders.getGetHeader()
            );
            setData(formatTaskBill(response.data.data, props.language));
        } catch (e) {
            console.error("Can't fetch UNVALIDATED BILLS", e);
        }
    };

    return (
        <BillingValidationTable
            language={props.language}
            getUnvalidatedBills={getUnvalidatedBills}
            getUnvalidatedBillsByType={getUnvalidatedBillsByType}
            bills={data}
            selectedArea={selectedArea}
            setSelectedArea={setSelectedArea}
            interval={interval}
            setInterval={setInterval}
            type="task"
        />
    )
}
