import React from 'react';
import { makeStyles, TextField, MenuItem } from '@material-ui/core';
import * as lang from '../../../../Constants/strings';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
    },
}));
export default function ContractSelect(props) {
    const classes = useStyles();
    const { language, fieldName, update, data, id } = props;

    const [contractList, setContractList] = React.useState([]);

    function fetchFunction(url, setResult) {
        fetch(`/api/${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setResult(response);
            })
            .catch(err => console.log(err))
    };

    function filterContracts(data) {
        let watchContract = []
        data.map(contract => {
            if (contract.TYPE === "CONTRACT_TYPE_WATCH") {
                watchContract.push(contract)
            }
        });
        setContractList(watchContract);
    }

    React.useEffect(() => {
        if (contractList.length === 0 && id !== null)
            fetchFunction(`get_contracts_list_summary_customer?id=${id}`, filterContracts);
    }, [id]);

    return (
        <TextField
            required
            className={classes.formControl}
            fullWidth
            select
            disabled={contractList.length === 0}
            variant="outlined"
            label={lang.CONTRACT[language]}
            onChange={(e) => update(fieldName, e.target.value)}
            value={data[fieldName] ? data[fieldName] : ""}
        >
            {contractList && contractList.map((item, index) =>
                <MenuItem key={index} value={item.OBJID}>
                    {`#${item.CONTRACT_NO} - ${lang[item.SUB_TYPE][language]} ${item.ACTIVE === 0 || item.UPDATED !== null || item.CANCELLED !== null ? "*" : ""}`}</MenuItem>
                    // ${item.ACTIVE === 1 ? ' [ACTIV]' : item.CANCELLED === null && item.UPDATED === null ? ' [ÎNCHEIAT]' : ''}${item.CANCELLED !== null || item.UPDATED !== null ? ' [REZILIAT]' : ""}
            )}
            <MenuItem disabled><em>* - încheiat, reziliat</em></MenuItem>
        </TextField>
    );
}