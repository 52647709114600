import React, { Component } from "react";
import * as lang from "../../Constants/strings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  useMediaQuery,
  CssBaseline,
  useTheme,
  makeStyles,
  Paper,
  Grow,
  Typography,
  Divider,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";
import * as actionCreators from "../../actions/actions";
import ReportSection from "./ReportsViewComponent/ReportSection";
import CustomModal from "../../Components/Modals/CustomModal";
import ReportDateRangeSelect from "./ReportsViewComponent/ReportDateRangeSelect";
import TabPanel from "../../Components/Misc/Tables/TabPanel";
import WrongPvReport from "./ReportsWrongPV/WrongPvReport";

import { formatDateFromDb } from "../../Functions/dateFunctions";
import { checkPermissions } from "../../Functions/permissionHide";
import ActivityReportsIntervention from "./ActivitiReports/ActivityReportsIntervention";
import ActivityReportsTask from "./ActivitiReports/ActivityReportsTask";
import ContractReport from "./ContractReports";
import ContractReportIntervention from "./ContractReports/ContractReportIntervention";
import ReportsUserTask from "./ReportsUserTask";
import ReportsExecutedInstallation from "./ReportsUserTask/ReportsExecutedInstallation";
import PvReports from "./PvReports";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
  },
  paper: {
    padding: theme.spacing(2),
  },
  shortcut: {
    margin: theme.spacing(1),
    transform: "rotate(180deg)",
  },
  title: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  bar: {
    width: "12%"
  }
}));

const style = (theme) => ({
  marginTop: {
    marginTop: theme.spacing(10),
    margin: theme.spacing(2),
    width: "auto",
  },
});

function ReportsSetup(props) {
  const classes = useStyles();
  const { language, buttons, modal, report } = props;
  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <CustomModal
        fullWidth
        title={lang.REPORT_DATE_RANGE[language]}
        content={
          <ReportDateRangeSelect
            language={language}
            report={report}
            classes={classes}
            index={modal.indexFile}
          />
        }
        open={modal.open}
        close={modal.closeAction}
        execute={() => {
          if (modal.indexFile !== null) {
            buttons[modal.indexFile].action();
            modal.closeAction();
          }
        }}
        validate={
          report.interval.start !== null &&
          report.interval.end !== null &&
          (report.contractor !== null || modal.open !== 0)
        }
        language={language}
      />
      <Grid item xs={12}>
        <Grow in={true} style={{ transitionDelay: true ? "100ms" : "0ms" }}>
          <Paper>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Typography className={classes.title} variant="h5">
                {lang.REPORTS[language]}
              </Typography>
            </Grid>
            <Divider />
            <Grid className={classes.paper} container>
              {buttons.map((item, index) => {
                if (item.render) {
                  return (
                    <Grid key={index} item xs={12} md={6}>
                      <ReportSection
                        data={item}
                        language={language}
                        classes={classes}
                        index={index}
                        onClickHandler={modal.openAction}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Paper>
        </Grow>
      </Grid>
    </>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

class ReportsView extends Component {
  state = {
    open: false,
    indexFile: null,
    report: {
      contractor: null,
      start: new Date(),
      end: null,
    },
    selectedTab: 0,
  };

  componentDidMount() {
    document.title = "RPG - " + lang.REPORTS[this.props.language];
    this.props.actions.checkSession();
  }

  textExcel = () => {
    fetch(`/api/activity_report`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.token,
      },
      method: "POST",
      responseType: "arraybuffer",
      body: JSON.stringify({
        ID: this.state.report.contractor,
        start: this.state.report.start,
        end: this.state.report.end,
      }),
    })
      .then((response) => response.blob())
      .then((response) => {
        const file = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = fileURL;
        link.download = `Activitate_desfasurata_${formatDateFromDb(
          new Date(),
          8
        )}_${new Date().getTime()}.xlsx`;
        link.click();
      })
      .catch((err) => console.log(err));
  };

  textExcel2 = () => {
    fetch(`/api/activity_report_2`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.token,
      },
      method: "POST",
      responseType: "arraybuffer",
      body: JSON.stringify({
        ID: this.state.report.contractor,
        start: this.state.report.start,
        end: this.state.report.end,
      }),
    })
      .then((response) => response.blob())
      .then((response) => {
        const file = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = fileURL;
        link.download = `Activitate_desfasurata_2_${formatDateFromDb(
          new Date(),
          8
        )}_${new Date().getTime()}.xlsx`;
        link.click();
      })
      .catch((err) => console.log(err));
  };

  setSelectedTab(event, newIndex) {
    this.setState({ selectedTab: newIndex });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={1} className={classes.marginTop}>
          <Grid item style={{ width: "12%" }}>
            <Tabs
              value={this.state.selectedTab}
              onChange={(event, newIndex) =>
                this.setSelectedTab(event, newIndex)
              }
              aria-label="Report_app_bar"
              centred="true"
              orientation="vertical"
            >
              <Tab
                label={lang.REPORT_ACTIVITY[this.props.language]}
                {...a11yProps(0)}
              />
              <Tab
                label={lang.WRONG_PV_REPORT[this.props.language]}
                {...a11yProps(1)}
              />
              <Tab label="Raport activitate tehnic" {...a11yProps(2)} />
              <Tab label="Raport activitate intervenții" {...a11yProps(3)} />
              <Tab
                label="REGISTRU CONTRACTE PAZA"
                {...a11yProps(4)}
              />
              <Tab
                label="REGISTRU CONTRACTE TEHNIC - MODEL 1"
                {...a11yProps(5)}
              />
              <Tab
                label="Registru procese verbal predare primire a sistemelor executate si proces verbale de instruire a utilizatorilor (MODELUL 2)"
                {...a11yProps(6)}
              />
              <Tab
                label="Registru pentru consemnarea defectiunilor semnalate (MODELUL 4)"
                {...a11yProps(7)}
              />
              <Tab
                label="Registru pentru evidenta lucrarilor executate (MODELUL 5)"
                {...a11yProps(8)}
              />
            </Tabs>
          </Grid>

          <Grid item style={{ width: "88%" }}>
            <TabPanel value={this.state.selectedTab} index={0}>
              <ReportsSetup
                language={this.props.language}
                buttons={[
                  {
                    TITLE: lang.REPORT_ACTIVITY[this.props.language],
                    action: () => this.textExcel(),
                    render:
                      this.props.isAuthenticated &&
                      checkPermissions(this.props.permissions, {
                        parent: "REPORTS",
                        name: "GENERATE_ACTIVITY_REPORT",
                      }),
                  },
                  {
                    TITLE: lang.REPORT_ACTIVITY_2[this.props.language],
                    action: () => this.textExcel2(),
                    render:
                      this.props.isAuthenticated &&
                      checkPermissions(this.props.permissions, {
                        parent: "REPORTS",
                        name: "GENERATE_ACTIVITY_REPORT_2",
                      }),
                  },
                ]}
                report={{
                  interval: this.state.report,
                  contractor: this.state.report.contractor,
                  setDate: (type, value) => {
                    this.setState({
                      report: { ...this.state.report, [type]: value },
                    });
                    console.log(value);
                  },
                }}
                modal={{
                  open: this.state.open,
                  openAction: (index) =>
                    this.setState({
                      open: true,
                      indexFile: index,
                      report: {
                        start: new Date(),
                        end: null,
                        contractor: null,
                      },
                    }),
                  closeAction: () =>
                    this.setState({ open: false, indexFile: null }),
                  indexFile: this.state.indexFile,
                }}
              />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={1}>
              <WrongPvReport language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={2}>
              <ActivityReportsTask language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={3}>
              <ActivityReportsIntervention language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={4}>
              <ContractReportIntervention language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={5}>
              <ContractReport language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={6}>
              <PvReports language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={7}>
              <ReportsUserTask language={this.props.language} />
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={8}>
              <ReportsExecutedInstallation language={this.props.language} />
            </TabPanel>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.config.language,
    isAuthenticated: state.auth.isAuthenticated,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ReportsView));
