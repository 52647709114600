import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';
import AddIcon from '@material-ui/icons/AddBoxRounded';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { MoreVert, LocationOn, Clear } from '@material-ui/icons';
import NestedMenu from '../NestedMenu';
import * as lang from '../../../Constants/strings';


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, header } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {header.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: "space-between",
        padding: theme.spacing(2),
    },
    wrapper: {
        display: 'flex',
        justifyContent: "flex-start",
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    search: {
    },
    margin: {
    },
    clear: {
        position: 'absolute',
        right: 0,
        marginRight: theme.spacing(3)
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const [text, setText] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { searchText, hideAddBtn, onSearch, hideSettings, language, areaList } = props;

    const handelMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handelMenuClose = () => {
        setAnchorEl(null);
    }

    return (
        <Toolbar className={classes.root}>
            {text
                ?
                <React.Fragment>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        className={classes.search}
                        placeholder={lang.SEARCH[props.language]}
                        onFocus={() => setText(true)}
                        value={searchText}
                        onChange={props.handleSearch}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape')
                                setText(false);
                            if (e.key === 'Enter')
                                onSearch(searchText);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={(searchText) => onSearch(searchText)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton onClick={() => setText(false)} className={classes.clear}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className={classes.wrapper}>
                        <span>
                            {
                                hideSettings === true ? null :
                                    <IconButton onClick={(event) => handelMenuOpen(event)}>
                                        <MoreVert className={classes.margin} />
                                    </IconButton>
                            }
                            <NestedMenu
                                open={open}
                                parentAnchorEl={anchorEl}
                                handelMenuClose={() => handelMenuClose()}
                                onSelect={
                                    (value) => {
                                        if (value === null) {
                                            props.resetSortItem()
                                        } else {
                                            props.handelSetSortItem(value)
                                        }
                                    }
                                }
                                menuItems={[
                                    {
                                        value: 1,
                                        text: lang.AREA[language],
                                        icon: <LocationOn style={{ marginRight: 10 }} />,
                                        subMenuItems: areaList !== null ?  areaList.map(area => {
                                            return {
                                                value: area.AREA,
                                                text: area.AREA
                                            }
										}) : []
                                    },
                                    {
                                        value: null,
                                        text: 'Elimina filtru',
                                        icon: <Clear style={{ marginRight: 10 }} />,
                                        subMenuItems: null
                                    }
                                ]}
                            />
                            {hideAddBtn === true ?
                                null
                                :
                                <IconButton onClick={props.openAddModal} className={classes.margin}>
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                            }
                        </span>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            {props.title}
                        </Typography>
                    </div>
                    <TextField
                        // fullWidth
                        variant="outlined"
                        className={classes.search}
                        placeholder={lang.SEARCH[props.language]}
                        onFocus={() => setText(true)}
                        onBlur={() => setText(false)}
                        value={searchText}
                        onChange={props.handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => onSearch(searchText)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </React.Fragment>
            }
        </Toolbar>
    );
};

const style = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        // minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

class EnhancedTable extends Component {
    state = {
        searchText: "",
        searchResults: [],
        hideAddBtn: this.props.hideAddBtn || false
    }

    handleRequestSort = (event, property) => {
        this.props.actions.setSortColumn(property, this.props.sortColumn, this.props.order);
        this.props.actions.setCurrentPage(0);
    };

    handleChangeRowsPerPage = event => {
        this.props.onRowsPerPageChange(parseInt(event.target.value, 10));
        this.props.actions.setCurrentPage(0);
    }

    getRow = (row, header, index) => {
        let results = [];
        header.forEach(field => {
            results.push(<TableCell key={field.id}>{row[field.id]}</TableCell>)
        })
        return results;
    }
    searchData = (searchText) => {
        this.setState({ searchText: searchText.toLowerCase() });
        let results = [];
        let ok = 0;
        for (let i = 0; i < this.props.data.length; i++) {
            ok = 1;
            let placeholder = "";
            for (let key in this.props.data[i]) {
                placeholder = placeholder + this.props.data[i][key] + ";";
            }
            let searchQueries = searchText.trim().split(" ");
            for (let i in searchQueries) {
                if (searchQueries[i].length > 0 && placeholder.toLowerCase().indexOf(searchQueries[i].toLowerCase().trim()) === -1) {
                    ok = 0;
                }
            }
            if (ok === 1)
                results.push(this.props.data[i])
        }
        this.setState({ searchResults: results });
        if (this.props.getSearchTerm)
            this.props.getSearchTerm(searchText);
    }

    onSearch = (searchTerm) => {
        if (this.props.onSearch) {
            this.props.onSearch(searchTerm)
        }
    }

    componentDidMount() {
        if (this.props.hideSettings !== true)
            this.props.actions.getActiveAreaList();
    }

    render() {
        const { header, data, classes, title, tiny, clickLabel, onSearch } = this.props;
        const emptyRows = this.props.rowsPerPage - Math.min(this.props.rowsPerPage, data.length - this.state.page * this.props.rowsPerPage);
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        tiny={tiny}
                        title={title}
                        searchText={this.props.searchTerm}
                        hideAddBtn={this.state.hideAddBtn}
                        handleSearch={(e) => this.searchData(e.target.value)}
                        language={this.props.language}
                        openAddModal={this.props.openAddModal}
                        onSearch={(searchTerm) => this.onSearch(searchTerm)}
                        hideSettings={this.props.hideSettings}
                        areaList={this.props.hideSettings !== true ? this.props.areaList === undefined ? [] : this.props.areaList : []}
                        handelSetSortItem={async (value) => {
                            await this.props.actions.setSortItem(value);
                            await this.props.onSearch();
                        }}
                        resetSortItem={this.props.actions.resetSortItem}
                    />
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={this.props.dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                header={header}
                                classes={classes}
                                order={this.props.order ? this.props.order.toLowerCase() : 'asc'}
                                orderBy={this.props.sortColumn}
                                onRequestSort={this.handleRequestSort}
                                rowCount={this.props.numberOfItems}
                            />
                            <TableBody>
                                {
                                    this.props.data.slice(this.props.currentPage * this.props.rowsPerPage, this.props.currentPage * this.props.rowsPerPage + this.props.rowsPerPage)
                                        .map((row, index) =>
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.props.openRowModal(row[clickLabel])}
                                            >
                                                {this.getRow(row, header, index)}
                                            </TableRow>
                                        )
                                }
                                {
                                    emptyRows > 0 && (
                                        <TableRow style={{ height: (this.props.dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.numberOfItems}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.currentPage}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={(e, p) => this.props.actions.setCurrentPage(p)}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage={lang.ROWS_PER_PAGE[this.props.language]}
                        labelDisplayedRows={lang.ROWS_FUNC[this.props.language]}
                    />
                </Paper>
            </div >
        );

    }

}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,
        numberOfItems: state.config.numbersOfItems,
        currentPage: state.config.currentPage,
        sortColumn: state.config.sortColumn,
        order: state.config.order,
        areaList: state.config.sortItems
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EnhancedTable));
