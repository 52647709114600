import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Grid, Paper, Tab, Tabs, MenuItem, TextField, FormControlLabel } from '@material-ui/core';
import { NewReleases, History, AirportShuttle, LocationOn } from '@material-ui/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';
import { checkPermissions } from '../../Functions/permissionHide';
import * as lang from '../../Constants/strings';
import AlarmTable from './InterventionsComponets/AlarmTable';
import PatrolTable from './InterventionsComponets/PatrolTable';
import InterventionHistoryTable from './InterventionsComponets/InterventionHistoryTable';
import DeprecatedInterventionModal from '../Site/SiteSingleViewComponents/Modals/DeprecatedInterventionModal';
import { downloadFile } from '../../Functions/fileDownload';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    select: {
        marginTop: theme.spacing(2),
        width: theme.spacing(40)
    },
    leftIcon: {
        marginRight: theme.spacing(5),
    },
}));

function TabSelector(props) {
    const { value, handleChange, language, areaList } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Grid container justify='space-around' alignItems='stretch' alignContent='space-between'>
                    <Grid item>
                        <FormControlLabel className={classes.select}
                            control={
                                <TextField
                                    fullWidth
                                    select
                                    value={props.selectedArea}
                                    onChange={(e) => props.setSelectedArea(e.target.value)}
                                    variant='outlined'
                                >
                                    {
                                        props.lockAllArea &&
                                        <MenuItem value={null}>{"NICIUNA"}</MenuItem>
                                    }
                                    {
                                        areaList && areaList.sort((a, b) => a.AREA.toUpperCase() < b.AREA.toUpperCase() ? -1 : a.AREA.toUpperCase() > b.AREA.toUpperCase() ? 1 : 0).map(area => {
                                            return <MenuItem key={area.AREA_CODE} value={area.AREA_CODE}>{area.AREA}</MenuItem>
                                        })
                                    }
                                </TextField>
                            }
                            labelPlacement='start'
                            label={<div className={classes.leftIcon}><Grid container justify='center' alignContent='space-between'>
                                <Grid item xs={6}>
                                    < LocationOn style={{ marginRight: 30 }} color='primary' />
                                </Grid>
                                <Grid item xs={6}>
                                    {lang.AREA[language]}
                                </Grid>
                            </Grid></div>}
                        />
                    </Grid>
                    <Grid item>
                        <Tabs value={value} onChange={(e, newValue) => { handleChange(newValue) }} aria-label="simple tabs example" variant="fullWidth">
                            <Tab icon={<NewReleases color='secondary' />} label={lang.ACTIVE_ALARMS[language]} value={1} />
                            <Tab icon={<AirportShuttle color='secondary' />} label={lang.ACTIVE_PATROL[language]} value={2} />
                            <Tab icon={<History color='primary' />} label={lang.ALARM_HISTORY[language]} value={3} disabled={!(props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "INTERVENTIONS", name: "VIEW_INTERVENTION_HISTORY" }))} />
                        </Tabs>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
};



class InterventionsView extends Component {
    state = {
        selectedTab: 1,
        selectedArea: null,
        areaUserList: [],
        userAreaListId: [],
        selectedIndex: null,
        interModal: false,
        firstLoad: true,
    };

    componentWillMount() {
        this.props.actions.checkSession();
        this.props.actions.getAreaList();
        fetch(`/api/get_user_area?id=${this.props.userData.id}`, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.length > 0) {
                    let idList = [];
                    response.data.map(area => {
                        idList.push(area.AREA_CODE);
                    })

                    this.setState({
                        userAreaListId: idList,
                        areaUserList: response.data,
                        selectedArea: response.data[0].AREA_CODE,
                        firstLoad: false
                    });
                } else {
                    this.setState({
                        userAreaListId: [],
                        areaUserList: [],
                        selectedArea: null,
                        firstLoad: false
                    });
                }
            })
    };

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <DeprecatedInterventionModal
                    siteData={this.props.siteData}
                    language={this.props.language}
                    open={this.state.interModal}
                    close={() => this.setState({ interModal: false })}
                    interventionId={this.state.selectedIndex}
                    userPermissions={this.props.permissions}
                    isUploadingFile={this.props.isUploadingFile}
                    isAuthenticated={this.props.isAuthenticated}
                    cust={{
                        data: this.props.customerData,
                        loading: this.props.isFetchingCustomerData || this.props.isFetchingSiteData,
                        taskData: this.props.interventionData,
                        initialLoad: async () => {
                            await this.props.actions.getCustomerData(this.props.interventionData.task.CONTACT_OBJID)
                            await this.props.actions.getSiteData(this.props.interventionData.task.SITE_OBJID)
                        },
                    }}
                    inter={{
                        userId: this.props.userAuth.id,
                        data: this.props.interventionData,
                        loading: this.props.isFetchingInterventionData,
                        downloadFile: downloadFile,
                        fetchData: () => this.props.actions.getInterventionData(this.state.selectedIndex),
                        newTaskComment: (changeType, changeFrom, changeTo, note, file) => this.props.actions.newInterventionComment(
                            this.props.userAuth.id,
                            {
                                INTERVENTION_OBJID: this.props.interventionData.task.OBJID,
                                CHG_USER: this.props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                                NOTE: note,
                                INTERVENTION: true,
                                GPS: this.props.geolocation,
                                FILE: file,
                                THEFT: false
                            }
                        ),
                        newTaskAction: (changeType, changeFrom, changeTo, note, pvImage, alarm, offender, report, theft, emaildata) => {
                            const formData = new FormData();

                            const informationBlob = new Blob([JSON.stringify({
                                USER_ID: this.props.userAuth.id,
                                INTERVENTION_OBJID: this.props.interventionData.task.OBJID,
                                CHG_USER: this.props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                                NOTE: note,
                                INTERVENTION: true,
                                GPS: this.props.geolocation,
                                ALARM: alarm,
                                OFFENDER: offender,
                                REPORT: report,
                                THEFT: theft,
                                EMAILDATA: emaildata
                            }, { type: "application/json" })]);

                            formData.append("file", pvImage !== null ? pvImage : null);
                            formData.append("information", informationBlob);

                            this.props.actions.newInterventionAction(this.props.userAuth.id, formData);
                        }
                    }}
                />
                <TabSelector
                    language={this.props.language}
                    value={this.state.selectedTab}
                    handleChange={newValue => this.setState({ selectedTab: newValue })}
                    userPermissions={this.props.permissions}
                    isAuthenticated={this.props.isAuthenticated}
                    areaList={this.state.areaUserList.length > 0 ? this.state.areaUserList : this.props.areaList}
                    lockAllArea={this.state.areaUserList.length <= 0}
                    selectedArea={this.state.selectedArea}
                    setSelectedArea={(areaCode) => this.setState({ selectedArea: areaCode })}
                />
                {
                    this.state.selectedTab === 1 &&
                    <AlarmTable
                        firstLoad={this.state.firstLoad}
                        userAreaList={this.state.userAreaListId}
                        language={this.props.language}
                        selectedArea={this.state.selectedArea}
                        openRowModal={id => this.setState({ selectedIndex: id, interModal: true })}
                        interModalStatus={this.state.interModal}
                        hasPermissionToViewStatus={checkPermissions(
                            this.props.permissions,
                            {
                                parent: "INTERVENTIONS",
                                name: "VIEW_INTERVENTION_WITH_PAY"
                            }
                        )}
                    /> ||
                    this.state.selectedTab === 2 &&
                    <PatrolTable
                        firstLoad={this.state.firstLoad}
                        language={this.props.language}
                        selectedArea={this.state.selectedArea}
                        openRowModal={id => this.setState({ selectedIndex: id, interModal: true })}
                        interModalStatus={this.state.interModal}
                    /> ||
                    this.state.selectedTab === 3 &&
                    <InterventionHistoryTable
                        language={this.props.language}
                        selectedArea={this.state.selectedArea}
                        openRowModal={id => this.setState({ selectedIndex: id, interModal: true })}
                        hasPermissionToViewStatus={checkPermissions(
                            this.props.permissions,
                            {
                                parent: "INTERVENTIONS",
                                name: "VIEW_INTERVENTION_WITH_PAY"
                            }
                        )}
                    />
                }
            </React.Fragment >
        )
    }
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        areaList: state.nom.areaList,
        userData: state.auth.userData,
        geolocation: state.config.geolocation,
        userAuth: state.auth.userData,
        customerData: state.cust.customerData,
        interventionList: state.task.interventionList,
        interventionData: state.task.interventionData,
        isFetchingInterventionData: state.task.isFetchingInterventionData,
        isFetchingCustomerData: state.cust.isFetchingCustomerData,
        isFetchingSiteData: state.cust.isFetchingSiteData,
        siteData: state.cust.siteData,
        isUploadingFile: state.task.isUploadingFile
    }
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InterventionsView);
