import React, { useState, useEffect } from "react";
import axios from "axios";

import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import MaintenanceModal from "../../Maintenance/MaintenanceModal/MaintenanceModal";

import * as lang from "../../../Constants/strings";
import * as requestHeaders from "../../../Constants/requestHeaders";

import { maintenance_view_site } from "../../../Components/Misc/Tables/TableHeaders";
import { formatDateFromDb } from "../../../Functions/dateFunctions";

const MaintenanceTable = (props) => {
    const {
        siteId,
        language,
        userPermissions,
        isAuthenticated,
        onNotificationOpen,
        user,
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState([]);
    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const [maintenanceId, setMaintenanceId] = useState();

    const getSiteMaintenance = async ({ siteId, active = false }) => {
        try {
            const response = await axios.get("/api/get_site_maintenances", {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: siteId,
                    active: false
                }
            });

            let list = [];
            response.data.maintenanceList.map(item => {
                list.push({
                    ...item,
                    TYPE: item.TYPE !== null ? lang[item.TYPE][language] : item.TYPE !== null,
                    STATUS: lang[item.STATUS][language],
                    LAST_UPDATE: formatDateFromDb(item.LAST_UPDATE, 12),
                    CREATE_TIME: formatDateFromDb(item.CREATE_TIME, 12),
                });
            });
            setData(list);
        } catch (error) {
            setData([]);
        }
    };

    useEffect(() => {
        getSiteMaintenance({
            siteId: siteId,
            active: false
        });
    }, [props.siteId]);

    return (
        <>
            <MaintenanceModal
                open={maintenanceModal}
                close={() => setMaintenanceModal(false)}
                language={language}
                userPermissions={userPermissions}
                isAuthenticated={isAuthenticated}
                onNotificationOpen={(data, message) => onNotificationOpen(data, message)}
                maintenanceId={maintenanceId}
                user={user}
            />
            <MaterialTable
                hideAddBtn={true}
                tiny={false}
                title={lang.MAINTENANCE[language]}
                language={language}
                data={data}
                rowCount={data.length}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={maintenance_view_site(language)}
                dense={false}
                openAddModal={() => alert("Add")}
                openRowModal={(id) => {
                    setMaintenanceId(id);
                    setMaintenanceModal(true);
                }}
                clickLabel="OBJID"
            />
        </>
    )
};

export default MaintenanceTable;
