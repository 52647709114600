import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import * as lang from '../../../Constants/strings';

export default function SiteCentralForm(props) {
    const { addData, language, updateField, disableEdit } = props;

    return (
        <React.Fragment>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="CENTRAL_TYPE"
                    label={lang.CENTRAL_TYPE[language]}
                    value={addData.CENTRAL_TYPE ? addData.CENTRAL_TYPE : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="CENTRAL_PASSWORD"
                    label={lang.CENTRAL_PASSWORD[language]}
                    value={addData.CENTRAL_PASSWORD ? addData.CENTRAL_PASSWORD : ""}
                    onChange={(e) => updateField(e, 50)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disableEdit}
                    fullWidth
                    variant="outlined"
                    name="CENTRAL_PHONE"
                    label={lang.CENTRAL_PHONE[language]}
                    value={addData.CENTRAL_PHONE ? addData.CENTRAL_PHONE : ""}
                    onChange={(e) => updateField(e, 20)}
                    type="number"
                />
            </Grid>
        </React.Fragment>
    );
}