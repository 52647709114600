import history from '../history';
import jwtDecode from 'jwt-decode';
import { onNotificationOpen } from './configActions';
import * as lang from '../Constants/strings';

export const SIGN_IN_BEGIN = "SIGN_IN_BEGIN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const LOGOUT = "LOGOUT";

export function SignInBegin() {
    return {
        type: SIGN_IN_BEGIN
    }
};

export function SignInSuccess(data) {
    // console.log("[SIGN_IN_SUCCESS]: " + token);
    localStorage.setItem('token', data.token);
    // console.log("[SIGN_IN_SUCCESS_LOCAL]: " + localStorage.token);
    return {
        type: SIGN_IN_SUCCESS,
        payload: data
    }
}

export function SignInFailure(error) {
    localStorage.removeItem('token');
    if (window.location.pathname !== '/sign_in')
        history.push('/sign_in');
    return {
        type: SIGN_IN_FAILURE,
        payload: { error }
    }
}

export function Logout() {
    localStorage.removeItem('token');
    return {
        type: LOGOUT
    }
}

export function LogoutAndRedirect(alt) {
    return (dispatch, state) => {
        dispatch(Logout());
        if (alt)
            dispatch(onNotificationOpen('info', lang.LOG_OUT, 6000));
        history.push('/sign_in');
    }
}

export function AlreadyLogedIn() {
    return (dispatch, state) => {
        history.push('/home');
    }
}

export const OPEN_CONFIRM_WINDOW = "OPEN_CONFIRM_WINDOW";

export function OpenConfirmWindow() {
    return {
        type: OPEN_CONFIRM_WINDOW
    }
}

export function SignInUser(user, password, redirect, updateToken) {
    return function (dispatch) {
        dispatch(SignInBegin());
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ username: user, password: password, updateToken })
        };

        return fetch('/api/sign_in', options)
            .then(response => response.json())
            .then(response => {
                try {
                    jwtDecode(response.data.token);
                    if (response.data.token) {
                        if (jwtDecode(response.data.token)) {
                            if (window.location.pathname === '/sign_in')
                                dispatch(onNotificationOpen('success', lang[response.message], 6000));
                            dispatch(SignInSuccess(response.data));
                            if (updateToken === false) {
                                if (redirect === false)
                                    window.location.reload();
                                else
                                    history.push(redirect);
                            }
                        }
                    }
                } catch (e) {
                    dispatch(SignInFailure(response.message));
                    if (response.message.param)
                        dispatch(onNotificationOpen('warning', lang[response.message.message](response.message.param), 6000));
                    else
                        dispatch(onNotificationOpen('warning', lang[response.message], 6000));
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(onNotificationOpen('warning', {
                    ro: "Nu sa putut realiza conexiunea cu server-ul!",
                    en: "Can't connect to the server!"
                }, 6000));
                dispatch(SignInFailure(error));
            })
    }
}

export function checkSession(route) {
    return function (dispatch) {
        return fetch(
            '/api/check_session',
            {
                method: 'GET',
                headers: {
                    'authorization': localStorage.token,
                },
            })
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.getItem("token")) {
                            dispatch(OpenConfirmWindow());
                        }
                        else {
                            dispatch(LogoutAndRedirect(true));
                        }
                        break;
                    }
                    default: return response;
                }
            })
            .then(response => response.json())
            .then(response => {
                if (response.data && response.data.token) {
                    jwtDecode(response.data.token);
                    if (route) {
                        dispatch(AlreadyLogedIn());
                    }
                }
                else {
                    dispatch(LogoutAndRedirect(true));
                }
            })
            .catch(error => {
                dispatch(OpenConfirmWindow());
            })
    }
}