import React from 'react';
import * as lang from '../../../Constants/strings';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomModal from '../../../Components/Modals/CustomModal';
import { Grid, MenuItem, useMediaQuery, Typography, Divider, Chip, Select, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { formatDateFromDb } from '../../../Functions/dateFunctions';
import { addressToString } from '../../../Functions/objectManipulation';
import { intervention_history_data } from '../../../Components/Misc/Tables/TableHeaders';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { PhoneRounded, EmailRounded, ReceiptRounded, LocationOn } from '@material-ui/icons';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import InterventionCount from './InterventionCount';
import getGoogleMapLink, { getGoogleMapRouteLink } from "../../../Functions/getGoogleMapLink";

const useStyles = makeStyles(theme => ({
    margin: {
        width: 'auto'
    },
    dateSelect: {
        margin: theme.spacing(1)
    },
    dividerStyle: {
        margin: theme.spacing(1, 1, 1, 1)
    },
    subtitle: {
        margin: theme.spacing(1)
    },
    textStyle: {
        margin: theme.spacing(1, 1, 1, 1)
    }
}));


export default function SiteInterventionModal(props) {
    const theme = useTheme();
    const classes = useStyles();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // deconstruct the props 
    const { open, close, language, selectedIndex } = props;

    // Containe all the intervention that where fetch by the Effect
    // The intervention will be sort wsing the givied params off the fetch function
    const [interventionList, setInterventionList] = React.useState([]);
    const [siteDetails, setSiteDetails] = React.useState(null);

    // The siteContractList will containe all the site activeContracts
    const [siteContractList, setSiteContractList] = React.useState([]);
    // This will containe the selected value display by the Select element
    // Is a object that reference the selected value
    // It dosen't containe the actuale value
    const [selectedContract, setSelectedContract] = React.useState(null);
    // In contractDetails we will have all the details for the selected contract as a object
    const [contractDetails, setContractDetails] = React.useState(null);

    // The Document modal setup
    const [docModalStaus, setDocModalStatus] = React.useState(false);
    // Is the Intervention OBJID and will be use to fetch all the documents from the view_intervention_docs
    const [interventionIndex, setInterventionIndex] = React.useState(null);
    // This list will containe all the docs for the interventionIndex
    const [interventionDocs, setInterventionDocs] = React.useState([]);

    // This dates will be set by the MuiPicker
    // Use for the interventtion list fetching
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(new Date());

    // Get the site info for the selectedIndex prop
    React.useEffect(() => {
        if (selectedIndex)
            fetch(`/api/site?id=${selectedIndex}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            })
                .then(response => response.json())
                .then(response => {
                    setSiteDetails(response.data)
                })
    }, [props.selectedIndex]);

    // Get the active site contract
    // And sort them
    // Only the CONTRACT_TYPE_WATCH subtype will be use
    React.useEffect(() => {
        fetch(`/api/get_site_contracts?id=${selectedIndex}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                let watchType = [];
                response.map(contract => {
                    if (contract.TYPE === 'CONTRACT_TYPE_WATCH') {
                        watchType.push(contract)
                    }
                });
                setSiteContractList(watchType)
            })
    }, [props.selectedIndex]);

    // The hook that will fetch the intervention list base on the selected params
    // This hooks will be call when one of this param will be change : props.selectedIndex, startDate, endDate, contractDetails ;
    React.useEffect(() => {
        // The contractId query param will be use only if the contractDetails is not null 
        // So when we don't want to  get the intervention base on contract all we have to do is to set contractDetails to null
        let contract = contractDetails !== null && contractDetails.OBJID ? `&contractId=${contractDetails.OBJID}` : '';
        fetch(`/api/get_site_close_interventions?siteId=${props.selectedIndex}&startDate=${formatDateFromDb(startDate, 15)}&endDate=${formatDateFromDb(endDate, 15)}${contract}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                // formating the date and translate the contract TYPE
                response.data.length > 0 &&
                    response.data.map(data => {
                        data.CREATE_DATE = formatDateFromDb(data.CREATE_DATE, 3);
                        data.END_DATE = formatDateFromDb(data.END_DATE, 3);
                        data.TANSLATE_TYPE = lang[data.TYPE][language];
                    })
                setInterventionList(response.data)
            });
    }, [props.selectedIndex, startDate, endDate, contractDetails]);

    // Will get the file name as a param and will retrive the the file from server
    const downloadFile = (fileName) => {
        fetch(`/api/download_pdf?file=${fileName}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.blob();
            })
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf;' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(err => console.log(err))
    };

    // This will take a intervention id and retrive the docs names form the backend
    React.useEffect(() => {
        fetch(`/api/get_site_intervention_docs?siteId=${interventionIndex}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setInterventionDocs(response.data)
            })
    }, [interventionIndex]);

    return (
        <React.Fragment>
            {/* Documents modal download*/}
            <Dialog fullWidth onClose={() => setDocModalStatus(false)} open={docModalStaus} onBackdropClick={() => setDocModalStatus(false)}>
                <DialogTitle>{lang.INTERVENTION_DOCS[props.language]}</DialogTitle >
                <DialogContent>
                    <Grid container justify='center' alignItems='stretch'>
                        {
                            interventionDocs.length > 0 && interventionDocs.map(doc => {
                                return <Grid key={doc.NAME + '_grid'} item xs={12}>
                                    <Button key={doc.NAME + '_btn'} fullWidth style={{ margin: 5 }} variant='contained' color='primary' onClick={() => {
                                        downloadFile(doc.NAME)
                                        setDocModalStatus(false)
                                    }}>{doc.NAME}</Button>
                                </Grid>
                            })
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* The main Modal */}
            <CustomModal
                open={open}
                fullScreen
                close={close}
                title={lang.VIEW_SITE_ALARMS[language]}
                content={
                    <Grid container component="main" justify='center' alignItems='center' spacing={2}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Grid item xs={12}>
                                    <Typography className={classes.dateSelect} variant='h6'>{lang.SELECT_INTERVAL_DATE[language]}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.dividerStyle} />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className={classes.dateSelect}
                                            autoOk
                                            required={true}
                                            inputVariant="outlined"
                                            fullWidth
                                            name="START_DATE"
                                            label={lang.START_INTERVAL_DATE[props.language]}
                                            format="dd/MM/yyyy"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            value={startDate}
                                            onChange={(value) => setStartDate(value)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className={classes.dateSelect}
                                            autoOk
                                            required={true}
                                            inputVariant="outlined"
                                            fullWidth
                                            name="END_DATE"
                                            label={lang.LAST_INTERVAL_DATE[props.language]}
                                            format="dd/MM/yyyy"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            value={endDate}
                                            onChange={(value) => setEndDate(value)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={12}>
                                    <Typography className={classes.textStyle} variant='h6'>{lang.SITE_DETAILS[language]}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.dividerStyle} />
                                </Grid>
                                <Grid container justify="center" alignItems="center" direction="column">
                                    <Grid container justify="center" alignItems="center" direction="row">
                                        {siteDetails && <Typography className={classes.title} variant="h4">{siteDetails.SITE_NAME}</Typography>}
                                    </Grid>
                                    <Grid container justify="center" alignItems="center" direction="row">
                                        {siteDetails &&
                                            <Chip className={classes.subtitle} color="secondary" label={`${lang.SITE[language]} #${siteDetails.SITE_CODE}`} />}
                                        {siteDetails && siteDetails.PHONE &&
                                            <Chip className={classes.subtitle} icon={<PhoneRounded fontSize="small" />} color="default" label={<a className={classes.links} href={`tel:${siteDetails.PHONE}`}>{siteDetails.PHONE}</a>} />}
                                        {siteDetails && siteDetails.EMAIL &&
                                            <Chip className={classes.subtitle} icon={<EmailRounded fontSize="small" />} color="default" label={<a className={classes.links} href={`mailto:${siteDetails.EMAIL}`}>{siteDetails.EMAIL}</a>} />}
                                    </Grid>
                                    <Grid container justify="center" alignItems="center" direction="row">
                                        {siteDetails && siteDetails.LATITUDE && siteDetails.LONGITUDE &&
                                            <Chip className={classes.subtitle} icon={<LocationOn fontSize="small" />} color="default" label={<a className={classes.links} target="_blank" href={`${getGoogleMapLink(siteDetails.LATITUDE, siteDetails.LONGITUDE)}`}>{lang.GPS[language]}</a>} />}
                                        {siteDetails &&
                                            <Chip className={classes.subtitle} icon={<ReceiptRounded fontSize="small" />} color="secondary" label={<a className={classes.links} target="_blank" href={`${getGoogleMapRouteLink(addressToString(siteDetails, 3))}`}>{addressToString(siteDetails, 1)}</a>} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.dividerStyle} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Typography className={classes.textStyle} variant='h5'>{lang.SELECT_CONTRACT[language]}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.dividerStyle} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select fullWidth variant='outlined' value={selectedContract} onChange={(e, newValue) => {
                                        setSelectedContract(newValue)
                                        setContractDetails(e.target.value)
                                    }}>
                                        <MenuItem value={null}>{lang.NONE[language]}</MenuItem>
                                        {
                                            siteContractList.map(contract => {
                                                console.log(contract)
                                                return <MenuItem key={contract.CONTRACT_NO} value={contract}>{`${lang[contract.SUB_TYPE][language]} #${contract.CONTRACT_NO}`}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                            {
                                contractDetails !== null ?
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <InterventionCount
                                                language={language}
                                                contractData={contractDetails}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.textStyle} variant='h5'>{lang.CONTRACT_DETAILS[language]}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider className={classes.dividerStyle} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justify="center" alignItems="center" direction="column">
                                                    <Grid container justify="center" alignItems="center" direction="row">
                                                        {contractDetails && contractDetails.CONTRACT_NO &&
                                                            <Chip className={classes.subtitle} color='primary' label={`${lang.CONTRACT_NUMBER[props.language]}: ${contractDetails.CONTRACT_NO}`} />
                                                        }
                                                        {contractDetails && contractDetails.SUB_TYPE &&
                                                            <Chip className={classes.subtitle} color='primary' label={`${lang.CONTRACT[props.language]}: ${lang[contractDetails.SUB_TYPE][language]}`} />
                                                        }
                                                        {contractDetails && contractDetails.START_DATE &&
                                                            <Chip className={classes.subtitle} color='primary' label={`${lang.CONTRACT_START_DATE[props.language]}: ${formatDateFromDb(contractDetails.START_DATE, 8)}`} />
                                                        }
                                                        {contractDetails && contractDetails.END_DATE &&
                                                            <Chip className={classes.subtitle} color='primary' label={`${lang.CONTRACT_END_DATE[props.language]}: ${formatDateFromDb(contractDetails.END_DATE, 8)}`} />
                                                        }
                                                        {contractDetails && contractDetails.FREE_TRUCK_ROLLS &&
                                                            <Chip className={classes.subtitle} color='secondary' label={`${lang.FREE_TRUCK_ROLLS[props.language]}: ${contractDetails.FREE_TRUCK_ROLLS}`} />
                                                        }
                                                        {contractDetails && contractDetails.TRUCK_ROLL_VALUE &&
                                                            <Chip className={classes.subtitle} color='secondary' label={`${lang.TRUCK_ROLL_VALUE[props.language]}: ${contractDetails.TRUCK_ROLL_VALUE} RON`} />
                                                        }
                                                        {contractDetails && contractDetails.BILL_CYCLE_DESC &&
                                                            <Chip className={classes.subtitle} color='secondary' label={`${lang.BILL_CYCLE[props.language]}: ${lang[contractDetails.BILL_CYCLE_DESC][language]}`} />
                                                        }
                                                        {contractDetails && contractDetails.VALUE &&
                                                            <Chip className={classes.subtitle} color='secondary' label={`${lang.CONTRACT_VALUE[props.language]}: ${contractDetails.VALUE} RON`} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <div />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.dividerStyle} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.textStyle} variant='h5'></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialTable
                                tiny={tiny}
                                title={`${lang.INTERVENTION_TABLE[language]} de la ${formatDateFromDb(startDate, 8)} până la ${formatDateFromDb(endDate, 8)} ${contractDetails !== null ? ' pe baza contractului de  ' + lang[contractDetails.SUB_TYPE][language] + ' numărul #' + contractDetails.CONTRACT_NO : ''}`}
                                language={language}
                                data={interventionList && interventionList.length > 0 ? interventionList : []}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                                header={intervention_history_data(language)}
                                dense={false}
                                hideAddBtn
                                openRowModal={(index) => {
                                    setInterventionIndex(index)
                                    setDocModalStatus(true)
                                }}
                                clickLabel="INTERVENTION_ID"
                            />
                        </Grid>
                    </Grid>
                }
                noButtons
                onlyBack
                language={language}
            />
        </React.Fragment>
    )
};
