import React, { Component } from 'react';
import { Grid, CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTableDemo from '../../../Components/Misc/Tables/MaterialTableSimple';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { roleNom } from '../../../Components/Misc/Tables/TableHeaders'
import * as lang from '../../../Constants/strings';
import CustomModal from '../../../Components/Modals/CustomModal';
import TableSkeleton from '../Skeleton';
import RoleNewForm from '../../../Components/Misc/Forms/RoleNewForm';
import axios from 'axios';
import RoleEditModal from './RoleNomComponents/RoleEditModal';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}));

function RoleNomSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.ROLE_TABLE[props.language]}
                    language={props.language}
                    data={props.roleList}
                    rowsPerPage={props.rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={roleNom(props.language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="ID"
                />
            </Grid>
        </Grid>
    );
}

class RoleNom extends Component {
    state = {
        addModal: false,
        selectedIndex: null,
        roleData: {},
    }
    fetchPermissionList = () => {
        axios.get('/api/permission_list')
            .then(res => {
                console.log(res.data)
                this.setState({ roleData: { ...this.state.roleData, PERMISSIONS: res.data } });
            })
            .catch(err => {
                console.log(err.message);
            });
    }
    tiny = theme => false;

    componentDidMount() {
        this.props.actions.getRoleList();
    }
    getIndeterminate = (title) => {
        let total = this.state.roleData.PERMISSIONS.filter(permission => permission.PARENT_PERMISSION === title).length;
        let checked = this.state.roleData.PERMISSIONS.filter(permission => permission.PARENT_PERMISSION === title && permission.VALUE === 1).length;

        if (total === checked)
            return 1;
        else
            if (checked === 0)
                return 0;
            else
                return -1;
    }
    updateCheckBox = (e, type) => {
        let placeholder = [];
        if (type === 1) {
            let parentName = this.state.roleData.PERMISSIONS.filter(permission => permission.PERMISSION === e.target.value)[0].PARENT_PERMISSION;
            this.state.roleData.PERMISSIONS.forEach(permission => {
                if (permission.PERMISSION === e.target.value)
                    placeholder.push({ ...permission, VALUE: permission.VALUE === 1 ? 0 : 1 })
                else
                    placeholder.push(permission);
            });
            let total = placeholder.filter(permission => permission.PARENT_PERMISSION === parentName).length;
            let checked = placeholder.filter(permission => permission.PARENT_PERMISSION === parentName && permission.VALUE === 1).length;
            placeholder.forEach((permission, index) => {
                if (permission.PERMISSION === parentName)
                    placeholder[index].VALUE = total === checked ? 1 : 0;
            })
        }
        else {
            let parent = this.state.roleData.PERMISSIONS.filter(permission => permission.PERMISSION === e.target.value)[0];
            this.state.roleData.PERMISSIONS.forEach(permission => {
                if (permission.PARENT_PERMISSION === e.target.value)
                    placeholder.push({ ...permission, VALUE: parent.VALUE === 0 ? 1 : 0 })
                else
                    if (permission.PERMISSION === e.target.value) {
                        placeholder.push({ ...permission, VALUE: parent.VALUE === 0 ? 1 : 0 })
                    }
                    else
                        placeholder.push(permission);

            })
        }

        this.setState({
            roleData: {
                ...this.state.roleData,
                PERMISSIONS: placeholder
            }
        })
    }
    updateField = (e) => {
        this.setState({ roleData: { ...this.state.roleData, NAME: e.target.value } })
    }
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    // fullWidth
                    fullScreen
                    open={this.state.addModal}
                    title={lang.ADD_ROLE[this.props.language]}
                    content={
                        <RoleNewForm
                            updateField={this.updateField}
                            getIndeterminate={this.getIndeterminate}
                            roleData={this.state.roleData}
                            updateCheckBox={this.updateCheckBox}
                            language={this.props.language}
                        />
                    }
                    close={() => {
                        this.setState({
                            addModal: false,
                            roleData: {},
                        })
                    }}
                    execute={() => {
                        this.props.actions.addNewRole(this.props.userAuth.id, this.state.roleData);
                        this.setState({ addModal: false })
                    }}
                    validate={this.state.roleData.NAME && this.state.roleData.NAME.length > 2}
                    language={this.props.language}
                />
                <RoleEditModal
                    authID={this.props.userAuth.id}
                    language={this.props.language}
                    selectedIndex={this.state.selectedIndex}
                    // updateExecutor={(data) => this.props.actions.updateExecutor(this.props.userAuth.id, { ...data, selectedIndex: this.state.selectedIndex })}
                    // toggleExecutor={(data) => this.props.actions.toggleExecutor(this.props.userAuth.id, data)}
                    close={() => this.setState({ selectedIndex: null })}
                    updateRole={(data) => this.props.actions.updateRole(this.props.userAuth.id, data, this.state.selectedIndex)}
                />
                {/* <EditModal
                    authID={this.props.userAuth.id}
                    language={this.props.language}
                    selectedIndex={this.state.selectedIndex}
                    updateExecutor={(data) => this.props.actions.updateExecutor(this.props.userAuth.id, { ...data, selectedIndex: this.state.selectedIndex })}
                    toggleExecutor={(data) => this.props.actions.toggleExecutor(this.props.userAuth.id, data)}
                    close={() => this.setState({ selectedIndex: null })}
                /> */}
                {this.props.isFetchingRoleList === false
                    ?
                    <RoleNomSetup
                        language={this.props.language}
                        roleList={this.props.roleList}
                        rowsPerPage={this.props.rowsPerPage}
                        onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
                        openAddModal={() => {
                            this.fetchPermissionList();
                            this.setState(
                                {
                                    addModal: true,
                                    roleData: {
                                        ...this.state.roleData,
                                        NAME: ""
                                    }
                                })
                        }
                        }
                        openRowModal={(value) => this.setState({ selectedIndex: value })}
                    />
                    :
                    <TableSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,

        roleList: state.nom.roleList,
        isFetchingRoleList: state.nom.isFetchingRoleList,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleNom);
