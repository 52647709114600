import React, { Component } from 'react';
import Toolbar from './LayoutComponents/Toolbar';
import Drawer from './LayoutComponents/Drawer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';

class Layout extends Component {
    state = {
        openDrawer: false,
    };

    render() {
        return (
            <React.Fragment>
                <Toolbar
                    toggleDrawer={() => this.setState({ openDrawer: !this.state.openDrawer })}
                    theme={this.props.theme}
                    onDarkModeToggle={this.props.actions.onDarkModeToggle}
                    language={this.props.language}
                    onLanguageChange={this.props.actions.onLanguageChange}
                    LogoutAndRedirect={this.props.actions.LogoutAndRedirect}
                    userID={this.props.userAuth && this.props.userAuth.id}
                />

                <Drawer
                    admin={this.props.admin}
                    open={this.state.openDrawer}
                    toggleDrawer={() => this.setState({ openDrawer: !this.state.openDrawer })}
                    language={this.props.language}
                />
                {this.props.children}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        theme: state.config.theme,
        language: state.config.language,
        userAuth: state.auth.userData,
    };
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout);