import React, { useState } from "react";
import {
    Grid,
    TextField,
} from "@material-ui/core";

import axios from "axios";
import CustomModal from "../../../../Components/Modals/CustomModal";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import * as lang from "../../../../Constants/strings";

const SetReasonModal = (props) => {
    const {
        open,
        close,
        language,
        installationId,
        refetch,
        userId
    } = props;

    const [reason, setReason] = useState("");

    const update = async () => {
        try {
            await axios.post("/api/update_installation_reason", {
                id: installationId,
                reason: reason,
                userId
            }, {
                ...requestHeaders.getPostHeader(),
            });
            refetch();
            close();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <CustomModal
            open={open}
            close={close}
            fullWidth
            title={`Editare motiv instalare`}
            content={
                <Grid container spacing={2}>
                    <TextField
                        label={lang.REASON[language]}
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            }
            execute={() => update()}
            validate={true}
            language={language}
        />
    )
};

export default SetReasonModal;