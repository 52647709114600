import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';
import CustomModal from '../../../../Components/Modals/CustomModal';
import SignaturePad from 'react-signature-canvas';
import axios from "axios";
import * as requestHeaders from "../../../../Constants/requestHeaders";

export default function PvModal(props) {
    const { open, close, riscEvaluationId, language, classes, tiny } = props;

    const [sign, setSign] = useState(false);
    const [signature, setSignature] = useState(null);
    const [evalProcent, setEvalProcent] = useState(0);
    const [goodEval, setGoodEval] = useState(false);
    const [obs, setObs] = useState("");
    const [name, setName] = useState('');
    const signCanvas = React.useRef({});

    const exec = async () => {
        try {
            const res = await axios.post(
                '/api/evaluation_create_pv',
                {
                    customerName: name,
                    signature,
                    id: riscEvaluationId,
                    evalProcent,
                    goodEval,
                    obs
                },
                requestHeaders.getPostHeader());
        } catch (error) {
            console.error(error)
        } finally {
            close();
        }
    }
    return (
        <React.Fragment>


            <CustomModal
                fullWidth
                fullScreen={tiny}
                open={open}
                content={
                    <Grid container justify="center" className={classes.float} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                value={name}
                                label="Nume Persona"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                required
                                type="number"
                                variant="outlined"
                                label="Punctaj Evaluare"
                                value={evalProcent}
                                onChange={(e) => setEvalProcent(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                multiline
                                aria-rowcount={3}
                                required
                                type="number"
                                variant="outlined"
                                label="Obeservații"
                                value={obs}
                                onChange={(e) => setObs(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={goodEval} onChange={() => setGoodEval(!goodEval)} />}
                                label={<Typography variant="caption">Însușire Analiză</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Button
                                onClick={() => setSign(true)}
                                variant='contained'
                                color="primary"
                                fullWidth
                            >
                                Semneaza
                            </Button>
                        </Grid>
                        <input
                            onChange={() => 1 + 0}
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, height: 0, width: 0 }}
                            value={signature === null ? "" : signature}
                            required
                        />
                    </Grid>
                }
                close={() => {
                    close()
                }}
                execute={() => exec()}
                language={language}
                validate={name !== '' && signature !== null}
            />
            <CustomModal
                fullWidth
                fullScreen={tiny}
                open={sign}
                content={
                    <Grid item xs={12}>
                        <SignaturePad
                            ref={signCanvas}
                            canvasProps={{
                                className: classes.signature
                            }}
                        />
                    </Grid>
                }
                execute={() => {
                    setSignature(signCanvas.current.getTrimmedCanvas().toDataURL("image/svg+xml"));
                    signCanvas.current.clear()
                    setSign(false);
                }}
                close={() => {
                    signCanvas.current.clear()
                    setSign(false);
                }}
                language={language}
                validate={true}
            />
        </React.Fragment>
    );
}
