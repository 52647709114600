import React from 'react';
import { Grid, TextField, FormControl, FormControlLabel, Checkbox, FormLabel, FormHelperText, FormGroup, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../../Constants/strings';
import * as perm from '../../../Constants/permissions';
import { subPermissionDetails, permissionDetails } from '../../../Constants/permissionDetails';



const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(3),
    },
    formGroup: {
        marginLeft: theme.spacing(3),
    },
}));

function renderPermissions(permissions, classes, props) {
    let toRender = [];

    permissions.filter(permission => permission.PARENT_PERMISSION === null).forEach((parentPermission, parentIndex) => {
        toRender.push(
            <Grid item xs={12} sm={12} md={6} lg={4} key={parentIndex}>
                <FormControl component="fieldset" className={classes.formControl} >
                    <FormLabel component="legend">
                        <Tooltip
                            key={parentIndex + '_ParentPermission'}
                            title={permissionDetails[parentPermission.PERMISSION] ? permissionDetails[parentPermission.PERMISSION][props.language] : ''}
                            arrow='true'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        indeterminate={props.getIndeterminate(parentPermission.PERMISSION) === -1}
                                        checked={props.getIndeterminate(parentPermission.PERMISSION) === 1}
                                        value={parentPermission.PERMISSION}
                                        onChange={(e) => props.updateCheckBox(e, 2)}
                                    />
                                }
                                label={perm[parentPermission.PERMISSION][props.language]}
                            />
                        </Tooltip>
                    </FormLabel>
                    <FormGroup className={classes.formGroup}>
                        {permissions.filter(permission => permission.PARENT_PERMISSION === parentPermission.PERMISSION).map((subPermission, subIndex) =>
                            <Tooltip key={'ToolTip_' + subIndex} title={subPermissionDetails[subPermission.PERMISSION] ? subPermissionDetails[subPermission.PERMISSION][props.language] : ''}
                                arrow='true'>
                                <FormControlLabel
                                    key={subIndex}
                                    control={
                                        <Checkbox
                                            checked={subPermission.VALUE === 1}
                                            value={subPermission.PERMISSION}
                                            onChange={(e) => props.updateCheckBox(e, 1)}
                                        />
                                    }
                                    label={perm[subPermission.PERMISSION] ? perm[subPermission.PERMISSION][props.language] : subPermission.PERMISSION}
                                />
                            </Tooltip>
                        )}
                    </FormGroup>
                    <FormHelperText></FormHelperText>
                </FormControl >
            </Grid>
        );
    });
    return toRender;
}

export default function RoleNewForm(props) {
    const classes = useStyles();
    const [editable, setEditable] = React.useState(true);

    React.useEffect(() => {
        setEditable(true);
    }, [props.roleData.NAME]);

    return (
        <Grid container>
            <Grid item xs={12}>
                {editable && !props.lock
                    ?
                    <TextField
                        // autoFocus
                        required
                        label={lang.ADD_ROLE[props.language]}
                        onChange={props.updateField}
                        variant="outlined"
                        value={(props.roleData && props.roleData.NAME) || ""}
                        placeholder={lang.ADD_ROLE[props.language]}
                        onBlur={() => props.roleData && props.roleData.NAME ? setEditable(false) : null}
                        helperText={lang.MIN_THREE_CHAR[props.language]}
                    />
                    :
                    <Typography
                        variant="h6"
                        onClick={() => setEditable(true)}
                    >
                        {props.roleData && props.roleData.NAME}
                    </Typography>
                }
            </Grid>
            {props.roleData && props.roleData.PERMISSIONS && renderPermissions(props.roleData.PERMISSIONS, classes, props)}
        </Grid>
    );
}