import React, { useState, useEffect, useRef } from "react";
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  MenuItem,
  FormControl,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Fab
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SignaturePad from "react-signature-canvas";
import { AddRounded } from "@material-ui/icons";
import Trainee from "../../../Site/SiteSingleViewComponents/Trainee";
import CustomModal from "../../../../Components/Modals/CustomModal";
import * as lang from "../../../../Constants/strings";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import TaskHandover from "../../../Site/SiteSingleViewComponents/TaskHandover";

const useStyles = makeStyles((theme) => ({
  signature: {
    backgroundColor: "white",
    width: "100%",
    height: "50vh",
  },
  float: {
    margin: theme.spacing(1),
  },
  btnMargins: {
    margin: theme.spacing(2)
  }
}));

const CloseFields = (props) => {
  const {
    installationId,
    language,
    userId,
    refetchTaskData,
    tiny,
    nextStatus,
    currentStatus,
    contractType,
    serviceNo,
  } = props;

  const classes = useStyles();
  const [obs, setObs] = useState("");
  const [siteContact, setSiteContact] = useState("");
  const [signModal, setSignModal] = useState(false);
  const [dispatchers, setDispatchers] = useState([]);
  const [selectedDispatcher, setSelectedDispatcher] = useState();
  const [teamSize, setTeamSize] = useState();
  const [journalData, setJournalData] = useState({
    NO: "",
    DATE: new Date(),
    POS: "",
  });
  const [opt, setOpt] = useState([false, false, false, false]);
  const [showProject, setShowProject] = useState(false);
  const [showJournal, setShowJournal] = useState(false);
  const [trainees, setTrainees] = useState([{ NAME: "", SIGN: "" }]);
  const [type, setType] = useState("INSTALARE");
  const [subtype, setSubtype] = useState([]);
  const [project, setProject] = useState({
    SIGN: "",
    NUMBER: "",
    NAME: ""
  });
  const [journal, setJournal] = useState({
    SIGN: "",
    NUMBER: "",
    NAME: ""
  });

  const sigCanvas = useRef({});
  const [imageURL, setImageURL] = useState(null);

  const updateProject = (field, value) => {
    if (field === "SHOW")
      setShowProject(!showProject)
    else {
      let placeholder = { ...project };
      placeholder[field] = value;
      setProject(placeholder);
    }
  };

  const updateJournal = (field, value) => {
    if (field === "SHOW")
      setShowJournal(!showJournal)
    else {
      let placeholder = { ...journal };
      placeholder[field] = value;
      setJournal(placeholder);
    }
  };

  const addRemoveTrainee = (index) => {
    const placeholder = [...trainees];

    if (index === false) {
      placeholder.push({ NAME: "", SIGN: "" });
    } else {
      placeholder.splice(index, 1);
    }

    setTrainees(placeholder);
  };

  const updateJournalData = (name, value) => {
    setJournalData({ ...journalData, [name]: value })
  };

  useEffect(() => {
    fetch(`/api/dispatch_assignee_list`, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setDispatchers(response);
      })
      .catch((err) => console.log(err));
  }, []);

  const closeInstallation = async () => {
    console.log("PROJECT: ", project);
    try {
      await axios.post(
        "/api/close_installation",
        {
          INSTALLATION_ID: installationId,
          REASON: obs,
          CHG_USER: userId,
          CHG_TYPE: 3,
          CHG_FROM: currentStatus,
          CHG_TO: nextStatus,
          NOTE: obs,
          CONTRACT_TYPE: contractType,
          SITE_CONTACT: siteContact,
          SIGNATURE: imageURL,
          SERVICE_NO: serviceNo,
          WARRANTY: null,
          OPT: opt,
          TRAINEES: trainees,
          PROJECT: {
            data: project,
            show: showProject
          },
          JOURNAL: {
            data: journal,
            show: showJournal
          },
          DISPATCHER_ID: selectedDispatcher,
          TYPE: type,
          PRODUCTS: subtype,
          TEAM_SIZE: teamSize,
          JOURNAL_DATE: journalData.DATE,
          JOURNAL_DATA: journalData,
          DEAD_LINE: null,
          PV_IMAGE: undefined
        },
        requestHeaders.getPostHeader(),
      );
      await refetchTaskData();
    } catch (error) {
      console.log(error);
    }
  };

  const updateOpt = (position) => {
    const p = [...opt];
    p[position] = !p[position];
    setOpt(p);
  };

  const updateTrainee = (index, field, value) => {
    console.log(index, field, value);

    let placeholder = [...trainees];
    placeholder[index][field] = value;
    console.log("placeholder: ", placeholder);

    setTrainees(placeholder);
  };

  const updateSubtype = (data) => {
    const index = subtype.indexOf(data.props.value);
    if (index === -1) {
      setSubtype([...subtype, data.props.value]);
    } else {
      const placeholder = [...subtype];
      placeholder.splice(index, 1);
      setSubtype(placeholder);
    }
  };

  const saveSign = () =>
    setImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/svg+xml")
    );

  const clear = () => sigCanvas.current.clear();

  return (
    <Grid item xs={12}>
      <CustomModal
        fullWidth
        open={signModal}
        content={
          <Grid item xs={12}>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: classes.signature,
              }}
            />
          </Grid>
        }
        execute={() => {
          saveSign();
          clear();
          setSignModal(false);
        }}
        close={() => {
          clear();
          setSignModal(false);
        }}
        language={language}
        validate={true}
      />
      <form onSubmit={closeInstallation}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              multiline
              fullWidth
              variant="outlined"
              name="OBSERVATION"
              label={lang.OBSERVATION[language]}
              value={obs}
              onChange={e => setObs(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              select
              variant="outlined"
              label="Servicii"
              id="select-multiple-chip"
              onChange={(e, newValue) => updateSubtype(newValue)}
              SelectProps={{
                multiple: true,
                value: subtype,
              }}
            >
              <MenuItem value={'CCTV'}>CCTV</MenuItem>
              <MenuItem value={'EFRACTIE'}>EFRACTIE</MenuItem>
              <MenuItem value={'CONTROL ACCES'}>CONTROL ACCES</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              name="SITE_CONTACT"
              label={lang.SITE_CONTACT_NAME[language]}
              value={siteContact}
              onChange={(e) => setSiteContact(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                required
                select
                variant="outlined"
                label={lang.DISPATCH_SELECTED[language]}
                onChange={(e) => setSelectedDispatcher(e.target.value)}
                value={selectedDispatcher ? selectedDispatcher : ""}
              >
                {dispatchers.map(item => {
                  return <MenuItem key={item.OBJID} value={item.OBJID}>{item.USERNAME}</MenuItem>
                })}
              </TextField>
              <input
                onChange={() => 1 + 0}
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0, width: 0 }}
                value={selectedDispatcher ? selectedDispatcher : ""}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              type="number"
              name="NUMBER_TEAM_MEMBERS"
              label={lang.NUMBER_TEAM_MEMBERS[language]}
              value={teamSize}
              onChange={e => setTeamSize(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" align="center">{lang.JOURNAL_DATA[language]}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} >
            <TextField
              required
              fullWidth
              variant="outlined"
              name="NO"
              label={lang.NUMBER[language]}
              value={journalData.NO}
              onChange={e => updateJournalData('NO', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                required
                inputVariant="outlined"
                fullWidth
                name="DATE"
                label={lang.DATE[language]}
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                value={journalData.DATE}
                className={classes.formControl}
                onChange={(value) => updateJournalData('DATE', value)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3} >
            <TextField
              required
              fullWidth
              variant="outlined"
              name="POS"
              type="number"
              label={lang.POSITION[language]}
              value={journalData.POS}
              onChange={e => updateJournalData('POS', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox size="small" checked={opt[0]} onChange={() => updateOpt(0)} />}
              label={<Typography variant="caption">Au fost respectate cerințele tehnice și economice</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox size="small" checked={opt[1]} onChange={() => updateOpt(1)} />}
              label={<Typography variant="caption">Cantitățile de lucrări cuprinse în tabelul de mai jos au fost executate</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox size="small" checked={opt[2]} onChange={() => updateOpt(2)} />}
              label={<Typography variant="caption">Lucrările corespund calitativ și cantitativ</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox size="small" checked={opt[3]} onChange={() => updateOpt(3)} />}
              label={<Typography variant="caption">Predarea documentației</Typography>}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" align="center">{lang.TRAINED_PERSONS[language]}</Typography>
          </Grid>
          {trainees.map((item, index) =>
            <Trainee
              tiny={tiny}
              key={index}
              data={item}
              index={index}
              update={(field, value) => updateTrainee(index, field, value)}
              remove={() => addRemoveTrainee(index)}
              language={language}
              classes={classes}
            />
          )}
          <Fab
            className={classes.float}
            color="secondary"
            onClick={() => addRemoveTrainee(false)}
            size="small"
          >
            <AddRounded fontSize="small" />
          </Fab>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container item xs={12} justify="center">
            <FormControlLabel
              control={<Checkbox size="small" checked={showProject} onChange={() => updateProject('SHOW')} />}
              label={<Typography variant="h6" align="center">{lang.SYSTEM_PROJECT[language]}</Typography>}
            />
          </Grid>
          {!showProject ? null :
            <TaskHandover
              tiny={tiny}
              index={1}
              data={project}
              update={updateProject}
              language={language}
              classes={classes}
            />
          }

          <Grid container item xs={12} justify="center">
            <FormControlLabel
              control={<Checkbox size="small" checked={showJournal} onChange={() => updateJournal('SHOW')} />}
              label={<Typography variant="h6" align="center">{lang.SYSTEM_JOURNAL[language]}</Typography>}
            />
          </Grid>
          {!showJournal ? null :
            <TaskHandover tiny={tiny} index={2} data={journal} update={updateJournal} language={language} classes={classes} />
          }
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container justify="center">
            {imageURL === null
              ?
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setSignModal(true)}
                  className={classes.btnMargins}
                >
                  {lang.SIGN_ACTION[language]}
                </Button>
                <input
                  onChange={() => 1 + 0}
                  tabIndex={-1}
                  autoComplete="off"
                  style={{ opacity: 0, height: 0, width: 0 }}
                  value={imageURL === null ? "" : imageURL}
                  required
                />
              </>
              :
              <Button disabled>
                {lang.SIGN_DONE[language]}
              </Button>
            }
          </Grid>
          <Grid container justify="center">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.btnMargins}
            >
              {lang.CLOSE[language]}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default CloseFields;
