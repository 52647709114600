import React from 'react';
import { Grow, Button, Box, Chip, Grid, MenuItem, TextField, FormControl } from '@material-ui/core';
import { InsertDriveFileRounded } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import * as lang from '../../../Constants/strings';
import { ro, enUS } from 'date-fns/esm/locale';

export default function ReportDateRangeSelect(props) {
    const { language, report, classes, index } = props;
    const [execList, setExecList] = React.useState([])

    React.useEffect(() => {
        fetch(`/api/executors_active`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setExecList(response);
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <React.Fragment>
            <Grid container spacing={4} className={classes.title}>
                <Grid item xs={12} md={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "en" ? enUS : ro}>
                        <DatePicker
                            variant="inline"
                            autoOk
                            fullWidth
                            format="dd/MM/yyyy"
                            label={lang.REPORT_START_DATE[language]}
                            maxDate={report.interval.end !== null ? report.interval.end : Date('2100-01-01')}
                            disableFuture
                            inputVariant="outlined"
                            value={report.interval.start}
                            onChange={(value) => report.setDate('start', value)}
                            animateYearScrolling
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "en" ? enUS : ro}>
                        <DatePicker
                            variant="inline"
                            autoOk
                            fullWidth
                            format="dd/MM/yyyy"
                            label={lang.REPORT_END_DATE[language]}
                            minDate={report.interval.start !== null ? report.interval.start : Date('1900-01-01')}
                            disableFuture
                            inputVariant="outlined"
                            value={report.interval.end}
                            onChange={(value) => report.setDate('end', value)}
                            animateYearScrolling
                        />
                    </MuiPickersUtilsProvider>
                </Grid >
                {index === 0 &&
                    <Grid item xs={12} md={6} >
                        <TextField
                            fullWidth
                            required
                            select
                            variant="outlined"
                            label={lang.EXEC_NAME[language]}
                            onChange={(e) => report.setDate('contractor', e.target.value)}
                            SelectProps={{
                                multiple: false,
                                value: report.contractor ? report.contractor : "",
                            }}
                        >
                            {execList && execList.length > 0 && execList.map((item, index) =>
                                <MenuItem key={index} value={item.OBJID}>{item.NAME}</MenuItem>
                            )}
                        </TextField>
                    </Grid >
                }
            </Grid>
        </React.Fragment >
    );
}