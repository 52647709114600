import React from 'react';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    max_height: {
        maxHeight: 400
    }
}))

export default function NestedMenu(props) {
    const classes = useStyles();
    const { open,
        parentAnchorEl,
        handelMenuClose,
        menuItems,
        onSelect
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSub, setOpenSub] = React.useState(menuItems.map(item => { return false }));

    const set = (index, value, e) => {
        let old = openSub;
        old[index] = value;
        setOpenSub(old);
        setAnchorEl(e !== undefined ? e.currentTarget : null);
    }

    const getOption = (index, value) => {
        if (value !== undefined) {
            onSelect(value);
            set(index, false);
        }
        handelMenuClose();
    }

    return (
        <React.Fragment>
            <Menu
                anchorEl={parentAnchorEl}
                open={open}
                onClose={() => handelMenuClose()}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {menuItems.map(item => {
                    return <div key={item.value + '_div'}>
                        <MenuItem
                            key={item.value}
                            value={item.text}
                            onClick={e =>
                                item.subMenuItems !== null ?
                                    set(menuItems.indexOf(item), true, e)
                                    :
                                    getOption(menuItems.indexOf(item), item.value)
                            }
                        >
                            {item.icon} {item.text}
                        </MenuItem>
                        {item.subMenuItems !== null ?
                            <Menu
                                className={classes.max_height}
                                key={item.value + '_SubMenu'}
                                open={openSub[menuItems.indexOf(item)] && Boolean(anchorEl)}
                                onClose={() => set(menuItems.indexOf(item), false)}
                                anchorEl={anchorEl}
                                keepMounted
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {item.subMenuItems.map(subItem => {
                                    return <MenuItem
                                        key={subItem.value}
                                        value={subItem.value}
                                        onClick={e => getOption(item.subMenuItems.indexOf(subItem), subItem.value)}
                                    >
                                        {subItem.text}
                                    </MenuItem>
                                })}
                            </Menu>
                            :
                            null
                        }
                    </div>
                })}
            </Menu>
        </React.Fragment>
    )
}
