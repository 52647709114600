import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../Constants/strings';


const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: theme.spacing(2),
    }
}));

export default function PasswordUpdateForm(props) {
    const classes = useStyles();
    return (
        <Grid container direction="column">
            <TextField
                fullWidth
                className={classes.textField}
                name="OLD_PASSWORD"
                variant="outlined"
                type="password"
                onChange={props.updatePasswordField}
                label={lang.OLD_PASSWORD[props.language]}
                value={props.password.OLD_PASSWORD.value}
                error={props.password.OLD_PASSWORD.error}
                helperText={props.password.OLD_PASSWORD.helperText}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
            <TextField
                fullWidth
                className={classes.textField}
                name="NEW_PASSWORD"
                variant="outlined"
                type="password"
                onChange={props.updatePasswordField}
                label={lang.NEW_PASSWORD[props.language]}
                value={props.password.NEW_PASSWORD.value}
                error={props.password.NEW_PASSWORD.error}
                helperText={props.password.NEW_PASSWORD.helperText}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
            <TextField
                fullWidth
                className={classes.textField}
                name="NEW_PASSWORD2"
                variant="outlined"
                type="password"
                onChange={props.updatePasswordField}
                label={lang.NEW_PASSWORD2[props.language]}
                value={props.password.NEW_PASSWORD2.value}
                error={props.password.NEW_PASSWORD2.error}
                helperText={props.password.NEW_PASSWORD2.helperText}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
        </Grid>
    );
}