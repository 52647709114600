import React, { useState, useEffect, useCallback } from "react";
import { useMediaQuery, useTheme, Grid, Divider, makeStyles } from '@material-ui/core';
import axios from "axios";

import { downloadFile } from '../../../../Functions/fileDownload';
import CommentList from "./CommentList";
import CustomModal from '../../../../Components/Modals/CustomModal';
import * as requestHeaders from "../../../../Constants/requestHeaders";
import UploadFiles from "../../../../Components/Modals/UploadFiles";

import RiscEvaluationActionButtons from "./RiscEvaluationActionButtons";
import RiscEvaluationDetails from "./RiscEvaluationDetails";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        width: "100%",
    },
    avatar: {
        width: 50,
        height: 50,
    },
    title: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    comment: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    commentAuto: {
        color: "gray",
        margin: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    timestamp: {
        marginTop: theme.spacing(1.5),
    },
    divider: {
        margin: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        marginTop: theme.spacing(1),
    },
    iconRight: {
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    title: {
        margin: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(1),
    },
    signature: {
        backgroundColor: "white",
        width: "100%",
        height: "50vh",
    },
    float: {
        margin: theme.spacing(1),
    },
}));

const RiscEvaluationActionModal = (props) => {
    const {
        language,
        open,
        close,
        riscEvaluationId,
        userId
    } = props;

    const classes = useStyles();

    const [riscEvaluationData, setRiscEvaluationData] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [commentFileUploadModal, setCommentFileUlpoadModal] = useState(false);
    const [sendingComment, setSendingComment] = useState(false);
    const [commentText, setCommentText] = React.useState("");
    const [showCommentBox, setShowCommentBox] = React.useState(false);

    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const getRiscEvaluationById = useCallback(async () => {
        try {
            const response = await axios.get(`api/evaluation/${riscEvaluationId}`, requestHeaders.getGetHeader());
            setRiscEvaluationData(response.data);
        } catch (error) {
            console.error(error);
        }
    }, [riscEvaluationId]);

    const createRiscEvaluationComment = async (note = '', file = null) => {
        setSendingComment(true);
        try {
            await axios.post('/api/risc_evaluation_comment', {
                CHG_FROM: null,
                CHG_TO: null,
                CHG_TYPE: 'COMMENT',
                CHG_USER: userId,
                FILE: file,
                NOTE: note,
                REASON: "RISC_EVALUATION",
                EVALUATIONS_TASK_OBJID: riscEvaluationId
            },
                requestHeaders.getPostHeader()
            );

            await getRiscEvaluationById();
            setSendingComment(false);
            setCommentText("");
            showCommentBox(false);
        } catch (error) {
            setSendingComment(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (riscEvaluationId) {
                try {
                    const response = await axios.get(`api/evaluation/${riscEvaluationId}`, requestHeaders.getGetHeader());
                    setRiscEvaluationData(response.data);
                } catch (error) {
                    console.error(error);
                }
            }
        })();
    }, [riscEvaluationId]);

    const executeAction = useCallback(async (newData) => {
        if (riscEvaluationData) {
            if (!riscEvaluationData?.hasBill && riscEvaluationData?.riscEvaluation?.STATUS === 'SIGN_PV') {
                alert("Pentru a finaliza evaluarea de risc trebuie sa ai adaugata factura!");
                return;
            }

            const data = {
                id: riscEvaluationData.riscEvaluation.ID,
                ...newData,
                userId
            };

            try {
                await axios.put('/api/evaluations', data, requestHeaders.getPostHeader())
            } catch (error) {
                console.error(error);
            }
            await getRiscEvaluationById();
        }
    }, [riscEvaluationData]);

    return (
        <CustomModal
            open={open}
            fullScreen
            title={riscEvaluationData ? `Evaluare de risc pe baza contractului numarul ${riscEvaluationData?.contractData?.CONTRACT_NO}` : 'Proiectare'}
            content={
                <Grid container spacing={2}>
                    <UploadFiles
                        open={commentFileUploadModal}
                        close={() => setCommentFileUlpoadModal(false)}
                        execute={(base64File) => setUploadedFile(base64File)}
                        language={props.language}
                    />
                    <Grid xs={12}>
                        <RiscEvaluationDetails data={riscEvaluationData} />
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={12}>
                        <RiscEvaluationActionButtons
                            userId={userId}
                            reFetch={getRiscEvaluationById}
                            riscEvaluationId={riscEvaluationData?.riscEvaluation?.ID}
                            doAction={executeAction}
                            riscEvaluationStatus={riscEvaluationData?.riscEvaluation?.STATUS}
                            language={language}
                            disabledActionButton={riscEvaluationData?.riscEvaluation?.OWNER_CURR !== userId}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={12}>
                        <CommentList
                            downloadFile={downloadFile}
                            task={riscEvaluationData}
                            data={riscEvaluationData && riscEvaluationData.history}
                            language={language}
                            tiny={tiny}
                            classes={classes}
                            isIntervention={false}
                            openFileUploadModal={() => setCommentFileUlpoadModal(true)}
                            uploadedFile={uploadedFile}
                            addComment={(note, uploadedFile) => createRiscEvaluationComment(note, uploadedFile)}
                            sendingComment={sendingComment}
                            commentText={commentText}
                            showCommentBox={showCommentBox}
                            handleCommentTextChange={value => setCommentText(value)}
                            handleCommentBoxShow={value => setShowCommentBox(value)}
                        />
                    </Grid>
                </Grid>
            }
            close={close}
            execute={() => close()}
            language={language}
            validate={null}
        />
    )
};

export default RiscEvaluationActionModal;