import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    Grid,
    makeStyles,
    Paper,
    Button,
    TextField,
    MenuItem,
    Grow,
    Chip,
    Input,
    Select
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import * as requestHeaders from '../../../Constants/requestHeaders';

import * as lang from '../../../Constants/strings';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 2, 0),
        width: 'auto'
    },
    fields: {
        margin: theme.spacing(1),
        borderRadius: '45px'
    },
    button: {
        margin: theme.spacing(2, 1, 1, 2),
        width: '98%',
    },
    checkbox: {
        margin: theme.spacing(1)
    },
    text: {
        margin: theme.spacing(2)
    },
    chip: {
        margin: 2,
    },
}));

export default function SortBar(props) {
    const classes = useStyles();
    const { language } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [contractorList, setContractorList] = useState([]);
    const [selectedContractor, setSelectedContractor] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);
    const [selectedContractType, setSelectedContractType] = useState([]);

    const applyFilters = () => {
        props.filter({ startDate, endDate, selectedContractor: selectedContractor.map(i => i.OBJID), contract_subType: selectedContractType.map(i => i.SUB_TYPE), contract_code: selectedContractType.map(i => i.CODE) });
    }

    const download = () => {
        props.onDownload({ startDate, endDate, selectedContractor: selectedContractor.map(i => i.OBJID), contract_subType: selectedContractType.map(i => i.SUB_TYPE), contract_code: selectedContractType.map(i => i.CODE) })
    }

    useEffect(() => {
        getContractTypes();
    }, []);

    useEffect(() => {
        if (selectedContractType !== null) getContractor();
    }, [selectedContractType]);

    const getContractor = async () => {
        try {
            const response = await axios.get('/api/executors_valid', {
                ...requestHeaders.getGetHeader()
            });
            setContractorList(response.data.data);
            console.table(response.data);
        } catch (e) {
            console.error(e);
        }
    };

    const getContractTypes = async () => {
        try {
            const response = await axios.get(`/api/contract_types`, requestHeaders.getGetHeader());
            setContractTypes(response.data);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Grow in={true} style={{ transitionDelay: true ? '100ms' : '0ms' }}>
            <Paper className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.fields}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.START_INTERVAL_DATE[language]}
                                value={startDate}
                                onChange={date => setStartDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.fields}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                id="date-picker-inline-1"
                                label={lang.LAST_INTERVAL_DATE[language]}
                                value={endDate}
                                onChange={date => setEndDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            required
                            select
                            className={classes.fields}
                            variant='outlined'
                            name='type'
                            label={lang.CONTRACT_TYPE[language]}
                            onChange={(e) => setSelectedContractType(e.target.value)}
                            value={selectedContractType}
                            fullWidth
                            SelectProps={{
                                multiple: true,
                                renderValue: selected => selected.map(value => lang[value.DESCRIPTION_TEXT][language]).join(', '),
                            }}
                        >
                            {
                                contractTypes.filter(e => e.TYPE === 2 && e.SUB_TYPE === 2).map(type => <MenuItem key={type.OBJID} value={type}>{lang[type.DESCRIPTION_TEXT][language]}</MenuItem>)
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            required
                            select
                            disabled={selectedContractType.length === 0}
                            className={classes.fields}
                            variant='outlined'
                            name='user'
                            label={lang.CONTRACTOR[language]}
                            onChange={(e) => setSelectedContractor(e.target.value)}
                            value={selectedContractor}
                            fullWidth
                            SelectProps={{
                                multiple: true,
                                renderValue: selected => selected.map(value => value.NAME).join(', '),
                            }}
                        >
                            {
                                contractorList.map(contractor => <MenuItem key={contractor.OBJID} value={contractor}>{contractor.NAME}</MenuItem>)
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={1}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={selectedContractor === null}
                            className={classes.button}
                            onClick={() => applyFilters()}
                        >
                            {lang.FILTER[language]}
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={selectedContractor === null}
                            className={classes.button}
                            onClick={download}
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grow>
    )
}
