/**
 * [AUTHOR]:      Tibulca, PAUL
 * [DETAILS]:     The principal view responsbile of authentication
 * [TIMESTAMP]:   2019-11-02, 02:56 PM
 */

import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as lang from '../../Constants/strings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';
import { LinearProgress } from '@material-ui/core';
import LanguageButton from '../../Components/Misc/LanguageButton';
import DarkModeButton from '../../Components/Misc/DarkModeButton';

function Copyright() {
    return (
        <React.Fragment>
            <Typography variant="body2" color="textPrimary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.facebook.com/pages/category/Security-Guard-Service/RPG-Suceava-236795097260877/">
                    RPG
                </Link>
                {' '}{new Date().getFullYear()}{'.'}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Powered by '}{' '}
                <Link color="inherit" href="https://raisissoftware.com/">
                    Raisis Software
                </Link>
            </Typography>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    lightImage: {
        backgroundImage: `url(/api/image?image=landing-page-light.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    darkImage: {
        backgroundImage: `url(/api/image?image=landing-page-dark.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cornerButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function SignInSide(props) {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={props.theme === "dark" ? classes.darkImage : classes.lightImage} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.cornerButton}>
                    <LanguageButton
                        language={props.language}
                        onLanguageChange={props.onLanguageChange}
                        size="small"
                    />
                    <DarkModeButton
                        theme={props.theme}
                        onDarkModeToggle={props.onDarkModeToggle}
                        size="small"
                    />
                </div>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {lang.SIGN_IN[props.language]}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={lang.USERNAME[props.language]}
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={props.username}
                            onChange={props.updateField}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={lang.PASSWORD[props.language]}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={props.password}
                            onChange={props.updateField}
                            onKeyDown={(e) => e.key === 'Enter' ? props.submit() : null}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label={lang.REMEMBER_ME[props.language]}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={props.submit}
                        >
                            {lang.SIGN_IN[props.language]}
                        </Button>
                        {props.isAuthenticating && <LinearProgress color="secondary" />}
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

class SignInView extends Component {
    state = {
        username: "",
        password: "",
    }
    componentDidMount() {
        this.props.actions.checkSession('/home');
        document.title = "RPG - " + lang["SIGN_IN"][this.props.language];
    }
    render() {
        return (
            <React.Fragment>
                <SignInSide
                    user={this.props.user}
                    username={this.state.username}
                    password={this.state.password}
                    updateField={
                        (e) => this.setState({ [e.target.name]: e.target.value })
                    }
                    submit={
                        () => this.props.actions.SignInUser(this.state.username, this.state.password, '/home', false)
                    }
                    onDarkModeToggle={this.props.actions.onDarkModeToggle}
                    theme={this.props.theme}
                    isAuthenticating={this.props.isAuthenticating}
                    language={this.props.language}
                    onLanguageChange={this.props.actions.onLanguageChange}
                />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.auth.username,
        isAuthenticating: state.auth.isAuthenticating,
        theme: state.config.theme,
        language: state.config.language,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SignInView);