import React from "react";
import moment from "moment";
import CustomModal from "../../../Components/Modals/CustomModal";
import {
    Grid,
    makeStyles
} from "@material-ui/core";
import * as lang from "../../../Constants/strings";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


const SelectDateModal = (props) => {
    const {
        open,
        onClose,
        language,
        callback
    } = props;

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    return(
        <CustomModal
            open={open}
            title={lang.SELECT_INTERVAL_DATE[language]}
            close={onClose}
            content={
                <Grid container spacing={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            required
                            inputVariant="outlined"
                            fullWidth
                            label={lang.START_INTERVAL_DATE[language]}
                            format="dd-MM-yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ margin: 10 }}
                            value={startDate}
                            onChange={setStartDate}
                         />
                        <KeyboardDatePicker
                            required
                            inputVariant="outlined"
                            fullWidth
                            label={lang.LAST_INTERVAL_DATE[language]}
                            format="dd-MM-yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ margin: 10 }}
                            value={endDate}
                            onChange={setEndDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            }
            execute={
                () => callback(startDate, endDate)
            }
            validate
            language={language}
        />

    )
};

export default SelectDateModal;
