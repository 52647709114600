import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import * as lang from '../../Constants/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundImage: `url(/api/image?image=modalHeader-01.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
    },
    button: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-end',
        right: theme.spacing(1),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CustomModal extends Component {
    render() {
        return (
            <CustomModalSetup {...this.props} />
        )
    }
}

function CustomModalSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [showContent2, setShow] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);


    React.useEffect(() => {
        if (props.open) {
            setIsSubmitting(false);
        }
    }, [props.open])

    function execute() {
        if (isSubmitting === false) {
            setIsSubmitting(true);
            props.execute();
        }
    }
    return (
        <Dialog
            fullWidth={props.fullWidth}
            fullScreen={props.fullScreen === true ? true : fullScreen}
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.close}
            disableEscapeKeyDown={props.nonDismissible}
            disableBackdropClick={props.nonDismissible}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.execute();
                }}
                style={{
                    height: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'space-between',
                }}
            >
                {typeof props.title === "string" && <DialogTitle className={classes.paper}>{props.title}</DialogTitle>}
                <DialogContent>
                    {typeof props.content === 'string' ? <DialogContentText>{props.content}</DialogContentText> : props.content}
                    {props.content2 && showContent2 && <React.Fragment>{props.content2}</React.Fragment>}
                    {props.aux && <React.Fragment>{props.aux}</React.Fragment>}
                </DialogContent>
                <DialogActions
                    className={classes.button}
                >
                    {props.twoStep
                        ?
                        showContent2 === false
                            ?
                            <React.Fragment>
                                <Button
                                    color="secondary"
                                    onClick={props.close}
                                >
                                    {lang.FORCE_LOGOUT[props.language]}
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => setShow(true)}
                                >
                                    {lang.UPDATE[props.language]}
                                </Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button
                                    color="secondary"
                                    onClick={() => setShow(false)}
                                >
                                    {lang.BACK[props.language]}
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={!props.validate}
                                    onClick={execute}
                                >
                                    {lang.FINISH[props.language]}
                                </Button>
                            </React.Fragment>
                        :
                        props.onlyBack
                        &&
                        <React.Fragment>
                            <Button
                                color="secondary"
                                onClick={props.close}
                            >
                                {lang.BACK[props.language]}
                            </Button>
                        </React.Fragment>
                    }
                    {!props.noButtons &&
                        <React.Fragment>
                            <Button
                                color="secondary"
                                onClick={props.close}
                            >
                                {lang.BACK[props.language]}
                            </Button>
                            {props.formValidate ?
                                <Button
                                    color="primary"
                                    type="submit"
                                >
                                    {lang.FINISH[props.language]}
                                </Button>
                                :
                                <Button
                                    color="primary"
                                    onClick={execute}
                                    disabled={!(props.validate === true)}
                                >
                                    {lang.FINISH[props.language]}
                                </Button>
                            }
                        </React.Fragment>
                    }
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CustomModal;