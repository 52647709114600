import React from 'react';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { patrol_header } from '../../../Components/Misc/Tables/TableHeaders';
import * as lang from '../../../Constants/strings';
import { getStatusIcon } from '../../../Functions/getIcon';
import { startInterval } from './helpers';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
        width: 'auto'
    },
}));

export default function PatrolTable(props) {
    const theme = useTheme();
    const classes = useStyles();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    const [patrol, setPatrol] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);



    React.useEffect(() => {
        if (!props.firstLoad) {
            fetchData();
        }
    }, [props.selectedArea, props.firstLoad]);

    React.useEffect(() => {
        startInterval(fetchData);
    }, [props.selectedArea]);

    React.useEffect(() => {
        if (!props.interModalStatus) {
            fetchData();
        }
    }, [props.interModalStatus])

    const fetchData = () => {
        let url = props.selectedArea ? `/api/get_active_inter_list?type=PATROL&area_code=${props.selectedArea}` : `/api/get_active_inter_list?type=PATROL`;
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.error === 1) {
                    setPatrol([]);
                } else {
                    let data = getStatusIcon(response.data);
                    setPatrol(data);
                }
            })
    };

    return (
        <React.Fragment>
            <Grid container component="main" className={classes.margin} spacing={tiny ? 1 : 4}>
                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.ACTIVE_PATROL[props.language]}
                        language={props.language}
                        data={patrol}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={setRowsPerPage}
                        header={patrol_header(props.language)}
                        hideAddBtn
                        openRowModal={props.openRowModal}
                        clickLabel="OBJID"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
