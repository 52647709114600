import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actions";
import * as lang from "../../Constants/strings";
import {
	CssBaseline,
	useTheme,
	useMediaQuery,
	Grid,
	makeStyles,
	Button,
	TextField
} from "@material-ui/core";
import axios from "axios";

import Can from "../../Components/Misc/Can";

import CustomModal from "../../Components/Modals/CustomModal";
import SiteMain from "./SiteSingleViewComponents/SiteMain";
import SiteNewForm from "../Customer/CustomerSingleViewComponents/SiteNewForm";
import SiteCust from "./SiteSingleViewComponents/SiteCust";
import SiteButtons from "./SiteSingleViewComponents/SiteButtons";
import ActiveContracts from "./SiteSingleViewComponents/Modals/ActiveContracts";
import AddContractModal from "./SiteSingleViewComponents/Modals/AddContractModal";
import SiteTabs from "./SiteSingleViewComponents/SiteTabs";
import AddTaskModal from "./SiteSingleViewComponents/Modals/AddTaskModal";
import TaskTable from "./SiteSingleViewComponents/TaskTable";
import TaskModal from "./SiteSingleViewComponents/Modals/TaskModal";
import ContractTable from "./SiteSingleViewComponents/ContractTable";
import SiteContractEdit from "./SiteSingleViewComponents/Modals/SingleContractEdit";
import EquipmentModal from "./SiteSingleViewComponents/Modals/EquipmentModal";
import SystemInfoModal from "./SiteSingleViewComponents/Modals/SystemInfoModal";
import SitePhoneWarning from "./SiteSingleViewComponents/SitePhoneWarning";
import AddInterventionModal from "./SiteSingleViewComponents/Modals/AddInterventionModal";
import InterventionTable from "./SiteSingleViewComponents/InterventionTable";
import DeprecatedInterventionModal from "./SiteSingleViewComponents/Modals/DeprecatedInterventionModal";
import SiteAreaNom from "../Nomenclature/SiteArea/SiteAreaNom";
import CentralInfoModal from "./SiteSingleViewComponents/Modals/CentralInfoModal";
import AddPhoneWarning from './SiteSingleViewComponents/Modals/AddPhoneWarning';
import SiteTaskBillHistory from './SiteSingleViewComponents/SiteTaskBillHistory';
import AddMaintenaceModal from '../../MainView/Maintenance/components/AddMaintenaceModal';
import MaintenanceTable from "./SiteSingleViewComponents/MaintenanceSiteTabel";
import InfoPapers from "./SiteSingleViewComponents/Modals/InfoPapers";
import AddProjectsTaskModal from "./SiteSingleViewComponents/Projects/AddProjectTaskModal";
import AddRiscEvaluationsModal from "./SiteSingleViewComponents/RiscEvaluation/AddWarrningModal";
import InstallationTable from "./SiteSingleViewComponents/InstallationTable";

import { contractSorting } from "../../Functions/contractSorting";
import { downloadFile } from "../../Functions/fileDownload";
import { checkPermissions } from "../../Functions/permissionHide";
import { contract, task, intervention } from "../../Constants/forms";
import { siteAddFormat } from "../../Functions/objectManipulation";
import { extractQueryFrontend } from "../../Functions/stringManipulation";
import { formatDateFromDb } from "../../Functions/dateFunctions";
import * as maintenanceHelpers from '../../MainView/Maintenance/helperFunctions';
import ProjectTable from "./SiteSingleViewComponents/Projects/ProjectTable";
import RiscEvaluationTable from "./SiteSingleViewComponents/RiscEvaluation/RiscEvaluationTable";
import AddInstallationModal from "./SiteSingleViewComponents/Modals/AddInstalation";
import AddSiteImage from "./SiteSingleViewComponents/Modals/AddSiteImage";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(10),
		width: "auto",
	},
	paper: {
		padding: theme.spacing(2),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}));

function SiteSingleSetup(props) {
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const {
		data,
		language,
		site,
		cust,
		contract,
		tabs,
		tasks,
		intervention,
		userPermissions,
		isAuthenticated,
		interventions,
		setProjectAddModal,
		setEvaluationAddModal,
		setInstallationAddModal,
		setAddSiteImageModal
	} = props;

	const [phoneWarning, setPhoneWarning] = useState(false);
	const [id, setId] = useState(null);

	useEffect(() => {
		if (data)
			setId(data.OBJID);
	}, [data]);

	return (
		<Grid
			container
			component="main"
			className={classes.root}
			spacing={tiny ? 1 : 4}
		>
			<Grid
				item
				xs={12}
				container
				direction="row"
				alignItems="stretch"
				spacing={tiny ? 1 : 4}
				style={{ paddingRight: "0px" }}
			>
				<SiteMain
					data={data}
					language={language}
					site={site}
					tiny={tiny}
					openSettingsModal={site.openSettingsModal}
					userPermissions={userPermissions}
					isAuthenticated={isAuthenticated}
					openModalEquipment={() => props.openModalEquipment()}
					openCentralInfo={() => props.centralInfoOpen()}
					openInfoPapers={props.openInfoPapers}
					setAddSiteImageModal={setAddSiteImageModal}
				/>
				<SiteCust
					data={cust.data.customer}
					language={language}
					cust={cust}
					tiny={tiny}
				/>
			</Grid>
			<Grid item xs={12} container direction="row" spacing={0}>
				<SiteButtons
					language={language}
					tiny={tiny}
					site={site}
					contract={contract}
					task={tasks}
					siteId={data.OBJID}
					intervention={intervention}
					userPermissions={userPermissions}
					isAuthenticated={isAuthenticated}
					addSitePhoneWarning={() => setPhoneWarning(true)}
					setProjectAddModal={setProjectAddModal}
					setEvaluationAddModal={setEvaluationAddModal}
					setInstallationAddModal={setInstallationAddModal}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				direction="row"
				alignItems="stretch"
				spacing={tiny ? 1 : 4}
				style={{ paddingRight: "0px" }}
			>
				<SiteTabs
					tabs={tabs}
					tiny={tiny}
					language={language}
					userPermissions={userPermissions}
					isAuthenticated={isAuthenticated}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				direction="row"
				alignItems="stretch"
				spacing={tiny ? 1 : 4}
				style={{ paddingRight: "0px" }}
			>
				{tabs.tabIndex === 0 && (
					<ContractTable
						hasPermissionToAdd={contract.hasPermissionToAdd}
						loading={contract.loading}
						data={contractSorting(userPermissions, contract.data)}
						language={language}
						tiny={tiny}
						openAddModal={contract.openAddContractModal}
						openRowModal={(id) => props.openModalContracts(id)}
					/>
				)}

				{tabs.tabIndex === 1 && (
					<MaintenanceTable
						siteId={id}
						language={language}
						userId={props.userAuth.id}
						user={props.userAuth}
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
						onNotificationOpen={(data, message) => props.onNotificationOpen(data, message)}
					/>
				)}
				{tabs.tabIndex === 2 && (
					<TaskTable
						hasPermissionToAdd={tasks.hasPermissionToAdd}
						loading={tasks.loading}
						data={props.taskList}
						language={language}
						tiny={tiny}
						openAddModal={tasks.openAddTaskModal}
						openRowModal={tasks.openTaskSettings}
					/>
				)}
				{tabs.tabIndex === 3 && (
					<InterventionTable
						hasPermissionToViewStatus={interventions.hasPermissionToViewStatus}
						hasPermissionToAdd={interventions.hasPermissionToAdd}
						loading={interventions.loading}
						data={interventions.data}
						language={language}
						tiny={tiny}
						openAddModal={interventions.openAddInterventionModal}
						openRowModal={interventions.openInterSettings}
					/>
				)}
				{tabs.tabIndex === 5 && (
					<SiteAreaNom
						userPermissions={userPermissions}
						isAuthenticated={isAuthenticated}
						siteId={id}
						userAuth={props.userAuth}
						language={language}
						onNotificationOpen={(type, message) =>
							props.onNotificationOpen(type, message)
						}
					/>
				)}
				{tabs.tabIndex === 4 && (
					<SitePhoneWarning
						userId={props.userAuth.id}
						onNotificationOpen={(type, message) =>
							props.onNotificationOpen(type, message)
						}
						siteId={id}
						language={language}
						tiny={tiny}
					/>
				)}
				{tabs.tabIndex === 6 && (
					<SiteTaskBillHistory
						userPermissions={userPermissions}
						userId={props.userAuth.id}
						onNotificationOpen={(type, message) =>
							props.onNotificationOpen(type, message)
						}
						siteId={id}
						language={language}
						tiny={tiny}
					/>
				)}
				{
					tabs.tabIndex === 7 && (
						<ProjectTable
							language={language}
							siteId={id}
							userId={props.userAuth.id}
							userPermissions={userPermissions}
						/>
					)
				}
				{
					tabs.tabIndex === 8 && (
						<RiscEvaluationTable
							language={language}
							siteId={id}
							userId={props.userAuth.id}
						/>
					)
				}
				{
					tabs.tabIndex === 9 && (
						<InstallationTable
							language={language}
							siteId={id}
							tiny={tiny}
						/>
					)
				}
				<AddPhoneWarning
					language={props.language}
					userId={props.userAuth.id}
					siteId={id}
					onNotificationOpen={(type, message) =>
						props.onNotificationOpen(type, message)
					}
					addModal={phoneWarning}
					close={() => setPhoneWarning(false)}
				/>
			</Grid>
		</Grid>
	);
}

function PlaceholderSkeleton() {
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Grid
			container
			component="main"
			className={classes.root}
			spacing={tiny ? 1 : 4}
		>
			<CssBaseline />
		</Grid>
	);
}

class SiteSingleView extends Component {
	state = {
		editModal: false,
		siteData: {},
		contractData: {},
		edited: false,
		settingsModal: false,
		siteIndex: null,
		activeContractsModal: false,
		addContractModal: false,
		taskModal: false,
		selectedTask: null,
		addTaskModal: false,
		addInterModal: false,
		addData: {},
		trainees: [
			{
				NAME: "",
				SIGN: "",
			},
		],
		project: {
			NAME: "",
			NUMBER: "",
			SIGN: "",
		},
		showProject: false,
		journal: {
			NAME: "",
			NUMBER: "",
			SIGN: "",
		},
		journalData: {
			NO: "",
			DATE: new Date(),
			POS: "",
		},
		opt: [false, false, false, false],
		showJournal: false,
		tabIndex: 0,
		contractTablelModal: false,
		editContractModal: false,
		contractID: null,
		equipmentModalStatus: false,
		removeAccountModal: false,
		error: false,
		openSystemLogingInfoModal: false,
		interModal: false,
		centralInfoModal: false,
		addMaintenaceData: {},
		addMaintenaceModalStatus: false,
		deleteContractModal: false,
		key: "",
		infoPapersModal: false,
		addProjectModal: false,
		evaluationAddModal: false,
		installationAddModal: false,
		addSiteImageModal: false
	};

	componentDidUpdate = (nextProps) => {
		if (this.props.location.search !== nextProps.location.search) {
			const query = extractQueryFrontend(this.props.location.search);
			document.title = `RPG - ${lang.SITE[this.props.language]} #${query.id}`;
			this.props.actions.onSiteDataRecieve({});
			this.props.actions.getSiteData(query.id);
		}
		return this.props.location.search !== nextProps.location.search;
	};

	componentDidMount = () => {
		this.props.actions.checkSession(false);
		const query = extractQueryFrontend(this.props.location.search);
		document.title = `RPG - ${lang.SITE[this.props.language]} #${query.id}`;
		this.props.actions.getInterventionList(query.id, this.props.language);
		this.props.actions.getContractList(query.id, this.props.language);
	};

	componentWillMount = () => {
		const query = extractQueryFrontend(this.props.location.search);
		this.props.actions.getSiteData(query.id);
		this.props.actions.getTaskList(query.id, this.props.language);
	};

	updateProject = (field, value) => {
		if (field === "SHOW")
			this.setState({ showProject: !this.state.showProject });
		else {
			let placeholder = this.state.project;
			placeholder[field] = value;
			this.setState({ project: placeholder });
		}
	};

	updateJournal = (field, value) => {
		if (field === "SHOW")
			this.setState({ showJournal: !this.state.showJournal });
		else {
			let placeholder = this.state.journal;
			placeholder[field] = value;
			this.setState({ journal: placeholder });
		}
	};

	updateJournalData = (field, value) => {
		let placeholder = this.state.journalData;
		placeholder[field] = value;
		this.setState({ journalData: placeholder });
	};

	resetJournalData = () => {
		this.setState({
			journalData: {
				NO: "",
				DATE: new Date(),
				POS: "",
			},
		});
	};

	resetProject = () => {
		this.setState({
			project: { NAME: "", NUMBER: "", SIGN: "" },
			showProject: false,
		});
	};

	resetJournal = () => {
		this.setState({
			journal: { NAME: "", NUMBER: "", SIGN: "" },
			showJournal: false,
		});
	};

	addRemoveTrainee = (index) => {
		let placeholder = this.state.trainees;

		if (index === false) {
			placeholder.push({ NAME: "", SIGN: "" });
		} else {
			placeholder.splice(index, 1);
		}

		this.setState({ trainees: placeholder });
	};

	updateTrainee = (index, field, value) => {
		let placeholder = this.state.trainees;
		placeholder[index][field] = value;
		this.setState({ trainees: placeholder });
	};

	resetTrainees = () => {
		this.setState({ trainees: [{ NAME: "", SIGN: "" }] });
	};

	updateOpt = (index) => {
		let placeholder = this.state.opt;
		placeholder[index] = !placeholder[index];
		this.setState({ opt: placeholder });
	};

	resetOpt = () => {
		this.setState({ opt: [false, false, false, false] });
	};

	addTaskToSite(id) {
		axios.get(`/api/get_site_active_task_count?id=${id}`, {
			method: "GET",
			headers: {
				authorization: localStorage.token,
			},
		})
			.then((response) => {
				switch (response.status) {
					case 200:
						if (response.data.data === 0) {
							this.props.actions.addNewTask(
								this.props.userAuth.id,
								{
									...this.state.addData,
									SITE_OBJID: this.props.siteData.OBJID,
								},
								this.props.language
							);
						} else {
							this.props.actions.onNotificationOpen(
								"warning",
								lang.ACTIVE_TASK_EXIST_ON_SITE,
								6000
							);
							this.props.actions.getTaskList(
								id,
								this.props.language
							);
						}
						break;
					case 304:
						if (response.data.data === 0) {
							this.props.actions.addNewTask(
								this.props.userAuth.id,
								{
									...this.state.addData,
									SITE_OBJID: this.props.siteData.OBJID,
								},
								this.props.language
							);
						} else {
							this.props.actions.onNotificationOpen(
								"warning",
								lang.ACTIVE_TASK_EXIST_ON_SITE,
								6000
							);
							this.props.actions.getTaskList(id, this.props.language);
						}
						break;
					default:
						console.log("No result");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	deleteContract = async () => {
		try {
			await axios.delete('/api/contract', {
				headers: {
					authorization: `Beare ${this.state.key}`
				},
				params: {
					id: this.state.contractID
				}
			});
			window.location.reload();
		} catch (e) {
			console.error(e);
		};
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<CentralInfoModal
					open={this.state.centralInfoModal}
					close={() => this.setState({ centralInfoModal: false })}
					data={this.props.siteData}
					language={this.props.language}
				/>
				<AddSiteImage
					open={this.state.addSiteImageModal}
					close={() => {
						this.setState({addSiteImageModal: false});
						window.location.reload();
					}
				}
					siteId={this.props.siteData.OBJID ?? null}
				/>
				<Can neededPermissions={{ parent: "SUPER", name: "SUPER_DELETE" }}>
					<CustomModal
						open={this.state.deleteContractModal}
						close={() => this.setState({ deleteContractModal: false })}
						content={
							<Grid alignItems="center" container spacing={2}>
								<TextField
									value={this.state.key}
									fullWidth
									variant="outlined"
									onChange={e => this.setState({ key: e.target.value })}
									placeholder="Key-ul de ștergere"
								/>
							</Grid>
						}
						execute={this.deleteContract}
						validate={this.state.key.length > 0}
						language={this.props.language}
					/>
				</Can>
				<AddProjectsTaskModal
					language={this.props.language}
					open={this.state.addProjectModal}
					siteId={this.props.siteData.OBJID}
					userId={this.props.userAuth.id}
					close={() => this.setState({ addProjectModal: false })}
					sendNotification={(status) => {
						if (status === 201) {
							this.props.actions.onNotificationOpen(
								"success",
								lang.DATA_UPDATE_SUCCESS,
								6000
							);
						} else {
							this.props.actions.onNotificationOpen(
								"warning",
								lang.ACTIVE_PROJECT_TASK_EXIST_ON_CONTRACT,
								6000
							);
						}
					}}
				/>
				<AddRiscEvaluationsModal
					language={this.props.language}
					open={this.state.evaluationAddModal}
					siteId={this.props.siteData.OBJID}
					userId={this.props.userAuth.id}
					close={() => this.setState({ evaluationAddModal: false })}
					sendNotification={(status) => {
						if (status === 201) {
							this.props.actions.onNotificationOpen(
								"success",
								lang.DATA_UPDATE_SUCCESS,
								6000
							);
						} else {
							this.props.actions.onNotificationOpen(
								"warning",
								lang.ACTIVE_EVALUATION_TASK_EXIST_ON_CONTRACT,
								6000
							);
						}
					}}
				/>
				<AddInstallationModal
					language={this.props.language}
					open={this.state.installationAddModal}
					siteId={this.props.siteData.OBJID}
					userId={this.props.userAuth.id}
					close={() => this.setState({ installationAddModal: false })}
					sendNotification={(status) => {
						if (status === 201) {
							this.props.actions.onNotificationOpen(
								"success",
								lang.DATA_UPDATE_SUCCESS,
								6000
							);
						} else {
							this.props.actions.onNotificationOpen(
								"warning",
								lang.ACTIVE_EVALUATION_TASK_EXIST_ON_CONTRACT,
								6000
							);
						}
					}}
				/>
				<ActiveContracts
					site={{
						data: this.props.siteData,
					}}
					userPermissions={this.props.permissions}
					siteId={this.props.siteData.OBJID}
					language={this.props.language}
					open={this.state.activeContractsModal}
					close={() => this.setState({ activeContractsModal: false })}
					terminateContract={(id, date, comment) => {
						this.props.actions.terminateContract(
							this.props.userAuth.id,
							id,
							date,
							comment
						);
					}}
					updateContract={(id) => {
						this.props.actions.addUpdatedContract(
							this.props.userAuth.id,
							id,
							{
								...this.state.addData,
								SITE_OBJID: this.props.siteData.OBJID,
								SALE_CONTACT: this.props.userAuth.id,
							}
						);
					}}
					setAddData={(data) =>
						this.setState({
							addData: {
								...data,
								TYPE: data.TYPE_ID,
								SUB_TYPE: data.SUB_TYPE_ID,
								CONTRACT_ASSIGNEE: data.ASSIGNEE_OBJID,
							},
						})
					}
					updateField={(e, length) =>
						this.setState({
							addData: {
								...this.state.addData,
								[e.target
									.name]: e.target.value
										.toString()
										.slice(0, length),
							},
						})
					}
					updateFieldRaw={(field, value) => {
						let placeholder = this.state.addData;
						placeholder[field] = value;
						this.setState({ addData: placeholder });
					}}
					addData={this.state.addData}
				/>
				<AddContractModal
					addData={this.state.contractData}
					language={this.props.language}
					open={this.state.addContractModal}
					close={() => this.setState({ addContractModal: false })}
					// close={() => alert('Fuck')}
					updateField={(e, length) =>
						this.setState({
							contractData: {
								...this.state.contractData,
								[e.target
									.name]: e.target.value
										.toString()
										.slice(0, length),
							},
						})
					}
					updateFieldRaw={(field, value) => {
						let placeholder = this.state.contractData;
						placeholder[field] = value;
						this.setState({ contractData: placeholder });
					}}
					addContract={() => {
						this.props.actions.addCustomerContract(
							this.props.userAuth.id,
							{
								...this.state.contractData,
								SITE_OBJID: this.props.siteData.OBJID,
								SALE_CONTACT: this.props.userAuth.id,
							},
							this.props.language
						)
					}}
				/>
				<AddTaskModal
					baseData={{
						userId: this.props.userAuth.id,
						siteId: this.props.siteData.OBJID,
					}}
					addData={this.state.addData}
					language={this.props.language}
					open={this.state.addTaskModal}
					close={() => this.setState({ addTaskModal: false })}
					updateField={(e, length) =>
						this.setState({
							addData: {
								...this.state.addData,
								[e.target
									.name]: e.target.value
										.toString()
										.slice(0, length),
							},
						})
					}
					updateFieldRaw={(field, value) => {
						let placeholder = this.state.addData;
						placeholder[field] = value;
						this.setState({ addData: placeholder });
					}}
					validate={
						this.state.addData.TYPE &&
						this.state.addData.TYPE !== "" &&
						this.state.addData.REASON &&
						this.state.addData.REASON.length > 0 &&
						this.state.addData.CONTRACT_OBJID &&
						this.state.addData.CONTRACT_OBJID > 0
					}
					executeFunction={() => {
						this.addTaskToSite(this.props.siteData.OBJID);
					}}
				/>
				<AddMaintenaceModal
					baseData={{
						userId: this.props.userAuth.id,
						siteId: this.props.siteData.OBJID,
						areaId: this.props.siteData.AREA_CODE,
					}}
					addData={this.state.addMaintenaceData}
					language={this.props.language}
					open={this.state.addMaintenaceModalStatus}
					close={() => this.setState({ addMaintenaceModalStatus: false })}
					updateField={(e, length) =>
						this.setState({
							addMaintenaceData: {
								...this.state.addMaintenaceData,
								[e.target
									.name]: e.target.value
										.toString()
										.slice(0, length),
							},
						})
					}
					updateFieldRaw={(field, value) => {
						let placeholder = this.state.addMaintenaceData;
						placeholder[field] = value;
						this.setState({ addMaintenaceData: placeholder });
					}}
					validate={
						this.state.addMaintenaceData.CONTRACT_OBJID &&
						this.state.addMaintenaceData.CONTRACT_OBJID !== null
					}
					executeFunction={async () => {
						try {
							await maintenanceHelpers.createMaintenance({
								userId: this.props.userAuth.id,
								data: {
									...this.state.addMaintenaceData,
									SITE_OBJID: this.props.siteData.OBJID,
								}
							});

							this.setState({ addMaintenaceModalStatus: false })
							this.props.actions.onNotificationOpen(
								"success",
								"DATA_UPDATE_SUCCESS",
								6000
							);
						} catch (error) {
							this.props.actions.onNotificationOpen(
								"error",
								"ERROR_OCCURED",
								6000
							);
						}
					}}
				/>
				<AddInterventionModal
					baseData={{
						userId: this.props.userAuth.id,
						siteId: this.props.siteData.OBJID,
						areaId: this.props.siteData.AREA_CODE,
					}}
					addData={this.state.addData}
					language={this.props.language}
					open={this.state.addInterModal}
					close={() => this.setState({ addInterModal: false })}
					updateField={(e, length) =>
						this.setState({
							addData: {
								...this.state.addData,
								[e.target
									.name]: e.target.value
										.toString()
										.slice(0, length),
							},
						})
					}
					updateFieldRaw={(field, value) => {
						let placeholder = this.state.addData;
						placeholder[field] = value;
						this.setState({ addData: placeholder });
					}}
					validate={
						(this.state.addData.TYPE === 1 &&
							this.state.addData.CONTRACT_OBJID &&
							this.state.addData.CONTRACT_OBJID !== null) ||
						(this.state.addData.TYPE === 2 &&
							this.state.addData.SUBTYPE !== null &&
							this.state.addData.CONTRACT_OBJID &&
							this.state.addData.CONTRACT_OBJID !== null)
					}
					executeFunction={() =>
						this.props.actions.addNewIntervention(
							this.props.userAuth.id,
							{
								...this.state.addData,
								SITE_OBJID: this.props.siteData.OBJID,
							},
							this.props.language
						)
					}
				/>
				<TaskModal
					isUploadingFile={this.props.isUploadingFile}
					siteData={this.props.siteData}
					language={this.props.language}
					user={this.props.userAuth}
					open={this.state.taskModal}
					close={() => this.setState({ taskModal: false })}
					taskId={this.state.selectedTask}
					userPermissions={this.props.permissions}
					isAuthenticated={this.props.isAuthenticated}
					onNotificationOpen={(type, message) =>
						this.props.actions.onNotificationOpen(
							type,
							message,
							6000
						)
					}
					addCustomerContract={(data) =>
						this.props.actions.addCustomerContract(
							this.props.userAuth.id,
							{
								...data,
								SITE_OBJID: this.props.siteData.OBJID,
								SALE_CONTACT: this.props.userAuth.id,
							},
							this.props.language
						)
					}
					cust={{
						data: this.props.customerData,
						loading: this.props.isFetchingCustomerData,
						initialLoad: () =>
							this.props.actions.getCustomerData(
								this.props.siteData.CONTACT_OBJID
							),
					}}
					task={{
						opt: this.state.opt,
						updateOpt: this.updateOpt,
						resetOpt: this.resetOpt,
						project: this.state.project,
						journal: this.state.journal,
						showProject: this.state.showProject,
						showJournal: this.state.showJournal,
						updateProject: this.updateProject,
						updateJournal: this.updateJournal,
						journalData: this.state.journalData,
						updateJournalData: this.updateJournalData,
						resetJournalData: this.resetJournalData,
						resetProject: this.resetProject,
						resetJournal: this.resetJournal,
						trainees: this.state.trainees,
						updateTrainee: this.updateTrainee,
						addRemoveTrainee: this.addRemoveTrainee,
						resetTrainees: this.resetTrainees,
						userId: this.props.userAuth.id,
						data: this.props.taskData,
						loading: this.props.isFetchingTaskData,
						downloadFile: downloadFile,
						fetchData: () =>
							this.props.actions.getTaskData(
								this.state.selectedTask
							),
						newTaskAction: (
							changeType,
							changeFrom,
							changeTo,
							note,
							siteContact,
							signature,
							serviceNo,
							warranty,
							opt,
							trainees,
							project,
							journal,
							dispatcher,
							type,
							products,
							teamSize,
							deadLine,
							pvImage
						) =>
							this.props.actions.newTaskAction(
								this.props.userAuth.id,
								{
									TASK_OBJID: this.props.taskData.task.OBJID,
									REASON: this.props.taskData.task.REASON,
									CHG_USER: this.props.userAuth.id,
									CHG_TYPE: changeType,
									CHG_FROM: changeFrom,
									CHG_TO:
										changeType !== 2
											? changeTo
											: this.props.userAuth.id,
									NOTE: note,
									CONTRACT_NO: this.props.taskData.task
										.CONTRACT_NO,
									CONTRACT_TYPE: this.props.taskData.task
										.CONTRACT_TYPE
										? lang[
										this.props.taskData.task
											.CONTRACT_TYPE
										][this.props.language]
										: "",
									SITE_CONTACT: siteContact,
									SIGNATURE: signature,
									SERVICE_NO: serviceNo,
									WARRANTY: warranty,
									OPT: opt,
									TRAINEES: trainees,
									PROJECT: project,
									JOURNAL: journal,
									DISPATCHER_ID: dispatcher,
									TYPE: type,
									PRODUCTS: products,
									TEAM_SIZE: teamSize,
									JOURNAL_DATA: this.state.journalData,
									DEAD_LINE: deadLine
										? formatDateFromDb(deadLine, 12)
										: formatDateFromDb(new Date(), 12),
									PV_IMAGE: pvImage,
								}
							),
						resetTaskAction: (
							changeType,
							changeFrom,
							changeTo,
							note
						) =>
							this.props.actions.resetTaskAction(
								this.props.userAuth.id,
								{
									TASK_OBJID: this.props.taskData.task.OBJID,
									REASON: this.props.taskData.task.REASON,
									CHG_USER: this.props.userAuth.id,
									CHG_TYPE: changeType,
									CHG_FROM: changeFrom,
									CHG_TO:
										changeType !== 2
											? changeTo
											: this.props.userAuth.id,
									NOTE: note,
								}
							),
						newTaskComment: (
							changeType,
							changeFrom,
							changeTo,
							note,
							file
						) =>
							this.props.actions.newTaskComment(
								this.props.userAuth.id,
								{
									TASK_OBJID: this.props.taskData.task.OBJID,
									REASON: this.props.taskData.task.REASON,
									CHG_USER: this.props.userAuth.id,
									CHG_TYPE: changeType,
									CHG_FROM: changeFrom,
									CHG_TO:
										changeType !== 2
											? changeTo
											: this.props.userAuth.id,
									NOTE: note,
									FILE: file,
								}
							),
					}}
				/>
				<DeprecatedInterventionModal
					siteData={this.props.siteData}
					language={this.props.language}
					open={this.state.interModal}
					close={() => this.setState({ interModal: false })}
					interventionId={this.state.selectedTask}
					userPermissions={this.props.permissions}
					isAuthenticated={this.props.isAuthenticated}
					isUploadingFile={this.props.isUploadingFile}
					cust={{
						data: this.props.customerData,
						loading: this.props.isFetchingCustomerData,
						initialLoad: () =>
							this.props.siteData.CONTACT_OBJID &&
							this.props.actions.getCustomerData(
								this.props.siteData.CONTACT_OBJID
							),
					}}
					inter={{
						userId: this.props.userAuth.id,
						data: this.props.interventionData,
						loading: this.props.isFetchingInterventionData,
						downloadFile: downloadFile,
						fetchData: () =>
							this.props.actions.getInterventionData(
								this.state.selectedTask
							),
						newTaskComment: (
							changeType,
							changeFrom,
							changeTo,
							note,
							file
						) =>
							this.props.actions.newInterventionComment(
								this.props.userAuth.id,
								{
									INTERVENTION_OBJID: this.props
										.interventionData.task.OBJID,
									CHG_USER: this.props.userAuth.id,
									CHG_TYPE: changeType,
									CHG_FROM: changeFrom,
									CHG_TO:
										changeType !== 2
											? changeTo
											: this.props.userAuth.id,
									NOTE: note,
									INTERVENTION: true,
									GPS: this.props.geolocation,
									FILE: file,
									THEFT: false,
								}
							),
						newTaskAction: (changeType, changeFrom, changeTo, note, pvImage, alarm, offender, report, theft, emaildata) => {
							let formData = new FormData();
							let jsonData = {
								USER_ID: this.props.userAuth.id,
								INTERVENTION_OBJID: this.props.interventionData.task.OBJID,
								CHG_USER: this.props.userAuth.id,
								CHG_TYPE: changeType,
								CHG_FROM: changeFrom,
								CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
								NOTE: note,
								INTERVENTION: true,
								GPS: this.props.geolocation,
								ALARM: alarm,
								OFFENDER: offender,
								REPORT: report,
								THEFT: theft,
								EMAILDATA: emaildata
							};

							let informationBlob = new Blob([JSON.stringify(jsonData, { type: "application/json" })]);
							formData.append("file", pvImage !== null ? pvImage : null);
							formData.append("information", informationBlob);

							this.props.actions.newInterventionAction(this.props.userAuth.id, formData)
						},
					}}
				/>
				<EquipmentModal
					open={this.state.equipmentModalStatus}
					siteId={extractQueryFrontend(this.props.location.search).id}
					close={() => this.setState({ equipmentModalStatus: false })}
					language={this.props.language}
					openNotificcation={(type, message) => {
						if (message)
							this.props.actions.onNotificationOpen(type, lang[message], 6000)
					}}
				/>
				<CustomModal
					open={this.state.contractTablelModal}
					title={lang.CONTRACTS[this.props.language]}
					content={
						<Grid
							container
							justify="center"
							alignItems="center"
							spacing={2}
						>
							<Can
								neededPermissions={{
									parent: "CONTRACTS",
									name: "EDIT_CONTRACT",
								}}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() =>
										this.setState({
											editContractModal: true,
											contractTablelModal: false,
										})
									}
								>
									{lang.EDIT_CONTRACTS[this.props.language]}
								</Button>
							</Can>
							<Can neededPermissions={{ parent: "SUPER", name: "SUPER_DELETE" }}>
								<Button
									variant="contained"
									color="secondary"
									onClick={() => {
										this.setState({
											deleteContractModal: true,
											contractTablelModal: false
										})
									}}
								>
									Șterge Contract
								</Button>
							</Can>
						</Grid>
					}
					close={() => this.setState({ contractTablelModal: false })}
					language={this.props.language}
					noButtons
				/>
				<CustomModal
					fullWidth
					open={this.state.removeAccountModal}
					title={lang.CONFIRM_ACTION[this.props.language]}
					content={lang.CONFIRM_ACTION_CONTENT[this.props.language]}
					close={() => this.setState({ removeAccountModal: false })}
					execute={() => {
						this.props.actions.removeSiteAccount(
							this.props.userAuth.id,
							{ OBJID: this.props.siteData.OBJID }
						);
						this.setState({ removeAccountModal: false });
					}}
					validate={true}
					language={this.props.language}
				/>

				<SystemInfoModal
					open={this.state.openSystemLogingInfoModal}
					close={() =>
						this.setState({ openSystemLogingInfoModal: false })
					}
					siteId={extractQueryFrontend(this.props.location.search).id}
					language={this.props.language}
				/>

				<InfoPapers
					open={this.state.infoPapersModal}
					close={() => this.setState({ infoPapersModal: false })}
					siteId={extractQueryFrontend(this.props.location.search).id}
					language={this.props.language}
				/>

				<SiteContractEdit
					site={{
						data: this.props.siteData,
					}}
					contractID={this.state.contractID}
					siteId={this.props.siteData.OBJID}
					language={this.props.language}
					open={this.state.editContractModal}
					close={() => this.setState({ editContractModal: false })}
					updateContract={(id) =>
						this.props.actions.addUpdatedContract(
							this.props.userAuth.id,
							id,
							{
								...this.state.addData,
								SITE_OBJID: this.props.siteData.OBJID,
								SALE_CONTACT: this.props.userAuth.id,
							}
						)
					}
					setAddData={(data) =>
						this.setState({
							addData: {
								...data,
								TYPE: data.TYPE_ID,
								SUB_TYPE: data.SUB_TYPE_ID,
								CONTRACT_ASSIGNEE: data.ASSIGNEE_OBJID,
							},
						})
					}
					updateField={(e, length) =>
						this.setState({
							addData: {
								...this.state.addData,
								[e.target.name]: e.target.value.toString().slice(0, length),
							},
						})
					}
					updateFieldRaw={(field, value) => {
						let placeholder = this.state.addData;
						placeholder[field] = value;
						this.setState({ addData: placeholder });
					}}
					reload={() => window.location.reload()}
					addData={this.state.addData}
				/>

				<CustomModal
					fullWidth
					open={this.state.editModal}
					title={lang.EDIT_SITE[this.props.language]}
					content={
						<Grid container spacing={2}>
							<SiteNewForm
								edit
								gps
								addData={this.state.siteData}
								language={this.props.language}
								updateFieldRaw={(field, value) => {
									let placeholder = this.state.siteData;
									placeholder[field] = value;
									this.setState({
										siteData: placeholder,
										edited: true,
									});
								}}
								updateField={(e, length) =>
									this.setState({
										siteData: {
											...this.state.siteData,
											[e.target
												.name]: e.target.value
													.toString()
													.slice(0, length),
										},
										edited: true,
									})
								}
								error={this.state.error}
								updateError={(value) =>
									this.setState({ error: value })
								}
							/>
						</Grid>
					}
					close={() => {
						this.setState({
							editModal: false,
						});
					}}
					execute={() => {
						this.props.actions.editSiteData(
							this.props.userAuth.id,
							{
								...siteAddFormat(this.state.siteData),
								EDIT_ADDRESS: this.state.siteData.EDIT_ADDRESS,
								EDIT_CONTACT: this.state.siteData.EDIT_CONTACT,
								EDIT_CENTRAL: this.state.siteData.EDIT_CENTRAL,
								OBJID: this.props.siteData.OBJID,
							}
						);
						this.setState({ editModal: false });
					}}
					validate={
						!this.state.error &&
						this.state.edited &&
						this.state.siteData.SITE_CODE &&
						this.state.siteData.SITE_CODE.length > 0 &&
						this.state.siteData.SITE_NAME &&
						this.state.siteData.SITE_NAME.length > 0 &&
						this.state.siteData.PHONE &&
						this.state.siteData.PHONE.length > 0 &&
						this.state.siteData.REGION &&
						this.state.siteData.REGION.length > 0 &&
						this.state.siteData.CITY &&
						this.state.siteData.CITY.length > 0 &&
						this.state.siteData.STREET_NAME &&
						this.state.siteData.STREET_NAME.length > 0 &&
						this.state.siteData.CONTACT_LAST_NAME &&
						this.state.siteData.CONTACT_LAST_NAME.length > 0 &&
						this.state.siteData.CONTACT_FIRST_NAME &&
						this.state.siteData.CONTACT_FIRST_NAME.length > 0 &&
						this.state.siteData.CONTACT_PHONE &&
						this.state.siteData.CONTACT_PHONE.length > 0
					}
					language={this.props.language}
				/>
				{this.props.isFetchingSiteData === false ? (
					<SiteSingleSetup
						language={this.props.language}
						data={this.props.siteData}
						addData={this.state.addData}
						userPermissions={this.props.permissions}
						isAuthenticated={this.props.isAuthenticated}
						userAuth={this.props.userAuth}
						setAddSiteImageModal={(state) => this.setState({ addSiteImageModal: state })}
						setProjectAddModal={(state) => this.setState({ addProjectModal: state })}
						setEvaluationAddModal={(state) => this.setState({ evaluationAddModal: state })}
						onNotificationOpen={(type, message) =>
							this.props.actions.onNotificationOpen(
								type,
								lang[message],
								6000
							)
						}
						centralInfoOpen={() =>
							this.setState({ centralInfoModal: true })
						}
						taskList={this.props.taskList}
						site={{
							loading:
								this.props.isFetchingSiteData ||
								this.props.isFetchingCustomerData,
							settingsModal: this.state.settingsModal,
							openSettingsModal: () =>
								this.setState({ settingsModal: true }),
							closeSettingsModal: () =>
								this.setState({ settingsModal: false }),
							openEditModal: () =>
								this.setState({
									editModal: true,
									siteData: {
										...this.props.siteData,
										EDIT_ADDRESS: false,
										EDIT_CONTACT: false,
									},
									edited: false,
								}),
							openSystemLoginInfoModal: () =>
								this.setState({
									openSystemLogingInfoModal: true,
								}),
							openRemoveSiteAccountModal: () =>
								this.setState({ removeAccountModal: true }),
						}}
						cust={{
							data: this.props.customerData,
							loading: this.props.isFetchingCustomerData,
							initialLoad: () =>
								this.props.siteData.CONTACT_OBJID &&
								this.props.actions.getCustomerData(
									this.props.siteData.CONTACT_OBJID
								),
						}}
						contract={{
							openActiveContractsModal: () => {
								this.setState({ activeContractsModal: true });
							},
							openAddContractModal: () => {
								this.setState({
									addContractModal: true,
									contractData: contract,
								});
							},
							data: [...this.props.contractList],
							loading: this.props.isFetchingContractList,
							hasPermissionToAdd: checkPermissions(
								this.props.permissions,
								{ parent: "CONTRACTS", name: "ADD_CONTRACT" }
							),
						}}
						tasks={{
							data: this.props.taskList,
							loading: this.props.isFetchingTaskList,
							hasPermissionToAdd: checkPermissions(
								this.props.permissions,
								{ parent: "TASKS", name: "ADD_TASK" }
							),
							openAddTaskModal: () => {
								this.setState({
									addTaskModal: true,
									addData: task,
								});
							},
							openTaskSettings: (value) => {
								this.setState({
									taskModal: true,
									selectedTask: value,
								});
							},
							openAddMaintenaceModal: () =>
								this.setState({ addMaintenaceModalStatus: true }),
						}}
						interventions={{
							data: this.props.interventionList,
							loading: this.props.isFetchingInterventionList,
							hasPermissionToAdd: checkPermissions(
								this.props.permissions,
								{
									parent: "INTERVENTIONS",
									name: "ADD_INTERVENTION",
								}
							),
							hasPermissionToViewStatus: checkPermissions(
								this.props.permissions,
								{
									parent: "INTERVENTIONS",
									name: "VIEW_INTERVENTION_WITH_PAY"
								}
							),
							openAddInterventionModal: () => {
								this.setState({
									addInterModal: true,
									addData: {
										...intervention,
										AREA_CODE: this.props.siteData
											.AREA_CODE,
									},
								});
							},
							openInterSettings: (value) => {
								this.setState({
									interModal: true,
									selectedTask: value,
								});
							},
						}}
						tabs={{
							tabIndex: this.state.tabIndex,
							changeTabIndex: (event, value) =>
								this.setState({ tabIndex: value }),
						}}
						task={{
							taskId: this.state.selectedTask,
							open: this.state.taskModal,
							close: () => this.setState({ taskModal: false }),
							data: this.props.taskData,
							loading: this.props.isFetchingTaskData,
							fetchData: () =>
								this.props.actions.getTaskData(
									this.state.selectedTask
								),
							newTaskAction: (CHG_TYPE, CHG_FROM, CHG_TO, NOTE) =>
								this.props.actions.newTaskAction(
									this.props.userAuth.id,
									{
										TASK_OBJID: this.props.taskData.task
											.OBJID,
										CHG_USER: this.props.userAuth.id,
										CHG_TYPE: CHG_TYPE,
										CHG_FROM: CHG_FROM,
										CHG_TO: CHG_TO,
										NOTE: NOTE,
									}
								),
						}}
						intervention={{
							openAddInterventionModal: () => {
								this.setState({
									addInterModal: true,
									addData: {
										...intervention,
										AREA_CODE: this.props.siteData
											.AREA_CODE,
									},
								});
							},
							interventionId: this.state.selectedTask,
						}}
						openModalContracts={(id) =>
							this.setState({
								contractID: id,
								contractTablelModal: true,
							})
						}
						openModalEquipment={() =>
							this.setState({ equipmentModalStatus: true })
						}
						openInfoPapers={() => {
							this.setState({ infoPapersModal: true })
						}}
						setInstallationAddModal={() => this.setState({ installationAddModal: true })}
					/>
				) : (
					<PlaceholderSkeleton />
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.config.language,
		theme: state.config.theme,
		geolocation: state.config.geolocation,
		rowsPerPage: state.config.rowsPerPage,
		userAuth: state.auth.userData,
		isFetchingSiteData: state.cust.isFetchingSiteData,
		siteData: state.cust.siteData,
		contractList: state.cust.contractList,
		isFetchingCustomerData: state.cust.isFetchingCustomerData,
		customerData: state.cust.customerData,
		taskList: state.task.taskList,
		isFetchingTaskData: state.task.isFetchingTaskData,
		taskData: state.task.taskData,
		isAuthenticated: state.auth.isAuthenticated,
		permissions: state.auth.permissions,
		interventionList: state.task.interventionList,
		interventionData: state.task.interventionData,
		isFetchingInterventionData: state.task.isFetchingInterventionData,
		isFetchingInterventionList: state.task.isFetchingInterventionList,
		isFetchingTaskList: state.task.isFetchingTaskList,
		isFetchingContractList: state.cust.isFetchingContractList,
		isUploadingFile: state.task.isUploadingFile,
	};
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteSingleView);
