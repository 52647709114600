import React from 'react';
import { Grid, Paper, Typography, Hidden, Divider, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import * as lang from '../../../Constants/strings';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
}));

export default function ProfileRecent(props) {
    const classes = useStyles();

    return (
        <Hidden smDown>
            <Grid item xs={false} md={3} container direction="column" >
            <Grow in={props.edit === false} style={{ transitionDelay: props.edit === false ? '100ms' : '0ms' }}>
                    <Paper className={classes.paper}>
                        <Typography variant="body1" align="center">{lang.RECENT_ACTIVITY[props.language]}</Typography>
                        <Divider className={classes.divier} />
                        <br />
                        <Typography variant="caption" align="center">{lang.NO_RESULT[props.language]}</Typography>
                    </Paper>
                </Grow>
            </Grid>
        </Hidden>
    );
}
export function SkeletonProfileRecent(props) {
    return (
        <Hidden smDown>
            <Grid item xs={false} md={3} container direction="column" >
                <Skeleton component={Paper} className={props.classes.paper} height="30vh">
                    <Grid container justify="center" alignItems="center" direction="column">
                        <Skeleton width="50%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                        <Skeleton width="70%" />
                    </Grid>
                </Skeleton>
            </Grid>
        </Hidden>
    );
}