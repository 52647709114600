import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Paper, Typography, Select, MenuItem, Button } from '@material-ui/core';
import { formatDateFromDb } from '../../../Functions/dateFunctions';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import * as lang from "../../../Constants/strings";
import { intervention_history_data } from '../../../Components/Misc/Tables/TableHeaders';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CustomModal from '../../../Components/Modals/CustomModal';

const useStyles = makeStyles(theme => ({
    margin: {
        // margin: theme.spacing(2),
        width: 'auto'
    },
}));


export default function CustomerSiteIntervention(props) {
    const theme = useTheme();
    const classes = useStyles();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const [interventionType, setInterventionType] = React.useState([]);
    const [selectedInterventionType, setSelectInterventionType] = React.useState(null);
    const [intervention, setIntervention] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [endDate, setEndDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(new Date());

    const [docModalStaus, setDocModalStatus] = React.useState(false);
    const [interventionIndex, setInterventionIndex] = React.useState(null);
    const [interventionDocs, setInterventionDocs] = React.useState([]);

    // This effect will fetch the intervention list
    React.useEffect(() => {
        let type = selectedInterventionType !== null ? `&type=${selectedInterventionType}` : ``
        fetch(`/api/get_customer_intervention?userId=${props.customerId}&startDate=${formatDateFromDb(startDate, 15)}&endDate=${formatDateFromDb(endDate, 15)}${type}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                response.data.length > 0 &&
                    response.data.map(data => {
                        data.CREATE_DATE = formatDateFromDb(data.CREATE_DATE, 3);
                        data.END_DATE = formatDateFromDb(data.END_DATE, 3);
                    })
                setIntervention(response.data)
            })
    }, [props.customerId, startDate, endDate, selectedInterventionType]);

    React.useEffect(() => {
        fetch(`/api/inter_typelist`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setInterventionType(response)
            })
    }, [props.customerId]);

    // This will take a intervention id and retrive the docs names form the backend
    React.useEffect(() => {
        fetch(`/api/get_site_intervention_docs?siteId=${interventionIndex}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                setInterventionDocs(response.data)
            })
    }, [docModalStaus]);

    // Will get the file name as a param and will retrive the the file from server
    const downloadFile = (fileName) => {
        fetch(`/api/download_pdf?file=${fileName}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.blob();
            })
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf;' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(err => console.log(err))
    };

    return (
        <div>
            <CustomModal
                open={docModalStaus}
                title={lang.VIEW_INTERVENTION_DOCS[props.language]}
                content={
                    <React.Fragment>
                        <Grid conatiner justify='space-around' alignItems='stretch'>
                            {
                                interventionDocs.length > 0 && interventionDocs.map(doc => {
                                    return <Grid key={doc.NAME + '_grid'} item xs={12}>
                                        <Button key={doc.NAME + '_btn'} fullWidth style={{ margin: 5 }} variant='contained' color='primary' onClick={() => downloadFile(doc.NAME)}>{doc.NAME}</Button>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </React.Fragment>
                }
                close={() => setDocModalStatus(false)}
                noButons
            />
            <Grid container component="main" alignItems='center' className={classes.margin} spacing={tiny ? 1 : 4}>
                <Grid item xs={3}>
                    <Paper>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required={true}
                                inputVariant="outlined"
                                fullWidth
                                name="START_DATE"
                                label={lang.START_INTERVAL_DATE[props.language]}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={startDate}
                                onChange={(value) => setStartDate(value)}
                            />
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                required={true}
                                inputVariant="outlined"
                                fullWidth
                                name="END_DATE"
                                label={lang.LAST_INTERVAL_DATE[props.language]}
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={endDate}
                                onChange={(value) => setEndDate(value)}
                            />
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.margin}>
                        <Grid container component="main" justify='space-around' direction='row' alignItems='center' style={{ padding: 5 }}>
                            <Grid item xs={6}>
                                <Typography variant='h5' >{lang.ALARM_TYPE[props.language]}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Select label={lang.ALARM_TYPE[props.language]} variant='outlined' fullWidth value={selectedInterventionType} onChange={(e) => setSelectInterventionType(e.target.value)}>
                                    <MenuItem value={null}>{lang.NONE[props.language]}</MenuItem>
                                    {interventionType &&
                                        interventionType.map(type => {
                                            if (type.FK2PARENT === null) {
                                                return <MenuItem key={type.TYPE} value={type.TYPE}>{lang[type.TYPE][props.language]}</MenuItem>
                                            }
                                        })
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        tiny={tiny}
                        title={lang.ALARM_HISTORY[props.language]}
                        language={props.language}
                        data={intervention}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={setRowsPerPage}
                        header={intervention_history_data(props.language)}
                        hideAddBtn
                        openRowModal={(index) => {
                            setInterventionIndex(index)
                            setDocModalStatus(true)
                        }}
                        clickLabel="INTERVENTION_ID"
                    />
                </Grid>
            </Grid>
        </div>
    )
}
