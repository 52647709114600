import React from 'react';
import * as lang from "../../../../Constants/strings";
import { Grid } from "@material-ui/core";
import { wrong_pv_files } from "../../../../Components/Misc/Tables/TableHeaders";
import axios from "axios";
import * as requestHeaders from "../../../../Constants/requestHeaders";
import CustomModal from "../../../../Components/Modals/CustomModal";
import MaterialTable from "../../../../Components/Misc/Tables/MaterialTableSimple";
import moment from "moment";

export default function UserWrongPvModal(props) {
    const {
        language,
        userId,
        startDate,
        endDate,
        open,
        close
    } = props;
    const [data, setData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        getUserWrongPv(userId, startDate, endDate);
    }, [userId, startDate, endDate]);

    const getUserWrongPv = async (userId, intervalStart, intervalEnd) => {
        const response = await axios.get("/api/get_worng_user_pv", {
            ...requestHeaders.getGetHeader(),
            params: {
                intervalStart: intervalStart,
                intervalEnd: intervalEnd,
                userId: userId,
            }
        });

        if (response.status === 200) {
            if (response.data.length > 0) {
                let data = [];
                response.data.forEach(entry => {
                    entry.END_DATE = moment(entry.END_DATE).format("DD-MM-YYYY HH:mm:ss");
                    data.push(entry);
                })
                setData(data);
            }
        } else {
            console.log("error: ", response.data.message);
        }
    };

    const downloadfile = async (docId) => {
        const response = await axios.get("/api/get_wrong_pv", {
            ...requestHeaders.getGetHeader(),
            params: {
                fileId: docId
            },
            responseType: 'blob',
        });
        if (response.status === 200) {
            const blob = new Blob([response.data])
            const href = window.URL.createObjectURL(blob)
            let link = document.createElement("a");
            link.download = "Doc.pdf";
            link.href = href;
            link.click();
        }
    }

    return (
        <CustomModal
            open={open}
            title={lang.WRONG_PV[language]}
            fullScreen
            content={
                <Grid container spacing={2}>
                    <MaterialTable
                        tiny={true}
                        title={lang.WRONG_PV_REPORT[language]}
                        language={language}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={wrong_pv_files(language)}
                        dense={false}
                        openAddModal={() => { }}
                        openRowModal={(id) => downloadfile(id)}
                        clickLabel="FK2DOCS"
                        hideAddBtn={true}
                    />
                </Grid>
            }
            close={close}
            execute={() => { }}
            validate={false}
            language={language}
        />
    )
};
