import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 'auto',
        margin: theme.spacing(2),
        width: 'auto',
        borderRadius: 10,
        maxHeight: "75vh"
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        // width: '400px'
    },
    tabComponet: {
        width: '100%',
        overflowY: 'scroll',
    },
    tab: {
        maxWidth: "600px"
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function SideTabPanel(props) {
    const classes = useStyles();
    const { selectedTab, onChangeTab, tabs, tabComponets } = props;

    // selectedTab contain the curent index for the selected tab
    // onChangeTab is the function that handel the tab change by modifing the selectedTab value
    // tabs is a list of object that must have the next form [ {name: "Tab name", index : 1}, ...]
    // tabComponets contain the index and the componet that will be render for the gived index

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant='scrollable'
                value={selectedTab}
                onChange={(e, newIndex) => onChangeTab(newIndex)}
                className={classes.tabs}
            >
                {
                    tabs.length > 0 && tabs.map(tab => {
                        return <Tab className={classes.tab} key={tab.index} label={tab.name} index={tab.index} {...a11yProps(tab.index)} />
                    })
                }
            </Tabs>
            {
                tabComponets.map(componet => {
                    return <TabPanel className={classes.tabComponet} key={componet.index} value={selectedTab} index={componet.index}>
                        {
                            componet.component
                        }
                    </TabPanel>
                })
            }
        </div>
    )
}
