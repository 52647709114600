import React from 'react';
import { Grid, TextField, Fab, Typography, makeStyles } from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import AddressNewForm from './AddressNewForm';
import { ErrorRounded, CheckCircleRounded } from '@material-ui/icons';
import { checkSpecialChar } from '../../../Functions/stringManipulation';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    btn: {
        margin: theme.spacing(1)
    },
    title: {
        margin: theme.spacing(2),
    },
    clear: {
        position: 'absolute',
        right: 0,
        margin: theme.spacing(2),
    },
}));


export default function CustomerNewForm(props) {
    const classes = useStyles();
    const { addData, language, type, updateField, updateFieldRaw, toggleType, edit, address, error, updateError } = props;
    const [errorText, setError] = React.useState();


    function checkNameAvailability(e) {
        const { value } = e.target;
        if (value.trim())
            axios.get(`/api/check_customer?value=${value.trim()}${edit ? `&objid=${addData.ID}` : ''}`)
                .then(res => {
                    updateError(Number(res.data) > 0);
                    if (Number(res.data) > 0)
                        setError("CUSTOMER_EXISTS");
                    if (checkSpecialChar(value.trim(), " *|,\":<>[]{}`';()@&$#%")) {
                        setError("SPECIAL_CHAR");
                        updateError(true);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        else {
            setError("EMPTY_FIELD");
        }
    }
    function focus() {
        setError(null);
    }
    React.useEffect(() => {
        setError(null);
    }, [addData.CUSTOMER_CODE]);

    return (
        <Grid container spacing={2}>
            {!edit &&
                <React.Fragment>
                    <Grid container component='main' justify='space-evenly' alignItems='stretch'>
                        <Grid item xs='auto'>
                            <Fab className={classes.btn} variant='round' color='secondary' disabled={type === 'PF'} onClick={() => { toggleType('PF'); setError(null); }}>PF</Fab>
                        </Grid>
                        <Grid item xs='auto'>
                            <Fab className={classes.btn} variant='round' color='secondary' disabled={type === 'PJ'} onClick={() => { toggleType('PJ'); setError(null); }}>PJ</Fab>
                        </Grid>
                    </Grid>
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Typography className={classes.title} variant='h6'>{type === 'PF' ? lang.CLIENT_TYPE_PF[language] : lang.CLIENT_TYPE_PJ[language]}</Typography>
                    </Grid>
                </React.Fragment>
            }
            {type === 'PF'
                ?
                <React.Fragment>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            name="CUSTOMER_CODE"
                            label={lang.CUSTOMER_CODE[language]}
                            value={addData.CNP ? addData.CNP : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="LAST_NAME"
                            label={lang.LAST_NAME[language]}
                            value={addData.LAST_NAME ? addData.LAST_NAME : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="FIRST_NAME"
                            label={lang.FIRST_NAME[language]}
                            value={addData.FIRST_NAME ? addData.FIRST_NAME : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                name="CNP"
                                label={lang.CNP[language]}
                                value={addData.CNP ? addData.CNP : ""}
                                onChange={(e) => updateField(e, 13)}
                                type="number"
                                onChange={(e) => updateField(e, 50)}
                                onFocus={focus}
                                error={error}
                                helperText={errorText ? lang[errorText][language] : null}
                                onBlur={checkNameAvailability}
                            />
                            {error === true
                                ?
                                <ErrorRounded className={classes.clear} color="secondary" />
                                :
                                error === false
                                    ?
                                    <CheckCircleRounded className={classes.clear} />
                                    :
                                    null
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="PHONE"
                            label={lang.PHONE[language]}
                            value={addData.PHONE ? addData.PHONE : ""}
                            onChange={(e) => updateField(e, 20)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="EMAIL"
                            label={lang.EMAIL[language]}
                            value={addData.EMAIL ? addData.EMAIL : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                </React.Fragment>
                :
                <React.Fragment>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            name="CUSTOMER_CODE"
                            label={lang.CUSTOMER_CODE[language]}
                            value={addData.CUI ? addData.CUI : ""}
                            onChange={(e) => updateField(e, 50)}
                            onFocus={focus}
                            error={error}
                            helperText={errorText ? lang[errorText][language] : null}
                            onBlur={checkNameAvailability}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="NAME"
                            label={lang.NAME[language]}
                            value={addData.NAME ? addData.NAME : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                name="CUI"
                                type='number'
                                label={lang.CUI[language]}
                                value={addData.CUI ? addData.CUI : ""}
                                onChange={(e) => updateField(e, 50)}
                                onFocus={focus}
                                error={error}
                                helperText={errorText ? lang[errorText][language] : null}
                                onBlur={checkNameAvailability}
                            />
                            {error === true
                                ?
                                <ErrorRounded className={classes.clear} color="secondary" />
                                :
                                error === false
                                    ?
                                    <CheckCircleRounded className={classes.clear} />
                                    :
                                    null
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="NR_REG"
                            label={lang.NR_REG[language]}
                            value={addData.NR_REG ? addData.NR_REG : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="ADMINISTRATOR"
                            label={lang.ADMINISTRATOR[language]}
                            value={addData.ADMINISTRATOR ? addData.ADMINISTRATOR : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="PHONE"
                            label={lang.PHONE[language]}
                            value={addData.PHONE ? addData.PHONE : ""}
                            onChange={(e) => updateField(e, 20)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="EMAIL"
                            label={lang.EMAIL[language]}
                            value={addData.EMAIL ? addData.EMAIL : ""}
                            onChange={(e) => updateField(e, 50)}
                        />
                    </Grid>
                </React.Fragment>
            }
            {address &&
                <React.Fragment>
                    <Grid container component='main' justify='center' alignItems='center'>
                        <Typography className={classes.title} variant='h6'>{lang.ADDRESS[language]}</Typography>
                    </Grid>
                    <AddressNewForm address={address} addData={addData} language={language} updateFieldRaw={updateFieldRaw} updateField={updateField} />
                </React.Fragment>
            }
        </Grid>
    );
}