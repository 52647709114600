import Html5QrcodePlugin from "./HtmlQrCodePlugin";
import * as lang from "../Constants/strings";
import CustomModal from "./Modals/CustomModal";

const ScanningModal = (props) => {
    const {
        isOpen,
        onClose,
        onRead,
        language = "ro"
    } = props;

    const onNewScanResult = (decodedText, decodedResult) => {
        onRead(decodedText);
    };

    return (
        <CustomModal
            fullScreen
            open={isOpen}
            title={lang.SCAN_ITEM[language]}
            content={
                <div>
                    <Html5QrcodePlugin
                        fps={5}
                        qrbox={250}
                        disableFlip={false}
                        verbose={true}
                        qrCodeSuccessCallback={(d, r) => onNewScanResult(d, r)}
                        qrCodeErrorCallback={() => { }}
                        language={language}
                    />
                </div>
            }
            close={() => onClose()}
            language={language}
            onlyBack
            noButtons
        />
    )
}

export default ScanningModal;
