import React, { useState } from "react";
import axios from "axios";
import * as requestHeaders from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import * as lang from "../../../Constants/strings";
import { contract_reporting_header } from "../../../Components/Misc/Tables/TableHeaders";
import SortBar from './SortBar';
import ViewContract from '../../Site/SiteSingleViewComponents/ViewContract';
import CustomModal from '../../../Components/Modals/CustomModal';
import {
    Grid
} from '@material-ui/core';

const ContractReport = (props) => {
    const { language } = props;
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [data, setData] = useState([]);
    const [contractData, setContractData] = useState(null);
    const [viewContractModal, setViewContractModal] = useState(false);

    const getUserTaskActivity = async ({ startDate, endDate, selectedContractor }) => {
        const response = await axios.get('/api/contract_report', {
            ...requestHeaders.getGetHeader(),
            params: {
                startDate,
                endDate,
                contractorId: selectedContractor,
                type: 2
            }
        });
        if (response.status === 200 || response.status === 204) {
            setData(response.data.data);
            console.log(response.data.data)
        } else {
            setData([]);
        }
    };


    const downloadAction = ({ startDate, endDate, selectedContractor }) => {
        fetch(`/api/download_contract_report_excel?startDate=${startDate}&endDate=${endDate}&contractorId=${selectedContractor}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                },
                method: 'GET',
                responseType: 'arraybuffer',
            }
        )
            .then(response => response.blob())
            .then(response => {
                const file = new Blob(
                    [response],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement('a');
                link.href = fileURL;
                link.download = `Raport_contracte.xlsx`;
                link.click();
            })
            .catch(err => console.log(err))
    };

    const getContract = async (id) => {
        try {
            let response = await axios.get('/api/get_contract_data', {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: id
                }
            });
            setContractData(response.data);
        } catch (e) {
            setContractData(null);
        }
    }
    return (
        <>
            {contractData !== null &&
                <CustomModal
                    open={viewContractModal}
                    title="Contract"
                    fullWidth
                    content={
                        <Grid container spacing={2}>
                            <ViewContract
                                {...props}
                                addData={contractData}
                                tiny={false}
                            />
                        </Grid>
                    }
                    close={() => setViewContractModal(false)}
                    execute={() => setViewContractModal(false)}
                    language={language}
                    noButtons
                />
            }
            <SortBar
                language={language}
                filter={getUserTaskActivity}
                onDownload={downloadAction}
            />
            <MaterialTable
                tiny={true}
                title="Raport contracte"
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={contract_reporting_header(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={(id) => {
                    getContract(id);
                    setViewContractModal(true);
                }}
                clickLabel="OBJID"
                hideAddBtn={true}
            />
        </>
    )
}

export default ContractReport;