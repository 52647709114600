import { createStore, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appReducer } from './reducers/rootReducer';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'config',
        'auth',
        // 'sp',
    ]
}

const persistedReducer = persistReducer(persistConfig, appReducer);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;