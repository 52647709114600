import MaterialTable from "../../Components/Misc/Tables/MaterialTableSimple";
import * as lang from "../../Constants/strings";
import {
  maintenance_history_view,
  maintenance_view,
  small_maintenance_history_view
} from "../../Components/Misc/Tables/TableHeaders";
import React, {useEffect, useState} from "react";
import {useMediaQuery, useTheme} from "@material-ui/core";
import axios from "axios";
import {getGetHeader} from "../../Constants/requestHeaders";
import ScoringModal from "../../Components/TechnicalUser/ScoringModal";
import moment from "moment";

const MaintenancesTechnicalScores = (props) => {
  const {
    language,
  } = props;

  const [rowsPerPage, onRowsPerPageChange] = useState(5);
  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down("xs"));

  const [history, setHistory] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [scoreModal, setScoreModal] = useState(false);

  const getMaintenances = async () => {
    try {
      const res = await axios.get('/api/maintenances/with-no-scores', getGetHeader());
      const data = [];

      for(const d of res.data.maintenaces) {
        const t  = { ...d };
        t.CREATE_DATE = moment(t.CREATE_DATE).format("DD-MM-YYYY HH:mm")
        data.push(t);
      }
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getMaintenances();
  }, []);

  return (
    <>
      {
        scoreModal &&
        <ScoringModal
          language={language}
          taskId={selectedTask}
          open={scoreModal}
          close={() => {
            setScoreModal(false);
            getMaintenances();
          }}
        />
      }

      <MaterialTable
        tiny={tiny}
        title={lang.MAINTENANCE[language]}
        language={language}
        data={history}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        header={maintenance_view(language)}
        dense={false}
        openAddModal={() => { }}
        openRowModal={(id) =>  {
          setSelectedTask(id);
          setScoreModal(true);
        }}
        clickLabel="TASK_ID"
        hideAddBtn={true}
      />
    </>
  )
}

export default MaintenancesTechnicalScores;