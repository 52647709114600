import React from 'react';
import { Grid, Paper, Avatar, Typography, Box, Divider, Button, ButtonBase, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import getAvatarUri from '../../../Functions/getDataUriAvatar';
import * as lang from '../../../Constants/strings';
import PasswordIcon from '@material-ui/icons/SecurityRounded';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    bigAvatar: {
        margin: theme.spacing(-1.3),
        width: 100,
        height: 100,
    },
    paper: {
        padding: theme.spacing(2),
    },
    paperBgLight: {
        height: '120px',
        padding: theme.spacing(2),
        backgroundImage: `url(/api/image?image=landing-page-light.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: '100%',
    },
    paperBgDark: {
        height: '120px',
        padding: theme.spacing(2),
        backgroundImage: `url(/api/image?image=landing-page-dark.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: '100%',
    },
    button: {
        margin: theme.spacing(-1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
        // width: '200px',
    },
    // avatarButton: {
    //     marginTop: theme.spacing(2)
    // },
    avatarButton: {
        marginTop: theme.spacing(2),
        position: 'relative',
        // height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                opacity: 1,
                // border: '1px solid currentColor',
                // borderRadius: '25px'
            },
        },
    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageTitle: {
        opacity: 0,
        position: 'relative',
        padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(6)}px`,
        transition: theme.transitions.create('opacity'),
    },
    focusVisible: {},

}));

export default function ProfileEditSecond(props) {
    const classes = useStyles();
    return (
        <Grid item sm={12} md={3} container direction="column">
            <Grid item xs={false} md={false} >
                <Slide in={props.edit === true} direction="down">
                    <Paper className={classes.paper}>
                        <Typography variant="body1" align="center">AVATAR</Typography>
                        <Divider className={classes.divier} />
                        <Grid container justify="center" >
                            <ButtonBase
                                className={classes.avatarButton}
                                focusVisibleClassName={classes.focusVisible}
                                onClick={props.toggleAvatarModal}
                            >
                                <Box border={5} borderRadius={100} color="white" >
                                    <Avatar alt={props.userData ? props.userData.basic.AVATAR_ID + "" : ""} src={getAvatarUri(props.userData ? props.userData.basic.AVATAR_ID : 1)} className={classes.bigAvatar} />
                                </Box>
                                <span className={classes.imageButton}>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color="inherit"
                                        className={classes.imageTitle}
                                    >
                                        {"Modifică"}
                                        <span className={classes.imageMarked} />
                                    </Typography>
                                </span>
                            </ButtonBase>
                        </Grid>
                    </Paper>
                </Slide>
            </Grid>
            <Grid item xs={false} md={false} style={{ marginTop: props.tiny ? '8px' : '32px' }}>
                <Slide in={props.edit === true} direction="down">
                    <Paper className={classes.paper}>
                        <Grid container justify="center" >
                            <Button
                                variant="text"
                                onClick={props.changePassword.togglePassModal}
                            >
                                <PasswordIcon fontSize="small" className={classes.leftIcon} />
                                {lang.CHANGE_PASSWORD[props.language]}
                            </Button>
                        </Grid>
                    </Paper>
                </Slide>
            </Grid>
        </Grid>
    );
}