export const CUSTOMERS = {
    "en": "Customer",
    "ro": "Clienți",
};
export const TASKS = {
    "en": "Tasks",
    "ro": "Ticket",
};
export const CONTRACTS = {
    "en": "Contracts",
    "ro": "Contracte",
};
export const ADD_CUSTOMER = {
    "en": "Add customer",
    "ro": "Adăugare client",
};
export const EDIT_CUSTOMER = {
    "en": "Edit customer",
    "ro": "Editare/Validare client",
};
export const VIEW_CUSTOMER = {
    "en": "View customer",
    "ro": "Vizualizare listă client",
};
export const VIEW_CUSTOMER_DETAIL = {
    "en": "View customer details",
    "ro": "Vizualizare detalii client",
};
export const ADD_TASK = {
    "en": "Add task",
    "ro": "Adăugare ticket",
};
export const EDIT_TASK = {
    "en": "Edit task",
    "ro": "Editare ticket",
};
export const EDIT_TASK_OWN = {
    "en": "Edit own task",
    "ro": "Editare ticket propriu",
};
export const VIEW_TASK = {
    "en": "View task",
    "ro": "Vizualizare ticket",
};
export const VIEW_TASK_DETAIL = {
    "en": "View task details",
    "ro": "Vizualizare detalii ticket",
};
export const ADD_CONTRACT = {
    "en": "Add contract",
    "ro": "Adăugare contract",
};
export const VIEW_CONTRACT = {
    "en": "View contract",
    "ro": "Vizualizare contract",
};
export const VIEW_CONTRACT_DETAIL = {
    "en": "View contract details",
    "ro": "Vizualizare detalii contract",
};
export const EDIT_CONTRACT = {
    "en": "Edit contract",
    "ro": "Editare contract",
};
export const DASHBOARD = {
    "en": "Dashboard",
    "ro": "Administrare",
};

export const SITE = {
    "en": "Site",
    "ro": "Obiectiv",
};

export const VIEW_SITE = {
    "en": "View site",
    "ro": "Vizualizare obiectiv",
};

export const ADD_SITE = {
    "en": "Add site",
    "ro": "Adăugare obiectiv",
};

export const EDIT_SITE = {
    "en": "Edit site",
    "ro": "Editare/Validare obiectiv",
};

export const QUICK_ACTION = {
    "en": "Quick action",
    "ro": "Acțiunii rapide",
};

export const QUICK_CUSTOMER_ADD = {
    "en": "Quick customer add",
    "ro": "Adăugare rapidă client",
};

export const QUICK_SITE_SEARCH = {
    "en": "Quick site cearch",
    "ro": "Căutare rapidă obiectiv",
};

export const QUICK_CUSTOMER_SEARCH = {
    "en": "Quick customer search",
    "ro": "Căutare rapidă clienți"
};

export const TAKE_TASK = {
    "en": "Take task",
    "ro": "Preluare/Anulare tickete propriu"
};

export const REPORTS = {
    "en": "Reports",
    "ro": "Rapoarte"
};

export const VIEW_REPORTS_PAGE = {
    "en": "View reports page",
    "ro": "Acces pagină rapoarte"
};

export const GENERATE_ACTIVITY_REPORT = {
    "en": "Generate police report #1",
    "ro": "Generare raport poliție #1"
};
export const GENERATE_ACTIVITY_REPORT_2 = {
    "en": "Generate police report #2",
    "ro": "Generare raport poliție #2"
};

export const CANCEL_TASK = {
    "en": "Cancel task",
    "ro": "Anulează ticket"
};

export const VIEW_TASK_HISTORY = {
    "en": "View task history",
    "ro": "Vizualizare istoric tickete"
};

export const BILLING = {
    "en": "Billing",
    "ro": "Facturare"
};

export const BILL_VALIDATION = {
    "en": "Bill validation",
    "ro": "Validare facturare"
};

export const RECEIPT_VALIDATION = {
    "en": "Receipt validation",
    "ro": "Validare încasare"
};

export const SITE_ADD_SYSTEM_LOGIN_INFO = {
    "en": "Add site system login information",
    "ro": "Adăugare/Editare informații de logare la sistemul obiectivului"
};

export const VIEW_SYSTEM_LOGIN_INFO = {
    "en": "View site system login info",
    "ro": "Vizualizare informații logare la sistemul obiectivului"
};

export const VIEW_SITE_AREA = {
    "en": "View site area",
    "ro": "Vizualizare zone alarmă obiectiv"
};

export const EDIT_SITE_AREA = {
    "en": "Edit site area",
    "ro": "Editare zone de alarmă obiectiv"
};

export const ADD_SITE_AREA = {
    "en": "Add site area",
    "ro": "Adăugare zonă alarmă pe obiectiv"
};

export const TASK_CHANGE_PRIORITY = {
    "en": "Task change priority",
    "ro": "Schimbare prioritate ticket"
}

export const SITE_VIEW_CENTRAL_INFO = {
    "en": "View site central information",
    "ro": "Vizualizare informații centrală"
}

export const SITE_VIEW_CONTRACT_TEHNIC = {
    "en": "View contract of type tehnic",
    "ro": "Vizualizare contracte de tip tehnic"
}

export const SITE_VIEW_CONTRACT_WATCH = {
    "en": "View contract of type watch",
    "ro": "Vizualizare contracte de pază"
}

export const INTERVENTIONS = {
    "en": "Alarms",
    "ro": "Alarme"
}

export const ACTION_INTERVENTION_BASIC = {
    "en": "Basic intervention actions",
    "ro": "Acțiuni de bază"
}

export const ACTION_INTERVENTION_DISPATCHER = {
    "en": "Dispatch intervention actions",
    "ro": "Acțiuni dispecer"
}

export const ACCOUNTING = {
    "en": "Accounting",
    "ro": "Contabilitate"
}

export const ACCOUNTING_ACCESS = {
    "en": "Accounting access",
    "ro": "Acces contabilitate"
}

export const ADD_INTERVENTION = {
    "en": "Add intervention",
    "ro": "Adăugare alarmă"
}

export const VIEW_PHONE_WARNING = {
    "en": "View phone warning",
    "ro": "Vizualizare înştiinţare telefonică"
}

export const ADD_PHONE_WARNING = {
    "en": "Add phone warning",
    "ro": "Adăugare înştiinţare telefonică"
};

export const VIEW_INTERVENTION_HISTORY = {
    "en": "View intervention History",
    "ro": "Vizualizare istoric alarme"
}

export const VIEW_CUSTOMER_INTERVENTION = {
    "en": "View customer intervention",
    "ro": "Vizualizare alarme clienți"
}

export const VIEW_SITE_INTERVENTION = {
    "en": "View site intervention",
    "ro": "Vizualizare alarme obiectiv"
}
export const USERS = {
    "en": "Users",
    "ro": "Utilizatori"
}
export const EDIT_USER = {
    "en": "Edit user",
    "ro": "Editare utilizator"
}

export const CHANGE_CUSTOMER_STATUS = {
    "en": "Change customer status",
    "ro": "Schimbare status clienți (Activ/Inactiv)"
}

export const QUICK_INTERVENTION = {
    "en": "Shortcut for intervention",
    "ro": "Scurtătură pentru alarme"
}

export const TEMPLATE_FILE = {
    "en": "Template files",
    "ro": "Documente tipizate"
};

export const VIEW_TEMPLATE_FILE = {
    "en": "View template file",
    "ro": "Vizualizare documente tipizate"
};

export const DELETE_TEMPLATE_FILE = {
    "en": "Delete template files",
    "ro": "Ștergere documente tipizate"
};

export const ADD_TEMPLATE_FILE = {
    "en": "Add template files",
    "ro": "Adăugare documente tipizat"
};

export const VALIDATE_TASK_BILL_RECIPE = {
    "en": "Task bill recipe validation",
    "ro": "Validare factură ticket"
};

export const VALIDATE_TASK_BILL_CHASHING = {
    "en": "Task bill chasing validation",
    "ro": "Validare încasare factură ticket"
};

export const VIEW_TASK_BILL_HISTORY = {
    "en": "View task bill history",
    "ro": "Vizualizare istoric de facturi/încasări pentru tickete"
};

export const QUICK_ACTION_VIEW_TASK_BILL_HISTORY = {
    "en": "Quick action view task bill history",
    "ro": "Scurtătură istoric de facturi/încasări pentru deranjamete"
};

export const QUICK_ACTION_VIEW_TEMPLATE_FILES = {
    "en": "Quick action view template files",
    "ro": "Scurtătură documente tipizate"
};

export const UPDATE_TASK_REASON = {
    "en": "Update task reason",
    "ro": "Actualizare motiv ticket"
};

export const EDIT_TASK_BILL = {
    en: "Edit Task Bill",
    ro: "Editare factură după validare"
}

export const SUPER_RESET_TASK = {
    en: "Reset Task",
    ro: "Anulează alocarea (oricăriu ticket)"
}
export const MAINTENANCE = {
    "en": "Maintenance",
    "ro": "Mentenanțe"
};
export const ADD_MAINTENANCE = {
    "en": "Add maintenance",
    "ro": "Adăugare manuală mentenanțe"
};
export const CANCEL_MAINTENANCE = {
    "en": "Cancel maintenance",
    "ro": "Anulare mentenanțe"
};
export const TAKE_MAINTENANCE = {
    "en": "Take maintenance",
    "ro": "Preluare mentenanțe"
};
export const VIEW_MAINTENANCE_HISTORY = {
    "en": "View maintenance hiistory",
    "ro": "Vizualizare istoric mentenanțe"
};
export const RESET_MAINTENANCE = {
    "en": "Reset maintenance",
    "ro": "Anulare preluare"
};
export const QUICK_VIEW_MAINTENANCE_SHORTCUT = {
    "en": "View maintenace shortcut",
    "ro": "Vizualizare scurtătură mentenanță"
};
export const VIEW_MAINTENANCE = {
    en: "View maintenance",
    ro: "Vizualizare mentenanțe"
};
export const SUPER = {
    en: "Super permissions",
    ro: "Super permisiunii"
};
export const SUPER_DELETE = {
    en: "Super delete",
    ro: "Super delete"
};
export const TECHNIC_MANAGEMENT = {
    en: "Tehnic management",
    ro: "Management tehnic"
};
export const VIEW_INTERVENTION_WITH_PAY = {
    en: "View intervention pay type",
    ro: "Vizualizare tip interventie (gratuit/cu plata)"
};

export const AREA_MANAGMENT = {
    en: "Area management",
    ro: "Gestiune zone"
};

export const PROJECTS = {
    en: "Project",
    ro: "Proiecte"
};

export const VIEW_PROJECTS = {
    en: "View projects",
    ro: "Vizualizare proiecte"
};

export const ADD_PROJECTS = {
    en: "Add project",
    ro: "Adaugare proiecte"
};

export const RISC_EVALUATIONS = {
    en: "Risc evaluations",
    ro: "Evaluari de risc"
};

export const VIEW_RISC_EVALUATIONS = {
    en: "View risc evalautiuons",
    ro: "Vizualizare evaluari de risc"
};

export const ADD_RISC_EVALUATIONS = {
    en: "Add risc evalautions",
    ro: "Adaugare evaluari de risc"
};

export const QUICK_VIEW_RISC_EVALUATION = {
    en: "Shortcut risc evalaution",
    ro: "Scurtatură evaluare de risc"
};

export const QUICK_PROJECTS_VIEW = {
    en: "Shortcut project view",
    ro: "Scurtatură proiectare"
};

export const INSTALLATION = {
    en: "Installation",
    ro: "Instalare"
};

export const ADD_INSTALLATION = {
    en: "Add installation",
    ro: "Adauga installare"
};

export const QUICK_INSTALLATION_VIEW = {
    en: "Quick installation view",
    ro: "Scurtatura installare"
};

export const VIEW_INSTALLATION_HISTORY = {
    en: "View installation history",
    ro: "Vizualizare istoric instalare"
};
export const VIEW_INSTALLATION = {
    en: "View installation",
    ro: "Vizualizare instalare"
};
export const VIEW_SCORE = {
    en: "View installation score",
    ro: "Vizualizare punctaj instalare"
};
export const VALIDATE_SCORE = {
    en: "Installation score validation",
    ro: "Validare punctaj instalare"
};
export const OVERWRITE_SCORE = {
    en: "Installation score overwrite",
    ro: "Modificare punctaj instalare"
};
export const VALIDATE_INSTALLATION_BILL_RECIPE = {
    en: "Validate installation bill recipe",
    ro: "Validare facturare instalare"
};

export const VALIDATE_INSTALLATION_BILL_CHASING = {
    en: "Validate installation bill chasing",
    ro: "Validare incasare factura instalare"
};

export const TAKE_INSTALLATION = {
    en: "Take installation",
    ro: "Preluare Instalare"
};

export const CANCEL_INSTALLATION = {
    en: "Cancel installation",
    ro: "Anulare instalare"
};


export const RESET_INSTALLATION = {
    en: "Reset installation",
    ro: "Resetare alocare instalare"
};

export const CASHING_VALIDATION_OVERWRITE = {
    en: "Overwrite the value of the recipe validation",
    ro: "Suprascriere valoare validata pentru factura"
};

export const CANCEL_PROJECT = {
    en: "Cancel project ticket",
    ro: "Anulare ticket de proiectare"
}

export const OVERWRITE_PROJECT_OWNER = {
    en: "Overwrite project owner",
    ro: "Preluare proiecte",
}

export const TECHNICAL_TEAM = {
    en: "Technical Team",
    ro: "Echipaje utilizatori din departamentul technic"
}

export const VIEW_AND_EDIT_TECHNICAL_SCORE = {
    en: "View and edit the technical score",
    ro: "Vizualizare și editare punctaj echipaj technic"
}

export const VIEW_AND_EDIT_TECHNICAL_SCORE_HISTORY = {
    en: "View and edit the technical score history",
    ro: "Vizualizare și editare istoric punctaj echipaj technic"
}

export const EXTERNAL_INVENTORY = {
    en: "External Information",
    ro: "Inventar Extern"
}