import React, { useState, useEffect, useCallback, Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  makeStyles,
  CssBaseline,
  useTheme,
  Grid,
  useMediaQuery,
  Tabs,
  Tab,
  Paper,
  Typography,
  Select,
  MenuItem
} from "@material-ui/core";
import { NewReleases } from "@material-ui/icons";
import { getStatusIcon } from "../../Functions/getIcon";
import axios from "axios";
import qs from "qs";

import * as actionCreators from "../../actions/actions";
import * as lang from "../../Constants/strings";
import * as requestHeaders from "../../Constants/requestHeaders";

import { formatDateFromDb } from "../../Functions/dateFunctions";
import {
  maintenance_view,
  small_maintenance_view,
} from "../../Components/Misc/Tables/TableHeaders";

import { checkPermissions } from '../../Functions/permissionHide';
import { startInterval } from '../Interventions/InterventionsComponets/helpers';
import MaterialTable from "../../Components/Misc/Tables/MaterialTableSimple";
import MaintenanceModal from "../Maintenance/MaintenanceModal/MaintenanceModal";
import MaintenanceHistory from "./MaintenanceHistory";
import AddTechnicalTeam from "../TechnicalTeams/Modals/AddTechnicalTeam";
import MaintenancesTechnicalScores from "./MaintenancesTechnicalScores";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(10),
    width: "auto",
  },
  shortcut: {
    marginBottom: theme.spacing(10),
    margin: theme.spacing(1),
    transform: "rotate(180deg)",
  },
  margin: {
    margin: theme.spacing(2),
    width: "auto",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  areaSelect: {
    maxHeight: "50vh",
  }
}));

function MaintenanceSetup(props) {
  const classes = useStyles();
  const [rowsPerPage, onRowsPerPageChange] = useState(5);
  const [data, setData] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);

  const { language } = props;

  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down("xs"));
  const [areaList, setAreaList] = React.useState([]);

  const getAreaList = async () => {
    let response = await axios.get(`/api/areas/MAINTENANCE`, requestHeaders.getGetHeader());

    if (response.status === 200) {
      setAreaList(response.data);
    } else {
      setAreaList([]);
    }
  };

  const handleAreaSelect = (newValue) => {
    if (newValue.props.value === 0) {
      setSelectedArea([])
    } else if (selectedArea.indexOf(newValue.props.value === -1)) {
      setSelectedArea([...selectedArea, newValue.props.value])
    }
  }

  const fetchFunction = useCallback(async () => {
    let areas = selectedArea.map(area => area.AREA_CODE)
    try {
      let result = await axios.get(
        "/api/get_active_maintenance",
        {
          ...requestHeaders.getGetHeader(),
          params: {
            area_code: areas
          },
          paramsSerializer: params => qs.stringify(params)
        }
      );
      let list = [];
      result.data.maintenanceList.map((item) => {
        list.push({
          ...item,
          TYPE: item.TYPE !== null ? lang[item.TYPE][language] : item.TYPE !== null,
          LAST_UPDATE: formatDateFromDb(item.LAST_UPDATE, 12),
          CREATE_TIME: formatDateFromDb(item.CREATE_TIME, 12),
        });
      });
      list = getStatusIcon(list);
      setData(list);
    } catch (error) {
      console.error(error);
      setData([]);
    }
  }, [selectedArea])

  useEffect(() => {
    fetchFunction().then(() => {});
    startInterval(fetchFunction, false);
  }, [selectedArea]);

  useEffect(() => {
    fetchFunction();
    getAreaList();
    startInterval(fetchFunction, false);
  }, []);

  return (
    <Grid container component="main" className={classes.margin}>
      <Grid item xs={12}>
        <AddTechnicalTeam />
        <Paper style={{ position: "relative" }}>
          <Select
            multiple
            select
            fullWidth
            style={{ borderRadius: "0px" }}
            variant="outlined"
            label={lang.AREA[language]}
            value={selectedArea}
            MenuProps={{
              className: classes.areaSelect,
              getContentAnchorEl: () => null
            }}
            onChange={(e, newValue) => handleAreaSelect(newValue)}
          >
            <MenuItem value={0}>Niciuna</MenuItem>
            {areaList && areaList.sort((a, b) => a.AREA.toUpperCase() < b.AREA.toUpperCase() ? -1 : a.AREA.toUpperCase() > b.AREA.toUpperCase() ? 1 : 0).map(area => {
              return <MenuItem key={area.AREA} value={area}>{`${area.AREA} (${area.TASK_COUNT})`}</MenuItem>
            })}
          </Select>
          <Typography variant="h6" style={{ position: "absolute", right: "45px", top: "12px" }} color="secondary">
            Nr. mentenanțe {data.length}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          tiny={tiny}
          title={lang.MAINTENANCE[language]}
          language={language}
          data={data}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          header={
            tiny === true
              ? small_maintenance_view(language)
              : maintenance_view(language)
          }
          dense={false}
          openAddModal={props.openAddModal}
          openRowModal={(id) => props.openRowModal(id)}
          clickLabel="TASK_ID"
          hideAddBtn={true}
        />
      </Grid>
    </Grid>
  );
}

function TabSelector(props) {
  const { value, handleChange, language } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={(e, newValue) => {
            handleChange(newValue);
          }}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab
            icon={<NewReleases color="secondary" />}
            label={lang.MAINTENANCE[language]}
            value={1}
          />
          <Tab
            icon={<NewReleases color="secondary" />}
            label={lang.MAINTENANCE_HISTORY[language]}
            value={2}
            disabled={!(props.isAuthenticated && checkPermissions(props.userPermissions, { parent: "MAINTENANCE", name: "VIEW_MAINTENANCE_HISTORY" }))}
          />
          <Tab
            icon={<NewReleases color="secondary" />}
            label={lang.TECHNICAL_SCORES_VALIDATIONS[language]}
            value={3}
            disabled={
              !checkPermissions(props.userPermissions, { parent: "TECHNICAL_TEAM", name: "VIEW_AND_EDIT_TECHNICAL_SCORE" }) ||
              !checkPermissions(props.userPermissions, { parent: "TECHNICAL_TEAM", name: "VIEW_AND_EDIT_TECHNICAL_SCORE_HISTORY" })
            }
          />
        </Tabs>
      </Paper>
    </React.Fragment>
  );
}

class MaintenanceView extends Component {
  state = {
    selectedTab: 1,
    maintenanceModal: false,
    maintenanceId: null,
  };

  componentDidMount() {
    this.props.actions.getAreaList();
    this.props.actions.checkSession();
  };

  render() {
    return (
      <>
        <CssBaseline />
        <React.Fragment>
          <MaintenanceModal
            open={this.state.maintenanceModal}
            close={() => this.setState({ maintenanceModal: false })}
            language={this.props.language}
            userPermissions={this.props.permissions}
            isAuthenticated={this.props.isAuthenticated}
            onNotificationOpen={(type, message) =>
              this.props.actions.onNotificationOpen(
                type,
                lang[message],
                6000
              )}
            maintenanceId={this.state.maintenanceId}
            user={this.props.userAuth}
            gps={this.props.geolocation}
          />
          <TabSelector
            language={this.props.language}
            value={this.state.selectedTab}
            handleChange={(newValue) =>
              this.setState({ selectedTab: newValue })
            }
            userPermissions={this.props.permissions}
            isAuthenticated={this.props.isAuthenticated}
          />
          {this.state.selectedTab === 1 && (
            <MaintenanceSetup
              language={this.props.language}
              theme={this.props.theme}
              openRowModal={(value) => {
                this.setState({
                  maintenanceModal: true,
                  maintenanceId: value
                })
              }}
            />
          )}
          {this.state.selectedTab === 2 && (
            <MaintenanceHistory
              openMaintenanceModal={(value) => {
                this.setState({
                  maintenanceModal: true,
                  maintenanceId: value
                })
              }}
              language={this.props.language}
              areaList={this.props.areaList}
            />
          )}
          {
            this.state.selectedTab === 3 && (
              <MaintenancesTechnicalScores
                language={this.props.language}
              />
            )
          }
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.config.language,
    theme: state.config.theme,
    isAuthenticated: state.auth.isAuthenticated,
    permissions: state.auth.permissions,
    userAuth: state.auth.userData,
    siteData: state.cust.siteData,
    isUploadingFile: state.task.isUploadingFile,
    geolocation: state.config.geolocation,
    areaList: state.nom.areaList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceView);
