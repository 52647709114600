import React, { Component } from 'react';
import { Grid, CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTableDemo from '../../../Components/Misc/Tables/MaterialTableSimple';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { departmentNom } from '../../../Components/Misc/Tables/TableHeaders'
import * as lang from '../../../Constants/strings';
import CustomModal from '../../../Components/Modals/CustomModal';
import TableSkeleton from '../Skeleton';
import { department } from '../../../Constants/forms';
import DepartmentNewForm from './DepartmentsNomComponents/DepartmentNewForm';
import DepartmentModal from './DepartmentsNomComponents/DepartmentModal';
import axios from 'axios';
import { ReportSharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}));

function DepartmentsNomSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.DEPARTMENT_TABLE[props.language]}
                    language={props.language}
                    data={props.departmentList}
                    rowsPerPage={props.rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={departmentNom(props.language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="ID"
                />
            </Grid>
        </Grid>
    );
}

class DepartmentsNom extends Component {
    state = {
        addModal: false,
        editModal: false,
        selectedIndex: null,
        addData: {},
        error: null,
        departmentData: {}
    }

    componentDidMount() {
        this.props.actions.getDepartmentList(0);
    }

    getDepartmentData(value) {
        fetch(`/api/get_department?id=${value}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.token,
                }
            }
        )
            .then(response => response.json())
            .then(response => this.setState({ departmentData: response }))
            .then(response => this.setState({ editModal: true }))
            .catch(err => console.log(err))
    };

    updateDepartmentData() {
        axios.post(`/api/update_department`, { ...this.state.departmentData }, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(res => {
                switch (res.status) {
                    case 200:
                        this.props.actions.onNotificationOpen("success", lang[res.data.message], 6000);
                        this.props.actions.getDepartmentList(0);
                        this.setState({ editModal: false });
                        break;
                    case 400:
                        this.props.actions.onNotificationOpen("warning", lang[res.data.message], 6000);
                        break;
                    default:
                        this.props.actions.onNotificationOpen("warning", lang[res.data.message], 6000);
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    fullWidth
                    open={this.state.addModal}
                    title={lang.ADD_DEPARTMENT[this.props.language]}
                    content={
                        <DepartmentNewForm
                            addData={this.state.addData}
                            language={this.props.language}
                            updateField={(e, length) => this.setState({ addData: { ...this.state.addData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                            updateFieldRaw={(field, value) => this.setState({ addData: { ...this.state.addData, [field]: value } })}
                            error={this.state.error}
                            updateError={(value) => this.setState({ error: value })}
                        />
                    }
                    close={() => {
                        this.setState({
                            addModal: false,
                            addData: {},
                            error: null,
                        })
                    }}
                    execute={() => {
                        this.props.actions.addNewDepartment(this.props.userAuth.id, this.state.addData);
                        console.log(this.state.addData);
                        this.setState({ addModal: false })
                    }}
                    validate={this.state.error === false}
                    language={this.props.language}
                />
                <DepartmentModal
                    open={this.state.editModal}
                    close={() => this.setState({ editModal: false })}
                    execute={() => this.updateDepartmentData()}
                    language={this.props.language}
                    updateField={(e, length) => this.setState({ departmentData: { ...this.state.departmentData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                    addData={this.state.departmentData}
                    error={this.state.error}
                    updateError={(value) => this.setState({ error: value })}
                />
                {this.props.isFetchingDepartmentList === false
                    ?
                    <DepartmentsNomSetup
                        language={this.props.language}
                        departmentList={this.props.departmentList}
                        rowsPerPage={this.props.rowsPerPage}
                        onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
                        openAddModal={() => this.setState({ addModal: true, addData: department })}
                        openRowModal={async (value) => {
                            await this.getDepartmentData(value);
                            await this.setState({ selectedIndex: value });
                        }
                        }
                    />
                    :
                    <TableSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,

        departmentList: state.nom.departmentList,
        isFetchingDepartmentList: state.nom.isFetchingDepartmentList,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsNom);