import InventoryTile from "./InventoryTile";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";

const TransferModal = (props) => {
    const {
        open,
        onClose,
        language,
        userId
    } = props;

    return (
        <CustomModal
            fullScreen={true}
            open={open}
            title={lang.CAR_INVENTORY_TRANSFERS[language]}
            content={
                <InventoryTile
                    userId={userId}
                    language={language}
                />
            }
            close={() => onClose()}
            language={language}
            onlyBack
            noButtons
        />
    )
}

export default TransferModal;