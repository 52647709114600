export const SITE_CODE = {
  en: "Site Code",
  ro: "Cod Obiectiv",
};

export const ACTIVE_SITES = {
  "en": "Active clients",
  "ro": "Obiective active",
};

export const INACTIVE_SITES = {
  "en": "Inactive clients",
  "ro": "Obiectiv inactive",
};

export const VALIDATE_SITES = {
  "en": "Valids sites",
  "ro": "Obiective validate",
};
export const NOVALIDATE_SITES = {
  "en": "Novalid sites",
  "ro": "Obiective nevalidate",
};

export const SITE_NAME = {
  en: "Site Name",
  ro: "Nume Obiectiv",
};
export const SITE_TYPE = {
  en: "Site Type",
  ro: "Tip Obiectiv",
};
export const ADD_SITE = {
  en: "Add site",
  ro: "Adăugare obiectiv",
};
export const EDIT_SITE = {
  en: "Edit site",
  ro: "Editare obiectiv",
};
export const DELETE_ACCOUNT = {
  en: "Delete account",
  ro: "Eliminare account obiectiv",
};
export const SITE = {
  en: "Site",
  ro: "Obiectiv",
};
export const SITE_DETAILS = {
  en: "Site details",
  ro: "Detalii obiectiv",
};

export const ACTIVE_CONTRACTS = {
  en: "Active contracts",
  ro: "Contracte active",
};

export const EXPIRATION_DATE = {
  en: "Expiration date",
  ro: "Dată de expirare",
};
export const ASSIGNEE = {
  en: "Assignee",
  ro: "Responsabil",
};
export const TICKET_CONTRACT = {
  en: "Ticket contract",
  ro: "Ticket reparare deschis in baza contractului",
};
export const FREE_TRUCK_ROLLS = {
  en: "Free truckrolls",
  ro: "Intervenții gratuite",
};
export const TRUCK_ROLL_VALUE = {
  en: "Truckroll price",
  ro: "Preț deplasare suplimentară",
};
export const TERMINATION_CONTRACT = {
  en: "Terminate",
  ro: "Reziliază",
};
export const ADJUST_CONTRACT = {
  en: "Adjust",
  ro: "Ajustare",
};
export const CONTRACT = {
  en: "Contract",
  ro: "Contract",
};
export const CONTRACT_ASSIGNEE = {
  en: "Contract assignee",
  ro: "Responsabili contracte",
};
export const DISPATCH_ASSIGNEE = {
  en: "Dispatcher assignee",
  ro: "Responsabili dispecer",
};
export const DISPATCH_SELECTED = {
  en: "Dispatcher",
  ro: "Dispecer de serviciu",
};
export const TYPE = {
  en: "Type",
  ro: "Tip",
};
export const SUBTYPE = {
  en: "Subtype",
  ro: "Subtip",
};
export const CONTRACT_NUMBER = {
  en: "Contract No.",
  ro: "Număr contract",
};
export const CONTRACT_START_DATE = {
  en: "Start date",
  ro: "Data începerii contractului",
};
export const CONTRACT_END_DATE = {
  en: "End data",
  ro: "Data finalizării contractului",
};
export const CONTRACT_INDETERMINATE = {
  en: "Indeterminate period",
  ro: "Perioadă nedeterminată",
};
export const CONTRACT_VALUE = {
  en: "Value",
  ro: "Valoare",
};
export const ASSIGNED_TEAM = {
  en: "Assigned team",
  ro: "Echipaj tehnic alocat/zona",
};
export const NFC = {
  en: "NFC",
  ro: "NFC",
};
export const BILL_CYCLE = {
  en: "Bill cycle",
  ro: "Ciclu facturare",
};
export const CONTRACT_ACTIVE = {
  en: "Active",
  ro: "Activ",
};
export const CONTRACT_INACTIVE = {
  en: "Inactive",
  ro: "Inactiv",
};

export const ADD_CONTRACT = {
  en: "New contract",
  ro: "Contract nou",
};
export const ADD_INTERVENTION = {
  en: "New intervention",
  ro: "Intervenție nouă",
};
export const ADD_TASK = {
  en: "New task",
  ro: "Ticket nou",
};

export const NO_EXECUTOR_AVAILABLE = {
  en: "No executors available",
  ro: "Contractor indisponibil",
};

export const CONTRACT_TYPE_WATCH = {
  en: "Guard",
  ro: "Pază",
};
export const CONTRACT_TYPE_TECHNIC = {
  en: "Technic",
  ro: "Tehnic",
};
export const CONTRACT_SUB_TYPE_WATCH = {
  en: "Guard",
  ro: "Pază",
};
export const CONTRACT_SUB_TYPE_MONITOR = {
  en: "Monitoring",
  ro: "Monitorizare",
};
export const CONTRACT_SUB_TYPE_MONITOR_PLUS = {
  en: "Monitoring and intervention",
  ro: "Monitorizare și intervenție",
};
export const CONTRACT_SUB_TYPE_MAINTENANCE = {
  en: "Maintenance",
  ro: "Mentenanță",
};
export const CONTRACT_SUB_TYPE_INSTALL = {
  en: "Instalation",
  ro: "Instalare",
};
export const CONTRACT_SUB_TYPE_PROJECT = {
  en: "Project",
  ro: "Proiectare",
};
export const CONTRACT_SUB_TYPE_EVALUATE = {
  en: "Evaluation",
  ro: "Evaluare",
};
export const CONTRACT_SUB_TYPE_INTERVENTION = {
  en: "Intervetion",
  ro: "Intervenție",
};
export const CONTRACT_SUB_TYPE_VALUE_TRANSPORT = {
  en: "Value transport",
  ro: "Transport valori",
};
export const CONTRACT_SUB_TYPE_REPAIR_ON_REQUEST = {
  en: "Repair on request",
  ro: "Reparare la cerere",
};
export const BILL_CURRENT_MONTH = {
  en: "Current month",
  ro: "Luna în curs",
};
export const BILL_LAST_MONTH = {
  en: "Last month",
  ro: "Luna precedentă",
};
export const BILL_QUARTER = {
  en: "Quarterly",
  ro: "Trimestrial",
};
export const BILL_SEMESTER = {
  en: "Semester",
  ro: "Semestrial",
};
export const BILL_YEARLY = {
  en: "Yearly",
  ro: "Anual",
};

export const INTERVENTIONS = {
  en: "Interventions",
  ro: "Intervenții",
};
export const TASKS = {
  en: "Tasks",
  ro: "Tickete",
};
export const TASKS_REPAIR = {
  en: "Repair tasks",
  ro: "Tickete Reparare",
};
export const TASKS_INSTALLATION = {
  en: "Installation tasks",
  ro: "Tickete Instalare",
};
export const ACCOUNTING = {
  en: "Accounting",
  ro: "Contabilitate",
};
export const CONTRACTS = {
  en: "Contracts",
  ro: "Contracte",
};
export const HISTORY = {
  en: "History",
  ro: "Istoric",
};

export const VALIDATES = {
  en: "Validates",
  ro: "Validează",
};

export const CENTRAL_TYPE = {
  en: "Central type",
  ro: "Tip centrală",
};
export const CENTRAL_PASSWORD = {
  en: "Central password",
  ro: "Parolă centrală",
};
export const CENTRAL_PHONE = {
  en: "Central phone",
  ro: "Telefon centrală",
};
export const ADDITIONAL_INFO = {
  en: "Additional information",
  ro: "Alte informații",
};

export const SELECT_CONTRACT = {
  en: "Select contract",
  ro: "Selectează contract",
};

export const CONTACT_PHONE = {
  en: "Contact phone number",
  ro: "Nr. telefon",
};

export const SITE_LANDMARKS = {
  en: "Site landmarks",
  ro: "Repere obiectiv",
};
export const WAS_MAILED = {
  en: "Was mailed",
  ro: "Status email",
};

export const SEND_MAIL = {
  en: "Send mail",
  ro: "Trimite email",
};

export const SITE_ALARM_AREA = {
  en: "Site alarm area",
  ro: "Zone alarmă obiectiv",
};

export const AREA_DESCRIPTION = {
  en: "Area description",
  ro: "Descriere zonă",
};

export const UPLOAD_AUDIO_FILE = {
  en: "Upload audio file",
  ro: "Încărcare fișiere audio",
};

export const EVENT = {
  en: "Event",
  ro: "Eveniment",
};

export const NO_ALARM = {
  en: "No alarm",
  ro: "Lipsă alarmă",
};

export const NO_DISARMAMENT = {
  en: "No disarmament",
  ro: "Lipsă dezarmare",
};

export const EFRACTON_SIGNAL = {
  en: "Efraction signal",
  ro: "Semnal de efracție",
};

export const NO_ARMAMENT = {
  en: "No armament",
  ro: "Lipsă armare",
};

export const INCIDENT = {
  en: "INCIDENT",
  ro: "Incident",
};

export const WAS_ANSWERD = {
  en: "Was answerd",
  ro: "A răspuns",
};

export const SITE_CENTRAL_INFO = {
  en: "Site central info",
  ro: "Informații centrală obiectiv",
};

export const CONTRACTOR_NAME = {
  en: "Contractor name",
  ro: "Firmă prestatoare",
};

export const NO_GPS_POSITION_WARNING = {
  en: "No GPS position",
  ro: "Fără poziție GPS",
};

export const SITE_WITH_NO_GPS_POSITION = {
  en: "The site dosen't have a gps pozition!",
  ro: "Obiectivul nu are poziție GPS!",
};

export const NO_GPS_POSITION_WARNING_CONTENT = {
  en: "Please get the GPS position and tranmited to the admin!",
  ro: "Vă rugăm sa preluați poziția GPS și să o transmiteți dispeceratului!",
};

export const CONTRACT_TYPE = {
  en: "Contract type",
  ro: "Tip Contract"
};

export const INFO_PAPERS = {
  en: "Info papers",
  ro: "Vizualizare informații documente"
};

export const SITE_ADDRESS = {
  en: "Site address",
  ro: "Adresa obiectivului"
}

export const GPS_POSITION = {
  en: "GPS Position",
  ro: "Poziția GPS"
}