/**
 * [AUTHOR]:      Tibulca, PAUL
 * [DETAILS]:     the button will switch between languages ([ro], [en] at the moment)
 * [TIMESTAMP]:   2019-11-02, 08:15 PM
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/LanguageRounded';
import Popper from '@material-ui/core/Popper';
import * as lang from '../../Constants/strings';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));


export default function LanguageButton(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <React.Fragment>
            <Tooltip title="Language">
                <IconButton
                    className={classes.margin}
                    ref={anchorRef}
                    aria-controls="menu-list-grow"
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color={props.color}
                >
                    <LanguageIcon fontSize={props.size} />
                </IconButton>
            </Tooltip>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                    <MenuItem disabled={props.language === "ro"} onClick={() => props.onLanguageChange("ro")}>{lang.ROMANIAN_LANGUAGE[props.language]}</MenuItem>
                                    <MenuItem disabled={props.language === "en"} onClick={() => props.onLanguageChange("en")}>{lang.ENGLISH_LANGUAGE[props.language]}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
