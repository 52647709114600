import React, { Component } from 'react';
import {
    Grid,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    Fab,
    Divider,
    FormControl,
    MenuItem
} from '@material-ui/core';
import * as lang from '../../../Constants/strings';
import SignaturePad from 'react-signature-canvas';
import CustomModal from '../../../Components/Modals/CustomModal';
import Trainee from './Trainee';
import { AddRounded } from '@material-ui/icons';
import TaskHandover from './TaskHandover';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import BillFields from '../../Tasks/Modals/billFields';

class TaskAction extends Component {
    state = {
        signDialog: false,
        trainees: this.props.trainees,
        billLock: false
    }

    componentDidMount() {
        this.props.setType('REPARARE')
    }

    render() {
        const {
            note,
            updateNote,
            language,
            classes,
            sign,
            confirm,
            toHold,
            siteContact,
            updateSiteContact,
            tiny,
            warranty,
            updateWarranty,
            opt,
            addRemoveTrainee,
            updateOpt,
            showProject,
            showJournal,
            project,
            journal,
            journalData,
            updateJournalData,
            teamSize,
            setTeamSize,
            deadLine,
            contractor,
            updateDeadLine
        } = this.props;

        const imageUploaderRef = React.createRef();
        const canvasRef = React.createRef();

        return (
            <React.Fragment key={this.props.trainees.length}>
                <CustomModal
                    fullWidth
                    fullScreen={tiny}
                    open={this.state.signDialog}
                    content={
                        <Grid item xs={12}>
                            <SignaturePad
                                ref={sign.sigCanvas}
                                canvasProps={{
                                    className: classes.signature
                                }}
                            />
                        </Grid>
                    }
                    execute={() => {
                        sign.saveSign();
                        sign.clear();
                        this.setState({ signDialog: false });
                    }}
                    close={() => {
                        sign.clear();
                        this.setState({ signDialog: false });
                    }}
                    language={language}
                    validate={true}
                />
                <Grid item xs={12}>
                    <TextField
                        required={confirm || toHold || this.props.loadImage}
                        multiline
                        fullWidth
                        variant="outlined"
                        name="OBSERVATION"
                        label={lang.OBSERVATION[language]}
                        value={note ? note : ""}
                        onChange={updateNote}
                    />
                </Grid>
                {
                    this.props.loadImage && <React.Fragment>
                        <Grid container justify='space-evenly' alignItems='center' alignContent='space-around'>
                            <Grid item md={12}>
                                <Button
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.props.onNextStateChange("CLOSED")}
                                    disabled={this.props.newStatusId == 8}
                                >
                                    {lang.CLOSE[this.props.language]}
                                </Button>
                                <Button
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.props.onNextStateChange("ON_HOLD")}
                                    disabled={this.props.newStatusId == 4}
                                >
                                    {lang.ON_HOLD[this.props.language]}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => imageUploaderRef.current.click()}
                                >
                                    {lang.LOAD_IMAGE[this.props.language]}
                                </Button>
                                <input
                                    onChange={() => 1 + 0}
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0, width: 0 }}
                                    value={this.props.imageUploaded}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            style={{ display: "none" }}
                            ref={imageUploaderRef}
                            tabIndex={-1}
                            onChange={async (e) => {
                                e.persist()
                                if (e.target.files.length > 0) {
                                    const canvas = canvasRef.current;
                                    const ctx = canvas.getContext('2d');
                                    ctx.fillStyle = '#fff';  /// set white fill style
                                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                                    var img = new Image();
                                    img.crossOrigin = 'Anonymous';;
                                    var url = URL.createObjectURL(e.target.files[0]);
                                    img.onload = () => {
                                        canvas.width = img.width;
                                        canvas.height = img.height;
                                        ctx.drawImage(img, 0, 0);
                                        this.props.onImageLoad(canvas.toDataURL("image/png"))
                                    }
                                    img.src = url;
                                }
                            }}
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} >
                            <canvas
                                style={{ maxWidth: 550, background: "none" }}
                                ref={canvasRef}
                            />
                        </Grid>
                    </React.Fragment>
                }
                {confirm && this.props.loadImage === false &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <FormControl
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <TextField
                                    required
                                    disabled
                                    select
                                    variant="outlined"
                                    label="Tip"
                                    id="select-multiple-chip"
                                    onChange={this.props.setType}
                                    SelectProps={{
                                        value: this.props.type,
                                    }}
                                >
                                    <MenuItem value={'INSTALARE'}>INSTALARE</MenuItem>
                                    <MenuItem value={'REPARARE'}>REPARARE</MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">

                                <TextField
                                    required
                                    select
                                    variant="outlined"
                                    label="Servicii"
                                    id="select-multiple-chip"
                                    onChange={this.props.setSubtype}
                                    SelectProps={{
                                        multiple: true,
                                        value: this.props.subtype,
                                    }}
                                >
                                    <MenuItem value={'CCTV'}>CCTV</MenuItem>
                                    <MenuItem value={'EFRACTIE'}>EFRACTIE</MenuItem>
                                    <MenuItem value={'CONTROL ACCES'}>CONTROL ACCES</MenuItem>
                                </TextField>
                                <input
                                    onChange={() => 1 + 0}
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    value={this.props.subtype}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="SITE_CONTACT"
                                label={lang.SITE_CONTACT_NAME[language]}
                                value={siteContact ? siteContact : ""}
                                onChange={updateSiteContact}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <TextField
                                    required
                                    select
                                    variant="outlined"
                                    label={lang.DISPATCH_SELECTED[language]}
                                    onChange={this.props.selectDispatcher}
                                    value={this.props.selectedDispatcher ? this.props.selectedDispatcher : ""}
                                >
                                    {this.props.dispatchers && this.props.dispatchers.length > 0 && this.props.dispatchers.map(item => {
                                        return <MenuItem key={item.OBJID} value={item.OBJID}>{item.USERNAME}</MenuItem>
                                    })}
                                </TextField>
                                <input
                                    onChange={() => 1 + 0}
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0, width: 0 }}
                                    value={this.props.selectedDispatcher ? this.props.selectedDispatcher : ""}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                type="number"
                                name="NUMBER_TEAM_MEMBERS"
                                label={lang.NUMBER_TEAM_MEMBERS[language]}
                                value={teamSize ? teamSize : ""}
                                ref="NUMBER_TEAM_MEMBERS"
                                onChange={setTeamSize}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">{lang.JOURNAL_DATA[language]}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="NO"
                                label={lang.NUMBER[language]}
                                value={journalData.NO ? journalData.NO : ""}
                                onChange={e => updateJournalData('NO', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    required
                                    inputVariant="outlined"
                                    fullWidth
                                    name="DATE"
                                    label={lang.DATE[language]}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={journalData.DATE ? journalData.DATE : ""}
                                    className={classes.formControl}
                                    onChange={(value) => updateJournalData('DATE', value)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="POS"
                                type="number"
                                label={lang.POSITION[language]}
                                value={journalData.POS ? journalData.POS : ""}
                                onChange={e => updateJournalData('POS', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={opt[0]} onChange={() => updateOpt(0)} />}
                                label={<Typography variant="caption">Au fost respectate cerințele tehnice și economice</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={opt[1]} onChange={() => updateOpt(1)} />}
                                label={<Typography variant="caption">Cantitățile de lucrări cuprinse în tabelul de mai jos au fost executate</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={opt[2]} onChange={() => updateOpt(2)} />}
                                label={<Typography variant="caption">Lucrările corespund calitativ și cantitativ</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={opt[3]} onChange={() => updateOpt(3)} />}
                                label={<Typography variant="caption">Predarea documentației</Typography>}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">{lang.TRAINED_PERSONS[language]}</Typography>
                        </Grid>
                        {this.props.trainees.map((item, index) =>
                            <Trainee tiny={tiny} key={index} data={item} index={index} update={(field, value) => this.props.updateTrainee(index, field, value)} remove={() => addRemoveTrainee(index)} language={language} classes={classes} />
                        )}
                        <Fab
                            className={classes.float}
                            color="secondary"
                            onClick={() => addRemoveTrainee(false)}
                            size="small"
                        >
                            <AddRounded fontSize="small" />
                        </Fab>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid container item xs={12} justify="center">
                            <FormControlLabel
                                control={<Checkbox size="small" checked={showProject} onChange={() => this.props.updateProject('SHOW', !showProject)} />}
                                label={<Typography variant="h6" align="center">{lang.SYSTEM_PROJECT[language]}</Typography>}
                            />
                        </Grid>
                        {showProject === false
                            ? null
                            :
                            <TaskHandover tiny={tiny} index={1} data={project} update={this.props.updateProject} language={language} classes={classes} />
                        }
                        <Grid container item xs={12} justify="center">
                            <FormControlLabel
                                control={<Checkbox size="small" checked={showJournal} onChange={() => this.props.updateJournal('SHOW', !showJournal)} />}
                                label={<Typography variant="h6" align="center">{lang.SYSTEM_JOURNAL[language]}</Typography>}
                            />
                        </Grid>
                        {showJournal === false
                            ? null
                            :
                            <TaskHandover tiny={tiny} index={2} data={journal} update={this.props.updateJournal} language={language} classes={classes} />
                        }

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid container justify="center">
                            {sign.imageURL === null
                                ?
                                <React.Fragment>
                                    <Button variant="contained" color="secondary" onClick={() => this.setState({ signDialog: true })}>
                                        {lang.SIGN_ACTION[language]}
                                    </Button>
                                    <input
                                        onChange={() => 1 + 0}
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0, width: 0 }}
                                        value={sign.imageURL === null ? "" : sign.imageURL}
                                        required
                                    />
                                </React.Fragment>
                                :
                                <Button disabled>
                                    {lang.SIGN_DONE[language]}
                                </Button>
                            }
                        </Grid>
                    </React.Fragment>
                }
                {(confirm || this.props.loadImage === true && this.props.newStatusId === 8) &&
                    <BillFields
                        userId={this.props.userId}
                        taskId={this.props.taskId}
                        contractor={contractor}
                        language={language}
                        onNotificationOpen={(type, data) =>
                            this.props.onNotificationOpen(type, data)
                        }
                    />
                }
                {toHold && this.props.loadImage === false &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="SITE_CONTACT"
                                label={lang.SITE_CONTACT_NAME[language]}
                                value={siteContact ? siteContact : ""}
                                onChange={updateSiteContact}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <TextField
                                    required
                                    select
                                    variant="outlined"
                                    label={lang.DISPATCH_SELECTED[language]}
                                    onChange={this.props.selectDispatcher}
                                    value={this.props.selectedDispatcher ? this.props.selectedDispatcher : ""}
                                >
                                    {this.props.dispatchers && this.props.dispatchers.length > 0 && this.props.dispatchers.map(item => {
                                        return <MenuItem key={item.OBJID} value={item.OBJID}>{item.USERNAME}</MenuItem>
                                    })}
                                </TextField>
                                <input
                                    onChange={() => 1 + 0}
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0, width: 0 }}
                                    value={this.props.selectedDispatcher ? this.props.selectedDispatcher : ""}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                type="number"
                                name="NUMBER_TEAM_MEMBERS"
                                label={lang.NUMBER_TEAM_MEMBERS[language]}
                                value={teamSize ? teamSize : ""}
                                ref="NUMBER_TEAM_MEMBERS"
                                onChange={setTeamSize}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">{lang.JOURNAL_DATA[language]}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="NO"
                                label={lang.NUMBER[language]}
                                value={journalData.NO ? journalData.NO : ""}
                                onChange={e => updateJournalData('NO', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    required
                                    inputVariant="outlined"
                                    fullWidth
                                    name="DATE"
                                    label={lang.DATE[language]}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={journalData.DATE ? journalData.DATE : ""}
                                    className={classes.formControl}
                                    onChange={(value) => updateJournalData('DATE', value)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="POS"
                                type="number"
                                label={lang.POSITION[language]}
                                value={journalData.POS ? journalData.POS : ""}
                                onChange={e => updateJournalData('POS', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    required
                                    inputVariant="outlined"
                                    fullWidth
                                    name="DATE_LIMIT"
                                    label={lang.DATE_LIMIT[language]}
                                    disablePast
                                    format="dd/MM/yyyy, HH:mm"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={deadLine}
                                    onChange={updateDeadLine}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={warranty} onChange={() => updateWarranty(!warranty)} />}
                                label={<Typography variant="caption">{lang.WARRANTY[language]}</Typography>}
                            />
                        </Grid>

                        <Grid container justify="center">
                            {sign.imageURL === null
                                ?
                                <React.Fragment>
                                    <Button variant="contained" color="secondary" onClick={() => this.setState({ signDialog: true })}>
                                        {lang.SIGN_ACTION[language]}
                                    </Button>
                                    <input
                                        onChange={() => 1 + 0}
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0, width: 0 }}
                                        value={sign.imageURL === null ? "" : sign.imageURL}
                                        required
                                    />
                                </React.Fragment>
                                :
                                <Button disabled>
                                    {lang.SIGN_DONE[language]}
                                </Button>
                            }
                        </Grid>
                    </React.Fragment>
                }
            </ React.Fragment >
        );
    }
}

export default TaskAction;
