import React, { Component } from 'react'
import * as lang from "../../../../Constants/strings";
import { formatDateFromDb } from "../../../../Functions/dateFunctions";
import InstallationModal from '../../../Installation/InstallationModal';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import * as actionCreators from "../../../../actions/actions";
import { downloadFile } from '../../../../Functions/fileDownload';

/**
 * The original implementation is a bit messy
 * A lot of coupling with the redux store we try to move away from it
 * @param {number} selectedIndex
 * @param {boolean} open
 * @param {function} close
 */
class InstallationModalAdapter extends Component {
    state = {
        searchTerm: "",
        selectedIndex: null,
        trainees: [
            {
                NAME: "",
                SIGN: "",
            },
        ],
        project: {
            NAME: "",
            NUMBER: "",
            SIGN: "",
        },
        showProject: false,
        journal: {
            NAME: "",
            NUMBER: "",
            SIGN: "",
        },
        journalData: {
            NO: "",
            DATE: new Date(),
            POS: "",
        },
        opt: [false, false, false, false],
        showJournal: false,
        permissionsCheck: [],
    };

    updateProject = (field, value) => {
        if (field === "SHOW")
            this.setState({ showProject: !this.state.showProject });
        else {
            let placeholder = this.state.project;
            placeholder[field] = value;
            this.setState({ project: placeholder });
        }
    };

    updateJournal = (field, value) => {
        if (field === "SHOW")
            this.setState({ showJournal: !this.state.showJournal });
        else {
            let placeholder = this.state.journal;
            placeholder[field] = value;
            this.setState({ journal: placeholder });
        }
    };

    resetProject = () => {
        this.setState({
            project: { NAME: "", NUMBER: "", SIGN: "" },
            showProject: false,
        });
    };

    resetJournal = () => {
        this.setState({
            journal: { NAME: "", NUMBER: "", SIGN: "" },
            showJournal: false,
        });
    };

    addRemoveTrainee = (index) => {
        let placeholder = this.state.trainees;
        if (index === false) placeholder.push({ NAME: "", SIGN: "" });
        else placeholder.splice(index, 1);
        this.setState({ trainees: placeholder });
    };

    updateTrainee = (index, field, value) => {
        let placeholder = this.state.trainees;
        placeholder[index][field] = value;
        this.setState({ trainees: placeholder }, console.log(this.state.trainees));
    };

    resetTrainees = () => {
        this.setState({ trainees: [{ NAME: "", SIGN: "" }] });
    };

    updateOpt = (index) => {
        let placeholder = this.state.opt;
        placeholder[index] = !placeholder[index];
        this.setState({ opt: placeholder }, console.log(this.state.opt));
    };

    resetOpt = () => {
        this.setState({ opt: [false, false, false, false] });
    };

    updateJournalData = (field, value) => {
        let placeholder = this.state.journalData;
        placeholder[field] = value;
        this.setState({ journalData: placeholder });
    };

    resetJournalData = () => {
        this.setState({
            journalData: {
                NO: "",
                DATE: new Date(),
                POS: "",
            },
        });
    };

    newTaskAction = async (
        changeType,
        changeFrom,
        changeTo,
        note,
        siteContact,
        signature,
        serviceNo,
        warranty,
        opt,
        trainees,
        project,
        journal,
        dispatcher,
        type,
        products,
        teamSize,
        deadLine,
        pvImage
    ) => {
        this.props.actions.newTaskAction(this.props.userAuth.id, {
            TASK_OBJID: this.props.taskData.task.OBJID,
            REASON: this.props.taskData.task.REASON,
            CHG_USER: this.props.userAuth.id,
            CHG_TYPE: changeType,
            CHG_FROM: changeFrom,
            CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
            NOTE: note,
            CONTRACT_NO: this.props.taskData.task.CONTRACT_NO,
            CONTRACT_TYPE: this.props.taskData.task.CONTRACT_TYPE
                ? lang[this.props.taskData.task.CONTRACT_TYPE][this.props.language]
                : "",
            SITE_CONTACT: siteContact,
            SIGNATURE: signature,
            SERVICE_NO: serviceNo,
            WARRANTY: warranty,
            OPT: opt,
            TRAINEES: trainees,
            PROJECT: project,
            JOURNAL: journal,
            DISPATCHER_ID: dispatcher,
            TYPE: type,
            PRODUCTS: products,
            TEAM_SIZE: teamSize,
            JOURNAL_DATA: this.state.journalData,
            DEAD_LINE: deadLine
                ? formatDateFromDb(deadLine, 12)
                : formatDateFromDb(new Date(), 12),
            PV_IMAGE: pvImage,
        });
        await this.props.actions.getActiveTaskData(this.props.selectedIndex);
        if (
            this.props.taskData &&
            this.props.taskData.task &&
            this.props.taskData.task.CONTACT_OBJID
        ) {
            await this.props.actions.getCustomerData(
                this.props.taskData.task.CONTACT_OBJID
            );
        }
    };

    render() {
        return (
            <InstallationModal
                siteData={this.props.siteData}
                language={this.props.language}
                open={this.props.open}
                close={this.props.close}
                installationId={this.props.selectedIndex}
                user={this.props.userAuth}
                userPermissions={this.props.permissions}
                isAuthenticated={this.props.isAuthenticated}
                onNotificationOpen={(type, message) =>
                    this.props.actions.onNotificationOpen(type, lang[message], 6000)
                }
                addCustomerContract={(data) => {
                    this.props.actions.addCustomerContract(
                        this.props.userAuth.id,
                        {
                            ...data,
                            SITE_OBJID: this.props.siteData.OBJID,
                            SALE_CONTACT: this.props.userAuth.id,
                        },
                        this.props.language
                    );
                }}
                isUploadingFile={this.props.isUploadingFile}
                cust={{
                    data: this.props.customerData,
                    loading:
                        this.props.isFetchingCustomerData ||
                        this.props.isFetchingSiteData,
                    taskData: this.props.taskData,
                    initialLoad: this.props.taskData.task
                        ? () =>
                            this.props.actions.getCustomerData(
                                this.props.taskData.task.CONTACT_OBJID
                            )
                        : () => console.log("No customer id"),
                }}
                task={{
                    opt: this.state.opt,
                    updateOpt: this.updateOpt,
                    resetOpt: this.resetOpt,
                    project: this.state.project,
                    journal: this.state.journal,
                    showProject: this.state.showProject,
                    showJournal: this.state.showJournal,
                    updateProject: this.updateProject,
                    updateJournal: this.updateJournal,
                    journalData: this.state.journalData,
                    updateJournalData: this.updateJournalData,
                    resetJournalData: this.resetJournalData,
                    resetProject: this.resetProject,
                    resetJournal: this.resetJournal,
                    trainees: this.state.trainees,
                    updateTrainee: this.updateTrainee,
                    addRemoveTrainee: this.addRemoveTrainee,
                    resetTrainees: this.resetTrainees,
                    userId: this.props.userAuth.id,
                    data: this.props.taskData,
                    loading: this.props.isFetchingTaskData,
                    downloadFile: downloadFile,
                    fetchData: async () => {
                        await this.props.actions.getTaskData(this.props.selectedIndex);
                        await this.props.actions.getSiteData(this.props.taskData.task.SITE_OBJID);
                    },
                    newTaskAction: this.newTaskAction,
                    resetTaskAction: (changeType, changeFrom, changeTo, note) =>
                        this.props.actions.resetTaskAction(this.props.userAuth.id, {
                            TASK_OBJID: this.props.taskData.task.OBJID,
                            REASON: this.props.taskData.task.REASON,
                            CHG_USER: this.props.userAuth.id,
                            CHG_TYPE: changeType,
                            CHG_FROM: changeFrom,
                            CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                            NOTE: note,
                        }),
                    newTaskComment: (changeType, changeFrom, changeTo, note, file) =>
                        this.props.actions.newTaskComment(this.props.userAuth.id, {
                            TASK_OBJID: this.props.taskData.task.OBJID,
                            REASON: this.props.taskData.task.REASON,
                            CHG_USER: this.props.userAuth.id,
                            CHG_TYPE: changeType,
                            CHG_FROM: changeFrom,
                            CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                            NOTE: note,
                            FILE: file,
                        }),
                }}
            />
        )
    }
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isFetchingTaskList: state.task.isFetchingTaskList,
        taskList: state.task.taskList,
        loadedPages: state.config.loadedPages,
        rowsPerPage: state.config.rowsPerPage,
        sortColumn: state.config.sortColumn,
        currentPage: state.config.currentPage,
        order: state.config.order,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,
        isFetchingTaskData: state.task.isFetchingTaskData,
        taskData: state.task.taskData,
        selectedSortItem: state.config.selectedSortItem,
        siteData: state.cust.siteData,
        isFetchingCustomerData: state.cust.isFetchingCustomerData,
        isFetchingSiteData: state.cust.isFetchingSiteData,
        customerData: state.cust.customerData,
        isUploadingFile: state.task.isUploadingFile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallationModalAdapter);