import React from 'react';
import PropTypes from 'prop-types';
import * as lang from '../../../Constants/strings';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import { file_table } from '../../../Components/Misc/Tables/TableHeaders';
import { checkPermissions } from "../../../Functions/permissionHide";

const FileTable = ({ data, language, handelFileSelect, addFile, userPermission }) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    return (
        <React.Fragment>
            <MaterialTable
                tiny={false}
                title={lang.TEMPLATE_FILES[language]}
                language={language}
                data={data && data.length > 0 ? [...data] : []}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={file_table(language)}
                dense={false}
                openAddModal={() => addFile() }
                openRowModal={(fileName) => handelFileSelect(fileName)}
                clickLabel="FILE_NAME"
                hideAddBtn={!checkPermissions(userPermission, { parent: "TEMPLATE_FILE", name: "ADD_TEMPLATE_FILE" })}
            />
        </React.Fragment>
    )
};

FileTable.propTypes = {
    data: PropTypes.array,
    language: PropTypes.string,
    handelFileSelect: PropTypes.func,
    addFile: PropTypes.func,
    userPermission: PropTypes.object
};

export default FileTable;