import React, { Component } from 'react';
import { Grid, CssBaseline, Paper, Button, Divider, Typography, IconButton, MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTableDemo from '../../Components/Misc/Tables/MaterialTableSimple';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { user } from '../../Components/Misc/Tables/TableHeaders';
import * as lang from '../../Constants/strings';
import CustomModal from '../../Components/Modals/CustomModal';
import UserNewForm from '../../Components/Misc/Forms/UserNewForm';
import { UserSummary } from './UserComponents/UserSummary';
import ExternalUserSelectModal from './UserComponents/ExternalUserSelectModal';
import { redirectTo } from '../../Functions/linkFunctions';
import Skeleton from '@material-ui/lab/Skeleton';
import { Lock, Block, CheckCircleRounded, DeleteRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    areaPaperContainer: {
        backgroundColor: "#818282",
        margin: theme.spacing(1)
    },
    addAreaItems: {
        marginTop: theme.spacing(1),
        marign: theme.spacing(2),
    }
}));

function UserViewSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.USER_TABLE[props.language]}
                    language={props.language}
                    data={props.userList}
                    roleList={props.roleList}
                    departmentList={props.departmentList}
                    rowsPerPage={props.rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={user(props.language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="USER_ID"
                />
            </Grid>
        </Grid>
    );
}

function PlaceholderSkeleton(props) {
    const classes = useStyles();
    return (
        <Grid container component="main" className={classes.root} spacing={4}>
            <Skeleton component={Paper} className={classes.paper} height="100%" width="100%">
                <Grid container justify="center" alignItems="center" direction="column">
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                </Grid>
            </Skeleton>
        </Grid>
    );
}

function UserModal(props) {
    const { selectedIndex, language } = props;
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <CustomModal
            fullWidth
            open={selectedIndex !== null}
            content={
                <UserSummary
                    id={selectedIndex}
                    language={language}
                    goToFunction={props.goToFunction}
                    close={props.close}
                    blockFunction={props.blockFunction}
                    openInterventionArea={props.openInterventionArea}
                    externalUserModal={props.externalUserModal}
                />
            }
            close={props.close}
            execute={props.execute}
            language={language}
            onlyBack={tiny}
            noButtons
        />
    );
};

// Allow to select and set the area that an user is permited to see in the 
// intervention page (for the user that are in the INTERVANTIONS department)
function UserAreaForIntervention(props) {
    const { open, language, close, userId } = props;

    const [areaList, setAreaList] = React.useState([]);
    const [selectedAreaList, setSelectedAreaList] = React.useState([]);
    const [curentSelectedArea, setCurentSelectedArea] = React.useState(null);
    const [edited, setEdited] = React.useState(false);

    const classes = useStyles();

    React.useEffect(() => {
        fetch(`/api/areas_active`, {
            method: "GET",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.length > 0) {
                    setAreaList([...response]);
                } else {
                    setAreaList([]);
                }
            })
    }, []);

    React.useEffect(() => {
        fetch(`/api/get_user_area?id=${props.userId}`, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.data.length > 0) {
                    setSelectedAreaList(response.data);
                } else {
                    setSelectedAreaList([]);
                }
            })
    }, [userId, open]);

    const updateUserArea = () => {
        fetch(`/api/update_user_area`, {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({ itemList: [...selectedAreaList], FK2USER_OBJID: userId })
        })
            .then(response => response.json())
            .then(response => console.log(response))
    };

    const deleteUserArea = (id) => {
        fetch(`/api/delete_user_area?id=${id}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
            body: JSON.stringify({})
        })
            .then(response => response.json())
            .then(response => {
                switch (response.status) {
                    case 200:
                        break;
                    case response.status > 400:
                        break
                    default: console.log("")
                }
            })
    }

    return (
        <React.Fragment>
            <CustomModal
                open={open}
                fullWidth
                title={lang.SELECT_USER_INTERVENTION_ASSIGN_AREA[language]}
                content={
                    <Grid container spacin={2}>
                        {
                            selectedAreaList.map(selectedArea => {
                                return <Grid item xs={12}>
                                    <Paper className={classes.areaPaperContainer}>
                                        <Grid container alignItems='stretch' justify='space-between'>
                                            <Grid item >
                                                <Typography style={{ margin: 10 }} varint="h6"> {selectedArea.AREA}</Typography>
                                            </Grid>
                                            <Grid item >
                                                <IconButton
                                                    color="secondary"
                                                    onClick={(e) => {
                                                        const index = selectedAreaList.indexOf(selectedArea);
                                                        let container = [...selectedAreaList];
                                                        container.splice(index, 1)
                                                        setSelectedAreaList(container);
                                                        deleteUserArea(selectedArea.OBJID);
                                                    }}
                                                >
                                                    <DeleteRounded />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            })
                        }

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justify='space-between' alignItems='center' spacing={2}>
                                <Grid item md={6}>
                                    <Select
                                        className={classes.addAreaItems}
                                        fullWidth
                                        variant='outlined'
                                        value={curentSelectedArea}
                                        onChange={e => setCurentSelectedArea(e.target.value)}
                                    >
                                        {
                                            areaList.map(area => {
                                                return <MenuItem key={`Area ${area.AREA_CODE}`} value={area.AREA_CODE}> {area.AREA}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item md={6}>
                                    <Button
                                        fullWidth
                                        className={classes.addAreaItems}
                                        variant='contained'
                                        color='primary'
                                        disabled={curentSelectedArea === null}
                                        onClick={() => {
                                            let oldData = selectedAreaList;
                                            let valueCheck = false;
                                            areaList.map(area => {
                                                // get the object where the AREA_CODE si equal with the curentSelectedArea
                                                // map throug the selecteArea if that object dosen't exist push it to the list
                                                if (area.AREA_CODE === curentSelectedArea) {
                                                    selectedAreaList.map(selectedArea => {
                                                        if (selectedArea.AREA_CODE === curentSelectedArea) {
                                                            valueCheck = true;
                                                        }
                                                    })
                                                    if (!valueCheck) {
                                                        setEdited(true);
                                                        oldData.push(area);
                                                    }
                                                }
                                            })
                                            setSelectedAreaList([...oldData]);
                                        }}
                                    >
                                        {lang.ADD_AREA[language]}
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                }
                execute={() => {
                    updateUserArea();
                    close();
                }}
                validate={selectedAreaList.length > 0 && edited}
                language={language}
                close={close}
            />
        </React.Fragment>
    )
}
class UserView extends Component {
    state = {
        userList: [],
        roleList: {},
        addUserModal: false,
        addUserData: {},
        selectedIndex: null,
        userError: null,
        interventionUserAreaList: false,
        selectUserId: null,
        externalUserModal: false, // controll fo the modal that selects and updates the user inventory account
    }

    tiny = theme => false;

    getItems() {
        fetch('/api/users', {
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            }
        })
            .then(response => response.json())
            .then(response => {
                let i = 0;
                let userList = response.data.userList.map(user => {
                    i++;
                    user["NR"] = i;
                    user.HAS_ACCOUNT_ID = user.INVENTORY_APP_USER_ID != null ? <CheckCircleRounded color='primary' /> : <CheckCircleRounded color='secondary' />;
                    user.LOCKED = user.LOCKED === 1 ? <Lock color='secondary' /> : <CheckCircleRounded color='primary' />;

                    return user;
                })
                this.setState({ userList: userList, roleList: response.data.roleList })
            })
            .catch(err => console.log(err))
    }

    componentDidMount() {
        this.getItems()
        this.props.actions.getDepartmentList(1);
    }


    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    open={this.state.addUserModal}
                    title={lang.ADD_USER[this.props.language]}
                    content={
                        <UserNewForm
                            addUserData={this.state.addUserData}
                            roleList={this.state.roleList}
                            departmentList={this.props.departmentList}
                            updateField={(e, length) => this.setState({ addUserData: { ...this.state.addUserData, [e.target.name]: e.target.value.toString().slice(0, length) } })}
                            language={this.props.language}
                            userError={this.state.userError}
                            userErrorUpdate={(value) => this.setState({ userError: value })}
                        />
                    }
                    close={() => {
                        this.setState({
                            addUserModal: false,
                            addUserData: {},
                            userError: null,
                        })
                    }}
                    execute={async () => {
                        this.setState({ addUserModal: false, addUserData: {}, userError: null, });
                        await this.props.actions.addNewUser(this.props.userAuth.id, this.state.addUserData);
                        await this.getItems();
                        await this.forceUpdate();
                    }}
                    validate={this.state.userError === false && this.state.addUserData.ROLE !== undefined && this.state.addUserData.DEPARTMENT_OBJID !== undefined &&
                        this.state.addUserData.LAST_NAME !== undefined && this.state.addUserData.FIRST_NAME !== undefined && this.state.addUserData.ROLE !== undefined &&
                        this.state.addUserData.PHONE_WORK !== undefined && this.state.addUserData.PHONE_PERSONAL !== undefined && this.state.addUserData.EMAIL !== undefined
                    }
                    language={this.props.language}
                />
                <UserModal
                    language={this.props.language}
                    selectedIndex={this.state.selectedIndex}
                    close={() => {
                        this.getItems();
                        this.setState({ selectedIndex: null })
                    }}
                    goToFunction={() => redirectTo(`/profile?id=${this.state.selectedIndex}`)}
                    blockFunction={async (LOCKED) => {
                        await this.props.actions.updateUserLock(this.props.userAuth.id, this.state.selectedIndex, LOCKED);
                        await this.getItems();
                    }}
                    openInterventionArea={() => this.setState({ interventionUserAreaList: true })}
                    externalUserModal={() => this.setState({ externalUserModal: true })}
                />

                {/*@TODO: Add modal here*/}
                <ExternalUserSelectModal
                    isOpen={this.state.externalUserModal && this.state.selectUserId}
                    onClose={() => this.setState({ externalUserModal: false })}
                    language={this.props.language}
                    selectedUserId={this.state.userList[this.state.userList.findIndex(x => x.USER_ID === this.state.selectedIndex)]?.INVENTORY_APP_USER_ID}
                    userId={this.state.selectUserId}
                />

                <UserAreaForIntervention
                    open={this.state.interventionUserAreaList}
                    language={this.props.language}
                    close={() => this.setState({ interventionUserAreaList: false })}
                    userId={this.state.selectUserId}
                />
                {this.props.isFetchingUserData === false
                    ?
                    <UserViewSetup
                        language={this.props.language}
                        userList={this.state.userList}
                        roleList={this.state.roleList}
                        departmentList={this.props.departmentList}
                        rowsPerPage={this.props.rowsPerPage}
                        onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
                        openAddModal={() => this.setState({ addUserModal: true })}
                        openRowModal={(value) => this.setState({ selectedIndex: value, selectUserId: value })}
                    />
                    :
                    <PlaceholderSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,

        isFetchingUserData: state.user.isFetchingUserData,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,

        departmentList: state.nom.departmentList,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
