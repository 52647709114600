import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import * as lang from "../../../Constants/strings";
import { taskS } from "../../../Components/Misc/Tables/TableHeaders";
import { getGetHeader } from "../../../Constants/requestHeaders";
import MaterialTable from "../../../Components/Misc/Tables/MaterialTableSimple";
import { formatDateFromDb } from "../../../Functions/dateFunctions";
import axios from "axios";
import InstallationModalAdapter from "./Modals/InstallationModalAdapter";

export default function InstallationTable(props) {
    const {
        language,
        tiny,
        siteId
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState();

    useEffect(() => {
        if (siteId) getInstallation(siteId);
    }, [siteId]);

    const getInstallation = async (siteId) => {
        setLoading(true)
        try {
            const { data } = await axios.get('/api/site_installation', {
                ...getGetHeader(),
                params: {
                    id: siteId,
                }
            });
            let fList = []
            data.data.forEach(item => {
                fList.push({
                    ...item,
                    TYPE_TRANS: item.TYPE !== null ? lang[item.TYPE][language] : item.TYPE !== null,
                    STATUS_TRANS: lang[item.STATUS][language],
                    LAST_UPDATE_TRANS: formatDateFromDb(item.LAST_UPDATE, 12),
                    CREATE_TIME_TRANS: formatDateFromDb(item.CREATE_TIME, 12),
                })
            }
            )
            setData(fList);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <React.Fragment>
            <InstallationModalAdapter
                selectedIndex={selectedId}
                open={modal}
                close={() => setModal(false)}
            />
            {loading ? (
                <SkeletonInstallationTable {...props} />
            ) : (
                <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    style={{ paddingRight: "0px" }}
                    direction="column"
                >
                    <MaterialTable
                        hideAddBtn={true}
                        tiny={tiny}
                        title={lang.INSTALLS[language]}
                        language={language}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(value) => setRowsPerPage(value)}
                        header={taskS(language)}
                        dense={false}
                        openAddModal={() => { }}
                        openRowModal={(id) => {
                            setSelectedId(id);
                            setModal(true);
                        }}
                        clickLabel="OBJID"
                    />
                </Grid>
            )}
        </React.Fragment>
    );
}

export function SkeletonInstallationTable(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={12} container direction="column">
            <Skeleton component={Paper} height="45vh">
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                >
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                </Grid>
            </Skeleton>
        </Grid>
    );
}
