export const TEMPLATE_FILES = {
    "en": "Template files",
    "ro": "Documente Tipizate"
};

export const DELETE_FILE = {
    "en": "Delete file",
    "ro": "Șterge fișier"
};

export const ADD_TEMPLATES_FILE = {
    "en": "Add templates file",
    "ro": "Adaugă fișier tipizat"
};

export const COMERCIAL_TEMPLATE_FILES = {
    en: "Comercial template files",
    ro: "Fișiere tipizate comercial"
};

export const TEHNIC_TEMPLATE_FILES = {
    en: "Tehnic template files",
    ro: "Fișiere tipizate tehnic"
};
