import React from 'react';
import MaterialTable from '../../../Components/Misc/Tables/MaterialTableSimple';
import { task_bill_history } from "../../../Components/Misc/Tables/TableHeaders";
import * as lang from "../../../Constants/strings";

export default function BillHistoryTable(props) {
    const { language, data, openRow } = props;

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    return (
        <React.Fragment>
            <MaterialTable
                tiny={true}
                title={lang.TASK_BILL_HISTORY[language]}
                language={language}
                data={data}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
                header={task_bill_history(language)}
                dense={false}
                openAddModal={() => { }}
                openRowModal={(e, data) => { 
                    openRow(e, data.TYPE);
                }}
                clickLabel="OBJID"
                hideAddBtn={true}
            />
        </React.Fragment>
    )
}
